import { Component, OnInit } from '@angular/core';
import { UtilityService } from './../service/utility.service';

@Component({
  selector: 'app-module-function',
  templateUrl: './module-function.component.html',
  styleUrls: ['./module-function.component.scss']
})
export class ModuleFunctionComponent implements OnInit {
  isModuleTraining: boolean= true;
  isFunctionTraining: boolean;

  constructor(private utilityService: UtilityService) { }

  ngOnInit() {
    this.utilityService.hideLoading();
  }
  tabChange(e){
    if(e === 1){
      this.isModuleTraining=true;
      this.isFunctionTraining=false;
    }
    else if(e === 2){
      this.isModuleTraining=false;
      this.isFunctionTraining=true;
    }
    else if(e === 3){
      this.isModuleTraining=false;
      this.isFunctionTraining=false;
    }
  }
}
