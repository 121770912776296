import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { OfferRoutingModule } from './offer-routing.module';
import { OfferComponent } from './offer.component';
import { SharedModule } from '../shared/shared/shared.module';
import { OfferModalComponent } from './offer-modal/offer-modal.component';
import { AvailableOfferComponent } from './available-offer/available-offer.component';
import { RequestedOfferComponent } from './requested-offer/requested-offer.component';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OfferService } from '../service/offer.service';
import { DepartmentService } from '../service/department.service';
import { ShiftService } from '../service/shift.service';
import { TermsConditionService } from '../service/terms-condition.service';
import { DatePipe } from '@angular/common';
import { DirectiveModule } from '../shared/shared/directive/directive.module';
import { RequestVotComponent } from './request-vot/request-vot.component';
import { SwapRequestComponent } from './swap-request/swap-request.component';
import { TransferRequestComponent } from './transfer-request/transfer-request.component';
import { TrainingRequestComponent } from './training-request/training-request.component';
import { AddEditVotRequestComponent } from './add-edit-vot-request/add-edit-vot-request.component';
import { VotRequestService } from '../service/vot-request.service';
import { AvailableSwapRequestComponent } from './available-swap-request/available-swap-request.component';
import { AvailableVotRequestComponent } from './available-vot-request/available-vot-request.component';
import { VtoRequestComponent } from './vto-request/vto-request.component';
import { AddEditVtoRequestComponent } from './add-edit-vto-request/add-edit-vto-request.component';
import { InstantVtoRequestComponent } from './instant-vto-request/instant-vto-request.component';
import { AvailableVtoRequestComponent } from './available-vto-request/available-vto-request.component';
import { CallInRequestComponent } from './call-in-request/call-in-request.component';
import { AddEditCallInRequestComponent } from './add-edit-call-in-request/add-edit-call-in-request.component';
import { VtoRequestService } from '../service/vto-request.service';
import { PendingRequestComponent } from './pending-request/pending-request.component';
import { PendingVtoRequestComponent } from './pending-vto-request/pending-vto-request.component';
import { EarlyGoRequestComponent } from './early-go-request/early-go-request.component';
import { AddEditEarlyGoRequestComponent } from './add-edit-early-go-request/add-edit-early-go-request.component';
import { AvailableEarlyGoRequestComponent } from './available-early-go-request/available-early-go-request.component';
import { FlexWorkRequestComponent } from './flex-work-request/flex-work-request.component';
import { AvailableFlexWorkRequestComponent } from './available-flex-work-request/available-flex-work-request.component';
import { AddEditFlexWorkRequestComponent } from './add-edit-flex-work-request/add-edit-flex-work-request.component';
import { InstantFlextWorkRequestComponent } from './instant-flext-work-request/instant-flext-work-request.component';
import { FlexRequestService } from '../service/flex-request.service';
import { FlexProcessConfirmationComponent } from './flex-process-confirmation/flex-process-confirmation.component';
import { AddEditTransferRequestComponent } from './add-edit-transfer-request/add-edit-transfer-request.component';
import { TransferRequestService } from '../service/transfer-request.service';
import { TimeoffRequestComponent } from './timeoff-request/timeoff-request.component';
import { AddTimeoffRequestComponent } from './add-timeoff-request/add-timeoff-request.component';
import { LateInRequestComponent } from './late-in-request/late-in-request.component';
import { AddEditLateInRequestComponent } from './add-edit-late-in-request/add-edit-late-in-request.component';
import { AvailableLateInRequestComponent } from './available-late-in-request/available-late-in-request.component';
import { TimePunchesComponent } from './time-punches/time-punches.component';
import { ReviewRequestComponent } from './review-request/review-request.component';
import { AvailableReviewRequestComponent } from './available-review-request/available-review-request.component';
import { AddEditReviewRequestComponent } from './add-edit-review-request/add-edit-review-request.component';
import { AddEditTimePunchesComponent } from './add-edit-time-punches/add-edit-time-punches/add-edit-time-punches.component';
import { TimeTrackingComponent } from '../employeer/time-tracking/time-tracking.component';
import { EditTimeTrackingComponent } from '../employeer/time-tracking/edit-time-tracking/edit-time-tracking.component';

@NgModule({
  declarations: [ 
                OfferComponent, OfferModalComponent,
                AvailableOfferComponent, RequestedOfferComponent, 
                RequestVotComponent, SwapRequestComponent, 
                TransferRequestComponent, TrainingRequestComponent,
                AddEditVotRequestComponent, AvailableSwapRequestComponent,
                AvailableVotRequestComponent, VtoRequestComponent, 
                AddEditVtoRequestComponent, InstantVtoRequestComponent,
                AvailableVtoRequestComponent, PendingRequestComponent,
                PendingVtoRequestComponent, CallInRequestComponent,
                AddEditCallInRequestComponent, EarlyGoRequestComponent, 
                AddEditEarlyGoRequestComponent, AvailableEarlyGoRequestComponent,
                FlexWorkRequestComponent, AvailableFlexWorkRequestComponent, 
                AddEditFlexWorkRequestComponent, InstantFlextWorkRequestComponent, 
                FlexProcessConfirmationComponent, AddEditTransferRequestComponent,
                TimeoffRequestComponent, AddTimeoffRequestComponent,
                LateInRequestComponent, AddEditLateInRequestComponent,
                AvailableLateInRequestComponent,TimePunchesComponent,
                ReviewRequestComponent, AvailableReviewRequestComponent,
                AddEditTimePunchesComponent,
                TimeTrackingComponent,
                EditTimeTrackingComponent
              ],
  imports: [
    SharedModule,
    OfferRoutingModule,
    NgxBootstrapIconsModule.pick(allIcons),
    CKEditorModule,
    DirectiveModule,
    NgMultiSelectDropDownModule
  ],
  exports: [
    AvailableFlexWorkRequestComponent, 
    InstantFlextWorkRequestComponent,
    TimeTrackingComponent,
    EditTimeTrackingComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [OfferService, DepartmentService, ShiftService,
    TermsConditionService, DatePipe, VotRequestService,
    VtoRequestService, FlexRequestService,TransferRequestService]
})
export class OfferModule { }