import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddEditKioskComponent } from './add-edit-kiosk/add-edit-kiosk.component';
import { KioskComponent } from './kiosk.component';


const routes: Routes = [
  {
    path: "",
    component: KioskComponent,
  },
  { path: 'add-edit-kiosk', component: AddEditKioskComponent },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KioskRoutingModule { }
