import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RouteGuard } from './auth/route-guard.service';
import { AuthService } from './auth/auth.service';
import { FormService } from './form.service';


@NgModule({
  declarations: [],
  imports: [
    ToastrModule.forRoot(),
    HttpClientModule,
    ModalModule.forRoot(),
  ],
  providers: [
    RouteGuard,
    AuthService,
    FormService
  ]
})
export class CoreModule { }
