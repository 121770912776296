import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Offer } from 'src/app/models/offer.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Constants } from 'src/app/constant/constants';
import { Role, OfferStatus } from 'src/app/models/role-model';
import { ToastrService } from 'ngx-toastr';
import { OfferService } from 'src/app/service/offer.service';
import { DatePipe } from '@angular/common';
import { UtilityService } from './../../service/utility.service';

@Component({
  selector: 'app-available-swap-request',
  templateUrl: './available-swap-request.component.html',
  styleUrls: ['./available-swap-request.component.scss']
})
export class AvailableSwapRequestComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  availableOffer: Offer;
  availableList = [];
  modalRef: BsModalRef;
  isConfirmed = false;
  totalItems: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  deleteId: string;
  role: number;
  roleEnum = Role;
  offerStatus = OfferStatus;
  acceptOfferId: number;
  searchSort: any;
  columns = [];
  isShowLink = false;

  constructor(
    private modalService: BsModalService,
    private toaster: ToastrService,
    private offerService: OfferService,
    private datepipe: DatePipe,
    private utilityService:UtilityService) {
    this.columns = ['dateToWorkDateStr', 'dateToSkipDateStr', 'shiftToSkipTitle', 'offerStatusStr'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.isConfirmed = false;
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getAvailableOfferList(this.page.pageNumber + 1);
  }

  getAvailableOfferList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.isShowLink = true;
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      this.offerService.getAvailableOfferList(currentPage)
        .then((res: any) => {
          if(JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))){
            this.utilityService.showLoading();
          }
          if (res['Success']) {
            this.availableList = res.Data.results;
            this.totalItems = res.Data.totalNumberOfRecords;
          } else {
            this.availableList = [];
            this.totalItems = 0;
          }
        }, err => { this.availableList = []; });
    }
  }

  openModal(template: TemplateRef<any>, type: any, data: any) {
    this.acceptOfferId = data.offerId;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.isShowLink = true;
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.isShowLink = false;
        this.clear();
      }
    }
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }

  accept(id) {
    this.offerService.acceptOffer(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.OFFER_ACCEPT_MSG);
        this.setPage({ offset: 0 });
      } else {
        this.toaster.error(res['Message']);
      }
      this.closeModal(true);
    }, err => { });
  }

  setnewDate(date) {
    const dateObj = {
      year: +(this.datepipe.transform(date, 'yyyy')),
      month: +(this.datepipe.transform(date, 'MM')),
      day: +(this.datepipe.transform(date, 'dd'))
    };
    return new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day']);
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  clear() {
    this.isShowLink = false;
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  filterData() {
    this.offerService.availableOfferDataFilter(this.searchSort).then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.availableList = res['data'];
        this.totalItems = res['recordsFiltered'];
      } else { this.availableList = []; this.totalItems = 0; }
    }, err => { this.availableList = []; this.totalItems = 0; });
  }

  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }
}
