import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Constants, PunchTypeEnum } from "src/app/constant/constants";
import { FormService } from "src/app/core/form.service";
import { timePunchModel } from "src/app/models/time-punch.model";
import { OfferService } from "src/app/service/offer.service";
import { UtilityService } from "src/app/service/utility.service";

@Component({
  selector: "app-add-edit-time-punches",
  templateUrl: "./add-edit-time-punches.component.html",
  styleUrls: ["./add-edit-time-punches.component.scss"],
})
export class AddEditTimePunchesComponent implements OnInit {
  @Output() closeRequest = new EventEmitter<string>();
  @Input() timePunchModel: timePunchModel;
  addTimePunchesForm: FormGroup;
  public messageList: any = new timePunchModel();
  today = new Date();
  punchTypeList = PunchTypeEnum;
  noChangeDetect: boolean = false;
  noChangeTimeDetect: boolean = false;
  noChangeDateDetect: boolean = false;
  public initalValues;
  constructor(
    private toaster: ToastrService,
    private utility: UtilityService,
    private offerService: OfferService,
    private formService: FormService,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.initializeTimePunchesForm();
    this.initializeMessages();
  }
  async initializeTimePunchesForm() {
    this.addTimePunchesForm = new FormGroup({
      TimePunchesId: new FormControl(
        !!this.timePunchModel ? this.timePunchModel.TimePunchesId : null
      ),
      PunchTime: new FormControl(
        !!this.timePunchModel ? new Date(this.timePunchModel.PunchTime) : null,
        Validators.required
      ),
      OldDate: new FormControl(
        !!this.timePunchModel ? new Date(this.timePunchModel.PunchTime) : null
      ),
      NewDate: new FormControl(
        !!this.timePunchModel ? new Date(this.timePunchModel.PunchTime) : null
      ),
      PunchTimeHoursMinutes: new FormControl(
        !!this.timePunchModel
          ? new Date(this.timePunchModel.PunchTime)
          : new Date(),
        Validators.required
      ),
      OldPunchType: new FormControl(
        !!this.timePunchModel ? this.timePunchModel.PunchType : ""
      ),
      PunchType: new FormControl(
        !!this.timePunchModel ? this.timePunchModel.PunchType : "",
        Validators.required
      ),
      reason: new FormControl("", Validators.required),
    });
    if (this.addTimePunchesForm.controls.PunchType.value == 3) {
      this.addTimePunchesForm.controls.PunchType.setValue(1);
    } else if (this.addTimePunchesForm.controls.PunchType.value == 4) {
      this.addTimePunchesForm.controls.PunchType.setValue(2);
    }
    this.initalValues = this.addTimePunchesForm.value;
  }

  initializeMessages() {
    this.messageList.PunchTime = {
      required: Constants.VALIDATION_MSG.PUNCHESBYHR.PUNCH_TIME,
    };
    this.messageList.PunchType = {
      required: Constants.VALIDATION_MSG.PUNCHESBYHR.PUNCH_TYPE,
    };
    this.messageList.PunchTimeHoursMinutes = {
      invalid: Constants.VALIDATION_MSG.PUNCHESBYHR.PUNCH_TIME_BYHRS,
    };
    this.messageList.reason = {
      invalid: Constants.VALIDATION_MSG.PUNCHESBYHR.REASON,
    };
  }
  closePopUp() {
    this.closeRequest.emit();
  }
  control(controlName: string): AbstractControl {
    return this.addTimePunchesForm.get(controlName);
  }
  value(controlName: string) {
    return this.control(controlName).value;
  }
  convertDateTime(date1, date2) {
    console.log(date1, date2);
    let newDate = new Date(date1);
    return this.datepipe.transform(
      new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        date2.getHours(),
        date2.getMinutes(),
        0,
        0
      ),
      "yyyy-MM-dd HH:mm"
    );
  }
  onDetectChange() {
    if (!this.timePunchModel) {
      this.noChangeDetect = true;
      return;
    }
    if (
      this.initalValues.punchType !=
      this.addTimePunchesForm.controls.PunchType.value
    ) {
      this.noChangeDetect = true;
    } else {
      this.noChangeDetect = false;
    }
  }
  onTimeChange($event) {
    if (!this.timePunchModel) {
      this.noChangeDetect = true;
      return;
    }
    if (
      this.initalValues.PunchTimeHoursMinutes.getTime() !=
      this.addTimePunchesForm.controls.PunchTimeHoursMinutes.value.getTime()
    ) {
      this.noChangeTimeDetect = true;
    } else {
      this.noChangeTimeDetect = false;
    }
  }
  onDetectInputChange($event) {
    if (!this.timePunchModel) {
      this.noChangeDetect = true;
      return;
    }
    if (
      this.initalValues.PunchTime.toISOString() != $event.toISOString() &&
      !$event.target
    ) {
      this.noChangeDateDetect = true;
    } else {
      this.noChangeDateDetect = false;
    }
  }
  save() {
    if (
      !!this.timePunchModel &&
      this.initalValues.PunchTime.toISOString() ==
        this.addTimePunchesForm.controls.PunchTime.value.toISOString() &&
      this.initalValues.PunchType ==
        this.addTimePunchesForm.controls.PunchType.value &&
      this.initalValues.PunchTimeHoursMinutes.getTime() ==
        this.addTimePunchesForm.controls.PunchTimeHoursMinutes.value.getTime()
    ) {
      this.toaster.success(Constants.TIME_PUNCHES_NO_CHANGE_DETECTED_MSG);
      this.closeRequest.emit("Close");
      return;
    }
    this.formService.markFormGroupTouched(this.addTimePunchesForm);
    if (this.addTimePunchesForm.invalid) {
      return;
    }
    let punchTimeHoursMinutes =
      this.addTimePunchesForm.controls.PunchTimeHoursMinutes.value;
    let PunchTime = this.convertDateTime(
      this.addTimePunchesForm.controls.PunchTime.value,
      punchTimeHoursMinutes
    );
    if (!!this.timePunchModel) {
      let oldPunchTime = this.convertDateTime(
        this.addTimePunchesForm.controls.OldDate.value,
        this.addTimePunchesForm.controls.OldDate.value
      );
      this.addTimePunchesForm.controls.OldDate.setValue(oldPunchTime);
    }
    this.addTimePunchesForm.controls.NewDate.setValue(PunchTime);
    const saveMethod = !!this.timePunchModel
      ? this.offerService.userEditRequest(this.addTimePunchesForm.value)
      : this.offerService.userAddRequest(this.addTimePunchesForm.value);
    saveMethod.then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(Constants.TIME_PUNCHES_UPDATE_SUCCESS_MSG);
          this.closeRequest.emit("Close");
        } else {
          this.utility.showErrorToast(res["Message"]);
        }
      },
      (err) => {}
    );
  }
}
