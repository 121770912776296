import { Time } from "@angular/common";

export class timeOffRequestModel {
  TimeOffUserRequestId: number;
  UserName: string;
  TimeOffStartTime: Date;
  TimeOffEndTime: Date;
  TimeOffStartDate: Date;
  TimeOffStartDateStr: Date;
  TimeOffDifference:Date;
  TimeOffDifferenceStr:Date;
  TimeOffEndDate: Date;
  TimeOffEndDateStr: Date;
  Status: number;
  IsActive: boolean;
  CreatedBy: number;
  CreatedDate: Date;
  ModifiedDate: Date;
  UserId: number;
  ShiftId: number; 
  TimeOffId: number;
  DepartmentId: number;
  TimeOffQuantity:number;
  Duration: number;
}
