import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Constants, OfferTypesEnum, ReviewTypeEnum, SettingType, SubscriptionType } from 'src/app/constant/constants';
import { FormService } from 'src/app/core/form.service';
import { Offer } from 'src/app/models/offer.model';
import { CallInRequestService } from 'src/app/service/callInRequest.service';
import { UtilityService } from 'src/app/service/utility.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Role } from 'src/app/models/role-model';
import { ReviewService } from 'src/app/service/review.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-edit-review-request',
  templateUrl: './add-edit-review-request.component.html',
  styleUrls: ['./add-edit-review-request.component.scss']
})
export class AddEditReviewRequestComponent implements OnInit {
  @ViewChild('confirmationPopup', { static: false }) confirmationPopup: TemplateRef<any>;
  @Input() Offer: Offer;
  @Input() view: boolean;
  @Output() closeRequest = new EventEmitter<boolean>();
  public messageList: any = new Offer();
  public reviewForm: FormGroup;
  // reviewType = [];
  selfRatingFlag: any;
  managerRatingFlag: any;
  departmentId: number;
  companyId: number;
  shiftId: number;
  roleEnum = Role;
  companyList = [];
  role: number;
  text = 'Are you sure ?'
  settingType = SettingType
  Editor = ClassicEditor;
  isReadOnly = false;
  isConfirm = false;
  cancleLogMsg: string = 'successfully prevented Review.';
  reviewType = ReviewTypeEnum;
  employeeList = [];
  editManager = false;
  viewForm = true;
  addManager = false;
  IsActive = false;
  moduleId: number;
  NineBlockerRatingSystemForEmployeeReview = false;
  NineBlockerRatingVisibleToEmployee = false;
  visibleToEmployee = false;
  isManagerReleaseReview = false;
  NineBlockerSystemHorizontalScale = false;
  NineBlockerSystemVerticalScale = false;
  viewMode: boolean;
  isConfirmed = false;
  Id: string;
  modalRef: BsModalRef;
  editUser = false;
  userName: string;
  vericalScaleText: string;
  horizontalScaleText: string;
  managerNote:string;
  saveInProgress=false;
  constructor(private utility: UtilityService,
    private toaster: ToastrService,
    private formService: FormService,
    private callInRequstService: CallInRequestService,
    private reviewService: ReviewService,
    private modalService: BsModalService,
    private utilityService: UtilityService) { }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.userName = localStorage.getItem(Constants.USERNAME);

    if (this.role === this.roleEnum.user) {
      this.editUser = true;
      if (this.Offer == null) {
        this.viewForm = true;
        this.editManager = false;
      } else {
        this.editManager = false;
      }
    }
    if (this.role === this.roleEnum.manager) {
      this.isManagerReleaseReview = true;
      // this.getUserList();
      this.viewForm = true;
      if (this.Offer == null) {
        this.viewForm = false;
        this.editManager = false;
        this.addManager = true;
      } else {
        this.viewForm = true;
        this.editManager = true;
      }
    }
    this.initializeReviewForm();
    this.initializeMessages();

  }

  async initializeReviewForm() {
    this.reviewForm = new FormGroup(
      {
        Id: new FormControl(!!this.Offer ? this.Offer.Id : 0),
        Status: new FormControl(!!this.Offer ? this.Offer.Status : 0),
        // Status: new FormControl(!!this.Offer ? this.Offer.Status : 1),
        reviewType: new FormControl(!!this.Offer ? this.Offer.ReviewType : "", Validators.required),
        companyId: new FormControl(this.companyId),
        departmentId: new FormControl(this.departmentId),
        offerType: new FormControl(12),
        Strength: new FormControl(!!this.Offer ? this.Offer.Strength : ''),
        Opportunities: new FormControl(!!this.Offer ? this.Offer.Opportunities : ''),
        OtherNote: new FormControl(!!this.Offer ? this.Offer.OtherNote : ''),
        ManagerNote: new FormControl(!!this.Offer ? this.Offer.ManagerNote : ''),
        SelfRating: new FormControl(!!this.Offer ? this.Offer.SelfRating : ''),
        ManagerRating: new FormControl(!!this.Offer ? this.Offer.ManagerRating : ''),
        IsVisibleToEmployee: new FormControl(!!this.Offer ? this.Offer.IsVisibleToEmployee : false),
        CreatedDate:new FormControl(!!this.Offer ? this.Offer.CreatedDate : null),
        // IsVisibleToEmployee: new FormControl(1),
        userName: new FormControl(
          !!this.Offer ? this.Offer.UserName : "",
          Validators.required
        ),
      },
    );
    if (!!this.reviewForm.controls.SelfRating.value) {
      this.selfRatingFlag = this.reviewForm.controls.SelfRating.value
    }
    if (!!this.reviewForm.controls.ManagerRating.value) {
      this.managerRatingFlag = this.reviewForm.controls.ManagerRating.value
    }
    if (this.role === this.roleEnum.user) {
      this.reviewForm.controls.userName.setValue(this.userName);
    }
    if (!!this.Offer) {
      this.reviewForm.controls.userName.disable();
      this.visibleToEmployee = this.Offer.IsVisibleToEmployee;
      this.managerNote = this.Offer.ManagerNote;
    }
    if (this.reviewForm.controls.Status.value === 3) {
      this.isManagerReleaseReview = true;
    }
    if (this.role === this.roleEnum.user&&this.reviewForm.controls.Status.value === 2) {
      this.saveInProgress = false;
    }
    if (this.role === this.roleEnum.user&&this.reviewForm.controls.Status.value === 3) {
      this.saveInProgress = true;
    }
    if (this.role != this.roleEnum.user){
      this.saveInProgress = true;
    }
    await this.getSettingByCompanyIDAsync();
  }
  initializeMessages() {
    this.messageList.reviewType = {
      required: Constants.VALIDATION_MSG.REVIEW_TYPE_REQUIRED,
    };
    this.messageList.userName = {
      required: Constants.VALIDATION_MSG.TIME_OFF.USERNAME_REQUIRED,
    };

  }
  reviewChange(event) { }
  checkUsername() {
    this.reviewService.getAllUserList(this.reviewForm.controls.userName.value, this.companyId).then(res => {

      if (res['Success']) {
     
      } else {
        this.toaster.error(Constants.VALIDATION_MSG.USER_INVALID);
      }
      this.utilityService.hideLoading();
    }, err => {
      this.utilityService.hideLoading();
    });
  }
  onClickSelfRating(value) {
    this.reviewForm.controls.SelfRating.setValue(value);
    this.selfRatingFlag = value;
  }

  onClickManagerRating(value) {
    this.reviewForm.controls.ManagerRating.setValue(value);
    this.managerRatingFlag = value;

  }
  cickBack() {
    this.formService.markFormGroupTouched(this.reviewForm);
    if (this.reviewForm.invalid) { return; }
    else if (this.reviewForm.controls.ManagerNote.value == "") {
      this.toaster.error(Constants.VALIDATION_MSG.MANAGER_NOTE_REQUIRED);
      return;
    }
    this.isConfirmed = true;
    this.reviewForm.controls.Status.setValue(1);
  }
  saveProgress(value) {
    this.formService.markFormGroupTouched(this.reviewForm);
    if (this.reviewForm.invalid) {
      return;
    }
    if (this.role === this.roleEnum.user) {
      this.reviewForm.controls.Status.setValue(1);
    } else {
      this.reviewForm.controls.Status.setValue(2);
    }
    this.submit();
  }

  async getSettingByCompanyIDAsync() {
    let module = SubscriptionType.filter((item) => {
      return item.id === OfferTypesEnum.EmployeeReviewModule;
    });
    this.moduleId = module[0].id;
    await this.callInRequstService.getSettingByCompanyID(this.moduleId, Number(localStorage.getItem(Constants.COMPANYID))).then((res: any) => {
      if (res['Success']) {
        if (res.Data.length > 0) {
          res.Data.map((item) => {
            if (item.SettingType === this.settingType[16].value) {
              item.Name = "9 blocker rating system for employee review";
              if (item.Enable == true) {
                this.NineBlockerRatingSystemForEmployeeReview = true;
              }
              else {
                this.NineBlockerRatingSystemForEmployeeReview = false;
              }
            }
            else if (item.SettingType === this.settingType[19].value) {
              item.Name = "9 blocker rating visible to employee";
              if (item.Enable == true) {
                this.NineBlockerRatingVisibleToEmployee = true;
              }
              else {
                this.NineBlockerRatingVisibleToEmployee = false;
              }
            }
            else if (item.SettingType === this.settingType[17].value) {
              item.Name = "9 blocker system horizontal scale";
              if (item.Enable == true) {
                this.NineBlockerSystemHorizontalScale = true;
                this.horizontalScaleText=res.Data[1].Description;
              }
              else {
                this.NineBlockerSystemHorizontalScale = false;
              }
            }
            else if (item.SettingType === this.settingType[18].value) {
              item.Name = "9 blocker system vertical scale";
              if (item.Enable == true) {
                this.NineBlockerSystemVerticalScale = true;
                this.vericalScaleText=res.Data[2].Description;
              }
              else {
                this.NineBlockerSystemVerticalScale = false;
              }
            }
          });
        }
      } else { }
    }, err => { });

  }
  save() {
    this.formService.markFormGroupTouched(this.reviewForm);
    if (this.reviewForm.invalid) {
      return;
    }
    if (this.role === this.roleEnum.manager) {
      this.reviewForm.controls.Status.setValue(3);

    } else {
      this.reviewForm.controls.Status.setValue(2);
    }
    this.isConfirmed = true;
  }
  submit() {
    this.reviewForm.controls.userName.enable();
    if (this.role === this.roleEnum.user && this.Offer==null) {
      this.reviewForm.controls.IsVisibleToEmployee.setValue(false);
    }
    else {
      if (!this.Offer)
        this.reviewForm.controls.IsVisibleToEmployee.setValue(this.visibleToEmployee);
    }
    const saveMethod = this.reviewForm.controls.Id.value > 0 ? this.reviewService.updateReviewRequestOffer(this.reviewForm.value) :
      this.reviewService.addReviewRequest(this.reviewForm.value);
    saveMethod.then(res => {
      if (res['Success']) {
        this.toaster.success(this.reviewForm.controls.Id.value > 0 ?
          Constants.REVIEW_REQUEST_UPDATE_SUCCESS_MSG : Constants.REVIEW_REQUEST_ADD_SUCCESS_MSG);
        this.closeRequest.emit(true);
      } else {
        this.utility.showErrorToast(res['Message']);
      }
    }, err => { })
  }
  close(event) {
    this.isConfirmed = false;
    this.reviewService.cancelReviewUserRequest(12, this.cancleLogMsg);
  }
  closePopUp() {
    this.closeRequest.emit(true);
  }

  control(controlName: string): AbstractControl {
    return this.reviewForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }
  displayToEmployee(event) {
    if (event.target.checked) {
      this.IsActive = true;
      this.visibleToEmployee = true;
    } else if (!event.target.checked) {
      this.IsActive = false;
      this.visibleToEmployee = false;
    }
  }

  saveConfirmation(template: TemplateRef<any>, data: any) {
    this.isConfirm = data;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-md',
      backdrop: 'static'
    });
  }

}
