import { NgModule } from '@angular/core';

import { DepartmentRoutingModule } from './department-routing.module';
import { DepartmentComponent } from './department.component';
import { SharedModule } from '../shared/shared/shared.module';
import { AddEditComponent } from './add-edit/add-edit.component';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { DepartmentService } from '../service/department.service';
import { CompanyService } from '../service/company.service';
import { DirectiveModule } from '../shared/shared/directive/directive.module';
import { DepartmentListComponent } from './department-list/department-list.component';
import { SubDepartmentListComponent } from './sub-department-list/sub-department-list.component';
import { AddEditSubDepartmentComponent } from './add-edit-sub-department/add-edit-sub-department.component';


@NgModule({
  declarations: [DepartmentComponent, AddEditComponent, DepartmentListComponent, SubDepartmentListComponent, AddEditSubDepartmentComponent],
  imports: [
    SharedModule,
    DepartmentRoutingModule,
    NgxBootstrapIconsModule.pick(allIcons),
    DirectiveModule
  ],
  providers: [DepartmentService, CompanyService]
})
export class DepartmentModule { }
