import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from '../constant/constants';

@Injectable({
  providedIn: 'root'
})
export class LateInService {

  constructor(private apiService: ApiService) { }

  addLateInRequest(data) {
    return this.apiService.post(
      `${API.LATE_IN_REQUEST.addLateInRequest}`,
      data,
      true,
    );
  }

  getReasons() {
    return this.apiService.get(
      `${API.CALL_OFF_REQUEST.callOffReasons}?reasonType=2`,
      true,
      true,
    );
  }
  checkWeeklyCallOffRequest(startdate, Enddate, offerId) {
    return this.apiService.post(`${API.CALL_OFF_REQUEST.checkMonthlyCallOffRequest}?startdate=${startdate}&Enddate=${Enddate}&offerId=${offerId}&offerType=${7}`
      , [6,7]
      , true);
  }

  getLateInRequestList(currentPage, rowsOnPage,isStopLoader=true) {
    return this.apiService.get(
      `${API.LATE_IN_REQUEST.getUserLateInRequestList}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      isStopLoader,
    );
  }
  updateLateInRequestOffer(params) {
    return this.apiService.put(`${API.LATE_IN_REQUEST.updateLateInRequest}`, params, true);
  }
  userLateInOfferDataFilter(data) {
    return this.apiService.post(`${API.LATE_IN_REQUEST.availableManagerLateInDataFilter}`, data, true);
  }
  // checkWeeklyCallOffRequest(startdate, Enddate, offerId) {
  //   return this.apiService.post(`${API.CALL_OFF_REQUEST.checkMonthlyCallOffRequest}?startdate=${startdate}&Enddate=${Enddate}&offerId=${offerId}&offerType=${7}`
  //     , [6,7]
  //     , true);
  // }



  getLateInAvailableManagerRequestList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.LATE_IN_REQUEST.avilableManagerLateInOffers}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }
  approveManagerLateInOffers(id) {
    return this.apiService.put(`${API.LATE_IN_REQUEST.approveManagerLateInOffers}?offerID=${id}`, null, true);
  }
  rejectManagerLateInOffers(id) {
    return this.apiService.put(`${API.LATE_IN_REQUEST.rejectManagerLateInOffers}?offerID=${id}`, null, true);
  }
  managerAvailableLateInOfferDataFilter(data) {
    return this.apiService.post(`${API.LATE_IN_REQUEST.availableManagerLateInDataFilter}`, data, true);
  }

  getLateInClosedManagerRequestList(currentPage, rowsOnPage,isStopLoader = true) {
    return this.apiService.get(
      `${API.LATE_IN_REQUEST.closedManagerLateInOffers}?companyId=${localStorage.getItem(Constants.COMPANYID)}&page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      isStopLoader,
    );
  }
  managerClosedLateInOfferDataFilter(data) {
    return this.apiService.post(`${API.LATE_IN_REQUEST.managerClosedLateInDataFilter}`, data, true);
  }


  getLateInPendingHrRequestList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.LATE_IN_REQUEST.hrPendingLateInRequests}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }
  getLateInClosedHrRequestList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.LATE_IN_REQUEST.hrClosedRequests}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }
  hrClosedLateInOfferDataFilter(data) {
    return this.apiService.post(`${API.LATE_IN_REQUEST.hrClosedLateInOfferDataFilter}`, data, true);
  }
  hrPendingLateInOfferDataFilter(data) {
    return this.apiService.post(`${API.LATE_IN_REQUEST.hrPendingLateInOfferDataFilter}`, data, true);
  }
  proceedHroffer(offer) {
    return this.apiService.put(
      `${API.LATE_IN_REQUEST.proceedHroffer}`,
      offer,
      true,
    );
  }
  RejectLateInOffers(id) {
    return this.apiService.put(`${API.LATE_IN_REQUEST.rejectHrLateInOffers}?offerID=${id}`, null, true);
  }
  cancelLateInUserRequest(offerType,message) {
    return this.apiService.post(
      `${API.OFFER_LOG.OfferLogEntry}?offerType=${offerType}&message=${message}`,
      null,
      true,
    );
  }

  approvalLateInDataFilter(data) {
    return this.apiService.post(`${API.LATE_IN_REQUEST.approveLateInDataFilter}`, data, true);
  }

  closedLateInDataFilter(data) {
    return this.apiService.post(`${API.LATE_IN_REQUEST.closedLateInDataFilter}`, data, true);
  }

  downloadPendingExcelFile(data) {
    return this.apiService.post(`${API.LATE_IN_REQUEST.downloadPendingExcelFile}`, data, true);
  }

  downloadClosedExcelFile(data) {
    return this.apiService.post(`${API.LATE_IN_REQUEST.downloadClosedExcelFile}`, data, true);
  }
  lateInManagerAvailableRequestDataFilter(data, date) { //dataFilter
    return this.apiService.post(`${API.LATE_IN_REQUEST.lateInManagerAvailableRequestDataFilter}?date=${date}`, data, true);
  }
  lateInAvailableRequestOfferDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.LATE_IN_REQUEST.availableLateInOfferDataFilter}`, data, true);
  }
}
