import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmployeeRoster } from './../../models/EmployeeRoster.model';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Constants, emplyeeTypeList } from 'src/app/constant/constants';
import { ToastrService } from 'ngx-toastr';
import { FormService } from 'src/app/core/form.service';
import { EmployeeRosterService } from './../../service/EmployeeRoster.service';

@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent
 implements OnInit {
  @Input() userValidation: EmployeeRoster;
  @Output() close = new EventEmitter<boolean>();
  public messageList: any = new EmployeeRoster();
  userValidationForm: FormGroup;
  companyId: number;
  employeeTypeList = emplyeeTypeList;
  isHourEnable = false;

  constructor(private formService: FormService,
    private toaster: ToastrService,
    private employeeRosterService: EmployeeRosterService) {
  }

  ngOnInit() {
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.initializeUserForm();
    this.initializeMessages();
  }
  initializeUserForm() {
    this.userValidationForm = new FormGroup({
      employeeRosterId: new FormControl(!!this.userValidation ? this.userValidation.employeeRosterId : 0),
      username: new FormControl(!!this.userValidation ? this.userValidation.username : '', Validators.required),
      employeeId: new FormControl(!!this.userValidation ? this.userValidation.employeeId : '', Validators.required),
      companyId: new FormControl(!!this.userValidation ? this.userValidation.companyId : this.companyId),
      createdDate: new FormControl(!!this.userValidation ? this.userValidation.createdDate : ''),
      employeeType: new FormControl(!!this.userValidation ? this.userValidation.EmployeeType : this.employeeTypeList[0].id, Validators.required),
      ptoEligible: new FormControl(!!this.userValidation ? this.userValidation.PTOEligible : true),
      holidayPayEligible: new FormControl(!!this.userValidation ? this.userValidation.HolidayPayEligible : ''),
      otEligible: new FormControl(!!this.userValidation ? this.userValidation.OTEligible : true),
      hours: new FormControl(!!this.userValidation ? this.userValidation.Hours : ''),
    });
    if(!!this.userValidation && this.userValidation.employeeRosterId > 0 && this.userValidation.HolidayPayEligible){
      this.isHourEnable = true
      this.userValidationForm.controls.hours.setValidators([Validators.pattern(Constants.REGEX.HOURS_PATTERN),Validators.required]);
      this.userValidationForm.controls.hours.updateValueAndValidity();
    } 
  }

  initializeMessages() {
    this.messageList.Username = {
      required: Constants.VALIDATION_MSG.USER_VALIDATION.USERNAME,
    };
    this.messageList.EmployeeId = {
      required: Constants.VALIDATION_MSG.USER_VALIDATION.EMPLOYEEID,
    };
    this.messageList.employeeType = {
      required: Constants.VALIDATION_MSG.USER_VALIDATION.EMPLOYEETYPE,
    };
    this.messageList.ptoEligible = {
      required: Constants.VALIDATION_MSG.USER_VALIDATION.PTOELIGIBLE,
    };
    this.messageList.holidayPayEligible = {
      required: Constants.VALIDATION_MSG.USER_VALIDATION.HOLIDAYPAYELIGIBLE,
    };
    this.messageList.otEligible = {
      required: Constants.VALIDATION_MSG.USER_VALIDATION.OTELIGIBLE,
    };
    this.messageList.Hours = {
      required: Constants.VALIDATION_MSG.USER_VALIDATION.HOURS,
      pattern:Constants.VALIDATION_MSG.USER_VALIDATION.INVALID_HOURS
    };
  }

  control(controlName: string): AbstractControl {
    return this.userValidationForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }
  cancel() {
    this.close.emit(false);
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.userValidationForm);
    if (this.userValidationForm.invalid) {
      return;
    }
    const saveMethod = this.userValidationForm.controls.employeeRosterId.value > 0 ? this.employeeRosterService.updateUserValidation(this.userValidationForm.value)
      : this.employeeRosterService.addUserValidation(this.userValidationForm.value);
    saveMethod.then((res) => {
      if (res['Success']) {
        this.toaster.success(this.userValidationForm.controls.employeeRosterId.value > 0 ? Constants.USER_VALIDATION_UPDATE_SUCCESS_MSG : Constants.USER_VALIDATION_ADD_SUCCESS_MSG);
        this.close.emit(true);
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { this.close.emit(false); });
  }

  onCheckBoxChange(event) {
    let checked = event.target.checked;
    if (checked) {
      this.isHourEnable = true;
      this.userValidationForm.controls.hours.setValue(null);
      this.userValidationForm.controls.hours.setValidators([Validators.pattern(Constants.REGEX.HOURS_PATTERN),Validators.required]);
      this.userValidationForm.controls.hours.updateValueAndValidity();
    } else {
      this.isHourEnable = false;
      this.userValidationForm.controls.hours.setValue(null);
      this.userValidationForm.controls.hours.setValidators(null);
      this.userValidationForm.controls.hours.updateValueAndValidity();
    }
  }
  onemployeeTypeChange(event) {
    let dropdownValue = event.target.value;
    if (dropdownValue == 1) {
      this.userValidationForm.controls.ptoEligible.setValue(true);
      this.userValidationForm.controls.otEligible.setValue(true);
    } else {
      this.userValidationForm.controls.ptoEligible.setValue(false);
      this.userValidationForm.controls.otEligible.setValue(false);
    }
  }

}
