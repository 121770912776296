import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Constants } from '../constant/constants';
import { CompanyService } from '../service/company.service';
import { Router } from '@angular/router';
import { Role } from '../models/role-model';
import { InvoiceService } from '../service/invoice.service';
import { UtilityService } from '../service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  @ViewChild('currencySummaryCell', { static: false }) nameSummaryCell: TemplateRef<any>;
  companyList = [];
  totalItems: any;
  modalRef: BsModalRef;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  role: number;
  roleEnum = Role;
  invoiceCompanyList = [];
  isShowLink = false;
  searchSort: any;
  columns = [];
  totalDueAmount = 0;
  totalPaidAmount = 0;
  companyId: number;
  invoiceData: any;
  isConfirmed = false;
  invoiceId: any;
  invoiceConfirmMsg = Constants.INVOICE_CONFIRM_MSG;

  constructor(private companyService: CompanyService, private modalService: BsModalService,
    private router: Router, private invoiceService: InvoiceService, private utilityService: UtilityService) {
    this.columns = ['invoiceNumber', 'Company.companyName', 'invoiceDate', 'statusStr', 'amount', 'paidOnDate'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    if (this.role === this.roleEnum.purchaser) {
      this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
      this.setPage({ offset: 0 });
    } else {
      this.getCompanyList();
    }
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getInvoiceByCompany(this.page.pageNumber + 1);
  }

  getCompanyList() {
    this.companyService.getCompanyListWithOutPagination()
      .then((res: any) => {
        if (res['Success']) {
          this.companyList = res.Data;
        } else { this.companyList = []; }
      }, err => { this.companyList = []; });
  }

  openModal(template: TemplateRef<any>, data: any) {
    this.invoiceData = data;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }

  onCompanyChange(event: any) {
    const id = Number(event.currentTarget.value);
    this.companyId = id;
    this.clear();
    this.invoiceCompanyList = [];
    this.totalItems = 0;
    this.setPage({ offset: 0 });
  }

  getInvoiceByCompany(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.isShowLink = true;
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      this.invoiceService.getInvoiceListByCompany(this.companyId, currentPage).then(res => {
        if (res['Success']) {
          this.invoiceCompanyList = res['Data'].results;
          this.totalItems = res['Data'].totalNumberOfRecords;
          this.caclulateSumm();
        }
      }, err => { this.totalItems = 0});
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.isShowLink = true;
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.isShowLink = false;
        this.clear()
      }
    }
  }

  filterData() {
    if (!!this.companyId) {
      this.invoiceService.dataFilter(this.searchSort, this.companyId).then(res => {
        if (res['data'] && res['data'].length > 0) {
          this.invoiceCompanyList = res['data'];
          this.totalItems = res['recordsFiltered'];
        } else { this.invoiceCompanyList = []; this.totalItems = 0; }
      }, err => { this.invoiceCompanyList = []; this.totalItems = 0; });
    } else {
      this.utilityService.showErrorToast(Constants.INVOICE_SEARCH_MSG);
    }
  }

  clear() {
    this.isShowLink = false;
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    if (!!this.companyId) {
      this.setPage({ offset: 0 });
    }
  }

  emptySumm() {
    return null;
  }

  caclulateSumm() {
    this.totalDueAmount = 0;
    this.totalPaidAmount = 0;
    this.invoiceCompanyList.forEach((item) => {
      if (item.status === 0) {
        this.totalDueAmount = Number(parseFloat(this.totalDueAmount + item.amount).toFixed(2));
      } else {
        this.totalPaidAmount = Number(parseFloat(this.totalPaidAmount + item.amount).toFixed(2));
      }
    });
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  onClickPaid(data) {
    this.invoiceId = data.invoiceId;
    this.invoiceData = data;
    this.isConfirmed = true;
  }

  statusChange() {
    this.isConfirmed = false;
    this.invoiceData.status = 1;
    this.invoiceData.paidOnDate = new Date();
    this.invoiceService.updateInvoice(this.invoiceData).then(res => {
      if (res['Success']) {
        this.utilityService.showSuccessToast(Constants.INVOICE_PAIDED_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      } else { this.utilityService.showErrorToast(res['Message']); }
    }, err => { });
  }

  print(data) {
    this.invoiceService.printInvoiceData(data.invoiceId).then(res => {
      window.open(environment.BLOB_URL + res['Message'], '_blank');
    }, err => { });
  }

  close(event) {
    this.isConfirmed = false;
  }

  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }
}
