import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  NgZone,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { EarlyGoOffer, Offer } from "src/app/models/offer.model";
import { DepartmentService } from "src/app/service/department.service";
import {
  bsConfig,
  Constants,
  OfferTypesEnum,
  SettingType,
  SubscriptionType,
  timeList,
  vtoType,
} from "src/app/constant/constants";
import { FormService } from "src/app/core/form.service";
import { DatePipe } from "@angular/common";
import { ShiftService } from "src/app/service/shift.service";
import { ToastrService } from "ngx-toastr";
import { EarlyOutService } from "./../../service/earlyOut.service";
import { UtilityService } from "src/app/service/utility.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CallInRequestService } from "src/app/service/callInRequest.service";
import { TermsConditionService } from "src/app/service/terms-condition.service";
import { SettingsService } from "src/app/service/settings.service";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-add-edit-early-go-request",
  templateUrl: "./add-edit-early-go-request.component.html",
  styleUrls: ["./add-edit-early-go-request.component.scss"],
})
export class AddEditEarlyGoRequestComponent implements OnInit {
  [x: string]: any;
  public showSignatureError: boolean = false;
  public signatureUrl: any;
  @ViewChild("confirmationPopup", { static: false })
  confirmationPopup: TemplateRef<any>;
  @ViewChild("startTimeConfirmPopup", { static: false })
  startTimeConfirmPopup: TemplateRef<any>;
  utoMessage: any;
  isFormSubmitted: boolean = false;
  // @ViewChild(SignaturePad,{static:false}) signaturePad: SignaturePad;

  private signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    minWidth: 1,
    canvasWidth: 500,
    canvasHeight: 200,
  };
  @Input() Offer: Offer;
  @Output() closeRequest = new EventEmitter<boolean>();
  isOtherReason = false;
  bsConfig = bsConfig;
  departmentList = [];
  reasonList = [];
  companyId: number;
  currentPage: number = 1;
  role: number;
  shiftEndTime: string;
  shiftStartTime: string;
  departmentId: number;
  shiftId: number;
  isConfirm = false;
  confirmMsg: string;
  IsCoverMyWork: boolean = false;
  modalRef: BsModalRef;
  // suggestionmodalRef: BsModalRef;
  cancleLogMsg: string = "successfully prevented an early out.";
  suggestionMsg: string =
    "It is suggested to preserve UTO as much as you can for emergency purposes. Sorry life threw a curved ball at you today. Let us know if we can help you.";
  startTimeConfirmMsg: string =
    "Early-out screen is used for obtaining approval to leave early from your supervisor. If you intend to call-off for the whole shift, please use Call-Off screen.";
  public messageList: any = new EarlyGoOffer();
  public earlyGoForm: FormGroup;
  counter: number = 0;
  nightShift = false;
  settingData: any;
  todaysDate = new Date();
  offerTypeEnum = OfferTypesEnum;
  tomorrowsDate = new Date(this.todaysDate);
  today = new Date();
  tomorrow = new Date(this.today);
  moduleId: any;
  settingList: any;
  paidTimeOff: any;
  message: string;
  isPaidTimeOff: boolean;
  msgSettingList = SettingType;
  isEarlyOutShown: boolean = false;
  dateToSkip = new Date().toLocaleDateString();
  isUTOError: boolean = false;

  constructor(
    private utility: UtilityService,
    private toaster: ToastrService,
    private shiftService: ShiftService,
    private datepipe: DatePipe,
    private formService: FormService,
    private earlyOutService: EarlyOutService,
    private modalService: BsModalService,
    private callInRequstService: CallInRequestService,
    private settingsService: SettingsService,
    private termsAndConditionService: TermsConditionService,
    private ngZone: NgZone
  ) {
    this.IsCoverMyWork =
      localStorage.getItem(Constants.APP_NAME) === "CoverMyWork" ? true : false;
  }

  async ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.departmentId = Number(localStorage.getItem(Constants.DEPARTMENTID));
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.shiftId = Number(localStorage.getItem(Constants.SHIFTID));
    this.initializeEarlyGoForm();
    this.initializeMessages();
    this.utility.showLoading();
    let module = SubscriptionType.filter((item) => {
      return item.value === "Early Out Module";
    });
    this.moduleId = module[0].id;
    forkJoin([
      this.earlyOutService.getReasons(false),
      this.callInRequstService.getSettingByCompanyID(
        this.moduleId,
        this.companyId,
        false
      ),
      this.termsAndConditionService
      .getTermsConditionListByCompanyId(this.companyId, null,false),
    ]).subscribe((res: any) => {
      //first api response       
      if (res[0]["Success"]) {
        this.reasonList = res[0]["Data"];
      } else {
        this.reasonList = [];
      }

      //second api response
      if (res[1]["Success"]) {
        this.settingList = res[1].Data;
        if (this.settingList.length > 0) {
          this.settingList.map((item) => {
            if (item.SettingType === SettingType[0].value) {
              item.Name = "Paid Time Off";
              this.paidTimeOff = item;
              if (this.value("offerId") == 0) {
                if (this.paidTimeOff.Enable == true) {
                  this.isPaidTimeOff = true;
                  this.message =
                    "All missed time will be covered by your Unpaid Time Off (UTO) balance. If you do not have enough UTO balance to cover the entire absence, Paid Time Off (PTO) will be applied to cover the shortage. It is your responsibility to ensure that you have enough UTO+PTO accumulated to cover this absence.";
                  this.earlyGoForm.controls["IsPaidOff"].setValue(true);
                } else {
                  this.isPaidTimeOff = false;
                  this.message =
                    "All missed time will be covered by your Unpaid Time Off (UTO) balance. It is your responsibility to ensure that you have enough UTO accumulated to cover this absence.";
                  this.earlyGoForm.controls["IsPaidOff"].setValue(false);
                }
              }
            }
          });
        }
      } else {
        this.settingList = [];
      }

      //third api response 
      if (res[2]["Success"]) {
        this.settingData = this.msgSettingList.filter(
          (x) => x.value == Constants.UTO_SYSTEM_FOR_ATTENDANCE
        );
        if (this.settingData.length == 0) {
          this.isEarlyOutShown = false;
          this.message = "";
        } else {
          var templist = res[2]["Data"].filter((x) => x.typeField == 7);

          if (templist.length != 0) {
            //tempbool = true;

            this.settingsService.getSettings(null, this.companyId).then(
              (setResponse) => {
                if (setResponse["Success"]) {
                  this.settingData = setResponse["Data"];
                  let data = this.settingData.filter(
                    (x) =>
                      x.SettingType ==
                        Constants.UTO_SYSTEM_FOR_ATTENDANCE &&
                      x.OfferType == this.offerTypeEnum.EarlyOutModule
                  );
                  if (
                    data[0]["OfferType"] ==
                      this.offerTypeEnum.EarlyOutModule &&
                    data[0]["Enable"] == true
                  ) {
                    this.isEarlyOutShown = true;
                    this.message = data[0]["Description"].replace(
                      /<[^>]*>/g,
                      ""
                    );
                  } else {
                    this.isEarlyOutShown = false;
                    this.message = "";
                  }
                } else {
                  this.settingData = [];
                }
              },
              (err) => {
                this.settingData = [];
              }
            );
          } else {
            this.utility.hideLoading();
          }
        }
      } else {
        this.settingData = [];
      }
    })
    this.tomorrowsDate.setDate(this.todaysDate.getDate() + 1);
    if (this.earlyGoForm.controls.offerId.value > 0) {
      this.dateToSkip = new Date(
        this.earlyGoForm.controls.dateToSkip.value
      ).toLocaleDateString();
    }
    // this.getDepartmentList();
  }

   showMessage() {
    var tempbool = false;
    this.termsAndConditionService
      .getTermsConditionListByCompanyId(this.companyId, null,false)
      .then(
        (response) => {
          if (response["Success"]) {
            this.settingData = this.msgSettingList.filter(
              (x) => x.value == Constants.UTO_SYSTEM_FOR_ATTENDANCE
            );
            if (this.settingData.length == 0) {
              this.isEarlyOutShown = false;
              this.message = "";
            } else {
              var templist = response["Data"].filter((x) => x.typeField == 7);

              if (templist.length != 0) {
                //tempbool = true;

                this.settingsService.getSettings(null, this.companyId).then(
                  (setResponse) => {
                    if (setResponse["Success"]) {
                      this.settingData = setResponse["Data"];
                      let data = this.settingData.filter(
                        (x) =>
                          x.SettingType ==
                            Constants.UTO_SYSTEM_FOR_ATTENDANCE &&
                          x.OfferType == this.offerTypeEnum.EarlyOutModule
                      );
                      if (
                        data[0]["OfferType"] ==
                          this.offerTypeEnum.EarlyOutModule &&
                        data[0]["Enable"] == true
                      ) {
                        this.isEarlyOutShown = true;
                        this.message = data[0]["Description"].replace(
                          /<[^>]*>/g,
                          ""
                        );
                      } else {
                        this.isEarlyOutShown = false;
                        this.message = "";
                      }
                    } else {
                      this.settingData = [];
                    }
                  },
                  (err) => {
                    this.settingData = [];
                  }
                );
              } else {
                this.utility.hideLoading();
                tempbool = false;
              }
            }
          } else {
            this.settingData = [];
          }
        },
        (err) => {
          this.settingData = [];
        }
      );
  }

  ngAfterViewInit() {
    //this.signaturePad.set('minWidth', 5);
    // this.signatureUrl ="";
    // this.signaturePad.clear();
  }

  // drawComplete() {
  //   var signDataUrl = this.signaturePad.toDataURL();
  // }

  // drawStart() {
  //   this.signatureUrl = this.signaturePad.toDataURL()
  // }
   getReasonList() {
     this.earlyOutService.getReasons(false).then(
      (response) => {
        if (response["Success"]) {
          this.reasonList = response["Data"];
        } else {
          this.reasonList = [];
        }
      },
      (err) => {
        this.reasonList = [];
      }
    );
  }

  reasonChange(value: string) {
    if (parseInt(value) === 1) {
      this.isOtherReason = true;
      this.earlyGoForm.controls.OtherReason.setValidators(Validators.required);
    } else {
      this.isOtherReason = false;
      this.earlyGoForm.controls.OtherReason.setValidators(null);
      this.earlyGoForm.controls.OtherReason.updateValueAndValidity();
    }
  }

  // getDepartmentList() {
  //   this.departmentService.getDepartmentListByCompanyId(null, this.companyId).then(res => {
  //     if (res['Success']) {
  //       this.departmentList = res['Data'];
  //     } else { this.departmentList = []; }
  //   }, err => { this.departmentList = []; });
  // }

  async initializeEarlyGoForm() {
    this.earlyGoForm = new FormGroup({
      offerId: new FormControl(!!this.Offer ? this.Offer.offerId : 0),
      status: new FormControl(!!this.Offer ? this.Offer.status : 1),
      companyId: new FormControl(this.companyId),
      departmentId: new FormControl(this.departmentId),
      offerType: new FormControl(7),
      shiftToSkip: new FormControl(this.shiftId),
      dateToSkip: new FormControl(
        !!this.Offer
          ? this.getDateToSkip(
              new Date(this.Offer.dateToSkip),
              this.Offer.vtoStartTime
            )
          : new Date(),
        Validators.required
      ),
      vtoStartTime: new FormControl(
        !!this.Offer ? this.Offer.vtoStartTime : new Date()
      ),
      // vtoEndTime: new FormControl(!!this.Offer ? this.Offer.vtoEndTime : ""),
      ReasonId: new FormControl(
        !!this.Offer ? this.Offer.ReasonId : "",
        Validators.required
      ),
      uTOHours: new FormControl(!!this.Offer ? this.Offer.uTOHours : ""),
      createdBy: new FormControl(!!this.Offer ? this.Offer.createdBy : null),
      IsPaidOff: new FormControl(!!this.Offer ? this.Offer.IsPaidOff : false),
      OtherReason: new FormControl(!!this.Offer ? this.Offer.OtherReason : ""),
      IsDateCrossOver: new FormControl(
        !!this.Offer ? this.Offer.IsDateCrossOver : false
      ),
    });
    if (
      this.earlyGoForm.controls.offerId.value > 0 &&
      this.earlyGoForm.controls.ReasonId.value === 1
    ) {
      this.isOtherReason = true;
      this.earlyGoForm.controls.OtherReason.setValidators(Validators.required);
    }
    // if (this.earlyGoForm.controls.offerId.value > 0) {
    //   this.skipDateChange(new Date(this.dateToSkip).toLocaleString());
    // }
    this.earlyGoForm.controls.vtoStartTime.setValidators(Validators.required);
    // this.earlyGoForm.controls.vtoEndTime.setValidators(Validators.required);
    this.earlyGoForm.controls.vtoStartTime.updateValueAndValidity();
    // this.earlyGoForm.controls.vtoEndTime.updateValueAndValidity();
    // this.earlyGoForm.controls.uTOHours.disable();
  }
  getDateToSkip(dateToSkip, vtoStartTime) {
      const vtoStartTimeSplit = vtoStartTime.split(":");
      const vtoStartTimeHour = parseInt(vtoStartTimeSplit[0]);
      const vtoStartTimeMinute = parseInt(vtoStartTimeSplit[1]);

      const vtoStartTimeDate = new Date(
        dateToSkip.getFullYear(),
        dateToSkip.getMonth(),
        dateToSkip.getDate(),
        vtoStartTimeHour,
        vtoStartTimeMinute
      );
return vtoStartTimeDate;
//       if (vtoStartTimeDate.getHours() >= 12) {
//         // PM
//         return dateToSkip;
//       } else {
//         // AM
//         const dateToSkipValue = new Date(
//           dateToSkip.getFullYear(),
//           dateToSkip.getMonth(),
//           dateToSkip.getDate() - 1
//         );
//         this.dateToSkip = dateToSkipValue.toLocaleDateString();
//         return dateToSkipValue;
//       }
  }

  closePopUp() {
    this.closeRequest.emit(true);
  }

  initializeMessages() {
    this.messageList.endTime = {
      required: Constants.VALIDATION_MSG.EARLY_GO.END_TIME,
    };
    this.messageList.startTime = {
      required: Constants.VALIDATION_MSG.EARLY_GO.START_TME,
    };
    this.messageList.date = {
      required: Constants.VALIDATION_MSG.EARLY_GO.DATE,
    };
    this.messageList.reason = {
      required: Constants.VALIDATION_MSG.EARLY_GO.REASON,
    };
    this.messageList.utoHours = {
      required: Constants.VALIDATION_MSG.EARLY_GO.UTO_HOURS,
    };
    this.messageList.OtherReason = {
      required: Constants.VALIDATION_MSG.CALL_IN.OTHER_REASON,
    };
  }

  skipDateChange(event) {
    if(event == "Invalid Date"){
      let tempTime = new Date(this.earlyGoForm.controls.dateToSkip.value).toLocaleDateString();
      this.dateToSkip = tempTime
      if (this.earlyGoForm.controls.offerId.value > 0) {
        this.dateToSkip = tempTime
      }
      this.earlyGoForm.controls.vtoStartTime.setValue(
        new Date(tempTime)
      );
    }
    else{
      let tempTime = new Date(event).toLocaleDateString();
      this.dateToSkip = tempTime
      if (this.earlyGoForm.controls.offerId.value > 0) {
        this.dateToSkip = tempTime
      }
      this.earlyGoForm.controls.vtoStartTime.setValue(
        new Date(tempTime)
      );
    }
   
    // if (this.Offer != null && this.counter === 0) {
    //   this.setTimes(this.Offer.vtoStartTime, this.Offer.vtoEndTime);
    //   this.shiftService
    //     .getTimeByShiftDateTime(this.dateToSkip, this.Offer.shiftToSkip, 1)
    //     .then(
    //       (response) => {
    //         if (response["Success"]) {
    //           this.shiftEndTime = response["Data"].endTime;
    //           this.shiftStartTime = response["Data"].startTime;
    //           this.nightShift = false;
    //           if (
    //             Number(this.shiftEndTime.split(":")[0]) -
    //               Number(this.shiftStartTime.split(":")[0]) <
    //             0
    //           )
    //             this.nightShift = true;
    //         }
    //       },
    //       (err) => {}
    //     );
    //   this.counter++;
    // } else {
    //   if (event.getTime()) {
    //     const skipDate = this.datepipe.transform(
    //       this.setnewDate(event),
    //       "yyyy-MM-dd"
    //     );
    //     this.earlyGoForm.controls.dateToSkip.setValue(skipDate);
    //     this.getTimeByShiftDateTime(skipDate, this.shiftId);
    //   }
    // }
  }

   getSettingByCompanyID() {
    let module = SubscriptionType.filter((item) => {
      return item.value === "Early Out Module";
    });
    this.moduleId = module[0].id;
    const getMethod = this.callInRequstService.getSettingByCompanyID(
      this.moduleId,
      this.companyId,
      false
    );
     getMethod.then(
      (res: any) => {
        if (res["Success"]) {
          this.settingList = res.Data;
          if (this.settingList.length > 0) {
            this.settingList.map((item) => {
              if (item.SettingType === SettingType[0].value) {
                item.Name = "Paid Time Off";
                this.paidTimeOff = item;
                if (this.value("offerId") == 0) {
                  if (this.paidTimeOff.Enable == true) {
                    this.isPaidTimeOff = true;
                    this.message =
                      "All missed time will be covered by your Unpaid Time Off (UTO) balance. If you do not have enough UTO balance to cover the entire absence, Paid Time Off (PTO) will be applied to cover the shortage. It is your responsibility to ensure that you have enough UTO+PTO accumulated to cover this absence.";
                    this.earlyGoForm.controls["IsPaidOff"].setValue(true);
                  } else {
                    this.isPaidTimeOff = false;
                    this.message =
                      "All missed time will be covered by your Unpaid Time Off (UTO) balance. It is your responsibility to ensure that you have enough UTO accumulated to cover this absence.";
                    this.earlyGoForm.controls["IsPaidOff"].setValue(false);
                  }
                }
              }
            });
          }
        } else {
          this.settingList = [];
        }
      },
      (err) => {
        this.settingList = [];
      }
    );
  }

  getTimeByShiftDateTime(date, shift) {
    this.shiftService.getTimeByShiftDateTime(date, Number(shift), 1).then(
      (response) => {
        if (response["Success"]) {
          this.shiftEndTime = response["Data"].endTime;
          this.shiftStartTime = response["Data"].startTime;
          this.nightShift = false;
          if (
            Number(this.shiftEndTime.split(":")[0]) -
              Number(this.shiftStartTime.split(":")[0]) <
            0
          )
            this.nightShift = true;
          // this.setTimes(this.shiftStartTime, this.shiftEndTime);
          // this.setUtoTime(this.earlyGoForm.controls.vtoStartTime.value);
        } else {
          // this.offerForm.controls.vtoStartTime.setValue(null);
          // this.offerForm.controls.vtoEndTime.setValue(null);
        }
      },
      (err) => {}
    );
  }
  setUtoTime(startTime) {
    if (
      new Date(startTime).getHours() ==
        Number(this.shiftEndTime.split(":")[0]) &&
      new Date(startTime).getMinutes() > 0
    ) {
      // this.toaster.error("Your start time is greater than shift end time");
      this.utoMessage = "Your start time is greater than shift end time";
      this.earlyGoForm.controls.uTOHours.setValue("");
      return;
    }
    if (!this.nightShift) {
      if (
        new Date(startTime).getHours() + 1 ==
          Number(this.shiftEndTime.split(":")[0]) &&
        new Date(startTime).getMinutes() > 0
      ) {
        var utoHours = 0;
      } else {
        var utoHours =
          Number(this.shiftEndTime.split(":")[0]) -
          new Date(startTime).getHours();
      }
    } else {
      if (
        new Date(startTime).getHours() + 1 ==
          Number(this.shiftEndTime.split(":")[0]) &&
        new Date(startTime).getMinutes() > 0
      ) {
        var utoHours = 0;
      } else {
        let convertDate = new Date(startTime);
        if (convertDate.getHours() >= 12) {
          var dt1 = new Date(
            this.todaysDate.setHours(new Date(startTime).getHours())
          );
          var dt2 = new Date(
            this.tomorrowsDate.setHours(Number(this.shiftEndTime.split(":")[0]))
          );
          var utoHours = this.diff_hours(dt1, dt2);
        } else {
          var dt1 = new Date(
            this.todaysDate.setHours(new Date(startTime).getHours())
          );
          var dt2 = new Date(
            this.todaysDate.setHours(Number(this.shiftEndTime.split(":")[0]))
          );
          var utoHours = this.diff_hours(dt1, dt2);
        }
      }
    }
    if (
      Number(this.shiftEndTime.split(":")[1]) >=
      new Date(startTime).getMinutes()
    ) {
      var utoMinutes = (
        Number(this.shiftEndTime.split(":")[1]) -
        new Date(startTime).getMinutes()
      ).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    } else {
      utoMinutes = (60 - new Date(startTime).getMinutes()).toLocaleString(
        "en-US",
        {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }
      );
      if (utoHours > 0) {
        utoHours -= 1;
      }
    }
    this.earlyGoForm.controls.uTOHours.setValue(utoHours + "." + utoMinutes);
  }
  setnewDate(date) {
    const dateObj = {
      year: +this.datepipe.transform(date, "yyyy"),
      month: +this.datepipe.transform(date, "MM"),
      day: +this.datepipe.transform(date, "dd"),
    };
    return new Date(dateObj["year"], dateObj["month"] - 1, dateObj["day"]);
  }
  setTimes(startTime, endTime) {
    if (!!startTime) {
      const startdate = new Date();
      startdate.setHours(
        Number(startTime.split(":")[0]),
        Number(startTime.split(":")[1])
      );
      this.earlyGoForm.controls.vtoStartTime.setValue(startdate);
    } else {
      this.toaster.error(Constants.SHIFT_START_TIME_CONFIGURE);
      this.earlyGoForm.controls.vtoStartTime.setValue(null);
    }
    if (!!endTime) {
      const enddate = new Date();
      enddate.setHours(
        Number(endTime.split(":")[0]),
        Number(endTime.split(":")[1])
      );
      // this.earlyGoForm.controls.vtoEndTime.setValue(enddate);
    } else {
      this.toaster.error(Constants.SHIFT_END_TIME_CONFIGURE);
      // this.earlyGoForm.controls.vtoEndTime.setValue(null);
    }
  }
  startTimeChange(event) {
    this.isFormSubmitted = false;
    // if (
    //   (event.type == "mouseup" && event.srcElement.type == "button") ||
    //   event.type == "change" ||
    //   event.type == "click"
    // ) {
    //   var self = this;
    //   this.ngZone.run(() => {
    //     setTimeout(function () {
    //       self.setUtoTime(self.earlyGoForm.controls.vtoStartTime.value);
    //       if (!self.nightShift) {
    //         if (
    //           new Date(
    //             self.earlyGoForm.controls.vtoStartTime.value
    //           ).getHours() > Number(self.shiftEndTime.split(":")[0])
    //         ) {
    //           // self.toaster.error(
    //           //   "Your start time is greater than shift end time"
    //           // );
    //           self.utoMessage =
    //             "Your start time is greater than shift end time";
    //           self.earlyGoForm.controls.uTOHours.setValue("");
    //         }
    //         if (
    //           new Date(
    //             self.earlyGoForm.controls.vtoStartTime.value
    //           ).getHours() < Number(self.shiftStartTime.split(":")[0])
    //         ) {
    //           // self.toaster.error(
    //           //   "Your start time is less than shift start time"
    //           // );
    //           self.utoMessage = "Your start time is less than shift start time";
    //           self.earlyGoForm.controls.uTOHours.setValue("");
    //         }
    //       } else {
    //         if (self.earlyGoForm.controls.vtoStartTime.value.getHours() > 12) {
    //           if (
    //             new Date(
    //               self.earlyGoForm.controls.vtoStartTime.value
    //             ).getHours() < Number(self.shiftEndTime.split(":")[0])
    //           ) {
    //             // self.toaster.error(
    //             //   "Your start time is greater than shift end time"
    //             // );
    //             self.utoMessage =
    //               "Your start time is greater than shift end time";
    //             self.earlyGoForm.controls.uTOHours.setValue("");
    //           }
    //           if (
    //             new Date(
    //               self.earlyGoForm.controls.vtoStartTime.value
    //             ).getHours() < Number(self.shiftStartTime.split(":")[0])
    //           ) {
    //             // self.toaster.error(
    //             //   "Your start time is less than shift start time"
    //             // );
    //             self.utoMessage =
    //               "Your start time is less than shift start time";
    //             self.earlyGoForm.controls.uTOHours.setValue("");
    //           }
    //         } else {
    //           if (
    //             new Date(
    //               self.earlyGoForm.controls.vtoStartTime.value
    //             ).getHours() > Number(self.shiftEndTime.split(":")[0])
    //           ) {
    //             // self.toaster.error(
    //             //   "Your start time is greater than shift end time"
    //             // );
    //             self.utoMessage =
    //               "Your start time is greater than shift end time";
    //             self.earlyGoForm.controls.uTOHours.setValue("");
    //           }
    //           if (
    //             new Date(
    //               self.earlyGoForm.controls.vtoStartTime.value
    //             ).getHours() > Number(self.shiftStartTime.split(":")[0])
    //           ) {
    //             // self.toaster.error(
    //             //   "Your start time is less than shift start time"
    //             // );
    //             self.utoMessage =
    //               "Your start time is less than shift start time";
    //             this.earlyGoForm.controls.uTOHours.setValue("");
    //           }
    //         }
    //       }
    //     }, 100);
    //   });
    // }
  }

  save() {
    this.isFormSubmitted = true;
    this.formService.markFormGroupTouched(this.earlyGoForm);
    if (this.earlyGoForm.invalid) {
      return;
    }
    // UTO validation
    // remove shift validation task from piyush https://sharing.clickup.com/10625334/t/h/2kcqd2e/LESLQ84N6OD4GKE
    // if(this.earlyGoForm.controls.uTOHours.value == ""){
    //   if(this.utoMessage != ""){
    //     this.toaster.error(this.utoMessage);
    //     return;
    //   }
    // }
    let selectedDateTime = new Date(this.earlyGoForm.controls.dateToSkip.value);
    if (
      selectedDateTime.toDateString() == new Date().toDateString() &&
      new Date(this.earlyGoForm.controls.vtoStartTime.value) < new Date()
    ) {
      this.toaster.error("You cannot add request in past time");
      return;
    }
    // this.earlyGoForm.controls.uTOHours.enable();
    const mxdate = this.datepipe.transform(
      this.setnewDate(this.earlyGoForm.controls.dateToSkip.value),
      "yyyy-MM-dd"
    );
    const startTimeValue = this.earlyGoForm.controls.vtoStartTime.value;
    // const endTimeValue = this.earlyGoForm.controls.vtoEndTime.value;
    var startTime =
      new Date(startTimeValue).getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      new Date(startTimeValue).getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":00";
    // if (this.shiftStartTime == startTime) {
    //   return this.openStartConfirmPopup();
    // }
   
    // if (!!new Date(this.earlyGoForm.controls.vtoEndTime.value).getTime()) {
    //   this.earlyGoForm.controls.vtoEndTime.setValue(
    //     new Date(endTimeValue).getHours() +
    //       ":" +
    //       new Date(endTimeValue).getMinutes()
    //   );
    // }
    const date = this.earlyGoForm.controls.dateToSkip.value;
    const dateToSkip = new Date(date);
    const firstDay = new Date(dateToSkip.setDate(dateToSkip.getDate() - 30));
    const startdate = this.datepipe.transform(
      this.setnewDate(firstDay),
      "yyyy-MM-dd"
    );
    const Enddate = this.datepipe.transform(
      this.setnewDate(date),
      "yyyy-MM-dd"
    );
    const checkExistingRequests =
      this.earlyOutService.checkWeeklyCallOffRequest(
        startdate,
        Enddate,
        this.earlyGoForm.controls.offerId.value
      );
    checkExistingRequests.then((res) => {
      this.settingsService.getSettings(null, this.companyId).then(
        (response) => {
          if (response["Success"]) {
            this.settingData = response["Data"];

            let _settingData = response["Data"].filter(
              (x) =>
                x.SettingType == Constants.EARLY_OUT_INTERVENTION &&
                x.OfferType == this.offerTypeEnum.EarlyOutModule
            );
            //Cond 1 : If the setting "Call-off intervention" is ON then intervention messages should come, if not, the request should just get created without any messages.
            if (
              _settingData.length > 0 &&
              _settingData[0]["OfferType"] ==
                this.offerTypeEnum.EarlyOutModule &&
              _settingData[0]["Enable"] == true
            ) {
              // For future days: if swap module subcribe
              if (parseInt(res["Message"]) > 0) {
                this.confirmMsg = `You have called off and taken early out for total of ${
                  parseInt(res["Message"]) + 1
                } time in last 30 days. Are you sure you want to continue?`;
                this.isConfirm = true;
              } else {
                this.openPopup();
              }
            } else {
              this.isConfirm = false;
              this.proceedSubmit();
            }
          } else {
            this.settingData = [];
          }
        },
        (err) => {
          this.settingData = [];
        }
      );
    });
  }
  openPopup() {
    if (
      !this.settingData.find(
        (x) =>
          x.OfferType === this.offerTypeEnum.EarlyOutModule &&
          x.SettingType === Constants.UTO_SYSTEM_FOR_ATTENDANCE &&
          x.Enable
      )
    ) {
      this.proceedSubmit();
      return;
    }
    this.isConfirm = false;
    this.modalRef = this.modalService.show(this.confirmationPopup, {
      class: "modal-dialog-centered modal-md",
      backdrop: "static",
    });
  }

  openStartConfirmPopup() {
    this.isConfirm = false;
    this.modalRef = this.modalService.show(this.startTimeConfirmPopup, {
      class: "modal-dialog-centered modal-md",
      backdrop: "static",
    });
  }

  proceedSubmit() {
    // this.modalRef = this.modalService.show(this.startTimeConfirmPopup, {
    //   class: "modal-dialog-centered modal-md",
    //   backdrop: "static",
    // });
    // this.modalRef.hide();
    if (!!this.modalRef) {
      this.modalRef.hide();
    }
    const oldDate = this.earlyGoForm.controls.dateToSkip.value;
    // const mxdate = this.datepipe.transform(
    //   this.setnewDate(this.earlyGoForm.controls.dateToSkip.value),
    //   "yyyy-MM-dd"
    // );
    // let startTime = this.earlyGoForm.value.vtoStartTime.split(":");
    //   if(this.earlyGoForm.controls.IsDateCrossOver.value){
    //     if (this.nightShift && startTime != null && startTime.length > 0) {
    //       if (12 < Number(this.earlyGoForm.value.vtoStartTime.split(":")[0])) {
    //         const mxdate = this.datepipe.transform(
    //           this.setnewDate(
    //             new Date(this.earlyGoForm.controls.dateToSkip.value).setDate(
    //               new Date(this.earlyGoForm.controls.dateToSkip.value).getDate() - 1
    //             )
    //           ),
    //           "yyyy-MM-dd"
    //         );
    //         this.earlyGoForm.controls.dateToSkip.setValue(mxdate);
    //         this.earlyGoForm.controls.IsDateCrossOver.setValue(false);
    //       }
    //     }
    //   }
    //  else if (this.nightShift && startTime != null && startTime.length > 0) {
    //     if (12 >= Number(this.earlyGoForm.value.vtoStartTime.split(":")[0])) {
    //       this.offer
    //       const mxdate = this.datepipe.transform(
    //         this.setnewDate(
    //           new Date(this.earlyGoForm.controls.dateToSkip.value).setDate(
    //             new Date(this.earlyGoForm.controls.dateToSkip.value).getDate() + 1
    //           )
    //         ),
    //         "yyyy-MM-dd"
    //       );
    //       this.earlyGoForm.controls.dateToSkip.setValue(mxdate);
    //       this.earlyGoForm.controls.IsDateCrossOver.setValue(true);
    //     }
    //     else{
    //       const mxdate = this.datepipe.transform(
    //         this.setnewDate(this.earlyGoForm.controls.dateToSkip.value),
    //         "yyyy-MM-dd"
    //       );
    //       this.earlyGoForm.controls.dateToSkip.setValue(mxdate);
    //       this.earlyGoForm.controls.IsDateCrossOver.setValue(false);
    //     }
    //   } else {
    //     const mxdate = this.datepipe.transform(
    //       this.setnewDate(this.earlyGoForm.controls.dateToSkip.value),
    //       "yyyy-MM-dd"
    //     );
    //     this.earlyGoForm.controls.dateToSkip.setValue(mxdate);
    //     this.earlyGoForm.controls.IsDateCrossOver.setValue(false);
    //   }
    const mxdate = this.datepipe.transform(
      this.setnewDate(this.earlyGoForm.controls.dateToSkip.value),
      "yyyy-MM-dd"
    );
    const startTimeValue = this.earlyGoForm.controls.vtoStartTime.value;
    this.earlyGoForm.patchValue({dateToSkip : mxdate});
    if (!!new Date(this.earlyGoForm.controls.vtoStartTime.value).getTime()) {
      this.earlyGoForm.controls.vtoStartTime.setValue(
        new Date(startTimeValue).getHours() +
          ":" +
          new Date(startTimeValue).getMinutes()
      );
    }
    this.earlyGoForm.controls.IsDateCrossOver.setValue(false);
    this.earlyGoForm.controls.uTOHours.setValue(null);
    const saveMethod =
      this.earlyGoForm.controls.offerId.value > 0
        ? this.earlyOutService.updateEarlyOutRequestOffer(
            this.earlyGoForm.value
          )
        : this.earlyOutService.addEarlyOutRequest(this.earlyGoForm.value);
    saveMethod.then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(
            this.earlyGoForm.controls.offerId.value > 0
              ? Constants.EARLY_OUT_REQUEST_UPDATE_SUCCESS_MSG
              : Constants.EARLY_OUT_REQUEST_ADD_SUCCESS_MSG
          );
          this.closeRequest.emit(true);
        } else {
          this.utility.showErrorToast(res["Message"]);
          this.earlyGoForm.controls.dateToSkip.setValue(new Date(oldDate));
            this.earlyGoForm.controls.vtoStartTime.setValue(
              new Date(startTimeValue).getHours() +
                ":" +
                new Date(startTimeValue).getMinutes()
            );
        }
      },
      (err) => {}
    );
  }
  close(event) {
    this.isConfirm = false;
    this.earlyOutService.cancelEarlyOutUserRequest(7, this.cancleLogMsg);
  }
  cancelValidationPopUp() {
    this.modalRef.hide();
    this.earlyOutService.cancelEarlyOutUserRequest(7, this.cancleLogMsg);
    this.closeRequest.emit(false);
  }
  // if(!this.signatureUrl)
  // {
  //   this.showSignatureError = true;
  // }
  // else{
  //   this.showSignatureError = false;
  // }

  control(controlName: string): AbstractControl {
    return this.earlyGoForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }

  diff_hours(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
  }
}
