import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Constants, SubscriptionType } from 'src/app/constant/constants';
import { Offer } from 'src/app/models/offer.model';
import { Role } from 'src/app/models/role-model';
import { CallInRequestService } from 'src/app/service/callInRequest.service';
import { DepartmentService } from 'src/app/service/department.service';
import { LateInService } from 'src/app/service/lateIn.service';
import { OfferService } from 'src/app/service/offer.service';
import { ShiftService } from 'src/app/service/shift.service';
import { UtilityService } from 'src/app/service/utility.service';
import { VtoRequestService } from 'src/app/service/vto-request.service';
import { commonHelper } from 'src/app/shared/Helpers/commonHelper';
import * as _ from 'lodash';

@Component({
  selector: 'app-available-late-in-request',
  templateUrl: './available-late-in-request.component.html',
  styleUrls: ['./available-late-in-request.component.scss']
})
export class AvailableLateInRequestComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  @ViewChild('workDateValue', { static: false }) workDateValue;
  @ViewChild('shiftValue', { static: false }) shiftValue;
  @ViewChild('departmentValue', { static: false }) departmentValue;
  @ViewChild('timeValue', { static: false }) timeValue;
  offerId: number;
  selected = [];
  selectedRecords = [];
  lateInRequestList = [];
  modalRef: BsModalRef;
  public earlyGoRequestList: any = [];
  public totalItems: any;
  page: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  role: number;
  roleEnum = Role;
  Offer: Offer;
  // offerStatus = OfferStatus;
  searchSort: any;
  moduleId: number;
  isPaidTimeOff: boolean;
  IsCoverMyWork: boolean;
  // modalService: any;
  confirmMsg = '';
  approveMsg = '';
  confirmCancelBtn = '';
  confirmSaveBtn = '';
  // isApprove = false;
  isReject = false;
  isConfirmed = false;
  requestType = Constants.APPROVED_OFFER;
  searchDate: any;
  filterValue = [];
  nextDate: any;
  commonShiftList = [];
  shiftList = [];
  departmentList = [];
  columns = [];
  public lateInOffer: any;
  isShowLink = false;
  totalRequestedHours = 0;
  companyId: number;

  constructor(private modalService: BsModalService,
    private lateInService: LateInService,
    private shiftService: ShiftService,
    private departmentService: DepartmentService,
    private offerService: OfferService,
    private utilityService: UtilityService,
    private callInRequstService: CallInRequestService,
    private toaster: ToastrService,
    private datepipe: DatePipe,
    private vtoService: VtoRequestService,) {
      this.columns = [
        'departmentId', 'dateToSkip', 'shiftToSkip', 'createdByUsername', 'createdShortDate','createdByObj.shift.title','departmentName','department.departmentName','subDepartmentName', 'shiftToWorkTitle', 'createdDate', 'isPit', 'operateToShow','preferenceId', 'dateToWorkDateStr','createdByShift'
      ];

    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [
      ],
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.isConfirmed = false;
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.setPage({ offset: 0 });
    if (this.role === this.roleEnum.manager) {
      this.getDepartmentList();
      this.getShiftList();
    }
  }

  ngAfterViewInit() {
    if (this.role === this.roleEnum.manager) {
      // this.workDateValue.nativeElement.value = this.nextDate;
      this.filterValue.push('');
      this.filterValue.push(this.shiftValue.nativeElement.value);
      this.filterValue.push(this.departmentValue.nativeElement.value);
      this.columns.forEach((element, i) => {
        if (i < 3) {
          const obj = {
            Data: 'string',
            Searchable: true,
            Orderable: true,
            Name: element,
            Search: {
              Value: this.filterValue[i],
              Regex: 'string'
            }
          }
          this.searchSort.Columns.push(obj);
        }
      });
    }
  }
  getDepartmentList() {
    this.departmentService.getDepartmentListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID))).then(res => {
      if (res['Success']) {
        this.departmentList = res['Data'];
      } else { this.departmentList = []; }
    }, err => { this.departmentList = []; });
  }

  getShiftList() {
    this.shiftService.getShiftListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID)))
      .then(response => {
        this.shiftList = response['Data'];
        this.commonShiftList = _.merge([], this.shiftList)
      }, err => { this.shiftList = []; });
  }

  async setPage(pageInfo) {
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
    await this.getLateInOfferListAsync(this.page.pageNumber + 1)
    await this.getSettingByCompanyIDAsync();
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  async getSettingByCompanyIDAsync() {
    let module = SubscriptionType.filter((item) => {
      return item.value === 'Early Out Module';
    });
    this.moduleId = module[0].id;
    const getMethod = this.callInRequstService.getSettingByCompanyID(this.moduleId, Number(localStorage.getItem(Constants.COMPANYID)));
    await getMethod.then((res: any) => {
      if (res['Success']) {
        if (res.Data.length > 0) {
          res.Data.map((item) => {
            if (item.SettingType === "PaidTimeOff") {
              item.Name = "Paid Time Off";
              if (item.Enable == true) {
                this.isPaidTimeOff = true;
              }
              else {
                this.isPaidTimeOff = false;
              }
            }
          });
        }
      }
    }, err => { });

  }


  async getLateInOfferListAsync(currentPage) {
    const getMethod = this.lateInService.getLateInAvailableManagerRequestList(currentPage, this.rowsOnPage)
    await getMethod.then((res: any) => {
      if (res['Success']) {
        if (res.Data && res.Data.results.length > 0) {
          this.lateInRequestList = res.Data.results
          this.totalItems = res.Data.totalNumberOfRecords;
          // this.totalRequestedHours = this.role == this.roleEnum.manager ? res.Data.totalHours.totalHoursApproved : 0;
        } else {
          this.lateInRequestList = [];
          this.totalItems = 0;
        }
      } else {
        this.lateInRequestList = [];
        this.totalItems = 0;
      }
    }, err => {
      this.lateInRequestList = [];
      this.totalItems = 0;
    });
  }
  
  // filterData() {
  //   const filterMethod = this.role === this.roleEnum.manager ? this.lateInService.managerClosedLateInOfferDataFilter(this.searchSort) :
  //     this.lateInService.userLateInOfferDataFilter(this.searchSort);
  //   filterMethod.then(res => {
  //     if (res['data'] && res['data'].length > 0) {
  //       this.lateInRequestList = res['data'];
  //       this.totalItems = res['recordsFiltered'];
  //     } else { this.lateInRequestList = []; this.totalItems = 0; }
  //   }, err => { this.lateInRequestList = []; this.totalItems = 0; });
  // }

  filterData(){
    const filterMethod = this.lateInService.managerAvailableLateInOfferDataFilter(this.searchSort);
    filterMethod.then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.lateInRequestList = res['data'];
        this.totalItems = res['recordsFiltered'];
      } else {
        this.totalItems = 0;
      }
    }, err => { this.lateInRequestList = []; this.totalItems = 0; });
}

  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.lateInOffer = data;
    } else {
      this.lateInOffer = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }
  clear() {
    if (this.role === this.roleEnum.user) {
      this.searchSort.Search.Value = '';
      this.searchSort.Order[0].ColumnName = '';
      this.searchSort.Order[0].Dir = 'asc';
      this.filterTextValue.nativeElement.value = '';
      this.setPage({ offset: 0 });
    } else {
      this.searchSort.Search.Value = '';
      this.searchSort.Order[0].ColumnName = '';
      this.searchSort.Order[0].Dir = 'asc';
      this.filterTextValue.nativeElement.value = '';
      this.searchSort.Columns.forEach(element => {
        element.Search.Value = '';
      });
      this.shiftValue.nativeElement.value = '';
      this.departmentValue.nativeElement.value = '';
      // this.workDateValue.nativeElement.value = this.nextDate;
      this.workDateValue.nativeElement.value = '';
      this.searchDate = this.nextDate;
      this.setPage({ offset: 0 });
    }
  }

  // updateFilter(event) {
  //   if (event.key !== "Tab") {
  //     if (!!event.target.value) {
  //       this.searchSort.Search.Value = event.target.value;
  //       this.searchSort.Search.ColumnNameList = this.columns;
  //       this.searchSort.Page = this.page.pageNumber + 1;
  //       this.filterData();
  //     } else {
  //       this.clearText();
  //     }
  //   }
  // }
  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.isShowLink = true;
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.isShowLink = false;
        this.clearText();
      }
    }
  }
  clearText() {
    this.searchSort.Search.Value = '';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  close(event) {
    this.isConfirmed = false;
    this.isReject = false;
  }

  Denied(data) {
    this.isReject = true;
    this.offerId = data.offerId;
    this.confirmSaveBtn = Constants.YES;
    this.confirmCancelBtn = Constants.NO;
    this.confirmMsg = 'Are you sure you want to reject this request?';
    // this.offerId = data.offerId;
  }

  openApprove(data) {
    this.offerId = data.offerId
    this.isConfirmed = true;
    this.approveMsg = 'Are you sure you want to approve this request?'
  }
  approve(id) {
    this.isConfirmed = false;
    this.lateInService.approveManagerLateInOffers(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.OFFER_APPROVE_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { });

  }
  reject(id) {
    this.isReject = false;
    this.lateInService.rejectManagerLateInOffers(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.OFFER_REJECT_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { });
  }

  workDateChange(event) {
    this.searchDate = null;
    const findDateColumn = this.searchSort.Columns.find(x => x.Name === 'dateToSkip');
    if (!!findDateColumn) {
      findDateColumn.Search.Value = this.datepipe.transform(this.setnewDate(event), 'yyyy-MM-dd');
      this.searchDate = this.datepipe.transform(this.setnewDate(event), 'yyyy-MM-dd');
    }
  }
  shiftChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(x => x.Name === 'shiftToSkip');
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = event.currentTarget.value;
    }
  }

  setnewDate(date) {
    const dateObj = {
      year: +(this.datepipe.transform(date, 'yyyy')),
      month: +(this.datepipe.transform(date, 'MM')),
      day: +(this.datepipe.transform(date, 'dd'))
    };
    return new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day']);
  }

  departmentChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(x => x.Name === 'shiftToSkip');
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = '';
    }
    if (event.currentTarget.value)
      this.shiftList = commonHelper.filterShiftListFromDepartmentId(event.currentTarget.value, this.commonShiftList);
    else
      this.shiftList = this.commonShiftList
    const findDepartmentColumn = this.searchSort.Columns.find(x => x.Name === 'departmentId');
    if (!!findDepartmentColumn) {
      findDepartmentColumn.Search.Value = event.currentTarget.value;
    }
  }

}
