import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from '../constant/constants';

@Injectable()
export class TransferRequestService {

  constructor(private apiService: ApiService) { }

  addTransferRequestOffer(params) {
    return this.apiService.post(`${API.TRANSFER_REQUEST_ROUTES.addTransferRequest}`, params, true);
  }

  updateTransferRequestOffer(params) {
    return this.apiService.put(`${API.TRANSFER_REQUEST_ROUTES.updateTransferRequest}`, params, true);
  }
  
  getTransferRequestList(currentPage, rowsOnPage,companyId) {
    return this.apiService.get(
      `${API.TRANSFER_REQUEST_ROUTES.getUserTransferRequestList}?page=` +
      currentPage + `&pageSize=${rowsOnPage}` + `&companyId=${companyId}`,
      true,
      true,
    );
  }
  getTransferClosedManagerRequestList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.TRANSFER_REQUEST_ROUTES.closedManagerTransferOffers}?companyId=${localStorage.getItem(Constants.COMPANYID)}&page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }

  downloadPendingExcelFile(data) {
    return this.apiService.post(`${API.TRANSFER_REQUEST_ROUTES.downloadPendingExcelFile}`, data, true);
  }

  downloadClosedExcelFile(data) {
    return this.apiService.post(`${API.TRANSFER_REQUEST_ROUTES.downloadClosedExcelFile}`, data, true);
  }

  getTransferPendingHrRequestList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.TRANSFER_REQUEST_ROUTES.hrTransferPendingRequests}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }
  getTransferClosedHrRequestList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.TRANSFER_REQUEST_ROUTES.hrTransferClosedRequests}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }

  hrClosedTransferOfferDataFilter(data) {
    return this.apiService.post(`${API.TRANSFER_REQUEST_ROUTES.hrClosedTransferOfferDataFilter}`, data, true);
  }
  hrPendingTransferOfferDataFilter(data) {
    return this.apiService.post(`${API.TRANSFER_REQUEST_ROUTES.hrPendingTransferOfferDataFilter}`, data, true);
  }

 

  approveTransferRequest(params) {
    return this.apiService.post(`${API.TRANSFER_REQUEST_ROUTES.approveTransferRequest}`, params, true);
  }

  approveDenialRequest(params) {
    return this.apiService.post(`${API.TRANSFER_REQUEST_ROUTES.approveDenialRequest}`, params, true);
  }

  acceptTransferOffer(offerId) {
    return this.apiService.post(`${API.TRANSFER_REQUEST_ROUTES.acceptTransferRequest}?offerId=${offerId}`, true, true);
  }

  notInterestedTransferOffer(offerId) {
    return this.apiService.post(`${API.TRANSFER_REQUEST_ROUTES.rejectTransferRequestByUser}?offerId=${offerId}`, true, true);
  }  

  TransferProcessOffer(data) {
    return this.apiService.put(`${API.TRANSFER_REQUEST_ROUTES.transferProcessRequest}`, data, true);
  }

  getTransferRequestsForUserDataFilter(data) {
    return this.apiService.post(`${API.TRANSFER_REQUEST_ROUTES.transferRequestForUserDataFilter}`, data, true);
  }

  
}
