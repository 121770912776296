import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/constant/constants';
import { Offer } from 'src/app/models/offer.model';
import { EmployeeReviewStatusEnum, OfferStatus, Role } from 'src/app/models/role-model';
import { OfferService } from 'src/app/service/offer.service';
import { ReviewService } from 'src/app/service/review.service';
@Component({
  selector: 'app-available-review-request',
  templateUrl: './available-review-request.component.html',
  styleUrls: ['./available-review-request.component.scss']
})
export class AvailableReviewRequestComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  @ViewChild('startDate', { static: false }) startDate;
  @ViewChild('managerValue', { static: false }) managerValue;
  @ViewChild('endDate', { static: false }) endDate;
  @ViewChild("userNameValue", { static: false }) userNameValue;
  reviewRequestList = [];
  searchSort: any;
  public myOffer: Offer;
  modalRef: BsModalRef;
  public totalItems: any;
  page: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  role: number;
  roleEnum = Role;
  deleteId: string;
  isConfirmed = false;
  employeeReviewStatus = EmployeeReviewStatusEnum;
  moduleId: number;
  settingList: any;
  columns = [];
  viewMode:boolean;
  filterValue = [];

  constructor(private modalService: BsModalService,
    private offerService: OfferService,
    private toaster: ToastrService,
    private reviewService: ReviewService) {
    this.columns = ['SubmitDateStr', 'ReviewDateStr', 'UserName', 'ReviewByObj.companyUsername', 'ReviewTypeStr', 'StatusStr'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Columns: [
      ],
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }
  ngOnInit() {
    this.setPage({ offset: 0 });
    this.columns.forEach((element, i) => {
      if (i < 5) {
        const obj = {
          Data: "string",
          Searchable: true,
          Orderable: true,
          Name: element,
          Search: {
            Value: this.filterValue[i],
            Regex: "string",
            isSearchBetweenDates: i === 0 ? true : false,
            endDate: null,
          },
        };
        this.searchSort.Columns.push(obj);
      }
    });
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getReviewList(this.page.pageNumber + 1);
  }
  getReviewList(currentPage) {
    if ((!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) ||
    (!!this.endDate && !!this.endDate.nativeElement.value) ||
    (!!this.startDate && !!this.startDate.nativeElement.value) ||
    (!!this.managerValue && this.managerValue.nativeElement.value) ||
    (!!this.userNameValue && !!this.userNameValue.nativeElement.value)) {
    this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
    this.searchSort.Search.ColumnNameList = this.columns;
    this.searchSort.Page = currentPage;
    this.filterData();
  } else {
    const getMethod = this.reviewService.getReviewPendingHrRequestList(currentPage, this.rowsOnPage)
    getMethod.then((res: any) => {
      if (res['Success']) {
        if (res.Data && res.Data.results.length > 0) {
          this.reviewRequestList = res.Data.results
          this.totalItems = res.Data.totalNumberOfRecords;
        } else {
          this.reviewRequestList = [];
          this.totalItems = 0;
        }
      } else {
        this.reviewRequestList = [];
        this.totalItems = 0;
      }
    }, err => {
      this.reviewRequestList = [];
      this.totalItems = 0;
    });
  }
}

  openModal(template: TemplateRef<any>,  data: any,viewMode=false) {
    this.viewMode=viewMode;
    if (data) {
      this.myOffer = data;
    } else {
      this.myOffer = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }
  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }
  onClickDelete(data) {
    this.deleteId = data.Id;
    this.isConfirmed = true;
  }
  delete(id) {
    this.isConfirmed = false;
    this.reviewService.RejectReviewOffers(id).then(res => {
      if (res['Success']) {
        this.toaster.success(res['Message']);
        this.setPage({ offset: 0 });
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { });
  }
  close(event) {
    this.isConfirmed = false;
  }
  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clearText();
      }
    }
  }
  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }
  filterData() {
    const filterMethod = this.reviewService.managerReviewPendingDataFilter(this.searchSort);
    filterMethod.then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.reviewRequestList = res['data'];
        this.totalItems = res['recordsFiltered'];
      } else {
        this.totalItems = 0;
      }
    }, err => { this.reviewRequestList = []; this.totalItems = 0; });
  }
  clearText() {
    this.searchSort.Search.Value = '';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }
  clear() {
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.searchSort.Columns.forEach(element => {
      element.Search.Value = '';
    });
    this.setPage({ offset: 0 });
  }
}
