import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from './../constant/constants';


@Injectable({
  providedIn: 'root'
})
export class EarlyOutService {
  constructor(private apiService: ApiService) { }

  addEarlyOutRequest(data) {
    return this.apiService.post(
      `${API.EARLY_OUT_REQUEST.addEarlyOutRequest}`,
      data,
      true,
    );
  }

  getReasons(isStopLoader = true) {
    return this.apiService.get(
      `${API.CALL_OFF_REQUEST.callOffReasons}?reasonType=2`,
      true,
      isStopLoader,
    );
  }

  getEarlyOutRequestList(currentPage, rowsOnPage,isStopLoader = true, isShowLoad = true) {
    return this.apiService.get(
      `${API.EARLY_OUT_REQUEST.getUserEarlyOutRequestList}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      isStopLoader,
      isShowLoad,
    );
  }
  updateEarlyOutRequestOffer(params) {
    return this.apiService.put(`${API.EARLY_OUT_REQUEST.updateEarlyOutRequest}`, params, true);
  }
  userEarltyOutOfferDataFilter(data) {
    return this.apiService.post(`${API.EARLY_OUT_REQUEST.userDataFilter}`, data, true);
  }
  checkWeeklyCallOffRequest(startdate, Enddate, offerId) {
    return this.apiService.post(`${API.CALL_OFF_REQUEST.checkMonthlyCallOffRequest}?startdate=${startdate}&Enddate=${Enddate}&offerId=${offerId}&offerType=${7}`
      , [6,7]
      , true);
  }



  getEarlyOutAvailableManagerRequestList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.EARLY_OUT_REQUEST.avilableManagerEarlyOutOffers}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }
  approveManagerEarlyOutOffers(id) {
    return this.apiService.put(`${API.EARLY_OUT_REQUEST.approveManagerEarlyOutOffers}?offerID=${id}`, null, true);
  }
  rejectManagerEarlyOutOffers(id) {
    return this.apiService.put(`${API.EARLY_OUT_REQUEST.rejectManagerEarlyOutOffers}?offerID=${id}`, null, true);
  }
  managerAvailableEarltyOutOfferDataFilter(data) {
    return this.apiService.post(`${API.EARLY_OUT_REQUEST.availableManagerEarlyOutDataFilter}`, data, true);
  }

  getEarlyOutClosedManagerRequestList(currentPage, rowsOnPage,isStopLoader = true, isShowLoad = true) {
    return this.apiService.get(
      `${API.EARLY_OUT_REQUEST.closedManagerEarlyOutOffers}?companyId=${localStorage.getItem(Constants.COMPANYID)}&page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      isStopLoader,
      isShowLoad,
    );
  }
  managerClosedEarltyOutOfferDataFilter(data) {
    return this.apiService.post(`${API.EARLY_OUT_REQUEST.managerClosedEarlyOutDataFilter}`, data, true);
  }


  getEarlyPendingHrRequestList(currentPage, rowsOnPage,isStopLoader = true,isShowLoader = true) {
    return this.apiService.get(
      `${API.EARLY_OUT_REQUEST.hrPendingRequests}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      isStopLoader,
      isShowLoader,
    );
  }
  getEarlyClosedHrRequestList(currentPage, rowsOnPage,isStopLoader = true,isShowLoader = true) {
    return this.apiService.get(
      `${API.EARLY_OUT_REQUEST.hrClosedRequests}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      isStopLoader,
      isShowLoader,
    );
  }
  hrClosedEarltyOutOfferDataFilter(data) {
    return this.apiService.post(`${API.EARLY_OUT_REQUEST.hrClosedEarltyOutOfferDataFilter}`, data, true);
  }
  hrPendingEarltyOutOfferDataFilter(data) {
    return this.apiService.post(`${API.EARLY_OUT_REQUEST.hrPendingEarltyOutOfferDataFilter}`, data, true);
  }
  proceedHroffer(offer) {
    return this.apiService.put(
      `${API.EARLY_OUT_REQUEST.proceedHroffer}`,
      offer,
      true,
    );
  }
  RejectEarlyOutOffers(id) {
    return this.apiService.put(`${API.EARLY_OUT_REQUEST.rejectHrEarlyOutOffers}?offerID=${id}`, null, true);
  }
  cancelEarlyOutUserRequest(offerType,message) {
    return this.apiService.post(
      `${API.OFFER_LOG.OfferLogEntry}?offerType=${offerType}&message=${message}`,
      null,
      true,
    );
  }

  downloadPendingExcelFile(data) {
    return this.apiService.post(`${API.EARLY_OUT_REQUEST.downloadPendingExcelFile}`, data, true);
  }

  downloadClosedExcelFile(data) {
    return this.apiService.post(`${API.EARLY_OUT_REQUEST.downloadClosedExcelFile}`, data, true);
  }
}