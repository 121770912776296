import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pending-request',
  templateUrl: './pending-request.component.html',
  styleUrls: ['./pending-request.component.scss']
})
export class PendingRequestComponent implements OnInit {
  isShowVto = false;
  isVtoRequests = false;
  constructor() { }

  ngOnInit() {
    this.isVtoRequests = true;
    this.isShowVto = true;
  }

  getOffer(data) {
    this.isVtoRequests = false;
    if (data === 5) {
      this.isVtoRequests = true;
    }
  }
}
