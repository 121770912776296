import { Component, Input, OnInit, ViewChild, AfterViewInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { Constants,SubscriptionType} from 'src/app/constant/constants';
import { DepartmentService } from 'src/app/service/department.service';
import { ShiftService } from 'src/app/service/shift.service';
import { CallInRequestService } from './../../service/callInRequest.service';
import { UtilityService } from './../../service/utility.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { OfferStatus, Role } from 'src/app/models/role-model';
import { Offer } from 'src/app/models/offer.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { commonHelper } from 'src/app/shared/Helpers/commonHelper';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-employee-call-in-request',
  templateUrl: './employee-call-in-request.component.html',
  styleUrls: ['./employee-call-in-request.component.scss']
})
export class EmployeeCallInRequestComponent implements OnInit, AfterViewInit {
  @Output() reloadCount = new EventEmitter<boolean>();
  @Input() requestType: string;
  type = '';
  selected = [];
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  @ViewChild('shiftValue', { static: false }) shiftValue;
  @ViewChild('departmentValue', { static: false }) departmentValue;
  @ViewChild('workDateValue', { static: false }) workDateValue;
  shiftList = [];
  departmentList = [];
  selectedRecords = [];
  allRowsSelected = [];
  page: any;
  Offer : Offer;
  filterValue = [];
  searchSort: any;
  requestedOfferList = [];
  totalItems: any;
  columns = [];
  rowsOnPage = Constants.HR_PAGE_ROWS_ON_PAGE;
  totalRequestedHours = 0;
  offerStatus = OfferStatus;
  public roleEnum = Role;
  role: number;
  modalRef: BsModalRef;
  IsCoverMyWork: boolean = false;
  subscriptionType = SubscriptionType;
  public moduleId:any;
  public settingList:any=[];
  public paidTimeOff:any={};
  public flma:any={};
  commonShiftList: any;


  constructor(
    private shiftService: ShiftService,
    private departmentService: DepartmentService,
    private callInRequestService: CallInRequestService,
    private utilityService: UtilityService,
    private datePipe: DatePipe,
    private modalService:BsModalService
  ) {
    this.columns = ['dateToSkip', 'departmentId', 'shiftToSkip', 'shiftId',
                    'createdByObj.companyUsername','ReasonObj.name','OtherReason',
                    'departmentName','shiftToSkipNavigation.title','IsPaidOff','IsHRCallBack'
                    ,'offerStatusStr','approvedByObj.name','IsFMLA'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [
      ],
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.IsCoverMyWork = localStorage.getItem(Constants.APP_NAME) === "CoverMyWork" ? true:false;
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = 0;
    let module = SubscriptionType.filter((item)=>{
      return item.value ==='Call-Off Module';
    });
    this.moduleId = module[0].id;
    forkJoin([
      this.shiftService.getShiftListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID)),false,false),
      this.departmentService.getDepartmentListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID)),false,false),
      this.callInRequestService.getSettingByCompanyID(this.moduleId,Number(localStorage.getItem(Constants.COMPANYID)),false,false),
      this.requestType === Constants.REQUESTED_OFFER ?
        this.callInRequestService.getCallOffReqestedList(1, this.rowsOnPage)
        : this.callInRequestService.getCallOffClosedList(1, this.rowsOnPage)
    ]).subscribe((res:any) => {
      //first api response 
      if (res[0]['Success']) {
        this.shiftList = res[0]['Data'];
        this.commonShiftList = _.merge([], this.shiftList)
      }

      //second api response 
      if (res[1]['Success']) {
        this.departmentList = res[1]['Data'];
      } else { this.departmentList = []; }

      //third api response 
      if (res[2]['Success']) {
        this.settingList = res[2].Data;
        if(this.settingList.length > 0){
          this.settingList.map((item)=>{
            if(item.SettingType==="PaidTimeOff"){
              item.Name = "Paid Time Off";
              this.paidTimeOff = item;
            }
            else if(item.SettingType==="FMLA"){
              item.Name = item.SettingType;
              this.flma = item;
            }
          });
        }
      } else { this.settingList = [];  }

      //fourth api response 
     
      if (res[3]['Success']) {
        if (!!res[3].Data.pagedResults && res[3].Data.pagedResults.results.length > 0) {
          this.requestedOfferList = res[3].Data.pagedResults.results;
          this.totalItems = res[3].Data.pagedResults.totalNumberOfRecords;
          this.totalRequestedHours = res[3].Data.totalHours.totalHoursApproved;
        } else {
          this.requestedOfferList = [];
          this.totalItems = 0;
        }
      } else {
        this.requestedOfferList = [];
        this.totalItems = 0;
      }
      if(JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))){
        this.utilityService.showLoading();
      }
      else{
        this.utilityService.hideLoading()
      }
    })
  }

  getSettingByCompanyID(){
    let module = SubscriptionType.filter((item)=>{
      return item.value ==='Call-Off Module';
    });
    this.moduleId = module[0].id;
    const getMethod = this.callInRequestService.getSettingByCompanyID(this.moduleId,Number(localStorage.getItem(Constants.COMPANYID)));
    getMethod.then((res: any) => {
      if (res['Success']) {
        this.settingList = res.Data;
        if(this.settingList.length > 0){
          this.settingList.map((item)=>{
            if(item.SettingType==="PaidTimeOff"){
              item.Name = "Paid Time Off";
              this.paidTimeOff = item;
            }
            else if(item.SettingType==="FMLA"){
              item.Name = item.SettingType;
              this.flma = item;
            }
          });
        }
      } else { this.settingList = [];  }
    }, err => { this.settingList = []; });
  }

  ngAfterViewInit() {
    this.filterValue.push(this.workDateValue.nativeElement.value);
    this.filterValue.push(this.shiftValue.nativeElement.value);
    this.filterValue.push(this.departmentValue.nativeElement.value);
    this.columns.forEach((element, i) => {
      if (i < 4) {
        const obj = {
          Data: 'string',
          Searchable: true,
          Orderable: true,
          Name: element,
          Search: {
            Value: this.filterValue[i],
            Regex: 'string'
          }
        }
        this.searchSort.Columns.push(obj);
      }
    });
  }

  getShiftList() {
    this.shiftService.getShiftListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID)))
      .then(response => {
        this.shiftList = response['Data'];
        this.commonShiftList = _.merge([], this.shiftList)
      }, err => { this.shiftList = []; });
  }

  getDepartmentList() {
    this.departmentService.getDepartmentListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID))).then(res => {
      if (res['Success']) {
        this.departmentList = res['Data'];
        this.getSettingByCompanyID(); 
      } else { this.departmentList = []; }
    }, err => { this.departmentList = []; });
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.selectedRecords = this.selected.map(x => x.offerId);
  }

  workDateChange(event) {
    const findDateColumn = this.searchSort.Columns.find(x => x.Name === 'dateToSkip');
    if (!!findDateColumn) {
      findDateColumn.Search.Value = this.datePipe.transform(this.setnewDate(event), 'yyyy/MM/dd');
    }
  }

  setnewDate(date) {
    const dateObj = {
      year: +(this.datePipe.transform(date, 'yyyy')),
      month: +(this.datePipe.transform(date, 'MM')),
      day: +(this.datePipe.transform(date, 'dd'))
    };
    return new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day']);
  }

  async setPage(pageInfo) {
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
   await this.getRequestedOfferList(this.page.pageNumber + 1)
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clearText();
      }
    }
  }

  clearText() {
    this.searchSort.Search.Value = '';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  shiftChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(x => x.Name === 'shiftToSkip');
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = event.currentTarget.value;
    }
  }

  departmentChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(x => x.Name === 'shiftToSkip');
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = '';
    }
    if (event.currentTarget.value)
      this.shiftList = commonHelper.filterShiftListFromDepartmentId(event.currentTarget.value, this.commonShiftList);
    else
      this.shiftList = this.commonShiftList
    const findDepartmentColumn = this.searchSort.Columns.find(x => x.Name === 'departmentId');
    if (!!findDepartmentColumn) {
      findDepartmentColumn.Search.Value = event.currentTarget.value;
    }
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  async getRequestedOfferList(currentPage) {
    if ((!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) ||
      (!!this.shiftValue && !!this.shiftValue.nativeElement.value) ||
      (!!this.departmentValue && !!this.departmentValue.nativeElement.value) ||
      (!!this.workDateValue && this.workDateValue.nativeElement.value)) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod = this.requestType === Constants.REQUESTED_OFFER ?
        this.callInRequestService.getCallOffReqestedList(currentPage, this.rowsOnPage)
        : this.callInRequestService.getCallOffClosedList(currentPage, this.rowsOnPage);
      await getMethod.then((res: any) => {
        if(JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))){
          this.utilityService.showLoading();
        }
        if (res['Success']) {
          if (!!res.Data.pagedResults && res.Data.pagedResults.results.length > 0) {
            this.requestedOfferList = res.Data.pagedResults.results;
            this.totalItems = res.Data.pagedResults.totalNumberOfRecords;
            this.totalRequestedHours = res.Data.totalHours.totalHoursApproved;
          } else {
            this.requestedOfferList = [];
            this.totalItems = 0;
          }
        } else {
          this.requestedOfferList = [];
          this.totalItems = 0;
        }
      }, err => {
        this.requestedOfferList = [];
        this.totalItems = 0;
      });
    }
  }

  filterData() {
    const filterMethod = this.requestType === Constants.REQUESTED_OFFER ? this.callInRequestService.approvalCallOffOfferDataFilter(this.searchSort)
      : this.callInRequestService.closedCallOffOfferDataFilter(this.searchSort);
    filterMethod.then(res => {
      if (res['offerlist'].data && res['offerlist'].data.length > 0) {
        this.requestedOfferList = res['offerlist'].data;
        this.totalItems = res['offerlist'].recordsFiltered;
        this.totalRequestedHours = res['totalhours'] === 0 ? 0 :res['totalhours'];
      } else {
        this.totalItems = 0;
        this.totalRequestedHours = 0;
      }
    }, err => { this.requestedOfferList = []; this.totalItems = 0; });
  }

  clear() {
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.searchSort.Columns.forEach(element => {
      element.Search.Value = '';
    });
    this.shiftValue.nativeElement.value = '';
    this.departmentValue.nativeElement.value = '';
    this.workDateValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }
  openApprove(template: TemplateRef<any>, data: any) {
    if (data) {
      this.Offer = data;
    } else {
      this.Offer = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  downloadFile() {
    const downloadMethod = this.requestType !== 'approvedOffer' ?
      this.callInRequestService.downloadPendingExcelFile(this.searchSort) :
      this.callInRequestService.downloadClosedExcelFile(this.searchSort);
    downloadMethod.then(response => {
      if (response['Success']) {
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.href = environment.BLOB_URL + response['Message'];
        a.download = response['Message'].split('/')[1];
        a.click();
      } else {
        this.utilityService.showErrorToast(response['Message']);
      }
    }, err => { });;
  }

  Process(){
    const postMethod = this.callInRequestService.approveCallOffOffer(this.selectedRecords)
    postMethod.then(async response => {
      if (response['Success']) {
        this.utilityService.showSuccessToast(Constants.CALL_OFF_REQUEST_APPROVE_MSG);
        await this.setPage({ offset: 0 });
        this.reloadCount.emit();
      } else {
        this.utilityService.showErrorToast(response['Message']);
      }
    }, err => { });
  }
  async closeModal(event) {
    if (event) {
      await this.setPage({ offset: 0 });
      this.reloadCount.emit();
    }
    this.modalRef.hide();
  }
}
