import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { API } from "../routes/api-routes";

@Injectable()
export class DepartmentMappingService {
  constructor(private apiService: ApiService) {}

  addDepartmentMapping(data) {
    return this.apiService.post(
      `${API.DEPARTMENT_MAPPING_ROUTES.addDepartmentMapping}`,
      data,
      true
    );
  }
  updateDepartmentMapping(data) {
    return this.apiService.put(
      `${API.DEPARTMENT_MAPPING_ROUTES.updateDepartmentMapping}`,
      data,
      true
    );
  }

  getDepartmentMappingList(currentPage) {
    return this.apiService.get(
      `${API.DEPARTMENT_MAPPING_ROUTES.departmentMapping}?page=` + currentPage,
      true,
      true
    );
  }

  dataFilter(data) {
    return this.apiService.post(
      `${API.DEPARTMENT_MAPPING_ROUTES.dataFilter}`,
      data,
      true
    );
  }

  deleteDepartmentMapping(Id) {
    return this.apiService.delete(
      `${API.DEPARTMENT_MAPPING_ROUTES.departmentMapping}?id=` + Id,
      true,
    );
  }
}
