import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Constants } from 'src/app/constant/constants';
import { TimePunchesService } from 'src/app/service/time-punches.service';

@Component({
  selector: 'app-inconsist-time-punches-records',
  templateUrl: './inconsist-time-punches-records.component.html',
  styleUrls: ['./inconsist-time-punches-records.component.scss']
})
export class InconsistTimePunchesRecordsComponent implements OnInit {
  @Input() weekStartDate;
  @Input() weekEndDate;
  companyId: number;
  inconsistPunchList = [];
  totalItems: any;
  modalRef: BsModalRef;
  @Output() close = new EventEmitter<boolean>();
  searchSort: any;
  page: any;
  columns = [];
  rowsOnPage = Constants.ROWS_ON_PAGE;
  constructor(
    private timePunchesService: TimePunchesService,
  ) {
    this.columns = ['Date', 'Username','FirstPunch','FirstPunchType','LastPunch','LastPunchType','Reason'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  ngOnInit() {
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.getInconsistList();
  }
  getInconsistList() {
    const getMethod = this.timePunchesService.getInconsistTimePunchesData(
      this.companyId, this.weekStartDate.toLocaleString(), this.weekEndDate.toLocaleString()
    );
    getMethod.then(
      (res: any) => {
        if (res["Success"]) {
          this.inconsistPunchList = res.Data;
          this.totalItems =
            res.Data.length === 0
              ? undefined
              : res.Data.length;
        } else {
          this.inconsistPunchList = [];
          this.totalItems = undefined;
        }
      },
      (err) => {
        this.inconsistPunchList = [];
      }
    );
  }
  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.getInconsistList();
  }
  cancel() {
    this.close.emit(false);
  }
}
