import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormService } from '../core/form.service';
import { ValidationService } from '../service/validation.service';
import { UtilityService } from '../service/utility.service';
import { AuthService } from '../core/auth/auth.service';
import { Constants, URL_ROUTES, drpSetting, dropdownList, phoneMask } from '../constant/constants';
import { ShiftService } from '../service/shift.service';
import { LocationService } from '../service/location.service';
import { DepartmentService } from '../service/department.service';
import { SignUpMessageList } from '../models/signup-model';
import { CompanyService } from '../service/company.service';
import { environment } from 'src/environments/environment';
import { UserService } from '../service/user.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  public subDepartmentList: any = [];
  public signUpForm: FormGroup;
  public eyeShow = false;
  public messageList: any = new SignUpMessageList();
  companyList = [];
  locationList = [];
  departmentList = [];
  managerUserList = [];
  shiftList = [];
  isShowConfirmPassword = false;
  isShowManager = false;
  dropdownList = dropdownList;
  selectedItems = [];
  dropdownSettings = drpSetting.dropdownSettings;
  companyId: number = 0;
  locationId: number;
  roleId: number = 4;
  isShowCompany: boolean = false;
  companyName: string;
  allShift = [];
  phoneMask = phoneMask;
  isValidUser = true;
  appName = environment.APP_NAME;
  methods = [];
  userFailedCount: number = 0;
  showCaptcha: boolean;
  sitekey: string;

  constructor(private router: Router,
    private formService: FormService,
    public validationService: ValidationService,
    private fb: FormBuilder,
    public authService: AuthService,
    private utilityService: UtilityService,
    private userService: UserService,
    private companyService: CompanyService,
    private locationService: LocationService,
    private departmentService: DepartmentService,
    private shiftService: ShiftService,
    private route: ActivatedRoute) {
    this.appName = localStorage.getItem(Constants.APP_NAME);
    document.documentElement.style.setProperty('--bgImg', `url(${localStorage.getItem(Constants.LOGIN_URL)})`);
    router.events.subscribe((event: NavigationStart) => {
      {
        if (event.navigationTrigger === 'popstate') {
          this.router.navigate(['/'])
        }
      }
    })
  }

  ngOnInit() {
    this.sitekey = Constants.RECAPTCHA_SITEKEY;
    if (window.location.hostname.split('.').length > 2 && window.location.hostname.split('.')[0] !== 'www') {
      this.companyName = window.location.hostname.split('.')[0];
    } else {
      this.route.queryParams.subscribe(params => {
        if (!params.hasOwnProperty('company')) {
          this.router.navigate(['/signup'], { queryParams: { company: '' } });
        } else {
          this.companyName = params['company'];
        }
      });
    }
    //this.selectedItems = this.dropdownList;
    this.initializeSignUpForm();
    this.initializeMessages();
  }
  initializeSignUpForm() {
    this.signUpForm = this.fb.group(
      {
        companyName: new FormControl('', Validators.required),
        managerId: new FormControl(''),
        emailId: new FormControl('', [
          Validators.required,
          Validators.pattern(Constants.REGEX.EMAIL_PATTERN),
        ]),
        phone: new FormControl('', [
          Validators.required,
          // Validators.pattern(Constants.REGEX.PHONE_PATTERN)
        ]),
        name: new FormControl('', Validators.required),
        companyUsername: new FormControl('', Validators.required),
        employeeId: new FormControl('', Validators.required),
        isRecievingTextMessage: new FormControl(false),
        GetCommunicationMethod: new FormControl([], Validators.required),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(16),
        ]),
        confirmPassword: new FormControl('', Validators.required),
        isActive: new FormControl(true),
        roleId: new FormControl(5),
        vCode: new FormControl(''),
        userId: new FormControl(0)
      },
      {
        validators: this.validationService.confirmedValidator(
          'password',
          'confirmPassword',
        ),
      },
    );
  }

  initializeMessages() {
    this.messageList.companyName = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMPANY_REQUIRED
    };
    this.messageList.locationId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.LOCATION_REQUIRED,
    };
    this.messageList.departmentId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.DEPARTMENT_REQUIRED,
    };

    this.messageList.subDepartmentId = {
      required: Constants.VALIDATION_MSG.SUB_DEPARTMENT.SUB_DEPARTMENT_NAME_REQUIRED,
    };
    this.messageList.shiftId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.SHIFT_REQUIRED,
    };
    this.messageList.email = {
      required: Constants.VALIDATION_MSG.SIGN_UP.EMAIL_REQUIRED,
      pattern: Constants.VALIDATION_MSG.SIGN_UP.EMAIL_INVALID,
    };
    this.messageList.phoneNumber = {
      required: Constants.VALIDATION_MSG.SIGN_UP.PHONE_REQUIRED,
      // pattern: Constants.VALIDATION_MSG.SIGN_UP.PHONE_INVALID
    };
    this.messageList.name = {
      required: Constants.VALIDATION_MSG.SIGN_UP.NAME_REQUIRED,
    };
    this.messageList.companyUsername = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMPANY_NAME_REQUIRED,
    };
    this.messageList.employeeId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.EMPLOYEEID_REQUIRED,
    };
    this.messageList.password = {
      required: Constants.VALIDATION_MSG.SIGN_UP.PASSWORD_REQUIRED,
    };
    this.messageList.confirmPassword = {
      required: Constants.VALIDATION_MSG.SIGN_UP.CONFIRM_PASSWORD_REQUIRED,
      confirmedValidator: Constants.VALIDATION_MSG.SIGN_UP.PASSWORD_CONFIRM_PASSWORD_NOT_MATCH
    };
    this.messageList.GetCommunicationMethod = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMMUNICATION_METHOD_REQUIRED
    }
  }

  getCompany() {
    this.companyService.getCompanyListWithOutPagination().then(res => {
      if (res['Success']) {
        this.companyList = res['Data'];
        if (!!this.companyName) {
          const index = this.companyList.findIndex(x => x.companyName.toLowerCase() === this.companyName.toLowerCase());
          if (index !== -1) {
            this.companyId = this.companyList[index].companyId;
            this.isShowCompany = !!this.companyId ? false : true;
          } else {
            this.isShowCompany = true;
            this.utilityService.showErrorToast(Constants.COMPANY_NOT_EXIST_MSG);
          }
        } else {
          this.isShowCompany = true;
        }
        if (!!this.companyId) {
          this.getDepartment();
          this.getShift();
          this.getLocation();
        }
      } else { this.companyList = []; }
    }, err => { this.companyList = []; });
  }

  onLocationChange(event: any) {
    const id = Number(event.currentTarget.value);
    if (!!id) {
      this.locationId = id;
      this.getDepartment();
      this.getManager();
      this.getShift();
      
    } else {
      this.locationId = 0;
      this.signUpForm.get('departmentId').setValue('');
      this.signUpForm.get('shiftId').setValue('');
      this.signUpForm.get('locationId').setValue('');
    }
    this.checkValidUser();
  }

  onCompanyChange(event: any) {
    const id = Number(event.currentTarget.value);
    if (!!id) {
      this.companyId = id;
      this.getDepartment();
      this.getManager();
      this.getShift();
      this.getLocation();
    } else {
      this.companyId = 0;
      this.departmentList = [];
      this.managerUserList = [];
      this.shiftList = [];
      this.signUpForm.get('departmentId').setValue('');
      this.signUpForm.get('shiftId').setValue('');
      this.signUpForm.get('locationId').setValue('');
    }
    this.checkValidUser();
  }

  getLocation() {
    this.locationService.getLocationListByCompany(this.companyId, null).then(res => {
      if (res['Success']) {
        this.locationList = res['Data'];
      } else { this.locationList = [];
        this.signUpForm.controls.locationId.setValue(''); }
    }, err => { this.locationList = []; });
  }

  getDepartment() {
    this.departmentService.getDepartmentListByCompanyId(null, this.companyId).then(res => {
      if (res['Success']) {
        this.departmentList = res['Data'];
      } else { this.departmentList = [];
        this.signUpForm.controls.departmentId.setValue('');
        this.subDepartmentList = [];
        this.signUpForm.controls.subDepartmentId.setValue('');

       }
    }, err => { this.departmentList = []; });
  }

  getManager() {
    this.userService.getAllManagerList(this.companyId, this.locationId).then(res => {

      if (res['Success']) {
        this.managerUserList = res['Data'];
      } else { this.managerUserList = [];
       }
    }, err => { this.managerUserList = []; });
  }

  getShift() {
    this.shiftService.getShiftListByCompanyId(null, this.companyId).then(res => {
      if (res['Success']) {
        this.allShift = res['Data'];
        this.shiftList = [];
      } else { this.shiftList = []; this.allShift = [];
        this.signUpForm.controls.shiftId.setValue(''); }
    }, err => { this.shiftList = []; this.allShift = []; })
  }

  onItemSelect(item: any) {
    this.selectedItems.push(item);
    this.signUpForm.controls.GetCommunicationMethod.setValue(this.selectedItems);
  }
  onSelectAll(items: any) {
    this.selectedItems = items;
    this.signUpForm.controls.GetCommunicationMethod.setValue(this.selectedItems);
  }

  onItemDeSelect(items: any) {
    this.selectedItems = this.selectedItems.filter(x => x.id !== items.id);
    this.signUpForm.controls.GetCommunicationMethod.setValue(this.selectedItems);
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.signUpForm.controls.GetCommunicationMethod.setValue(this.selectedItems);
  }

  toggleEyeShow() {
    this.eyeShow = !this.eyeShow;
  }

  toggleisShowConfirmPassword() {
    this.isShowConfirmPassword = !this.isShowConfirmPassword;
  }

  backToLogin() {
    this.router.navigate([`${URL_ROUTES.LOGIN}`]);
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.signUpForm);
    if (this.userFailedCount === 3)
      return
    if (this.signUpForm.invalid) {
      this.userFailedCount++;
      if (this.userFailedCount === 3) {
        this.showCaptcha = true
      };
      return;
    }
    const originalValue = this.signUpForm.get('GetCommunicationMethod').value;

    if (this.signUpForm.value.GetCommunicationMethod.length > 0) {
      const communicationMethod = this.signUpForm.value.GetCommunicationMethod.map(x => x.id.toString());
      this.signUpForm.get('GetCommunicationMethod').patchValue(communicationMethod);
    } else {
      this.userFailedCount++;
      if (this.userFailedCount === 3) {
        this.showCaptcha = true;
      }
      this.signUpForm.get('GetCommunicationMethod').patchValue(originalValue);
      this.utilityService.showErrorToast(Constants.VALIDATION_MSG.SIGN_UP.COMMUNICATION_METHOD_REQUIRED);
      return;
    }
    const signUpData = {
      ...this.signUpForm.value,
    };
    delete signUpData.confirmPassword;
    this.authService.signUp(signUpData).then((res: any) => {
      if (res['Success']) {
        this.utilityService.showSuccessToast('User signup successfully!');
        this.router.navigate(['']);
      } else {
        this.userFailedCount++;
        if (this.userFailedCount === 3) {
          this.showCaptcha = true
        };
        this.utilityService.showErrorToast(res['Message']);
        this.selectedItems = originalValue;
        this.signUpForm.get('GetCommunicationMethod').patchValue(originalValue);
      }
    });
  }

  onDepartmentChange(event) {
    const id = event.currentTarget.value;
    if (!!id) {
      this.shiftList = this.allShift;
      this.shiftList = this.shiftList.filter(x => x.departmentId === Number(id));
      this.getSubDeptByDepartment(Number(event.currentTarget.value), this.companyId);
    } else {
      this.shiftList = [];
    }
    this.signUpForm.controls.shiftId.setValue('');
  }

  getSubDeptByDepartment(departmentId, companyId) {
    this.signUpForm.controls.subDepartmentId.setValue('');
    this.departmentService.getSubDeptByDepartment(departmentId, companyId)
      .then(response => {
        if (response['Success']) {
          this.subDepartmentList = response['Data'];
        } else {
          this.subDepartmentList = [];
          this.signUpForm.controls.subDepartmentId.setValue('');
        }
      }, err => { this.subDepartmentList = []; });
  }

  captchaSolved(event) {
    if (event === null)
      this.userFailedCount = 3
    else
      this.userFailedCount = 0;
      this.showCaptcha = false
  }

  checkValidUser() {
    if (!!this.signUpForm.controls.isRecievingTextMessage.value) {
      this.isValidUser = true;
    } else {
      this.isValidUser = false;
    }
  }
}
