import { Directive, EventEmitter, HostListener, OnDestroy, OnInit, Output } from "@angular/core";

@Directive({
    selector: '[appMouseMovement]'
})
export class MouseMovementDirective implements OnInit {
    @Output() mouseOver = new EventEmitter<boolean>();
    ngOnInit(): void {}

    @HostListener('document:mouseover', ['$event']) 
    onMouseMove(e) {
        this.mouseOver.emit(true);
    }
    
}