import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShiftRoutingModule } from './shift-routing.module';
import { ShiftComponent } from './shift.component';

import { AddComponent } from './add/add.component';
import { SharedModule } from '../shared/shared/shared.module';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { ShiftService } from '../service/shift.service';
import { CompanyService } from '../service/company.service';
import { DepartmentService } from '../service/department.service';
import { LocationService } from '../service/location.service';
import { DirectiveModule } from '../shared/shared/directive/directive.module';

@NgModule({
  declarations: [ShiftComponent, AddComponent],
  imports: [
    CommonModule,
    SharedModule,
    ShiftRoutingModule,
    NgxBootstrapIconsModule.pick(allIcons),
    DirectiveModule
  ],
  providers: [ShiftService, CompanyService, DepartmentService, LocationService]
})
export class ShiftModule { }
