import { Component, OnInit, ViewChild, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { bsConfig, timeList, Constants, flexType } from 'src/app/constant/constants';
import { Offer } from 'src/app/models/offer.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Role } from 'src/app/models/role-model';
import { FormService } from 'src/app/core/form.service';
import { DepartmentService } from 'src/app/service/department.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ShiftService } from 'src/app/service/shift.service';
import { FlexRequestService } from 'src/app/service/flex-request.service';

@Component({
  selector: 'app-add-edit-flex-work-request',
  templateUrl: './add-edit-flex-work-request.component.html',
  styleUrls: ['./add-edit-flex-work-request.component.scss']
})
export class AddEditFlexWorkRequestComponent implements OnInit {
  @ViewChild('confirmationPopup', { static: false }) confirmationPopup: TemplateRef<any>;
  @Input() flexOffer: Offer;
  @Output() closeRequest = new EventEmitter<boolean>();
  @Output() cancleRequest = new EventEmitter<boolean>();
  public offerForm: FormGroup;
  bsConfig = bsConfig;
  departmentList = [];
  companyId: number;
  public messageList: any = new Offer();
  modalRef: BsModalRef;
  confirmData: any;
  timeList = timeList;
  role: number;
  roleEnum = Role;
  today = new Date();
  tomorrow = new Date(this.today);
  nextMinDate = new Date(this.tomorrow.setDate(this.tomorrow.getDate() + 1));
  shiftId: number;
  shiftDetail: any;
  weekDayArray = [];
  shiftList = [];

  constructor(
    private modalService: BsModalService,
    private formService: FormService,
    private departmentService: DepartmentService,
    private toaster: ToastrService,
    private flexService: FlexRequestService,
    private datepipe: DatePipe,
    private bsService: BsModalService,
    private shiftService: ShiftService
  ) { }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.getDepartmentList();
    this.initializeOfferForm();
    this.initializeMessages();
  }


  async initializeOfferForm() {
    this.offerForm = new FormGroup(
      {
        offerId: new FormControl(!!this.flexOffer ? this.flexOffer.offerId : 0),
        dateToSkip: new FormControl(!!this.flexOffer ? new Date(this.flexOffer.dateToSkip) : null, Validators.required),
        companyId: new FormControl(this.companyId),
        departmentId: new FormControl(!!this.flexOffer ? this.flexOffer.departmentId : '', Validators.required),
        shiftToSkip: new FormControl(!!this.flexOffer ? this.flexOffer.shiftToSkip : ''),
        vtoStartTime: new FormControl('', Validators.required),
        vtoEndTime: new FormControl('', Validators.required),
        FlexWorkType: new FormControl(!!this.flexOffer ? this.flexOffer.FlexWorkType : null),
        timeType: new FormControl(!!this.flexOffer ? this.flexOffer.timeType : '', Validators.required),
        vtoCount: new FormControl(!!this.flexOffer ? this.flexOffer.vtoCount : null),
        status: new FormControl(!!this.flexOffer ? this.flexOffer.status : this.role === this.roleEnum.user ? 1 : 4),
        offerType: new FormControl(5),
        isVtoSms: new FormControl(!!this.flexOffer ? !!this.flexOffer.isVtoSms : true),
        createdBy: new FormControl(!!this.flexOffer ? this.flexOffer.createdBy : null),
        createdDate: new FormControl(!!this.flexOffer ? this.flexOffer.createdDate : null)
      },
    );

    if (this.role === this.roleEnum.hrAdmin) {
      this.offerForm.controls.vtoCount.setValidators([Validators.required, Validators.pattern(Constants.REGEX.FLEX_LIMIT_PATTERN)]);
      this.offerForm.controls.shiftToSkip.setValidators([Validators.required]);
      this.offerForm.controls.shiftToSkip.updateValueAndValidity();
      this.offerForm.controls.vtoCount.updateValueAndValidity();
    } else {
      this.shiftId = Number(localStorage.getItem(Constants.SHIFTID));
      this.offerForm.controls.shiftToSkip.setValue(this.shiftId);
      this.offerForm.controls.departmentId.setValue(Number(localStorage.getItem(Constants.DEPARTMENTID)));
    }
  }

  initializeMessages() {
    this.messageList.noOfUsers = {
      required: Constants.VALIDATION_MSG.VTO.NO_OF_USER,
      pattern: Constants.VALIDATION_MSG.VTO.NO_Of_USER_FLEX_PATTERN
    };
    this.messageList.endTime = {
      required: Constants.VALIDATION_MSG.VTO.END_TIME,
    };
    this.messageList.startTime = {
      required: Constants.VALIDATION_MSG.VTO.START_TME,
    };
    this.messageList.date = {
      required: Constants.VALIDATION_MSG.VTO.DATE,
    }
    this.messageList.departmentId = {
      required: Constants.VALIDATION_MSG.VTO.DEPARTMENT_ID,
    }
    this.messageList.shiftToSkip = {
      required: Constants.VALIDATION_MSG.VTO.SHIFT_ID,
    }
    this.messageList.timeType = {
      required: Constants.VALIDATION_MSG.OFFER.TIME_TYPE_REQUIRED,
    }
  }

  getDepartmentList() {
    this.departmentService.getDepartmentListByCompanyId(null, this.companyId).then(res => {
      if (res['Success']) {
        this.departmentList = res['Data'];
      } else { this.departmentList = []; }
    }, err => { this.departmentList = []; });
  }

  control(controlName: string): AbstractControl {
    return this.offerForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.offerForm);
    if (this.offerForm.invalid) {
      return;
    }
    this.openConfirmationPopup(this.confirmationPopup, this.offerForm.value)
  }

  save() {
    this.formService.markFormGroupTouched(this.offerForm);
    if (this.offerForm.invalid) {
      return;
    }
    this.confirm();
  }

  openConfirmationPopup(template: TemplateRef<any>, data: any) {
    this.confirmData = data;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-md',
      backdrop: 'static'
    });
  }

  cancel() {
    this.modalRef.hide();
  }

  confirm() {
    const mxdate = this.datepipe.transform(this.setnewDate(this.offerForm.controls.dateToSkip.value), 'yyyy-MM-dd');
    const startTimeValue = this.offerForm.controls.vtoStartTime.value;
    const endTimeValue = this.offerForm.controls.vtoEndTime.value;
    this.offerForm.controls.dateToSkip.setValue(mxdate);
    if (!!this.offerForm.controls.vtoStartTime.value) {
      this.offerForm.controls.vtoStartTime.setValue(new Date(startTimeValue).getHours() +
        ":" +
        new Date(startTimeValue).getMinutes());
    }
    if (!!this.offerForm.controls.vtoEndTime.value) {
      this.offerForm.controls.vtoEndTime.setValue(new Date(endTimeValue).getHours() +
        ":" +
        new Date(endTimeValue).getMinutes());
    }
    this.offerForm.controls.departmentId.setValue(Number(this.offerForm.controls.departmentId.value));
    this.offerForm.controls.shiftToSkip.setValue(!!this.offerForm.controls.shiftToSkip.value ? Number(this.offerForm.controls.shiftToSkip.value) : null);
    this.offerForm.controls.vtoCount.setValue(Number(this.offerForm.controls.vtoCount.value));
    this.offerForm.controls.FlexWorkType.setValue(this.role === this.roleEnum.user ? flexType.myRequest : flexType.hrOffer);

    const saveMethod = !!this.offerForm.controls.offerId.value ?
      this.flexService.updateFlexRequestOffer(this.offerForm.value) :
      this.flexService.addFlexRequestOffer(this.offerForm.value);
    saveMethod.then(response => {
      if (response['Success']) {
        this.closeRequest.emit(true);
        if (this.role === this.roleEnum.hrAdmin) {
          this.bsService.hide(1);
          this.modalRef.hide();
        }
        this.toaster.success(this.offerForm.controls.offerId.value > 0 ?
          Constants.FLEX_REQUEST_UPDATE_SUCCESS_MSG : Constants.FLEX_REQUEST_ADD_SUCCESS_MSG);
      } else {
        this.toaster.error(response['Message']);
        this.offerForm.controls.dateToSkip.setValue(new Date(mxdate));
        if (this.role === this.roleEnum.hrAdmin) {
          this.setTimes(this.offerForm.controls.vtoStartTime.value, this.offerForm.controls.vtoEndTime.value);
        }
      }
    }, err => { });
  }

  closeModal() {
    this.closeRequest.emit(false);
  }

  setnewDate(date) {
    if (date.getTime()) {
      const dateObj = {
        year: +(this.datepipe.transform(date, 'yyyy')),
        month: +(this.datepipe.transform(date, 'MM')),
        day: +(this.datepipe.transform(date, 'dd'))
      };
      return new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day']);
    }
    return new Date(date)
  }

  closePopUp() {
    this.cancleRequest.emit(true);
  }

  departmentChange(event) {
    this.offerForm.controls.shiftToSkip.setValue('');
    this.offerForm.controls.timeType.setValue('');
    this.offerForm.controls.vtoStartTime.setValue(null);
    this.offerForm.controls.vtoEndTime.setValue(null);
    if (!!event.currentTarget) {
      if (!!event.currentTarget.value) {
        if (this.role === this.roleEnum.hrAdmin) {
          this.getShiftByDepartment(Number(event.currentTarget.value));
        }
      }
    } else {
      if (!!event) {
        if (this.role === this.roleEnum.hrAdmin) {
          this.getShiftByDepartment(Number(event));
        }
      }
    }
  }

  getShiftByDepartment(departmentId) {
    this.shiftService.getShiftListByDepartment(departmentId)
      .then(response => {
        if (response['Success']) {
          this.shiftList = response['Data'];
        } else {
          this.shiftList = [];
        }
      }, err => { this.shiftList = []; });
  }

  onShiftChange(event) {
    this.offerForm.controls.timeType.setValue('');
    this.offerForm.controls.vtoStartTime.setValue(null);
    this.offerForm.controls.vtoEndTime.setValue(null);
    if (!!event.currentTarget.value && !!this.offerForm.controls.timeType.value
      && !!this.offerForm.controls.dateToSkip.value) {
      const skipDate = this.datepipe.transform(this.setnewDate(this.offerForm.controls.dateToSkip.value), 'yyyy-MM-dd');
      this.getTimeByShiftDateTime(skipDate, event.currentTarget.value, this.offerForm.controls.timeType.value);
    }
  }

  onTimeChange(event) {
    this.offerForm.controls.vtoStartTime.setValue(null);
    this.offerForm.controls.vtoEndTime.setValue(null);
    if (!!event.currentTarget.value && !!this.offerForm.controls.dateToSkip.value
      && !!this.offerForm.controls.shiftToSkip.value) {
      const skipDate = this.datepipe.transform(this.setnewDate(this.offerForm.controls.dateToSkip.value), 'yyyy-MM-dd');
      this.getTimeByShiftDateTime(skipDate, this.offerForm.controls.shiftToSkip.value, event.currentTarget.value);
    }
  }

  getTimeByShiftDateTime(date, shift, time) {
    this.shiftService.getTimeByShiftDateTime(date, Number(shift), Number(time))
      .then(response => {
        if (response['Success']) {
          this.setTimes(response['Data'].startTime, response['Data'].endTime);
        } else {
          this.offerForm.controls.vtoStartTime.setValue(null);
          this.offerForm.controls.vtoEndTime.setValue(null);
        }
      }, err => { });
  }

  skipDateChange(event) {
    if (!!event && event.getTime()) {
      const skipDate = this.datepipe.transform(this.setnewDate(event), 'yyyy-MM-dd');
      this.offerForm.controls.vtoStartTime.setValue(null);
      this.offerForm.controls.vtoEndTime.setValue(null);
      if (!!this.offerForm.controls.shiftToSkip.value &&
        !!this.offerForm.controls.timeType.value) {
        this.getTimeByShiftDateTime(skipDate, this.offerForm.controls.shiftToSkip.value, this.offerForm.controls.timeType.value);
      }
    }
  }

  getName(data, type) {
    if (type === 'shift') {
      return this.shiftList.find(x => x.shiftId === Number(data.shiftToSkip)).title;
    }
    if (type === 'time') {
      return this.timeList.find(x => x.id === Number(data.timeType)).value;
    }
    if (type === 'department') {
      return this.departmentList.find(x => x.departmentId === Number(data.departmentId)).departmentName;
    }
  }

  setTimes(startTime, endTime) {
    if (!!startTime) {
      const startdate = new Date();
      startdate.setHours(
        Number(startTime.split(":")[0]),
        Number(startTime.split(":")[1])
      );
      this.offerForm.controls.vtoStartTime.setValue(startdate);
    } else {
      this.toaster.error(Constants.SHIFT_START_TIME_CONFIGURE);
      this.offerForm.controls.vtoStartTime.setValue(null);
    }
    if (!!endTime) {
      const enddate = new Date();
      enddate.setHours(
        Number(endTime.split(":")[0]),
        Number(endTime.split(":")[1])
      );
      this.offerForm.controls.vtoEndTime.setValue(enddate);
    } else {
      this.toaster.error(Constants.SHIFT_END_TIME_CONFIGURE);
      this.offerForm.controls.vtoEndTime.setValue(null);
    }
  }
}
