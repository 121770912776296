import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';


@Injectable()
export class ForgotpasswordService {

  constructor(private apiService: ApiService) { }

  addEmail(email) {
    return this.apiService.post(`${API.FORGOT_PASSWORD_ROUTES.forgotPassword}?email=${email}&isMobile=false`, null, true);
  }
}
