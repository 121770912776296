import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Constants } from 'src/app/constant/constants';
import { Role, OfferStatus } from 'src/app/models/role-model';
import { VtoRequestService } from 'src/app/service/vto-request.service';
import { UtilityService } from 'src/app/service/utility.service';

@Component({
  selector: 'app-pending-vto-request',
  templateUrl: './pending-vto-request.component.html',
  styleUrls: ['./pending-vto-request.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PendingVtoRequestComponent implements OnInit {
  @ViewChild('table', { static: false }) table: any;
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  vtoRequestList = [];
  totalItems: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  columns = [];
  searchSort: any;
  role: number;
  roleEnum = Role;
  companyId: number;
  offerStatus = OfferStatus;

  constructor(private vtoService: VtoRequestService,
    private utilityService: UtilityService) {
    this.columns = ['departmentName', 'vtoTypestr', 'dateToSkipDateStr', 'vtoStartTime', 'vtoEndTime', 'vtoCount', 'createdByUsername'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getVtoRequestList(this.page.pageNumber + 1);
  }

  getVtoRequestList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      this.vtoService.getPendingApprovalVtoRequestList(currentPage, this.rowsOnPage)
        .then(res => {
          if (res['Success']) {
            this.vtoRequestList = res['Data'].results;
            this.totalItems = res['Data'].totalNumberOfRecords;
          } else {
            this.vtoRequestList = [];
            this.totalItems = 0;
          }
        }, err => { this.vtoRequestList = []; });
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clear();
      }
    }
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  clear() {
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  filterData() {
    this.vtoService.vtoPendingApprovalRequestDataFilter(this.searchSort).then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.vtoRequestList = res['data'];
        this.totalItems = res['recordsFiltered'];
      } else { this.vtoRequestList = []; this.totalItems = 0; }
    }, err => { this.vtoRequestList = []; this.totalItems = 0; });
  }

  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }

  approveRejectOffer(type, data) {
    const putMethod = type === 1 ? this.vtoService.approveVtoOffer(data.vtoDetailId)
      : this.vtoService.rejectVtoOffer(data.vtoDetailId);
    putMethod.then(response => {
      if (response['Success']) {
        this.utilityService.showSuccessToast(type === 1 ? Constants.VTO_REQUEST_APPROVE_SUCCESS_MSG : Constants.VTO_REQUEST_DENIED_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      } else {
        this.utilityService.showErrorToast(response['Message']);
      }
    }, err => { });
  }

  detailVtoOffer(offer) {
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {

  }
}
