import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from './../constant/constants';


@Injectable({
  providedIn: 'root'
})
export class EmployeeRosterService {
  constructor(private apiService: ApiService) { }

  getEmployeeRosterList(currentPage,companyId) {
    return this.apiService.get(
      `${API.EMPLOYEE_ROSTER.EmployeeRoster}?companyId=` +
      companyId + `&page=` + currentPage,
      true,
      true,
    );
  }

  addUserValidation(data) {
    return this.apiService.post(
      `${API.EMPLOYEE_ROSTER.EmployeeRoster}`,
      data,
      true,
    );
  }
  updateUserValidation(data) {
    return this.apiService.put(
      `${API.EMPLOYEE_ROSTER.EmployeeRoster}`,
      data,
      true,
    );
  }
  deleteUserValidation(id){
    return this.apiService.delete(
      `${API.EMPLOYEE_ROSTER.EmployeeRoster}?id=${id}`,
      true,
    );
  }
  DataFilter(params){
    return this.apiService.post(`${API.EMPLOYEE_ROSTER.dataFilter}`, params, true);
  }
}