import { Role } from 'src/app/models/role-model';
import { Constants } from 'src/app/constant/constants';
import { environment } from 'src/environments/environment';

export const Menu = {
    [Role.hrAdmin]: [
        { title: !!localStorage.getItem(Constants.APP_NAME) ? localStorage.getItem(Constants.APP_NAME) : environment.APP_NAME + ' Requests', link: '/layout/employer' },
    ],
    [Role.user]: [
        { title: 'Offer', link: '/layout/offer' },
    ],
    [Role.manager]: [
        { title: 'Requests', link: '/layout/offer' },
    ],
    [Role.purchaser]: [
        { title: 'Plan Detail', link: '/layout/plan' },
    ],
    [Role.learningAdmin]: [
        { title: 'Training Register', link: '/layout/LearningAdmin' },
    ]
}