import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxFileDropEntry,FileSystemFileEntry } from 'ngx-file-drop';
import { Constants } from 'src/app/constant/constants';
import { LocationService } from 'src/app/service/location.service';
import { UserService } from 'src/app/service/user.service';
import { UtilityService } from 'src/app/service/utility.service';

@Component({
  selector: 'app-file-upload-employees',
  templateUrl: './file-upload-employees.component.html',
  styleUrls: ['./file-upload-employees.component.scss']
})
export class FileUploadEmployeesComponent implements OnInit {
  downloadFileName = 'AddEmployeeSampleFile.csv';
  downloadFilePath = '/assets/attachment/AddEmployeeSampleFile.csv';
    @Output() close = new EventEmitter<boolean>();
    public files: NgxFileDropEntry[] = [];
    isUpload = false;
    fileExtension = Constants.FILE_EXTENSIONS;
  constructor(
    private utility: UtilityService,
        private userService: UserService,
        private locationService: LocationService
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.close.emit(false);
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    const extension = this.files[0]['relativePath'].split('.').pop().toUpperCase();
    if (!['CSV', 'XLS', 'XLSX'].includes(extension)) {
      this.utility.showErrorToast('Invalid file!');
      return false;
    }
    this.isUpload = true;
  }

  upload() {
    const data: FormData = new FormData();
    const fileEntry = this.files[0].fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      data.append('file', file);
      this.userService.fileUploadForEmployees(data).then(res => {
        console.log(res)
        if (res['Success']) {
          
         setTimeout(() => {
          this.utility.showSuccessToast(Constants.FILE_UPLOAD_SUCCESS_MSG_EMPLOYEE);
          this.utility.hideLoading();
          this.close.emit(true);
         }, 3000);
        } else {
          this.utility.hideLoading();
          this.utility.showErrorToast(res['Message']);
        }
        this.utility.hideLoading();
      }, err => { });
    })
  }
}
