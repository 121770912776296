import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';


@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(private apiService: ApiService) { }

  getPlanDetails(id) {
    return this.apiService.get(
      `${API.PLAN_ROUTES.getPlanNew}?id=${id}`,
      false,
      true,
    );
  }

  updatePlan(params) {
    return this.apiService.put(`${API.PLAN_ROUTES.updatePlan}`, params, true);
  }

  getState() {
    return this.apiService.get(
      `${API.PLAN_ROUTES.getState}`,
      true,
      true,
    );
  }

  getUserByPricingId(userId) {
    return this.apiService.get(
      `${API.PLAN_ROUTES.getUserByPricing}?id=` + userId
     ,
      true,
      true,
    );
  }
}
