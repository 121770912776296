import { Component, OnInit, ViewChild } from '@angular/core';
import { Constants, flexType } from 'src/app/constant/constants';
import { Role, OfferStatus } from 'src/app/models/role-model';
import { Offer } from 'src/app/models/offer.model';
import { FlexRequestService } from 'src/app/service/flex-request.service';
import { ShiftService } from 'src/app/service/shift.service';
import { DepartmentService } from 'src/app/service/department.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-flex-work-request',
  templateUrl: './flex-work-request.component.html',
  styleUrls: ['./flex-work-request.component.scss']
})
export class FlexWorkRequestComponent implements OnInit {
  @ViewChild('table', { static: false }) table: any;
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  @ViewChild('workDateValue', { static: false }) workDateValue;
  @ViewChild('shiftValue', { static: false }) shiftValue;
  @ViewChild('departmentValue', { static: false }) departmentValue;
  flexRequestList = [];
  totalItems: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  role: number;
  roleEnum = Role;
  searchSort: any;
  columns = [];
  flexOffer: Offer;
  offerStatus = OfferStatus;
  vtoType = flexType;
  shiftList = [];
  departmentList = [];
  filterValue = [];
  today = new Date();
  nextweekDate = new Date(this.today.getTime() + 7 * 24 * 60 * 60 * 1000);
  nextDate: any;
  searchDate: any;

  constructor(
    private flexService: FlexRequestService,
    private shiftService: ShiftService,
    private departmentService: DepartmentService,
    private datepipe: DatePipe
  ) {
    this.columns = ['departmentId', 'dateToSkip', 'shiftToSkip', 'departmentName', 'offerStatusStr', 'dateToSkipShortDate', 'timeTypeStr', 'FlexWorkType', 'vtoStartTime', 'vtoEndTime', 'FlexTypestr', 'createdByUsername', 'acceptedByUsername', 'approvedByObj.companyUsername', 'acceptedByShift', 'shiftToSkipTitle', 'processedByObj.companyUsername'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Columns: [
      ],
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.setPage({ offset: 0 });
    if (this.role === this.roleEnum.hrAdmin) {
      this.getShiftList();
      this.getDepartmentList();
    }
  }

  ngAfterViewInit() {
    if (this.role === this.roleEnum.hrAdmin) {
      // this.workDateValue.nativeElement.value = this.nextDate;
      this.filterValue.push('');
      this.filterValue.push(this.shiftValue.nativeElement.value);
      this.filterValue.push(this.departmentValue.nativeElement.value);
      this.columns.forEach((element, i) => {
        if (i < 3) {
          const obj = {
            Data: 'string',
            Searchable: true,
            Orderable: true,
            Name: element,
            Search: {
              Value: this.filterValue[i],
              Regex: 'string'
            }
          }
          this.searchSort.Columns.push(obj);
        }
      });
    }
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getFlexRequestList(this.page.pageNumber + 1);
  }

  getFlexRequestList(currentPage) {
    if ((!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) ||
      (!!this.shiftValue && !!this.shiftValue.nativeElement.value) ||
      (!!this.departmentValue && !!this.departmentValue.nativeElement.value) ||
      (!!this.workDateValue && this.workDateValue.nativeElement.value)) {
      this.workDateValue.nativeElement.value = this.datepipe.transform(this.setnewDate(this.nextweekDate), 'yyyy-MM-dd');
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      if (this.role === this.roleEnum.hrAdmin) {
        this.nextDate = this.datepipe.transform(this.setnewDate(this.nextweekDate), 'yyyy-MM-dd');
      } else {
        this.nextDate = null;
      }
      this.flexService.getMyFlexRequestList(currentPage, this.rowsOnPage, this.nextDate)
        .then((res: any) => {
          if (res['Success']) {
            this.flexRequestList = res.Data.results;
            //this.vtoRequestList = [...this.vtoRequestList];
            this.totalItems = res.Data.totalNumberOfRecords;
          } else {
            this.flexRequestList = [];
            this.totalItems = 0;
          }
        }, err => { this.flexRequestList = []; });
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clear();
      }
    }
  }

  clearText() {
    this.searchSort.Search.Value = '';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  clear() {
    if (this.role === this.roleEnum.user) {
      this.searchSort.Search.Value = '';
      this.searchSort.Order[0].ColumnName = '';
      this.searchSort.Order[0].Dir = 'asc';
      this.filterTextValue.nativeElement.value = '';
      this.setPage({ offset: 0 });
    } else {
      this.searchSort.Search.Value = '';
      this.searchSort.Order[0].ColumnName = '';
      this.searchSort.Order[0].Dir = 'asc';
      this.filterTextValue.nativeElement.value = '';
      this.searchSort.Columns.forEach(element => {
        element.Search.Value = '';
      });
      this.shiftValue.nativeElement.value = '';
      this.departmentValue.nativeElement.value = '';
      // this.workDateValue.nativeElement.value = this.nextDate;
      this.workDateValue.nativeElement.value = '';
      this.searchDate = this.nextDate;
      this.setPage({ offset: 0 });
    }
  }

  filterData() {
    this.flexService.flexRequestOfferDataFilter(this.searchSort, this.role === this.roleEnum.hrAdmin ? !!this.searchDate ? this.searchDate : this.nextDate : null)
      .then(res => {
        if (res['data'] && res['data'].length > 0) {
          this.flexRequestList = res['data'];
          this.totalItems =  res['recordsFiltered'];
        } else { this.flexRequestList = []; this.totalItems = 0; }
      }, err => { this.flexRequestList = []; this.totalItems = 0; });
  }

  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }

  getShiftList() {
    this.shiftService.getShiftListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID)))
      .then(response => {
        this.shiftList = response['Data'];
      }, err => { this.shiftList = []; });
  }

  getDepartmentList() {
    this.departmentService.getDepartmentListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID))).then(res => {
      if (res['Success']) {
        this.departmentList = res['Data'];
      } else { this.departmentList = []; }
    }, err => { this.departmentList = []; });
  }

  workDateChange(event) {
    this.searchDate = null;
    const findDateColumn = this.searchSort.Columns.find(x => x.Name === 'dateToSkip');
    if (!!findDateColumn) {
      findDateColumn.Search.Value = this.datepipe.transform(this.setnewDate(event), 'yyyy/MM/dd');
      // findDateColumn.Search.Value = '';
      this.searchDate = this.datepipe.transform(this.setnewDate(event), 'yyyy-MM-dd');
    }
  }

  setnewDate(date) {
    const dateObj = {
      year: +(this.datepipe.transform(date, 'yyyy')),
      month: +(this.datepipe.transform(date, 'MM')),
      day: +(this.datepipe.transform(date, 'dd'))
    };
    return new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day']);
  }

  shiftChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(x => x.Name === 'shiftToSkip');
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = event.currentTarget.value;
    }
  }

  departmentChange(event) {
    const findDepartmentColumn = this.searchSort.Columns.find(x => x.Name === 'departmentId');
    if (!!findDepartmentColumn) {
      findDepartmentColumn.Search.Value = event.currentTarget.value;
    }
  }
}
