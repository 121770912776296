import { DatePipe } from "@angular/common";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { invalid } from "moment";
import { ThreeDotsVertical } from "ngx-bootstrap-icons";
import { ToastrService } from "ngx-toastr";
import {
  Constants,
  punchTimeUser,
  PunchTypeEnum,
} from "src/app/constant/constants";
import { FormService } from "src/app/core/form.service";
import { addPunchesByHr } from "src/app/models/addpunches-by-hr.model";
import { TimePunchesService } from "src/app/service/time-punches.service";
import { UtilityService } from "src/app/service/utility.service";

@Component({
  selector: "app-add-punches-time",
  templateUrl: "./add-punches-time.component.html",
  styleUrls: ["./add-punches-time.component.scss"],
})
export class AddPunchesTimeComponent implements OnInit {
  addTimePunchesForm: FormGroup;
  messageList: any = new addPunchesByHr();
  @Output() closeRequest = new EventEmitter<string>();
  isSpecificUser: boolean = true;
  punchTimeUser = punchTimeUser;
  punchTypeEnum = PunchTypeEnum;
  checkedValue: string;
  today = new Date();
  constructor(
    private formService: FormService,
    private datepipe: DatePipe,
    private timePunchesService: TimePunchesService,
    private toaster: ToastrService,
    private utility: UtilityService
  ) {
    this.checkedValue = "specific-user";
  }

  ngOnInit() {
    this.initializeTimePunchesForm();
    this.initializeMessages();
  }
  async initializeTimePunchesForm() {
    this.addTimePunchesForm = new FormGroup({
      PunchTime: new FormControl("", Validators.required),
      PunchTimeHoursMinutes: new FormControl(new Date(), Validators.required),
      PunchType: new FormControl("", Validators.required),

      ScheduledOrUsers: new FormControl(
        !!this.checkedValue ? this.checkedValue : "",
        Validators.required
      ),
      UserName: new FormControl("", Validators.required),
    });
  }

  initializeMessages() {
    this.messageList.PunchTime = {
      required: Constants.VALIDATION_MSG.PUNCHESBYHR.PUNCH_TIME,
    };
    this.messageList.PunchType = {
      required: Constants.VALIDATION_MSG.PUNCHESBYHR.PUNCH_TYPE,
    };
    this.messageList.ScheduledOrUsers = {
      required: Constants.VALIDATION_MSG.PUNCHESBYHR.SCHEDULED_OR_USER,
    };
    this.messageList.UserName = {
      required: Constants.VALIDATION_MSG.PUNCHESBYHR.USERNAME,
    };
    this.messageList.PunchTimeHoursMinutes = {
      invalid: Constants.VALIDATION_MSG.PUNCHESBYHR.PUNCH_TIME_BYHRS
    }
  }

  closePopUp() {
    this.closeRequest.emit("Close");
  }


  setChecked(value) {
    this.checkedValue = value;
    this.addTimePunchesForm.controls.ScheduledOrUsers.setValue(
      this.checkedValue
    );
    if (value == "specific-user") {
      this.addTimePunchesForm.controls.UserName.enable();
      this.isSpecificUser = true;
    } else {
      this.addTimePunchesForm.controls.UserName.disable();
      this.isSpecificUser = false;
    }
  }

  saveAndCreate() {
    this.formService.markFormGroupTouched(this.addTimePunchesForm);
    if (this.addTimePunchesForm.invalid) {
      return;
    }
    let todayDate:any = new Date().getTime();
    let selectedDate:any = new Date(this.addTimePunchesForm.controls.PunchTimeHoursMinutes.value).getTime();
    
    if(new Date(this.addTimePunchesForm.controls.PunchTime.value).toDateString() == new Date().toDateString() && todayDate < selectedDate){
      this.toaster.error(Constants.VALIDATION_MSG.PUNCHESBYHR.PUNCH_TIME_BYHRS)
      return;
    } 
    let date = this.addTimePunchesForm.controls.PunchTime.value;
    let punchTimeHoursMinutes =
      this.addTimePunchesForm.controls.PunchTimeHoursMinutes.value;
    let mixedPunchDateTime = this.convertDateTime(
      this.addTimePunchesForm.controls.PunchTime.value,
      punchTimeHoursMinutes
    );

    this.addTimePunchesForm.controls.PunchTime.setValue(mixedPunchDateTime);
    this.removeValueFromObject(
      this.addTimePunchesForm.controls.PunchTime.value,
      "date"
    );
    let timePunchesFormValue = this.addTimePunchesForm.value;

    const saveMethod =
      this.timePunchesService.addTimePunchesByHr(timePunchesFormValue);
    saveMethod.then(
      (res) => {
        if (res["Success"]) {
          this.utility.showSuccessToast(res["Message"]);
          this.closeRequest.emit("SaveAndClose");
          this.addTimePunchesForm.controls.PunchTime.reset();
        } else {
          this.addTimePunchesForm.controls.PunchTime.setValue(new Date(date));
          this.utility.showErrorToast(res["Message"]);
        }
      },
      (err) => {}
    );
  }

  save() {
    this.formService.markFormGroupTouched(this.addTimePunchesForm);
    if (this.addTimePunchesForm.invalid) {
      return;
    }
    let todayDate:any = new Date().getTime();
    let selectedDate:any = new Date(this.addTimePunchesForm.controls.PunchTimeHoursMinutes.value).getTime();
    
    if(new Date(this.addTimePunchesForm.controls.PunchTime.value).toDateString() == new Date().toDateString() && todayDate < selectedDate){
      this.toaster.error(Constants.VALIDATION_MSG.PUNCHESBYHR.PUNCH_TIME_BYHRS)
      return;
    } 
    let date = this.addTimePunchesForm.controls.PunchTime.value;
    let punchTimeHoursMinutes =
      this.addTimePunchesForm.controls.PunchTimeHoursMinutes.value;
    let mixedPunchDateTime = this.convertDateTime(
      this.addTimePunchesForm.controls.PunchTime.value,
      punchTimeHoursMinutes
    );

    this.addTimePunchesForm.controls.PunchTime.setValue(mixedPunchDateTime);
    this.removeValueFromObject(
      this.addTimePunchesForm.value,
      "PunchTimeHoursMinutes"
    );
    let timePunchesFormValue = this.addTimePunchesForm.value;

    const saveMethod =
      this.timePunchesService.addTimePunchesByHr(timePunchesFormValue);
    saveMethod.then(
      (res) => {
        if (res["Success"]) {
          this.utility.showSuccessToast(res["Message"]);
          this.closeRequest.emit('Save');
        } else {
          this.addTimePunchesForm.controls.PunchTime.setValue(new Date(date));
          this.utility.showErrorToast(res["Message"]);
        }
      },
      (err) => {}
    );
  }

  convertDateTime(date1, date2) {
    let newDate = new Date(date1);
    return this.datepipe.transform(
      new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        date2.getHours(),
        date2.getMinutes(),
        0,
        0
      ),
      "yyyy-MM-dd HH:mm"
    );
  }

  removeValueFromObject(values, removeValue1) {
    for (const [key, value] of Object.entries(values)) {
      if (key === removeValue1) {
        delete values[key];
      }
    }
  }
}
