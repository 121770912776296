import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Constants } from "src/app/constant/constants";
import { ModuleFunctionService } from "src/app/service/moduleFunction.service";
import { FormService } from "./../../core/form.service";
import { UtilityService } from "src/app/service/utility.service";
import { FileToUpload } from "src/app/models/fileUpload";

@Component({
  selector: "app-add-edit-module-training-config",
  templateUrl: "./add-edit-module-training-config.component.html",
  styleUrls: ["./add-edit-module-training-config.component.scss"],
})
export class AddEditModuleTrainingConfigComponent implements OnInit {
  @Input() moduleTraining: any;
  @Output() closeRequest = new EventEmitter<boolean>();
  public moduleConfig: any;
  messageList: any = {
    moduleName: String,
    moduleDescription: String,
  };
  theFile: any = null;
  theFiles: any[] = [];
  FileName: String;
  fileObj: any=[];
  constructor(
    private formService: FormService,
    private moduleFunctionService: ModuleFunctionService,
    private toaster: ToastrService,
    private utility: UtilityService,
  ) {}

  ngOnInit() {
    this.initializeForm();
    this.initializeMessages();
  }
  initializeForm() {
    this.moduleConfig = new FormGroup({
      moduleId: new FormControl(
        !!this.moduleTraining ? this.moduleTraining.moduleId : 0
      ),
      moduleName: new FormControl(
        !!this.moduleTraining ? this.moduleTraining.moduleName : "",
        Validators.required
      ),
      ValidityPeriod: new FormControl(
        !!this.moduleTraining ? this.moduleTraining.ValidityPeriod : "",
        Validators.required
      ),
      moduleDescription: new FormControl(
        !!this.moduleTraining ? this.moduleTraining.moduleDescription : "",
        Validators.required
      ),
      isActive: new FormControl(true),
      deletedBy: new FormControl(),
      recordedByID : new FormControl(!!this.moduleTraining ? this.moduleTraining.recordedByID : ''),
      companyId :new FormControl(Number(localStorage.getItem(Constants.COMPANYID))),
      FileModels:new FormControl(),
      Files:new FormControl(!!this.moduleTraining ? this.moduleTraining.Files : "")
    });
    console.log(this.moduleTraining)
    if(!!this.moduleTraining && this.moduleTraining.moduleId > 0 && this.moduleTraining.FileModels.length >0){
      this.fileObj = this.moduleTraining.FileModels.map(x=>{
        return {
          FileName : x.OriginalFileName,
          Path : x.Path
        }
      })
    }
  }
  initializeMessages() {
    this.messageList.moduleName = {
      required: Constants.VALIDATION_MSG.Module_Traning.MODULEREQ,
    };
    this.messageList.ValidityPeriod = {
      required: Constants.VALIDATION_MSG.Module_Traning.ValidityPeriod,
    };
    this.messageList.moduleDescription = {
      required: Constants.VALIDATION_MSG.Module_Traning.DECRITION,
    };
  }
  cancel() {
    this.closeRequest.emit(false);
  }
  value(controlName: string) {
    return this.control(controlName).value;
  }
  control(controlName: string): AbstractControl {
    return this.moduleConfig.get(controlName);
  }
  onSubmit() {
    this.formService.markFormGroupTouched(this.moduleConfig);
    if (this.moduleConfig.invalid) {
      return;
    }
    this.control("FileModels").setValue(this.fileObj);
    const saveMethod =
      this.moduleConfig.controls.moduleId.value > 0
        ? this.moduleFunctionService.updateModule(this.moduleConfig.value)
        : this.moduleFunctionService.addModule(this.moduleConfig.value);
    saveMethod.then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(
            this.moduleConfig.controls.moduleId.value > 0
              ? Constants.MODULE_UPDATE_SUCESS_MSG
              : Constants.MODULE_ADD_SUCESS_MSG
          );
          this.closeRequest.emit(true);
        } else {
          this.toaster.error(res["Message"]);
        }
      },
      (err) => {
        this.closeRequest.emit(false);
      }
    );
  }

  
  onFileChange(event) {
    this.theFiles = [];
    let file = event.target.files[0];
    let fileSizeMB = (file.size / 1024) / 1024;
    let fileName = file.name.toLowerCase().split(".")
    if (fileName[fileName.length - 1] != "pdf" && fileName[fileName.length - 1] != "xls"
    && fileName[fileName.length - 1] != "xlsx" && fileName[fileName.length - 1] != "doc"
    &&  fileName[fileName.length - 1] != "docx" ) {
      this.utility.showErrorToast("This file is not in valid format");
      return;
    } else if(fileSizeMB>5){
      this.utility.showErrorToast("Selected file size must be less than 5 MB");
      return;
    }else {
      this.theFiles.push(file);
      this.readAndUploadFile(this.theFiles[0]);
    }
  }
    private readAndUploadFile(theFile: any) {
      let file = new FileToUpload();
  
      // Set File Information
      file.fileName = theFile.name;
      file.fileSize = theFile.size;
      file.fileType = theFile.type;
      file.lastModifiedTime = theFile.lastModified;
      file.lastModifiedDate = theFile.lastModifiedDate;
  
      // Use FileReader() object to get file to upload
      // NOTE: FileReader only works with newer browsers
      let reader = new FileReader();
  
      // Setup onload event for reader
      reader.onload = () => {
        // Store base64 encoded representation of file
        file.fileAsBase64 = reader.result.toString();
        this.fileObj.push({
          FileName : file.fileName,
          Base64StringFile: file.fileAsBase64
        })
      }
  
      // Read the file
      reader.readAsDataURL(theFile);
    }
  removeFile(index: number) {
    this.fileObj.splice(index, 1);
  }
  
}
