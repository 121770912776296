import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute, Route, Router } from "@angular/router";
import * as L from "leaflet";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subscriber } from "rxjs";
import { LocationService } from "../service/location.service";
import { UtilityService } from "../service/utility.service";

@Component({
  selector: "app-location-map",
  templateUrl: "./location-map.component.html",
  styleUrls: ["./location-map.component.scss"],
})
export class LocationMapComponent implements OnInit, AfterViewInit {
  @Input() isShowHeader = true;
  @Input() locationDetails;
  @Output() closeRequest = new EventEmitter<boolean>();
  private map;
  constructor(
    private utilityService: UtilityService,
    private modelservice: BsModalService,
    private route: ActivatedRoute
  ) {}

  ngAfterViewInit(): void {
    this.utilityService.hideLoading();
    this.initMap();
  }

  private initMap(): void {
    this.map = L.map("map", {
      center: [this.locationDetails.latitude, this.locationDetails.longitude],
      zoom: 8,
    });
    const tiles = L.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        maxZoom: 18,
        minZoom: 5,
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
      }
    );

    tiles.addTo(this.map);
    this.map.flyTo(
      [this.locationDetails.latitude, this.locationDetails.longitude],
      14
    );

    const icon = L.icon({
      iconUrl: "https://cdn-icons-png.flaticon.com/512/1077/1077114.png",
      popupAnchor: [13, 0],
      iconSize: [35, 35],
    });

    const myIcon = L.icon({
      iconUrl:
        "https://res.cloudinary.com/rodrigokamada/image/upload/v1637581626/Blog/angular-leaflet/marker-icon.png",
      iconSize: [25, 41],
    });
    const marketLocation = L.marker(
      [this.locationDetails.latitude, this.locationDetails.longitude],
      {
        icon: myIcon,
      }
    );
    if (!!this.locationDetails.locationName) {
      marketLocation.bindPopup(this.locationDetails.locationName);
    }
    marketLocation.addTo(this.map);
  }

  getCurrentPosition(): any {
    return new Observable((observer: Subscriber<any>) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: any) => {
          observer.next({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          observer.complete();
        });
      } else {
        observer.error();
      }
    });
  }
  ngOnInit() {
    var locationDetail = this.route.queryParams["value"].locationDetail;
    if (!!locationDetail) {
      const splitLocation = locationDetail.split("|");
      if (splitLocation.length > 1) {
        this.locationDetails = {
          latitude: splitLocation[0],
          longitude: splitLocation[1],
        };
      }
    }
    //   if (!!companyId) {
    //     this.locationService
    //       .getLocationListByCompany(companyId, null)
    //       .then((res) => {
    //         if (res["Success"]) {
    //           if (res["Data"].length > 0) {
    //             res["Data"].map((item) => {
    //               if (!!item.Latitude && !!item.Longitude) {
    //                 const myIcon = L.icon({
    //                   iconUrl:
    //                     "https://res.cloudinary.com/rodrigokamada/image/upload/v1637581626/Blog/angular-leaflet/marker-icon.png",
    //                   iconSize: [25, 41],
    //                 });
    //                 const marketLocation = L.marker(
    //                   [item.Latitude, item.Longitude],
    //                   {
    //                     icon: myIcon,
    //                   }
    //                 );
    //                 if (!!item.locationName) {
    //                   marketLocation.bindPopup(item.locationName);
    //                 }
    //                 marketLocation.addTo(this.map);
    //               }
    //             });
    //           }
    //         }
    //       });
    //   }
  }
  cancel() {
    this.closeRequest.emit(true);
  }
}
