import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgotPasswordComponent } from './forgot-password.component';
import { SharedModule } from '../shared/shared/shared.module';
import {ForgotpasswordService} from  '../service/forgotpassword.service';
import { ForgotPasswordRoutingModule } from './forgot-password-routing.module';

@NgModule({
  declarations: [ForgotPasswordComponent],
  imports: [
    CommonModule,
    SharedModule,
    ForgotPasswordRoutingModule
  ],
  providers: [ForgotpasswordService]
})

export class ForgotPasswordModule { }


