import { NgModule } from '@angular/core';

import { LocationRoutingModule } from './location-routing.module';
import { LocationComponent } from './location.component';
import { SharedModule } from '../shared/shared/shared.module';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { AddEditComponent } from './add-edit/add-edit.component';
import { LocationService } from '../service/location.service';
import { DirectiveModule } from '../shared/shared/directive/directive.module';
import { CompanyService } from '../service/company.service';


@NgModule({
  declarations: [LocationComponent, AddEditComponent],
  imports: [
    SharedModule,
    LocationRoutingModule,
    NgxBootstrapIconsModule.pick(allIcons),
    DirectiveModule
  ],
  providers: [LocationService,CompanyService]
})
export class LocationModule { }
