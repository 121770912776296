import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { Role } from '../models/role-model';
import { RouteGuard } from '../core/auth/route-guard.service';
import { ProfileResolverService } from '../service/profile-resolver.service';
import { PlanResolverService } from '../service/plan-resolver.service';
import { UserTrainingInfoComponent } from './../user-training-info/user-training-info.component';
import { KioskModule } from '../kiosk/kiosk.module';
import { DepartmentMappingModule } from '../department-mapping/department-mapping.module';
import { LaunchKioskModule } from '../launch-kiosk/launch-kiosk.module';
import { ModuleFunctionModule } from '../module-function/module-function.module';
import { LearningAdminModule } from '../learning-admin/learning-admin.module';
import { SettingsModule } from '../settings/settings.module';
import { InvoiceModule } from '../invoice/invoice.module';
import { ChangePasswordModule } from '../change-password/change-password.module';
import { ProfileModule } from '../profile/profile.module';
import { PlanModule } from '../plan/plan.module';
import { UserModule } from '../user/user.module';
import { CompanyModule } from '../company/company.module';
import { LocationModule } from '../location/location.module';
import { InternalNetworkModule } from '../internal-network/internal-network.module';
import { UserValidationModule } from '../user-validation/user-validation.module';
import { CallOffReasonModule } from '../call-off-reason/call-off-reason.module';
import { DepartmentModule } from '../department/department.module';
import { TimeOffModule } from '../time-off/time-off-config.module';
import { EmployeerModule } from '../employeer/employeer.module';
import { OfferModule } from '../offer/offer.module';
import { ShiftModule } from '../shift/shift.module';
import { LocationMapComponent } from '../location-map/location-map.component';


const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'shift',
        loadChildren: () => {return ShiftModule},
        // loadChildren: () => import('../shift/shift.module').then(m => m.ShiftModule),
        data: { role: [Role.hrAdmin] },
        canActivate: [RouteGuard]
      },
      {
        path: 'offer',
        loadChildren: () => {return OfferModule},
        // loadChildren: () => import('../offer/offer.module').then(m => m.OfferModule),
        data: { role: [Role.user, Role.manager] },
        canActivate: [RouteGuard]
      },
      {
        path: 'employer',
        loadChildren: () => {return EmployeerModule},
        // loadChildren: () => import('../employeer/employeer.module').then(m => m.EmployeerModule),
        data: { role: [Role.hrAdmin] },
        canActivate: [RouteGuard]
      },
      {
        path: 'time-off',
        loadChildren: () => {return TimeOffModule},
        //loadChildren: () => import('../time-off/time-off-config.module').then(m => m.TimeOffModule),
        data: { role: [Role.hrAdmin] },
        canActivate: [RouteGuard]
      },
      {
        path: 'department',
        loadChildren: () => {return DepartmentModule},
        //loadChildren: () => import('../department/department.module').then(m => m.DepartmentModule),
        data: { role: [Role.hrAdmin] },
        canActivate: [RouteGuard]
      },
      {
        path: 'callOffReason',
        loadChildren: () => {return CallOffReasonModule},
        //loadChildren: () => import('../call-off-reason/call-off-reason.module').then(m => m.CallOffReasonModule),
        data: { role: [Role.hrAdmin] },
        canActivate: [RouteGuard]
      },
      {
        path: 'userValidation',
        loadChildren: () => {return UserValidationModule},
        //loadChildren: () => import('../user-validation/user-validation.module').then(x => x.UserValidationModule),
        data: { role: [Role.hrAdmin] },
        canActivate: [RouteGuard]
      },
      {
        path: 'terms-condition',
        loadChildren: () => {return InternalNetworkModule},
        //loadChildren: () => import('../internal-network/internal-network.module').then(m => m.InternalNetworkModule),
        data: { role: [Role.hrAdmin] },
        canActivate: [RouteGuard]
      },
      {
        path: 'location',
        loadChildren: () => {return LocationModule},
        //loadChildren: () => import('../location/location.module').then(m => m.LocationModule),
        data: { role: [Role.hrAdmin, Role.superUser] },
        canActivate: [RouteGuard]
      },
      {
        path: 'company',
        loadChildren: () => {return CompanyModule},
        //loadChildren: () => import('../company/company.module').then(m => m.CompanyModule),
        data: { role: [Role.superUser] },
        canActivate: [RouteGuard]
      },
      {
        path: 'users',
        loadChildren: () => {return UserModule},
        //loadChildren: () => import('../user/user.module').then(m => m.UserModule),
        data: { role: [Role.superUser, Role.hrAdmin, Role.manager, Role.purchaser] },
        canActivate: [RouteGuard]
      },
      {
        path: 'plan',
        loadChildren: () => {return PlanModule},
        //loadChildren: () => import('../plan/plan.module').then(m => m.PlanModule),
        data: { role: [Role.purchaser] },
        canActivate: [RouteGuard],
        resolve: { plan: PlanResolverService }
      },
      {
        path: 'profile',
        loadChildren: () => {return ProfileModule},
        //loadChildren: () => import("../profile/profile.module").then((m) => m.ProfileModule),
        resolve: { user: ProfileResolverService },
      },
      {
        path: 'change-password',
        loadChildren: () => {return ChangePasswordModule},
        //loadChildren: () => import("../change-password/change-password.module").then((m) => m.ChangePasswordModule),
      },
      {
        path: 'invoice',
        loadChildren: () => {return InvoiceModule},
        //loadChildren: () => import("../invoice/invoice.module").then((m) => m.InvoiceModule),
      },
      {
        path: 'settings',
        loadChildren: () => {return SettingsModule},
        //loadChildren: () => import("../settings/settings.module").then((m) => m.SettingsModule),
      },
      {
        path: 'LearningAdmin',
        loadChildren: () => {return LearningAdminModule},
        //loadChildren: () => import("../learning-admin/learning-admin.module").then((m) => m.LearningAdminModule),
      },
      {
        path: 'FunctionModule',
        loadChildren: () => {return ModuleFunctionModule},
        //loadChildren: () => import("../module-function/module-function.module").then((m) => m.ModuleFunctionModule),
      },
      {
        path: 'DepartmentMapping',
        loadChildren: () => {return DepartmentMappingModule},
        //loadChildren: () => import("../department-mapping/department-mapping.module").then((m) => m.DepartmentMappingModule),
      },
      {
        path: 'MyTrainings',
        component:UserTrainingInfoComponent
      },
      {
        path: 'kiosk',
        loadChildren: () => {return KioskModule},
        //loadChildren: () => import('../kiosk/kiosk.module').then(m => m.KioskModule),
        data: { role: [Role.hrAdmin] },
        canActivate: [RouteGuard]
      },
      {
        path: 'launch-kiosk',
        loadChildren: () => {return LaunchKioskModule},
        //loadChildren: () => import('../launch-kiosk/launch-kiosk.module').then(m => m.LaunchKioskModule),
        data: { role: [Role.hrAdmin] },
        resolve: { user: ProfileResolverService },
        canActivate: [RouteGuard]
      },
    ]
  },
  {
    path: 'companyLocation',
    component: LocationMapComponent
  }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
exports: [RouterModule]
})
export class LayoutRoutingModule { }
