import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Shift } from "src/app/models/shift.model";
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { Role } from "src/app/models/role-model";
import { Constants } from "src/app/constant/constants";
import { FormService } from "src/app/core/form.service";
import { ShiftService } from "src/app/service/shift.service";
import { ToastrService } from "ngx-toastr";
import { CompanyService } from "src/app/service/company.service";
import { DepartmentService } from "src/app/service/department.service";
import { LocationService } from "src/app/service/location.service";

@Component({
  selector: "app-add",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss"],
})
export class AddComponent implements OnInit {
  @Input() shift: Shift;
  isMeridian: boolean = false;
  @Output() close = new EventEmitter<boolean>();
  shiftForm: FormGroup;
  public messageList: any = new Shift();
  roleEnum = Role;
  isWeekDayValid = true;
  mytime: Date = new Date();
  startTime: Date = new Date();
  endTime: Date = new Date();
  role: number;
  companyList = [];
  departmentList = [];
  locationList = [];
  weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  companyId = 0;
  shiftDays: any;
  isDisabled = true;
  maxTime = null;

  constructor(
    private formService: FormService,
    private shiftService: ShiftService,
    private toaster: ToastrService,
    private companyService: CompanyService,
    private build: FormBuilder,
    private departmentService: DepartmentService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.initializeShiftForm();
    this.initializeMessages();
    if (this.role === Role.superUser) {
      this.getCompanyList();
    }
    this.getDepartmentList();
  }

  initializeShiftForm() {
    this.shiftForm = this.build.group({
      shiftId: new FormControl(0),
      title: new FormControl("", Validators.required),
      shiftCode: new FormControl("", Validators.required),
      departmentId: new FormControl(null, Validators.required),
      locationId: new FormControl(null, Validators.required),
      companyId: new FormControl(null),
      isActive: new FormControl(true),
      shiftWeekDay: this.build.array([]),
    });
    this.companyId =
      this.role === Role.superUser
        ? 0
        : Number(localStorage.getItem(Constants.COMPANYID));
    if (this.role === Role.superUser) {
      this.companyId = !!this.shift ? this.shift.companyId : 0;
      this.shiftForm.controls.companyId.setValidators(Validators.required);
    }
    if (!!this.shift) {
      this.shiftForm.patchValue(this.shift);
    }
    for (let i = 0; i < 7; i++) {
      (this.shiftForm.controls.shiftWeekDay as FormArray).push(
        this.hourDistributionControl(i)
      );
    }
    this.getLocationList();
  }

  hourDistributionControl(index?: any): FormGroup {
    const group = new FormGroup({
      shiftWeekDayId: new FormControl(0),
      isSelectDay: new FormControl(false),
      weekday: new FormControl(null),
      startTime: new FormControl(null, [Validators.required]),
      endTime: new FormControl(null, [Validators.required]),
      paidHours: new FormControl(null),
      firstHalfEndTime: new FormControl(null),
      secondHalfStartTime: new FormControl(null),
      isActive: new FormControl(false),
      shiftId: new FormControl(0),
    });
    if (!!this.shift) {
      const wId = this.shift.shiftWeekDay.find((x) => x.weekday == index + 1);
      if (!!wId) {
        group.patchValue(wId);
        if (wId.isActive) {
          group.controls.isSelectDay.patchValue(wId.isActive);
          group.controls.isActive.patchValue(wId.isActive);
          const startdate = new Date();
          startdate.setHours(
            Number(wId.startTime.split(":")[0]),
            Number(wId.startTime.split(":")[1])
          );
          const enddate = new Date();
          enddate.setHours(
            Number(wId.endTime.split(":")[0]),
            Number(wId.endTime.split(":")[1])
          );
          if (!!wId.firstHalfEndTime) {
            const firstHalf = new Date();
            firstHalf.setHours(
              Number(wId.firstHalfEndTime.split(":")[0]),
              Number(wId.firstHalfEndTime.split(":")[1])
            );
            group.controls.firstHalfEndTime.patchValue(firstHalf);
          } else {
            group.controls.firstHalfEndTime.patchValue(null);
          }
          if (!!wId.secondHalfStartTime) {
            const secondHalf = new Date();
            secondHalf.setHours(
              Number(wId.secondHalfStartTime.split(":")[0]),
              Number(wId.secondHalfStartTime.split(":")[1])
            );
            group.controls.secondHalfStartTime.patchValue(secondHalf);
          } else {
            group.controls.secondHalfStartTime.patchValue(null);
          }
          group.controls.startTime.patchValue(startdate);
          group.controls.endTime.patchValue(enddate);
        } else {
          group.controls.startTime.disable();
          group.controls.endTime.disable();
          group.controls.firstHalfEndTime.disable();
          group.controls.secondHalfStartTime.disable();
        }
      } else {
        group.controls.startTime.disable();
        group.controls.endTime.disable();
        group.controls.firstHalfEndTime.disable();
        group.controls.secondHalfStartTime.disable();
      }
    } else {
      group.controls.startTime.disable();
      group.controls.endTime.disable();
      group.controls.firstHalfEndTime.disable();
      group.controls.secondHalfStartTime.disable();
    }

    group.get("startTime").valueChanges.subscribe((startTime) => {
      if (!startTime) group.get("startTime").setValidators(Validators.required);
      // if (startTime && group.get("endTime").value) {
      //   this.startTimeValidate(startTime, group);
      // }
    });
    group.get("endTime").valueChanges.subscribe((endTime) => {
      if (!endTime) group.get("endTime").setValidators(Validators.required);
      // if (endTime && group.get("startTime").value) {
      //   this.endTimeValidate(endTime, group);
      // }
    });
    return group;
  }
  // startTimeValidate(startTime, group) {
  //   const sTime = `${startTime.getHours()}:${startTime.getMinutes()}`,
  //     eTime = `${group.get('endTime').value.getHours()}:${group.get('endTime').value.getMinutes()}`;
  //   const start = this.timeToHour(sTime);
  //   const end = this.timeToHour(eTime);
  //   if (start >= end) {
  //     group.get('startTime').setErrors({ invalid: true });
  //     group.get('endTime').setErrors({ invalid: true });
  //   } else {
  //     group.get('startTime').setErrors(null);
  //     group.get('endTime').setErrors(null);
  //   }
  //   // group.get('startTime').updateValueAndValidity();
  //   // group.get('endTime').updateValueAndValidity();
  // }

  // endTimeValidate(endTime, group) {
  //   const eTime = `${endTime.getHours()}:${endTime.getMinutes()}`,
  //     sTime = `${group.get('startTime').value.getHours()}:${group.get('startTime').value.getMinutes()}`;
  //   const start = this.timeToHour(sTime);
  //   const end = this.timeToHour(eTime);
  //   if (start >= end) {
  //     group.get('startTime').setErrors({ invalid: true });
  //     group.get('endTime').setErrors({ invalid: true });
  //   } else {
  //     group.get('startTime').setErrors(null);
  //     group.get('endTime').setErrors(null);
  //   }
  // }

  timeToHour(time: string): number {
    const [hh, mm] = time.split(":").map((i) => Number(i));
    return hh + mm / 60;
  }

  initializeMessages() {
    this.messageList.title = {
      required: Constants.VALIDATION_MSG.SHIFT.TITLE_REQUIRED,
    };
    this.messageList.shiftCode = {
      required: Constants.VALIDATION_MSG.SHIFT.SHIFT_CODE_REQUIRED,
    };
    this.messageList.departmentId = {
      required: Constants.VALIDATION_MSG.SHIFT.DEPARTMENT_REQUIRED,
    };
    this.messageList.companyId = {
      required: Constants.VALIDATION_MSG.SHIFT.COMPANY_REQUIRED,
    };
    this.messageList.locationId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.LOCATION_REQUIRED,
    };
  }

  getCompanyList() {
    this.companyService.getCompanyListWithOutPagination().then((res) => {
      if (res["Success"]) {
        this.companyList = res["Data"];
      } else {
        this.companyList = [];
      }
    });
  }

  getDepartmentList() {
    const getMethod =
      this.role === Role.superUser
        ? this.departmentService.getDepartmentList(0)
        : this.departmentService.getDepartmentListByCompanyId(
          null,
          this.companyId
        );
    getMethod.then(
      (res: any) => {
        if (res["Success"]) {
          this.departmentList =
            this.role === Role.superUser ? res.Data.results : res.Data;
        } else {
          this.departmentList = [];
        }
      },
      (err) => {
        this.departmentList = [];
      }
    );
  }

  getLocationList() {
    this.locationService.getLocationListByCompany(this.companyId, null).then(
      (res) => {
        if (res["Success"]) {
          this.locationList = res["Data"];
        } else {
          this.locationList = [];
        }
      },
      (err) => {
        this.locationList = [];
      }
    );
  }

  onCompanyChange(event: any) {
    const id = Number(event.currentTarget.value);
    if (!!id) {
      this.companyId = id;
      this.getDepartmentList();
    } else {
      this.companyId = 0;
      this.departmentList = [];
      this.shiftForm.get("departmentId").setValue("");
    }
  }

  control(controlName: string): AbstractControl {
    return this.shiftForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }

  showError(controlName: string): boolean {
    return (
      this.control(controlName).touched && this.control(controlName).invalid
    );
  }

  getStartTime(i) {
    this.shiftDays = this.shiftForm.get("shiftWeekDay") as FormArray;
    return this.shiftDays.controls[i].controls.startTime;
  }
  getEndTime(i) {
    this.shiftDays = this.shiftForm.get("shiftWeekDay") as FormArray;
    return this.shiftDays.controls[i].controls.endTime;
  }

  isChecked(index) {
    this.shiftDays = this.shiftForm.get("shiftWeekDay") as FormArray;
    if (!this.shiftDays.controls[index].controls.isSelectDay.value) {
      this.isWeekDayValid = true;
      this.shiftDays.controls[index].controls.isSelectDay.setValue(true);
      this.shiftDays.controls[index].controls.isActive.setValue(true);
      this.shiftDays.controls[index].controls.weekday.setValue(index + 1);
      this.shiftDays.controls[index].controls.startTime.enable();
      this.shiftDays.controls[index].controls.endTime.enable();
      this.shiftDays.controls[index].controls.firstHalfEndTime.enable();
      this.shiftDays.controls[index].controls.secondHalfStartTime.enable();
    } else {
      this.shiftDays.controls[index].controls.isSelectDay.setValue(false);
      this.shiftDays.controls[index].controls.isActive.setValue(false);
      this.shiftDays.controls[index].controls.startTime.setValue(null);
      this.shiftDays.controls[index].controls.endTime.setValue(null);
      this.shiftDays.controls[index].controls.firstHalfEndTime.setValue(null);
      this.shiftDays.controls[index].controls.secondHalfStartTime.setValue(
        null
      );
      this.shiftDays.controls[index].controls.startTime.disable();
      this.shiftDays.controls[index].controls.endTime.disable();
      this.shiftDays.controls[index].controls.firstHalfEndTime.disable();
      this.shiftDays.controls[index].controls.secondHalfStartTime.disable();
    }
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.shiftForm);
    if (this.shiftForm.invalid) {
      return;
    } else if (
      !this.shiftForm.value.shiftWeekDay.some((x) => x.isSelectDay === true)
    ) {
      this.isWeekDayValid = false;
      return;
    }
    this.getTime();
    this.shiftForm.controls.companyId.setValue(this.companyId);
    this.shiftForm.controls.departmentId.setValue(
      Number(this.shiftForm.controls.departmentId.value)
    );
    this.shiftForm.controls.locationId.setValue(
      Number(this.shiftForm.controls.locationId.value)
    );
    const saveMethod =
      this.shiftForm.controls.shiftId.value > 0
        ? this.shiftService.updateShift(this.shiftForm.value)
        : this.shiftService.addShift(this.shiftForm.value);
    saveMethod.then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(
            this.shiftForm.controls.shiftId.value > 0
              ? Constants.SHIFT_UPDATE_SUCCESS_MSG
              : Constants.SHIFT_ADD_SUCCESS_MSG
          );
        } else {
          this.toaster.error(res["Message"]);
        }
        this.close.emit(true);
      },
      (err) => {
        this.close.emit(false);
      }
    );
  }

  getTime() {
    this.shiftDays = this.shiftForm.get("shiftWeekDay") as FormArray;
    this.shiftDays.controls.map((x) => {
      x.controls.startTime.setValue(
        new Date(x.controls.startTime.value).getHours() +
        ":" +
        new Date(x.controls.startTime.value).getMinutes()
      );
      x.controls.endTime.setValue(
        new Date(x.controls.endTime.value).getHours() +
        ":" +
        new Date(x.controls.endTime.value).getMinutes()
      );
      if (!!x.controls.firstHalfEndTime.value) {
        x.controls.firstHalfEndTime.setValue(
          new Date(x.controls.firstHalfEndTime.value).getHours() +
          ":" +
          new Date(x.controls.firstHalfEndTime.value).getMinutes()
        );
      }
      if (!!x.controls.secondHalfStartTime.value) {
        x.controls.secondHalfStartTime.setValue(
          new Date(x.controls.secondHalfStartTime.value).getHours() +
          ":" +
          new Date(x.controls.secondHalfStartTime.value).getMinutes()
        );
      }
    });
  }
  secondHalfStartTimeChange(index) {
    if (
      this.shiftForm.controls.shiftWeekDay["controls"][index].controls
        .secondHalfStartTime.value != null &&
      this.shiftForm.controls.shiftWeekDay["controls"][index].controls.endTime
        .value != null
    ) {
      let secondHalfEndTime = new Date(
        this.shiftForm.controls.shiftWeekDay["controls"][
          index
        ].controls.secondHalfStartTime.value
      );
      let shiftEndTime = new Date(
        this.shiftForm.controls.shiftWeekDay["controls"][
          index
        ].controls.endTime.value
      );
      let shiftStartTime = new Date(
        this.shiftForm.controls.shiftWeekDay["controls"][
          index
        ].controls.startTime.value
      );
      if (shiftStartTime.getHours() > 12 && shiftEndTime.getHours() < 12) {
        let tempDate = new Date(new Date(shiftEndTime.getTime()).setDate(shiftEndTime.getDate() + 1))
        if (shiftEndTime.getTime() <= shiftStartTime.getTime()) {
          if (secondHalfEndTime.getHours() < 12) {
            if (shiftStartTime.getTime() <= tempDate.getTime() && secondHalfEndTime.getTime() <= tempDate.getTime() && secondHalfEndTime.getTime() <= shiftEndTime.getTime() && shiftStartTime.getHours() >= secondHalfEndTime.getHours()) {
              return
            }
          } else {
            if (shiftStartTime.getTime() <= tempDate.getTime() && secondHalfEndTime.getTime() <= tempDate.getTime() && shiftStartTime.getHours() <= secondHalfEndTime.getHours()) {
              return
            }
          }
        }

        if (shiftStartTime.getTime() <= secondHalfEndTime.getTime() && secondHalfEndTime.getTime() <= tempDate.getTime()) {
          return
        }
        else {
          this.shiftForm.controls.shiftWeekDay["controls"][
            index
          ].controls.secondHalfStartTime.setValue(null);
          this.toaster.error("Please enter a valid time");
          return
        }

      }
      if (secondHalfEndTime.getTime() > shiftEndTime.getTime()) {
        this.toaster.error(
          "Second shift start time can not be greater than shift end time"
        );
        this.shiftForm.controls.shiftWeekDay["controls"][
          index
        ].controls.secondHalfStartTime.setValue(shiftEndTime);
      }
      if (secondHalfEndTime.getTime() < shiftStartTime.getTime()) {
        this.toaster.error(
          "Second shift start time can not be less than shift start time"
        );
        this.shiftForm.controls.shiftWeekDay["controls"][
          index
        ].controls.secondHalfStartTime.setValue(null);
      }
    }

  }

  firstHalfEndTimeChange(index) {
    if (
      this.shiftForm.controls.shiftWeekDay["controls"][index].controls
        .firstHalfEndTime.value != null &&
      this.shiftForm.controls.shiftWeekDay["controls"][index].controls.endTime
        .value != null
    ) {
      var secondHalfEndTime = new Date(
        this.shiftForm.controls.shiftWeekDay["controls"][
          index
        ].controls.firstHalfEndTime.value
      );
      let shiftEndTime = new Date(
        this.shiftForm.controls.shiftWeekDay["controls"][
          index
        ].controls.endTime.value
      );
      let shiftStartTime = new Date(
        this.shiftForm.controls.shiftWeekDay["controls"][
          index
        ].controls.startTime.value
      );  

      if (shiftStartTime.getHours() > 12 && shiftEndTime.getHours() < 12) {
        let tempDate = new Date(new Date(shiftEndTime.getTime()).setDate(shiftEndTime.getDate() + 1))
        if (shiftEndTime.getTime() <= shiftStartTime.getTime()) {
          if (secondHalfEndTime.getHours() < 12) {
            if (shiftStartTime.getTime() <= tempDate.getTime() && secondHalfEndTime.getTime() <= tempDate.getTime() && secondHalfEndTime.getTime() <= shiftEndTime.getTime()) {
              return
            }
          } else {
            if (shiftStartTime.getTime() >= tempDate.getTime() && secondHalfEndTime.getTime() >= tempDate.getTime()) {
              return
            }
          }
        }
        if (shiftStartTime.getTime() <= secondHalfEndTime.getTime() && secondHalfEndTime.getTime() <= tempDate.getTime()) {
          return
        }
        else {
          this.shiftForm.controls.shiftWeekDay["controls"][
            index
          ].controls.firstHalfEndTime.setValue(null);
          this.toaster.error("Please enter a valid time");
          return
        }
      }
      if (secondHalfEndTime.getTime() > shiftEndTime.getTime()) {
        this.toaster.error(
          "First shift end time can not be greater than shift end time"
        );
        this.shiftForm.controls.shiftWeekDay["controls"][
          index
        ].controls.firstHalfEndTime.setValue(null);
      }
      if (secondHalfEndTime.getTime() < shiftStartTime.getTime()) {
        this.toaster.error(
          "First shift end time can not be less than shift start time"
        );
        this.shiftForm.controls.shiftWeekDay["controls"][
          index
        ].controls.firstHalfEndTime.setValue(shiftStartTime);
      }
    }
  }

  cancel() {
    this.close.emit(false);
  }
}
