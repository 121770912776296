import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ForgotPasswordModule } from "./forgot-password/forgot-password.module";
import { LayoutModule } from "./layout/layout.module";
import { LoginModule } from "./login/login.module";
import { MarketingCampaignModule } from "./marketing-campaign/marketing-campaign.module";
import { RecoveryPasswordModule } from "./recovery-password/recovery-password.module";
import { ResetIdentityComponent } from "./reset-identity/reset-identity.component";
import { SignupModule } from "./signup/signup.module";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => {return LoginModule},
    // loadChildren: () => import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "signup",
    loadChildren: () => {return SignupModule},
    // loadChildren: () => import("./signup/signup.module").then((m) => m.SignupModule),
  },
  {
    path: "layout",
    loadChildren: () => {return LayoutModule},
    // loadChildren: () => import("./layout/layout.module").then((m) => m.LayoutModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => {return ForgotPasswordModule},
    // loadChildren: () => import("./forgot-password/forgot-password.module").then((m) => m.ForgotPasswordModule),
  },
  {
    path: 'recovery-password',
    loadChildren: () => {return RecoveryPasswordModule},
    // loadChildren: () => import("./recovery-password/recovery-password.module").then((m) => m.RecoveryPasswordModule),
  },
  {
    path: 'AddYourCompany',
    loadChildren: () => {return MarketingCampaignModule},
    // loadChildren: () => import("./marketing-campaign/marketing-campaign.module").then((m) => m.MarketingCampaignModule),
  },
  {
    path: 'ResetIdentity',
    component: ResetIdentityComponent,
    // loadChildren: () => import("./marketing-campaign/marketing-campaign.module").then((m) => m.MarketingCampaignModule),
  },
  {
    path: "**",
    loadChildren: () => {return LoginModule},
    // loadChildren: () => import("./login/login.module").then((m) => m.LoginModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
