import {
  AfterViewInit,
  Component,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  EventEmitter,
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
  Constants,
  roleList,
  timeList,
  SubscriptionType,
} from "src/app/constant/constants";
import { OfferStatus, Role } from "src/app/models/role-model";
import { DepartmentService } from "src/app/service/department.service";
import { ShiftService } from "src/app/service/shift.service";
import { Offer } from "src/app/models/offer.model";
import { CallInRequestService } from "./../../service/callInRequest.service";
import { OfferService } from "src/app/service/offer.service";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { UtilityService } from "./../../service/utility.service";
import * as _ from "lodash";
import { commonHelper } from "src/app/shared/Helpers/commonHelper";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-call-in-request",
  templateUrl: "./call-in-request.component.html",
  styleUrls: ["./call-in-request.component.scss"],
})
export class CallInRequestComponent implements OnInit, AfterViewInit {
  @ViewChild("filterTextValue", { static: false }) filterTextValue;
  @ViewChild("shiftValue", { static: false }) shiftValue;
  @ViewChild("departmentValue", { static: false }) departmentValue;
  @ViewChild("workDateValue", { static: false }) workDateValue;
  @ViewChild("timeValue", { static: false }) timeValue;
  @Output() showSwap = new EventEmitter(false);
  selected = [];
  shiftList = [];
  departmentList = [];
  selectedRecords = [];
  allRowsSelected = [];
  page: any;
  filterValue = [];
  searchSort: any;
  totalItems: any;
  columns = [];
  rowsOnPage = Constants.ROWS_ON_PAGE;
  modalRef: BsModalRef;
  role: number;
  roleEnum = Role;
  myOffer: Offer;
  callOffRequestList = [];
  deleteId: string;
  isConfirmed = false;
  offerStatus = OfferStatus;
  totalRequestedHours = 0;
  IsCoverMyWork: boolean = false;
  subscriptionType = SubscriptionType;
  public moduleId: any;
  public settingList: any = [];
  public paidTimeOff: any = {};
  public flma: any = {};
  commonShiftList: any;

  constructor(
    private modalService: BsModalService,
    private shiftService: ShiftService,
    private departmentService: DepartmentService,
    private callInRequestService: CallInRequestService,
    private offerService: OfferService,
    private toaster: ToastrService,
    private datePipe: DatePipe,
    private utilityService: UtilityService,
    private callInRequstService: CallInRequestService
  ) {
    this.columns = [
      "dateToSkip",
      "departmentId",
      "shiftToSkip",
      "shiftId",
      "createdByObj.companyUsername",
      "ReasonObj.Name",
      "OtherReason",
      "departmentName",
      "shiftToSkipNavigation.title",
      "IsPaidOff",
      "offerStatusStr",
      "approvedByObj.name",
      "IsFMLA",
    ];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  ngOnInit() {
    this.IsCoverMyWork =
      localStorage.getItem(Constants.APP_NAME) === "CoverMyWork" ? true : false;
    this.role = Number(localStorage.getItem(Constants.ROLE));
    let module = SubscriptionType.filter((item) => {
      return item.value === "Call-Off Module";
    });
    this.moduleId = module[0].id;
    this.utilityService.showLoading();
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = 0;
    forkJoin([
      this.shiftService.getShiftListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID)),
        false,
        false
      ),
      this.departmentService.getDepartmentListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID)),
        false,
        false
      ),
      this.callInRequstService.getSettingByCompanyID(
        this.moduleId,
        Number(localStorage.getItem(Constants.COMPANYID)),
        false,
        false
      ),
      this.role === this.roleEnum.manager
        ? this.callInRequestService.GetCallOffListForManager(
            1,
            this.rowsOnPage,
            false,
            false
          )
        : this.callInRequestService.getCallOffRequestList(
            1,
            this.rowsOnPage,
            false,
            false
          ),
    ]).subscribe((res: any) => {
      //first api response

      if (res[0]["Success"]) {
        this.shiftList = res[0]["Data"];
        this.commonShiftList = _.merge([], this.shiftList);
      }

      //second api response
      if (res[1]["Success"]) {
        this.departmentList = res[1]["Data"];
      } else {
        this.departmentList = [];
      }

      //third api response
      if (res[2]["Success"]) {
        this.settingList = res[2].Data;
        if (this.settingList.length > 0) {
          this.settingList.map((item) => {
            if (item.SettingType === "PaidTimeOff") {
              item.Name = "Paid Time Off";
              this.paidTimeOff = item;
            } else if (item.SettingType === "FMLA") {
              item.Name = item.SettingType;
              this.flma = item;
            }
          });
        }
      } else {
        this.settingList = [];
      }

      //fourth api resonse
      if (JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))) {
        this.utilityService.showLoading();
      }
      if (res[3]["Success"]) {
        if (
          res[3].Data.pagedResults &&
          res[3].Data.pagedResults.results.length > 0
        ) {
          this.callOffRequestList = res[3].Data.pagedResults.results;
          this.totalItems = res[3].Data.pagedResults.totalNumberOfRecords;
          this.totalRequestedHours =
            this.role == this.roleEnum.manager
              ? res[3].Data.totalHours.totalHoursApproved
              : 0;
        } else {
          this.callOffRequestList = [];
          this.totalItems = 0;
        }
      } else {
        this.callOffRequestList = [];
        this.totalItems = 0;
      }
      this.utilityService.hideLoading();
    });
  }
  getSettingByCompanyID() {
    let module = SubscriptionType.filter((item) => {
      return item.value === "Call-Off Module";
    });
    this.moduleId = module[0].id;
    const getMethod = this.callInRequstService.getSettingByCompanyID(
      this.moduleId,
      Number(localStorage.getItem(Constants.COMPANYID)),
      false
    );
    getMethod.then(
      (res: any) => {
        if (res["Success"]) {
          this.settingList = res.Data;
          if (this.settingList.length > 0) {
            this.settingList.map((item) => {
              if (item.SettingType === "PaidTimeOff") {
                item.Name = "Paid Time Off";
                this.paidTimeOff = item;
              } else if (item.SettingType === "FMLA") {
                item.Name = item.SettingType;
                this.flma = item;
              }
            });
          }
        } else {
          this.settingList = [];
        }
      },
      (err) => {
        this.settingList = [];
      }
    );
  }
  ngAfterViewInit() {
    this.columns.forEach((element, i) => {
      if (i < 4) {
        const obj = {
          Data: "string",
          Searchable: true,
          Orderable: true,
          Name: element,
          Search: {
            Value: this.filterValue[i],
            Regex: "string",
          },
        };
        this.searchSort.Columns.push(obj);
      }
    });
  }

  getShiftList() {
    this.shiftService
      .getShiftListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID)),
        false
      )
      .then(
        (response) => {
          this.shiftList = response["Data"];
          this.commonShiftList = _.merge([], this.shiftList);
        },
        (err) => {
          this.shiftList = [];
        }
      );
  }

  getDepartmentList() {
    this.departmentService
      .getDepartmentListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID)),
        false
      )
      .then(
        (res) => {
          if (res["Success"]) {
            this.departmentList = res["Data"];
            this.getSettingByCompanyID();
          } else {
            this.departmentList = [];
          }
        },
        (err) => {
          this.departmentList = [];
        }
      );
  }
  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.myOffer = data;
    } else {
      this.myOffer = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }
  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }
  redirectToSwap(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
    this.showSwap.emit(true);
  }
  async setPage(pageInfo) {
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
    await this.getCallOffOfferList(this.page.pageNumber + 1);
  }
  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  workDateChange(event) {
    const findDateColumn = this.searchSort.Columns.find(
      (x) => x.Name === "dateToSkip"
    );
    if (!!findDateColumn) {
      findDateColumn.Search.Value = this.datePipe.transform(
        this.setnewDate(event),
        "yyyy/MM/dd"
      );
    }
  }
  setnewDate(date) {
    const dateObj = {
      year: +this.datePipe.transform(date, "yyyy"),
      month: +this.datePipe.transform(date, "MM"),
      day: +this.datePipe.transform(date, "dd"),
    };
    return new Date(dateObj["year"], dateObj["month"] - 1, dateObj["day"]);
  }

  shiftChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(
      (x) => x.Name === "shiftToSkip"
    );
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = event.currentTarget.value;
    }
  }

  departmentChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(
      (x) => x.Name === "shiftToSkip"
    );
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = "";
    }
    if (event.currentTarget.value)
      this.shiftList = commonHelper.filterShiftListFromDepartmentId(
        event.currentTarget.value,
        this.commonShiftList
      );
    else this.shiftList = this.commonShiftList;
    const findDepartmentColumn = this.searchSort.Columns.find(
      (x) => x.Name === "departmentId"
    );
    if (!!findDepartmentColumn) {
      findDepartmentColumn.Search.Value = event.currentTarget.value;
    }
  }
  clear() {
    this.searchSort.Search.Value = "";
    this.searchSort.Order[0].ColumnName = "";
    this.searchSort.Order[0].Dir = "asc";
    this.filterTextValue.nativeElement.value = "";
    this.searchSort.Columns.forEach((element) => {
      element.Search.Value = "";
    });
    this.shiftValue.nativeElement.value = "";
    this.departmentValue.nativeElement.value = "";
    this.workDateValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }
  close(event) {
    this.isConfirmed = false;
  }
  async getCallOffOfferList(currentPage) {
    if (
      (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) ||
      (!!this.shiftValue && !!this.shiftValue.nativeElement.value) ||
      (!!this.departmentValue && !!this.departmentValue.nativeElement.value) ||
      (!!this.timeValue && !!this.timeValue.nativeElement.value) ||
      (!!this.workDateValue && this.workDateValue.nativeElement.value)
    ) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod =
        this.role === this.roleEnum.manager
          ? this.callInRequestService.GetCallOffListForManager(
              currentPage,
              this.rowsOnPage
            )
          : this.callInRequestService.getCallOffRequestList(
              currentPage,
              this.rowsOnPage
            );
      await getMethod.then(
        (res: any) => {
          if (JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))) {
            this.utilityService.showLoading();
          }
          if (res["Success"]) {
            if (
              res.Data.pagedResults &&
              res.Data.pagedResults.results.length > 0
            ) {
              this.callOffRequestList = res.Data.pagedResults.results;
              this.totalItems = res.Data.pagedResults.totalNumberOfRecords;
              this.totalRequestedHours =
                this.role == this.roleEnum.manager
                  ? res.Data.totalHours.totalHoursApproved
                  : 0;
            } else {
              this.callOffRequestList = [];
              this.totalItems = 0;
            }
          } else {
            this.callOffRequestList = [];
            this.totalItems = 0;
          }
        },
        (err) => {
          this.callOffRequestList = [];
          this.totalItems = 0;
        }
      );
    }
  }
  filterData() {
    if (this.role === this.roleEnum.user) {
      const filterMethod =
        this.callInRequestService.userCallOffRequestsDataFilter(
          this.searchSort
        );
      filterMethod.then(
        (res) => {
          if (res["data"] && res["data"].length > 0) {
            this.callOffRequestList = res["data"];
            this.totalItems = res["recordsFiltered"];
          } else {
            this.totalItems = 0;
          }
        },
        (err) => {
          this.callOffRequestList = [];
          this.totalItems = 0;
        }
      );
    }
    if (this.role === this.roleEnum.manager) {
      const filterMethod =
        this.callInRequestService.managerCallOffOfferDataFilter(
          this.searchSort
        );
      filterMethod.then(
        (res) => {
          if (res["offerlist"].data && res["offerlist"].data.length > 0) {
            this.callOffRequestList = res["offerlist"].data;
            this.totalItems = res["offerlist"].recordsFiltered;
            this.totalRequestedHours =
              res["totalhours"] === 0 ? 0 : res["totalhours"];
          } else {
            this.totalItems = 0;
            this.totalRequestedHours = 0;
          }
        },
        (err) => {
          this.callOffRequestList = [];
          this.totalItems = undefined;
        }
      );
    }
  }
  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clearText();
      }
    }
  }
  clearText() {
    this.searchSort.Search.Value = "";
    this.filterTextValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }
  onClickDelete(data) {
    this.deleteId = data.offerId;
    this.isConfirmed = true;
  }

  delete(id) {
    this.isConfirmed = false;
    this.offerService.deleteOffer(id).then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(Constants.OFFER_DELETE_SUCCESS_MSG);
          this.setPage({ offset: 0 });
        } else {
          this.toaster.error(res["Message"]);
        }
      },
      (err) => {}
    );
  }
}
