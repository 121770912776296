import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import {
  Constants,
  SettingType,
  SubscriptionType,
} from "src/app/constant/constants";
import { FormService } from "src/app/core/form.service";
import { LateInOffer, Offer } from "src/app/models/offer.model";
import { CallInRequestService } from "src/app/service/callInRequest.service";
import { DepartmentService } from "src/app/service/department.service";
import { LateInService } from "src/app/service/lateIn.service";
import { ShiftService } from "src/app/service/shift.service";
import { UtilityService } from "src/app/service/utility.service";

@Component({
  selector: "app-add-edit-late-in-request",
  templateUrl: "./add-edit-late-in-request.component.html",
  styleUrls: ["./add-edit-late-in-request.component.scss"],
})
export class AddEditLateInRequestComponent implements OnInit {
  @ViewChild("confirmationPopup", { static: false })
  confirmationPopup: TemplateRef<any>;
  @ViewChild("startTimeConfirmPopup", { static: false })
  startTimeConfirmPopup: TemplateRef<any>;
  @Input() Offer: Offer;
  @Output() closeRequest = new EventEmitter<boolean>();
  isOtherReason = false;
  public lateInForm: FormGroup;
  departmentList = [];
  reasonList = [];
  public messageList: any = new LateInOffer();
  todaysDate = new Date();
  tomorrowsDate = new Date(this.todaysDate);
  today = new Date();
  tomorrow = new Date(this.today);
  isConfirm = false;
  confirmMsg: string;
  IsCoverMyWork: boolean = false;
  settingList: any;
  paidTimeOff: any;
  isPaidTimeOff: boolean;
  message: string;
  cancleLogMsg: string = "successfully prevented an late in.";
  suggestionMsg: string =
    "It is suggested to preserve UTO as much as you can for emergency purposes. Sorry life threw a curved ball at you today. Let us know if we can help you.";
  startTimeConfirmMsg: string =
    "Late-in screen is used for obtaining approval to come late from your supervisor. If you intend to call-off for the whole shift, please use Call-Off screen.";
  counter: number = 0;
  nightShift = false;
  isx: boolean;
  modalRef: BsModalRef;
  role: number;
  shiftEndTime: string;
  shiftStartTime: string;
  departmentId: number;
  companyId: number;
  shiftId: number;
  moduleId: any;
  constructor(
    private utility: UtilityService,
    private toaster: ToastrService,
    private shiftService: ShiftService,
    private datepipe: DatePipe,
    private formService: FormService,
    private departmentService: DepartmentService,
    private lateInService: LateInService,
    private modalService: BsModalService,
    private callInRequstService: CallInRequestService,
    private ngZone: NgZone
  ) {
    this.IsCoverMyWork =
      localStorage.getItem(Constants.APP_NAME) === "CoverMyWork" ? true : false;
  }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.departmentId = Number(localStorage.getItem(Constants.DEPARTMENTID));
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.shiftId = Number(localStorage.getItem(Constants.SHIFTID));
    this.initializeLateInForm();
    this.getReasonList();
    this.initializeMessages();
    this.getReasonList();
    this.getSettingByCompanyID();
    this.tomorrowsDate.setDate(this.todaysDate.getDate() + 1);
  }

  getReasonList() {
    this.lateInService.getReasons().then(
      (response) => {
        if (response["Success"]) {
          this.reasonList = response["Data"];
        } else {
          this.reasonList = [];
        }
      },
      (err) => {
        this.reasonList = [];
      }
    );
  }

  reasonChange(value: string) {
    if (parseInt(value) === 1) {
      this.isOtherReason = true;
      this.lateInForm.controls.OtherReason.setValidators(Validators.required);
    } else {
      this.isOtherReason = false;
      this.lateInForm.controls.OtherReason.setValidators(null);
      this.lateInForm.controls.OtherReason.updateValueAndValidity();
    }
  }

  getDepartmentList() {
    this.departmentService
      .getDepartmentListByCompanyId(null, this.companyId)
      .then(
        (res) => {
          if (res["Success"]) {
            this.departmentList = res["Data"];
          } else {
            this.departmentList = [];
          }
        },
        (err) => {
          this.departmentList = [];
        }
      );
  }
    getDateToSkip(dateToSkip, vtoStartTime) {
    const vtoStartTimeSplit = vtoStartTime.split(":");
    const vtoStartTimeHour = parseInt(vtoStartTimeSplit[0]);
    const vtoStartTimeMinute = parseInt(vtoStartTimeSplit[1]);

    const vtoStartTimeDate = new Date(
      dateToSkip.getFullYear(),
      dateToSkip.getMonth(),
      dateToSkip.getDate(),
      vtoStartTimeHour,
      vtoStartTimeMinute
    );
    return vtoStartTimeDate;
  }

  async initializeLateInForm() {
    this.lateInForm = new FormGroup({
      offerId: new FormControl(!!this.Offer ? this.Offer.offerId : 0),
      status: new FormControl(!!this.Offer ? this.Offer.status : 1),
      companyId: new FormControl(this.companyId),
      departmentId: new FormControl(this.departmentId),
      offerType: new FormControl(10),
      shiftToSkip: new FormControl(this.shiftId),
      dateToSkip: new FormControl(
        !!this.Offer ? this.getDateToSkip(new Date(this.Offer.dateToSkip),this.Offer.vtoStartTime) : new Date(),
        Validators.required
      ),
      vtoStartTime: new FormControl(
        !!this.Offer ? this.getDateToSkip(new Date(this.Offer.dateToSkip),this.Offer.vtoStartTime): new Date()
      ),
      // vtoEndTime: new FormControl(''),
      ReasonId: new FormControl(
        !!this.Offer ? this.Offer.ReasonId : "",
        Validators.required
      ),
      // uTOHours: new FormControl(!!this.Offer ? this.Offer.uTOHours : '', Validators.required),
      createdBy: new FormControl(!!this.Offer ? this.Offer.createdBy : null),
      IsPaidOff: new FormControl(!!this.Offer ? this.Offer.IsPaidOff : false),
      OtherReason: new FormControl(!!this.Offer ? this.Offer.OtherReason : ""),
    });
    if (
      this.lateInForm.controls.offerId.value > 0 &&
      this.lateInForm.controls.ReasonId.value === 1
    ) {
      this.isOtherReason = true;
      this.lateInForm.controls.OtherReason.setValidators(Validators.required);
    }
    this.lateInForm.controls.vtoStartTime.setValidators(Validators.required);
    // this.lateInForm.controls.vtoEndTime.setValidators(Validators.required);
    this.lateInForm.controls.vtoStartTime.updateValueAndValidity();
    // this.lateInForm.controls.vtoEndTime.updateValueAndValidity();
    // this.lateInForm.controls.uTOHours.disable();
  }

  initializeMessages() {
    this.messageList.endTime = {
      required: Constants.VALIDATION_MSG.LATE_IN.END_TIME,
    };
    this.messageList.startTime = {
      required: Constants.VALIDATION_MSG.LATE_IN.START_TME,
    };
    this.messageList.date = {
      required: Constants.VALIDATION_MSG.LATE_IN.DATE,
    };
    this.messageList.reason = {
      required: Constants.VALIDATION_MSG.LATE_IN.REASON,
    };
    this.messageList.utoHours = {
      required: Constants.VALIDATION_MSG.LATE_IN.UTO_HOURS,
    };
    this.messageList.OtherReason = {
      required: Constants.VALIDATION_MSG.CALL_IN.OTHER_REASON,
    };
  }
  setnewDate(date) {
    const dateObj = {
      year: +this.datepipe.transform(date, "yyyy"),
      month: +this.datepipe.transform(date, "MM"),
      day: +this.datepipe.transform(date, "dd"),
    };
    return new Date(dateObj["year"], dateObj["month"] - 1, dateObj["day"]);
  }

  setTimes(startTime, endTime) {
    if (!!startTime) {
      const startdate = new Date();
      startdate.setHours(
        Number(startTime.split(":")[0]),
        Number(startTime.split(":")[1])
      );
      this.lateInForm.controls.vtoStartTime.setValue(startdate);
    } else {
      this.toaster.error(Constants.SHIFT_START_TIME_CONFIGURE);
      this.lateInForm.controls.vtoStartTime.setValue(null);
    }
    if (!!endTime) {
      const enddate = new Date();
      enddate.setHours(
        Number(endTime.split(":")[0]),
        Number(endTime.split(":")[1])
      );
      // this.lateInForm.controls.vtoEndTime.setValue(enddate);
    } else {
      this.toaster.error(Constants.SHIFT_END_TIME_CONFIGURE);
      // this.lateInForm.controls.vtoEndTime.setValue(null);
    }
  }

  skipDateChange(event) {
    // if (this.Offer != null && this.counter === 0) {
    //   this.setTimes(this.Offer.vtoStartTime, this.Offer.vtoEndTime);
    //   this.shiftService.getTimeByShiftDateTime(this.Offer.dateToSkip, this.Offer.shiftToSkip, 1)
    //     .then(response => {
    //       if (response['Success']) {
    //         this.shiftEndTime = response['Data'].endTime;
    //         this.shiftStartTime = response['Data'].startTime;
    //         this.nightShift = false;
    //         if ((Number(this.shiftEndTime.split(":")[0]) - Number(this.shiftStartTime.split(":")[0])) < 0)
    //           this.nightShift = true;
    //       }
    //     }, err => { });
    //   this.counter++;
    // }
    // else {
    //   if (event.getTime()) {
    //     const skipDate = this.datepipe.transform(this.setnewDate(event), 'yyyy-MM-dd');
    //     this.lateInForm.controls.dateToSkip.setValue(skipDate);
    //     this.getTimeByShiftDateTime(skipDate, this.shiftId);
    //   }
    // }
  }

  getSettingByCompanyID() {
    let module = SubscriptionType.filter((item) => {
      return item.value === "Late In Module";
    });
    this.moduleId = module[0].id;
    const getMethod = this.callInRequstService.getSettingByCompanyID(
      this.moduleId,
      this.companyId
    );
    getMethod.then(
      (res: any) => {
        if (res["Success"]) {
          this.settingList = res.Data;
          if (this.settingList.length > 0) {
            this.settingList.map((item) => {
              if (item.SettingType === SettingType[0].value) {
                item.Name = "Paid Time Off";
                this.paidTimeOff = item;
                if (this.value("offerId") == 0) {
                  if (this.paidTimeOff.Enable == true) {
                    this.isPaidTimeOff = true;
                    this.message =
                      "All missed time will be covered by your Unpaid Time Off (UTO) balance. If you do not have enough UTO balance to cover the entire absence, Paid Time Off (PTO) will be applied to cover the shortage. It is your responsibility to ensure that you have enough UTO+PTO accumulated to cover this absence.";
                    this.lateInForm.controls["IsPaidOff"].setValue(true);
                  } else {
                    this.isPaidTimeOff = false;
                    this.message =
                      "All missed time will be covered by your Unpaid Time Off (UTO) balance. It is your responsibility to ensure that you have enough UTO accumulated to cover this absence.";
                    this.lateInForm.controls["IsPaidOff"].setValue(false);
                  }
                }
              }
            });
          }
        } else {
          this.settingList = [];
        }
      },
      (err) => {
        this.settingList = [];
      }
    );
  }

  getTimeByShiftDateTime(date, shift) {
    this.shiftService.getTimeByShiftDateTime(date, Number(shift), 1).then(
      (response) => {
        if (response["Success"]) {
          this.shiftEndTime = response["Data"].endTime;
          this.shiftStartTime = response["Data"].startTime;
          this.nightShift = false;
          if (
            Number(this.shiftEndTime.split(":")[0]) -
              Number(this.shiftStartTime.split(":")[0]) <
            0
          )
            this.nightShift = true;
          // this.setTimes(this.shiftStartTime, this.shiftEndTime);
          // this.setUtoTime(this.lateInForm.controls.vtoStartTime.value)
        } else {
          // this.offerForm.controls.vtoStartTime.setValue(null);
          // this.offerForm.controls.vtoEndTime.setValue(null);
        }
      },
      (err) => {}
    );
  }
  setUtoTime(startTime) {
    if (
      new Date(startTime).getHours() ==
        Number(this.shiftEndTime.split(":")[0]) &&
      new Date(startTime).getMinutes() > 0
    ) {
      this.toaster.error("Your start time is greater than shift end time");
      // this.lateInForm.controls.uTOHours.setValue('');
      return;
    }
    if (!this.nightShift) {
      if (
        new Date(startTime).getHours() + 1 ==
          Number(this.shiftEndTime.split(":")[0]) &&
        new Date(startTime).getMinutes() > 0
      ) {
        var utoHours = 0;
      } else {
        var utoHours =
          Number(this.shiftEndTime.split(":")[0]) -
          new Date(startTime).getHours();
      }
    } else {
      if (
        new Date(startTime).getHours() + 1 ==
          Number(this.shiftEndTime.split(":")[0]) &&
        new Date(startTime).getMinutes() > 0
      ) {
        var utoHours = 0;
      } else {
        if (startTime.getHours() >= 12) {
          var dt1 = new Date(
            this.todaysDate.setHours(new Date(startTime).getHours())
          );
          var dt2 = new Date(
            this.tomorrowsDate.setHours(Number(this.shiftEndTime.split(":")[0]))
          );
          var utoHours = this.diff_hours(dt1, dt2);
        } else {
          var dt1 = new Date(
            this.todaysDate.setHours(new Date(startTime).getHours())
          );
          var dt2 = new Date(
            this.todaysDate.setHours(Number(this.shiftEndTime.split(":")[0]))
          );
          var utoHours = this.diff_hours(dt1, dt2);
        }
      }
    }
    if (
      Number(this.shiftEndTime.split(":")[1]) >=
      new Date(startTime).getMinutes()
    ) {
      var utoMinutes = (
        Number(this.shiftEndTime.split(":")[1]) -
        new Date(startTime).getMinutes()
      ).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    } else {
      utoMinutes = (60 - new Date(startTime).getMinutes()).toLocaleString(
        "en-US",
        {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }
      );
      if (utoHours > 0) {
        utoHours -= 1;
      }
    }
    // this.lateInForm.controls.uTOHours.setValue(utoHours + '.' + utoMinutes);
  }

  save() {
    this.formService.markFormGroupTouched(this.lateInForm);
    if (this.lateInForm.invalid) {
      return;
    }
    // this.lateInForm.controls.uTOHours.enable();
    let selectedDateTime = new Date(this.lateInForm.controls.dateToSkip.value);
    if (
      selectedDateTime.toDateString() == new Date().toDateString() &&
      new Date(this.lateInForm.controls.vtoStartTime.value) < new Date()
    ) {
      this.toaster.error("You cannot add request in past time");
      return;
    }
    const mxdate = this.datepipe.transform(
      this.setnewDate(this.lateInForm.controls.dateToSkip.value),
      "yyyy-MM-dd"
    );
    const startTimeValue = this.lateInForm.controls.vtoStartTime.value;
    // const endTimeValue = this.lateInForm.controls.vtoEndTime.value;
    var startTime =
      new Date(startTimeValue)
        .getHours()
        .toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
      ":" +
      new Date(startTimeValue)
        .getMinutes()
        .toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
      ":00";
    if (this.shiftStartTime == startTime) {
      return this.openStartConfirmPopup();
    }
    if (!!new Date(this.lateInForm.controls.vtoStartTime.value).getTime()) {
      this.lateInForm.controls.vtoStartTime.setValue(
        new Date(startTimeValue).getHours() +
          ":" +
          new Date(startTimeValue).getMinutes()
      );
    }
    // if (!!(new Date(this.lateInForm.controls.vtoEndTime.value).getTime())) {
    //   this.lateInForm.controls.vtoEndTime.setValue(new Date(endTimeValue).getHours() +
    //     ":" +
    //     new Date(endTimeValue).getMinutes());
    // }
    const date = this.lateInForm.controls.dateToSkip.value;
    const dateToSkip = new Date(date);
    const firstDay = new Date(dateToSkip.setDate(dateToSkip.getDate() - 30));
    const startdate = this.datepipe.transform(
      this.setnewDate(firstDay),
      "yyyy-MM-dd"
    );
    const Enddate = this.datepipe.transform(
      this.setnewDate(date),
      "yyyy-MM-dd"
    );
    const checkExistingRequests = this.lateInService.checkWeeklyCallOffRequest(
      startdate,
      Enddate,
      this.lateInForm.controls.offerId.value
    );
    checkExistingRequests.then(
      (res) => {
        if (res["Success"]) {
          if (parseInt(res["Message"]) > 0) {
            this.confirmMsg = `You have called off and taken late in for total of ${
              parseInt(res["Message"]) + 1
            } time in last 30 days. Are you sure you want to continue?`;
            this.isConfirm = true;
          } else {
            this.openPopup();
          }
        } else {
          this.utility.showErrorToast(res["Message"]);
        }
      },
      (err) => {}
    );
  }

  openPopup() {
    this.isConfirm = false;
    this.modalRef = this.modalService.show(this.confirmationPopup, {
      class: "modal-dialog-centered modal-md",
      backdrop: "static",
    });
  }

  close(event) {
    this.isConfirm = false;
    this.lateInService.cancelLateInUserRequest(10, this.cancleLogMsg);
  }

  proceedSubmit() {
    this.modalRef.hide();
    const mxdate = this.datepipe.transform(
      this.setnewDate(this.lateInForm.controls.dateToSkip.value),
      "yyyy-MM-dd"
    );
    this.lateInForm.controls.dateToSkip.setValue(mxdate);
    const saveMethod =
      this.lateInForm.controls.offerId.value > 0
        ? this.lateInService.updateLateInRequestOffer(this.lateInForm.value)
        : this.lateInService.addLateInRequest(this.lateInForm.value);
    saveMethod.then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(
            this.lateInForm.controls.offerId.value > 0
              ? Constants.LATE_IN_REQUEST_UPDATE_SUCCESS_MSG
              : Constants.LATE_IN_REQUEST_ADD_SUCCESS_MSG
          );
          this.closeRequest.emit(true);
        } else {
          this.utility.showErrorToast(res["Message"]);
          this.lateInForm.controls.dateToSkip.setValue(new Date(mxdate));
        }
      },
      (err) => {}
    );
  }
  cancelValidationPopUp() {
    this.modalRef.hide();
    this.lateInService.cancelLateInUserRequest(10, this.cancleLogMsg);
    this.closeRequest.emit(false);
  }
  openStartConfirmPopup() {
    this.isConfirm = false;
    this.modalRef = this.modalService.show(this.startTimeConfirmPopup, {
      class: "modal-dialog-centered modal-md",
      backdrop: "static",
    });
  }
  control(controlName: string): AbstractControl {
    return this.lateInForm.get(controlName);
  }
  value(controlName: string) {
    return this.control(controlName).value;
  }

  startTimeChange(event) {
    if (
      (event.type == "mouseup" && event.srcElement.type == "button") ||
      event.type == "change"
    ) {
      var self = this;
      this.ngZone.run(() => {
        setTimeout(function () {
          self.setUtoTime(self.lateInForm.controls.vtoStartTime.value);
          if (!self.nightShift) {
            if (
              new Date(self.lateInForm.controls.vtoStartTime.value).getHours() >
              Number(self.shiftEndTime.split(":")[0])
            ) {
              self.toaster.error(
                "Your start time is greater than shift end time"
              );
              // self.lateInForm.controls.uTOHours.setValue('');
            }
            if (
              new Date(self.lateInForm.controls.vtoStartTime.value).getHours() <
              Number(self.shiftStartTime.split(":")[0])
            ) {
              self.toaster.error(
                "Your start time is less than shift start time"
              );
              // self.lateInForm.controls.uTOHours.setValue('');
            }
          } else {
            if (self.lateInForm.controls.vtoStartTime.value.getHours() > 12) {
              if (
                new Date(
                  self.lateInForm.controls.vtoStartTime.value
                ).getHours() < Number(self.shiftEndTime.split(":")[0])
              ) {
                self.toaster.error(
                  "Your start time is greater than shift end time"
                );
                // self.lateInForm.controls.uTOHours.setValue('');
              }
              if (
                new Date(
                  self.lateInForm.controls.vtoStartTime.value
                ).getHours() < Number(self.shiftStartTime.split(":")[0])
              ) {
                self.toaster.error(
                  "Your start time is less than shift start time"
                );
                // self.lateInForm.controls.uTOHours.setValue('');
              }
            } else {
              if (
                new Date(
                  self.lateInForm.controls.vtoStartTime.value
                ).getHours() > Number(self.shiftEndTime.split(":")[0])
              ) {
                self.toaster.error(
                  "Your start time is greater than shift end time"
                );
                // self.lateInForm.controls.uTOHours.setValue('');
              }
              if (
                new Date(
                  self.lateInForm.controls.vtoStartTime.value
                ).getHours() > Number(self.shiftStartTime.split(":")[0])
              ) {
                self.toaster.error(
                  "Your start time is less than shift start time"
                );
                // self.lateInForm.controls.uTOHours.setValue('');
              }
            }
          }
        }, 300);
      });
    }
  }

  closePopUp() {
    this.closeRequest.emit(true);
  }
  diff_hours(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
  }
}
