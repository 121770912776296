import { Component, Input, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { OfferService } from 'src/app/service/offer.service';
import { Constants } from 'src/app/constant/constants';
import { Role, OfferStatus } from 'src/app/models/role-model';
import { Offer } from 'src/app/models/offer.model';

@Component({
  selector: 'app-swap-request',
  templateUrl: './swap-request.component.html',
  styleUrls: ['./swap-request.component.scss']
})
export class SwapRequestComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  @ViewChild('template', { static: true }) input: TemplateRef<any>;
  myOffer: Offer;
  myOfferList = [];
  modalRef: BsModalRef;
  isConfirmed = false;
  totalItems: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  deleteId: string;
  role: number;
  roleEnum = Role;
  offerStatus = OfferStatus;
  userId: number;
  searchSort: any;
  columns = [];
  isShowLink = false;
  requestType: any;
  showAddEdit = false;

  constructor(private modalService: BsModalService,
    private toaster: ToastrService,
    private offerService: OfferService) {
    this.columns = ['dateToWorkDateStr', 'dateToSkipDateStr', 'shiftToSkipTitle', 'shiftToWorkTitle', 'offerStatusStr'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.userId = Number(localStorage.getItem(Constants.USERID));
    this.isConfirmed = false;
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getMyOfferList(this.page.pageNumber + 1);
  }

  getMyOfferList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.isShowLink = true;
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      this.offerService.getMyOfferList(currentPage)
        .then((res: any) => {
          if (res['Success']) {
            this.myOfferList = res.Data.results;
            this.totalItems = res.Data.totalNumberOfRecords;
          } else {
            this.myOfferList = [];
            this.totalItems = 0;
          }
        }, err => { this.myOfferList = []; });
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.isShowLink = true;
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.isShowLink = false;
        this.clear();
      }
    }
  }

  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.myOffer = data;
    } else {
      this.myOffer = null;
    }

    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.showAddEdit = false
    this.modalRef.hide();
  }

  onClickDelete(data) {
    this.deleteId = data.offerId;
    this.isConfirmed = true;
  }

  delete(id) {
    this.isConfirmed = false;
    this.offerService.deleteOffer(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.OFFER_DELETE_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { });
  }

  close(event) {
    this.isConfirmed = false;
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  clear() {
    this.isShowLink = false;
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  filterData() {
    this.offerService.myRequestOfferDataFilter(this.searchSort).then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.myOfferList = res['data'];
        this.totalItems = res['recordsFiltered'];
      } else { this.myOfferList = []; this.totalItems = 0; }
    }, err => { this.myOfferList = []; this.totalItems = 0; });
  }

  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }
}
