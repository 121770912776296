import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CollapseModule } from '../../node_modules/ngx-bootstrap/collapse';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from './core/core.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { InputTrimModule } from 'ng2-trim-directive';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TrainingService } from 'src/app/service/training.service';
import { FullCalendarModule } from '@fullcalendar/angular'; 
import { ModalModule } from 'ngx-bootstrap/modal';

import { ResetIdentityComponent } from './reset-identity/reset-identity.component';

@NgModule({
  declarations: [
    AppComponent,
    ResetIdentityComponent,
  ],
  imports: [
  BrowserModule,
  FullCalendarModule,
    CoreModule,
    AppRoutingModule,
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    TimepickerModule.forRoot(),
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgbModule,
    CKEditorModule,
    NgxSpinnerModule,
    InputTrimModule,
    AccordionModule.forRoot(),
    ModalModule.forRoot()
  ],
  exports: [CollapseModule, ToastrModule, NgMultiSelectDropDownModule, NgbModule, CKEditorModule, NgxSpinnerModule, AccordionModule],
  bootstrap: [AppComponent],
  providers: [NgxSpinnerService,TrainingService]
})
export class AppModule { }
