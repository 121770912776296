import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  DoCheck,
  HostListener,
  Input,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Constants } from "../constant/constants";
import { AuthService } from "../core/auth/auth.service";
import { FormService } from "../core/form.service";
import { LaunchKiosk } from "../models/lanuchkiosk.model";
import { AuthorizeLaunchService } from "../service/authorizeLaunch.service";
import { UtilityService } from "../service/utility.service";

@Component({
  selector: "app-launch-kiosk",
  templateUrl: "./launch-kiosk.component.html",
  styleUrls: ["./launch-kiosk.component.scss"],
})
export class LaunchKioskComponent implements OnInit {
  @Input() ipAddress: any;
  @Input() name: string;
  companyName = "";
  timer: any;
  public launchKioskForm: FormGroup;
  public messageList: any = new LaunchKiosk();
  isAuthorizeLaunch = "";
  date = new Date();
  currentIpAddress: any;
  constructor(
    private utilityService: UtilityService,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private formService: FormService,
    private http: HttpClient,
    private authorizeLaunchService: AuthorizeLaunchService,
    private datepipe: DatePipe
  ) {
    this.authService.getConfiguration().then(
      (response) => {
        if (response["Success"] && !!response["Data"]) {
          document.documentElement.style.setProperty(
            "--bgImg",
            `url(${localStorage.getItem(Constants.LOGIN_URL)})`
          );
        }
      },
      (err) => {}
    );
    this.companyName =
      this.activeRoute.snapshot.data.user.Data.company.companyName;
  }

  ngOnInit() {
    this.utilityService.hideLoading();
    this.getIpAddress();

    this.reloadPageTimer();
    this.formInit();
    this.initializeMessages();
  }

  formInit() {
    this.launchKioskForm = new FormGroup({
      username: new FormControl("", [Validators.required]),
    });
  }

  reloadPageTimer(time = 300000) {
    this.timer = setTimeout(() => {
      window.location.reload();
    }, time);
  }

  onKeyUp(event) {
    clearTimeout(this.timer);
    this.reloadPageTimer();
  }

  checkMouseOver(event) {
    if (event) {
      clearTimeout(this.timer);
      this.reloadPageTimer();
    }
  }

  initializeMessages() {
    this.messageList.username = {
      required: Constants.VALIDATION_MSG.LAUNCHKIOSK.USERNAME_REQUIRED,
    };
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.launchKioskForm);
    if (this.launchKioskForm.invalid) {
      return;
    }
    let punchTime = this.datepipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss");

    let userName = this.launchKioskForm.controls.username.value;
    this.authorizeLaunchService
      .submitPunches({
        userName: userName,
        punchTime: punchTime,
        ipAddress: this.currentIpAddress,
      })
      .then(
        (res: any) => {
          if (res.Success) {
            console.log(res);
            this.utilityService.showSuccessToast(res["Message"]);
            this.formInit();
          } else {
            console.log(res);
            this.utilityService.showErrorToast(res["Message"]);
          }
        },
        (err) => {
          this.utilityService.showErrorToast(err.error.error_description);
          this.formInit();
        }
      );
  }

  private async getIpAddress() {
    await this.http
      .get("https://api.ipify.org/?format=json")
      .subscribe((res: any) => {
        this.currentIpAddress = res.ip;

        this.checkAuthorize();
      });
  }

  checkAuthorize() {
    this.authorizeLaunchService.authorizeLaunch(this.currentIpAddress).then(
      (res: any) => {
        if (res.Success) {
          this.isAuthorizeLaunch = "true";
        } else if (!res.Success) {
          this.isAuthorizeLaunch = "false";
        }
      },
      (err) => {
        this.utilityService.showErrorToast(err.error.error_description);
      }
    );
  }
}
