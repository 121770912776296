import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmployeerComponent } from './employeer.component';
import { EmployerDetailComponent } from './employer-detail/employer-detail.component';


const routes: Routes = [{
  path: '',
  children: [{
    path: '',
    component: EmployeerComponent
  }, {
    path: ':id',
    component: EmployerDetailComponent
  }]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeerRoutingModule { }
