import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Constants } from '../constant/constants';
import { LocationService } from '../service/location.service';
import { Location } from 'src/app/models/location.model';
import { ToastrService } from 'ngx-toastr';
import { Role } from '../models/role-model';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  location = new Location();
  locationList = [];
  modalRef: BsModalRef;
  totalItems: any;
  rowsOnPage = Constants.HR_PAGE_ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  isConfirmed: boolean = false;
  deleteId: string;
  companyId: number;
  roleEnum = Role;
  role: number;
  searchSort: any;
  columns = [];
  isShowLink = false;

  constructor(private modalService: BsModalService,
    private locationService: LocationService,
    private toaster: ToastrService) {
    this.columns = ['locationName'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    if (this.role === this.roleEnum.hrAdmin || this.role === this.roleEnum.superUser) {
      this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    }
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getLocationList(this.page.pageNumber + 1);
  }

  getLocationList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.isShowLink = true;
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod = this.role === this.roleEnum.hrAdmin ? this.locationService.getLocationListByCompany(this.companyId, currentPage)
        : this.locationService.getLocationList(null);
      getMethod.then((res: any) => {
        if (res['Success']) {
          this.locationList = this.role === this.roleEnum.hrAdmin ? res.Data.results : res.Data;
          this.totalItems = this.locationList.length;
        } else { this.locationList = []; this.totalItems = 0; }
      }, err => { this.locationList = []; });
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.isShowLink = true;
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.isShowLink = false;
        this.clear();
      }
    }
  }

  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.location = data;
    } else {
      this.location = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-md',
      backdrop: 'static'
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }

  close(event) {
    this.isConfirmed = false;
  }

  onClickDelete(data) {
    this.deleteId = data.locationId;
    this.isConfirmed = true;
  }

  delete(id) {
    this.isConfirmed = false;
    this.locationService.deleteLocation(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.LOCATION_DELETE_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      } else { this.toaster.error(res['Message']); }
    }, err => { });
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  clear() {
    this.isShowLink = false;
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  filterData() {
    this.locationService.dataFilter(this.searchSort).then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.locationList = res['data'];
        this.totalItems = res['recordsFiltered'];
      } else { this.locationList = []; this.totalItems = 0; }
    }, err => { this.locationList = []; this.totalItems = 0; });
  }

  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }
}
