import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from '../constant/constants';

@Injectable()
export class OfferService {
  constructor(private apiService: ApiService) {}

  getMyOfferList(currentPage) {
    return this.apiService.get(
      `${API.OFFER_ROUTES.getMyOffers}?page=` + currentPage,
      true,
      true
    );
  }

  getAvailableOfferList(currentPage) {
    return this.apiService.get(
      `${API.OFFER_ROUTES.availableOffer}?companyId=${localStorage.getItem(
        Constants.COMPANYID
      )}&page=` + currentPage,
      true,
      true
    );
  }

  getRequestedOfferList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.OFFER_ROUTES.getRequestedOffer}?companyId=${localStorage.getItem(
        Constants.COMPANYID
      )}&page=` +
        currentPage +
        `&pageSize=${rowsOnPage}`,
      true,
      true
    );
  }

  getClosedOfferList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.OFFER_ROUTES.getClosedRequest}?companyId=${localStorage.getItem(
        Constants.COMPANYID
      )}&page=` +
        currentPage +
        `&pageSize=${rowsOnPage}`,
      true,
      true
    );
  }

  // getListByUser(currentPage, rowsOnPage) {
  //   return this.apiService.get(
  //     `${API.TIME_PUNCH_ROUTE.getListByUser}?userId=${localStorage.getItem(Constants.USERID)}&page=` +
  //     currentPage + `&pageSize=${rowsOnPage}`,
  //     true,
  //     true,
  //   );
  // }

  addOffer(params) {
    return this.apiService.post(`${API.OFFER_ROUTES.addOffer}`, params, true);
  }

  updateOffer(params) {
    return this.apiService.put(`${API.OFFER_ROUTES.updateOffer}`, params, true);
  }

  deleteOffer(Id,isStopLoader = true) {
    return this.apiService.delete(
      `${API.OFFER_ROUTES.deleteOffer}${Id}?deletedBy=` +
        localStorage.getItem(Constants.USERID),
      true,
      isStopLoader
    );
  }

  acceptOffer(Id) {
    return this.apiService.put(
      `${
        API.OFFER_ROUTES.acceptOffer
      }?offerID=${Id}&acceptedBy=${localStorage.getItem(Constants.USERID)}`,
      null,
      true
    );
  }

  approveOffer(Id) {
    return this.apiService.put(
      `${
        API.OFFER_ROUTES.approveOffer
      }?offerID=${Id}&approvedBy=${localStorage.getItem(Constants.USERID)}`,
      null,
      true
    );
  }

  rejectOffer(Id) {
    return this.apiService.put(
      `${
        API.OFFER_ROUTES.rejectOffer
      }?offerID=${Id}&rejectedBy=${localStorage.getItem(Constants.USERID)}`,
      null,
      true
    );
  }

  hasPendingRequest(userId) {
    return this.apiService.get(
      `${API.OFFER_ROUTES.pendingRequest}?userId=${userId}`,
      true,
      true
    );
  }

  deleteOfferForUser(Id) {
    return this.apiService.delete(
      `${API.OFFER_ROUTES.deleteOfferUser}?id=${Id}&deletedBy=` +
        localStorage.getItem(Constants.USERID),
      true
    );
  }

  myRequestOfferDataFilter(data) {
    //dataFilter
    return this.apiService.post(
      `${API.OFFER_ROUTES.myRequestOfferDataFilter}`,
      data,
      true
    );
  }

  availableOfferDataFilter(data) {
    //dataFilter
    return this.apiService.post(
      `${API.OFFER_ROUTES.availableOfferDataFilter}`,
      data,
      true
    );
  }

  approvalOfferDataFilter(data) {
    //dataFilter
    return this.apiService.post(
      `${API.OFFER_ROUTES.approvalOfferDataFilter}`,
      data,
      true
    );
  }

  closedOfferDataFilter(data) {
    //dataFilter
    return this.apiService.post(
      `${API.OFFER_ROUTES.closedRequestOfferDataFilter}`,
      data,
      true
    );
  }

  downloadPendingExcelFile(data) {
    return this.apiService.post(
      `${API.OFFER_ROUTES.downloadPendingExcelFile}`,
      data,
      true
    );
  }
  downloadPendingRequestsExcelFiles(data) {
    return this.apiService.post(
      `${API.TIME_PUNCH_EDIT_ROUTE.hrGetUserPendingRequestsDownload}`,
      data,
      true
    );
  }
  hrGetUserClosedRequestsDownload(data) {
    return this.apiService.post(
      `${API.TIME_PUNCH_EDIT_ROUTE.hrGetUserClosedRequestsDownload}`,
      data,
      true
    );
  }

  downloadClosedExcelFile(data) {
    return this.apiService.post(
      `${API.OFFER_ROUTES.downloadClosedExcelFile}`,
      data,
      true
    );
  }

  getShiftWorkingDetails(date) {
    return this.apiService.get(
      `${API.OFFER_ROUTES.getShiftWorkingDetails}?date=${date}`,
      true,
      true
    );
  }

  hasUnregisterDevice(id) {
    return this.apiService.get(
      `${API.OFFER_ROUTES.pendingUnregisterDevice}?id=${id}`,
      true,
      true
    );
  }

  hrGetUserPendingRequests(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${
        API.TIME_PUNCH_EDIT_ROUTE.hrGetUserPendingRequests
      }?companyId=${localStorage.getItem(Constants.COMPANYID)}&page=` +
        currentPage +
        `&pageSize=${rowsOnPage}`,
      true,
      true
    );
  }

  hrGetUserClosedRequests(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${
        API.TIME_PUNCH_EDIT_ROUTE.GetHrClosedRequests
      }?companyId=${localStorage.getItem(Constants.COMPANYID)}&page=` +
        currentPage +
        `&pageSize=${rowsOnPage}`,
      true,
      true
    );
  }

  approvedDeniedUserRequests(timePunchId, status) {
    return this.apiService.post(
      `${API.TIME_PUNCH_EDIT_ROUTE.approveRejectTimePuncheEditRequest}?id=${timePunchId}&status=` +
        status,
      true,
      true
    );
  }
  userEditRequest(data) {
    return this.apiService.post(
      `${API.TIME_PUNCH_EDIT_ROUTE.editUserRequest}`,
      data,
      true
    );
  }
  userAddRequest(data) {
    return this.apiService.post(
      `${API.TIME_PUNCH_EDIT_ROUTE.addUserRequest}`,
      data,
      true
    );
  }
  hrGetUserPendingRequestsDataFilter(data) {
    return this.apiService.post(
      `${API.TIME_PUNCH_EDIT_ROUTE.hrGetUserPendingRequestsDataFilter}`,
      data,
      true
    );
  }

  hrGetUserClosedRequestsDataFilter(data) {
    return this.apiService.post(
      `${API.TIME_PUNCH_EDIT_ROUTE.hrGetUserClosedRequestsDataFilter}`,
      data,
      true
    );
  }

  removeTimePunchRequest(timePunchId) {
    return this.apiService.delete(
      `${API.TIME_PUNCH_ROUTE.removeTimePunchRequest}?timePunchId=${timePunchId}`,
      true
    );
  }
}
