import { DatePipe } from "@angular/common";
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Constants, SubscriptionType } from "src/app/constant/constants";
import { Offer } from "src/app/models/offer.model";
import { OfferStatus, Role } from "src/app/models/role-model";
import { CallInRequestService } from "src/app/service/callInRequest.service";
import { DepartmentService } from "src/app/service/department.service";
import { LateInService } from "src/app/service/lateIn.service";
import { OfferService } from "src/app/service/offer.service";
import { ShiftService } from "src/app/service/shift.service";
import { commonHelper } from "src/app/shared/Helpers/commonHelper";
import * as _ from "lodash";
import { forkJoin } from "rxjs";
import { UtilityService } from "src/app/service/utility.service";

@Component({
  selector: "app-late-in-request",
  templateUrl: "./late-in-request.component.html",
  styleUrls: ["./late-in-request.component.scss"],
})
export class LateInRequestComponent implements OnInit {
  @ViewChild("filterTextValue", { static: false }) filterTextValue;
  @ViewChild("workDateValue", { static: false }) workDateValue;
  @ViewChild("shiftValue", { static: false }) shiftValue;
  @ViewChild("departmentValue", { static: false }) departmentValue;
  @ViewChild("timeValue", { static: false }) timeValue;
  @Input() requestType: any;
  public showAction: boolean = true;
  role: number;
  roleEnum = Role;
  modalRef: BsModalRef;
  public myOffer: Offer;
  IsCoverMyWork: boolean;
  settingList: any;
  moduleId: number;
  isPaidTimeOff: boolean;
  lateInRequestList = [];
  public totalItems: any;
  page: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  searchSort: any;
  offerStatus = OfferStatus;
  deleteId: string;
  isConfirmed = false;
  selected = [];
  columns = [];
  selectedRecords = [];
  searchDate: any;
  filterValue = [];
  nextDate: any;
  commonShiftList = [];
  shiftList = [];
  departmentList = [];

  constructor(
    private modalService: BsModalService,
    private lateInService: LateInService,
    private offerService: OfferService,
    private toaster: ToastrService,
    private callInRequstService: CallInRequestService,
    private datepipe: DatePipe,
    private shiftService: ShiftService,
    private departmentService: DepartmentService,
    private utilityService : UtilityService
  ) {
    this.columns = [
      "departmentId",
      "dateToSkip",
      "shiftToSkip",
      "createdByUsername",
      "createdShortDate",
      "createdByObj.shift.title",
      "departmentName",
      "department.departmentName",
      "subDepartmentName",
      "shiftToWorkTitle",
      "createdDate",
      "isPit",
      "operateToShow",
      "preferenceId",
      "dateToWorkDateStr",
      "createdByShift",
    ];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  ngOnInit() {
    this.IsCoverMyWork =
      localStorage.getItem(Constants.APP_NAME) === "CoverMyWork" ? true : false;
    this.role = Number(localStorage.getItem(Constants.ROLE));
    if (this.role == this.roleEnum.manager) {
      this.showAction = false;
    }
    this.setPage({ offset: 0 });
    if (this.role === this.roleEnum.manager) {
      forkJoin([
        this.departmentService.getDepartmentListByCompanyId(
          null,
          Number(localStorage.getItem(Constants.COMPANYID)),
          false
        ),
        this.shiftService.getShiftListByCompanyId(
          null,
          Number(localStorage.getItem(Constants.COMPANYID)),
          false
        ),
      ]).subscribe((res: any) => {
        if (res[0]["Success"]) {
          this.departmentList = res[0]["Data"];
        } else {
          this.departmentList = [];
        }

        if (res[1]["Success"]) {
          this.shiftList = res[1]["Data"];
          this.commonShiftList = _.merge([], this.shiftList);
        } else {
          this.shiftList = [];
          this.commonShiftList = [];
        }
        this.utilityService.hideLoading();
      });
    }
  }

  async setPage(pageInfo) {
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
    await this.getLateInOfferListAsync(this.page.pageNumber + 1);
    await this.getSettingByCompanyIDAsync();
  }

  async getSettingByCompanyIDAsync() {
    let module = SubscriptionType.filter((item) => {
      return item.value === "Late In Module";
    });
    this.moduleId = module[0].id;
    const getMethod =
      this.role == this.roleEnum.manager
        ? this.callInRequstService.getSettingByCompanyID(
            this.moduleId,
            Number(localStorage.getItem(Constants.COMPANYID)),
            false
          )
        : this.callInRequstService.getSettingByCompanyID(
            this.moduleId,
            Number(localStorage.getItem(Constants.COMPANYID))
          );
    await getMethod.then(
      (res: any) => {
        if (res["Success"]) {
          if (res.Data.length > 0) {
            res.Data.map((item) => {
              if (item.SettingType === "PaidTimeOff") {
                item.Name = "Paid Time Off";
                if (item.Enable == true) {
                  this.isPaidTimeOff = true;
                } else {
                  this.isPaidTimeOff = false;
                }
              }
            });
          }
        } else {
          this.settingList = [];
        }
      },
      (err) => {
        this.settingList = [];
      }
    );
  }

  ngAfterViewInit() {
    if (this.role === this.roleEnum.manager) {
      // this.workDateValue.nativeElement.value = this.nextDate;
      this.filterValue.push("");
      this.filterValue.push(this.shiftValue.nativeElement.value);
      this.filterValue.push(this.departmentValue.nativeElement.value);
      this.columns.forEach((element, i) => {
        if (i < 3) {
          const obj = {
            Data: "string",
            Searchable: true,
            Orderable: true,
            Name: element,
            Search: {
              Value: this.filterValue[i],
              Regex: "string",
            },
          };
          this.searchSort.Columns.push(obj);
        }
      });
    }
  }

  async getLateInOfferListAsync(currentPage) {
    const getMethod =
      this.role === this.roleEnum.manager
        ? this.lateInService.getLateInClosedManagerRequestList(
            currentPage,
            this.rowsOnPage,
            false
          )
        : this.lateInService.getLateInRequestList(
            currentPage,
            this.rowsOnPage,
            false
          );
    await getMethod.then(
      (res: any) => {
        if (res["Success"]) {
          if (res.Data && res.Data.results.length > 0) {
            this.lateInRequestList = res.Data.results;
            this.totalItems = res.Data.totalNumberOfRecords;
          } else {
            this.lateInRequestList = [];
            this.totalItems = 0;
          }
        } else {
          this.lateInRequestList = [];
          this.totalItems = 0;
        }
      },
      (err) => {
        this.lateInRequestList = [];
        this.totalItems = 0;
      }
    );
  }
  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  filterData() {
    const filterMethod =
      this.role === this.roleEnum.manager
        ? this.lateInService.managerClosedLateInOfferDataFilter(this.searchSort)
        : this.lateInService.userLateInOfferDataFilter(this.searchSort);
    filterMethod.then(
      (res) => {
        if (res["data"] && res["data"].length > 0) {
          this.lateInRequestList = res["data"];
          this.totalItems = res["recordsFiltered"];
        } else {
          this.lateInRequestList = [];
          this.totalItems = 0;
        }
      },
      (err) => {
        this.lateInRequestList = [];
        this.totalItems = 0;
      }
    );
  }
  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clearText();
      }
    }
  }
  clear() {
    if (this.role === this.roleEnum.user) {
      this.searchSort.Search.Value = "";
      this.searchSort.Order[0].ColumnName = "";
      this.searchSort.Order[0].Dir = "asc";
      this.filterTextValue.nativeElement.value = "";
      this.setPage({ offset: 0 });
    } else {
      this.searchSort.Search.Value = "";
      this.searchSort.Order[0].ColumnName = "";
      this.searchSort.Order[0].Dir = "asc";
      this.filterTextValue.nativeElement.value = "";
      this.searchSort.Columns.forEach((element) => {
        element.Search.Value = "";
      });
      this.shiftValue.nativeElement.value = "";
      this.departmentValue.nativeElement.value = "";
      // this.workDateValue.nativeElement.value = this.nextDate;
      this.workDateValue.nativeElement.value = "";
      this.searchDate = this.nextDate;
      this.setPage({ offset: 0 });
    }
  }
  clearText() {
    this.searchSort.Search.Value = "";
    this.filterTextValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }

  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.myOffer = data;
    } else {
      this.myOffer = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }

  onClickDelete(data) {
    this.deleteId = data.offerId;
    this.isConfirmed = true;
  }
  delete(id) {
    this.isConfirmed = false;
    this.offerService.deleteOffer(id).then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(Constants.OFFER_DELETE_SUCCESS_MSG);
          this.setPage({ offset: 0 });
        } else {
          this.toaster.error(res["Message"]);
        }
      },
      (err) => {}
    );
  }
  close(event) {
    this.isConfirmed = false;
  }

  workDateChange(event) {
    this.searchDate = null;
    const findDateColumn = this.searchSort.Columns.find(
      (x) => x.Name === "dateToSkip"
    );
    if (!!findDateColumn) {
      findDateColumn.Search.Value = this.datepipe.transform(
        this.setnewDate(event),
        "yyyy-MM-dd"
      );
      this.searchDate = this.datepipe.transform(
        this.setnewDate(event),
        "yyyy-MM-dd"
      );
    }
  }
  shiftChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(
      (x) => x.Name === "shiftToSkip"
    );
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = event.currentTarget.value;
    }
  }

  setnewDate(date) {
    const dateObj = {
      year: +this.datepipe.transform(date, "yyyy"),
      month: +this.datepipe.transform(date, "MM"),
      day: +this.datepipe.transform(date, "dd"),
    };
    return new Date(dateObj["year"], dateObj["month"] - 1, dateObj["day"]);
  }

  departmentChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(
      (x) => x.Name === "shiftToSkip"
    );
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = "";
    }
    if (event.currentTarget.value)
      this.shiftList = commonHelper.filterShiftListFromDepartmentId(
        event.currentTarget.value,
        this.commonShiftList
      );
    else this.shiftList = this.commonShiftList;
    const findDepartmentColumn = this.searchSort.Columns.find(
      (x) => x.Name === "departmentId"
    );
    if (!!findDepartmentColumn) {
      findDepartmentColumn.Search.Value = event.currentTarget.value;
    }
  }
  getDepartmentList() {
    this.departmentService
      .getDepartmentListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID))
      )
      .then(
        (res) => {
          if (res["Success"]) {
            this.departmentList = res["Data"];
          } else {
            this.departmentList = [];
          }
        },
        (err) => {
          this.departmentList = [];
        }
      );
  }

  getShiftList() {
    this.shiftService
      .getShiftListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID))
      )
      .then(
        (response) => {
          this.shiftList = response["Data"];
          this.commonShiftList = _.merge([], this.shiftList);
        },
        (err) => {
          this.shiftList = [];
        }
      );
  }
}
