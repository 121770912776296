export class timePunchModel {
    TimePunchesId: number;
    PunchTime: Date;
    PunchTimeHoursMinutes:Date;
    AdjustedPunchTime: Date;
    AdjustedPunchTimeTimeHoursMinutes:Date;
    PunchType: number;
    PunchLocation: number; 
    Source: number;
    Compliance: number;
    IsDeleted:boolean;
    CompanyId:number;
    CreatedDate:Date;
    CreatedBy:number;
    ModifiedDate:Date;
    geoLocation:string;
    ModifiedBy:number;
    reason: string;
  }
  

