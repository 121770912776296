import { DatePipe } from "@angular/common";
import { CalendarOptions } from '@fullcalendar/angular';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { bsConfig, Constants } from "src/app/constant/constants";
import { OfferStatus, Role } from "src/app/models/role-model";
import { DepartmentService } from "src/app/service/department.service";
import { ShiftService } from "src/app/service/shift.service";
import { UtilityService } from "src/app/service/utility.service";
import { TimeOffService } from "src/app/service/time-off.service";
import { environment } from "src/environments/environment";
import * as _ from "lodash";
import { commonHelper } from "src/app/shared/Helpers/commonHelper";


@Component({
  selector: "app-hr-manager-time-off-register",
  templateUrl: "./hr-manager-time-off-register.component.html",
  styleUrls: ["./hr-manager-time-off-register.component.scss"],
})
export class HrManagerTimeOffRegisterComponent implements OnInit {
  @Output() reloadCount = new EventEmitter<boolean>();
  @ViewChild("filterTextValue", { static: false }) filterTextValue;
  @ViewChild("shiftValue", { static: false }) shiftValue;
  @ViewChild("departmentValue", { static: false }) departmentValue;
  @ViewChild("typeValue", { static: false }) typeValue;
  @Input() requestType: string;
  @Input() showCloseRequestOption: boolean;
  @Input() showCloseRequestOptionManager: boolean;
  requestedOffer: any;
  timeOffRequestsLists = [];
  modalRef: BsModalRef;
  totalItems: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  offerStatus = OfferStatus;
  hrStatus = OfferStatus.hrApproval;
  searchSort: any;
  columns = [];
  bsConfig: Partial<BsDatepickerConfig> = bsConfig;
  allRowsSelected = [];
  shiftList = [];
  departmentList = [];
  selectedRecords = [];
  filterValue = [];
  selected = [];
  typeList = [];
  type = "";
  companyId: number;
  timeOffConfigList = [];
  commonShiftList: any;
  roleEnum = Role;
  companyList = [];
  role: number;
  isChecked: boolean = false;
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this),
    editable: true,
    selectable: true,
    contentHeight: 1000,
    height: 1000,
  };
  isShow = true;
  calendarViewList = [];
  userName: any;
  isPushed = true;
  isEnabled = true;
  tempArr = [];
  isCheckbox: boolean;

  constructor(
    private modalService: BsModalService,
    private shiftService: ShiftService,
    private departmentService: DepartmentService,
    private utilityService: UtilityService,
    private timeOffService: TimeOffService  ) {
    this.columns = [
      "ShiftId",
      "TimeOffId",
      "DepartmentId",
      "timeType",
      "Shift.title",
      "UserName",
      "TimeOffDifference",
      "TimeOffEndDateStr",
      "TimeOffStartDateStr",
      "TimeOffConfiguration.Name",
      "Department.departmentName",
      "NetBalance",
    ];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  async ngOnInit() {
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.role = Number(localStorage.getItem(Constants.ROLE));

    await this.getTimeOffConfigList();
    this.bsConfig = Object.assign(
      {},
      {
        dateInputFormat: "MM/DD/YYYY",
      }
    );
    await this.getShiftList().then();
    await this.getDepartmentList();
    await this.setPage({ offset: 0 });
  }

  handleDateClick(args: any) {
    if(args["event"] == undefined){
      return;
    }
    else{
     //Approve/Deny for calendar view
    if(args.event._def.extendedProps.Status == 1){
      this.isPushed = !this.isPushed;
    }
    this.isEnabled = !this.isEnabled;
    this.selectedRecords = [args["event"]["_def"]["publicId"], ...this.selectedRecords]
    this.isCheckbox = false; //Checkbox view toggle
    let selectedEvent = this.tempArr.map((x) => {
      if (x.id == args.event._def.publicId && this.isEnabled == false &&
         this.isPushed == false) {
        return {
          color: "red",
          ...x
        };
      } else {
        return x;
      }
    })
    this.calendarOptions.events = selectedEvent;
    }
  }

  async getTimeOffConfigList() {
    await this.timeOffService
      .getTimeOff(null, this.companyId,false,true)
      .then((response) => {
        if (response["Success"]) {
          this.timeOffConfigList = response["Data"];
        }
      });
  }

  ngAfterViewInit() {
    this.filterValue.push("");
    this.filterValue.push(this.typeValue.nativeElement.value);
    this.filterValue.push(this.shiftValue.nativeElement.value);
    this.filterValue.push(this.departmentValue.nativeElement.value);
    this.columns.forEach((element, i) => {
      if (i < 3) {
        const obj = {
          Data: "string",
          Searchable: true,
          Orderable: true,
          Name: element,
          Search: {
            Value: this.filterValue[i],
            Regex: "string",
          },
        };
        this.searchSort.Columns.push(obj);
      }
    });
  }

  showCalendar() {
    this.isPushed = true;
    this.isShow = this.isShow ? false : true;
    if (!this.isShow) {
      this.getHrCalendarList(this.page.pageNumber + 1);
    }
    else {
      this.isChecked = false;
      this.getHrTimeOffList(this.page.pageNumber + 1);
      this.setPage({ offset: 0 });
    }
  }

  async setPage(pageInfo, isReloadCount = false) {
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
    if (this.role === Role.hrAdmin) {
      await this.getHrTimeOffList(this.page.pageNumber + 1);
      if (isReloadCount) {
        this.reloadCount.emit();
      }
    } else {
      await this.gettimeOffRequestsLists(this.page.pageNumber + 1);
    }
  }
  async getShiftList() {
    await this.shiftService.getShiftListByCompanyId(null, this.companyId,false,true).then(
      (response) => {
        this.shiftList = response["Data"];
        this.commonShiftList = _.merge([], this.shiftList);
      },
      (err) => {
        this.shiftList = [];
      }
    );
  }

  openModal(template: TemplateRef<any>, type) {
    if (type === "approve") {
      this.type = type;
    } else {
      this.type = type;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-md",
      backdrop: "static",
    });
  }
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    selected.map((e) => {
      this.selected.push(e);
      this.selectedRecords = this.selected.map((x) => x.TimeOffUserRequestId);
    });
    this.isCheckbox = true;
  }

  async getDepartmentList() {
    await this.departmentService
      .getDepartmentListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID)),
        false,
        true
      )
      .then(
        (res) => {
          if (res["Success"]) {
            this.departmentList = res["Data"];
          } else {
            this.departmentList = [];
          }
        },
        (err) => {
          this.departmentList = [];
        }
      );
  }
  

  getHrCalendarList(currentPage) {
    const getMethod = this.requestType === Constants.REQUESTED_OFFER ?
      this.timeOffService.getPendingHRTimeOffRequestList(
        this.companyId,
        null,
        this.isChecked = true)
      : undefined;
    getMethod.then(
      (res: any) => {
        if (JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))) {
          this.utilityService.showLoading();
        }
        if (res["Success"]) {
          this.isEnabled = true;
          this.calendarViewList = res["Data"];
          this.tempArr = []
          this.calendarViewList.map((item) => {
            this.tempArr.push({ title: item.UserName, date: item.TimeOffStartDate.slice(0, 10), id: item.TimeOffUserRequestId, Status: item.Status });
          })
            this.calendarOptions = {
              initialView: 'dayGridMonth',
              eventClick: this.handleDateClick.bind(this),
              events: this.tempArr,
              eventDidMount: function(data){
                if(data.event._def.extendedProps.Status == 3){
                  data.el.style.background = "#dc3545"
                }
                if(data.event._def.extendedProps.Status == 2){
                  data.el.style.background = "#20c997"
                }
                if(data.event._def.extendedProps.Status == 1){
                  data.el.style.background = "#ffc107"
                }
              },
            };
        }
        else {
          this.tempArr = [];
          this.calendarOptions.events = [];
        }
      }
    )
  }

  async getHrTimeOffList(currentPage) {
    if (
      (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) ||
      (!!this.shiftValue && !!this.shiftValue.nativeElement.value) ||
      (!!this.departmentValue && !!this.departmentValue.nativeElement.value) ||
      (!!this.typeValue && !!this.typeValue.nativeElement.value)
    ) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod =
        this.requestType === Constants.REQUESTED_OFFER
          ? this.timeOffService.getPendingHRTimeOffRequestList(
            this.companyId,
            currentPage,
            this.isChecked
          )
          : this.timeOffService.getClosedHRTimeOffRequestList(
            this.companyId,
            currentPage
          );
      await getMethod.then(
        (res: any) => {
          if (JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))) {
            this.utilityService.showLoading();
          }
          if (res["Success"]) {
            this.timeOffRequestsLists = res["Data"].results;
            this.totalItems =
              res["Data"].totalNumberOfRecords === 0
                ? undefined
                : res["Data"].totalNumberOfRecords;
          } else {
            this.timeOffRequestsLists = [];
            this.totalItems = undefined;
          }
        },
        (err) => {
          this.timeOffRequestsLists = [];
          this.totalItems = undefined;
        }
      );
    }
  }

  async gettimeOffRequestsLists(currentPage) {
    if (
      (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) ||
      (!!this.shiftValue && !!this.shiftValue.nativeElement.value) ||
      (!!this.departmentValue && !!this.departmentValue.nativeElement.value) ||
      (!!this.typeValue && !!this.typeValue.nativeElement.value)
    ) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod =
        this.requestType === Constants.REQUESTED_OFFER
          ? this.timeOffService.getPendingTimeOffRequestList(
            currentPage,
            this.isChecked
          )
          : this.timeOffService.getClosedTimeOffRequestList(currentPage);
      await getMethod.then(
        (res: any) => {
          if (JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))) {
            this.utilityService.showLoading();
          }
          if (res["Success"]) {
            this.timeOffRequestsLists = res["Data"].results;
            this.totalItems =
              res["Data"].totalNumberOfRecords === 0
                ? undefined
                : res["Data"].totalNumberOfRecords;
          } else {
            this.timeOffRequestsLists = [];
            this.totalItems = undefined;
          }
        },
        (err) => {
          this.timeOffRequestsLists = [];
          this.totalItems = undefined;
        }
      );
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clearText();
      }
    }
  }

  shiftChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(
      (x) => x.Name === "ShiftId"
    );
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = event.currentTarget.value;
    }
  }
  typeChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(
      (x) => x.Name === "TimeOffId"
    );
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = event.currentTarget.value;
    }
  }

  departmentChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(
      (x) => x.Name === "ShiftId"
    );
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = "";
    }
    if (event.currentTarget.value)
      this.shiftList = commonHelper.filterShiftListFromDepartmentId(
        event.currentTarget.value,
        this.commonShiftList
      );
    else this.shiftList = this.commonShiftList;
    const findDepartmentColumn = this.searchSort.Columns.find(
      (x) => x.Name === "DepartmentId"
    );
    if (!!findDepartmentColumn) {
      findDepartmentColumn.Search.Value = event.currentTarget.value;
    }
  }

  filterData() {
    if (this.role === Role.hrAdmin) {
      const filterMethod =
        this.requestType === Constants.REQUESTED_OFFER
          ? this.timeOffService.getPendingTimeOffRequestListFilterData(
            this.searchSort,
            this.companyId,
            this.isChecked
          )
          : this.timeOffService.getClosedTimeOffRequestListFilterData(
            this.searchSort,
            this.companyId
          );
      filterMethod.then(
        (res) => {
          if (res["data"] && res["data"].length > 0) {
            this.timeOffRequestsLists = res["data"];
            this.totalItems =
              res["recordsFiltered"] === 0 ? undefined : res["recordsFiltered"];
          } else {
            this.timeOffRequestsLists = [];
            this.totalItems = undefined;
          }
        },
        (err) => {
          this.timeOffRequestsLists = [];
          this.totalItems = undefined;
        }
      );
    }
    else {
      const filterMethod =
        this.requestType === Constants.REQUESTED_OFFER
          ? this.timeOffService.getAllPendingTimeOffRequestManagerFilter(
            this.searchSort,
            this.isChecked
          )
          : this.timeOffService.getAllClosedTimeOffRequestManagerDataFilter(
            this.searchSort,
            this.companyId
          );
      filterMethod.then(
        (res) => {
          if (res["data"] && res["data"].length > 0) {
            this.timeOffRequestsLists = res["data"];
            this.totalItems =
              res["recordsFiltered"] === 0 ? undefined : res["recordsFiltered"];
          } else {
            this.timeOffRequestsLists = [];
            this.totalItems = undefined;
          }
        },
        (err) => {
          this.timeOffRequestsLists = [];
          this.totalItems = undefined;
        }
      );

    }


  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  clearText() {
    this.searchSort.Search.Value = "";
    this.filterTextValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }
  clear() {
    this.searchSort.Search.Value = "";
    this.searchSort.Order[0].ColumnName = "";
    this.searchSort.Order[0].Dir = "asc";
    this.filterTextValue.nativeElement.value = "";
    this.searchSort.Columns.forEach((element) => {
      element.Search.Value = "";
    });
    this.shiftValue.nativeElement.value = "";
    this.departmentValue.nativeElement.value = "";
    this.typeValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }

  async Approved(isApproved) {
    const selectedEventId = this.isCheckbox ? this.selectedRecords : [this.selectedRecords[0]];
    await this.timeOffService
      .approvedDeniedUserRequests(selectedEventId, isApproved)
      .then((res) => {
        if (res["Success"]) {
          this.isPushed = true;
          this.setPage({ offset: 0 }, true);
          if (isApproved) {
            this.utilityService.showSuccessToast(
              Constants.TIME_OFF_REQUEST_APPROVE_MSG
            );
          }
          else {
            this.utilityService.showSuccessToast(
              Constants.TIME_OFF_REQUEST_REJECT_MSG
            );
          }
          this.getHrCalendarList(this.currentPage);
        }
        else {
          this.getHrCalendarList(this.currentPage);
          this.utilityService.showErrorToast(res["Message"]);
        }
      });

  }
  downloadFile() {
    const downloadMethod =
      this.requestType !== "approvedOffer"
        ? this.timeOffService.downloadPendingRequestsExcelFiles(
          this.searchSort,
          this.companyId,
          this.isChecked
        )
        : this.timeOffService.downloadClosedExcelFile(
          this.searchSort,
          this.companyId
        );
    downloadMethod.then(
      (response) => {
        if (response["Success"]) {
          const a = document.createElement("a");
          a.setAttribute("style", "display:none;");
          document.body.appendChild(a);
          a.href = environment.BLOB_URL + response["Message"];
          a.download = response["Message"].split("/")[1];
          a.click();
        } else {
          this.utilityService.showErrorToast(response["Message"]);
        }
      },
      (err) => { }
    );
  }

  async checkCheckbox(event) {
    this.isChecked = false;
    if (event.target.checked) {
      this.isChecked = true;
    } else if (!event.target.checked) {
      this.isChecked = false;
    }

    if (this.role === this.roleEnum.manager) {
      await this.gettimeOffRequestsLists(this.page.pageNumber + 1);
    } else if (this.role === this.roleEnum.hrAdmin) {
      this.getHrTimeOffList(this.page.pageNumber + 1);
    }
  }
}
