import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Setting, SettingMessageList } from "src/app/models/setting.model";
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  FormBuilder,
  RequiredValidator,
} from "@angular/forms";
import { FormService } from "src/app/core/form.service";
import { ToastrService } from "ngx-toastr";
import {
  Constants,
  SubscriptionType,
  SettingType,
  ModuleType,
} from "src/app/constant/constants";
import { UserService } from "src/app/service/user.service";
import { ValidationService } from "../../service/validation.service";
import { SettingsService } from "src/app/service/settings.service";
@Component({
  selector: "app-add-edit-setting",
  templateUrl: "./add-edit-setting.component.html",
  styleUrls: ["./add-edit-setting.component.scss"],
})
export class AddEditSettingComponent implements OnInit {
  @Input() setting: Setting;
  @Output() close = new EventEmitter<boolean>();
  public settingForm: FormGroup;
  public messageList: any = new SettingMessageList();
  companyId: number;
  public settingTypeList = SettingType;
  subscriptionType = SubscriptionType;
  public moduleType = ModuleType;
  cloneModuleType = ModuleType
  isVotCutOffHours: boolean = false;
  isClockInGracePeriod: boolean = false;
  isClockOutGracePeriod: boolean = false; 
  isClockInOutLocationGracePeriod: boolean = false; 

  constructor(
    private formService: FormService,
    private settingService: SettingsService,
    private toaster: ToastrService,
    public validationService: ValidationService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.initializeUserForm();
    this.initializeMessages();
    console.log(this.setting)
  }

  initializeUserForm() {
    this.settingForm = this.fb.group({
      companyId: new FormControl(this.companyId),
      Id: new FormControl(!!this.setting ? this.setting.Id : 0),
      Description: new FormControl(
        !!this.setting ? this.setting.Description : "",
        Validators.required
      ),
      SettingType: new FormControl(
        !!this.setting ? this.setting.SettingType : "",
        Validators.required
      ),
      OfferType: new FormControl(
        !!this.setting ? this.setting.OfferType : "",
        Validators.required
      ),
      Enable: new FormControl(!!this.setting ? this.setting.Enable : false),
      VOTCutOffHours: new FormControl(
        !!this.setting ? this.setting.VOTCutOffHours : ""
      ),
      ClockInGracePeriod: new FormControl(
        !!this.setting ? this.setting.ClockInOutGracePeriod : ""
      ),
      ClockOutGracePeriod: new FormControl(
        !!this.setting ? this.setting.ClockInOutGracePeriod : ""
      ),
      ClockInOutLocationGracePeriod: new FormControl(
        !!this.setting ? this.setting.ClockInOutLocationGracePeriod : ""
      ),


    });
    this.changeSetting("");
    if (this.settingForm.controls.Id.value > 0) {
      this.settingForm.controls.SettingType.disable();
      this.settingForm.controls.OfferType.disable();
      if (this.settingForm.controls.SettingType.value === "VOT Cut-Off Hours") {
        this.isVotCutOffHours = true;
        this.settingForm.controls.VOTCutOffHours.setValidators(
          [Validators.required, Validators.pattern("^[1-9][0-9]?$")]
        );
      }
      if (this.settingForm.controls.SettingType.value === "Mobile check-in/out permitted") {
       this.isClockInOutLocationGracePeriod = true;
      }
    }
  }

  initializeMessages() {
    this.messageList.description = {
      required: Constants.VALIDATION_MSG.SETTING.DESCRIPTION_REQUIRED,
    };
    this.messageList.enable = {
      required: Constants.VALIDATION_MSG.SETTING.ENABLE_REQUIRED,
    };
    this.messageList.offerType = {
      required: Constants.VALIDATION_MSG.SETTING.OFFER_TYPE_REQUIRED,
    };
    this.messageList.settingType = {
      required: Constants.VALIDATION_MSG.SETTING.SETTING_TYPE_REQUIRED,
    };
    this.messageList.VOTCutOffHours = {
      required: Constants.VALIDATION_MSG.SETTING.VOT_CUT_OFF_HOURS,
      pattern: Constants.VALIDATION_MSG.SETTING.VOT_CUT_OFF_HOURS_Pattern,
    };
  }
  onSubmit() {
    this.formService.markFormGroupTouched(this.settingForm);
    if (this.settingForm.invalid) {
      return;
    }
    this.settingForm.controls.OfferType.setValue(
      Number(this.settingForm.controls.OfferType.value)
    );
    this.settingForm.controls.SettingType.enable();
    this.settingForm.controls.OfferType.enable();
    let data = this.settingForm.value;
    data.ClockInOutGracePeriod = this.settingForm.value.ClockInGracePeriod ? this.settingForm.value.ClockInGracePeriod : this.settingForm.value.ClockOutGracePeriod;
    const saveMethod =
      this.settingForm.controls.Id.value > 0
        ? this.settingService.updateSettings(data)
        : this.settingService.addSettings(data);
    saveMethod.then(
      (res: any) => {
        if (res["Success"]) {
          this.toaster.success(
            this.settingForm.controls.Id.value > 0
              ? Constants.SETTING_UPDATE_SUCESS_MSG
              : Constants.SETTING_ADD_SUCESS_MSG
          );
          this.close.emit(true);
        } else {
          this.settingForm.controls.SettingType.disable();
          this.settingForm.controls.OfferType.disable();
          this.toaster.error(res["Message"]);
        }
      },
      (err) => {
        this.close.emit(false);
      }
    );
  }

  cancel() {
    this.close.emit(false);
  }

  control(controlName: string): AbstractControl {
    return this.settingForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }

  changeSetting(event) {
    this.moduleType = this.removeOptionClockInOut();
    this.isVotCutOffHours = false;
    this.isClockOutGracePeriod = false;
    this.isClockInGracePeriod = false;
    this.isClockInOutLocationGracePeriod = false;
    if (this.settingForm.controls.SettingType.value == "Hour Early") {
      this.settingForm.controls.OfferType.setValue(2);
    } else if (this.settingForm.controls.SettingType.value == "Hour Late") {
      this.settingForm.controls.OfferType.setValue(2);
    } else if (this.settingForm.controls.SettingType.value == "PaidTimeOff") {
      this.settingForm.controls.OfferType.enable();
      this.settingForm.controls.VOTCutOffHours.setValidators(null);
      this.settingForm.controls.VOTCutOffHours.updateValueAndValidity();
      this.moduleType = this.moduleType.filter(x=>x.id != 2 && x.id != 0);
      return;
    } else if (this.settingForm.controls.SettingType.value == "FMLA") {
      this.settingForm.controls.OfferType.setValue(6);
    } else if (
      this.settingForm.controls.SettingType.value === "VOT Cut-Off Hours"
    ) {
      this.settingForm.controls.OfferType.setValue(2);
      this.settingForm.controls.VOTCutOffHours.setValidators(
        [Validators.required, Validators.pattern("^[1-9][0-9]?$")]
      );
      this.isVotCutOffHours = true;
    }else if (this.settingForm.controls.SettingType.value == "Call-off intervention") {
      this.settingForm.controls.OfferType.setValue(6);
    }else if (this.settingForm.controls.SettingType.value == "Early-out intervention") {
      this.settingForm.controls.OfferType.enable();
      this.settingForm.controls.VOTCutOffHours.setValidators(null);
      this.settingForm.controls.VOTCutOffHours.updateValueAndValidity();
      this.moduleType = this.moduleType.filter(x=>x.id == 7 || x.id ==10);
      return;
    }else if (this.settingForm.controls.SettingType.value == "Early-Out UTO Message") {
      this.settingForm.controls.OfferType.setValue(7);
    }else if (this.settingForm.controls.SettingType.value == "UTO system for attendance") {
      this.moduleType = this.cloneModuleType;
      this.settingForm.controls.OfferType.enable(); 
      this.settingForm.controls.VOTCutOffHours.setValidators(null);
      this.settingForm.controls.VOTCutOffHours.updateValueAndValidity();
      this.moduleType = this.moduleType.filter(x=>x.id != 3 && x.id != 0);
      return;
    }
    else if (
      this.settingForm.controls.SettingType.value === "Clock-in grace period"
    ) {
      this.moduleType=this.cloneModuleType;
      this.settingForm.controls.OfferType.setValue(11);
       this.settingForm.controls.ClockInGracePeriod.setValidators(
      [Validators.required, Validators.pattern("^[1-9][0-9]?$")]
      );
       this.isClockInGracePeriod = true;
    }
    else if (
      this.settingForm.controls.SettingType.value === "Clock-out grace period"
    ) {
      this.moduleType=this.cloneModuleType;
      this.settingForm.controls.OfferType.setValue(11);
       this.settingForm.controls.ClockOutGracePeriod.setValidators(
      [Validators.required, Validators.pattern("^[1-9][0-9]?$")]
      );
       this.isClockOutGracePeriod = true;
    }
    else if (
      this.settingForm.controls.SettingType.value === "Lunchtime clock-in/out"
    ) {
      this.moduleType=this.cloneModuleType;
      this.settingForm.controls.OfferType.setValue(11);
    }
    else if (
      this.settingForm.controls.SettingType.value === "Mobile check-in/out permitted"
    ) {
      this.moduleType=this.cloneModuleType;
      this.settingForm.controls.OfferType.setValue(11);
         this.isClockInOutLocationGracePeriod = true;
    }
    else if (
      this.settingForm.controls.SettingType.value === "Point system for attendance"
    ) {
      this.moduleType=this.cloneModuleType;
      this.settingForm.controls.OfferType.setValue(11);
    }

    else if (this.settingForm.controls.SettingType.value == "Allow administrators to log in as user") {
      this.settingForm.controls.OfferType.setValue(0);
    }
    else if (this.settingForm.controls.SettingType.value == "Enable Text messaging") {
      this.settingForm.controls.OfferType.setValue(0);
    }else if (
      this.settingForm.controls.SettingType.value === "9 blocker rating system for employee review"
    ) {
      this.moduleType=this.cloneModuleType;
      this.settingForm.controls.OfferType.setValue(12);
    }else if (
      this.settingForm.controls.SettingType.value === "9 blocker system horizontal scale"
    ) {
      this.moduleType=this.cloneModuleType;
      this.settingForm.controls.OfferType.setValue(12);
    }else if (
      this.settingForm.controls.SettingType.value === "9 blocker system vertical scale"
    ) {
      this.moduleType=this.cloneModuleType;
      this.settingForm.controls.OfferType.setValue(12);
    }else if (
      this.settingForm.controls.SettingType.value === "9 blocker rating visible to employee"
    ) {
      this.moduleType=this.cloneModuleType;
      this.settingForm.controls.OfferType.setValue(12);
    }
    this.settingForm.controls.OfferType.disable();
    this.settingForm.controls.VOTCutOffHours.setValidators(null);
    this.settingForm.controls.VOTCutOffHours.updateValueAndValidity();
  }

  removeOptionClockInOut() {
    return this.cloneModuleType.filter(moduleType => {
      if(moduleType.value !== "ClockInOut") {
        return moduleType;
      }
    });
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  
}
