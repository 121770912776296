import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';

@Injectable()
export class VtoRequestService {

  constructor(private apiService: ApiService) { }

  addVtoRequestOffer(params) {
    return this.apiService.post(`${API.VTO_REQUEST_ROUTES.addVtoRequest}`, params, true);
  }

  updateVtoRequestOffer(params) {
    return this.apiService.put(`${API.VTO_REQUEST_ROUTES.updateVtoRequest}`, params, true);
  }

  getMyVtoRequestList(currentPage, rowsOnPage, date) {
    return this.apiService.get(
      `${API.VTO_REQUEST_ROUTES.getVtoRequest}?page=` +
      currentPage + `&pageSize=${rowsOnPage}&date=${date}`,
      true,
      true,
    );
  }

  getAvailableVtoRequestList(currentPage, rowsOnPage, companyId) {
    return this.apiService.get(
      `${API.VTO_REQUEST_ROUTES.availableVtoRequest}?page=` +
      currentPage + `&pageSize=${rowsOnPage}&companyId=${companyId}`,
      true,
      true,
    );
  }

  vtoRequestOfferDataFilter(data, date) { //dataFilter
    return this.apiService.post(`${API.VTO_REQUEST_ROUTES.vtoRequestOfferDataFilter}?date=${date}`, data, true);
  }

  vtoAvailableRequestOfferDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.VTO_REQUEST_ROUTES.availableVtoOfferDataFilter}`, data, true);
  }

  acceptVtoOffer(Id) {
    return this.apiService.put(`${API.VTO_REQUEST_ROUTES.acceptVtoOffer}?offerID=${Id}`, null, true);
  }

  approveVtoOffer(Id) {
    return this.apiService.put(`${API.VTO_REQUEST_ROUTES.approveVtoOffer}?vtoDetailId=${Id}`, null, true);
  }

  approveRejectManagerVtoOffer(Id, status,modifiedDate) {
    return this.apiService.put(`${API.VTO_REQUEST_ROUTES.approveRejectMangerVtoOffer}?offerID=${Id}&status=${status}&modifiedDate=${modifiedDate}`, null, true);
  }

  rejectVtoOffer(Id) {
    return this.apiService.put(`${API.VTO_REQUEST_ROUTES.rejectVtoOffer}?vtoDetailId=${Id}`, null, true);
  }

  getPendingApprovalVtoRequestList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.VTO_REQUEST_ROUTES.getPendingApprovalRequest}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }

  getUnProcessedVtoRequestList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.VTO_REQUEST_ROUTES.getUnProcessedVtoRequest}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }

  getManagerAvailableVtoRequestList(currentPage, rowsOnPage, date) {
    return this.apiService.get(
      `${API.VTO_REQUEST_ROUTES.availableManagerVtoOffers}?page=` +
      currentPage + `&pageSize=${rowsOnPage}&date=${date}`,
      true,
      true,
    );
  }

  vtoManagerAvailableRequestDataFilter(data, date) { //dataFilter
    return this.apiService.post(`${API.VTO_REQUEST_ROUTES.vtoManagerAvailableRequestDataFilter}?date=${date}`, data, true);
  }

  vtoPendingApprovalRequestDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.VTO_REQUEST_ROUTES.pendingApprovalRequestDataFilter}`, data, true);
  }

  vtoUnprocessedRequestDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.VTO_REQUEST_ROUTES.unProcessedVtoOfferDataFilter}`, data, true);
  }

  getClosedVtoRequestList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.VTO_REQUEST_ROUTES.getVtoClosedRequest}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }

  vtoClosedRequestDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.VTO_REQUEST_ROUTES.vtoClosedRequestDataFilter}`, data, true);
  }

  processVtoRequest(ids) {
    return this.apiService.put(`${API.VTO_REQUEST_ROUTES.processVtoRequest}`, ids, true);
  }

  instantVtoRequest(params) {
    return this.apiService.post(`${API.VTO_REQUEST_ROUTES.instantVtoRequest}?userName=${params.userName}&employeeId=${params.employeeId}&vtoStartTime=${params.vtoStartTime}`, null, true);
  }

  downloadPendingExcelFile(data) {
    return this.apiService.post(`${API.VTO_REQUEST_ROUTES.downloadPendingVtoFile}`, data, true);
  }

  downloadClosedExcelFile(data) {
    return this.apiService.post(`${API.VTO_REQUEST_ROUTES.downloadClosedVtoFile}`, data, true);
  }
}
