export class TimeOff {
    companyId: number;
    TimeOffId:number = 0;
    Name:string;
    SeedAtHire:number;
    AnnualAllocation:number;
    ClassType:number;
    MaxAccumulation:number;
    UseItOrLoseIt:true;
    TimeOffConfigTypeId : number;
}