import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { Role } from "../models/role-model";
import { TrainingService } from "../service/training.service";
import { Constants } from "./../constant/constants";

@Component({
  selector: "app-user-training-info",
  templateUrl: "./user-training-info.component.html",
  styleUrls: ["./user-training-info.component.scss"],
})
export class UserTrainingInfoComponent implements OnInit {
  @ViewChild("filterTextValue", { static: false }) filterTextValue;

  userTrainingList = [];
  role: number;
  roleEnum = Role;
  selected = [];
  selectedRecords = [];
  allRowsSelected = [];
  page: any;
  filterValue = [];
  searchSort: any;
  totalItems: any;
  columns = [];
  hrManagercolumns = [];
  rowsOnPage = Constants.ROWS_ON_PAGE;
  companyId: number;
  IsActive = false;

  constructor(
    private trainingService: TrainingService,
    private toaster: ToastrService
  ) {
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.columns = [
      "userName",
      "module.moduleName",
      "Function.functionName",
      "trainingDateShortString",
      "trainerName",
      "statusStr",
      "modifiedDateStr",
      "recordedBy.companyUsername",
    ];
    this.hrManagercolumns = [
      "userName",
      "trainerName",
      "statusStr",
      "modifiedDateStr",
      "recordedBy.companyUsername",
      "functionName.functionName",
      "trainingDateStr",
      "action",
    ];

    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getUserData(this.page.pageNumber + 1);
  }

  getUserData(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList =
        this.role === this.roleEnum.user ? this.columns : this.hrManagercolumns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const Data =
        this.role === this.roleEnum.user
          ? this.trainingService.getUserTrainingList(
              currentPage,
              this.companyId
            )
          : this.trainingService.hrManagerTrainingList(
              currentPage,
              this.companyId,
              this.IsActive
            );
      Data.then(
        (res: any) => {
          if (res["Success"]) {
            this.userTrainingList = res.Data.results;
            this.totalItems = res.Data.totalNumberOfRecords;
          } else {
            this.userTrainingList = [];
            this.totalItems = 0;
          }
        },
        (err) => {
          this.userTrainingList = [];
        }
      );
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList =
          this.role === this.roleEnum.user
            ? this.columns
            : this.hrManagercolumns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clear();
      }
    }
  }

  filterData() {
    const filterList =
      this.role === this.roleEnum.user
        ? this.trainingService.userTrainingDataFilter(
            this.searchSort,
            this.companyId
          )
        : this.trainingService.hrManagerFilterData(
            this.searchSort,
            this.companyId,
            this.IsActive
          );
    filterList.then(
      (res) => {
        if (res["data"] && res["data"].length > 0) {
          this.userTrainingList = res["data"];
          this.totalItems = res["recordsFiltered"];
        } else {
          this.userTrainingList = [];
          this.totalItems = 0;
        }
      },
      (err) => {
        this.userTrainingList = [];
        this.totalItems = 0;
      }
    );
  }

  clear() {
    this.searchSort.Search.Value = "";
    this.searchSort.Order[0].ColumnName = "";
    this.searchSort.Order[0].Dir = "asc";
    this.filterTextValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }
  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }
  downloadFile() {
    const downloadMethod =
      this.role === this.roleEnum.user
        ? this.trainingService.downloadUserExcelData(
            this.searchSort,
            this.companyId
          )
        : this.trainingService.downloadHrManagerData(
            this.searchSort,
            this.companyId,
            this.IsActive
          );
    downloadMethod.then(
      (response) => {
        if (response["Success"]) {
          const a = document.createElement("a");
          a.setAttribute("style", "display:none;");
          document.body.appendChild(a);
          a.href = environment.BLOB_URL + response["Message"];
          a.download = response["Message"].split("/")[1];
          a.click();
        } else {
          this.toaster.error(response["Message"]);
        }
      },
      (err) => {}
    );
  }
  displayActive(event) {
    if (event.target.checked) {
      this.IsActive = true;
      this.getUserData(this.page.pageNumber + 1);
    } else if (!event.target.checked) {
      this.IsActive = false;
      this.getUserData(this.page.pageNumber + 1);
    }
  }
}
