import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import {
  bsConfig,
  ComplianceEnum,
  Constants,
  ViewEnum,
} from "src/app/constant/constants";
import { DepartmentService } from "src/app/service/department.service";
import { ToastrService } from "ngx-toastr";
import { ShiftService } from "src/app/service/shift.service";
import { commonHelper } from "src/app/shared/Helpers/commonHelper";
import * as _ from "lodash";
import { TimePunchesService } from "src/app/service/time-punches.service";
import { DatePipe } from "@angular/common";
import { environment } from "src/environments/environment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Offer } from "src/app/models/offer.model";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { forkJoin } from "rxjs";
import { UtilityService } from "src/app/service/utility.service";

@Component({
  selector: "app-time-punches-info",
  templateUrl: "./time-punches-info.component.html",
  styleUrls: ["./time-punches-info.component.scss"],
})
export class TimePunchesInfoComponent implements OnInit {
  @ViewChild("userNameValue", { static: false }) userNameValue;
  @ViewChild("filterTextValue", { static: false }) filterTextValue;
  @ViewChild("shiftValue", { static: false }) shiftValue;
  @ViewChild("departmentValue", { static: false }) departmentValue;
  @ViewChild("complianceValue", { static: false }) complianceValue;
  @ViewChild("punchDate", { static: false }) punchDate;
  @ViewChild("punchEndDate", { static: false }) punchEndDate;
  // @ViewChild("viewValue", { static: false }) viewValue;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild("inconsistRecordRecordPopUp", { static: false })
  inconsistRecordRecord: BsModalRef;
  @ViewChild("inconsistRecordRecordPopUp", { static: false })
  inconsistRecordRecordPopUp: TemplateRef<any>;
  @ViewChild("detailClockInClockOutPopUp", { static: false })
  detailClockInClockOutPopUp: BsModalRef;

  timePunchList = [];
  companyId: number;
  isConfirmed = false;
  modalRef: BsModalRef;
  public myOffer: Offer;
  totalHrPunchesItems: any;
  totalItems: any;
  rowsOnPage = Constants.HR_PAGE_ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  bsConfig: Partial<BsDatepickerConfig> = bsConfig;
  selected = [];
  columns = [];
  complianceList = ComplianceEnum;
  viewList = ViewEnum;
  shiftList = [];
  departmentList = [];
  commonShiftList = [];
  selectedRecords = [];
  filterValue = [];
  deleteId: string;
  page: any;
  searchSort: any;
  nextDate: any;
  searchDate: any;
  IsActive = false;
  locationDetails: any;
  // totalPayrollItems: any;
  longitude: string;
  ViewChangeEvent: boolean = false;
  latitude: string;
  isPayrollReport = false;
  isOnPromiseReport = false;
  isHrTimepunches = true;
  payRollList = [];
  payrollColumns = [];
  punchTime: any;
  isClockIn: boolean;
  isShowDetail = false;
  isShowDaily = false;
  startDate = new Date();
  endDate = new Date();
  initialStartDate = new Date();
  initialEndDate = new Date();
  weekStartDate = new Date();
  weekEndDate = new Date();
  userId = 0;
  popUpUserId = 0;
  viewType = 1;
  clockOutDate: any;
  isShowInconsistRecord = false;
  inconsistPunchList = [];
  isRunCheck:boolean = false;
  isInconsistentModal: boolean = false;
  clearedData:boolean;
  periodSummaryView: boolean;
  dailyView: boolean;
  detailView: boolean;
  // workHour:any;
  // this.tomorrowsDate.setDate(this.todaysDate.getDate() + 1);
  constructor(
    private timePunchesService: TimePunchesService,
    private datepipe: DatePipe,
    private shiftService: ShiftService,
    private departmentService: DepartmentService,
    private toaster: ToastrService,
    private modalService: BsModalService,
    private utilityService : UtilityService
  ) {
    this.columns = [
      "PunchTime",
      "Username",
      "ComplianceStr",
      "CreatedByObj.companyUsername",
      "CreatedByObj.shiftId",
      "CreatedByObj.departmentId",
      "AdjustedPunchTimeStr",
      "PunchTypeStr",
      "PunchLocationStr",
      "SourceStr",
    ];
    this.payrollColumns = [
      "PunchTime",
      "Username",
      "ClockInDate",
      "ClockOutDate",
      "TotalWorkHours",
      "RegularWorkHours",
      "PaidTimeOffHours",
      "WeekNumber",
      "OverTimeWorkHours",
      "TotalWorkHours",
      "HolidayHours",
      "BonusPay"
    ];

    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  ngOnInit() {
    this.isRunCheck = false;
    this.periodSummaryView = true;
    var curr = new Date();
    var first = curr.getDate() - 1;
    this.endDate = new Date(curr.setDate(first));
    this.startDate.setDate(new Date().getDate() - 14);
    this.initialStartDate = this.startDate;
    this.initialEndDate = this.endDate;
    this.weekStartDate.setDate(this.endDate.getDate() - 14);
    this.bsConfig = Object.assign(
      {},
      {
        dateInputFormat: "MM/DD/YYYY",
      }
    );
    this.utilityService.showLoading();
    forkJoin([
      this.departmentService.getDepartmentListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID)),
        false,
        false
      ),
      this.shiftService.getShiftListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID)),
        false,
        false
      ),
    ]).subscribe((res: any) => {
      if (res[0]["Success"]) {
        this.departmentList = res["Data"];
      } else {
        this.departmentList = [];
      }
      if (res[1]["Success"]) {
        this.shiftList = res[1]["Data"];
        this.commonShiftList = _.merge([], this.shiftList);
      } else {
        this.shiftList = [];
        this.commonShiftList = [];
      }
    });
    this.setPage({ offset: 0 });
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
  }
  setPage(pageInfo) {
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
    if (this.isPayrollReport) {
      this.getPayrollTimePunchList(this.page.pageNumber + 1);
    }
    if (this.isHrTimepunches) {
      this.getHrTimePunchList(this.page.pageNumber + 1);
    }
  }
  displayHrTimePunches() {
    this.isRunCheck = false;
    this.isHrTimepunches = true;
    this.isPayrollReport = false;
    this.isOnPromiseReport = false;
    setTimeout(() => {
      this.filterValue.push("");
      this.filterValue.push(this.userNameValue.nativeElement.value);
      this.filterValue.push(this.departmentValue.nativeElement.value);
      this.filterValue.push(this.shiftValue.nativeElement.value);
      this.filterValue.push(this.punchDate.nativeElement.value);
      this.filterValue.push(this.punchEndDate.nativeElement.value);
      this.filterValue.push(this.complianceValue.nativeElement.value);
      this.searchSort.Columns = [];
      this.columns.forEach((element, i) => {
        if (i < 8) {
          const obj = {
            Data: "string",
            Searchable: true,
            Orderable: true,
            Name: element,
            Search: {
              Value: this.filterValue[i],
              Regex: "string",
              isSearchBetweenDates: i === 0 ? true : false,
              endDate: null,
            },
          };
          this.searchSort.Columns.push(obj);
        }
      });
      this.setPage({ offset: 0 });
    }, 500);
  }

  ngAfterViewInit() {
    this.filterValue.push("");
    this.filterValue.push(this.userNameValue.nativeElement.value);
    this.filterValue.push(this.departmentValue.nativeElement.value);
    this.filterValue.push(this.shiftValue.nativeElement.value);
    this.filterValue.push(this.punchDate.nativeElement.value);
    this.filterValue.push(this.punchEndDate.nativeElement.value);
    this.filterValue.push(this.complianceValue.nativeElement.value);
    this.columns.forEach((element, i) => {
      if (i <= 8) {
        const obj = {
          Data: "string",
          Searchable: true,
          Orderable: true,
          Name: element,
          Search: {
            Value: this.filterValue[i],
            Regex: "string",
            isSearchBetweenDates: i === 0 ? true : false,
            endDate: null,
          },
        };
        this.searchSort.Columns.push(obj);
      }
    });
  }
  getHrTimePunchList(currentPage) {
    if (
      (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) ||
      (!!this.filterTextValue && !!this.userNameValue.nativeElement.value) ||
      (!!this.filterTextValue && !!this.departmentValue.nativeElement.value) ||
      (!!this.filterTextValue && !!this.shiftValue.nativeElement.value) ||
      (!!this.filterTextValue && !!this.complianceValue.nativeElement.value) ||
      (!!this.punchDate && !!this.punchDate.nativeElement.value) ||
      (!!this.punchDate && !!this.punchEndDate.nativeElement.value)
    ) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod = this.timePunchesService.hrManagerTimePunchesList(
        currentPage,
        this.rowsOnPage,
        this.IsActive
      );
      getMethod.then(
        (res: any) => {
          if (res["Success"]) {
            this.timePunchList = res.Data.results;
            this.totalHrPunchesItems =
              res.Data.totalNumberOfRecords === 0
                ? undefined
                : res.Data.totalNumberOfRecords;
          } else {
            this.timePunchList = [];
            this.totalHrPunchesItems = undefined;
          }
        },
        (err) => {
          this.timePunchList = [];
        }
      );
    }
  }

  getShiftList() {
    this.shiftService
      .getShiftListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID))
      )
      .then(
        (response) => {
          this.shiftList = response["Data"];
          this.commonShiftList = _.merge([], this.shiftList);
        },
        (err) => {
          this.shiftList = [];
        }
      );
  }

  getDepartmentList() {
    this.departmentService
      .getDepartmentListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID))
      )
      .then(
        (res) => {
          if (res["Success"]) {
            this.departmentList = res["Data"];
          } else {
            this.departmentList = [];
          }
        },
        (err) => {
          this.departmentList = [];
        }
      );
  }
  setnewDate(date) {
    const dateObj = {
      year: +this.datepipe.transform(date, "yyyy"),
      month: +this.datepipe.transform(date, "MM"),
      day: +this.datepipe.transform(date, "dd"),
    };
    return new Date(dateObj["year"], dateObj["month"] - 1, dateObj["day"]);
  }
  startWorkDateChange(event) {
    this.searchDate = null;
    const findDateColumn = this.searchSort.Columns.find(
      (x) => x.Name === "PunchTime"
    );
    if (!!findDateColumn) {
      findDateColumn.Search.Value = this.datepipe.transform(
        this.setnewDate(event),
        "MM/dd/yyyy"
      );
      this.searchDate = this.datepipe.transform(
        this.setnewDate(event),
        "yyyy-MM-dd"
      );
    }
  }
  endWorkDateChange(event) {
    this.searchDate = null;
    const findDateColumn = this.searchSort.Columns.find(
      (x) => x.Name === "PunchTime"
    );
    if (!!findDateColumn) {
      findDateColumn.Search.endDate = this.datepipe.transform(
        this.setnewDate(event),
        "MM/dd/yyyy"
      );
      this.searchDate = this.datepipe.transform(
        this.setnewDate(event),
        "yyyy-MM-dd"
      );
    }
  }
  startPayWorkDateChange(event) {
    if (!!event && !!this.endDate && event > this.endDate) {
      setTimeout(() => {
        this.toaster.error("Start date can not be greater than end date");
        this.startDate = this.initialStartDate;
      }, 10);
    }
    if(!!event){
      this.weekStartDate = event.toLocaleString();
    }
  }
  endWorkPayDateChange(event) {
    if (!!event && !!this.startDate && event < this.startDate) {
      setTimeout(() => {
        this.endDate = this.initialEndDate;
        this.toaster.error("End date can not be less than start date");
      }, 10);
    }
    if(!!event){
      this.weekEndDate = event.toLocaleString();
    }
  }
  complianceChange(event) {
    const findComplianceColumn = this.searchSort.Columns.find(
      (x) => x.Name === "ComplianceStr"
    );
    if (!!findComplianceColumn) {
      if (!!event.currentTarget.value) {
        findComplianceColumn.Search.Value = this.complianceList
          .find((x) => x.id == event.currentTarget.value)
          .value.toLowerCase();
      } else {
        findComplianceColumn.Search.Value = "";
      }
    }
  }
  shiftChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(
      (x) => x.Name === "CreatedByObj.shiftId"
    );
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = event.currentTarget.value;
    }
  }
  userNameChange(event) {
    if (event.target.value == "") {
      this.clear();
    } else {
      this.searchSort.Search.ColumnNameList = this.columns;
      const findUserNameColumn = this.searchSort.Columns.find(
        (x) => x.Name === "CreatedByObj.companyUsername"
      );
      if (!!findUserNameColumn) {
        findUserNameColumn.Search.Value =
          event.currentTarget.value.toLowerCase();
      }

      this.filterData();
    }
  }


  payrollUserNameChange(event) {
    if (event.target.value == "") {
      this.clear();
    } else {
      const findUserNameColumn = this.searchSort.Columns.find(
        (x) => x.Name === "Username"
      );
      if (!!findUserNameColumn) {
        findUserNameColumn.Search.Value =
          event.currentTarget.value.toLowerCase();
      }
      this.filterPayrollData();
    }
  }

  departmentChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(
      (x) => x.Name === "CreatedByObj.departmentId"
    );
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = "";
    }
    if (event.currentTarget.value)
      this.shiftList = commonHelper.filterShiftListFromDepartmentId(
        event.currentTarget.value,
        this.commonShiftList
      );
    else this.shiftList = this.commonShiftList;
    const findDepartmentColumn = this.searchSort.Columns.find(
      (x) => x.Name === "CreatedByObj.departmentId"
    );
    if (!!findDepartmentColumn) {
      findDepartmentColumn.Search.Value = event.currentTarget.value;
    }
  }
  viewChange(event) {
    this.ViewChangeEvent = true;
    this.viewType = event.currentTarget.value;
    if (this.viewType == 1) {
      this.periodSummaryView = true;
      this.dailyView = false;
      this.detailView = false;
      this.payRollList = [];
      this.clear();
      this.isRunCheck = false;
      this.inconsistPunchList = [];
      this.isShowDaily = false;
      this.isShowDetail = false;
      this.totalItems = undefined;
      this.userNameValue.nativeElement.value = ""
    } else {
    }
    if (this.viewType == 2) {
      this.periodSummaryView = false;
      this.dailyView = true;
      this.detailView = false;
      this.isShowDaily = true;
      this.isInconsistentModal = false;
      this.filterTextValue.nativeElement.value = "";
      this.searchSort.Search.Value = "";
      this.userNameValue.nativeElement.value = ""
    } else {
      this.isShowDaily = false;
    }
    if (this.viewType == 4) {
      this.periodSummaryView = false;
      this.dailyView = false;
      this.detailView = true;
      this.isShowDetail = true;
      this.isInconsistentModal = false;
      this.filterTextValue.nativeElement.value = "";
      this.searchSort.Search.Value = "";
      this.userNameValue.nativeElement.value = ""
    } else {
      this.isShowDetail = false;
    }
    this.timePunchesService.payRollTimePunchesDataFilter(
      this.startDate.toLocaleString(),
      this.endDate.toLocaleString(),
      this.userId,
      this.viewType,
      this.searchSort
    ).then((res) => {
      if (res["Data"].data && res["Data"].data.length > 0 && !this.isInconsistentModal) {
        if(this.viewType == 1){
          this.payRollList = [];
          this.totalItems = undefined;
        }
        else{
          this.payRollList = res["Data"].data;
          this.totalItems = res["Data"].data.length;
        }
      } else {
        this.totalItems = 0;
      }
    },
    (err) => {
      this.payRollList = [];
      this.totalItems = 0;
    })
  }
  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    if (this.isHrTimepunches) {
      this.filterData();
    } else if (this.isPayrollReport) {
      this.filterPayrollData();
    }
  }

  filterData() {
    if (!!this.userNameValue.nativeElement.value) {
      const findUserNameColumn = this.searchSort.Columns.find(
        (x) => x.Name === "CreatedByObj.companyUsername"
      );
      if (!!findUserNameColumn) {
        findUserNameColumn.Search.Value =
          this.userNameValue.nativeElement.value.toLowerCase();
      }
    }
    const filterMethod = this.timePunchesService.hrTimePunchesDataFilter(
      this.searchSort,
      this.companyId,
      this.IsActive
    );
    filterMethod.then(
      (res) => {
        if (res["data"] && res["data"].length > 0) {
          this.timePunchList = res["data"];
          this.totalHrPunchesItems = res["recordsFiltered"];
        } else {
          this.totalHrPunchesItems = 0;
        }
      },
      (err) => {
        this.timePunchList = [];
        this.totalHrPunchesItems = 0;
      }
    );
  }
  updateFilter(event) {
    if (event.key !== "Tab") {
      if(event.target.value != ""){
        this.searchSort.Search.Value = event.target.value;
        if (this.isPayrollReport) {
          this.searchSort.Search.ColumnNameList = this.payrollColumns;
          this.searchSort.Page = this.page.pageNumber + 1;
          this.filterPayrollData();
        }
      }
      else{
        if (!!event.target.value) {
          this.searchSort.Search.Value = event.target.value;
          if (this.isPayrollReport) {
            this.searchSort.Search.ColumnNameList = this.payrollColumns;
            this.searchSort.Page = this.page.pageNumber + 1;
            this.filterPayrollData();
          } else {
            this.searchSort.Search.ColumnNameList = this.columns;
            this.searchSort.Page = this.page.pageNumber + 1;
            this.filterData();
          }
        } else {
          this.clearText();
        }
      }
    }
  }
  clearText() {
    this.searchSort.Search.Value = "";
    this.filterTextValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }
  clear() {
    if (this.isPayrollReport) {
      this.ViewChangeEvent = false;
      this.searchSort.Search.Value = "";
      this.searchSort.Order[0].ColumnName = "";
      this.searchSort.Order[0].Dir = "asc";
      this.filterTextValue.nativeElement.value = "";
      this.searchSort.Columns.forEach((element) => {
        element.Search.Value = "";
      });
      this.clearedData = true;
      this.userNameValue.nativeElement.value = "";
      this.startDate = this.initialStartDate;
      this.endDate = this.initialEndDate;
      this.setPage({ offset: 0 });
    } else {
      this.searchSort.Search.Value = "";
      this.searchSort.Order[0].ColumnName = "";
      this.searchSort.Order[0].Dir = "asc";
      this.filterTextValue.nativeElement.value = "";
      this.searchSort.Columns.forEach((element) => {
        element.Search.Value = "";
      });

      this.userNameValue.nativeElement.value = "";
      this.departmentValue.nativeElement.value = "";
      this.shiftValue.nativeElement.value = "";
      this.complianceValue.nativeElement.value = "";
      this.punchDate.nativeElement.value = "";
      this.punchEndDate.nativeElement.value = "";
      this.setPage({ offset: 0 });
    }
  }

  onClickDelete(data) {
    this.deleteId = data.TimePunchesId;
    this.isConfirmed = true;
  }

  delete(id) {
    this.isConfirmed = false;
    this.timePunchesService.deleteTimePunchesById(id).then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(Constants.TIME_PUNCHES_DELETE_SUCCESS_MSG);
          this.setPage({ offset: 0 });
        } else {
          this.toaster.error(res["Message"]);
        }
      },
      (err) => {}
    );
  }
  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.myOffer = data;
    } else {
      this.myOffer = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }

  openMapPopUpModel(template: TemplateRef<any>, data) {
    if (data.geoLocation) {
      const splitGeoLocation = data.geoLocation.split("|");
      if (splitGeoLocation.length > 1) {
        this.locationDetails = {
          latitude: splitGeoLocation[0],
          longitude: splitGeoLocation[1],
          locationName: data.location,
        };
      }

      this.myOffer = data;
    } else {
      this.myOffer = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }
  closeMap() {
    this.modalRef.hide();
  }
  openAddModal(template: TemplateRef<any>, data: any) {
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-md",
      backdrop: "static",
    });
  }
  closeModal(event) {
    if (event === "Save" || event === "SaveAndClose") {
      this.table.offset = 0;
      this.setPage({ offset: 0 });
    }
    if (event === "Save" || event === "Close") {
      this.modalRef.hide();
      this.setPage({ offset: 0 });
    }
  }
  close(event) {
    this.isConfirmed = false;
  }

  closeClockInOutModel() {
    this.modalRef.hide();
  }

  downloadFile() {
    const downloadMethod = this.timePunchesService.downloadUserExcelData(
      this.searchSort,
      this.IsActive,
      this.companyId
    );
    downloadMethod.then(
      (response) => {
        if (response["Success"]) {
          const a = document.createElement("a");
          a.setAttribute("style", "display:none;");
          document.body.appendChild(a);
          a.href = environment.BLOB_URL + response["Message"];
          a.download = response["Message"].split("/")[1];
          a.click();
        } else {
          this.toaster.error(response["Message"]);
        }
      },
      (err) => {}
    );
  }

  displayActive(event) {
    this.isShowDaily = false;
    this.isShowDetail = false;
    if (event.target.checked) {
      this.IsActive = true;
      this.getHrTimePunchList(this.page.pageNumber + 1);
    } else if (!event.target.checked) {
      this.IsActive = false;
      this.getHrTimePunchList(this.page.pageNumber + 1);
    }
  }

  displayPayrollReport() {
    this.payRollList = [];
    this.isPayrollReport = true;
    this.isHrTimepunches = false;
    this.isInconsistentModal = false;
    if(this.isRunCheck == true){
      const getMethod = this.timePunchesService.getInconsistTimePunchesData(
        this.companyId,
        this.weekStartDate.toLocaleString(),
        this.weekEndDate.toLocaleString()
      );
      getMethod.then(
        (res: any) => {
          if (res["Success"]) {
            this.inconsistPunchList = res.Data;
            this.totalItems = res.Data.length === 0 ? undefined : res.Data.length;
            if (this.totalItems > 0) {
              this.isShowInconsistRecord = true;
            } else {
              this.isShowInconsistRecord = false;
            }
          } else {
            this.inconsistPunchList = [];
            this.totalItems = undefined;
          }
          if (this.isShowInconsistRecord && this.viewType == 1) {
            this.inconsistPunchList = [];
            this.payRollList = [];
            this.openInconsistTimePunchModel(this.weekStartDate, this.weekEndDate);
            this.isInconsistentModal = true;
            this.totalItems = [];
            this.clearedData = true;
          } else {
            this.isInconsistentModal = false;
            this.isHrTimepunches = false;
            this.isPayrollReport = true;
            this.isShowDaily = false;
            this.isShowDetail = false;
            this.isShowInconsistRecord = false;
            this.filterValue.push("");
            this.filterValue.push(this.userNameValue.nativeElement.value);
            this.filterValue.push(
              !this.punchDate.nativeElement.value
                ? this.startDate
                : this.punchDate.nativeElement.value
            );
            this.filterValue.push(
              !this.punchEndDate.nativeElement.value
                ? this.endDate
                : this.punchEndDate.nativeElement.value
            );
            this.searchSort.Columns = [];
            this.payrollColumns.forEach((element, i) => {
              if (i < 8) {
                const obj = {
                  Data: "string",
                  Searchable: true,
                  Orderable: true,
                  Name: element,
                  Search: {
                    Value: this.filterValue[i],
                    Regex: "string",
                    isSearchBetweenDates: i === 0 ? true : false,
                    endDate: null,
                  },
                };
                this.searchSort.Columns.push(obj);
              }
            });
            let UserNameInputValue = this.userNameValue.nativeElement.value;
            let FilterListInputValue = this.filterTextValue.nativeElement.value;
            this.setPage({ offset: 0 });
            this.filterTextValue.nativeElement.value = FilterListInputValue;
            this.userNameValue.nativeElement.value = UserNameInputValue
          }
        },
        (err) => {
          this.inconsistPunchList = [];
        }
      );
    }
    
  }
  openInconsistTimePunchModel(
    data: any,
    endData:any,
    template: TemplateRef<any> = this.inconsistRecordRecordPopUp
  ) {
    if (!!data) {
      this.startDate = data;
      // this.clockOutDate = data.ClockOutDate;
    } else {
      this.startDate = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }
  closeInconsistDataModel() {
    this.isShowInconsistRecord = false;
    this.modalRef.hide();
  }
  async getPayrollTimePunchList(currentPage) {
    this.userNameValue.nativeElement.value = "";
    this.filterTextValue.nativeElement.value = "";
    if (
      (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) ||
      (!!this.filterTextValue && !!this.userNameValue.nativeElement.value)
    ) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.payrollColumns;
      this.searchSort.Page = currentPage;
      // this.startPayWorkDateChange(this.endDate);
      // this.endWorkPayDateChange(this.startDate);
      this.filterPayrollData();
    } else {
      if(this.viewType == 1 && this.isInconsistentModal == true){
        this.payRollList = [];
        return;
      }
      else{
        const getMethod = this.timePunchesService.getPayrollDataByPeriod(
          this.startDate.toLocaleString(),
          this.endDate.toLocaleString(),
          this.userId,
          this.viewType
        );
        await getMethod.then((res: any) => {
            if (res["Success"]) {
              if((this.clearedData == undefined || this.clearedData == true) && this.isRunCheck == true && this.ViewChangeEvent == false){
                this.payRollList = res.Data;
                this.clearedData = false;
              }
              this.totalItems = this.payRollList.length
            } else {
              this.payRollList = [];
              this.totalItems = undefined;
            }
          },
          (err) => {
            this.payRollList = [];
          }
        );
      }
    }
  }
  downloadWorkHoursReport() {
    const downloadMethod =
      this.timePunchesService.downloadPayrollReportExcelData(
        this.startDate.toLocaleString(),
        this.endDate.toLocaleString(),
        this.userId,
        this.viewType,
        this.searchSort
      );
    downloadMethod.then(
      (response) => {
        if (response["Success"]) {
          const a = document.createElement("a");
          a.setAttribute("style", "display:none;");
          document.body.appendChild(a);
          a.href = environment.BLOB_URL + response["Message"];
          a.download = response["Message"].split("/")[1];
          a.click();
        } else {
          this.toaster.error(response["Message"]);
        }
      },
      (err) => {}
    );
  }
  filterPayrollData() {
    this.isRunCheck = true;
    if (!!this.userNameValue.nativeElement.value) {
      const findUserNameColumn = this.searchSort.Columns.find(
        (x) => x.Name === "Username"
      );
      if (!!findUserNameColumn) {
        findUserNameColumn.Search.Value =
          this.userNameValue.nativeElement.value.toLowerCase();
      }
    }
    if(this.isInconsistentModal == false){
      const filterMethod = this.timePunchesService.payRollTimePunchesDataFilter(
        this.startDate.toLocaleString(),
        this.endDate.toLocaleString(),
        this.userId,
        this.viewType,
        this.searchSort
      );
      filterMethod.then(
        (res) => {
          if (res["Data"].data && res["Data"].data.length > 0 && !this.isInconsistentModal) {
            this.payRollList = res["Data"].data;
            this.totalItems = res["Data"].data.length;
          } else {
            this.totalItems = 0;
          }
        },
        (err) => {
          this.payRollList = [];
          this.totalItems = 0;
        }
      );
    }
    this.displayPayrollReport();
  }
  openClockInClockOutPopUpModel(template: TemplateRef<any>, data) {
    if (data) {
      this.popUpUserId = data.UserId;
      this.clockOutDate = data.ClockOutDate;
    } else {
      this.popUpUserId = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }

  displayOnPromiseReport() {
    this.isHrTimepunches = false;
    this.isOnPromiseReport = true;
  }
}
