import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Role } from '../models/role-model';
import { Constants, pageSizing } from '../constant/constants';
import { UserService } from '../service/user.service';
import { OfferService } from '../service/offer.service';
import { TimePunchesService } from "src/app/service/time-punches.service";


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  public isHiddenColumn: boolean = true
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  user: any;
  userList = [];
  modalRef: BsModalRef;
  totalItems: any;
  rowsOnPage = Constants.HR_PAGE_ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  isConfirmed: boolean = false;
  deleteId: string;
  public roleEnum = Role;
  role: number;
  companyId: number;
  delText = '';
  searchSort: any;
  columns = [];
  isShowLink = false;
  pageSizing = pageSizing;
  isShowDetail = false;
  isShowDaily = false;
  IsActive = false;
  timePunchList = [];
  totalHrPunchesItems: any;
  isShowClockInOut: false;

  constructor(private modalService: BsModalService,
    private toaster: ToastrService,
    private userSerive: UserService,
    private offerService: OfferService,
    private timePunchesService: TimePunchesService) {
    this.columns = ['companyUsername', 'company.companyName', 'department.departmentName', 'location.locationName', 'shift.title', 'emailId', 'roleValue', 'hireDate'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = this.role === Role.superUser ? 0 : Number(localStorage.getItem(Constants.COMPANYID));
    this.isShowClockInOut = JSON.parse(localStorage.getItem(Constants.IS_CLOCKINOUT));
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getUsersList(this.page.pageNumber + 1);
  }

  getUsersList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.isShowLink = true;
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod = this.role === Role.superUser ? this.userSerive.getUserList(currentPage) :
        this.userSerive.getUserListByCompanyId(currentPage, this.companyId, this.IsActive, this.page.size)
      getMethod.then((res: any) => {
        if (res['Success']) {
          this.userList = res.Data.results;

          this.userList.forEach(x => {
            x.roleId >= this.role ? x['isShow'] = true : x['isShow'] = false;
            let role;
            let roleText = "";
            if (x.Roles.length > 1) {
              x.Roles.forEach(element => {
                roleText += Role[element] + ", ";
              });
              roleText = roleText.substring(0, roleText.length - 2);
              x['roleValue'] = roleText;
            }
            else {
              role = JSON.parse(x.roleId);
              roleText = Role[role];
              x['roleValue'] = roleText;
            }
          });
          this.totalItems = res.Data.totalNumberOfRecords;
        } else { this.userList = []; this.totalItems = 0; }
      }, err => { this.userList = []; });
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.isShowLink = true;
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.isShowLink = false;
        this.clear();
      }
    }
  }

  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.user = data;
    } else {
      this.user = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }

  onClickDelete(data) {
    this.offerService.hasPendingRequest(data.userId).then(res => {
      if (res) {
        if (res['Success']) {
          this.delText = `This account have pending offers, Are you sure you want to delete ${data.companyUsername}?`;
        } else {
          this.delText = Constants.DELETE_MSG;
        }
        this.deleteId = data.userId;
        this.isConfirmed = true;
      }
    }, err => { });
  }

  onClickUnregisterDevice(data) {
    this.offerService.hasUnregisterDevice(data.userId).then(res => {
      if (res) {
        if (res['Success']) {
          this.setPage({ offset: 0 });
        } else {
          this.toaster.error(res['Message']);
        }
      }
    }, err => { });
  }

  delete(id) {
    this.isConfirmed = false;
    this.userSerive.deleteUser(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.USER_DELETE_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { });
  }

  close(event) {
    this.isConfirmed = false;
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  clear() {
    this.isShowLink = false;
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  filterData() {
    const getMethod = this.role === Role.superUser ? this.userSerive.allDataFilter(this.searchSort) : this.userSerive.dataFilter(this.searchSort, this.IsActive)
    getMethod.then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.userList = res['data'];
        this.userList.forEach(x => {
          x.roleId >= this.role ? x['isShow'] = true : x['isShow'] = false;
          let role;
          let roleText = "";
          if (x.Roles.length > 1) {
            x.Roles.forEach(element => {
              roleText += Role[element] + ", ";
            });
            roleText = roleText.substring(0, roleText.length - 2);
            x['roleValue'] = roleText;
          }
          else {
            role = JSON.parse(x.roleId);
            roleText = Role[role];
            x['roleValue'] = roleText;
          }
        });
        this.totalItems = res['recordsFiltered'];
      } else { this.userList = []; this.totalItems = 0; }
    }, err => { this.userList = []; this.totalItems = 0; });
  }

  changePageSize(data) {
    this.page.PageSize = Number(data.target.value);
  }

  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }

  displayActive(event) {
    this.isShowDaily = false;
    this.isShowDetail = false;
    if (event.target.checked) {
      this.IsActive = true;
      this.getUsersList(this.page.pageNumber + 1);
    } else if (!event.target.checked) {
      this.IsActive = false;
      this.getUsersList(this.page.pageNumber + 1);
    }
  }

}
