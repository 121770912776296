import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from '../constant/constants';


@Injectable()
export class CompanyService {

  constructor(private apiService: ApiService) { }

  getCompanyListWithOutPagination(isShowLoader = true) {
    return this.apiService.get(`${API.COMPANY_ROUTES.get}`, false, true, isShowLoader);
  }

  getUserCompanyList(uname) {
    return this.apiService.get(`${API.COMPANY_ROUTES.getCompany}?username=${uname}`, false, true);
  }

  getCompanyList(currentPage) {
    return this.apiService.get(
      `${API.COMPANY_ROUTES.get}?page=` +
      currentPage,
      false,
      true,
    );
  }

  addCompany(params) {
    return this.apiService.post(`${API.COMPANY_ROUTES.company}`, params, true);
  }
  updateCompany(params) {
    return this.apiService.put(`${API.COMPANY_ROUTES.company}`, params, true);
  }

  deleteCompany(Id) {
    return this.apiService.delete(
      `${API.COMPANY_ROUTES.deleteCompany}` + Id + `?deletedBy=` + localStorage.getItem(Constants.COMPANYID),
      true,
    );
  }

  dataFilter(data) { //dataFilter
    return this.apiService.post(`${API.COMPANY_ROUTES.dataFilter}`, data, true);
  }
}
