import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { UtilityService } from 'src/app/service/utility.service';
import { Constants, timePickingSystemList } from 'src/app/constant/constants';
import { UserService } from 'src/app/service/user.service';
import { LocationService } from 'src/app/service/location.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Output() close = new EventEmitter<boolean>();
  public files: NgxFileDropEntry[] = [];
  isUpload = false;
  fileExtension = Constants.FILE_EXTENSIONS;
  downloadFileName = '';
  downloadFilePath = '';
  constructor(private utility: UtilityService,
    private userService: UserService,
    private locationService: LocationService) { }

  ngOnInit() {
    this.getTimePickingSystem();
  }

  cancel() {
    this.close.emit(false);
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    const extension = this.files[0]['relativePath'].split('.').pop().toUpperCase();
    if (!['CSV', 'XLS', 'XLSX'].includes(extension)) {
      this.utility.showErrorToast('Invalid file!');
      return false;
    }
    this.isUpload = true;
  }

  upload() {
    const data: FormData = new FormData();
    const fileEntry = this.files[0].fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      data.append('file', file);
      this.userService.fileUpload(data).then(res => {
        if (res['Success']) {
          this.utility.showSuccessToast(Constants.FILE_UPLOAD_SUCCESS_MSG)
          this.close.emit(true);
        } else {
          this.utility.showErrorToast(res['Message']);
        }
      }, err => { });
    })
  }

  async getTimePickingSystem() {
    await this.locationService.getLocationListByCompany(Number(localStorage.getItem(Constants.COMPANYID)), null).then(res => {
      if (res['Success'] && res['Data'].length > 0) {
        this.downloadFileName = res['Data'][0].timePickingSystem === timePickingSystemList[0].id ?
          'KRONOS_SAMPLE_FILE.csv' : '';
        this.downloadFilePath = res['Data'][0].timePickingSystem === timePickingSystemList[0].id ?
          '/assets/attachment/sampleFileKRONOS.csv' : '';
      }
    }, err => { });
  }
}
