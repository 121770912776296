import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import * as moment from "moment";
import { BsDatepickerConfig, BsDatepickerViewMode } from "ngx-bootstrap/datepicker";
import { ToastrService } from "ngx-toastr";
import { bsConfig, Constants, SubscriptionType } from "src/app/constant/constants";
import { FormService } from "src/app/core/form.service";
import { Company } from "src/app/models/company.model";
import { CompanyService } from "src/app/service/company.service";
import { PlanService } from "./../../service/plan.service";

@Component({
  selector: "app-add-edit",
  templateUrl: "./add-edit.component.html",
  styleUrls: ["./add-edit.component.scss"],
})
export class AddEditComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig> = bsConfig;
  minMode: BsDatepickerViewMode = 'month';
  @Input() company: any;
  @Output() close = new EventEmitter<boolean>();
  companyForm: FormGroup;
  public messageList: any = new Company();
  locationList = [];
  subscriptionType = SubscriptionType;
  priceModules: any;
  paymentOpen = false;
  stateList = [];
  minDate = new Date();

  constructor(
    private toaster: ToastrService,
    private formService: FormService,
    private companyService: CompanyService,
    private build: FormBuilder,
    private planService: PlanService
  ) {}

  ngOnInit() {
    // this.getLocationList();
    this.getStateList();
    this.bsConfig = Object.assign({}, {
      minMode: this.minMode,
      dateInputFormat: 'MM-YYYY',
    });
    this.initializeCompanyForm();
    this.initializeMessages();
  }

  initializeCompanyForm() {
    this.companyForm = this.build.group({
      companyId: new FormControl(!!this.company ? this.company.companyId : 0),
      companyName: new FormControl(
        !!this.company ? this.company.companyName : "",
        Validators.required
      ),
      createdDate: new FormControl (!!this.company ? this.company.createdDate : ""),
      createdBy: new FormControl (!!this.company ? this.company.createdBy : null),
      //locationId: new FormControl(!!this.company ? this.company.locationId : null, Validators.required),
      pricingId: new FormControl(
        !!this.company ? this.company.pricing.pricingId : 0
      ),
      setUpCost: new FormControl(
        !!this.company ? this.company.pricing.setUpCost : 0,
        Validators.pattern(Constants.REGEX.DECIMAL_PATTRN)
      ),
      costForFirstUsers: new FormControl(
        !!this.company ? this.company.pricing.costForFirstUsers : 0,
        Validators.pattern(Constants.REGEX.DECIMAL_PATTRN)
      ),
      numberOfUsers: new FormControl(
        !!this.company ? this.company.pricing.numberOfUsers : 0,
        Validators.pattern(Constants.REGEX.INTEGER_PATTERN)
      ),
      costForRestUsers: new FormControl(
        !!this.company ? this.company.pricing.costForRestUsers : 0,
        Validators.pattern(Constants.REGEX.DECIMAL_PATTRN)
      ),
      isActive: new FormControl(true),
      priceForEnablingMsg: new FormControl(
        !!this.company ? this.company.pricing.priceForEnablingMsg : 0,
        Validators.pattern(Constants.REGEX.DECIMAL_PATTRN)
      ),
      PricingSubscription: this.build.array([]),
      isAutoRenew: new FormControl(
        !!this.company ? this.company.pricing.isAutoRenew : false
      ),
      cardHolderName: new FormControl(
        !!this.company ? this.company.pricing.cardHolderName : ""
      ),
      creditCardNumber: new FormControl(
        !!this.company ? this.company.pricing.creditCardNumber : 0
      ),
      expirationDate: new FormControl(
        !!this.company
          ? !!this.company.pricing.expirationDate
            ? new Date(this.company.pricing.expirationDate)
            : null
          : null
      ),
      securityCode: new FormControl(
        !!this.company ? this.company.pricing.securityCode : 0
      ),
      Address: new FormControl(
        !!this.company && !!this.company.pricing.BillingAddress
          ? this.company.pricing.BillingAddress.Address
          : ""
      ),
      City: new FormControl(
        !!this.company && !!this.company.pricing.BillingAddress
          ? this.company.pricing.BillingAddress.City
          : ""
      ),
      StateId: new FormControl(
        !!this.company && !!this.company.pricing.BillingAddress
          ? this.company.pricing.BillingAddress.StateId
          : ""
      ),
      Zip: new FormControl(
        !!this.company && !!this.company.pricing.BillingAddress
          ? this.company.pricing.BillingAddress.Zip
          : ""
      ),
      BillingAddressId: new FormControl(
        !!this.company && !!this.company.BillingAddress
          ? this.company.pricing.BillingAddress.BillingAddressId
          : ""
      ),
    });
    this.priceModules = this.companyForm.get(
      "PricingSubscription"
    ) as FormArray;

    for (let i = 0; i < 12; i++) {
      (this.companyForm.controls.PricingSubscription as FormArray).push(
        this.subscriptionControl(i)
      );
    }
  }

  subscriptionControl(index?: any): FormGroup {
    const group = this.build.group({
      pricingSubscriptionId: new FormControl(0),
      subscriptionType: new FormControl(0),
      pricingId: new FormControl(0),
      isSubscribe: new FormControl(false),
      numberOfUsers: new FormControl(
        0,
        Validators.pattern(Constants.REGEX.INTEGER_PATTERN)
      ),
      costForFirstUsers: new FormControl(
        0,
        Validators.pattern(Constants.REGEX.DECIMAL_PATTRN)
      ),
      costForRestUsers: new FormControl(
        0,
        Validators.pattern(Constants.REGEX.DECIMAL_PATTRN)
      ),
    });

    if (
      !!this.company &&
      !!this.company.pricing["pricingSubscription"][index]
    ) {
      group.patchValue(this.company.pricing["pricingSubscription"][index]);
    }
    return group;
  }

  getStateList() {
    this.planService.getState().then(
      (response) => {
        if (response["Success"]) {
          this.stateList = response["Data"];
        } else {
          this.stateList = [];
        }
      },
      (err) => {
        this.stateList = [];
      }
    );
  }

  initializeMessages() {
    this.messageList.companyName = {
      required: Constants.VALIDATION_MSG.COMPANY.COMPANY_NAME_REQUIRED,
    };
    // this.messageList.locationId = {
    //   required: Constants.VALIDATION_MSG.COMPANY.LOCATION_NAME_REQUIRED,
    // };
    this.messageList.setUpCost = {
      pattern: Constants.VALIDATION_MSG.COMPANY.ONLY_DIGIT_ALLOWED,
    };
    this.messageList.costForFirstUsers = {
      pattern: Constants.VALIDATION_MSG.COMPANY.ONLY_DIGIT_ALLOWED,
    };
    this.messageList.numberOfUsers = {
      pattern: Constants.VALIDATION_MSG.COMPANY.ONLY_DIGIT_ALLOWED,
    };
    this.messageList.costForRestUsers = {
      pattern: Constants.VALIDATION_MSG.COMPANY.ONLY_DIGIT_ALLOWED,
    };
    this.messageList.priceForEnablingMsg = {
      pattern: Constants.VALIDATION_MSG.COMPANY.ONLY_DIGIT_ALLOWED,
    };
    this.messageList.cardHolderName = {
      required: Constants.VALIDATION_MSG.PLAN.CARDHOLDERNAME,
      pattern: Constants.VALIDATION_MSG.PLAN.CHARACTER_ALLOW_MSG,
    };
    this.messageList.creditCardNumber = {
      required: Constants.VALIDATION_MSG.PLAN.CREDITCARDNUMBER,
      pattern: Constants.VALIDATION_MSG.COMPANY.ONLY_DIGIT_ALLOWED,
      minLength: Constants.VALIDATION_MSG.PLAN.CREDIT_CARD_MIN_LENGTH,
      maxLength: Constants.VALIDATION_MSG.PLAN.CREDIT_CARD_MAX_LENGTH,
    };
    this.messageList.expirationDate = {
      required: Constants.VALIDATION_MSG.PLAN.EXPIRATIONDATE,
    };
    this.messageList.securityCode = {
      required: Constants.VALIDATION_MSG.PLAN.SECURITYCODE,
      pattern: Constants.VALIDATION_MSG.COMPANY.ONLY_DIGIT_ALLOWED,
    };
    this.messageList.Address = {
      required: Constants.VALIDATION_MSG.PLAN.ADDRESS_REQUIRED,
    };
    this.messageList.City = {
      required: Constants.VALIDATION_MSG.PLAN.CITY_REQUIRED,
    };
    this.messageList.StateId = {
      required: Constants.VALIDATION_MSG.PLAN.STATE_REQUIRED,
    };
    this.messageList.Zip = {
      required: Constants.VALIDATION_MSG.PLAN.ZIP_REQUIRED,
      pattern: Constants.VALIDATION_MSG.PLAN.ZIP_INVALID,
    };
  }

  // getLocationList() {
  //   this.locationService.getLocationList(0).then(res => {
  //     if (res['Success']) {
  //       this.locationList = res['Data'].results;
  //     } else { this.locationList = []; }
  //   }, err => { this.locationList = []; })
  // }

  onSubmit() {
    this.formService.markFormGroupTouched(this.companyForm);
    if (this.companyForm.invalid) {
      return;
    }
    this.priceModules = this.companyForm.get(
      "PricingSubscription"
    ) as FormArray;
    this.priceModules.controls.map((element, i) => {
      element.controls.isSubscribe.setValue(element.controls.isSubscribe.value);
      element.controls.subscriptionType.setValue(i + 1);
      // element.controls.costForFirstUsers.setValue(parseFloat(element.controls.costForFirstUsers.value));
      // element.controls.costForRestUsers.setValue(parseFloat(element.controls.costForRestUsers.value));
      // element.controls.numberOfUsers.setValue(parseFloat(element.controls.numberOfUsers.value));

      !!element.controls.costForFirstUsers.value
        ? element.controls.costForFirstUsers.setValue(
            parseFloat(element.controls.costForFirstUsers.value)
          )
        : element.controls.costForFirstUsers.setValue(0);
      !!element.controls.costForRestUsers.value
        ? element.controls.costForRestUsers.setValue(
            parseFloat(element.controls.costForRestUsers.value)
          )
        : element.controls.costForRestUsers.setValue(0);
      !!element.controls.numberOfUsers.value
        ? element.controls.numberOfUsers.setValue(
            parseFloat(element.controls.numberOfUsers.value)
          )
        : element.controls.numberOfUsers.setValue(0);
    });
    const data = {
      companyId: this.companyForm.controls.companyId.value,
      companyName: this.companyForm.controls.companyName.value,
      locationId: 1,
      pricingId: this.companyForm.controls.pricingId.value,
      pricing: {
        pricingId: this.companyForm.controls.pricingId.value,
        setUpCost: !!this.companyForm.controls.setUpCost.value
          ? parseFloat(this.companyForm.controls.setUpCost.value)
          : 0,
        costForFirstUsers: !!this.companyForm.controls.costForFirstUsers.value
          ? parseFloat(this.companyForm.controls.costForFirstUsers.value)
          : 0,
        numberOfUsers: !!this.companyForm.controls.numberOfUsers.value
          ? parseFloat(this.companyForm.controls.numberOfUsers.value)
          : 0,
        costForRestUsers: !!this.companyForm.controls.costForRestUsers.value
          ? parseFloat(this.companyForm.controls.costForRestUsers.value)
          : 0,
        isActive: true,
        priceForEnablingMsg: !!this.companyForm.controls.priceForEnablingMsg
          .value
          ? parseFloat(this.companyForm.controls.priceForEnablingMsg.value)
          : 0,
        PricingSubscription: this.priceModules.value,
        CardHolderName: this.companyForm.controls.cardHolderName.value,
        CreditCardNumber: this.companyForm.controls.creditCardNumber.value,
        ExpirationDate: this.companyForm.controls.expirationDate.value,
        SecurityCode: this.companyForm.controls.securityCode.value,
        IsAutoRenew: this.companyForm.controls.isAutoRenew.value,
        BillingAddress: {
          BillingAddressId: Number(
            this.companyForm.controls.BillingAddressId.value
          ),
          Address: this.companyForm.controls.Address.value,
          City: this.companyForm.controls.City.value,
          StateId: !!this.companyForm.controls.StateId.value
            ? Number(this.companyForm.controls.StateId.value)
            : "",
          Zip: this.companyForm.controls.Zip.value,
        },
      },
      isActive: true,
    };
    const saveMethod =
      this.companyForm.controls.companyId.value > 0
        ? this.companyService.updateCompany(data)
        : this.companyService.addCompany(data);
    saveMethod.then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(
            this.companyForm.controls.companyId.value > 0
              ? Constants.COMPANY_UPDATE_SUCESS_MSG
              : Constants.COMPANY_ADD_SUCCESS_MSG
          );
          this.close.emit(true);
        } else {
          this.toaster.error(res["Message"]);
        }
      },
      (err) => {
        this.close.emit(false);
      }
    );
  }
  onChange(event) {
    this.paymentOpen = !event;
    if (this.paymentOpen) {
      this.companyForm.controls.cardHolderName.setValidators([
        Validators.required,
        Validators.pattern(Constants.REGEX.CHARACTER_PATTERN),
      ]);
      this.companyForm.controls.creditCardNumber.setValidators([
        Validators.required,
        Validators.pattern(Constants.REGEX.INTEGER_PATTERN),
        Validators.minLength(16),
        Validators.maxLength(16),
      ]);
      this.companyForm.controls.expirationDate.setValidators(
        Validators.required
      );
      this.companyForm.controls.securityCode.setValidators([
        Validators.required,
        Validators.pattern(Constants.REGEX.INTEGER_PATTERN),
        Validators.maxLength(10),
      ]);
      this.companyForm.controls.Address.setValidators(Validators.required);
      this.companyForm.controls.City.setValidators(Validators.required);
      this.companyForm.controls.StateId.setValidators(Validators.required);
      this.companyForm.controls.Zip.setValidators([
        Validators.required,
        Validators.pattern(Constants.REGEX.ZIP_PATTERN),
      ]);
    } else {
      this.companyForm.controls.cardHolderName.setValidators(null);
      this.companyForm.controls.creditCardNumber.setValidators(null);
      this.companyForm.controls.expirationDate.setValidators(null);
      this.companyForm.controls.securityCode.setValidators(null);
      this.companyForm.controls.Address.setValidators(null);
      this.companyForm.controls.City.setValidators(null);
      this.companyForm.controls.StateId.setValidators(null);
      this.companyForm.controls.Zip.setValidators(null);
    }
    this.companyForm.controls.cardHolderName.updateValueAndValidity();
    this.companyForm.controls.creditCardNumber.updateValueAndValidity();
    this.companyForm.controls.expirationDate.updateValueAndValidity();
    this.companyForm.controls.securityCode.updateValueAndValidity();
    this.companyForm.controls.Address.updateValueAndValidity();
    this.companyForm.controls.City.updateValueAndValidity();
    this.companyForm.controls.StateId.updateValueAndValidity();
    this.companyForm.controls.Zip.updateValueAndValidity();
  }
  control(controlName: string): AbstractControl {
    return this.companyForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }

  cancel() {
    this.close.emit(false);
  }
  getdisabled(i): boolean {
    return i === 0 ? true : false;
  }

  expiryDateChange(event) {
    if (!!event) {
      if (
        new Date().getMonth() + 1 >= moment(event).month() + 1 &&
        new Date().getFullYear() >= moment(event).year()
      ) {
        this.toaster.error(Constants.PLAN_EXIPRY_MSG);
      }
    }
  }
}
