import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormService } from 'src/app/core/form.service';
import { Contact } from 'src/app/models/contact.model';
import { Constants, phoneMask } from 'src/app/constant/constants';
import { ContactService } from 'src/app/service/contact.service';
import { UtilityService } from 'src/app/service/utility.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  public contactForm: FormGroup;
  public messageList: any = new Contact();
  phoneMask = phoneMask;
  constructor(private formService: FormService,
    private contactService: ContactService,
    private utilityService: UtilityService) { }

  ngOnInit() {
    this.contactForm = new FormGroup({
      company: new FormControl('',[Validators.required]),
      name: new FormControl('',[Validators.required]), 
      email: new FormControl('', [Validators.required,  Validators.pattern(Constants.REGEX.EMAIL_PATTERN)]),
      phoneNumber: new FormControl('',[Validators.required]),
      message: new FormControl('',[Validators.required]),
    });
    this.initializeMessages();
  }

  
  initializeMessages() {
    this.messageList.company = {
      required: Constants.VALIDATION_MSG.CONTACT_US.COMPANY_REQUIRED,
    };
    this.messageList.name = {
      required: Constants.VALIDATION_MSG.CONTACT_US.NAME_REQUIRED,
    };
    this.messageList.email = {
      required: Constants.VALIDATION_MSG.CONTACT_US.EMAIL_REQUIRED,
    };
    this.messageList.phoneNumber = {
      required: Constants.VALIDATION_MSG.CONTACT_US.PHONE_REQUIRED,
    };
    this.messageList.message = {
      required: Constants.VALIDATION_MSG.CONTACT_US.MESSAGE_REQUIRED,
    };
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.contactForm);
    if (this.contactForm.invalid) {
      return;
    }
  
    let contactData = {  
      Company: encodeURIComponent(this.contactForm.controls.company.value),  
      Email: this.contactForm.controls.email.value,  
      Phone: this.contactForm.controls.phoneNumber.value,  
      Name: this.contactForm.controls.name.value,
      Message: this.contactForm.controls.message.value 
    };  
    this.contactService.contact(contactData).then((res: any) => {
      if (res.Success && res.Success === true) {
        this.utilityService.showSuccessToast(res['Message']);
        this.contactForm.reset();
      } else { this.utilityService.showErrorToast(res['Message']); }
    }, err => {
      this.utilityService.showErrorToast(err.error.error_description);
    });

  }

}
