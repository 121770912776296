import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SettingsService } from "../service/settings.service";
import { Constants, SubscriptionType } from "../constant/constants";
import { Role } from "../models/role-model";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  modalRef: BsModalRef;
  setting: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  roleEnum = Role;
  public role: any;
  public settingsList: any = [];
  public totalItems: any;
  page: any;
  companyId: number;
  subscriptionType = SubscriptionType;
  searchSort: any;
  columns = [];
  isShowLink = false;
  constructor(
    private settingService: SettingsService,
    private toaster: ToastrService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.columns = ['SettingType', 'Description', 'OfferTypeStr'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId =
      this.role === this.roleEnum.superUser
        ? 0
        : Number(localStorage.getItem(Constants.COMPANYID));
    this.setPage({ offset: 0 });
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getSettingsList(this.page.pageNumber + 1);
  }

  getSettingsList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.isShowLink = true;
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod = this.settingService.getSettings(
        currentPage,
        this.companyId
      );
      getMethod.then(
        (res: any) => {
          if (res["Success"]) {
            this.settingsList = res.Data.results;
            this.totalItems = res.Data.totalNumberOfRecords;
            if (this.settingsList.length > 0) {
              this.settingsList.map((item) => {
                if (item.SettingType === "PaidTimeOff") {
                  item.Name = "Paid Time Off";
                } else if (item.SettingType === "FMLA") {
                  item.Name = item.SettingType;
                }
                let module = SubscriptionType.filter((result) => {
                  return result.id === item.OfferType;
                });
              });
            }
            console.log(this.settingsList);
          } else {
            this.settingsList = [];
          }
        },
        (err) => {
          this.settingsList = [];
        }
      );
    }
  }

  changeSetting(data: any) {
    var enable;
    if (data.Enable == true) {
      enable = false;
    } else {
      enable = true;
    }
    this.setPage({ offset: 0 });
    let params = {
      SettingType: data.SettingType,
      Enable: enable,
      CompanyId: this.companyId,
      OfferType: data.OfferType,
      Description: data.Description,
      VOTCutOffHours: data.VOTCutOffHours,
    };

    const addMethod = this.settingService.updateSettings(params);
    addMethod.then(
      (res: any) => {
        // this.toaster.success('Settings data updated succesfully');
        this.getSettingsList(Constants.CURRENT_PAGE);
        
      },
      (err) => { }
    );
  }

  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.setting = data;
    } else {
      this.setting = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }

  closeModal(event) {
    if(event){
      this.setPage({offset:this.page.pageNumber})
    }
    this.modalRef.hide();
  }
  clear() {
    this.isShowLink = false;
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }
  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.isShowLink = true;
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.isShowLink = false;
        this.clear();
      }
    }
  }
  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }
  filterData() {
    this.settingService.dataFilter(this.searchSort).then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.settingsList = res['data'];
        this.totalItems = res['recordsFiltered'];
      } else { this.settingsList = []; this.totalItems = 0; }
    }, err => { this.settingsList = []; this.totalItems = 0; });
  }

  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }

}
