import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { bsConfig, Constants } from 'src/app/constant/constants';
import { FormService } from 'src/app/core/form.service';
import { TrackFunctionTimeModel } from 'src/app/models/time-track.model';
import { TimePunchesService } from 'src/app/service/time-punches.service';
import { TimeTrackingService } from 'src/app/service/time-tracking.service';
import { UtilityService } from 'src/app/service/utility.service';

@Component({
  selector: 'app-edit-time-tracking',
  templateUrl: './edit-time-tracking.component.html',
  styleUrls: ['./edit-time-tracking.component.scss']
})
export class EditTimeTrackingComponent implements OnInit {
  @Input() myTimeTrack:TrackFunctionTimeModel;
  public timePunchesForm: FormGroup;
  @Output() closeRequest = new EventEmitter<string>();
  public messageList: any = new TrackFunctionTimeModel();
  AllAvaibaleFunctions=[];
  today = new Date();
  bsConfig: Partial<BsDatepickerConfig> = bsConfig;
  constructor(
    private modalService: BsModalService,
    private timePunchesService: TimePunchesService,
    private datepipe: DatePipe,
    private formService: FormService,
    private toaster: ToastrService,
    private utility: UtilityService,
    public timeTrackingSr:TimeTrackingService,
  ) { }

  ngOnInit() {
    console.log(this.myTimeTrack)
    this.initializeTimePunchesForm();
    this.GetAllFunctions();
  }
  async initializeTimePunchesForm() {
    this.timePunchesForm = new FormGroup({
      FunctionEndTime: new FormControl(
        !!this.myTimeTrack ? new Date(this.myTimeTrack.FunctionEndTime) : new Date(Date.now()),
        Validators.required
      ),
      EndTimeHoursMinutes: new FormControl(
        !!this.myTimeTrack ? this.myTimeTrack.FunctionEndTime : new Date(),
        Validators.required
      ),
      FunctionStartTime: new FormControl(
        !!this.myTimeTrack
          ? new Date(this.myTimeTrack.FunctionStartTime)
          : new Date(),
        Validators.required
      ),
      StartTimeHoursMinutes: new FormControl(
        !!this.myTimeTrack ? this.myTimeTrack.FunctionStartTime : new Date(),
        Validators.required
      ),
      FunctionId: new FormControl(
        !!this.myTimeTrack ? this.myTimeTrack.FunctionId : 0,
        Validators.required
      ),
      IsLocked: new FormControl(
        !!this.myTimeTrack ? this.myTimeTrack.IsLocked : false,
        Validators.required
      ),
   
      
      AutoCloseFlag: new FormControl(
        !!this.myTimeTrack ? this.myTimeTrack.AutoCloseFlag : false,
        Validators.required
      ),
    });
  }
  
  closePopUp() {
    this.closeRequest.emit("Close");
  }
  initializeMessages() {
    // this.messageList.PunchTime = {
    //   required: Constants.VALIDATION_MSG.PUNCHES.PUNCH_TIME,
    // };
    // this.messageList.AdjustedPunchTime = {
    //   required: Constants.VALIDATION_MSG.PUNCHES.ADJUST_PUNCH_TIME,
    // };
    // this.messageList.source = {
    //   required: Constants.VALIDATION_MSG.PUNCHES.SOURCE,
    // };
    // this.messageList.Compliance = {
    //   required: Constants.VALIDATION_MSG.PUNCHES.COMPLIANCE,
    // };
    // this.messageList.punchType = {
    //   required: Constants.VALIDATION_MSG.PUNCHES.PUNCH_TYPE,
    // };
    // this.messageList.punchLocation = {
    //   required: Constants.VALIDATION_MSG.PUNCHES.PUNCH_LOCATION,
    // };
  }
  GetAllFunctions(){
    this.timeTrackingSr.GetAllFunction(Number(localStorage.getItem(Constants.COMPANYID))).then((response:any)=>{
        this.AllAvaibaleFunctions = response.Data.results ? response.Data.results : response.Data;
    }).catch(error=>{

    })
  }
  OnChange(){
    var enable;
    if (this.timePunchesForm.get("IsLocked").value == true) {
      enable = false;
    } else {
      enable = true;
    }
    console.log(enable);
    this.timePunchesForm.get("IsLocked").setValue(enable);
  }
  OnChangeAutoClose(){
    var enable;
    if (this.timePunchesForm.get("AutoCloseFlag").value == true) {
      enable = false;
    } else {
      enable = true;
    }
    console.log(enable);
    this.timePunchesForm.get("AutoCloseFlag").setValue(enable);
  }
  save(){
    let punchTimeHoursMinutes =
    this.timePunchesForm.controls.StartTimeHoursMinutes.value;
    let mixedPunchDateTime = this.convertDateTime(
    this.timePunchesForm.controls.FunctionStartTime.value,
    new Date(punchTimeHoursMinutes)
    );
    let EndHoursMinutes =
    this.timePunchesForm.controls.EndTimeHoursMinutes.value;
    let mixedEndDateTime = this.convertDateTime(
    this.timePunchesForm.controls.FunctionEndTime.value,
    new Date(EndHoursMinutes)
    );
    this.timePunchesForm.get("FunctionStartTime").setValue(mixedPunchDateTime);
    this.timePunchesForm.get("FunctionEndTime").setValue(mixedEndDateTime);
    this.myTimeTrack.FunctionStartTime = this.timePunchesForm.get("FunctionStartTime").value;
    this.myTimeTrack.FunctionEndTime = this.timePunchesForm.get("FunctionEndTime").value;
    this.myTimeTrack.FunctionId = Number(this.timePunchesForm.get("FunctionId").value);
    this.myTimeTrack.IsLocked = this.timePunchesForm.get("IsLocked").value;
    this.myTimeTrack.AutoCloseFlag = this.timePunchesForm.get("AutoCloseFlag").value;
    console.log(this.myTimeTrack);
    let data : TrackFunctionTimeModel = {
      RecordId:this.myTimeTrack.RecordId,
      CompanyId : this.myTimeTrack.CompanyId,
      CreatedBy : this.myTimeTrack.CreatedBy,
      LocationId : this.myTimeTrack.LocationId,
      FunctionId : this.myTimeTrack.FunctionId,
      ShiftEndTime :this.myTimeTrack.ShiftEndTime,
      ShiftStartTime:this.myTimeTrack.ShiftStartTime,
      FunctionStartTime : this.myTimeTrack.FunctionStartTime,
      FunctionEndTime : this.myTimeTrack.FunctionEndTime,
      ModifiedBy : this.myTimeTrack.ModifiedBy,
      IsDeleted : this.myTimeTrack.IsDeleted,
      AutoCloseFlag : this.myTimeTrack.AutoCloseFlag,
      IsLocked : this.myTimeTrack.IsLocked,
    }
    console.log(data)
    this.timeTrackingSr.UpdateTrackFunctionTime(data,this.myTimeTrack.RecordId).then((response:any)=>{
      console.log(response);
      if(response.Success){
        this.toaster.success("List updated successfully!");
        this.closeRequest.emit("Save");
      }
    }).catch(error=>{
      console.log(error)
    })
  }
  convertDateTime(date1, date2) {
    console.log(date1, date2);
    let newDate = new Date(date1);
    return this.datepipe.transform(
      new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        date2.getHours(),
        date2.getMinutes(),
        0,
        0
      ),
      "yyyy-MM-dd HH:mm"
    );
  }

}
