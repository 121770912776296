import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InvoiceRoutingModule } from './invoice-routing.module';
import { InvoiceComponent } from './invoice.component';
import { SharedModule } from '../shared/shared/shared.module';
import { CompanyService } from '../service/company.service';
import { InvoicePageComponent } from './invoice-page/invoice-page.component';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { DirectiveModule } from '../shared/shared/directive/directive.module';
import { InvoiceService } from '../service/invoice.service';


@NgModule({
  declarations: [InvoiceComponent, InvoicePageComponent],
  imports: [
    CommonModule,
    InvoiceRoutingModule,
    SharedModule,
    NgxBootstrapIconsModule.pick(allIcons),
    DirectiveModule
  ],
  providers: [CompanyService, InvoiceService]
})
export class InvoiceModule { }
