import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserValidationRoutingModule } from './user-validation-routing.module';
import { AddEditComponent } from './add-edit/add-edit.component';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { DirectiveModule } from '../shared/shared/directive/directive.module';
import { SharedModule } from '../shared/shared/shared.module';
import { UserValidationComponent } from './user-validation.component';
import { FileUploadComponent } from '../user/file-upload/file-upload.component';
import { AuthService } from '../core/auth/auth.service';
import { LocationService } from '../service/location.service';



@NgModule({
  declarations: [UserValidationComponent,AddEditComponent,FileUploadComponent],
  imports: [
    CommonModule,
    UserValidationRoutingModule,
    NgxBootstrapIconsModule.pick(allIcons),
    SharedModule,
    DirectiveModule,
    UserValidationRoutingModule
  ],
  providers: [ LocationService, AuthService]
})
export class UserValidationModule { }
