import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from './../constant/constants';
@Injectable({
  providedIn: 'root'
})
export class TimePunchesService {

  constructor(private apiService: ApiService) { }
  getListByUser(currentPage, rowsOnPage,isStopLoader = true,isShowLoader= true) {
    return this.apiService.get(
      `${API.TIME_PUNCH_ROUTE.getListByUser}?userId=${localStorage.getItem(Constants.USERID)}&page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      isStopLoader,
      isShowLoader
    );
  }
  userTimePunchesDataFilter(data) {//dataFilter
    return this.apiService.post(`${API.TIME_PUNCH_ROUTE.timePunchesDataFilter}`, data, true);
  }
  userEditedDataFilter(data) {//dataFilter
    return this.apiService.post(`${API.TIME_PUNCH_EDIT_ROUTE.getUserRequestDataFilter}`, data, true);
  }

  hrManagerTimePunchesList(currentPage, rowsOnPage, IsActive) {
    return this.apiService.get(
      `${API.TIME_PUNCH_ROUTE.hrManagerTimePunches}?companyId=${localStorage.getItem(Constants.COMPANYID)}&isActive=` +
      IsActive + `&page=` + currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true
    );
  }
  hrTimePunchesDataFilter(data, companyId, isActive) {//dataFilter
    return this.apiService.post(`${API.TIME_PUNCH_ROUTE.hrUserTimePunchesDataFilter}?companyId=${companyId}&isActive=${isActive}`, data, true);
  }

  updateTimePunches(data) {
    return this.apiService.put(
      `${API.TIME_PUNCH_ROUTE.editTimePunches}`,
      data,
      true
    );
  }

  addTimePunchesByHr(data) {
    return this.apiService.post(
      `${API.TIME_PUNCH_ROUTE.hrAddTimePunches}`,
      data,
      true
    );
  }

  deleteTimePunchesById(Id) {
    return this.apiService.delete(
      `${API.TIME_PUNCH_ROUTE.deleteTimePunches}?id=` + Id,
      true
    );
  }

  downloadUserExcelData(data, isActive, companyId) {
    return this.apiService.post(
      `${API.TIME_PUNCH_ROUTE.userTrainingExport}?isActive=${isActive}&companyId=${companyId}`,
      data,
      true
    );
  }
  managerTimePunchesList(currentPage,isStopLoader = true,isShowLoader = true) {
    return this.apiService.get(
      `${API.TIME_PUNCH_ROUTE.managerTimePunches}?page=${currentPage}`,
      true,
      isStopLoader,
      isShowLoader
    );
  }
  
  userEditedRequests(currentPage) {
    return this.apiService.get(
      `${API.TIME_PUNCH_EDIT_ROUTE.getUserRequest}?page=${currentPage}`,
      true,
      true
    );
  }

  managerFilterData(data) {//dataFilter
    return this.apiService.post(`${API.TIME_PUNCH_ROUTE.managerTimePunchesDataFilter}`, data, true);
  }
  getPayrollDataByPeriod(startDate,endDate,userId,viewType) {
    return this.apiService.get(
      `${API.TIME_PUNCH_ROUTE.periodPayRoll}?startDate=${startDate}&endDate=${endDate}&userId=${userId}&viewType=${viewType}`,
      true,
      true
    );
  }
  getInconsistTimePunchesData(companyId,startDate, endDate) {
    return this.apiService.get(
      `${API.TIME_PUNCH_ROUTE.getInconsistTimePunches}?companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`,
      true,
      true
    );
  }
  downloadPayrollReportExcelData(startDate,endDate,userId,viewType,data) {
    return this.apiService.post(
      `${API.TIME_PUNCH_ROUTE.payrollReportExport}?startDate=${startDate}&endDate=${endDate}&userId=${userId}&viewType=${viewType}`, data, true);
  }
  payRollTimePunchesDataFilter(startDate,endDate,userId,viewType,data) {//dataFilter
    return this.apiService.post(`${API.TIME_PUNCH_ROUTE.payRollTimePunchesFilter}?startDate=${startDate}&endDate=${endDate}&userId=${userId}&viewType=${viewType}`, data, true);
  }
  getPayrollDetailData(userId, punchTime, companyId) {
    return this.apiService.get(
      `${API.TIME_PUNCH_ROUTE.payrollReportDetail}?userId=${userId}&punchTime=${punchTime}&companyId=${companyId}`,
      true,
      true
    );
  }
  getUserWeekHours(userId,isStopLoader = true, isShowLoader= true) {
    return this.apiService.get(
      `${API.TIME_PUNCH_ROUTE.userWeekHours}?userId=${userId}`,
      true,
      isStopLoader,
      isShowLoader
    );
  }
  resetUserIdentity(username, dateTime, userId) {
    return this.apiService.get(
      `${API.TIME_PUNCH_ROUTE.resetUserIdentity}?username=${username}&dateTime=${dateTime}&userId=${userId}`,
      false,
      true
    );
  }
}
