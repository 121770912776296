import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Constants, ContentEnum } from 'src/app/constant/constants';
import { TermsConditionService } from 'src/app/service/terms-condition.service';
import { UtilityService } from 'src/app/service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fine-print',
  templateUrl: './fine-print.component.html',
  styleUrls: ['./fine-print.component.scss']
})
export class FinePrintComponent implements OnInit {
  public role:number
  @Input() data: any;
  content = '';
  @Input() btnText?= Constants.BTN_TEXT;
  @Input() cancleBtnText?= Constants.CANCLE_BTN_TEXT;
  @Output() closePopup = new EventEmitter<boolean>();
  @Input() isBtnDisplayed = true;
  @Input() title: any;
  @Input() contentType: any;
  

  constructor(private utilityService: UtilityService) { }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.getContent();
  }
  disAgree() {
    this.closePopup.emit(false);
  }

  public getContent(){
    this.content = `<div>The Fine Print: <br/> <br/>
    •	You will receive a $100 Amazon gift card for referring <a href="https://covermywork.com/"><span class="ft4">www.covermywork.com</span></a>   to your current employer when your current employer completes 30 days of active subscription.<br/> <br/>
    •	You will also receive 10% of <a href="https://covermywork.com/"><span class="ft4">www.covermywork.com</span></a> subscription fees paid by the referred company to PARK Technologies LLC each month for 5 years from the start of subscription.<br/> <br/>
    •	PARK Technologies, LLC will contact you at the phone number you provided. You must provide a completed W9 form for receiving above stated payment from PARK Technologies LLC. No payment will be made prior to submitting valid W9 form.<br/> <br/>
    •	By submitting your employers contact information, you confirm that you have obtained necessary approvals from appropriate parties.
    </div>`
  }

}
