import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from "@angular/core";
import { Constants } from "src/app/constant/constants";
import { Role } from "src/app/models/role-model";
import { UtilityService } from "src/app/service/utility.service";

@Component({
  selector: "app-requested-offer",
  templateUrl: "./requested-offer.component.html",
  styleUrls: ["./requested-offer.component.scss"],
})
export class RequestedOfferComponent implements OnInit {
  requestType = Constants.ISREQUESTED_OFFER;
  isSwapRequests = false;
  isVOTRequests = false;
  isTransferRequests = false;
  isTrainingRequests = false;
  isFlexWorkRequests = false;
  isShowSwap = false;
  isShowVot = false;
  isShowTransfer = false;
  isShowTraning = false;
  isVTORequests = false;
  isShowVTO = false;
  isCallInRequest = false;
  isEarlyGoRequest = false;
  isShowCallIn = false;
  isShowEarlyOut = false;
  isShowFlexWork = false;
  isTimeOffRequests = false;
  isShowTimeOff = false;
  isShowLateIn = false;
  isLateInRequest = false;
  isClockInOutRequest = false;
  isShowReview = false;
  isReviewRequest = false;
  role: number;
  roleEnum = Role;
  hrRequestType = Constants.APPROVED_OFFER;
  @ViewChild('noModule', { static: true }) noModule: ElementRef;
  constructor(private ngZone: NgZone, private utilityService: UtilityService) { }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.isSwapRequests = JSON.parse(
      localStorage.getItem(Constants.IS_SWAP_REQUEST)
    );
    this.isVOTRequests = JSON.parse(
      localStorage.getItem(Constants.IS_VOT_REQUEST)
    );
    this.isTransferRequests = JSON.parse(
      localStorage.getItem(Constants.IS_TRANSFER_REQUEST)
    );
    this.isTrainingRequests = JSON.parse(
      localStorage.getItem(Constants.IS_TRAINING_REQUEST)
    );
    this.isVTORequests = JSON.parse(
      localStorage.getItem(Constants.IS_VTO_REQUEST)
    );
    this.isFlexWorkRequests = JSON.parse(
      localStorage.getItem(Constants.IS_FLEX_WORK_REQUEST)
    );
    this.isShowSwap = JSON.parse(localStorage.getItem(Constants.IS_SWAP));
    this.isShowVot = JSON.parse(localStorage.getItem(Constants.IS_VOT));
    this.isShowTransfer = JSON.parse(
      localStorage.getItem(Constants.IS_TRANSFER)
    );
    this.isShowTraning = JSON.parse(
      localStorage.getItem(Constants.IS_TRAINING)
    );
    this.isShowVTO = JSON.parse(localStorage.getItem(Constants.IS_VTO));
    this.isCallInRequest = JSON.parse(
      localStorage.getItem(Constants.IS_CALL_IN_REQUEST)
    );
    this.isEarlyGoRequest = JSON.parse(
      localStorage.getItem(Constants.IS_EARLY_GO_REQUEST)
    );
    this.isShowCallIn = JSON.parse(localStorage.getItem(Constants.IS_CALL_OFF));
    this.isShowEarlyOut = JSON.parse(
      localStorage.getItem(Constants.IS_EARLY_OUT)
    );
    this.isShowFlexWork = JSON.parse(
      localStorage.getItem(Constants.IS_FLEX_WORK)
    );
    this.isTimeOffRequests = JSON.parse(
      localStorage.getItem(Constants.IS_TIME_OFF_REQUEST)
    );
    this.isShowTimeOff = JSON.parse(
      localStorage.getItem(Constants.IS_TIME_OFF)
    );
    this.isShowLateIn = JSON.parse(
      localStorage.getItem(Constants.IS_LATE_IN)
    );
    this.isLateInRequest = JSON.parse(
      localStorage.getItem(Constants.IS_LATE_IN_REQUEST)
    );
    this.isClockInOutRequest = JSON.parse(
      localStorage.getItem(Constants.IS_CLOCKINOUT_REQUEST)
    );
    this.isShowReview =
      JSON.parse(
        localStorage.getItem(Constants.IS_REVIEW)
      );
    this.isReviewRequest =
      JSON.parse(
        localStorage.getItem(Constants.IS_REVIEW_REQUEST)
      );
    if (this.isShowSwap) {
      this.getOffer(1);
    } else if (this.isShowVot) {
      this.getOffer(2);
    } else if (this.isShowVTO) {
      this.getOffer(5);
    } else if (this.isShowCallIn) {
      this.getOffer(6);
    } else if (this.isShowEarlyOut) {
      this.getOffer(7);
    } else if (this.isShowFlexWork) {
      this.getOffer(8);
    } else if (this.isShowTransfer) {
      this.getOffer(3);
    } else if (this.isShowTimeOff) {
      this.getOffer(9);
    } else if (this.isShowLateIn) {
      this.getOffer(10);
    } else if (this.isShowReview) {
      this.getOffer(12);
    }else{
      let elementClass: any = document.getElementsByClassName("sub");
      if (elementClass.length > 0)
        elementClass[0].style.border = 'none'
     this.noModule.nativeElement.innerHTML ="<h1> No modules subscribed, Please contact admin for subscription</h1>";
    }

    this.utilityService.hideLoading();
  }

  getOffer(data) {
    this.isSwapRequests = false;
    this.isVOTRequests = false;
    this.isTransferRequests = false;
    this.isTrainingRequests = false;
    this.isVTORequests = false;
    this.isCallInRequest = false;
    this.isEarlyGoRequest = false;
    this.isFlexWorkRequests = false;
    this.isTimeOffRequests = false;
    this.isLateInRequest = false;
    this.isReviewRequest = false;
    if (data === 1) {
      this.isSwapRequests = true;
    } else if (data === 2) {
      this.isVOTRequests = true;
    } else if (data === 3) {
      this.isTransferRequests = true;
    } else if (data === 4) {
      this.isTrainingRequests = true;
    } else if (data === 5) {
      this.isVTORequests = true;
    } else if (data === 6) {
      this.isCallInRequest = true;
    } else if (data === 7) {
      this.isEarlyGoRequest = true;
    } else if (data === 8) {
      this.isFlexWorkRequests = true;
    } else if (data === 9) {
      this.isTimeOffRequests = true;
    } else if (data === 10) {
      this.isLateInRequest = true;
    } else if (data === 11) {
      this.isClockInOutRequest = true;
    } else if (data === 12) {
      this.isReviewRequest = true;
    }
    else{
      this.noModule.nativeElement.innerHTML ="";
      let elementClass: any = document.getElementsByClassName("sub");
      if (elementClass.length > 0)
        elementClass[0].style.border = 'none'
     this.noModule.nativeElement.innerHTML ="<h1> No modules subscribed, Please contact admin for subscription</h1>";
    }
    this.setLocalState();
  }

  setLocalState() {
    localStorage.setItem(
      Constants.IS_SWAP_REQUEST,
      String(this.isSwapRequests)
    );
    localStorage.setItem(Constants.IS_VOT_REQUEST, String(this.isVOTRequests));
    localStorage.setItem(
      Constants.IS_TRANSFER_REQUEST,
      String(this.isTransferRequests)
    );
    localStorage.setItem(
      Constants.IS_TRAINING_REQUEST,
      String(this.isTrainingRequests)
    );
    localStorage.setItem(Constants.IS_VTO_REQUEST, String(this.isVTORequests));
    localStorage.setItem(
      Constants.IS_CALL_IN_REQUEST,
      String(this.isCallInRequest)
    );
    localStorage.setItem(
      Constants.IS_EARLY_GO_REQUEST,
      String(this.isEarlyGoRequest)
    );
    localStorage.setItem(
      Constants.IS_FLEX_WORK_REQUEST,
      String(this.isFlexWorkRequests)
    );
    localStorage.setItem(
      Constants.IS_TIME_OFF_REQUEST,
      String(this.isTimeOffRequests)
    );
    localStorage.setItem(
      Constants.IS_LATE_IN_REQUEST,
      String(this.isLateInRequest)
    );
    localStorage.setItem(
      Constants.IS_CLOCKINOUT_REQUEST,
      String(this.isClockInOutRequest)
    );
    localStorage.setItem(
      Constants.IS_REVIEW_REQUEST,
      String(this.isReviewRequest)
    );
  }
}
