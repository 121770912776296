import { Component, TemplateRef, ViewChild, AfterViewInit, OnInit, Input, ElementRef } from '@angular/core';
import { Constants, ContentEnum } from 'src/app/constant/constants';
import { Role } from 'src/app/models/role-model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UtilityService } from 'src/app/service/utility.service';

@Component({
  selector: 'app-available-offer',
  templateUrl: './available-offer.component.html',
  styleUrls: ['./available-offer.component.scss']
})
export class AvailableOfferComponent implements OnInit, AfterViewInit {

  
  @ViewChild('template', { static: false }) template: TemplateRef<any>;
  isSwapRequests = false;
  isVOTRequests = false;
  isTransferRequests = false;
  isTrainingRequests = false;
  isFlexWorkRequests = false;
  isShowSwap = false;
  isShowVot = false;
  isShowTransfer = false;
  isShowTraning = false;
  isShowEarlyOut = false;
  isShowFlexWork = false;
  role: number;
  roleEnum = Role;
  modalRef: BsModalRef;
  btnText = Constants.BTN_TEXT;
  cancleBtnText = Constants.CANCLE_BTN_TEXT;
  data: any;
  isShowVto = false;
  isVtoRequests = false;
  isCallInRequests = false;
  isEarlyGoRequest = false;
  title = Constants.TERMS_CONDITION;
  contentType = ContentEnum;
  requestType = Constants.ISAVAILABLE_OFFER;
  hrRequstedType = Constants.REQUESTED_OFFER;
  isShowTimeOffRegister = false;
  isTimeOffRequest = false;
  isLateInRequest = false;
  isShowLateIn=false;
  isReviewRequest = false;
  isShowReview=false;
  @Input() ShowClosedRequestOption:boolean;
  isAvailableOfferUser=false;
  isShowSwapUser= false;
  isShowVtoUser= false;
  isShowFlexWorkUser= false;
  @ViewChild('noModule', { static: true }) noModule: ElementRef;
  
  constructor(private modalService: BsModalService,
    private utilityService: UtilityService,
    private authService: AuthService) {
    // KISHAN : https://sharing.clickup.com/t/h/29fve13/HCKCSDFJZ7QCSQ0
    //if (JSON.parse(localStorage.getItem(Constants.IS_SWAP_REQUEST)) === null) {
      //this.isSwapRequests = true;
      localStorage.setItem(Constants.IS_SWAP_REQUEST, String(this.isSwapRequests));
      localStorage.setItem(Constants.IS_VOT_REQUEST, String(this.isVOTRequests));
      localStorage.setItem(Constants.IS_TRANSFER_REQUEST, String(this.isTransferRequests));
      localStorage.setItem(Constants.IS_TRAINING_REQUEST, String(this.isTrainingRequests));
      localStorage.setItem(Constants.IS_VTO_REQUEST, String(this.isVtoRequests));
      localStorage.setItem(Constants.IS_CALL_IN_REQUEST, String(this.isCallInRequests));
      localStorage.setItem(Constants.IS_EARLY_GO_REQUEST, String(this.isEarlyGoRequest));
      localStorage.setItem(Constants.IS_FLEX_WORK_REQUEST, String(this.isFlexWorkRequests));
      localStorage.setItem(Constants.IS_TIME_OFF_REQUEST, String(this.isTimeOffRequest));
      localStorage.setItem(Constants.IS_LATE_IN_REQUEST, String(this.isLateInRequest));
      localStorage.setItem(Constants.IS_REVIEW_REQUEST, String(this.isReviewRequest));
    //}
  }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    if(this.role===this.roleEnum.user && this.requestType){
    this.isShowSwapUser = JSON.parse(localStorage.getItem(Constants.IS_SWAP));
    this.isShowVtoUser = JSON.parse(localStorage.getItem(Constants.IS_VTO));
    this.isShowFlexWorkUser = JSON.parse(localStorage.getItem(Constants.IS_FLEX_WORK));

    if(this.isShowSwapUser || this.isShowVtoUser || this.isShowFlexWorkUser){
      this.isAvailableOfferUser = true;
    }
         
    }else{
    this.isAvailableOfferUser = false;
    this.isShowSwap = JSON.parse(localStorage.getItem(Constants.IS_SWAP));
    this.isShowVot = JSON.parse(localStorage.getItem(Constants.IS_VOT));
    this.isShowTransfer = JSON.parse(localStorage.getItem(Constants.IS_TRANSFER));
    this.isShowTraning = JSON.parse(localStorage.getItem(Constants.IS_TRAINING));
    this.isShowEarlyOut = JSON.parse(localStorage.getItem(Constants.IS_EARLY_OUT));
    this.isShowVto = JSON.parse(localStorage.getItem(Constants.IS_VTO));
    this.isShowFlexWork = JSON.parse(localStorage.getItem(Constants.IS_FLEX_WORK));
    this.isShowTimeOffRegister = JSON.parse(localStorage.getItem(Constants.IS_TIME_OFF));
    this.isShowLateIn = JSON.parse(localStorage.getItem(Constants.IS_LATE_IN));
    this.isShowReview = JSON.parse(localStorage.getItem(Constants.IS_REVIEW));
    }

    

    if(this.isShowSwap || this.isShowSwapUser)
    {
      this.isSwapRequests = true;
      localStorage.setItem(Constants.IS_SWAP_REQUEST, String(this.isSwapRequests));
    }
    else if(this.isShowVot && this.role !== this.roleEnum.user)
    {
      this.isVOTRequests = true;
      localStorage.setItem(Constants.IS_VOT_REQUEST, String(this.isVOTRequests));
    }
    else if(this.isShowVto || this.isShowVtoUser){
      this.isVtoRequests = true;
      localStorage.setItem(Constants.IS_VTO_REQUEST, String(this.isVtoRequests));
    }
    else if(this.isShowEarlyOut){
      this.isEarlyGoRequest = true;
      localStorage.setItem(Constants.IS_EARLY_GO_REQUEST, String(this.isEarlyGoRequest));
    }
    else if(this.isShowFlexWork || this.isShowFlexWorkUser){
      this.isFlexWorkRequests = true;
      localStorage.setItem(Constants.IS_FLEX_WORK_REQUEST, String(this.isFlexWorkRequests));
    }
    else if(this.isShowTransfer){
      this.isTransferRequests = true;
      localStorage.setItem(Constants.IS_TRANSFER_REQUEST, String(this.isTransferRequests));
    }
    else if(this.isShowTraning){
      this.isTrainingRequests = true;
      localStorage.setItem(Constants.IS_TRAINING_REQUEST, String(this.isTrainingRequests));
    }
    else if(this.isShowTimeOffRegister){
      this.isTimeOffRequest = true;
      localStorage.setItem(Constants.IS_TIME_OFF_REQUEST, String(this.isTimeOffRequest));
    }
    else if(this.isShowLateIn){
      this.isLateInRequest = true;
      localStorage.setItem(Constants.IS_TIME_OFF_REQUEST, String(this.isLateInRequest));
    }
    else if(this.isShowReview){
      this.isReviewRequest = true;
      localStorage.setItem(Constants.IS_TIME_OFF_REQUEST, String(this.isReviewRequest));
    }
    else{
      this.noModule.nativeElement.innerHTML= ''
      let elementClass: any = document.getElementsByClassName("sub");
      if (elementClass.length > 0)
        elementClass[0].style.border = 'none'
     this.noModule.nativeElement.innerHTML ="<h1> No modules subscribed, Please contact admin for subscription</h1>";
    }

    this.isSwapRequests = JSON.parse(localStorage.getItem(Constants.IS_SWAP_REQUEST));
    this.isVOTRequests = JSON.parse(localStorage.getItem(Constants.IS_VOT_REQUEST));
    this.isTransferRequests = JSON.parse(localStorage.getItem(Constants.IS_TRANSFER_REQUEST));
    this.isTrainingRequests = JSON.parse(localStorage.getItem(Constants.IS_TRAINING_REQUEST));
    this.isVtoRequests = JSON.parse(localStorage.getItem(Constants.IS_VTO_REQUEST));
    this.isFlexWorkRequests = JSON.parse(localStorage.getItem(Constants.IS_FLEX_WORK_REQUEST));
    this.isCallInRequests = JSON.parse(localStorage.getItem(Constants.IS_CALL_IN_REQUEST));
    this.isEarlyGoRequest = JSON.parse(localStorage.getItem(Constants.IS_EARLY_GO_REQUEST));
    this.isTimeOffRequest = JSON.parse(localStorage.getItem(Constants.IS_TIME_OFF_REQUEST));
    this.isShowTimeOffRegister = JSON.parse(localStorage.getItem(Constants.IS_TIME_OFF));
    this.isLateInRequest = JSON.parse(localStorage.getItem(Constants.IS_LATE_IN_REQUEST));
    this.isReviewRequest = 
    JSON.parse(localStorage.getItem(Constants.IS_REVIEW_REQUEST));
    

    // KISHAN : https://sharing.clickup.com/t/h/29fve13/HCKCSDFJZ7QCSQ0
    // if (this.role === this.roleEnum.user) {
    //   if (this.isVOTRequests || this.isTransferRequests || this.isTrainingRequests || this.isCallInRequests || this.isEarlyGoRequest || this.isTimeOffRequest ) {
    //     this.isSwapRequests = true;
    //     this.isShowSwap = true;
    //     this.isShowVot = this.role === this.roleEnum.user ? false : true;
    //     this.isVOTRequests = false;
    //     this.isTransferRequests = false;
    //     this.isTrainingRequests = false;
    //     this.isShowTraning = false;
    //     this.isShowTransfer = false;
    //     this.isEarlyGoRequest = false
    //     this.isFlexWorkRequests = false;
    //     this.isShowTimeOffRegister = false;
    //     this.isTimeOffRequest = false;
    //this.isLateInRequest=false;
    //   }
    // }

    // if (this.role === this.roleEnum.manager) {
    //   if (this.isTransferRequests || this.isTrainingRequests || this.isCallInRequests || this.isFlexWorkRequests) {
    //     this.isSwapRequests = true;
    //     this.isShowSwap = true;
    //     this.isShowVot = true;
    //     this.isShowTransfer = true;
    //     this.isShowTraning = false;
    //     this.isSwapRequests=true;
    //     this.isTrainingRequests = false;
    //     this.isTransferRequests = false;
    //     this.isVOTRequests = false;
    //     this.isEarlyGoRequest = false;
    //     this.isFlexWorkRequests = false;
    //this.isLateInRequest=false;
    //   }
    // }
    this.utilityService.hideLoading();
  }

  getOffer(data) {
    this.isSwapRequests = false;
    this.isVOTRequests = false;
    this.isTransferRequests = false;
    this.isTrainingRequests = false;
    this.isVtoRequests = false;
    this.isCallInRequests = false;
    this.isEarlyGoRequest = false;
    this.isFlexWorkRequests = false;
    this.isTimeOffRequest = false;
    this.isLateInRequest = false;
    this.isReviewRequest = false;
    if (data === 1) {
      this.isSwapRequests = true;
    } else if (data === 2) {
      this.isVOTRequests = true;
    } else if (data === 3) {
      this.isTransferRequests = true;
    } else if (data === 4) {
      this.isTrainingRequests = true;
    } else if (data === 5) {
      this.isVtoRequests = true;
    } else if (data === 6) {
      this.isEarlyGoRequest = true;
    } else if (data === 8) {
      this.isFlexWorkRequests = true;
    } else if (data === 9) {
      this.isTimeOffRequest = true;
    }else if (data === 10) {
      this.isLateInRequest = true;
    }else if (data === 12) {
      this.isReviewRequest = true;
    }
    else{
      this.noModule.nativeElement.innerHTML='';
      let elementClass: any = document.getElementsByClassName("sub");
      if (elementClass.length > 0)
        elementClass[0].style.border = 'none'
     this.noModule.nativeElement.innerHTML ="<h1> No modules subscribed, Please contact admin for subscription</h1>";
    }
    localStorage.setItem(Constants.IS_SWAP_REQUEST, String(this.isSwapRequests));
    localStorage.setItem(Constants.IS_VOT_REQUEST, String(this.isVOTRequests));
    localStorage.setItem(Constants.IS_TRANSFER_REQUEST, String(this.isTransferRequests));
    localStorage.setItem(Constants.IS_TRAINING_REQUEST, String(this.isTrainingRequests));
    localStorage.setItem(Constants.IS_VTO_REQUEST, String(this.isVtoRequests));
    localStorage.setItem(Constants.IS_CALL_IN_REQUEST, String(this.isCallInRequests));
    localStorage.setItem(Constants.IS_EARLY_GO_REQUEST, String(this.isEarlyGoRequest));
    localStorage.setItem(Constants.IS_FLEX_WORK_REQUEST, String(this.isFlexWorkRequests));
    localStorage.setItem(Constants.IS_TIME_OFF_REQUEST, String(this.isTimeOffRequest));
    localStorage.setItem(Constants.IS_LATE_IN_REQUEST, String(this.isLateInRequest));
    localStorage.setItem(Constants.IS_REVIEW_REQUEST, String(this.isReviewRequest));
  
  }

  ngAfterViewInit() {
    if (!!localStorage.getItem(Constants.IS_TERMS_UPDATE) && JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))) {
     
      this.openTermsModal(this.template, null);
    }
  }

  openTermsModal(template: TemplateRef<any>, data: any) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  checkAgreeDisagree(event) {
    if (event) {
      this.agree();
    } else {
      this.utilityService.showErrorToast(Constants.TERMS_CONDITION_MSG);
    }
  }

  agree() {
    this.authService.agreeForCondition(localStorage.getItem(Constants.USERID)).
      then(response => {
        if (response['Success']) {
          localStorage.setItem(Constants.IS_TERMS_UPDATE, 'false');
          this.modalRef.hide();
        } else {
          this.utilityService.showErrorToast(response['Message']);
        }
      }, err => { });
  }
}
