import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { API } from "../routes/api-routes";

@Injectable()
export class TrainingService {
  constructor(private apiService: ApiService) {}

  getModuleList(currentPage, isActive) {
    return this.apiService.get(
      `${API.TRAINING_ROUTE.getModuleTraining}?isActive=` +
        isActive +
        `&page=` +
        currentPage,
      true,
      true
    );
  }

  getFunctionList(currentPage, isActive) {
    return this.apiService.get(
      `${API.TRAINING_ROUTE.getFunctionTraining}?isActive=` +
        isActive +
        `&page=` +
        currentPage,
      true,
      true
    );
  }

  getActivityDatList(currentPage,rowOnPage, isActive) {
    return this.apiService.get(
      `${API.ACTIVITY_LOG.activityLog}?isActive=` +
        isActive +
        `&page=` +
        currentPage + 
        `&pageSize=`+ rowOnPage,
      true,
      true
    );
  }

  getUserTrainingList(currentPage, companyId) {
    return this.apiService.get(
      `${API.TRAINING_ROUTE.getUsertraining}?page=` +
        currentPage +
        `&companyId=` +
        companyId,
      true,
      true
    );
  }

  addTraining(data) {
    return this.apiService.post(
      `${API.TRAINING_ROUTE.addTraining}`,
      data,
      true
    );
  }
  updateTraining(data) {
    return this.apiService.put(
      `${API.TRAINING_ROUTE.updateTraining}`,
      data,
      true
    );
  }

  getDepartmentMappingList(currentPage) {
    return this.apiService.get(
      `${API.DEPARTMENT_MAPPING_ROUTES.departmentMapping}?page=` + currentPage,
      true,
      true
    );
  }

  moduledataFilter(data, isActive) {
    return this.apiService.post(
      `${API.TRAINING_ROUTE.ModuledataFilter}?isActive=` + isActive,
      data,
      true
    );
  }

  functionDatFilter(data, isActive) {
    return this.apiService.post(
      `${API.TRAINING_ROUTE.FunctionDataFilter}?isActive=` + isActive,
      data,
      true
    );
  }

  activityDataFilter(data, isActive) {
    return this.apiService.post(
      `${API.ACTIVITY_LOG.activityLogDataFilter}?isActive=` + isActive,
      data,
      true
    );
  }

  userTrainingDataFilter(data, companyId) {
    return this.apiService.post(
      `${API.TRAINING_ROUTE.userTrainingDataFilter}?companyId=` + companyId,
      data,
      true
    );
  }

  hrManagerFilterData(data, companyId,isActive) {
    return this.apiService.post(
      `${API.ACTIVITY_LOG.hrManagerFilterData}?companyId=` + companyId + `&isActive=` +isActive ,
      data,
      true
    );
  }

  hrManagerTrainingList(currentPage, companyId, IsActive) {
    return this.apiService.get(
      `${API.ACTIVITY_LOG.hrManagerDataList}?isActive=` +
        IsActive +
        `&companyId=` +
        companyId +
        `&page=` +
        currentPage,
      true,
      true
    );
  }

  deleteTraining(Id) {
    return this.apiService.delete(
      `${API.TRAINING_ROUTE.training}?id=` + Id,
      true
    );
  }

  downloadModuleExcelFile(data, isActive) {
    return this.apiService.post(
      `${API.TRAINING_ROUTE.downloadModuleExcelFile}?isActive=` + isActive,
      data,
      true
    );
  }

  downloadFunctionExcelFile(data, isActive) {
    return this.apiService.post(
      `${API.TRAINING_ROUTE.downloadFunctionExcelFile}?isActive=` + isActive,
      data,
      true
    );
  }
  downloadActivityExcel(data, isActive) {
    return this.apiService.post(
      `${API.ACTIVITY_LOG.downloadActivityExcel}?isActive=` + isActive,
      data,
      true
    );
  }
  downloadUserExcelData(data, companyId) {
    return this.apiService.post(
      `${API.TRAINING_ROUTE.userTrainingExport}?companyId=` + companyId,
      data,
      true
    );
  }
  downloadHrManagerData(data, companyId,isActive) {
    return this.apiService.post(
      `${API.ACTIVITY_LOG.downloadHrManagerData}?companyId=` + companyId + `&isActive=` + isActive,
      data,
      true
    );
  }
  downloadFile(traningId) {
    return this.apiService.post(
      `${API.TRAINING_ROUTE.downloadFileData}?traningId=` + traningId,
     null,
      true
    );
  }
}
