import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Constants, trainingStatus } from 'src/app/constant/constants';
import { TrainingTypeEnum } from 'src/app/models/role-model';
import { ModuleFunctionService } from 'src/app/service/moduleFunction.service';
import { FormService } from './../../core/form.service';
import { TrainingService } from 'src/app/service/training.service';
import { ToastrService } from 'ngx-toastr';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { UtilityService } from 'src/app/service/utility.service';
import { FileToUpload } from 'src/app/models/fileUpload';

@Component({
  selector: 'app-add-edit-function-training',
  templateUrl: './add-edit-function-training.component.html',
  styleUrls: ['./add-edit-function-training.component.scss']
})
export class AddEditFunctionTrainingComponent implements OnInit {
  @Input() functionTraining: any;
  @Output() closeRequest = new EventEmitter<boolean>();
  public trainingForm: any;
  public files: NgxFileDropEntry[] = [];
  messageList: any = {
    UserName: String,
    Module: String,
    TraindedDate: Date,
    Trainer: String,
    Status: String
  };
  statusList = [];
  functionList = [];
  theFile: any = null;
  theFiles: any[] = [];
  fileObj: any = [];
  constructor(private formService: FormService,
    private moduleFunctionService: ModuleFunctionService,
    private trainingService: TrainingService,
    private toaster: ToastrService,
    private utility: UtilityService,) { }

  ngOnInit() {
    this.statusList = trainingStatus
    this.initializeForm();
    this.initializeMessages();
    this.getFunctionList();
  }
  initializeForm() {
    this.trainingForm = new FormGroup({
      trainingId: new FormControl(!!this.functionTraining ? this.functionTraining.trainingId : 0),
      Username: new FormControl(!!this.functionTraining ? this.functionTraining.userName : '', Validators.required),
      functionId: new FormControl(!!this.functionTraining ? this.functionTraining.functionId : '', Validators.required),
      trainingDate: new FormControl(!!this.functionTraining ? new Date(this.functionTraining.trainingDate) : '', Validators.required),
      TrainerName: new FormControl(!!this.functionTraining ? this.functionTraining.trainerName : '', Validators.required),
      Status: new FormControl(!!this.functionTraining ? this.functionTraining.status : '', Validators.required),
      trainingType: new FormControl(TrainingTypeEnum.function),
      isActive: new FormControl(true),
      recordedByID: new FormControl(!!this.functionTraining ? this.functionTraining.recordedByID : ''),
      statusChangedDate: new FormControl(!!this.functionTraining ? this.functionTraining.statusChangedDate : ''),
      createdDate: new FormControl(!!this.functionTraining ? this.functionTraining.createdDate : ''),
      FileModels:new FormControl()
    })
    if(!!this.functionTraining && this.functionTraining.trainingId > 0 && this.functionTraining.FileModels.length >0){
      this.fileObj = this.functionTraining.FileModels.map(x=>{
        return {
          FileName : x.OriginalFileName,
          Path : x.Path
        }
      })
    }
  }
  initializeMessages() {
    this.messageList.Username = {
      required: Constants.VALIDATION_MSG.Module_Traning.USERNAME
    };
    this.messageList.Function = {
      required: Constants.VALIDATION_MSG.Module_Traning.FUNCTION_NAME
    };
    this.messageList.TrainedDate = {
      required: Constants.VALIDATION_MSG.Module_Traning.TRAINED_DATE
    };
    this.messageList.Trainer = {
      required: Constants.VALIDATION_MSG.Module_Traning.TRAINER
    };
    this.messageList.Status = {
      required: Constants.VALIDATION_MSG.Module_Traning.STATUS
    };
  }
  getFunctionList() {
    this.moduleFunctionService.functionDataList(null).then(
      (res: any) => {
        if (res["Success"]) {
          this.functionList = res.Data;
        } else {
          this.functionList = [];
        }
      },
      (err) => {
        this.functionList = [];
      }
    );
  }
  cancel() {
    this.closeRequest.emit(false);
  }
  value(controlName: string) {
    return this.control(controlName).value;
  }
  control(controlName: string): AbstractControl {
    return this.trainingForm.get(controlName);
  }
  onSubmit() {
    this.formService.markFormGroupTouched(this.trainingForm);
    if (this.trainingForm.invalid) {
      return;
    }
    if (
      this.trainingForm.controls.Username.value ==
      this.trainingForm.controls.TrainerName.value
    ) {
      this.toaster.error("Users can not train themselves");
      return;
    }
    this.trainingForm.controls.FileModels.setValue(this.fileObj);
    const saveMethod = this.trainingForm.controls.trainingId.value > 0 ? this.trainingService.updateTraining(this.trainingForm.value)
      : this.trainingService.addTraining(this.trainingForm.value);
    saveMethod.then((res) => {
      if (res['Success']) {
        this.toaster.success(this.trainingForm.controls.trainingId.value > 0 ? Constants.TRAINING_UPDATE_SUCCESS_MSG : Constants.TRAINING_ADD_SUCCESS_MSG);
        this.closeRequest.emit(true);
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { this.closeRequest.emit(false); });
  }
  private readAndUploadFile(theFile: any) {
    let file = new FileToUpload();

    // Set File Information
    file.fileName = theFile.name;
    file.fileSize = theFile.size;
    file.fileType = theFile.type;
    file.lastModifiedTime = theFile.lastModified;
    file.lastModifiedDate = theFile.lastModifiedDate;

    // Use FileReader() object to get file to upload
    // NOTE: FileReader only works with newer browsers
    let reader = new FileReader();

    // Setup onload event for reader
    reader.onload = () => {
      // Store base64 encoded representation of file
      file.fileAsBase64 = reader.result.toString();
      this.fileObj.push({
        FileName : file.fileName,
        Base64StringFile: file.fileAsBase64
      })
      // POST to server
      // this.uploadService.uploadFile(file).subscribe(resp => { 
      //     this.messages.push("Upload complete"); });
    }

    // Read the file
    reader.readAsDataURL(theFile);
  }

  onFileChange(event) {
    this.theFiles = [];
    let file = event.target.files[0];
    let fileSizeMB = (file.size / 1024) / 1024;
    let fileName = file.name.toLowerCase().split(".")
    if (fileName[fileName.length - 1] != "pdf" && fileName[fileName.length - 1] != "xls"
    && fileName[fileName.length - 1] != "xlsx" && fileName[fileName.length - 1] != "doc"
    &&  fileName[fileName.length - 1] != "docx" ) {
      this.utility.showErrorToast("This file is not in valid format");
      return;
    } else if(fileSizeMB>5){
      this.utility.showErrorToast("Selected file size must be less than 5 MB");
      return;
    }else {
      this.theFiles.push(file);
      this.readAndUploadFile(this.theFiles[0]);
    }
  }
  removeFile(index: number) {
    this.fileObj.splice(index, 1);
  }
}
