import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Constants, SubscriptionType } from 'src/app/constant/constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Role } from 'src/app/models/role-model';
import { EarlyOutService } from './../../service/earlyOut.service';
import { ToastrService } from 'ngx-toastr';
import { CallInRequestService } from 'src/app/service/callInRequest.service';
import { DatePipe } from '@angular/common';
import { forkJoin } from 'rxjs';
import { UtilityService } from 'src/app/service/utility.service';



@Component({
  selector: 'app-available-early-go-request',
  templateUrl: './available-early-go-request.component.html',
  styleUrls: ['./available-early-go-request.component.scss']
})
export class AvailableEarlyGoRequestComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  offerId: number;
  selected = [];
  columns = [];
  selectedRecords = [];
  confirmMsg = '';
  approveMsg = '';
  confirmCancelBtn = '';
  confirmSaveBtn = '';
  isApprove = false;
  isReject = false;
  isConfirmed = false;
  public earlyGoOffer: any;
  modalRef: BsModalRef;
  public earlyGoRequestList: any = [];
  public totalItems: any = '1';
  page: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  role: number;
  roleEnum = Role;
  earlyOutRequestList = [];
  searchSort: any;
  moduleId: number;
  isPaidTimeOff: boolean;
  IsCoverMyWork: boolean;

  constructor(private toaster: ToastrService,
    private modalService: BsModalService,
    private earlyOutService: EarlyOutService,
    private datePipe:DatePipe,
    private utilityService:UtilityService,
    private callInRequstService:CallInRequestService) {
      this.columns=['createdByObj.companyUsername','dateToSkipShortDate','vtoStartTime','shiftToSkipNavigation.title','departmentName','uTOHours'
                    ,'ReasonObj.name',"createdDate",'VtoStartTime12Hours','IsPaidOff','OtherReason',"depatureTime"]
      this.page = {
        pageNumber: 0,
        size: this.rowsOnPage,
      };
      this.searchSort = {
        Page: this.page.pageNumber + 1,
        PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
        Columns: [
        ],
        Search: {
          Value: '',
          ColumnNameList: [],
          Regex: 'string'
        },
        Order: [{
          Column: 0,
          ColumnName: '',
          Dir: 'asc'
        }]
      }
  }

  ngOnInit() {
    this.IsCoverMyWork = localStorage.getItem(Constants.APP_NAME) === "CoverMyWork" ? true:false;
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.isConfirmed = false;
    this.setPage({ offset: 0 });
  }

  async setPage(pageInfo) {
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
    this.utilityService.showLoading();
    let module = SubscriptionType.filter((item)=>{
      return item.value ==='Early Out Module';
    });
    this.moduleId = module[0].id;
    forkJoin([
      this.earlyOutService.getEarlyOutAvailableManagerRequestList(this.page.pageNumber + 1, this.rowsOnPage),
      this.callInRequstService.getSettingByCompanyID(this.moduleId, Number(localStorage.getItem(Constants.COMPANYID)))
    ]).subscribe((res:any) => {
      //first api response
      if (res[0]['Success']) {
        if (res[0].Data && res[0].Data.results.length > 0) {
          this.earlyOutRequestList = res[0].Data.results
          this.totalItems = res[0].Data.totalNumberOfRecords;
        } else {
          this.earlyOutRequestList = [];
          this.totalItems = 0;
        }
      } else {
        this.earlyOutRequestList = [];
        this.totalItems = 0;
      }

      //second api response
      if (res[1]['Success']) {
        if(res[1].Data.length > 0){
          res[1].Data.map((item)=>{
            if(item.SettingType==="PaidTimeOff"){
              item.Name = "Paid Time Off";
                if(item.Enable==true){
                  this.isPaidTimeOff = true;
                }
                else{
                  this.isPaidTimeOff = false;
                }
            }
          });
        }
      } 
      this.utilityService.hideLoading();
    })
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }
  
  getSettingByCompanyIDAsync() {
    let module = SubscriptionType.filter((item)=>{
      return item.value ==='Early Out Module';
    });
    this.moduleId = module[0].id;
    const getMethod = this.callInRequstService.getSettingByCompanyID(this.moduleId, Number(localStorage.getItem(Constants.COMPANYID)));
    getMethod.then((res: any) => {
      if (res['Success']) {
        if(res.Data.length > 0){
          res.Data.map((item)=>{
            if(item.SettingType==="PaidTimeOff"){
              item.Name = "Paid Time Off";
                if(item.Enable==true){
                  this.isPaidTimeOff = true;
                }
                else{
                  this.isPaidTimeOff = false;
                }
            }
          });
        }
      } 
    }, err => {  });
    
  }


   getEarlyOutOfferListAsync(currentPage) {
    const getMethod = this.earlyOutService.getEarlyOutAvailableManagerRequestList(currentPage, this.rowsOnPage)
    getMethod.then((res: any) => {
      if (res['Success']) {
        if (res.Data && res.Data.results.length > 0) {
          this.earlyOutRequestList = res.Data.results
          this.totalItems = res.Data.totalNumberOfRecords;
          // this.totalRequestedHours = this.role == this.roleEnum.manager ? res.Data.totalHours.totalHoursApproved : 0;
        } else {
          this.earlyOutRequestList = [];
          this.totalItems = 0;
        }
      } else {
        this.earlyOutRequestList = [];
        this.totalItems = 0;
      }
    }, err => {
      this.earlyOutRequestList = [];
      this.totalItems = 0;
    });
  }
  filterData(){
      const filterMethod = this.earlyOutService.managerAvailableEarltyOutOfferDataFilter(this.searchSort);
      filterMethod.then(res => {
        if (res['data'] && res['data'].length > 0) {
          this.earlyOutRequestList = res['data'];
          this.totalItems = res['recordsFiltered'];
        } else {
          this.totalItems = 0;
        }
      }, err => { this.earlyOutRequestList = []; this.totalItems = 0; });
  }

  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.earlyGoOffer = data;
    } else {
      this.earlyGoOffer = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }
  clear() {
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.searchSort.Columns.forEach(element => {
      element.Search.Value = '';
    });
    this.setPage({ offset: 0 });
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clearText();
      }
    }
  }

  clearText() {
    this.searchSort.Search.Value = '';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  close(event) {
    this.isConfirmed = false;
    this.isReject = false;
  }

  Denied(data) {
    this.isReject = true;
    this.offerId = data.offerId;
    this.confirmSaveBtn = Constants.YES;
    this.confirmCancelBtn = Constants.NO;
    this.confirmMsg = 'Are you sure you want to reject this request?';
    // this.offerId = data.offerId;
  }

  openApprove(data) {
    this.offerId = data.offerId
    this.isConfirmed = true;
    this.approveMsg = 'Are you sure you want to approve this request?'
  }
  approve(id) {
    this.isConfirmed = false;
    this.earlyOutService.approveManagerEarlyOutOffers(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.OFFER_APPROVE_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { });

  }
  reject(id) {
    this.isReject = false;
    this.earlyOutService.rejectManagerEarlyOutOffers(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.OFFER_REJECT_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { });
  }
  convertDateToSkipShortDate(dateToSkipShortDate){
    const date = dateToSkipShortDate.split('/');
    const dateToSkipMM = parseInt(date[0]);
    const dateToSkipDD = parseInt(date[1]);
    const dateToSkipYYYY = parseInt(date[2]);

    return this.datePipe.transform(new Date(
      dateToSkipYYYY,
      dateToSkipMM - 1,
      dateToSkipDD - 1
    ), 'MM/dd/yyyy');
}
}

