import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ShiftComponent } from "./shift.component";
import { AddComponent } from './add/add.component';
import { DatatableComponent } from '@swimlane/ngx-datatable';

const routes: Routes = [
  {
    path: "",
    component: ShiftComponent,
  },
  { path: 'add', component: AddComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShiftRoutingModule {
}
