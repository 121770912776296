import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/constant/constants';
import { TimeTrackingService } from 'src/app/service/time-tracking.service';

@Component({
  selector: 'app-time-tracking',
  templateUrl: './time-tracking.component.html',
  styleUrls: ['./time-tracking.component.scss']
})
export class TimeTrackingComponent implements OnInit {
  AllTimeTrackings=[];
  rowsOnPage = Constants.HR_PAGE_ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  totalHrPunchesItems: any;
  deleteId: any;
  isConfirmed: boolean=false;
  myTimeTrack: any;
  modalRef: BsModalRef;
  constructor(
    public timeTrackingSr:TimeTrackingService,
    private toaster: ToastrService,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.getAllTimeTrackings(1);
  }
  getAllTimeTrackings(pageNumber){
    let companyId = Number(localStorage.getItem(Constants.COMPANYID))
    this.timeTrackingSr.getAllTimeTracking(0,companyId,pageNumber,200).then((response:any)=>{
        this.AllTimeTrackings = response.Data.results ? response.Data.results : response.Data;
        this.totalHrPunchesItems =
        response.Data.totalNumberOfRecords === 0
          ? this.AllTimeTrackings.length
          : response.Data.totalNumberOfRecords;
    })
  }

  onClickDelete(data) {
    this.deleteId = data.RecordId;
    this.isConfirmed = true;
  }
  delete(id) {
    this.isConfirmed = false;
    this.timeTrackingSr.DeleteTimeTracking(id).then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success("Time Track record deleted success!");
          this.getAllTimeTrackings(1);
        } else {
          this.toaster.error(res["Message"]);
        }
      },
      (err) => {}
    );
  }
  close(event) {
    this.isConfirmed = false;
  }
  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.myTimeTrack = data;
    } else {
      this.myTimeTrack = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }
  closeModal(event) {
    if (event === "Save" || event === "SaveAndClose") {
    }
    if (event === "Save" || event === "Close") {
      this.modalRef.hide();
      this.getAllTimeTrackings(1);
    }
  }
  caclulateHours(start,end){
    var date1:any = new Date(end);
    var date2:any = new Date(start);
    var diffInSeconds = Math.abs(date1 - date2) / 1000;
    var days = Math.floor(diffInSeconds / 60 / 60 / 24);
    var hours = Math.floor(diffInSeconds / 60 / 60 % 24);
    var minutes = Math.floor(diffInSeconds / 60 % 60);
    var seconds = Math.floor(diffInSeconds % 60);
    var milliseconds = Math.round((diffInSeconds - Math.floor(diffInSeconds)) * 1000);
    return `${hours}:${minutes}:${seconds}`;
  }
}
