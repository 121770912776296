export const Constants = {
  CURRENT_PAGE: 1,
  COMMONTOKEN: "commonToken",
  ROWS_ON_PAGE: 10,
  HR_PAGE_ROWS_ON_PAGE: 100,
  EARLY_OUT_CONFIRM_DISCLAIMER: `You have called off and taken early out for total of ${parseInt('res'['Message']) + 1} time in last 30 days. Are you sure you want to continue?`,
  CALL_OFF_CONFIRM_DISCLAIMER: "Would you like to try shift swap instead of calling off?",
  MANAGER_OFFER_DISCLAIMER: ` I understand that I am creating a solo opportinuty to for team members to take advantage of. If this offer was to be accepted by a team member. It will have net effect on expected head count on skip date as this is a solo swap. I understand Coverage Catch allows managers to create this type of dummy offers to balance staffing needs based on seasonality of your business. I understand that creating too many of these opportunities may shift the headcount balance too far but I have reviewed the business need.`,
  DISCLAIMER: "Disclaimer",
  HR_TERMS_AND_CONDITION_OFFER_TEXT:
    "I agree to the following terms to the offer",
  COMPANY_CHANGE_CONFIRM_MSG: "Are you sure you want to change the company",
  REJECT_MSG: "Are you sure you want to reject ?",
  APPROVE_MSG: `By approving this request, you are confirming that you have adjusted related employee’s work schedules in your inhouse time keeping system (KRONOS or other). CMW does not interact with your scheduling system currently therefore manual schedule adjustment is necessary right before approving requests. An email/text will be sent to employee as soon as you click confirm.`,
  APPROVE: 1,
  REJECT: 0,
  CALL_OFF_UTO_MESSAGE: "Call-off UTO Message",
  CALL_OFF_INTERVENTION: "Call-off intervention",
  EARLY_OUT_INTERVENTION: "Early-out intervention",
  UTO_SYSTEM_FOR_ATTENDANCE: "UTO system for attendance",
  PENDING_CONFIRM_BTN_TEXT: "Confirm",
  PENDING_CANCEL_BTN_TEXT: "Cancel",
  YES: "Yes",
  NO: "No",
  ROLE: "role",
  USERNAME: "userName",
  TOKEN: "access_token",
  USERID: "userId",
  SHIFTID: "shiftId",
  DEPARTMENTID: "departmentId",
  SUBDEPARTMENTID: "subdepartmentId",
  LOCATIONID: "loationId",
  MANAGERID: "managerId",
  PRICINGID: "pricingId",
  FILE_EXTENSIONS: ".csv, .xls, .xlsx",
  FILE_EXTENSION: ".csv, .xls, .xlsx, .pdf",
  COMPANY_NAME: "companyName",
  RETURNING_COMPANYID:"returning-userCompanyID",
  REQUESTED_OFFER: "requestedOffer",
  APPROVED_OFFER: "approvedOffer",
  ISAVAILABLE_OFFER: "isAvailableOffer",
  ISREQUESTED_OFFER: "isRequestedOffer",
  IS_SWAP: "isSwap",
  IS_VOT: "isVot",
  IS_TRANSFER: "isTransfer",
  IS_TRAINING: "isTraining",
  ACTIVE_TAB: "activeTab",
  IS_VTO: "isVto",
  IS_CALL_OFF: "isCallOff",
  IS_EARLY_OUT: "isEarlyOut",
  IS_LATE_IN: "isLateIn",
  IS_FLEX_WORK: "isFlexWork",
  IS_TIME_OFF_REQUEST: "isTimeOffRequests",
  IS_TIME_OFF: "isTimeOff",
  IS_CLOCKINOUT: "isCheckInOut",
  IS_REVIEW: "isReview",
  IS_SWAP_REQUEST: "isSwapRequests",
  IS_VOT_REQUEST: "isVOTRequests",
  IS_TRANSFER_REQUEST: "isTransferRequests",
  IS_TRAINING_REQUEST: "isTrainingRequests",
  IS_TERMS_UPDATE: "isTermsUpdated",
  IS_VTO_REQUEST: "isVtoRequest",
  IS_FLEX_WORK_REQUEST: "isFlexWorkRequest",
  IS_CALL_IN_REQUEST: "isCallInRequest",
  IS_EARLY_GO_REQUEST: "isEarlyGoRequest",
  IS_LATE_IN_REQUEST: "isLateInRequest",
  IS_CLOCKINOUT_REQUEST: "isCheckInOutRequest",
  IS_REVIEW_REQUEST: "isReviewRequest",
  IS_ALLOW_AS_USER: "isAllowAsAUser",
  APP_NAME: "appName",
  LOGO_PATH: "logoPath",
  LOGIN_URL: "loginUrl",
  WEB_URL: "webUrl",
  GET_SETTINGS_BY_COMPANYID: "getSettingsByCompanyID",
  BTN_TEXT: "I agree",
  TERMS_CONDITION: "Terms & Condition",
  PRIVACY: "Privacy Notice",
  FINE_PRINT: "Marketing bonus for covermywork",
  CANCLE_BTN_TEXT: `I don't agree`,
  COVERMYWORK: "http://www.covermywork.com/",
  COVERAGECATCH: "https://www.coveragecatch.com/",
  NO_INTERNET_CONNECTION_MSG: "No internet connection!",
  RECAPTCHA_SITEKEY: "6LfS9g0cAAAAAGC1QLgmu1DILycAUFjEJVfo8aIs",
  OFFER_MSG_WHILE_COMPANY_CHANGE:
    "Your account found created pending offers, If you wish to change company then all will be removed. Are you sure to change company?",
  DELETE_MSG: "Are you sure you want to Delete ?",
  IACCEPT_MSG: "Are you sure you want to Accept ?",
  APPROVED_LABEL_MESSAGE:
    "All approved transfers have to be accepted by you within 7 days of approval. Please click the Action icon and Accept",
  NOT_INTERESTED_MSG: "Are you sure you are not interested ?",
  REGEX: {
    EMAIL_PATTERN: new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
    PASSWORD_PATTERN: new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,16}$/
    ),
    PHONE_PATTERN: new RegExp(/^([0-9]{10,10})$/),
    DECIMAL_PATTRN: new RegExp(/^\d*?(\.\d{1,2})?$/),
    INTEGER_PATTERN: new RegExp(/^[0-9]*$/),
    CHARACTER_PATTERN: new RegExp(/^[a-zA-Z ]*$/),
    ZIP_PATTERN: new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/),
    LIMIT_PATTERN: new RegExp(/^([1-9]|1[0-9]|2[0])$/),
    FLEX_LIMIT_PATTERN: new RegExp(/^([1-9]|[1-2-3-4][0-9]|5[0])$/),
    LONGITUDE_PATTERN: new RegExp(/^-?(0|[1-9]\d*)(\.\d+)?$/),
    LATITUDE_PATTERN: new RegExp(/^-?(0|[1-9]\d*)(\.\d+)?$/),
    HOURS_PATTERN: new RegExp(/^[1-9]+[0-9]*$/)
  },
  VALIDATION_MSG: {
    DESCTIPTION_REQ: "UTO warning Message is Required",
    MANAGER_NOTE_REQUIRED: "Manager Note is Required",
    USER_INVALID:"User does not exist",
    REVIEW_TYPE_REQUIRED:"Review Type is Required",
    CHECK_CREDENTIALS: "Please check your credentials and company name",
    SIGN_UP: {
      COMPANY_REQUIRED: "Company is required",
      LOCATION_REQUIRED: "Location is required",
      DEPARTMENT_REQUIRED: "Department is required",
      MANAGER_REQUIRED: "Manager is required",
      SHIFT_REQUIRED: "Shift is required",
      NAME_REQUIRED: "Name is required",
      EMAIL_REQUIRED: "Email is required",
      EMAIL_INVALID: "Email is not valid",
      PHONE_REQUIRED: "Phone number is required",
      PHONE_INVALID: "Phone number is not valid",
      COMPANY_NAME_REQUIRED: "User name is required",
      EMPLOYEEID_REQUIRED: "EmployeeId is required",
      PASSWORD_REQUIRED: "Password is required",
      PASSWORD_INVALID:
        "Password should contain 1 uppercase, 1 lowercase, 1 digit, 1 special character and minimum 6 and maximum 15 letter length!",
      CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
      PASSWORD_CONFIRM_PASSWORD_NOT_MATCH: `Password and Confirm password doesn't match!`,
      ROLE_REQUIRED: "Role is required",
      COMMUNICATION_METHOD_REQUIRED:
        "At least one communication method is required!",
    },
    LOCATION: {
      LOCATION_NAME_REQUIRED: "Location name is required",
      GEOLOCATION_NAME_REQUIRED: "GeoLocation name is required",
      TIME_PICKING_SYSTEM_REQUIRED: "Time picking system is required",
      LATITUDE_REQUIRED: "Latitude is required",
      LATITUDE_Pattern: "Please enter a valid latitude",
      LONGITUDE_REQUIRED: "Longitude is required",
      LONGITUDE_Pattern: "Please enter a valid longitude",
      COMPANY_NAME_REQUIRED: "Company name is required",
    },
    DEPARTMENT: {
      DEPARTMENT_NAME_REQUIRED: "Department name is required",
    },
    SUB_DEPARTMENT: {
      DEPARTMENT_REQUIRED: "Department  is required",
      SUB_DEPARTMENT_NAME_REQUIRED: "Sub Department is required",
    },
    TERMS_CONDITION: {
      DESCRIPTION_REQUIRED: "Terms and condition is required",
      TYPE_REQUIRED: "Type is required",
    },
    COMPANY: {
      COMPANY_NAME_REQUIRED: "Company name is required",
      LOCATION_NAME_REQUIRED: "Location is required",
      SETUPCOST_REQUIRED: "One time SetUpCost is required",
      COSTFORFIRSTUser_REQUIRED: "Cost for first user is required",
      NUMBEROFUSERS_REQUIRED: "Number of users required",
      ONLY_DIGIT_ALLOWED: "Only digit allowed",
    },
    SHIFT: {
      TITLE_REQUIRED: "Friendly name is required",
      SHIFT_CODE_REQUIRED: "Shift code is required",
      DEPARTMENT_REQUIRED: "Department is required",
      COMPANY_REQUIRED: "Company is required",
    },
    FORGOTPASSWORD: {
      EMAIL_REQUIRED: "Email is required",
      EMAIL_SERVERDOWN: "Email server down, Please try later",
    },
    OFFER: {
      SKIP_DATE_REQUIRED: "I would like to skip date is required",
      WORK_ON_DATE_REQUIRED: "I would like to work on date is required",
      SKIP_SHIFT_REQUIRED: "Offer to skip shift is required",
      WORK_OFFER_SHIFT_REQUIRED: "Offer to work shift is required",
      WORK_SHIFT_REQUIRED: "Shift to work on is required",
      DEPARTMENT_REQUIRED: "Department is required",
      DATE_MSG: "Date to work is not same as skip date!",
      TIME_TYPE_REQUIRED: "Time is required!",
    },
    CHANGEPASSWORD: {
      CURRENT_PASSWORD_REQUIRED: "Current Password is required",
      NEW_PASSWORD_REQUIRED: "Password is required",
      PASSWORD_INVALID:
        "Password should contain 1 uppercase, 1 lowercase, 1 digit, 1 special character and minimum 6 and maximum 15 letter length!",
      CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
      PASSWORD_CONFIRM_PASSWORD_NOT_MATCH: `Password and Confirm password doesn't match!`,
    },
    PLAN: {
      NUMBEROFUSERS_REQUIRED: "Number of users required",
      SETUPCOST_REQUIRED: "Setupcost is required",
      COSTFORFIRSTUSER_REQUIRED: "Cost for first user is required",
      COSTFORRESTUSER_REQUIRED: "Cost for rest user is required",
      PRICEFORENABLINGMSG: "Price for enablingmsg is required",
      CARDHOLDERNAME: "Card holder name is required",
      CREDITCARDNUMBER: "Credit card number is required",
      EXPIRATIONDATE: "Experation date is require",
      SECURITYCODE: "Security code is required",
      CREDIT_CARD_MIN_LENGTH: "The min allowed number of characters is 16",
      CREDIT_CARD_MAX_LENGTH: "The max allowed number of characters is 16",
      CHARACTER_ALLOW_MSG: "Only character allowed",
      ADDRESS_REQUIRED: "Address is required",
      STATE_REQUIRED: "State is required",
      CITY_REQUIRED: "City is required",
      ZIP_REQUIRED: "Zip is required",
      ZIP_MIN_LENGTH: "The min allowed number of character is 6",
      ZIP_MAX_LENGTH: "The max allowed number of character is 6",
      ZIP_INVALID: "Zip is not valid",
    },
    VTO: {
      NO_OF_USER: "No. of users is required",
      END_TIME: "End time is required",
      START_TME: "Departure time is required",
      DATE: "Date is required",
      DEPARTMENT_ID: "Department is required",
      NO_Of_USER_PATTERN: "No. of users between 1 to 20",
      SHIFT_ID: "Shift is required",
      NO_Of_USER_FLEX_PATTERN: "No. of users between 1 to 50",
    },
    CALL_IN: {
      Name: "Name is required",
      CALL_OFF_FOR: "Reason is required",
      CALL_OFF_ON: "Call-off On is required",
      OTHER_REASON: "Explain other reason is required",
      NO_Of_USER_PATTERN: "No. of users between 1 to 20",
      SHIFT_ID: "Shift is required",
      MODULE_REQ: "Module is required",
    },
    TIME_OFF: {
      TIME_OFF_TYPE: "Time-Off Type is required",
      DEFAULT_SEED_AT_HIRE: "default Seed at Hire is required",
      DEFAULT_ALLOCATION: "default Allocation is required",
      TIME_OFF_CLASS_REQ: "Time-Off Class is required",
      MAXIMUM_ACCUMMULATION: "Maximum Accummulation is required",
      USERNAME_REQUIRED: "Username is required",
      DATE_TIME_END_REQUIRED: "Date time-off taken is required",
      TIME_OFF_QUANTITY: "Time-off Quantity is required",
      GREATER_THAN_ZERO: "Value must be greater than 0",
    },
    TIME_OFF_REQUEST: {
      TIME_OFF_TYPE: "Time-Off Type is required",
      DURATION: "Duration is required",
      TIME: "Time is required",
      START_TIME: "Time-Off Start Date is required",
      END_TIME: "Time-Off End Date is required",
      TOTAL_REQUESTED_HOURS: "Total Requested Hours is required",
    },
    DEPARTMENT_MAPPING: {
      FUNCTION: "Please select a function",
      MODULE: "Please select a Module",
      DEPARTMENT: "Please select a department",
      SUBDEPARTMENT: "Plaese select a sub-department",
    },
    Module_Traning: {
      USERNAME: "Username is required",
      MODULEREQ: "Module name is required",
      MODULE: "Please select a module",
      TRAINED_DATE: "Trained date is required",
      TRAINER: "Trainer Name is required",
      STATUS: "Status is required",
      FUNCTIONREQ: "Function name is required",
      FUNCTION_NAME: "Please select a function",
      DECRITION: "Decription is required",
    },
    TRAINING_MODULE: {
      FUNCTION_NAME: "Function name is required",
    },
    EARLY_GO: {
      END_TIME: "End time is required",
      START_TME: "Start time is required",
      DATE: "Date is required",
      REASON: "Reason is required",
      UTO_HOURS: "UTO hours is required",
      SELECT_A_OPTION: "Please select one option",
    },
    LATE_IN: {
      END_TIME: "End time is required",
      START_TME: "Start time is required",
      DATE: "Date is required",
      REASON: "Reason is required",
      UTO_HOURS: "UTO hours is required",
      SELECT_A_OPTION: "Please select one option",
    },
    KIOSK: {
      LOCATION: "Location is required",
      KIOSK_NAME: "Kiosk Name is required",
      HAREWARE_IDENTIFICATION: "Hardware Identification is required",
    },
    PUNCHES: {
      PUNCH_TIME: "Punch Time is required",
      PUNCH_TYPE: "Punch Type is required",
      ADJUST_PUNCH_TIME: "Adjust punch time is required",
      SOURCE: "Source is required",
      COMPLIANCE: "Compliance is required",
      PUNCH_LOCATION: "Punch Location is required",
    },
    USER_VALIDATION: {
      USERNAME: "Username is required",
      EMPLOYEEID: "EmployeeId is required",
      EMPLOYEETYPE: "EmployeeType is required",
      PTOELIGIBLE: "PTOEligible is required",
      HOLIDAYPAYELIGIBLE: "HolidayPayEligible is required",
      OTELIGIBLE: "OTEligible is required",
      HOURS: "Hours is required",
      INVALID_HOURS:"Please enter Valid Hours"
    },
    MARKET_CAMPAIGN: {
      NAME: "Name is required",
      NUMBER: "Number is required ",
      OPTION: "Please select one option",
      COMPANYNAME: "Your company name is required",
      CONTACT_PERSON_NAME: "Contact person name is required",
      CONTACT_PERSON_NUMBER: "Contact person number is required",
      CONTACT_PERSON_EMAIL: "Contact person email is required",
    },

    SETTING: {
      DESCRIPTION_REQUIRED: "Description is required",
      ENABLE_REQUIRED: "Enable is required",
      OFFER_TYPE_REQUIRED: "Module is required",
      SETTING_TYPE_REQUIRED: "Setting Type is required",
      VOT_CUT_OFF_HOURS: "Cut Off Hours is required",
      VOT_CUT_OFF_HOURS_Pattern: "Please add valid hours",
    },
    TRANSFER_REQUEST: {
      DEPARTMENT_REQUIRED: "Department is required",
      SUB_DEPARTMENT_REQUIRED: "Sub Department is required",
      SHIFT_TO_WORK_REQUIRED: "Shift is required",
      PREFRENCE_ID_REQUIRED: "Prefence is required",
      IS_PIT_REQUIRED: "PIT is required",
    },

    TRANSFER_APPROVE_REQUEST: {
      START_DATE_REQUIRED: "Start Date is required",
    },

    DENIAL_REQUEST: {
      REASON_REQUIRED: "Reason is required",
    },
    LAUNCHKIOSK: {
      USERNAME_REQUIRED: "Username is required",
    },
    SUBSCRIPTION_EMAIL: {
      EMAIL_REQUIRED: "Email is required",
    },
    CONTACT_US: {
      COMPANY_REQUIRED: "Company is required",
      NAME_REQUIRED: "Name is required",
      EMAIL_REQUIRED: "Email is required",
      PHONE_REQUIRED: "Phone is required",
      MESSAGE_REQUIRED: "Message is required",
    },
    ROLE: {
      USER_ROLE_REQUIRED: "User Role is required",
    },
    PUNCHESBYHR: {
      PUNCH_TIME: "Punch Time is required",
      PUNCH_TYPE: "Punch Type is required",
      SCHEDULED_OR_USER: "Select User",
      USERNAME: "User is required",
      PUNCH_TIME_BYHRS: "Can not enter future time in Time punch",
      REASON: "Reason is required",
    },
  },


  LOCATION_ADD_SUCCESS_MSG: "Location added successfully!",
  LOCATION_UPDATE_SUCESS_MSG: "Location updated successfully!",
  LOCATION_DELETE_SUCCESS_MSG: "Location deleted successfully!",
  COMPANYID: "companyId",
  COMPANY_ADD_SUCCESS_MSG: "Company added successfully!",
  COMPANY_UPDATE_SUCESS_MSG: "Company updated successfully!",
  COMPANY_DELETE_SUCCESS_MSG: "Company deleted successfully!",
  DEPARTMENT_ADD_SUCCESS_MSG: "Department added successfully!",
  DEPARTMENT_UPDATE_SUCESS_MSG: "Department updated successfully!",
  DEPARTMENT_DELETE_SUCESS_MSG: "Department deleted successfully!",
  SUB_DEPARTMENT_ADD_SUCCESS_MSG: "Sub Department added successfully!",
  SUB_DEPARTMENT_UPDATE_SUCESS_MSG: "Sub Department updated successfully!",
  SUB_DEPARTMENT_DELETE_SUCESS_MSG: "Sub Department deleted successfully!",
  TERMS_CONDITIONID: "termsAndConditionsId",
  TERMS_CONDITION_ADD_SUCCESS_MSG: "Terms and condition added successfully!",
  TERMS_CONDITION_UPDATE_SUCCESS_MSG:
    "Terms and condition updated successfully!",
  TERMS_CONDITION_DELETE_SUCCESS_MSG:
    "Terms and condition deleted successfully!",
  SHIFT_ADD_SUCCESS_MSG: "Shift added successfully!",
  SHIFT_UPDATE_SUCCESS_MSG: "Shift updated successfully!",
  SHIFT_DELETE_SUCCESS_MSG: "Shift deleted successfully!",
  USER_ADD_SUCCESS_MSG: "User added successfully!",
  USER_UPDATE_SUCESS_MSG: "User updated successfully!",
  USER_DELETE_SUCCESS_MSG: "User deleted successfully!",
  EMAIL_ADD_SUCCESS_MSG: "Please check your mail for reset the password!",
  PROFILE_SUCCESS_MSG: "Profile updated successfully!",
  PASSWORD_UPDATE_SUCCESS_MSG: "Password updated successfully!",
  COMPANY_NOT_EXIST_MSG: "Company not available",
  OFFER_ADD_SUCCESS_MSG: "Request added successfully!",
  OFFER_UPDATE_SUCCESS_MSG: "Request updated successfully!",
  OFFER_DELETE_SUCCESS_MSG: "Request deleted successfully!",
  OFFER_APPROVE_SUCCESS_MSG: "Request approved successfully!",
  OFFER_REJECT_SUCCESS_MSG: "Request rejected successfully!",
  PLAN_UPDATE_SUCCESS_MSG: "Plan updated successfully!",
  LOGIN_ERROR_MSG: "Username and password not valid",
  OFFER_ACCEPT_MSG: "Offer accepted successfully!",
  FILE_UPLOAD_SUCCESS_MSG: "We will notify via e-mail once data imported!",
  INVOICE_PAIDED_SUCCESS_MSG: "Invoice paid successfully!",
  INVOICE_CONFIRM_MSG: "Are you sure you want to paid ?",
  INVOICE_DUE_MSG: "Your invoice have due status",
  INVOICE_SEARCH_MSG: `Company not selected so you can't get search result`,
  VOT_REQUEST_ADD_MSG: "VOT request added successfully!",
  VOT_REQUEST_UPDATE_MSG: "VOT request updated successfully!",
  PLAN_EXIPRY_MSG: `Credit card expires too soon, please use a different card.`,
  VOT_REQUEST_APPROVE_MSG: "VOT request approved successfully!",
  VOT_REQUEST_DENIED_MSG: "VOT request denied successfully!",
  TERMS_CONDITION_MSG: `You must agree to terms and conditions to proceed`,
  VTO_REQUEST_ADD_SUCCESS_MSG: "Vto request added successfully!",
  VTO_REQUEST_UPDATE_SUCCESS_MSG: "Vto request updated successfully!",
  VTO_REQUEST_APPROVE_SUCCESS_MSG: "Vto request approved successfully!",
  VTO_REQUEST_DENIED_SUCCESS_MSG: "Vto request denied successfully!",
  CALL_OFF_REQUEST_ADD_SUCCESS_MSG: "Call-Off request added successfully!",
  CALL_OFF_REQUEST_UPDATE_SUCCESS_MSG: "Call-Off request updated successfully!",
  VTO_REQUEST_ACCEPT_SUCCESS_MSG: "Vto request accept successfully!",
  VTO_REQUEST_DELETE_SUCCESS_MSG: "Vto request deleted successfully!",
  CALL_OFF_REQUEST_APPROVE_MSG: "Call-Off request processed successfully!",
  CALL_OFF_REASON_ADD_MSG: "Call-Off reason added successfully!",
  CALL_OFF_REASON_EDIT_MSG: "Call-Off reason updated successfully!",
  REASON_ADD_MSG: 'Reason added successfully!',
  REASON_UPDATE_MSG: 'Reason updated successfully!',
  CALL_OFF_REASON_DELETE_MSG: "Call-Off reason deleted successfully!",
  TIME_OFF_CONFIG_ADD_MSG: "Time-Off Configuration added successfully!",
  TIME_OFF_CONFIG_EDIT_MSG: "Time-Off Configuration updated successfully!",
  TIME_OFF_CONFIG_DELETE_MSG: "Time-Off Configuration deleted successfully!",
  TIME_OFF_REQ_ADD_MSG: "Time-Off Request added successfully!",
  TIME_OFF_REQ_EDIT_MSG: "Time-Off Request updated successfully!",
  TIME_OFF_REQ_DELETE_MSG: "Time-Off Request deleted successfully!",
  TIME_OFF_REQUEST_APPROVE_MSG: "Time-Off Requests approved successfully!",
  TIME_OFF_REQUEST_REJECT_MSG: "Time-Off Requests denied successfully!",
  VTO_REQUEST_PROCESS_SUCCESS_MSG: "Vto request processed successfully!",
  SHIFT_START_TIME_CONFIGURE:
    "Please configure sencond half start time in shift",
  SHIFT_END_TIME_CONFIGURE: "Please configure first half end time in shift",
  EARLY_OUT_REQUEST_UPDATE_SUCCESS_MSG:
    "Early-Out request updated successfully!",
  EARLY_OUT_REQUEST_PROCESS_SUCCESS_MSG:
    "Early-Out request processed successfully!",
  EARLY_OUT_REQUEST_ADD_SUCCESS_MSG: "Early-Out request added successfully!",
  USER_VALIDATION_ADD_SUCCESS_MSG: "User added successfully!",
  USER_VALIDATION_UPDATE_SUCCESS_MSG: "User updated successfully!",
  USER_VALIDATION_DELETE_SUCCESS_MSG: "User deleted successfully!",
  FLEX_REQUEST_ADD_SUCCESS_MSG: "Flex request added successfully!",
  FLEX_REQUEST_UPDATE_SUCCESS_MSG: "Flex request updated successfully!",
  FLEX_REQUEST_APPROVE_SUCCESS_MSG: "Flex request approved successfully!",
  FLEX_REQUEST_DENIED_SUCCESS_MSG: "Flex request denied successfully!",
  FLEX_REQUEST_DELETE_SUCCESS_MSG: "Flex request deleted successfully!",
  FLEX_REQUEST_ACCEPT_SUCCESS_MSG: "Flex request accepted successfully!",
  FLEX_REQUEST_PROCESS_SUCCESS_MSG: "Flex request processed successfully!",
  SETTING_ADD_SUCESS_MSG: "Setting added successfully!",
  SETTING_UPDATE_SUCESS_MSG: "Setting updated successfully!",
  TRANSFER_REQUEST_ADD_MSG: "Transfer request added successfully!",
  TRANSFER_REQUEST_UPDATE_MSG: "Transfer request updated successfully!",
  TRANSFER_REQUEST_DENY_MSG: "Transfer request denied successfully!",
  TRANSFER_REQUEST_APPROVE_MSG: "Transfer Request approved successfully!",
  TRANSFER_OFFER_ACCEPT_MSG: "Offer accepted successfully!",
  TRANSFER_OFFER_NOT_INTERESTED_MSG: "Offer rejected successfully!",
  TRANSFER_REQUEST_PROCESS_MSG:
    "Transfer Request request processed successfully!",
  FUNCTION_ADD_SUCESS_MSG: "Function added successfully!",
  FUNCTION_UPDATE_SUCESS_MSG: "Function updated successfully!",
  FUNCTION_DELETE_SUCESS_MSG: "Function deleted successfully!",
  MODULE_ADD_SUCESS_MSG: "Module added successfully!",
  MODULE_UPDATE_SUCESS_MSG: "Module updated successfully!",
  MODULE_DELETE_SUCESS_MSG: "Module deleted successfully!",
  DEPARTMENT_MAPPING_ADD_SUCESS_MSG: "Department Mapping added successfully!",
  DEPARTMENT_MAPPING_UPDATE_SUCESS_MSG:
    "Department Mapping updated successfully!",
  DEPARTMENT_MAPPING_DELETE_SUCESS_MSG:
    "Department Mapping deleted successfully!",
  TRAINING_ADD_SUCCESS_MSG: "Training added successfully!",
  TRAINING_UPDATE_SUCCESS_MSG: "Training updated successfully!",
  TRAINING_DELETE_SUCCESS_MSG: "Training deleted successfully!",
  TIME_OFF_DELETE_SUCCESS_MSG: "Time-Off request deleted successfully!",
  TIME_OFF_VALID_VALUE: "Please enter a valid int value",
  LATE_IN_REQUEST_UPDATE_SUCCESS_MSG:
    "Late-In request updated successfully!",
  LATE_IN_REQUEST_PROCESS_SUCCESS_MSG:
    "Late-In request processed successfully!",
  LATE_IN_REQUEST_ADD_SUCCESS_MSG: "Late-In request added successfully!",
  KIOSK_REQUEST_ADD_SUCCESS_MSG: "Kiosk added successfully! ",
  KIOSK_REQUEST_UPDATE_SUCCESS_MSG: "Kiosk updated successfully! ",
  KIOSK_REQUEST_DELETE_SUCCESS_MSG: "Kiosk deleted successfully! ",
  TIME_PUNCHES_ADD_SUCCESS_MSG: "Time Punches added successfully!",
  TIME_PUNCHES_UPDATE_SUCCESS_MSG: "Time Punches updated successfully!",
  TIME_PUNCHES_DELETE_SUCCESS_MSG: "Time Punches deleted successfully!",
  TIME_PUNCHES_APPROVE_MSG: "Time Punches approved successfully!",
  TIME_PUNCHES_REJECT_MSG: "Time Punches denied successfully!",
  NO_AVAILABLE_SHIFT: "User is not working on selected date",
  REVIEW_REQUEST_UPDATE_SUCCESS_MSG:
  "Review request updated successfully!",
  REVIEW_REQUEST_ADD_SUCCESS_MSG: "Review request added successfully!",
  TIME_PUNCHES_NO_CHANGE_DETECTED_MSG: "No change detected! Request is not be saved",
};

export const URL_ROUTES = {
  LOGIN: "",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  SIGN_UP: "/sign-up",
};

export const drpSetting = {
  dropdownSettings: {
    singleSelection: false,
    idField: "id",
    textField: "text",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: false,
  },
};

export const typeField = [
  { id: 1, typeValue: "Schedule Adjust" },
  { id: 2, typeValue: "Offer Terms and conditions" },
  { id: 3, typeValue: "Manager declaimer" },
  { id: 4, typeValue: "VOT request" },
  { id: 5, typeValue: "UTO Warning" },
  { id: 6, typeValue: "Call-off UTO Message" },
  { id: 7, typeValue: "Early-Out UTO Message" }
];

export const timeOffClassList = [
  {
    id: 1,
    value: "Paid",
  },
  {
    id: 2,
    value: "UnPaid",
  },
];

export const dropdownList = [
  { id: 1, text: "Email" },
  { id: 2, text: "In-App Notification" },
];

export const trainingStatus = [
  { id: 1, text: "Active" },
  { id: 2, text: "InActive" },
  { id: 3, text: "Canceled" },
];

export const roleList = [
  { id: 1, text: "Admin" },
  { id: 2, text: "Purchaser" },
  { id: 3, text: "HrAdmin" },
  { id: 4, text: "Manager" },
  { id: 5, text: "User" },
  { id: 6, text: "LearningAdmin" },
];

export const reasonType = [
  { reasonType: 1, name: "Call-Off" },
  { reasonType: 2, name: "Early Out" },
];

export const bsConfig = {
  dateInputFormat: "MM-DD-YYYY",
  //dateInputFormat: 'YYYY-MM-DD',
  containerClass: "theme-blue",
  showWeekNumbers: false,
  selectFromOtherMonth: true,
};
export const bsConfig_date_time = {
  dateInputFormat: "MM-DD-YYYY HH:mm:ss",
  //dateInputFormat: 'YYYY-MM-DD',
  containerClass: "theme-blue",
  showWeekNumbers: false,
  selectFromOtherMonth: true,
};

export const bsConfig_withMinDate = {
  dateInputFormat: "MM-DD-YYYY",
  containerClass: "theme-blue",
  showWeekNumbers: false,
  selectFromOtherMonth: true,
  minDate: new Date()
};

export const timePickingSystemList = [{ id: 1, systemName: "KRONOS" }];

export const emplyeeTypeList = [
  {id:1, value:"Full Time"},
  {id:2, value:"Part Time"}
];

export const pageSizing = ["10", "25", "50", "100"];

export const phoneMask = { mask: "{+1} 000-000-0000" };

export const Status = [
  { id: 0, value: "Due" },
  { id: 1, value: "Paid" },
];

export const timeList = [
  { id: 1, value: 'Full day' },
  { id: 2, value: 'First half' },
  { id: 3, value: 'Second half' }]

export const SubscriptionType = [
  { id: 1, value: "Shift Swap Module" },
  { id: 2, value: "VOT Request Module" },
  { id: 3, value: "Transfer Request Module" },
  { id: 4, value: "Training Request Module" },
  { id: 5, value: "VTO Module" },
  { id: 6, value: "Call-Off Module" },
  { id: 7, value: "Early Out Module" },
  { id: 8, value: "Flex Work Module" },
  { id: 9, value: "Time-Tracking Module" },
  { id: 10, value: "Late In Module" },
  { id: 11, value: "Clock-In-Out Module" },
  { id: 12, value: "Employee Review Module" }
];

export const ModuleType = [
  { id: 0, value: "All Module" },
  { id: 2, value: "VOT Request Module" },
  { id: 6, value: "Call-Off Module" },
  { id: 7, value: "Early Out Module" },
  { id: 10, value: "Late In Module" },
  { id: 11, value: "ClockInOut" },
  { id: 12, value: "Employee Review Module" }
];

export const SettingType = [
  //NOTE: Do check CommonSettingList in backend and update accordingly if you make any changes in this list 
  { id: 1, value: "PaidTimeOff" },
  { id: 2, value: "FMLA" },
  { id: 3, value: "Hour Early" },
  { id: 4, value: "Hour Late" },
  { id: 5, value: "VOT Cut-Off Hours" },
  { id: 6, value: "Call-off intervention" },
  { id: 7, value: "Early-out intervention" },
  { id: 8, value: "Early-Out UTO Message" },
  { id: 9, value: "UTO system for attendance" },
  { id: 10, value: "Lunchtime clock-in/out" },
  { id: 11, value: "Mobile check-in/out permitted" },
  { id: 12, value: "Point system for attendance" },
  { id: 13, value: "Clock-in grace period" },
  { id: 14, value: "Clock-out grace period" },
  { id: 15, value: "Allow administrators to log in as user" },
  { id: 16, value: "Enable Text messaging" },
  { id: 17, value: "9 blocker rating system for employee review" },
  { id: 18, value: "9 blocker system horizontal scale" },
  { id: 19, value: "9 blocker system vertical scale" },
  { id: 20, value: "9 blocker rating visible to employee" },
];

export const MarketingCampaignOptionList = [
  {
    id: 1,
    value:
      "I am a business owner, interested in exploring CoverMyWork.",
  },
  {
    id: 2,
    value: "I would like to refer CoverMyWork to my company",
  },
];
export const vtoType = {
  instant: 1,
  managerOffer: 2,
  myRequest: 3,
};

export const flexType = {
  instantHROffer: 1,
  hrOffer: 2,
  myRequest: 3,
};

export enum ContentEnum {
  TNC = 1,
  PrivacyPolicy = 2,
}

export enum OfferTypesEnum {
  ShiftSwapModule = 1,
  VOTRequestModule = 2,
  TransferRequestModule = 3,
  TrainingRequestModule = 4,
  VTOModule = 5,
  CallOffModule = 6,
  EarlyOutModule = 7,
  FlexWorkModule = 8,
  TimeKeepingModule = 9,
  LateInModule = 10,
  ClockInOutModule=11,
  EmployeeReviewModule = 12,
}

export const ComplianceEnum = [
  { id: 1, value: "OnTime" },
  { id: 2, value: "Late" },
  { id: 3, value: "Early" },
  { id: 4, value: "UnScheduled" },
  { id: 5, value: "AdditionalPunch" },

];

export const PunchLocationEnum = [
  { id: 1, value: "OnSite" },
  { id: 2, value: "OffSite" },
];
export const PunchTypeEnum = [
  { id: 1, value: "ClockIn" },
  { id: 2, value: "ClockOut" },
];
export const SourceEnum = [
  { id: 1, value: "Mobile" },
  { id: 2, value: "Kiosk" },
  { id: 3, value: "Manual" },
];

export const Duration = [
  { id: 1, value: "Full Day" },
  { id: 2, value: "First Half" },
  { id: 3, value: "Second Half" },
  { id: 4, value: "Specific Times" },
];

export const loginRoleList = [
  { id: 2, role: "purchaser", value: "Continue as Purchaser" },
  { id: 3, role: "HrAdmin", value: "Continue as HrAdmin" },
  { id: 4, role: "Manager", value: "Continue as Manager" },
  { id: 6, role: "LearningAdmin", value: "Continue as Learning Admin" },
  { id: 5, role: "User", value: "login as an end-user" },
];

export const punchTimeUser = [
  { id:1, role: "Specific user", value:"specific-user" },
  { id:2, role: "All scheduled users", value:"all-scheduled-users"}
]
export const ViewEnum= [
  { id: 1, value: "Period Summary"},
  { id: 2, value: "Daily view" },
  { id: 4, value: "Detail View" },
];

export const ReviewTypeEnum = [
  { id: 1, value: "Monthly" },
  { id: 2, value: "Quarterly" },
  { id: 3, value: "MidYear" },
  { id: 4, value: "Annual" },
  { id: 5, value: "Adhoc" },
];

export const submitFeedBackEnum = [
  { id: 1, value: "A New idea" },
  { id: 2, value: "Enhance functionality" },
  { id: 3, value: "Not working" },
]
