import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/constant/constants';
import { FormService } from 'src/app/core/form.service';
import { ModuleFunctionService } from 'src/app/service/moduleFunction.service';

@Component({
  selector: 'app-add-edit-function-training-config',
  templateUrl: './add-edit-function-training-config.component.html',
  styleUrls: ['./add-edit-function-training-config.component.scss']
})
export class AddEditFunctionTrainingConfigComponent implements OnInit {
  @Input() functionTraining: any;
  @Output() closeRequest = new EventEmitter<boolean>();
  public functionConfig: any;
  messageList: any = {
    functionName: String,
    functionDescription: String,
  };
  constructor(private formService: FormService,
    private moduleFunctionService : ModuleFunctionService,
    private toaster: ToastrService) { }

  ngOnInit() {
    this.initializeForm();
    this.initializeMessages();
  }
  initializeForm() {
    this.functionConfig = new FormGroup({
      functionId: new FormControl(!!this.functionTraining ? this.functionTraining.functionId : 0),
      functionName: new FormControl(!!this.functionTraining ? this.functionTraining.functionName : '', Validators.required),
      functionDescription: new FormControl(!!this.functionTraining ? this.functionTraining.functionDescription : '', Validators.required),
      companyId :new FormControl(Number(localStorage.getItem(Constants.COMPANYID))),
      isActive:new FormControl(true),
      deletedBy:new FormControl(),
      recordedByID : new FormControl(!!this.functionTraining ? this.functionTraining.recordedByID : ''),
      
    })
  }
  initializeMessages() {
    this.messageList.functionName = {
      required: Constants.VALIDATION_MSG.Module_Traning.FUNCTIONREQ
    };
    this.messageList.functionDescription = {
      required: Constants.VALIDATION_MSG.Module_Traning.DECRITION
    };
  }
  cancel() {
    this.closeRequest.emit(false);
  }
  value(controlName: string) {
    return this.control(controlName).value;
  }
  control(controlName: string): AbstractControl {
    return this.functionConfig.get(controlName);
  }
  onSubmit() {
    this.formService.markFormGroupTouched(this.functionConfig);
    if (this.functionConfig.invalid) {
      return;
    }
    const saveMethod = this.functionConfig.controls.functionId.value > 0 ? this.moduleFunctionService.updateFunction(this.functionConfig.value)
      : this.moduleFunctionService.addFunction(this.functionConfig.value);
    saveMethod.then((res) => {
      if (res['Success']) {
        this.toaster.success(this.functionConfig.controls.functionId.value > 0 ? Constants.FUNCTION_UPDATE_SUCESS_MSG : Constants.FUNCTION_ADD_SUCESS_MSG);
        this.closeRequest.emit(true);
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { this.closeRequest.emit(false); });
  }

}
