import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { defaultRoutes } from '../core/auth/default-routes';
import { Constants } from '../constant/constants';
import { ToastrService } from 'ngx-toastr';
import { ChangePassword } from '../models/changepassword.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormService } from '../core/form.service';
import { ChangepasswordService } from '../service/changepassword.service';
import { ValidationService } from '../service/validation.service';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  isShowConfirmPassword = false;
  isShowNewPassword = false;
  public eyeShow = false;
  public messageList: any = new ChangePassword();
  constructor(private router: Router, private toaster: ToastrService,
    private formService: FormService,
    private changePasswordService: ChangepasswordService,
    public validationService: ValidationService,
    private fb: FormBuilder, private utilityService: UtilityService) { }


  ngOnInit() {
    this.initializeChangePasswordForm();
    this.initializeMessages();
    this.utilityService.hideLoading();
  }

  initializeChangePasswordForm() {
    this.changePasswordForm = this.fb.group({
      currentPassword: new FormControl('', [Validators.required, , Validators.minLength(6), Validators.maxLength(16)]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]),
      confirmPassword: new FormControl('', [Validators.required]),
      userName: new FormControl(localStorage.getItem(Constants.USERNAME)),
      isChangePassword: new FormControl(true),
      companyId: new FormControl(Number(localStorage.getItem(Constants.COMPANYID))),
    },
      {
        validators: this.validationService.confirmedValidator(
          'newPassword',
          'confirmPassword',
        )
      },
    );
  }

  initializeMessages() {
    this.messageList.currentPassword = {
      required: Constants.VALIDATION_MSG.CHANGEPASSWORD.CURRENT_PASSWORD_REQUIRED
    };
    this.messageList.newPassword = {
      required: Constants.VALIDATION_MSG.CHANGEPASSWORD.NEW_PASSWORD_REQUIRED,
    };
    this.messageList.confirmPassword = {
      required: Constants.VALIDATION_MSG.CHANGEPASSWORD.CONFIRM_PASSWORD_REQUIRED,
      confirmedValidator: Constants.VALIDATION_MSG.CHANGEPASSWORD.PASSWORD_CONFIRM_PASSWORD_NOT_MATCH
    };
  }

  toggleEyeShow() {
    this.eyeShow = !this.eyeShow;
  }

  toggleisShowNewPassword() {
    this.isShowNewPassword = !this.isShowNewPassword;
  }

  toggleisShowConfirmPassword() {
    this.isShowConfirmPassword = !this.isShowConfirmPassword;
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.changePasswordForm);
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.changePasswordService.updatePassword(this.changePasswordForm.value).then((res) => {
      if (res['Success']) {
        this.toaster.success(Constants.PASSWORD_UPDATE_SUCCESS_MSG);
        this.router.navigate(['']);
      } else {
        this.toaster.error(res['Message']);
      }
      //this.router.navigate([defaultRoutes[Number(localStorage.getItem(Constants.ROLE))]]);
    }, err => { });
  }

  cancel() {
    this.router.navigate([defaultRoutes[Number(localStorage.getItem(Constants.ROLE))]]);
  }
}
