import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/constant/constants';
import { FormGroup, FormControl } from '@angular/forms';
import { VtoRequestService } from 'src/app/service/vto-request.service';

@Component({
  selector: 'app-instant-vto-request',
  templateUrl: './instant-vto-request.component.html',
  styleUrls: ['./instant-vto-request.component.scss']
})
export class InstantVtoRequestComponent implements OnInit {
  @Output() closeRequest = new EventEmitter<boolean>();
  public offerForm: FormGroup;

  constructor(private toaster: ToastrService,
    private vtoService: VtoRequestService) { }

  ngOnInit() {
    this.initializeOfferForm();
  }

  async initializeOfferForm() {
    this.offerForm = new FormGroup(
      {
        userName: new FormControl(''),
        employeeId: new FormControl(''),
      },
    );
  }

  closeModal() {
    this.closeRequest.emit(false);
  }

  onSubmit() {
    var newDate = new Date();
    const data = {
      userName: this.offerForm.controls.userName.value,
      employeeId: this.offerForm.controls.employeeId.value,
      vtoStartTime: newDate.getHours() +":" + newDate.getMinutes()
    }
    this.vtoService.instantVtoRequest(data).then(response => {
      if (response['Success']) {
        this.toaster.success(Constants.VTO_REQUEST_ADD_SUCCESS_MSG);
        this.closeRequest.emit(true);
      } else {
        this.toaster.error(response['Message']);
      }

    })
  }

  get isFilled(): boolean {
    if (!!this.offerForm.controls.employeeId.value || !!this.offerForm.controls.userName.value) {
      return false;
    }
    return true;
  }

  onEmployeeChange(event) {
    if (!!event.currentTarget.value) {
      this.offerForm.get('userName').disable();
    } else {
      this.offerForm.get('employeeId').enable();
      this.offerForm.get('userName').enable();
    }
  }

  onUsernameChange(event) {
    if (!!event.currentTarget.value) {
      this.offerForm.get('employeeId').disable();
    } else {
      this.offerForm.get('employeeId').enable();
      this.offerForm.get('userName').enable();
    }
  }
}
