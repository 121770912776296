import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SignupRoutingModule } from './signup-routing.module';
import { SignupComponent } from './signup.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AuthService } from '../core/auth/auth.service';
import { LocationService } from '../service/location.service';
import { ShiftService } from '../service/shift.service';
import { DepartmentService } from '../service/department.service';
import { SharedModule } from '../shared/shared/shared.module';
import { CompanyService } from '../service/company.service';
import { ForgotpasswordService } from '../service/forgotpassword.service';



@NgModule({
  declarations: [SignupComponent],
  imports: [
    CommonModule,
    SignupRoutingModule,
    NgMultiSelectDropDownModule,
    SharedModule
  ],
  providers: [AuthService, LocationService, ShiftService, DepartmentService, CompanyService, ForgotpasswordService]
})
export class SignupModule { }
