export class Company {
  companyId: number = 0;
  companyName: string;
  locationId: number;
  pricing: Pricing;
  isActive = true;
  
  //pricingId: number;
}
export class Pricing {
  pricingId: number;
  setUpCost: number;
  costForFirstUsers: number;
  numberOfUsers: number;
  costForRestUsers: number;
  isActive: true;
  priceForEnablingMsg: number;
}

export enum SubscriptionEnum {
  ShiftSwapModule = 1,
  VOTRequestModule = 2,
  TransferRequestModule = 3,
  TrainingRequestModule = 4,
}
