import { Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../constant/constants';
import { EmployeeRosterService } from './../service/EmployeeRoster.service';

@Component({
  selector: 'app-user-validation',
  templateUrl: './user-validation.component.html',
  styleUrls: ['./user-validation.component.scss']
})
export class UserValidationComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  userValidationList = [];
  modalRef: BsModalRef;
  totalItems: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  columns = [];
  searchSort: any;
  page: any;
  userValidation: any;
  companyId: number;
  isConfirmed: boolean = false;
  deleteId: string;

  constructor(
    private modalService: BsModalService,
    private toaster: ToastrService,
    private employeeRosterService: EmployeeRosterService,
    private ngZone: NgZone) {
    this.columns = ['username', 'employeeId', 'EmployeeTypeStr', 'PTOEligible', 'HolidayPayEligible', 'OTEligible', 'Hours'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.setPage({ offset: 0 });

  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getuserValidationList(this.page.pageNumber + 1);
  }
  getuserValidationList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod = this.employeeRosterService.getEmployeeRosterList(currentPage, this.companyId)
      getMethod.then((res: any) => {
        if (res['Success']) {
          this.userValidationList = res.Data.results
          this.totalItems = res.Data.totalNumberOfRecords;
        } else { this.userValidationList = []; this.totalItems = 0; }
      }, err => { this.userValidationList = []; });
    }
  }
  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.userValidation = data;
    } else {
      this.userValidation = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clear();
      }
    }
  }
  filterData() {
    const getMethod = this.employeeRosterService.DataFilter(this.searchSort)
    getMethod.then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.userValidationList = res['data'];

        this.totalItems = res['recordsFiltered'];
      } else { this.userValidationList = []; this.totalItems = 0; }
    }, err => { this.userValidationList = []; this.totalItems = 0; });
  }
  clear() {
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }
  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }
  closeModal(event) {
    this.ngZone.run(() => {
      var self = this;
      // now you are in a zone
      if (event) {
        self.setPage({ offset: this.page.pageNumber });
      }
    });
    this.modalRef.hide();
  }
  onClickDelete(data) {
    this.deleteId = data.employeeRosterId;
    this.isConfirmed = true;
  }

  delete(id) {
    this.isConfirmed = false;
    this.employeeRosterService.deleteUserValidation(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.USER_VALIDATION_DELETE_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      } else { this.toaster.error(res['Message']); }
    }, err => { });
  }
  close(event) {
    this.isConfirmed = false;
  }
}
