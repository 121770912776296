export class Shift {
    shiftId: number = 0;
    companyId: number;
    title: string;
    shiftCode: string;
    departmentId: number;
    isActive = true;
    shiftWeekDay: [ShiftWeekDay]
}

export class ShiftWeekDay {
    shiftWeekDayId: number = 0;
    weekday: number;
    startTime: string;
    endTime: string;
    shiftId: number;
    isActive = true;
    paidHours: any;
    firstHalfEndTime: string;
    secondHalfStartTime: string;
}