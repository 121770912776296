import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecoveryPasswordRoutingModule } from './recovery-password-routing.module';
import { RecoveryPasswordComponent } from './recovery-password.component';
import { SharedModule } from '../shared/shared/shared.module';


@NgModule({
  declarations: [RecoveryPasswordComponent],
  imports: [
    CommonModule,
    RecoveryPasswordRoutingModule,
    SharedModule
  ]
})
export class RecoveryPasswordModule { }
