import { Injectable } from '@angular/core';
import { API } from '../routes/api-routes';
import { ApiService } from './api.service';
import { Constants } from '../constant/constants';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  constructor(private apiService: ApiService) { }
  getReviewRequestList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.EMPLOYEE_REVIEW_REQUEST.getUserReviewRequestList}?userId=${localStorage.getItem(Constants.USERID)}&page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }
  addReviewRequest(data) {
    return this.apiService.post(
      `${API.EMPLOYEE_REVIEW_REQUEST.addReviewRequest}`,
      data,
      true,
    );
  }

  getReviewPendingHrRequestList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.EMPLOYEE_REVIEW_REQUEST.hrPendingReviewRequests}?companyId=${localStorage.getItem(Constants.COMPANYID)}&page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }
  getReviewClosedHrRequestList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.EMPLOYEE_REVIEW_REQUEST.ReviewClosedManagerRequestList}?companyId=${localStorage.getItem(Constants.COMPANYID)}&page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }
  userReviewDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.EMPLOYEE_REVIEW_REQUEST.userDataFilter}?userId=${localStorage.getItem(Constants.USERID)}`, data, true);
  }
  managerReviewPendingDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.EMPLOYEE_REVIEW_REQUEST.managerPendingDataFilter}?companyId=${localStorage.getItem(Constants.COMPANYID)}`, data, true);
  }
  managerReviewClosedDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.EMPLOYEE_REVIEW_REQUEST.managerClosedDataFilter}?companyId=${localStorage.getItem(Constants.COMPANYID)}`, data, true);
  }
  employeeReviewPendingDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.EMPLOYEE_REVIEW_REQUEST.reviewPendingDataFilter}?companyId=${localStorage.getItem(Constants.COMPANYID)}`, data, true);
  }
  employeeReviewClosedDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.EMPLOYEE_REVIEW_REQUEST.reviewClosedDataFilter}?companyId=${localStorage.getItem(Constants.COMPANYID)}`, data, true);
  }
  updateReviewRequestOffer(params) {
    return this.apiService.put(`${API.EMPLOYEE_REVIEW_REQUEST.updateReviewRequest}`, params, true);
  }
  RejectReviewOffers(id) {
    return this.apiService.delete(`${API.EMPLOYEE_REVIEW_REQUEST.deleteReviewRequest}?id=${id}`, true);
  }
  // rejectOffer(Id) {
  //   return this.apiService.put(`${API.OFFER_ROUTES.rejectOffer}?offerID=${Id}&rejectedBy=${localStorage.getItem(Constants.USERID)}`, null, true);
  // }

  downloadPendingExcelFile(data) {
    return this.apiService.post(`${API.EMPLOYEE_REVIEW_REQUEST.downloadPendingExcelFile}?companyId=${localStorage.getItem(Constants.COMPANYID)}`, data, true);
  }
  downloadClosedExcelFile(data) {
    return this.apiService.post(`${API.EMPLOYEE_REVIEW_REQUEST.downloadClosedExcelFile}?companyId=${localStorage.getItem(Constants.COMPANYID)}`, data, true);
  }
  cancelReviewUserRequest(offerType, message) {
    return this.apiService.post(
      `${API.OFFER_LOG.OfferLogEntry}?offerType=${offerType}&message=${message}`,
      null,
      true,
    );
  }
  getAllManagerList(companyId, locationId) {
    return this.apiService.get(
      `${API.USER_ROUTE.getAllManagerList}?companyId=` + companyId + `&locationId=` + locationId,
      true);
  }
  getAllUserList(userName, companyId) {
    return this.apiService.get(
      `${API.USER_ROUTE.getAllUserList}?userName=` + userName + `&companyId=` + companyId,
      true);
  }
}
