import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { SharedModule } from '../shared/shared/shared.module';
import { CompanyService } from '../service/company.service';
import { LoginlayoutComponent } from './loginlayout/loginlayout.component';
import {SwiperModule } from 'swiper/angular';
import { ScheduleRequestComponent } from './schedule-request/schedule-request.component';
import { ContactusComponent } from './contactus/contactus.component';
import { AllowRoleLoginComponent } from './allow-role-login/allow-role-login.component';
import { CaptchaService } from '../service/captcha.service';

@NgModule({
  declarations: [LoginComponent, LoginlayoutComponent, ScheduleRequestComponent, ContactusComponent, AllowRoleLoginComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    SharedModule,
    SwiperModule
  ],
  providers: [CompanyService, CaptchaService],
  entryComponents: [
    AllowRoleLoginComponent,
  ],
})
export class LoginModule { }
