import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from '../constant/constants';


@Injectable({
  providedIn: 'root'
})
export class AuthorizeLaunchService {

  constructor(private apiService: ApiService) { }

  authorizeLaunch(ipAddress) {
    return this.apiService.get(
      `${API.TIME_PUNCH_ROUTE.authorizePage}?ipAddress=${ipAddress}`,
      true,
      true,
    );
  }

  submitPunches(data) {
    return this.apiService.get(
      `${API.TIME_PUNCH_ROUTE.addLaunchPunches}?userName=` +data.userName + `&punchTime=` + data.punchTime + `&ipAddress=` + data.ipAddress,
      true,
      true,
    );
  }
}