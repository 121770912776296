import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InternalNetworkRoutingModule } from './internal-network-routing.module';
import { InternalNetworkComponent } from './internal-network.component';
import { AddEditComponent } from './add-edit/add-edit.component';
import { SharedModule } from '../shared/shared/shared.module';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { TermsConditionService } from '../service/terms-condition.service';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DirectiveModule } from '../shared/shared/directive/directive.module';



@NgModule({
  declarations: [InternalNetworkComponent, AddEditComponent],
  imports: [
    CommonModule,
    InternalNetworkRoutingModule,
    SharedModule,
    NgxBootstrapIconsModule.pick(allIcons),
    CKEditorModule,
    DirectiveModule
  ],
  providers: [TermsConditionService]
})
export class InternalNetworkModule { }
