import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LaunchKioskRoutingModule } from './launch-kiosk-routing.module';
import { SharedModule } from '../shared/shared/shared.module';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { LaunchKioskComponent } from './launch-kiosk.component';
import { FormsModule } from '@angular/forms';
import { DirectiveModule } from '../shared/shared/directive/directive.module';



@NgModule({
  declarations: [LaunchKioskComponent],
  imports: [
    CommonModule,
    LaunchKioskRoutingModule,
    SharedModule,
    FormsModule,
    NgxBootstrapIconsModule.pick(allIcons),
    DirectiveModule,
   
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe]
})
export class LaunchKioskModule { }
