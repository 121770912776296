import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../../constant/constants';
import { DepartmentService } from '../../service/department.service';
import { SubDepartment } from '../../models/subdepartment.model';
import { Role } from '../../models/role-model';

@Component({
  selector: 'app-sub-department-list',
  templateUrl: './sub-department-list.component.html',
  styleUrls: ['./sub-department-list.component.scss']
})
export class SubDepartmentListComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  subDepartment = new SubDepartment();
  subDepartmentList = [];
  modalRef: BsModalRef;
  totalItems: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  companyId: number;
  isConfirmed: boolean = false;
  deleteId: string;
  role: number;
  roleEnum = Role;
  searchSort: any;
  columns = [];
  isShowLink = false;
  
  constructor(private modalService: BsModalService,
    private toaster: ToastrService,
    private departmentService: DepartmentService) {
    this.columns = ['departmentName', 'companyName','subDepartmentName'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }
    
  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = this.role === this.roleEnum.superUser ? 0 : Number(localStorage.getItem(Constants.COMPANYID));
    this.setPage({ offset: 0 });
  }
    
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    //this.getDepartmentList(this.page.pageNumber + 1);
     this.getSubDepartmentList(this.page.pageNumber + 1);
  }
    
    // getsubDepartmentList(currentPage){
    //   this.subDepartmentList =[{
    //     companyId: 10039,
    //     createdBy: 223,
    //     createdDate: "2021-01-01T12:17:53.35",
    //     deletedBy: null,
    //     deletedDate: null,
    //     departmentId: 65,
    //     departmentName: "IT",
    //     subdepartmentName: "Development",
    //     isActive: true,
    //     modifiedBy: 223,
    //     modifiedDate: "2021-04-07T08:35:28.13"
    //   }];
    //   this.totalItems = this.subDepartmentList.length
    // }
    
    
    getSubDepartmentList(currentPage) {
      if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
        this.isShowLink = true;
        this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = currentPage;
        this.filterData();
      } else {
        const getMethod = this.role === Role.superUser ? this.departmentService.getDepartmentList(currentPage) :
          this.departmentService.getSubDepartmentListByCompanyId(currentPage, this.companyId)
        getMethod.then((res: any) => {
          if (res['Success']) {
            this.subDepartmentList = res.Data.results;
            this.totalItems = res.Data.totalNumberOfRecords;
          } else { this.subDepartmentList = []; this.totalItems = 0; }
        }, err => { this.subDepartmentList = []; });
      }
    }
    
    updateFilter(event) {
      if (event.key !== "Tab") {
        if (!!event.target.value) {
          this.isShowLink = true;
          this.searchSort.Search.Value = event.target.value;
          this.searchSort.Search.ColumnNameList = this.columns;
          this.searchSort.Page = this.page.pageNumber + 1;
          this.filterData();
        } else {
          this.isShowLink = false;
          this.clear()
        }
      }
    }
    
    openModal(template: TemplateRef<any>, data: any) {
      if (data) {
        this.subDepartment = data;
      } else {
        this.subDepartment = new SubDepartment();
      }
      this.modalRef = this.modalService.show(template, {
        class: 'modal-dialog-centered modal-md',
        backdrop: 'static'
      });
    }
    
    closeModal(event) {
      if (event) {
        this.setPage({ offset: 0 });
      }
      this.modalRef.hide();
    }
  
    onClickDelete(data) {
      this.deleteId = data.subDepartmentId;
      this.isConfirmed = true;
    }
  
    delete(id) {
      this.isConfirmed = false;
      this.departmentService.deleteSubDepartment(id).then(res => {
        if (res['Success']) {
          this.toaster.success(Constants.SUB_DEPARTMENT_DELETE_SUCESS_MSG);
          this.setPage({ offset: 0 });
        } else { this.toaster.error(res['Message']); }
      }, err => { });
    }
    
    close(event) {
      this.isConfirmed = false;
    }
    
    sortCallback(sortInfo) {
      this.searchSort.Page = this.page.pageNumber + 1;
      this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
      this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
      this.filterData();
    }
    
    clear() {
      this.isShowLink = false;
      this.searchSort.Search.Value = '';
      this.searchSort.Order[0].ColumnName = '';
      this.searchSort.Order[0].Dir = 'asc';
      this.filterTextValue.nativeElement.value = '';
      this.setPage({ offset: 0 });
    }
    
    filterData() {
      this.departmentService.SubDepartmentDataFilter(this.searchSort).then(res => {
        if (res['data'] && res['data'].length > 0) {
          this.subDepartmentList = res['data'];
          this.totalItems =res['recordsFiltered'];
        } else { this.subDepartmentList = []; this.totalItems = 0; }
      }, err => { this.subDepartmentList = []; this.totalItems = 0; });
    }
    
    get enableDisableCloseIcon(): boolean {
      if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
        return false;
      }
      return true;
    }
  }
  
  