import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/app/constant/constants';

@Component({
  selector: 'app-approved-requests',
  templateUrl: './approved-requests.component.html',
  styleUrls: ['./approved-requests.component.scss']
})
export class ApprovedRequestsComponent implements OnInit {
  isSwapRequests = false;
  isVOTRequests = false;
  isTransferRequests = false;
  isTrainingRequests = false;
  requestType = Constants.APPROVED_OFFER;
  isShowSwap = false;
  isShowVot = false;
  isShowTransfer = false;
  isShowTraning = false;
  isShowVto = false;
  isVTORequests = false;
  isShowFlex = false;
  isFlexWorkRequests = false;
  isShowCallOff = false;
  isCallInRequests = false;
  isEarlyGoRequests = false;
  isShowEarlyOut = false;
  isShowTimeOffRequest = false;
  isTimeOffRequest = false;
  isLateInRequest = false;
  isShowLateIn = false;
  isReviewRequest = false;
  isShowReview = false;
  isClockInOutRequest = false;
  isShowClockInOut = false;
  @ViewChild('noModule', { static: true }) noModule: ElementRef;
  constructor() {
  }

  ngOnInit() {
    this.isSwapRequests = JSON.parse(localStorage.getItem(Constants.IS_SWAP_REQUEST));
    this.isVOTRequests = JSON.parse(localStorage.getItem(Constants.IS_VOT_REQUEST));
    this.isTransferRequests = JSON.parse(localStorage.getItem(Constants.IS_TRANSFER_REQUEST));
    this.isTrainingRequests = JSON.parse(localStorage.getItem(Constants.IS_TRAINING_REQUEST));
    this.isVTORequests = JSON.parse(localStorage.getItem(Constants.IS_VTO_REQUEST));
    this.isCallInRequests = JSON.parse(localStorage.getItem(Constants.IS_CALL_IN_REQUEST));
    this.isEarlyGoRequests = JSON.parse(localStorage.getItem(Constants.IS_EARLY_GO_REQUEST));
    this.isShowTimeOffRequest = JSON.parse(localStorage.getItem(Constants.IS_TIME_OFF));
    this.isFlexWorkRequests = JSON.parse(localStorage.getItem(Constants.IS_FLEX_WORK_REQUEST));
    this.isTimeOffRequest = JSON.parse(localStorage.getItem(Constants.IS_TIME_OFF_REQUEST));
    this.isLateInRequest = JSON.parse(localStorage.getItem(Constants.IS_LATE_IN_REQUEST));
    this.isReviewRequest = JSON.parse(localStorage.getItem(Constants.IS_REVIEW_REQUEST));
    this.isClockInOutRequest = JSON.parse(localStorage.getItem(Constants.IS_CLOCKINOUT_REQUEST));

    this.isShowSwap = JSON.parse(localStorage.getItem(Constants.IS_SWAP));
    this.isShowCallOff = JSON.parse(localStorage.getItem(Constants.IS_CALL_OFF));
    this.isShowEarlyOut = JSON.parse(localStorage.getItem(Constants.IS_EARLY_OUT));
    this.isShowVot = JSON.parse(localStorage.getItem(Constants.IS_VOT));
    this.isShowTransfer = JSON.parse(localStorage.getItem(Constants.IS_TRANSFER));
    this.isShowTraning = JSON.parse(localStorage.getItem(Constants.IS_TRAINING));
    this.isShowVto = JSON.parse(localStorage.getItem(Constants.IS_VTO));
    this.isShowFlex = JSON.parse(localStorage.getItem(Constants.IS_FLEX_WORK));
    this.isShowLateIn = JSON.parse(localStorage.getItem(Constants.IS_LATE_IN));
    this.isShowReview = 
    JSON.parse(localStorage.getItem(Constants.IS_REVIEW));
    this.isShowClockInOut = JSON.parse(localStorage.getItem(Constants.IS_CLOCKINOUT));
  }

  getOffer(data) {
    this.isSwapRequests = false;
    this.isVOTRequests = false;
    this.isTransferRequests = false;
    this.isTrainingRequests = false;
    this.isVTORequests = false;
    this.isCallInRequests = false;
    this.isEarlyGoRequests = false;
    this.isFlexWorkRequests = false;
    this.isTimeOffRequest = false;
    this.isLateInRequest = false;
    this.isReviewRequest = false;
    this.isClockInOutRequest = false;
    if (data === 1) {
      this.isSwapRequests = true;
    } else if (data === 2) {
      this.isVOTRequests = true;
    } else if (data === 3) {
      this.isTransferRequests = true;
    } else if (data === 4) {
      this.isTrainingRequests = true;
    } else if (data === 5) {
      this.isVTORequests = true;
    } else if (data === 6) {
      this.isCallInRequests = true;
    } else if (data === 7) {
      this.isEarlyGoRequests = true;
    } else if (data === 8) {
      this.isFlexWorkRequests = true;
    } else if (data === 9) {
      this.isTimeOffRequest = true;
    } else if (data === 10) {
      this.isLateInRequest = true;
    } else if (data === 11) {
        this.isClockInOutRequest = true;
    } else if (data === 12) {
      this.isReviewRequest = true;
    }else{
    let elementClass: any = document.getElementsByClassName("sub");
      if (elementClass.length > 0)
        elementClass[0].style.border = 'none'
    //  this.noModule.nativeElement.innerHTML ="<h1> No modules subscribed, Please contact admin for subscription</h1>";
    }
    localStorage.setItem(Constants.IS_SWAP_REQUEST, String(this.isSwapRequests));
    localStorage.setItem(Constants.IS_VOT_REQUEST, String(this.isVOTRequests));
    localStorage.setItem(Constants.IS_TRANSFER_REQUEST, String(this.isTransferRequests));
    localStorage.setItem(Constants.IS_TRAINING_REQUEST, String(this.isTrainingRequests));
    localStorage.setItem(Constants.IS_VTO_REQUEST, String(this.isVTORequests));
    localStorage.setItem(Constants.IS_CALL_IN_REQUEST, String(this.isCallInRequests));
    localStorage.setItem(Constants.IS_EARLY_GO_REQUEST, String(this.isEarlyGoRequests));
    localStorage.setItem(Constants.IS_FLEX_WORK_REQUEST, String(this.isFlexWorkRequests));
    localStorage.setItem(Constants.IS_TIME_OFF_REQUEST, String(this.isTimeOffRequest));
    localStorage.setItem(Constants.IS_LATE_IN_REQUEST, String(this.isLateInRequest));
    localStorage.setItem(Constants.IS_REVIEW_REQUEST, String(this.isReviewRequest));
    localStorage.setItem(Constants.IS_CLOCKINOUT_REQUEST, String(this.isClockInOutRequest));
  }
}
