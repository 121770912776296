import { Component, ElementRef, EventEmitter, Input, OnInit, Output,ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Constants } from '../constant/constants';
import { TimePunchesService } from '../service/time-punches.service';

@Component({
  selector: 'app-clockin-clockout',
  templateUrl: './clockin-clockout.component.html',
  styleUrls: ['./clockin-clockout.component.scss']
})
export class ClockinClockoutComponent implements OnInit {
  @Input() isShowHeader = true;
  @Input() clockOutDate;
  @Input() userId;
  @Output() closeRequest = new EventEmitter<boolean>();
  totalItems: any;
  payRollList = [];
  viewType = 3;
  modalRef: BsModalRef;

  constructor(private timePunchesService: TimePunchesService,) { }

  ngOnInit() {
    this.getPayrollTimePunchList();
  }
  getPayrollTimePunchList() {
    const getMethod = this.timePunchesService.getPayrollDataByPeriod(
      this.clockOutDate.toLocaleString(), this.clockOutDate.toLocaleString(), this.userId, this.viewType
    );
    getMethod.then(
      (res: any) => {
        if (res["Success"]) {
          this.payRollList = res.Data;
          this.totalItems =
            res.Data.length === 0
              ? undefined
              : res.Data.length;
        } else {
          this.payRollList = [];
          this.totalItems = undefined;
        }
      },
      (err) => {
        this.payRollList = [];
      }
    );
  }
  cancel() {
    this.closeRequest.emit(true);
  }
}
