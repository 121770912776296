import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanRoutingModule } from './plan-routing.module';
import { PlanComponent } from './plan.component';
import { SharedModule } from '../shared/shared/shared.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';


@NgModule({
  declarations: [PlanComponent],
  imports: [
    CommonModule,
    PlanRoutingModule,
    SharedModule,
    AccordionModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PlanModule { }
