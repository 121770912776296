import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { FormService } from "src/app/core/form.service";
import { timeOffRequestModel } from "src/app/models/TimeOffRequest.model";
import { ShiftService } from "src/app/service/shift.service";
import { TimeOffService } from "src/app/service/time-off.service";
import {
  bsConfig_withMinDate,
  Constants,
  Duration,
} from "../../constant/constants";

@Component({
  selector: "app-add-timeoff-request",
  templateUrl: "./add-timeoff-request.component.html",
  styleUrls: ["./add-timeoff-request.component.scss"],
})
export class AddTimeoffRequestComponent implements OnInit {
  @Input() timeOffRequestModel: timeOffRequestModel;

  @Output() closeRequest = new EventEmitter<boolean>();
  @Output() cancleRequest = new EventEmitter<boolean>();
  public timeOffRequestForm: FormGroup;

  bsConfig = bsConfig_withMinDate;
  today = new Date();
  companyId: number;
  timeOffConfigList = [];
  counter = 0;
  allNetBalance = "";
  public messageList: any = new timeOffRequestModel();
  durationType = Duration;
  shiftList: any;
  nightShift = false;
  isSelectedCurrectShift = true;
  onSubmittingFrom = false;
  selectedValue: any;

  constructor(
    private formService: FormService,
    private timeOffService: TimeOffService,
    private toaster: ToastrService,
    private shiftService: ShiftService,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.getTimeOffConfigList();
    this.initializeTimeOffRequestForm();
    this.initializeMessages();
    this.getShiftList();
  }

  initializeTimeOffRequestForm() {
    this.timeOffRequestForm = new FormGroup({
      companyId: new FormControl(
        Number(localStorage.getItem(Constants.COMPANYID))
      ),
      shiftId: new FormControl(Number(localStorage.getItem(Constants.SHIFTID))),
      timeOffUserRequestId: new FormControl(
        !!this.timeOffRequestModel
          ? this.timeOffRequestModel.TimeOffUserRequestId
          : 0
      ),
      TimeOffStartDate: new FormControl(
        !!this.timeOffRequestModel
          ? new Date(this.timeOffRequestModel.TimeOffStartDate)
          : new Date(),
        Validators.required
      ),
      TimeOffEndDate: new FormControl(
        !!this.timeOffRequestModel
          ? new Date(this.timeOffRequestModel.TimeOffEndDate)
          : new Date(),
        Validators.required
      ),
      TimeOffId: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.TimeOffId : "",
        Validators.required
      ),
      Duration: new FormControl(
        !!this.timeOffRequestModel
          ? this.timeOffRequestModel.Duration
          : this.durationType[0].id,
        Validators.required
      ),
      TimeOffDifference: new FormControl(
        !!this.timeOffRequestModel
          ? new Date(this.timeOffRequestModel.TimeOffDifference)
          : null
      ),
      TimeOffStartTime: new FormControl(
        !!this.timeOffRequestModel
          ? new Date(this.timeOffRequestModel.TimeOffStartDate)
          : "",
        Validators.required
      ),
      TimeOffEndTime: new FormControl(
        !!this.timeOffRequestModel
          ? new Date(this.timeOffRequestModel.TimeOffEndDate)
          : "",
        Validators.required
      ),
      createdBy: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.CreatedBy : null
      ),
      CreatedDate: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.CreatedDate : null
      ),
      isActive: new FormControl(true),
      UserId: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.UserId : 0
      ),
      UserName: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.UserName : null
      ),
      DepartmentId: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.DepartmentId : 0
      ),
    });

    if (!!this.timeOffRequestModel) this.setInialDurationLogic();
  }

  initializeMessages() {
    this.messageList.TimeOffStartDate = {
      required: Constants.VALIDATION_MSG.TIME_OFF_REQUEST.START_TIME,
    };
    this.messageList.TimeOffEndDate = {
      required: Constants.VALIDATION_MSG.TIME_OFF_REQUEST.END_TIME,
    };
    this.messageList.TimeOffDifference = {
      required: Constants.VALIDATION_MSG.TIME_OFF_REQUEST.TOTAL_REQUESTED_HOURS,
    };
    this.messageList.TimeOffId = {
      required: Constants.VALIDATION_MSG.TIME_OFF_REQUEST.TIME_OFF_TYPE,
    };
    this.messageList.Duration = {
      required: Constants.VALIDATION_MSG.TIME_OFF_REQUEST.DURATION,
    };
    this.messageList.TimeOffEndTime = {
      required: Constants.VALIDATION_MSG.TIME_OFF_REQUEST.TIME,
    };
    this.messageList.TimeOffStartTime = {
      required: Constants.VALIDATION_MSG.TIME_OFF_REQUEST.TIME,
    };
  }

  getTimeOffConfigList() {
    this.timeOffService.GetAllConfigForUser().then((response) => {
      if (response["Success"]) {
        this.timeOffConfigList = response["Data"];
        this.timeOffConfigList.forEach((element) => {
          this.allNetBalance += `${element["Name"]}: ${element["NetBalance"]} \n`;
        });
        console.log(this.allNetBalance);
      }
    });
  }

  close() {
    this.cancleRequest.emit(true);
  }

  startTimeChange() {
    console.log("PM");
  }

  getShiftList(event = "init") {
    this.shiftService
      .getShiftListByUserId(Number(localStorage.getItem(Constants.SHIFTID)))
      .then(
        (response) => {
          if (response["Success"]) {
            this.shiftList = response["Data"];

            // NEW FORM
            if (!this.timeOffRequestModel) this.startEndDateCalc();
          }
        },
        (err) => {
          this.shiftList = [];
        }
      );
  }

  startEndDateCalc(flag = "init") {
    let currentDate = new Date();

    if (flag == "OnDurationChanged")
      currentDate = new Date(
        this.timeOffRequestForm.controls.TimeOffStartDate.value
      );

    let currentWeekDay = currentDate.getDay() + 1;
    let userNextWorkingDays = this.shiftList.shiftWeekDay.find(
      (x) => x.weekday === currentWeekDay
    );
    if (!userNextWorkingDays) {
      userNextWorkingDays = this.shiftList.shiftWeekDay.find(
        (x) => x.weekday > currentWeekDay
      );
      if (!userNextWorkingDays) {
        userNextWorkingDays = this.shiftList.shiftWeekDay.find(
          (x) => x.weekday <= currentWeekDay
        );
      }
    }
    let userUpcommingWorkingDay = new Date(
      currentDate.setDate(
        currentDate.getDate() +
          ((userNextWorkingDays.weekday + (6 - currentDate.getDay())) % 7)
      )
    );
    let userNextShiftStartTime = userNextWorkingDays.startTime.split(":");
    let userNextShiftEndTime = userNextWorkingDays.endTime.split(":");
    let startTime = new Date(
      new Date().setHours(userNextShiftStartTime[0])
    ).setMinutes(userNextShiftStartTime[1]);
    let endTime = new Date(
      new Date().setHours(userNextShiftEndTime[0])
    ).setMinutes(userNextShiftEndTime[1]);

    // NEW FORM
    if (!this.timeOffRequestModel && flag == "init") {
      this.timeOffRequestForm.controls.TimeOffStartDate.setValue(
        new Date(userUpcommingWorkingDay)
      );
      this.timeOffRequestForm.controls.TimeOffEndDate.setValue(
        new Date(userUpcommingWorkingDay)
      );
    }

    this.timeOffRequestForm.controls.TimeOffStartTime.setValue(
      new Date(startTime)
    );
    this.timeOffRequestForm.controls.TimeOffEndTime.setValue(new Date(endTime));

    if (this.timeOffRequestForm.controls.Duration.value == 1) {
      this.selectedValue = 1;
      this.setTimes(userNextWorkingDays.startTime, userNextWorkingDays.endTime);
      this.disabledInputs();
    } else if (this.timeOffRequestForm.controls.Duration.value == 2) {
      this.selectedValue = 2;
      this.setTimes(
        userNextWorkingDays.startTime,
        userNextWorkingDays.firstHalfEndTime
      );
      this.disabledInputs();
    } else if (this.timeOffRequestForm.controls.Duration.value == 3) {
      this.selectedValue = 3;
      this.setTimes(
        userNextWorkingDays.secondHalfStartTime,
        userNextWorkingDays.endTime
      );
      this.disabledInputs();
    } else if (this.timeOffRequestForm.controls.Duration.value == 4) {
      if (this.selectedValue == 2) {
        this.setTimes(
          userNextWorkingDays.startTime,
          userNextWorkingDays.firstHalfEndTime
        );
      } else if (this.selectedValue == 3) {
        this.setTimes(
          userNextWorkingDays.secondHalfStartTime,
          userNextWorkingDays.endTime
        );
      } else {
        this.setTimes(
          userNextWorkingDays.startTime,
          userNextWorkingDays.endTime
        );
      }
      // this.timeOffRequestForm.controls.TimeOffStartDate.setValue(new Date());
      // this.timeOffRequestForm.controls.TimeOffEndDate.setValue(new Date());
      // this.timeOffRequestForm.controls.TimeOffStartTime.setValue(new Date(startTime));
      // this.timeOffRequestForm.controls.TimeOffEndTime.setValue(new Date(endTime));
      this.enabledInputs();
    }
  }

  onDurationChange(event) {
    this.startEndDateCalc("OnDurationChanged");
  }
  endDateChangeValidation(event) {
    let endDate = new Date(
      this.timeOffRequestForm.controls.TimeOffEndDate.value
    );
    if (event.target.placeholder === "HH") {
      let minutes =
        event.path[0].parentElement.nextElementSibling.nextElementSibling
          .children[0].value;
      minutes = !!minutes ? minutes : 0;
      this.timeOffRequestForm.controls.TimeOffEndDate.setValue(
        new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          Number(event.target.value),
          Number(minutes),
          0
        )
      );
    }
    if (event.target.placeholder === "MM") {
      let hours =
        event.path[0].parentElement.parentElement.firstChild.children[0].value;
      hours = !!hours ? hours : 0;
      this.timeOffRequestForm.controls.TimeOffEndDate.setValue(
        new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          Number(hours),
          Number(event.target.value),
          0
        )
      );
    }
  }
  startTimeChangeValidation(event) {
    let startDate = new Date(
      this.timeOffRequestForm.controls.TimeOffStartDate.value
    );
    if (event.target.placeholder === "HH") {
      let minutes =
        event.path[0].parentElement.nextElementSibling.nextElementSibling
          .children[0].value;
      minutes = !!minutes ? minutes : 0;
      this.timeOffRequestForm.controls.TimeOffStartDate.setValue(
        new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          Number(event.target.value),
          Number(minutes),
          0
        )
      );
    }
    if (event.target.placeholder === "MM") {
      let hours =
        event.path[0].parentElement.parentElement.firstChild.children[0].value;
      hours = !!hours ? hours : 0;
      this.timeOffRequestForm.controls.TimeOffStartDate.setValue(
        new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          Number(hours),
          Number(event.target.value),
          0
        )
      );
    }
  }
  setTimes(startTime, endTime, date = new Date()) {
    if (!!startTime) {
      const startdate = new Date(this.timeOffRequestForm.controls.TimeOffStartDate.value);
      startdate.setHours(
        Number(startTime.split(":")[0]),
        Number(startTime.split(":")[1])
      );
      this.timeOffRequestForm.controls.TimeOffStartTime.setValue(startdate);
      this.timeOffRequestForm.controls.TimeOffStartDate.setValue(startdate);
    } else {
      this.toaster.error(Constants.SHIFT_START_TIME_CONFIGURE);
      this.timeOffRequestForm.controls.TimeOffStartTime.setValue(null);
    }
    if (!!endTime) {
      const enddate =new Date(this.timeOffRequestForm.controls.TimeOffEndDate.value);
      enddate.setHours(
        Number(endTime.split(":")[0]),
        Number(endTime.split(":")[1])
      );
      this.timeOffRequestForm.controls.TimeOffEndTime.setValue(enddate);
      this.timeOffRequestForm.controls.TimeOffEndDate.setValue(enddate);
    } else {
      this.toaster.error(Constants.SHIFT_END_TIME_CONFIGURE);
      this.timeOffRequestForm.controls.TimeOffEndTime.setValue(null);
    }
  }

  enabledInputs() {
    this.timeOffRequestForm.controls.TimeOffStartDate.enable();
    this.timeOffRequestForm.controls.TimeOffEndDate.enable();
    this.timeOffRequestForm.controls.TimeOffStartTime.enable();
    this.timeOffRequestForm.controls.TimeOffEndTime.enable();
  }

  disabledInputs() {
    this.timeOffRequestForm.controls.TimeOffEndDate.disable();
    this.timeOffRequestForm.controls.TimeOffStartTime.disable();
    this.timeOffRequestForm.controls.TimeOffEndTime.disable();
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.timeOffRequestForm);
    if (this.timeOffRequestForm.invalid) {
      return;
    }

    if (
      !this.isSelectedCurrectShift &&
      this.timeOffRequestForm.controls.Duration.value != 4
    ) {
      this.toaster.error(Constants.NO_AVAILABLE_SHIFT);
      return;
    }

    this.onSubmittingFrom = true;

    let startDate = new Date(
      this.timeOffRequestForm.controls.TimeOffStartDate.value
    );
    let startDateFromTime = new Date(
      this.timeOffRequestForm.controls.TimeOffStartTime.value
    );
    let endDate = new Date(
      this.timeOffRequestForm.controls.TimeOffEndDate.value
    );
    let endDateFromTime = new Date(
      this.timeOffRequestForm.controls.TimeOffEndTime.value
    );
    if (this.timeOffRequestForm.controls.Duration.value == 4) {
      this.timeOffRequestForm.controls.TimeOffStartDate.setValue(
        this.datepipe.transform(startDate, "yyyy-MM-dd HH:mm")
      );

      
      this.timeOffRequestForm.controls.TimeOffEndDate.setValue(
        this.datepipe.transform(new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          endDate.getHours(),
          endDate.getMinutes(),
          0,
          0
        ), "yyyy-MM-dd HH:mm")
      );
    } else {
      this.timeOffRequestForm.controls.TimeOffStartDate.setValue(
        this.datepipe.transform(
          new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            startDateFromTime.getHours(),
            startDateFromTime.getMinutes(),
            0,
            0
          ),
          "yyyy-MM-dd HH:mm"
        )
      );

      this.timeOffRequestForm.controls.TimeOffEndDate.setValue(
        moment(
          new Date(
            endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate(),
            endDateFromTime.getHours(),
            endDateFromTime.getMinutes(),
            0,
            0
          )
        ).format("YYYY-MM-DD HH:mm")
      );
    }

    //enabled
    this.enabledInputs();
    this.timeOffRequestForm.controls.Duration.setValue(
      Number(this.timeOffRequestForm.controls.Duration.value)
    );
    this.timeOffRequestForm.controls.TimeOffId.setValue(
      Number(this.timeOffRequestForm.controls.TimeOffId.value)
    );
    const saveMethod =
      this.timeOffRequestForm.controls.timeOffUserRequestId.value > 0
        ? this.timeOffService.updateTimeOffRequest(
            this.timeOffRequestForm.value
          )
        : this.timeOffService.addTimeOffRequest(this.timeOffRequestForm.value);
    saveMethod.then(
      (res) => {
        this.onSubmittingFrom = false;
        this.disabledInputs();

        if (res["Success"]) {
          this.toaster.success(
            this.timeOffRequestForm.controls.timeOffUserRequestId.value > 0
              ? Constants.TIME_OFF_REQ_EDIT_MSG
              : Constants.TIME_OFF_REQ_ADD_MSG
          );
          this.closeRequest.emit(true);
        } else {
          this.toaster.error(res["Message"]);
          let tempEndDate =
            this.timeOffRequestForm.controls.TimeOffEndDate.value;
          this.timeOffRequestForm.controls.TimeOffStartDate.setValue(
            new Date(this.timeOffRequestForm.controls.TimeOffStartDate.value)
          );
          this.timeOffRequestForm.controls.TimeOffEndDate.setValue(
            new Date(tempEndDate)
          );
        }
      },
      (err) => {
        this.onSubmittingFrom = false;
        this.disabledInputs();
        this.closeRequest.emit(false);
      }
    );
  }

  setnewDate(date) {
    if (date.getTime()) {
      const dateObj = {
        year: +this.datepipe.transform(date, "yyyy"),
        month: +this.datepipe.transform(date, "MM"),
        day: +this.datepipe.transform(date, "dd"),
      };
      return new Date(dateObj["year"], dateObj["month"] - 1, dateObj["day"]);
    }
    return new Date(date);
  }

  startDateChangeValidation(event) {
    if (this.onSubmittingFrom) return;

    if (
      this.timeOffRequestForm.controls.Duration.value == 4 ||
      this.onSubmittingFrom
    )
      return;

    let currentWeekDay = new Date().getDay() + 1;
    console.log(currentWeekDay);
    if (!!this.shiftList) {
      this.isSelectedCurrectShift = false;

      for (let index = 0; index < this.shiftList.shiftWeekDay.length; index++) {
        if (
          this.shiftList.shiftWeekDay[index].weekday ==
          new Date(event).getDay() + 1
        ) {
          let shiftWeek = this.shiftList.shiftWeekDay[index];
          this.timeOffRequestForm.controls.TimeOffStartDate.setValue(
            new Date(event)
          );
          this.timeOffRequestForm.controls.TimeOffEndDate.setValue(
            new Date(event)
          );

          if (this.timeOffRequestForm.controls.Duration.value == 1) {
            this.setTimes(shiftWeek.startTime, shiftWeek.endTime, event);
          }
          if (this.timeOffRequestForm.controls.Duration.value == 2) {
            this.setTimes(shiftWeek.startTime, shiftWeek.firstHalfEndTime);
          }
          if (this.timeOffRequestForm.controls.Duration.value == 3) {
            this.setTimes(shiftWeek.secondHalfStartTime, shiftWeek.endTime);
          }
          this.isSelectedCurrectShift = true;
          break;
        }
      }
      if (!this.isSelectedCurrectShift) {
        this.toaster.error(Constants.NO_AVAILABLE_SHIFT);
      }
    }

    if (
      this.timeOffRequestForm.controls.timeOffUserRequestId.value > 0 &&
      this.counter <= 1
    ) {
      this.counter++;
      return;
    }
  }

  setInialDurationLogic() {
    if (this.timeOffRequestForm.controls.Duration.value == 1) {
      this.disabledInputs();
    } else if (this.timeOffRequestForm.controls.Duration.value == 2) {
      this.disabledInputs();
    } else if (this.timeOffRequestForm.controls.Duration.value == 3) {
      this.disabledInputs();
    } else if (this.timeOffRequestForm.controls.Duration.value == 4) {
      this.enabledInputs();
    }
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }
  control(controlName: string): AbstractControl {
    return this.timeOffRequestForm.get(controlName);
  }
}
