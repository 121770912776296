import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutRoutingModule } from './layout-routing.module';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from './../shared/shared/shared.module';
import { AddFeedbackReportComponent } from '../offer/add-feedback-report/add-feedback-report.component';


@NgModule({
  declarations: [LayoutComponent, HeaderComponent, AddFeedbackReportComponent],
  imports: [
    SharedModule,
    CommonModule,
    LayoutRoutingModule,
    CollapseModule,
    NgbDropdownModule,
    NgxSpinnerModule,
  ],
  exports: [CollapseModule],
  providers: []
})
export class LayoutModule { }
