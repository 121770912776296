import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../constant/constants';
import { TermsConditionService } from '../service/terms-condition.service';
import { TermsCondition } from '../models/terms-condition.model';

@Component({
  selector: 'app-internal-network',
  templateUrl: './internal-network.component.html',
  styleUrls: ['./internal-network.component.scss']
})
export class InternalNetworkComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  termsCondition = new TermsCondition();
  termsConditionList = [];
  modalRef: BsModalRef;
  totalItems: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  deleteId: string;
  isConfirmed: boolean = false;
  companyId: number;
  searchSort: any;
  columns = [];
  isShowLink = false;

  constructor(private modalService: BsModalService,
    private toaster: ToastrService,
    private termsConditionService: TermsConditionService) {
    this.columns = ['typeFieldstr', 'description'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));

    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getTermsConditionList(this.page.pageNumber + 1);
  }

  getTermsConditionList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.isShowLink = true;
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      this.termsConditionService.getTermsConditionListByCompanyId(this.companyId, currentPage)
        .then((res: any) => {
          if (res['Success']) {
            this.termsConditionList = res.Data.results;
            this.totalItems = res.Data.totalNumberOfRecords;
          } else { this.termsConditionList = []; this.totalItems = 0; }
        }, err => { this.termsConditionList = []; });
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.isShowLink = true;
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.isShowLink = false;
        this.clear();
      }
    }
  }

  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.termsCondition = data;
    } else {
      this.termsCondition = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-md',
      backdrop: 'static'
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }

  close(event) {
    this.isConfirmed = false;
  }

  onClickDelete(data) {
    this.deleteId = data.termsAndConditionsId;
    this.isConfirmed = true;
  }

  delete(id) {
    this.isConfirmed = false;
    this.termsConditionService.deleteTermsCondition(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.TERMS_CONDITION_DELETE_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      }
    }, err => { });
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  clear() {
    this.isShowLink = false;
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  filterData() {
    this.termsConditionService.dataFilter(this.searchSort).then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.termsConditionList = res['data'];
        this.totalItems = res['recordsFiltered'];
      } else { this.termsConditionList = []; this.totalItems = 0; }
    }, err => { this.termsConditionList = []; this.totalItems = 0; });
  }
}
