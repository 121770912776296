import { Component, OnInit, ViewChild } from '@angular/core';
import { Constants, timeList,SubscriptionType } from 'src/app/constant/constants';
import { VotRequestService } from 'src/app/service/vot-request.service';
import { Role, OfferStatus } from 'src/app/models/role-model';
import { Offer } from 'src/app/models/offer.model';
import { ShiftService } from 'src/app/service/shift.service';
import { DepartmentService } from 'src/app/service/department.service';
import { DatePipe } from '@angular/common';
import { UtilityService } from './../../service/utility.service';
import { CallInRequestService } from 'src/app/service/callInRequest.service';
import * as _ from 'lodash';
import { commonHelper } from 'src/app/shared/Helpers/commonHelper';

@Component({
  selector: 'app-available-vot-request',
  templateUrl: './available-vot-request.component.html',
  styleUrls: ['./available-vot-request.component.scss']
})
export class AvailableVotRequestComponent implements OnInit {
  companyId: number;
  public HourEarly:any={};
  public HourLate:any={};
  public settingList:any={};
  public moduleId:any;
  public isHiddenColumn:boolean = false;
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  @ViewChild('workDateValue', { static: false }) workDateValue;
  @ViewChild('shiftValue', { static: false }) shiftValue;
  @ViewChild('departmentValue', { static: false }) departmentValue;
  @ViewChild('timeValue', { static: false }) timeValue;
  myOffer: Offer;
  votRequestList = [];
  totalItems: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  role: number;
  roleEnum = Role;
  offerStatus = OfferStatus;
  userId: number;
  searchSort: any;
  columns = [];
  isShowLink = false;
  requestType: any;
  totalRequestedHours = 0;
  shiftList = [];
  departmentList = [];
  filterValue = [];
  timeList = timeList;
  commonShiftList: any;

  constructor(
    private votRequestService: VotRequestService,
    private shiftService: ShiftService,
    private departmentService: DepartmentService,
    private datepipe: DatePipe,
    private utilityService :UtilityService,
    private callInRequstService:CallInRequestService
    ) {
    this.columns = ['dateToWork', 'departmentId', 'shiftToWork', 'timeType', 'dateToWorkDateStr', 'department.departmentName', 'shiftToWorkTitle', 'timeTypeStr', 'offerStatusStr', 'dateToWork', 'shiftToWork', 'timeType', 'createdByUsername','isHourEarly','isHourLate','createdShortDate'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Columns: [
      ],
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.userId = Number(localStorage.getItem(Constants.USERID));
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.setPage({ offset: 0 });
    this.getShiftList();
    this.getDepartmentList();
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    
  }

  ngAfterViewInit() {
    this.filterValue.push(this.workDateValue.nativeElement.value);
    this.filterValue.push(this.shiftValue.nativeElement.value);
    this.filterValue.push(this.departmentValue.nativeElement.value);
    this.filterValue.push(this.timeValue.nativeElement.value);
    this.columns.forEach((element, i) => {
      if (i < 4) {
        const obj = {
          Data: 'string',
          Searchable: true,
          Orderable: true,
          Name: element,
          Search: {
            Value: this.filterValue[i],
            Regex: 'string'
          }
        }
        this.searchSort.Columns.push(obj);
      }
    });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getVotRequestList(this.page.pageNumber + 1);
  }

  getVotRequestList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.isShowLink = true;
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      this.votRequestService.getVotHrRequestedOfferList(currentPage, this.rowsOnPage)
        .then((res: any) => {
          if(JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))){
            this.utilityService.showLoading();
          }
          if (res['Success']) {
            if (!!res['Data'].pagedResults && res['Data'].pagedResults.results.length > 0) {
              this.votRequestList = res['Data'].pagedResults.results;
              this.totalItems = res.Data.pagedResults.totalNumberOfRecords ;
            } else {
              this.votRequestList = [];
              this.totalItems = 0;
            }
            this.totalRequestedHours = res.Data.approvalHours.totalHoursPending;
          } else {
            this.votRequestList = [];
            this.totalItems = 0;
          }
        }, err => { this.votRequestList = []; });
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.isShowLink = true;
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.isShowLink = false;
        this.clearText();
      }
    }
  }

  clearText() {
    this.searchSort.Search.Value = '';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  clear() {
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.searchSort.Columns.forEach(element => {
      element.Search.Value = '';
    });
    this.shiftValue.nativeElement.value = '';
    this.departmentValue.nativeElement.value = '';
    this.timeValue.nativeElement.value = '';
    this.workDateValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  filterData() {
    this.votRequestService.approvalVotOfferDataFilter(this.searchSort).then(res => {
      if (res['offerlist'].data && res['offerlist'].data.length > 0) {
        this.votRequestList = res['offerlist'].data;
        this.totalItems = res['offerlist']['recordsFiltered'];
      } else {
        this.votRequestList = []; this.totalItems = 0;
      }
      this.totalRequestedHours = res['approvehours'].totalHoursPending;
    }, err => { this.votRequestList = []; this.totalItems = 0; });
  }

  emptySumm() {
    return null;
  }

  getShiftList() {
    this.shiftService.getShiftListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID)))
      .then(response => {
        this.getSettingByCompanyID();
        this.shiftList = response['Data'];
        this.commonShiftList = _.merge([], this.shiftList)
      }, err => { this.shiftList = []; });
  }

  getDepartmentList() {
    this.departmentService.getDepartmentListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID))).then(res => {
      if (res['Success']) {
        this.departmentList = res['Data'];
      } else { this.departmentList = []; }
    }, err => { this.departmentList = []; });
  }

  workDateChange(event) {
    const findDateColumn = this.searchSort.Columns.find(x => x.Name === 'dateToWork');
    if (!!findDateColumn) {
      findDateColumn.Search.Value = this.datepipe.transform(this.setnewDate(event), 'yyyy/MM/dd');
    }
  }

  setnewDate(date) {
    const dateObj = {
      year: +(this.datepipe.transform(date, 'yyyy')),
      month: +(this.datepipe.transform(date, 'MM')),
      day: +(this.datepipe.transform(date, 'dd'))
    };
    return new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day']);
  }

  shiftChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(x => x.Name === 'shiftToWork');
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = event.currentTarget.value;
    }
  }

  departmentChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(x => x.Name === 'shiftToWork');
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = '';
    }
    if (event.currentTarget.value)
      this.shiftList = commonHelper.filterShiftListFromDepartmentId(event.currentTarget.value, this.commonShiftList);
    else
      this.shiftList = this.commonShiftList
    const findDepartmentColumn = this.searchSort.Columns.find(x => x.Name === 'departmentId');
    if (!!findDepartmentColumn) {
      findDepartmentColumn.Search.Value = event.currentTarget.value;
    }
  }

  timeChange(event) {
    const findTimeColumn = this.searchSort.Columns.find(x => x.Name === 'timeType');
    if (!!findTimeColumn) {
      findTimeColumn.Search.Value = event.currentTarget.value;
    }
  }

  getSettingByCompanyID() {            
    let module = SubscriptionType.filter((item)=>{
      return item.value ==='VOT Request Module';
    });
    this.moduleId = module[0].id;
    const getMethod = this.callInRequstService.getSettingByCompanyID(this.moduleId, this.companyId,false);
    getMethod.then((res: any) => {
      if (res['Success']) {
        this.settingList = res.Data;
        if(this.settingList.length > 0){
          this.settingList.map((item)=>{
            if(item.SettingType==="Hour Early"){
              item.Name = "An Hour Early";
              this.HourEarly = item;
              console.log(this.HourEarly)
            }
            else if(item.SettingType==="Hour Late"){
              item.Name = "An Hour Late";
              this.HourLate = item;
            }
          });    
        }
      } else { this.settingList = [];  }
    }, err => { this.settingList = []; });
  }
}
