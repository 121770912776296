import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/constant/constants';
import { Offer } from 'src/app/models/offer.model';
import { OfferStatus, Role } from 'src/app/models/role-model';
import { KioskService } from 'src/app/service/kiosk.service';


@Component({
  selector: 'app-kiosk',
    templateUrl: './kiosk.component.html',
    styleUrls: ['./kiosk.component.scss']
})
export class KioskComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  @Input() requestType: string;
  public showAction: boolean = true;
  kioskRequestList = [];
  selected = [];
  columns = [];
  selectedRecords = [];

  searchSort: any;
  public myOffer: Offer;
  modalRef: BsModalRef;
  public totalItems: any = '1';
  page: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  role: number;
  roleEnum = Role;
  deleteId: string;
  KioskId: string;
  isConfirmed = false;
  offerStatus = OfferStatus;
  moduleId: number;
  settingList: any;

  message: string;
  IsCoverMyWork: boolean;

  constructor(
    private modalService: BsModalService,
    private kioskService: KioskService,
    private toaster: ToastrService,
  ) {
    this.columns = ['Location', 'Name', 'HardwareIdentifier']
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [
      ],
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.isConfirmed = false;
    this.setPage({ offset: 0 });
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.IsCoverMyWork = localStorage.getItem(Constants.APP_NAME) === "CoverMyWork" ? true : false;
  }

  async setPage(pageInfo) {
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
    this.getKioskList(this.page.pageNumber + 1)
  }
  getKioskList(currentPage) {
    const getMethod =
      this.kioskService.getKioskUserList(Number(localStorage.getItem(Constants.COMPANYID)), currentPage);
    getMethod.then((res: any) => {
      if (res['Success']) {
        if (res.Data && res.Data.results.length > 0) {
          this.kioskRequestList = res.Data.results
          this.totalItems = res.Data.totalNumberOfRecords;
        } else {
          this.kioskRequestList = [];
          this.totalItems = 0;
        }
      } else {
        this.kioskRequestList = [];
        this.totalItems = 0;
      }
    }, err => {
      this.kioskRequestList = [];
      this.totalItems = 0;
    });
  }
  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  filterData() {
    const filterMethod = this.kioskService.kioskUserDataFilter(this.searchSort)
    filterMethod.then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.kioskRequestList = res['data'];
        this.totalItems = res['recordsFiltered'];
      } else {
        this.totalItems = 0;
      }
    }, err => { this.kioskRequestList = []; this.totalItems = 0; });
  }
  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clearText();
      }
    }
  }
  clear() {
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.searchSort.Columns.forEach(element => {
      element.Search.Value = '';
    });
    this.setPage({ offset: 0 });
  }
  clearText() {
    this.searchSort.Search.Value = '';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }
  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.myOffer = data;
    } else {
      this.myOffer = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }

  onClickDelete(data) {
    this.deleteId = data.KioskId;
    this.isConfirmed = true;
  }
  delete(id) {
    this.isConfirmed = false;
    this.kioskService.deleteOffer(id).then(res => {
      if (!!res) {
        this.toaster.success(Constants.KIOSK_REQUEST_DELETE_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { });
  }
  close(event) {
    this.isConfirmed = false;
  }


}


