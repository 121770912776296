import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DepartmentService } from 'src/app/service/department.service';
import { Constants, bsConfig, timeList, typeField,drpSetting } from 'src/app/constant/constants';
import { Offer,TransferOffer } from 'src/app/models/offer.model';
import { ShiftService } from 'src/app/service/shift.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormService } from 'src/app/core/form.service';
import { ToastrService } from 'ngx-toastr';
import { TransferRequestService } from 'src/app/service/transfer-request.service';
import { UtilityService } from 'src/app/service/utility.service';
import { OfferStatus, Role } from 'src/app/models/role-model';




@Component({
  selector: 'app-add-edit-transfer-request',
  templateUrl: './add-edit-transfer-request.component.html',
  styleUrls: ['./add-edit-transfer-request.component.scss']
})
export class AddEditTransferRequestComponent implements OnInit {
  public startTime:any;
  public endTime:any;
  searchSort: any;
  page: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  selected = [];
  selectedRecords = [];
  public showAction:boolean = false;
  role: number;
  roleEnum = Role;
  public subDepartmentList:any=[];
  public dropdownList:any=[];
  addTransferForm: FormGroup;
  dropdownSettings = drpSetting.dropdownSettings;
  @Input() Offer: Offer;
  @Output() closeRequest = new EventEmitter<boolean>();
  public messageList: any = new TransferOffer();
  companyId: number;
  shiftId:number;
  public departmentList = [];
  public shiftList:any=[];
  public transferRequestList:any=[];
  public totalItems: any = '1';
  
  constructor(private utility: UtilityService,private transferRequestService:TransferRequestService,private toaster: ToastrService,private formService: FormService,private departmentService :DepartmentService,private shiftService:ShiftService) {
   
   }
  
  ngOnInit() {
    this.companyId = !!this.Offer ? this.Offer.companyId : Number(localStorage.getItem(Constants.COMPANYID));
    this.shiftId = Number(localStorage.getItem(Constants.SHIFTID));
    this.initializeUserForm();
    this.getDepartmentList();
    // this.getShiftList();
    this.initializeMessages();
    this.dropdownList = [
      {
        "id":"1",
        "text":'1'
      },
      {
        "id":"2",
        "text":'2'
      },
      {
        "id":"3",
        "text":'3'
      }
    ]
  }

 
  

  async initializeUserForm() {
    this.addTransferForm = new FormGroup(
      {
        status: new FormControl(!!this.Offer ? this.Offer.status : 1),
        companyId: new FormControl(this.companyId),
        offerId: new FormControl(!!this.Offer ? this.Offer.offerId : 0),
        offerType: new FormControl(3),
        shiftToWork: new FormControl(!!this.Offer ? this.Offer.shiftToWork : '',Validators.required),
        preferenceId: new FormControl(!!this.Offer ? this.Offer.preferenceId : '',Validators.required),
        departmentId: new FormControl(!!this.Offer ? this.Offer.departmentId : '', Validators.required),
        subDepartmentId: new FormControl(!!this.Offer ? this.Offer.subDepartmentId : '',Validators.required),
        isPit:new FormControl(!!this.Offer ? this.Offer.isPit : false ),
        // startTime: new FormControl('', Validators.required),
        // endTime: new FormControl('', Validators.required),
      },
    );
  }

  initializeMessages() {
    this.messageList.departmentId = {
      required: Constants.VALIDATION_MSG.TRANSFER_REQUEST.DEPARTMENT_REQUIRED,
    };
    this.messageList.subDepartmentId = {
      required: Constants.VALIDATION_MSG.TRANSFER_REQUEST.SUB_DEPARTMENT_REQUIRED,
    };
    this.messageList.preferenceId = {
      required: Constants.VALIDATION_MSG.TRANSFER_REQUEST.PREFRENCE_ID_REQUIRED,
    };
    this.messageList.shiftToWork  = {
      required: Constants.VALIDATION_MSG.TRANSFER_REQUEST.SHIFT_TO_WORK_REQUIRED,
    };
    this.messageList.endTime = {
      required: Constants.VALIDATION_MSG.VTO.END_TIME,
    };
    this.messageList.startTime = {
      required: Constants.VALIDATION_MSG.VTO.START_TME,
    };
  }
    
    getDepartmentList() {
      this.departmentService.getDepartmentListByCompanyId(null, this.companyId).then(res => {
        if (res['Success']) {
          this.departmentList = res['Data'];
        } else { this.departmentList = []; }
      }, err => { this.departmentList = []; });
    }
    
    shiftChange(event){
      if (!!event.currentTarget) {
        if (!!event.currentTarget.value) {
            this.getStartEndTimeByShift(Number(event.currentTarget.value));
        }
      } else {
        if (!!event) {
            this.getStartEndTimeByShift(Number(event.currentTarget.value));
        }
      }
    }


    getStartEndTimeByShift(departmentId) {
      this.shiftService.getStartEndTimeByShift(departmentId)
        .then(response => {
          if (response['Success']) {
            this.setTimes(response['Data'].startTime, response['Data'].endTime);
          } else {
            this.startTime = "";
            this.endTime = "";
          }
        }, err => {  });
    }

    setTimes(startTime, endTime) {
      if (!!startTime) {
        const startdate = new Date();
        startdate.setHours(
          Number(startTime.split(":")[0]),
          Number(startTime.split(":")[1])
        );
        this.startTime = startdate;
      } else {
        this.toaster.error(Constants.SHIFT_START_TIME_CONFIGURE);
        this.startTime = "";
      }
      if (!!endTime) {
        const enddate = new Date();
        enddate.setHours(
          Number(endTime.split(":")[0]),
          Number(endTime.split(":")[1])
        );
        this.endTime = enddate;
      } else {
        this.toaster.error(Constants.SHIFT_END_TIME_CONFIGURE);
        this.endTime = "";
      }
    }    
    cancel() {
      this.closeRequest.emit(false);
    }
    departmentChange(event) {
      if (!!event.currentTarget) {
         if (!!event.currentTarget.value) {
             this.getSubDeptByDepartment(Number(event.currentTarget.value),this.companyId);
             this.getShiftByDepartment(Number(event.currentTarget.value));
         }
       } else {
         if (!!event) {
             this.getSubDeptByDepartment(Number(event),this.companyId);
             this.getShiftByDepartment(Number(event.currentTarget.value));
         }
       }
     }
     
     getShiftByDepartment(departmentId) {
      this.shiftService.getShiftListByDepartment(departmentId)
        .then(response => {
          if (response['Success']) {
            this.shiftList = response['Data'].filter( x => x.shiftId != this.shiftId);
          } else {
            this.shiftList = [];
          }
        }, err => { this.shiftList = []; });
    }
   
     getSubDeptByDepartment(departmentId,companyId) {
       this.departmentService.getSubDeptByDepartment(departmentId,companyId)
         .then(response => {
           if (response['Success']) {
             this.subDepartmentList = response['Data'];
           } else {
             this.subDepartmentList = [];
           }
         }, err => { this.subDepartmentList = []; });
     }

     onSubmit() {
      this.formService.markFormGroupTouched(this.addTransferForm);
      if (this.addTransferForm.invalid) {
        return;
      }
      this.addTransferForm.controls.departmentId.setValue(Number(this.addTransferForm.controls.departmentId.value));
      this.addTransferForm.controls.shiftToWork.setValue(Number(this.addTransferForm.controls.shiftToWork.value))
      const saveMethod = this.addTransferForm.controls.offerId.value > 0 ? this.transferRequestService.updateTransferRequestOffer(this.addTransferForm.value) :
      this.transferRequestService.addTransferRequestOffer(this.addTransferForm.value);  
      saveMethod.then(res => {
        if (res['Success']) {
          this.toaster.success(this.addTransferForm.controls.offerId.value > 0 ?
          Constants.TRANSFER_REQUEST_UPDATE_MSG : Constants.TRANSFER_REQUEST_ADD_MSG);
          this.closeRequest.emit(true);
        } else {
          this.utility.showErrorToast(res['Message']);
        }
      }, err => { })
    }
 }
  