import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Constants } from "src/app/constant/constants";
import { ModuleFunctionService } from "src/app/service/moduleFunction.service";
import { FormService } from "./../../core/form.service";

@Component({
  selector: "app-add-edit-module-training-config",
  templateUrl: "./add-edit-module-training-config.component.html",
  styleUrls: ["./add-edit-module-training-config.component.scss"],
})
export class AddEditModuleTrainingConfigComponent implements OnInit {
  @Input() moduleTraining: any;
  @Output() closeRequest = new EventEmitter<boolean>();
  public moduleConfig: any;
  messageList: any = {
    moduleName: String,
    moduleDescription: String,
  };
  constructor(
    private formService: FormService,
    private moduleFunctionService: ModuleFunctionService,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    this.initializeForm();
    this.initializeMessages();
  }
  initializeForm() {
    this.moduleConfig = new FormGroup({
      moduleId: new FormControl(
        !!this.moduleTraining ? this.moduleTraining.moduleId : 0
      ),
      moduleName: new FormControl(
        !!this.moduleTraining ? this.moduleTraining.moduleName : "",
        Validators.required
      ),
      moduleDescription: new FormControl(
        !!this.moduleTraining ? this.moduleTraining.moduleDescription : "",
        Validators.required
      ),
      isActive: new FormControl(true),
      deletedBy: new FormControl(),
      recordedByID : new FormControl(!!this.moduleTraining ? this.moduleTraining.recordedByID : ''),
      companyId :new FormControl(Number(localStorage.getItem(Constants.COMPANYID))),
    });
  }
  initializeMessages() {
    this.messageList.moduleName = {
      required: Constants.VALIDATION_MSG.Module_Traning.MODULEREQ,
    };
    this.messageList.moduleDescription = {
      required: Constants.VALIDATION_MSG.Module_Traning.DECRITION,
    };
  }
  cancel() {
    this.closeRequest.emit(false);
  }
  value(controlName: string) {
    return this.control(controlName).value;
  }
  control(controlName: string): AbstractControl {
    return this.moduleConfig.get(controlName);
  }
  onSubmit() {
    this.formService.markFormGroupTouched(this.moduleConfig);
    if (this.moduleConfig.invalid) {
      return;
    }
    const saveMethod =
      this.moduleConfig.controls.moduleId.value > 0
        ? this.moduleFunctionService.updateModule(this.moduleConfig.value)
        : this.moduleFunctionService.addModule(this.moduleConfig.value);
    saveMethod.then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(
            this.moduleConfig.controls.moduleId.value > 0
              ? Constants.MODULE_UPDATE_SUCESS_MSG
              : Constants.MODULE_ADD_SUCESS_MSG
          );
          this.closeRequest.emit(true);
        } else {
          this.toaster.error(res["Message"]);
        }
      },
      (err) => {
        this.closeRequest.emit(false);
      }
    );
  }
  
}
