import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {
  isDepartmentRequests = false;
  isSubDepartmentRequests = false;
  constructor() { }

  ngOnInit() {
    this.isDepartmentRequests = true;
  }

  getDepartmentList(data) {
    this.isDepartmentRequests = false;
    this.isSubDepartmentRequests = false
    if (data === 1) {
      this.isDepartmentRequests = true;
    } else if (data === 2) {
      this.isSubDepartmentRequests = true;
    }
  }
}
