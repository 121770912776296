import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { bsConfig, Constants } from 'src/app/constant/constants';
import { Offer } from 'src/app/models/offer.model';
import { OfferStatus, Role } from 'src/app/models/role-model';
import { ReviewService } from 'src/app/service/review.service';
import { UserService } from 'src/app/service/user.service';
import { UtilityService } from 'src/app/service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-employer-review-request',
  templateUrl: './employer-review-request.component.html',
  styleUrls: ['./employer-review-request.component.scss']
})
export class EmployerReviewRequestComponent implements OnInit {
  @Output() reloadCount = new EventEmitter<boolean>();
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  @ViewChild('startDate', { static: false }) startDate;
  @ViewChild('managerValue', { static: false }) managerValue;
  @ViewChild('endDate', { static: false }) endDate;
  @ViewChild("userNameValue", { static: false }) userNameValue;
  @Input() requestType: string;
  searchSort: any;
  nextDate: any;
  searchDate: any;
  managerList = [];
  employeeList = [];
  public totalItems: any;
  page: any;
  offerStatus = OfferStatus;
  role: number;
  roleEnum = Role;
  reviewRequestList = [];
  columns = [];
  modalRef: BsModalRef;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  public myOffer: Offer;
  bsConfig = bsConfig;
  companyId: number;
  isApprove = false;
  isReject = false;
  isConfirmed = false;
  Id: number;
  approveMsg = '';
  filterValue = [];
  viewMode: boolean;

  constructor(private datepipe: DatePipe,
    private reviewService: ReviewService,
    private utilityService: UtilityService,
    private modalService: BsModalService,
  ) {
    this.columns = ['SubmitedDate', 'ReviewDateStr', 'UserName', 'ReviewByObj.companyUsername', 'ReviewTypeStr', 'StatusStr'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [
      ],
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.getManagerList();
    this.setPage({ offset: 0 });
    this.isConfirmed = false;
    this.columns.forEach((element, i) => {
      if (i < 5) {
        const obj = {
          Data: "string",
          Searchable: true,
          Orderable: true,
          Name: element,
          Search: {
            Value: this.filterValue[i],
            Regex: "string",
            isSearchBetweenDates: i === 0 ? true : false,
            endDate: null,
          },
        };
        this.searchSort.Columns.push(obj);
      }
    });

  }
  async setPage(pageInfo,isReloadCount = false) {
    this.page.pageNumber = pageInfo.offset;
    await this.getReviewOfferListAsync(this.page.pageNumber + 1);
    if(isReloadCount){
      this.reloadCount.emit();
    }
  }

  getManagerList() {
    this.reviewService.getAllManagerList(this.companyId, null).then(res => {

      if (res['Success']) {
        this.managerList = res['Data'];
      } else {
        this.managerList = [];
      }
      this.utilityService.hideLoading();
    }, err => {
      this.managerList = [];
      this.utilityService.hideLoading();
    });
  }

  async getReviewOfferListAsync(currentPage) {
    if ((!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) ||
      (!!this.endDate && !!this.endDate.nativeElement.value) ||
      (!!this.startDate && !!this.startDate.nativeElement.value) ||
      (!!this.managerValue && this.managerValue.nativeElement.value) ||
      (!!this.userNameValue && !!this.userNameValue.nativeElement.value)) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod = this.requestType === Constants.REQUESTED_OFFER ?
        this.reviewService.getReviewPendingHrRequestList(currentPage, this.rowsOnPage)
        : this.reviewService.getReviewClosedHrRequestList(currentPage, this.rowsOnPage);
      await getMethod.then((res: any) => {

        if (res['Success']) {
          if (res.Data && res.Data.results.length > 0) {
            this.reviewRequestList = res.Data.results
            this.totalItems = res.Data.totalNumberOfRecords;
          } else {
            this.reviewRequestList = [];
            this.totalItems = 0;
          }
        } else {
          this.reviewRequestList = [];
          this.totalItems = 0;
        }
      }, err => {
        this.reviewRequestList = [];
        this.totalItems = 0;
      });
    }
  }
  setnewDate(date) {
    const dateObj = {
      year: +this.datepipe.transform(date, "yyyy"),
      month: +this.datepipe.transform(date, "MM"),
      day: +this.datepipe.transform(date, "dd"),
    };
    return new Date(dateObj["year"], dateObj["month"] - 1, dateObj["day"]);
  }
  startWorkDateChange(event) {
    this.searchDate = null;
    const findDateColumn = this.searchSort.Columns.find(
      (x) => x.Name === "SubmitedDate"
    );
    if (!!findDateColumn) {
      findDateColumn.Search.Value = this.datepipe.transform(
        this.setnewDate(event),
        "MM/dd/yyyy"
      );
      this.searchDate = this.datepipe.transform(
        this.setnewDate(event),
        "yyyy-MM-dd"
      );
    }
  }
  endWorkDateChange(event) {
    this.searchDate = null;
    const findDateColumn = this.searchSort.Columns.find(
      (x) => x.Name === "SubmitedDate"
    );
    if (!!findDateColumn) {
      findDateColumn.Search.endDate = this.datepipe.transform(
        this.setnewDate(event),
        "MM/dd/yyyy"
      );
      this.searchDate = this.datepipe.transform(
        this.setnewDate(event),
        "yyyy-MM-dd"
      );
    }
  }
  managerChange(managerId) {
    const findManagerColumn = this.searchSort.Columns.find(
      (x) => x.Name === "ReviewByObj.companyUsername"
    );
    if (!!findManagerColumn) {
      if (!!managerId) {
        findManagerColumn.Search.Value = this.managerList.find((x) => x.userId == managerId)['companyUsername'].toLowerCase();
      } else {
        findManagerColumn.Search.Value = "";
      }
    }

  }
  employeeChange(event) { }
  downloadFile() {
    const downloadMethod = this.requestType !== 'approvedOffer' ?
      this.reviewService.downloadPendingExcelFile(this.searchSort) :
      this.reviewService.downloadClosedExcelFile(this.searchSort);
    downloadMethod.then(response => {
      if (response['Success']) {
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.href = environment.BLOB_URL + response['Message'];
        a.download = response['Message'].split('/')[1];
        a.click();
      } else {
        this.utilityService.showErrorToast(response['Message']);
      }
    }, err => { });;
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clearText();
      }
    }
  }
  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }
  filterData() {
    if(!!this.userNameValue.nativeElement.value){
      const findUserNameColumn = this.searchSort.Columns.find(
        (x) => x.Name === "Username"
      );
      if (!!findUserNameColumn) {
        findUserNameColumn.Search.Value =  this.userNameValue.nativeElement.value.toLowerCase();
      }
    }
    const filterMethod = this.requestType === Constants.REQUESTED_OFFER ? this.reviewService.employeeReviewPendingDataFilter(this.searchSort)
      : this.reviewService.employeeReviewClosedDataFilter(this.searchSort);
    filterMethod.then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.reviewRequestList = res['data'];
        this.totalItems = res['recordsFiltered'];
      } else {
        this.totalItems = 0;
      }
    }, err => { this.reviewRequestList = []; this.totalItems = 0; });
  }

  clear() {
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.searchSort.Columns.forEach(element => {
      element.Search.Value = '';
    });
    this.filterTextValue.nativeElement.value = '';
    this.startDate.nativeElement.value = '';
    this.endDate.nativeElement.value = '';
    this.managerValue.nativeElement.value = '';
    this.userNameValue.nativeElement.value = '';
    this.setPage({ offset: 0 });

  }
  clearText() {
    this.searchSort.Search.Value = '';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }
  openModal(template: TemplateRef<any>, data: any, viewMode = false) {
    this.viewMode = viewMode;
    if (data) {
      this.myOffer = data;
    } else {
      this.myOffer = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }
  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 },event);
    }
    this.modalRef.hide();
  }
  close(event) {
    this.isConfirmed = false;
  }
  openApprove(data) {
    this.Id = data.Id
    this.isConfirmed = true;
    this.approveMsg = 'Are you sure you want to approve this request?'
  }
  userNameChange(event) {
    if (event.target.value == "") {
      this.clear();
    } else {
      const findUserNameColumn = this.searchSort.Columns.find(
        (x) => x.Name === "UserName"
      );
      if (!!findUserNameColumn) {
        findUserNameColumn.Search.Value = event.currentTarget.value.toLowerCase();
      }
      this.filterData();
    }
  }
}
