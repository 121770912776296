import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared/shared.module';
import { ProfileComponent } from './profile.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule } from '@angular/forms';
import { ProfileRoutingModule } from './profile-routing.module';
import { UserService } from '../service/user.service';
import { CompanyService } from '../service/company.service';
import { LocationService } from '../service/location.service';
import { DepartmentService } from '../service/department.service';
import { ShiftService } from '../service/shift.service';
import { OfferService } from '../service/offer.service';

@NgModule({
    declarations: [ProfileComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        NgMultiSelectDropDownModule,
        ProfileRoutingModule
    ],
    providers: [UserService, CompanyService, LocationService, DepartmentService, ShiftService, OfferService]
})
export class ProfileModule { }
