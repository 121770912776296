import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { drpSetting, Constants, dropdownList, phoneMask } from '../constant/constants';
import { SignUpMessageList } from '../models/signup-model';
import { Role } from '../models/role-model';
import { UserService } from '../service/user.service';
import { CompanyService } from '../service/company.service';
import { ShiftService } from '../service/shift.service';
import { DepartmentService } from '../service/department.service';
import { LocationService } from '../service/location.service';
import { FormService } from '../core/form.service';
import { defaultRoutes } from '../core/auth/default-routes';
import { OfferService } from '../service/offer.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  dropdownList = dropdownList;
  selectedItems = [];
  dropdownSettings = drpSetting.dropdownSettings;
  isConfirm = false;
  confirmMsg = Constants.COMPANY_CHANGE_CONFIRM_MSG;
  userForm: FormGroup;
  public messageList: any = new SignUpMessageList();
  roleEnum = Role;
  companyId: number;
  companyList = [];
  role: number;
  locationList = [];
  departmentList = [];
  managerList = [];
  subDepartmentList = [];
  shiftList = [];
  user: any;
  isCompanyChange = false;
  allShiftList = [];
  allSubDepartmentList = [];
  hasPendingOffer = false;
  phoneMask = phoneMask;

  constructor(private router: Router,
    private formService: FormService,
    private toaster: ToastrService,
    private userService: UserService,
    private companyService: CompanyService,
    private locationService: LocationService,
    private departmentService: DepartmentService,
    private shiftService: ShiftService,
    private activeRoute: ActivatedRoute,
    private offerService: OfferService) {
    this.user = this.activeRoute.snapshot.data.user.Data;
  }

  ngOnInit() {
    this.isCompanyChange = false;
    this.isConfirm = false;
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.getManager();
    this.initializeUserForm();
    this.initializeMessages();
    this.getCompany();
    this.getLocation();
    this.getDepartment();
    this.getSubDepartment();
    this.getShift();
  }

  initializeUserForm() {
    this.userForm = new FormGroup(
      {
        userId: new FormControl(!!this.user ? this.user.userId : 0),
        emailId: new FormControl(!!this.user ? this.user.emailId : ''),
        name: new FormControl(!!this.user ? this.user.name : '', Validators.required),
        vCode: new FormControl(!!this.user ? this.user.vCode : ''),
        companyId: new FormControl(!!this.user ? this.user.companyId : this.companyId, Validators.required),
        departmentId: new FormControl(!!this.user ? this.user.departmentId : null, Validators.required),
        subDepartmentId: new FormControl(!!this.user ? this.user.subDepartmentId : null),
        managerId: new FormControl(!!this.user ? this.user.managerId : null),
        locationId: new FormControl(!!this.user ? this.user.locationId : null, Validators.required),
        isRecievingTextMessage: new FormControl(!!this.user ? this.user.isRecievingTextMessage : false),
        phone: new FormControl(!!this.user ? this.user.phone : '', [
          Validators.required,
          // Validators.pattern(Constants.REGEX.PHONE_PATTERN)
        ]),
        companyUsername: new FormControl(!!this.user ? this.user.companyUsername : '', Validators.required),
        employeeId: new FormControl(!!this.user ? this.user.employeeId : '', Validators.required),
        roleId: new FormControl(!!this.user ? this.user.roleId : localStorage.getItem(Constants.ROLE)),
        shiftId: new FormControl(!!this.user ? this.user.shiftId : null, Validators.required),
        isActive: new FormControl(!!this.user ? this.user.isActive : true),
        GetCommunicationMethod: new FormControl(!!this.user ? this.user.GetCommunicationMethod : this.selectedItems,Validators.required),
        password: new FormControl(!!this.user ? this.user.password : ''),
        confirmPassword: new FormControl(''),
        Roles:new FormControl(!!this.user ? this.user.Roles : '')
      });
    if (!!this.userForm.controls.GetCommunicationMethod.value) {
      this.userForm.controls.GetCommunicationMethod.value.forEach(element => {
        this.selectedItems.push(this.dropdownList[Number(element - 1)]);
      });
      this.userForm.get('GetCommunicationMethod').patchValue(this.selectedItems);
    } else {
      this.userForm.get('GetCommunicationMethod').patchValue([]);
    }
    this.setOptionalField(this.userForm.controls.roleId.value);
    !!this.userForm.controls.companyUsername.value ? this.userForm.controls['companyUsername'].disable() : this.userForm.controls['companyUsername'].enable();
    !!this.userForm.controls.employeeId.value ? this.userForm.controls['employeeId'].disable() : this.userForm.controls['employeeId'].enable();
  }

  initializeMessages() {
    this.messageList.name = {
      required: Constants.VALIDATION_MSG.SIGN_UP.NAME_REQUIRED,
    };
    this.messageList.companyId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMPANY_REQUIRED,
    };
    this.messageList.departmentId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.DEPARTMENT_REQUIRED,
    };
    this.messageList.subDepartmentId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.DEPARTMENT_REQUIRED,
    };
    this.messageList.locationId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.LOCATION_REQUIRED,
    };
    this.messageList.phone = {
      required: Constants.VALIDATION_MSG.SIGN_UP.PHONE_REQUIRED,
      // pattern: Constants.VALIDATION_MSG.SIGN_UP.PHONE_INVALID
    };
    this.messageList.companyUsername = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMPANY_NAME_REQUIRED,
    };
    this.messageList.employeeId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.EMPLOYEEID_REQUIRED,
    };
    this.messageList.shiftId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.SHIFT_REQUIRED,
    };
    this.messageList.companyId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMPANY_REQUIRED,
    };
    this.messageList.GetCommunicationMethod ={
      required :Constants.VALIDATION_MSG.SIGN_UP.COMMUNICATION_METHOD_REQUIRED
    }
  }

  getCompany() {
    this.companyService.getCompanyListWithOutPagination().then(res => {
      if (res['Success']) {
        this.companyList = res['Data'];
      } else { this.companyList = []; }
    }, err => { this.companyList = []; });
  }

  onCompanyChange(event: any) {
    this.isCompanyChange = true;
    const id = Number(event.currentTarget.value);
    if (!!id) {
      this.companyId = id;
      this.getDepartment();
      this.getShift();
      this.getLocation();
      this.getSubDepartment();
      this.getManager();
    } else {
      this.companyId = 0;
      this.departmentList = [];
      this.subDepartmentList = [];
      this.shiftList = [];
      this.locationList = [];
      this.managerList = [];
    }
    this.userForm.controls.departmentId.setValue(null);
    this.userForm.controls.subDepartmentId.setValue(null);
    this.userForm.controls.shiftId.setValue(null);
    this.userForm.controls.locationId.setValue(null);
    this.userForm.controls.managerId.setValue(null);
  }

  getLocation() {
    this.locationService.getLocationListByCompany(this.companyId, null).then(res => {
      if (res['Success']) {
        this.locationList = res['Data'];
      } else { this.locationList = []; }
    }, err => { this.locationList = []; });
  }

  getDepartment() {
    this.departmentService.getDepartmentListByCompanyId(null, this.companyId).then(res => {
      if (res['Success']) {
        this.departmentList = res['Data'];
      } else { this.departmentList = []; }
    }, err => { this.departmentList = []; });
  }

  getManager() {
    this.departmentService.getManagerListByCompanyId(this.companyId).then(res => {
      if (res['Success']) {
        this.managerList = res['Data'];
      } else { this.managerList = []; }
    }, err => { this.managerList = []; });
  }

  getSubDepartment() {
    this.departmentService.getSubDepartmentListByCompanyId(null, this.companyId).then(res => {
      if (res['Success']) {
        this.allSubDepartmentList = res['Data'];
        this.subDepartmentList = [];
        if (!!this.user && !!this.user.departmentId) {
          this.onDepartmentChange(this.user.departmentId);
        }
      } else { this.subDepartmentList = []; this.allSubDepartmentList = [];}
    }, err => { this.subDepartmentList = []; this.allSubDepartmentList = [];});
  }

  getShift() {
    this.shiftService.getShiftListByCompanyId(null, this.companyId).then(res => {
      if (res['Success']) {
        this.allShiftList = res['Data'];
        this.shiftList = [];
        if (!!this.user && !!this.user.departmentId) {
          this.onDepartmentChange(this.user.departmentId);
        }
      } else { this.shiftList = []; this.allShiftList = []; }
    }, err => { this.shiftList = []; this.allShiftList = []; })
  }

  cancel() {
    this.isConfirm = false;
    this.router.navigate([defaultRoutes[Number(localStorage.getItem(Constants.ROLE))]]);
  }

  onItemSelect(item: any) {
    this.selectedItems.push(item);
    this.userForm.controls.GetCommunicationMethod.setValue(this.selectedItems);
  }
  onSelectAll(items: any) {
    this.selectedItems = items;
    this.userForm.controls.GetCommunicationMethod.setValue(this.selectedItems);
  }

  onItemDeSelect(items: any) {
    this.selectedItems = this.selectedItems.filter(x => x.id !== items.id);
    this.userForm.controls.GetCommunicationMethod.setValue(this.selectedItems);
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.userForm.controls.GetCommunicationMethod.setValue(this.selectedItems);
  }

  onSubmit() {
    if (this.isCompanyChange) {
      this.offerService.hasPendingRequest(Number(localStorage.getItem(Constants.USERID))).then(res => {
        if (res) {
          if (res['Success']) {
            this.hasPendingOffer = true;
            this.confirmMsg = Constants.OFFER_MSG_WHILE_COMPANY_CHANGE;
          } else {
            this.hasPendingOffer = false;
            this.confirmMsg = Constants.COMPANY_CHANGE_CONFIRM_MSG
          }
          this.isConfirm = true;

        }
      }, err => { });
    } else {
      this.updateUserData();
    }
  }

  confirmCompanyChange(event) {
    this.isConfirm = false;
    if (this.userForm.invalid) {
      this.formService.markFormGroupTouched(this.userForm);
      return false;
    } else {
      this.updateUserData();
    }
  }

  updateUserData() {
    this.formService.markFormGroupTouched(this.userForm);
    if (this.userForm.invalid) {
      return false;
    }
    if (this.userForm.value.GetCommunicationMethod.length > 0) {
      const communicationMethod = this.userForm.value.GetCommunicationMethod.map(x => x.id.toString());
      this.userForm.get('GetCommunicationMethod').patchValue(communicationMethod);
    } else {
      this.userForm.controls.GetCommunicationMethod.setValue([]);
    }

    this.userForm.controls.companyId.setValue(Number(this.userForm.controls.companyId.value));
    this.userForm.controls.locationId.setValue(!!this.userForm.controls.locationId.value ? Number(this.userForm.controls.locationId.value) : null);
    this.userForm.controls.managerId.setValue(!!this.userForm.controls.managerId.value ? Number(this.userForm.controls.managerId.value) : null);
    this.userForm.controls.departmentId.setValue(!!this.userForm.controls.departmentId.value ? Number(this.userForm.controls.departmentId.value) : null);
    this.userForm.controls.subDepartmentId.setValue(!!this.userForm.controls.subDepartmentId.value ? Number(this.userForm.controls.subDepartmentId.value) : null);
    this.userForm.controls.shiftId.setValue(!!this.userForm.controls.shiftId.value ? Number(this.userForm.controls.shiftId.value) : null);
    this.userForm.controls.employeeId.setValue(!!this.userForm.controls.employeeId.value ? this.userForm.controls.employeeId.value : null);
   
    const Data = {
      ...this.userForm.value,
      employeeId: this.userForm.controls.employeeId.value,
      companyUsername: this.userForm.controls.companyUsername.value
    };
    delete Data.confirmPassword;
    if (this.isCompanyChange) {
      this.offerService.deleteOfferForUser(Number(localStorage.getItem(Constants.USERID))).then(res => {
        if (res['Success']) {
          this.apiCall(Data);
        }
      }, err => { });
    } else {
      this.apiCall(Data);
    }

  }

  apiCall(Data) {
    this.userService.updateUser(Data).then((res: any) => {
      if (res['Success']) {
        
        this.toaster.success(Constants.PROFILE_SUCCESS_MSG);
        localStorage.setItem(Constants.USERNAME, res['Data'].companyUsername);
        localStorage.setItem(Constants.COMPANYID, res['Data'].companyId);
        localStorage.setItem(Constants.USERID, res['Data'].userId);
        localStorage.setItem(Constants.SHIFTID, res['Data'].shiftId);
        localStorage.setItem(Constants.DEPARTMENTID, res['Data'].departmentId);
        localStorage.setItem(Constants.SUBDEPARTMENTID, res['Data'].subdepartmentId);
        localStorage.setItem(Constants.LOCATIONID, res['Data'].locationId);
        localStorage.setItem(Constants.MANAGERID, res['Data'].managerId);
        if (this.isCompanyChange) {
          this.isConfirm = false;
          this.router.navigate(['']);
        } else {
          this.router.navigate([defaultRoutes[Number(localStorage.getItem(Constants.ROLE))]]);
        }
      } else {
        this.toaster.error(res['Message']);
      }
    });
  }

  setOptionalField(value) {
    this.userForm.controls.departmentId.setValidators(value !== 5 ? null : [Validators.required]);
    this.userForm.controls.shiftId.setValidators(value !== 5 ? null : [Validators.required]);
    this.userForm.controls.locationId.setValidators(value !== 5 ? null : [Validators.required]);
    this.userForm.controls.employeeId.setValidators(null);
    this.userForm.controls.departmentId.updateValueAndValidity();
    this.userForm.controls.shiftId.updateValueAndValidity();
    this.userForm.controls.employeeId.updateValueAndValidity();
    this.userForm.controls.locationId.updateValueAndValidity();
  }

  onDepartmentChange(event) {
    if (!!event.currentTarget) {
      if (!!event.currentTarget.value) {
        this.shiftList = this.allShiftList;
        this.subDepartmentList = this.allSubDepartmentList;
        this.shiftList = this.shiftList.filter(x => x.departmentId === Number(event.currentTarget.value));
        this.subDepartmentList = this.subDepartmentList.filter(x => x.departmentId === Number(event.currentTarget.value));
        this.userForm.controls.shiftId.setValue('null');
        this.userForm.controls.subDepartmentId.setValue('null');
      } else {
        this.shiftList = [];
        this.subDepartmentList = [];
        this.userForm.controls.shiftId.setValue('null');
        this.userForm.controls.subDepartmentId.setValue('null');
      }
    } else {
      if (!!event) {
        this.shiftList = this.allShiftList;
        this.shiftList = this.shiftList.filter(x => x.departmentId === Number(event));
        this.subDepartmentList = this.allSubDepartmentList;
        this.subDepartmentList = this.subDepartmentList.filter(x => x.departmentId === Number(event));
      } else {
        this.shiftList = [];
        this.subDepartmentList = [];
        this.userForm.controls.shiftId.setValue('');
        this.userForm.controls.subDepartmentId.setValue('');
      }
    }
  }

}
