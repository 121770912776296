import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ThreeDotsVertical } from 'ngx-bootstrap-icons';
import { Constants } from 'src/app/constant/constants';
import { FormService } from 'src/app/core/form.service';
import { SubscriptionEmail } from 'src/app/models/subscriptionemail.model';
import { ContactService } from 'src/app/service/contact.service';

import { UtilityService } from 'src/app/service/utility.service';


@Component({
  selector: 'app-schedule-request',
  templateUrl: './schedule-request.component.html',
  styleUrls: ['./schedule-request.component.scss']
})
export class ScheduleRequestComponent implements OnInit {
  public subscriptionEmailForm: FormGroup;
  public messageList: any = new SubscriptionEmail();
  constructor(private formService: FormService, 
    private contactService: ContactService,
    private utilityService: UtilityService) { }

  ngOnInit() {
    this.subscriptionEmailForm = new FormGroup({
      email: new FormControl('', [Validators.required,  Validators.pattern(Constants.REGEX.EMAIL_PATTERN)]),
    });
    this.initializeMessages();
  }

  
  initializeMessages() {
    this.messageList.email = {
      required: Constants.VALIDATION_MSG.SUBSCRIPTION_EMAIL.EMAIL_REQUIRED,
    };
  }

  subscriptionEmail() {
    this.formService.markFormGroupTouched(this.subscriptionEmailForm);
    if (this.subscriptionEmailForm.invalid) {
      return;
    }
    let emailData =  this.subscriptionEmailForm.controls.email.value;
    this.contactService.subscriptionByEmail(emailData).then((res: any) => {
      if (res.Success && res.Success === true) {
        this.utilityService.showSuccessToast(res['Message']);
        this.subscriptionEmailForm.reset();
      } else { this.utilityService.showErrorToast(res['Message']); }
    }, err => {
      this.utilityService.showErrorToast(err.error.error_description);
    });
    
  }
}
