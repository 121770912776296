import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from '../constant/constants';

@Injectable()
export class MarketCampaignService {

    constructor(private apiService: ApiService) { }

    add(data) { //dataFilter
        return this.apiService.post(`${API.MARKET_CAMPAIGN.add}`, data, true);
      }
}