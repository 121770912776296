import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CallOffReasonRoutingModule } from './call-off-reason-routing.module';
import { CallOffReasonComponent } from './call-off-reason.component';
import { SharedModule } from './../shared/shared/shared.module';
import { DirectiveModule } from './../shared/shared/directive/directive.module';
import { CompanyService } from '../service/company.service';
import { DepartmentService } from '../service/department.service';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { AddEditComponent } from './add-edit/add-edit.component';


@NgModule({
  declarations: [CallOffReasonComponent, AddEditComponent],
  imports: [
    SharedModule,
    CallOffReasonRoutingModule,
    CallOffReasonRoutingModule,
    DirectiveModule,
    NgxBootstrapIconsModule.pick(allIcons),
  ],
  providers: [DepartmentService, CompanyService]
})
export class CallOffReasonModule { }
