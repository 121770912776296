export class Offer {
    offerId: number;
    dateToSkip: Date;
    dateToWork: Date;
    departmentId: number;
    shiftToSkip: number;
    shiftToWork: number;
    companyId: number;
    status: number;
    createdBy: string;
    createdDate: string;
    timeType: number;
    vtoStartTime: string;
    vtoEndTime: string;
    vtoType: number;
    vtoCount: number;
    isVtoSms: boolean;
    ReasonId : string;
    OtherReason : string;
    IsHRCallBack:boolean;
    IsPaidOff:boolean;
    IsFMLA:boolean;
    uTOHours : number;
    isUtoBalance:boolean;
    isWarningCompleted:boolean;
    approvedBy:number;
    approvedDate:string;
    offerType:number;
    UTOwarningStr: string;
    FlexWorkType: number;
    preferenceId:number;
    isPit:boolean;
    subDepartmentId:number;
    startDate:Date;
    reason:string;
    isHourEarly:boolean;
    isHourLate:boolean;
    hourValue:string;
    LocationId: number;
    Name : string;  
    HardwareIdentifier : string;
    KioskId:number;
    location: string;
    name : string;  
    hardwareIdentifier : string;
    kioskId:number;
    TimePunchesId:number;
    PunchTime:string;
    AdjustedPunchTime:string;
    PunchType: number;
    PunchLocation: number; 
    Source: number;
    Compliance: number;
    Id: number;
    ReviewType: number;
    Strength: string; 
    Opportunities: string; 
    OtherNote: string; 
    ManagerNote: string; 
    SelfRating:  number;
    ManagerRating: number;
    Status: String;
    IsVisibleToEmployee:boolean;
    UserName:string;
    SubmitedDate:Date;
    CreatedDate;
    IsDateCrossOver:boolean;
}

export class VtoOffer {
    offerId: number;
    departmentId: number;
    date: Date;
    startTime: string;
    endTime: string;
    noOfUsers: number;
}
export class EarlyGoOffer {
    date: Date;
    startTime: string;
    endTime: string;
    reason:string;
    utoHours:string;
}
export class TransferOffer {
    departmentId:number;
    subDepartmentId:number;
    shiftToWork:number;
	preferenceId:number;
	isPit:boolean;
}
export class LateInOffer {
    date: Date;
    startTime: string;
    endTime: string;
    reason:string;
    utoHours:string;
}
export class Kiosk {
    kioskId:number;
    location: string;
    kioskName: string;
    hardwareIdentifier:string;
  
}

export class IssueReportModel{
    subject: number;
    body: string;
}