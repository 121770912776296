import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from '../constant/constants';

@Injectable()
export class DepartmentService {

  constructor(private apiService: ApiService) { }

  getDepartmentList(currentPage) {
    return this.apiService.get(
      `${API.DEPARTMENT_ROUTES.departmentGetNew}?page=` +
      currentPage,
      false,
      true,
    );
  }

  getDepartmentListByCompanyId(currentPage, companyId,isStopLoader = true,isShowLoader = true) {
    return this.apiService.get(
      `${API.DEPARTMENT_ROUTES.getDepartmentByCompany}?companyId=` + companyId
      + `&page=` + currentPage,
      true,
      isStopLoader,
      isShowLoader
    );
  }

  getManagerListByCompanyId(companyId) {
    return this.apiService.get(
      `${API.USER_ROUTE.getAllManagerListByCompany}?&companyId=`+ companyId,
      true,);
  }

  addDepartment(params) {
    return this.apiService.post(`${API.DEPARTMENT_ROUTES.saveDepartment}`, params, true);
  }

  updatedDepartment(params) {
    return this.apiService.put(`${API.DEPARTMENT_ROUTES.saveDepartment}`, params, true);
  }

  deleteDepartment(Id) {
    return this.apiService.delete(
      `${API.DEPARTMENT_ROUTES.deleteDepartment}` + Id + `?deletedBy=` + localStorage.getItem(Constants.USERID),
      true,
    );
  }

  dataFilter(data) { //dataFilter
    return this.apiService.post(`${API.DEPARTMENT_ROUTES.dataFilter}`, data, true);
  }

  getDepartmentById(Id) {
    return this.apiService.get(
      `${API.DEPARTMENT_ROUTES.getDepartmentById}/` + Id,
      true,
      true,
    );
  }
    
  //SUB DEPARTMENT RELATED FUNCTIONS STARTS HERE

  getSubDepartmentListByCompanyId(currentPage, companyId){
    return this.apiService.get(
      `${API.SUB_DEPARTMENT_ROUTES.getSubDepartmentByCompany}?companyId=` + companyId
      + `&page=` + currentPage,
      true,
      true,
    );
  }
  deleteSubDepartment(Id) {
    return this.apiService.delete(
      `${API.SUB_DEPARTMENT_ROUTES.deleteSubDepartment}`  + `?id=` + Id,
      true,
    );
  }
  SubDepartmentDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.SUB_DEPARTMENT_ROUTES.subDepartmentDataFilter}`, data, true);
  }

  addSubDepartment(params) {
    return this.apiService.post(`${API.SUB_DEPARTMENT_ROUTES.addSubDepartment}`, params, true);
  }

  updatedSubDepartment(params) {
    return this.apiService.put(`${API.SUB_DEPARTMENT_ROUTES.updateSubDepartment}`, params, true);
  }

  getSubDeptByDepartment(departmentId,companyId) {
    return this.apiService.get(
      `${API.SUB_DEPARTMENT_ROUTES.getSubDepartmentByDepartmentId}?departmentId=` + departmentId + `&companyId=` + companyId,
      false,
      true,
    );
  }
}
