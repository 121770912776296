
import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Constants, ContentEnum } from 'src/app/constant/constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UtilityService } from 'src/app/service/utility.service';
import { AuthService } from 'src/app/core/auth/auth.service';


@Component({
  selector: 'app-employeer-requested-offer',
  templateUrl: './employeer-requested-offer.component.html',
  styleUrls: ['./employeer-requested-offer.component.scss']
})
export class EmployeerRequestedOfferComponent implements OnInit {
  @ViewChild('template', { static: false }) template: TemplateRef<any>;
  // @Input() requestType: string;
  isSwapRequests = false;
  isVOTRequests = false;
  isTransferRequests = false;
  isTrainingRequests = false;
  requestType = Constants.REQUESTED_OFFER;
  isShowSwap = false;
  isShowVot = false;
  isShowTransfer = false;
  isShowTraning = false;
  isShowVto = false;
  isVTORequests = false;
  isFlexRequests = false;
  isShowFlex = false;
  isFlexWorkRequests = false;
  isShowCallOff = false;
  isCallInRequest = false;
  isEarlyGoRequest = false;
  isLateInRequest = false;
  isShowEarlyOut = false;
  isShowLateIn = false;
  isShowCallInOut = false;
  modalRef: BsModalRef;
  btnText = Constants.BTN_TEXT;
  cancleBtnText = Constants.CANCLE_BTN_TEXT;
  data: any;
  title = Constants.TERMS_CONDITION;
  isShowTimeOffRequest = false;
  isTimeOffRequest = false;
  isShowCallInOutRequest = false;
  isCallInOutRequest = false;
  contentType = ContentEnum;
  isClockInOutRequest = false;
  isShowClockInOut = false;
  count: number;
  isReviewRequest = false;
  isShowReview= false;
  @Input() ShowCloseRequestOption:boolean;
  @ViewChild('noModule', { static: true }) noModule: ElementRef;


  constructor(private modalService: BsModalService,
    private utilityService: UtilityService,
    private authService: AuthService) {
    // KISHAN : https://sharing.clickup.com/t/h/29fve13/HCKCSDFJZ7QCSQ0
    // if (JSON.parse(localStorage.getItem(Constants.IS_SWAP_REQUEST)) === null) {
    // this.isSwapRequests = true;
    localStorage.setItem(Constants.IS_SWAP_REQUEST, String(this.isSwapRequests));
    localStorage.setItem(Constants.IS_VOT_REQUEST, String(this.isVOTRequests));
    localStorage.setItem(Constants.IS_TRANSFER_REQUEST, String(this.isTransferRequests));
    localStorage.setItem(Constants.IS_TRAINING_REQUEST, String(this.isTrainingRequests));
    localStorage.setItem(Constants.IS_VTO_REQUEST, String(this.isVTORequests));
    localStorage.setItem(Constants.IS_CALL_IN_REQUEST, String(this.isCallInRequest));
    localStorage.setItem(Constants.IS_EARLY_GO_REQUEST, String(this.isEarlyGoRequest));
    localStorage.setItem(Constants.IS_FLEX_WORK_REQUEST, String(this.isFlexWorkRequests));
    localStorage.setItem(Constants.IS_TIME_OFF_REQUEST, String(this.isTimeOffRequest));
    localStorage.setItem(Constants.IS_CLOCKINOUT_REQUEST, String(this.isClockInOutRequest));
    localStorage.setItem(Constants.IS_REVIEW_REQUEST, String(this.isReviewRequest));
    //}
  }

  async ngOnInit() {
    await this.getPendingRequestsCount();
    this.isShowSwap = JSON.parse(localStorage.getItem(Constants.IS_SWAP));
    this.isShowVot = JSON.parse(localStorage.getItem(Constants.IS_VOT));
    this.isShowTransfer = JSON.parse(localStorage.getItem(Constants.IS_TRANSFER));
    this.isShowTraning = JSON.parse(localStorage.getItem(Constants.IS_TRAINING));
    this.isShowTimeOffRequest = JSON.parse(localStorage.getItem(Constants.IS_TIME_OFF));
    this.isShowVto = JSON.parse(localStorage.getItem(Constants.IS_VTO));
    this.isShowFlex = JSON.parse(localStorage.getItem(Constants.IS_FLEX_WORK));
    this.isShowCallOff = JSON.parse(localStorage.getItem(Constants.IS_CALL_OFF));
    this.isShowEarlyOut = JSON.parse(localStorage.getItem(Constants.IS_EARLY_OUT));
    this.isShowLateIn = JSON.parse(localStorage.getItem(Constants.IS_LATE_IN));
    this.isShowClockInOut = JSON.parse(localStorage.getItem(Constants.IS_CLOCKINOUT));
    this.isShowReview = JSON.parse(localStorage.getItem(Constants.IS_REVIEW));


    if (this.isShowSwap) {
      this.isSwapRequests = true;
      localStorage.setItem(Constants.IS_SWAP_REQUEST, String(this.isSwapRequests));
    }
    else if (this.isShowVot) {
      this.isVOTRequests = true;
      localStorage.setItem(Constants.IS_VOT_REQUEST, String(this.isVOTRequests));
    }
    else if (this.isShowVto) {
      this.isVTORequests = true;
      localStorage.setItem(Constants.IS_VTO_REQUEST, String(this.isVTORequests));
    }
    else if (this.isShowCallOff) {
      this.isCallInRequest = true;
      localStorage.setItem(Constants.IS_CALL_IN_REQUEST, String(this.isCallInRequest));
    }
    else if (this.isShowEarlyOut) {
      this.isEarlyGoRequest = true;
      localStorage.setItem(Constants.IS_EARLY_GO_REQUEST, String(this.isEarlyGoRequest));
    }
    else if (this.isShowFlex) {
      this.isFlexWorkRequests = true;
      localStorage.setItem(Constants.IS_FLEX_WORK_REQUEST, String(this.isFlexWorkRequests));
    }
    else if (this.isShowTransfer) {
      this.isTransferRequests = true;
      localStorage.setItem(Constants.IS_TRANSFER_REQUEST, String(this.isTransferRequests));
    }
    else if (this.isShowTraning) {
      this.isTrainingRequests = true;
      localStorage.setItem(Constants.IS_TRAINING_REQUEST, String(this.isTrainingRequests));
    }
    else if (this.isShowTimeOffRequest) {
      this.isTimeOffRequest = true;
      localStorage.setItem(Constants.IS_TIME_OFF_REQUEST, String(this.isTimeOffRequest));
    }
    else if (this.isShowLateIn) {
      this.isLateInRequest = true;
      localStorage.setItem(Constants.IS_LATE_IN_REQUEST, String(this.isLateInRequest));
    }
    else if (this.isShowClockInOut) {
      this.isClockInOutRequest = true;
      localStorage.setItem(Constants.IS_CLOCKINOUT_REQUEST, String(this.isClockInOutRequest));
    }  
    else if (this.isShowReview) {
      this.isReviewRequest = true;
      localStorage.setItem(Constants.IS_REVIEW_REQUEST, String(this.isReviewRequest));
    }
    else{
      let elementClass: any = document.getElementsByClassName("sub");
      if (elementClass.length > 0)
        elementClass[0].style.border = 'none'
      // this.noModule.nativeElement.innerHTML="";
      // this.noModule.nativeElement.innerHTML ="<h1> No modules subscribed, Please contact admin for subscription</h1>";
    }
    

    localStorage.setItem(Constants.IS_SWAP_REQUEST, String(this.isSwapRequests));
    localStorage.setItem(Constants.IS_VOT_REQUEST, String(this.isVOTRequests));
    localStorage.setItem(Constants.IS_VTO_REQUEST, String(this.isVTORequests));
    localStorage.setItem(Constants.IS_CALL_IN_REQUEST, String(this.isCallInRequest));
    localStorage.setItem(Constants.IS_EARLY_GO_REQUEST, String(this.isEarlyGoRequest));
    localStorage.setItem(Constants.IS_FLEX_WORK_REQUEST, String(this.isFlexWorkRequests));
    localStorage.setItem(Constants.IS_TRANSFER_REQUEST, String(this.isTransferRequests));
    localStorage.setItem(Constants.IS_TRAINING_REQUEST, String(this.isTrainingRequests));
    localStorage.setItem(Constants.IS_TIME_OFF_REQUEST, String(this.isTimeOffRequest));
    localStorage.setItem(Constants.IS_LATE_IN_REQUEST, String(this.isLateInRequest));
    localStorage.setItem(Constants.IS_CLOCKINOUT_REQUEST, String(this.isClockInOutRequest));
    localStorage.setItem(Constants.IS_REVIEW_REQUEST, String(this.isReviewRequest));
    this.utilityService.hideLoading();
  }

  getOffer(data) {
    this.isSwapRequests = false;
    this.isVOTRequests = false;
    this.isTransferRequests = false;
    this.isTrainingRequests = false;
    this.isVTORequests = false;
    this.isCallInRequest = false;
    this.isEarlyGoRequest = false;
    this.isFlexWorkRequests = false;
    this.isTimeOffRequest = false;
    this.isLateInRequest = false;
    this.isClockInOutRequest = false;
    this.isReviewRequest=false;
    if (data === 1) {
      this.isSwapRequests = true;
    } else if (data === 2) {
      this.isVOTRequests = true;
    } else if (data === 3) {
      this.isTransferRequests = true;
    } else if (data === 4) {
      this.isTrainingRequests = true;
    } else if (data === 5) {
      this.isVTORequests = true;
    } else if (data === 6) {
      this.isCallInRequest = true;
    } else if (data === 7) {
      this.isEarlyGoRequest = true;
    } else if (data === 8) {
      this.isFlexWorkRequests = true;
    } else if (data === 9) {
      this.isTimeOffRequest = true;
    } else if (data === 10) {
      this.isLateInRequest = true;
    } else if (data === 11) {
      this.isClockInOutRequest = true;
    }else if (data === 12) {
      this.isReviewRequest = true;
    }
    else{
      let elementClass: any = document.getElementsByClassName("sub");
      if (elementClass.length > 0)
        elementClass[0].style.border = 'none'
    //   this.noModule.nativeElement.innerHTML ="";
    //  this.noModule.nativeElement.innerHTML ="<h1> No modules subscribed, Please contact admin for subscription</h1>";
    }


    localStorage.setItem(Constants.IS_SWAP_REQUEST, String(this.isSwapRequests));
    localStorage.setItem(Constants.IS_VOT_REQUEST, String(this.isVOTRequests));
    localStorage.setItem(Constants.IS_TRANSFER_REQUEST, String(this.isTransferRequests));
    localStorage.setItem(Constants.IS_TRAINING_REQUEST, String(this.isTrainingRequests));
    localStorage.setItem(Constants.IS_VTO_REQUEST, String(this.isVTORequests));
    localStorage.setItem(Constants.IS_CALL_IN_REQUEST, String(this.isCallInRequest));
    localStorage.setItem(Constants.IS_EARLY_GO_REQUEST, String(this.isEarlyGoRequest));
    localStorage.setItem(Constants.IS_FLEX_WORK_REQUEST, String(this.isFlexWorkRequests));
    localStorage.setItem(Constants.IS_TIME_OFF_REQUEST, String(this.isTimeOffRequest));
    localStorage.setItem(Constants.IS_LATE_IN_REQUEST, String(this.isLateInRequest));
    localStorage.setItem(Constants.IS_CLOCKINOUT_REQUEST, String(this.isClockInOutRequest));
    localStorage.setItem(Constants.IS_REVIEW_REQUEST, String(this.isReviewRequest));
  }

  ngAfterViewInit() {
    if (!!localStorage.getItem(Constants.IS_TERMS_UPDATE) && JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))) {
      this.openTermsModal(this.template, null);
    }
  }

  openTermsModal(template: TemplateRef<any>, data: any) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  checkAgreeDisagree(event) {
    if (event) {
      this.agree();
    } else {
      this.utilityService.showErrorToast(Constants.TERMS_CONDITION_MSG);
    }
  }

  agree() {
    this.authService.agreeForCondition(localStorage.getItem(Constants.USERID)).
      then(response => {
        if (response['Success']) {
          localStorage.setItem(Constants.IS_TERMS_UPDATE, 'false');
          this.modalRef.hide();
        } else {
          this.utilityService.showErrorToast(response['Message']);
        }
      }, err => { });
  }
  async getPendingRequestsCount() {
    await this.authService.pendingRequestCount().
      then(response => {
        if (response['Success']) {
          this.utilityService.hideLoading();
          this.count = response['Data']
        } else { }
      }, err => { });
  }
}
