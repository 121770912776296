import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ForgotpasswordService } from '../service/forgotpassword.service';
import { Constants } from '../constant/constants';
import { ForgotPassword } from '../models/forgotpassword.model';
import { FormService } from '../core/form.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotpasswordForm: FormGroup;
  public messageList: any = new ForgotPassword();
  appName = environment.APP_NAME;
  companyName: string;
  constructor(private toaster: ToastrService, private router: Router,
    private forgotpasswordService: ForgotpasswordService,
    private formService: FormService, private utilityService: UtilityService) {
    this.appName = localStorage.getItem(Constants.APP_NAME);
  }

  ngOnInit() {
    if (window.location.hostname.split('.').length > 2 && window.location.hostname.split('.')[0] !== 'www') {
      this.companyName = window.location.hostname.split('.')[0];
    }
    this.initializeForgotPasswordForm();
    this.initializeMessages();
    this.utilityService.hideLoading();
  }

  initializeForgotPasswordForm() {
    this.forgotpasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(Constants.REGEX.EMAIL_PATTERN)]),
    });
  }

  initializeMessages() {
    this.messageList.email = {
      required: Constants.VALIDATION_MSG.FORGOTPASSWORD.EMAIL_REQUIRED,
      pattern: Constants.VALIDATION_MSG.SIGN_UP.EMAIL_INVALID
    };
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.forgotpasswordForm);
    if (this.forgotpasswordForm.invalid) {
      return;
    }
    this.forgotpasswordService.addEmail(this.forgotpasswordForm.controls.email.value).then((res) => {
      if (res['Success']) {
        this.toaster.success(Constants.EMAIL_ADD_SUCCESS_MSG);
      } else {
        this.toaster.error(!!res['Message'] ? res['Message'] : Constants.VALIDATION_MSG.FORGOTPASSWORD.EMAIL_SERVERDOWN);
      }
      this.router.navigate(['']);
    }, err => { });
  }
}
