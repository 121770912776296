import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';

@Injectable()
export class FlexRequestService {

  constructor(private apiService: ApiService) { }

  addFlexRequestOffer(params) {
    return this.apiService.post(`${API.FLEX_REQUEST_ROUTES.addFlexRequest}`, params, true);
  }

  updateFlexRequestOffer(params) {
    return this.apiService.put(`${API.FLEX_REQUEST_ROUTES.updateFlexRequest}`, params, true);
  }

  getMyFlexRequestList(currentPage, rowsOnPage, date) {
    return this.apiService.get(
      `${API.FLEX_REQUEST_ROUTES.getFlexRequest}?page=` +
      currentPage + `&pageSize=${rowsOnPage}&date=${date}`,
      true,
      true,
    );
  }

  flexRequestOfferDataFilter(data, date) { //dataFilter
    return this.apiService.post(`${API.FLEX_REQUEST_ROUTES.flexRequestOfferDataFilter}?date=${date}`, data, true);
  }

  approveFlexOffer(Id) {
    return this.apiService.put(`${API.FLEX_REQUEST_ROUTES.approveFlexOffer}?flexDetailId=${Id}`, null, true);
  }

  rejectFlexOffer(Id) {
    return this.apiService.put(`${API.FLEX_REQUEST_ROUTES.rejectFlexOffer}?flexDetailId=${Id}`, null, true);
  }

  getHrAdminAvailableFlexRequestList(currentPage, rowsOnPage, date) {
    return this.apiService.get(
      `${API.FLEX_REQUEST_ROUTES.availableHrAdminFlexOffers}?page=` +
      currentPage + `&pageSize=${rowsOnPage}&date=${date}`,
      true,
      true,
    );
  }

  getAvailableFlexRequestList(currentPage, rowsOnPage, companyId) {
    return this.apiService.get(
      `${API.FLEX_REQUEST_ROUTES.availableFlexRequest}?page=` +
      currentPage + `&pageSize=${rowsOnPage}&companyId=${companyId}`,
      true,
      true,
    );
  }

  flexHrAdminrAvailableRequestDataFilter(data, date) { //dataFilter
    return this.apiService.post(`${API.FLEX_REQUEST_ROUTES.flexHrAdminAvailableRequestDataFilter}?date=${date}`, data, true);
  }

  flexAvailableRequestOfferDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.FLEX_REQUEST_ROUTES.availableFlexOfferDataFilter}`, data, true);
  }

  acceptFlexOffer(Id) {
    return this.apiService.put(`${API.FLEX_REQUEST_ROUTES.acceptFlexOffer}?offerID=${Id}`, null, true);
  }

  approveRejectHrAdminFlexOffer(Id, status) {
    return this.apiService.put(`${API.FLEX_REQUEST_ROUTES.approveRejectHrAdminFlexOffer}?offerID=${Id}&status=${status}`, null, true);
  }

  getClosedFlexRequestList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.FLEX_REQUEST_ROUTES.getFlexClosedRequest}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }

  flexClosedRequestDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.FLEX_REQUEST_ROUTES.flexClosedRequestDataFilter}`, data, true);
  }

  downloadClosedExcelFile(data) {
    return this.apiService.post(`${API.FLEX_REQUEST_ROUTES.downloadClosedFlexFile}`, data, true);
  }

  instantFlexRequest(params) {
    return this.apiService.post(`${API.FLEX_REQUEST_ROUTES.instantFlexRequest}?userName=${params.userName}&employeeId=${params.employeeId}&vtoStartTime=${params.vtoStartTime}`, null, true);
  }

  processFlexRequest(ids) {
    return this.apiService.put(`${API.FLEX_REQUEST_ROUTES.processFlexRequest}`, ids, true);
  }
}
