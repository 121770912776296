import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Constants, ContentEnum } from 'src/app/constant/constants';
import { TermsConditionService } from 'src/app/service/terms-condition.service';
import { UtilityService } from 'src/app/service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-coverage-terms-condition',
  templateUrl: './coverage-terms-condition.component.html',
  styleUrls: ['./coverage-terms-condition.component.scss'],
  providers: [TermsConditionService]
})
export class CoverageTermsConditionComponent implements OnInit {
  @Input() data: any;
  content = '';
  @Input() btnText?= Constants.BTN_TEXT;
  @Input() cancleBtnText?= Constants.CANCLE_BTN_TEXT;
  @Output() closePopup = new EventEmitter<boolean>();
  @Input() isBtnDisplayed = true;
  @Input() title: any;
  @Input() contentType: any
  role: number;
  downloadLink: any;
  type = ContentEnum;
  pdfSrc: any;

  constructor(private termsConditionService: TermsConditionService, private utilityService: UtilityService) { }

  ngOnInit() {
    if (this.contentType === this.type.TNC) {
      this.getTermsAndCondition();
    }
    this.role = Number(localStorage.getItem(Constants.ROLE));
  }

  agree() {
    this.closePopup.emit(true);
  }

  disAgree() {
    this.closePopup.emit(false);
  }

  getTermsAndCondition() {
    this.utilityService.showLoading();
    this.termsConditionService.getTermsCondition(this.contentType).
      then(response => {
        if (response['Success']) {
          this.content = response['Data'].htmlContent;
          this.downloadLink = response['Data'].pdfLink;
          this.utilityService.hideLoading();
        }
        else {
          this.utilityService.showErrorToast(response['Message']);
        }
      }, err => { });
  }

  downloadFile() {
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.href = environment.BLOB_URL + this.downloadLink;
    a.download = this.downloadLink.split('/')[1];
    a.target = 'blank';
    a.click();
  }
}
