import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from "@angular/forms";
import {
  bsConfig,
  Constants,
  Duration,
  timeList,
  vtoType,
} from "src/app/constant/constants";
import { FormService } from "src/app/core/form.service";
import { DepartmentService } from "src/app/service/department.service";
import { ToastrService } from "ngx-toastr";
import { VtoOffer, Offer } from "src/app/models/offer.model";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Role } from "src/app/models/role-model";
import { VtoRequestService } from "src/app/service/vto-request.service";
import { DatePipe } from "@angular/common";
import { ShiftService } from "src/app/service/shift.service";

@Component({
  selector: "app-add-edit-vto-request",
  templateUrl: "./add-edit-vto-request.component.html",
  styleUrls: ["./add-edit-vto-request.component.scss"],
})
export class AddEditVtoRequestComponent implements OnInit {
  @ViewChild("confirmationPopup", { static: false })
  confirmationPopup: TemplateRef<any>;
  @Input() VtoOffer: Offer;
  @Output() closeRequest = new EventEmitter<boolean>();
  @Output() cancleRequest = new EventEmitter<boolean>();
  public offerForm: FormGroup;
  bsConfig = bsConfig;
  departmentList = [];
  companyId: number;
  public messageList: any = new VtoOffer();
  modalRef: BsModalRef;
  confirmData: any;
  timeList = Duration;
  role: number;
  roleEnum = Role;
  today = new Date();
  tomorrow = new Date(this.today);
  shiftId: number;
  shiftDetail: any;
  // disableDaysForDateToSkip = [];
  // disableDaysForDateToWork = [];
  weekDayArray = [];
  shiftList: any;
  isChange: boolean = false;
  isSelectedCurrectShift = true;
  isSubmitting: boolean;
  constructor(
    private modalService: BsModalService,
    private formService: FormService,
    private departmentService: DepartmentService,
    private toaster: ToastrService,
    private vtoService: VtoRequestService,
    private datepipe: DatePipe,
    private bsService: BsModalService,
    private shiftService: ShiftService
  ) {}

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.getDepartmentList();
    this.initializeOfferForm();
    this.initializeMessages();
    this.getShiftList();
    if (this.role === this.roleEnum.user) {
      this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    }
  }

  async initializeOfferForm() {
    this.offerForm = new FormGroup({
      offerId: new FormControl(!!this.VtoOffer ? this.VtoOffer.offerId : 0),
      dateToSkip: new FormControl(
        !!this.VtoOffer ? new Date(this.VtoOffer.dateToSkip) : null,
        Validators.required
      ),
      companyId: new FormControl(this.companyId),
      departmentId: new FormControl(
        !!this.VtoOffer ? this.VtoOffer.departmentId : "",
        Validators.required
      ),
      shiftToSkip: new FormControl(
        !!this.VtoOffer ? this.VtoOffer.shiftToSkip : ""
      ),
      vtoStartTime: new FormControl(
        !!this.VtoOffer ? this.VtoOffer.vtoStartTime : "",
        Validators.required
      ),
      vtoEndTime: new FormControl(
        !!this.VtoOffer ? this.VtoOffer.vtoEndTime : "",
        Validators.required
      ),
      vtoType: new FormControl(!!this.VtoOffer ? this.VtoOffer.vtoType : null),
      timeType: new FormControl(
        !!this.VtoOffer ? this.VtoOffer.timeType : "",
        Validators.required
      ),
      vtoCount: new FormControl(
        !!this.VtoOffer ? this.VtoOffer.vtoCount : null
      ),
      status: new FormControl(
        !!this.VtoOffer
          ? this.VtoOffer.status
          : this.role === this.roleEnum.user
          ? 1
          : 4
      ),
      offerType: new FormControl(5),
      isVtoSms: new FormControl(
        !!this.VtoOffer ? !!this.VtoOffer.isVtoSms : true
      ),
      createdBy: new FormControl(
        !!this.VtoOffer ? this.VtoOffer.createdBy : null
      ),
      createdDate: new FormControl(
        !!this.VtoOffer ? this.VtoOffer.createdDate : null
      ),
    });
    if (this.offerForm.controls.offerId.value > 0) {
      this.setTimes(this.VtoOffer.vtoStartTime, this.VtoOffer.vtoEndTime);
    }
    if (this.role === this.roleEnum.manager) {
      this.offerForm.controls.vtoCount.setValidators([
        Validators.required,
        Validators.pattern(Constants.REGEX.LIMIT_PATTERN),
      ]);
      this.offerForm.controls.shiftToSkip.setValidators([Validators.required]);
      this.offerForm.controls.shiftToSkip.updateValueAndValidity();
      this.offerForm.controls.vtoCount.updateValueAndValidity();
    } else {
      this.shiftId = Number(localStorage.getItem(Constants.SHIFTID));
      //await this.getshiftDetail(this.shiftId);
      this.offerForm.controls.shiftToSkip.setValue(this.shiftId);
      this.offerForm.controls.departmentId.setValue(
        Number(localStorage.getItem(Constants.DEPARTMENTID))
      );
    }
    // this code for edit
    // if (!!this.VtoOffer && !!this.VtoOffer.offerId && !!this.VtoOffer.vtoStartTime && !!this.VtoOffer.vtoEndTime) {
    //   const startdate = new Date();
    //   startdate.setHours(
    //     Number(this.VtoOffer.vtoStartTime.split(":")[0]),
    //     Number(this.VtoOffer.vtoStartTime.split(":")[1])
    //   );
    //   const enddate = new Date();
    //   enddate.setHours(
    //     Number(this.VtoOffer.vtoEndTime.split(":")[0]),
    //     Number(this.VtoOffer.vtoEndTime.split(":")[1])
    //   );
    //   this.offerForm.controls.vtoStartTime.patchValue(startdate);
    //   this.offerForm.controls.vtoEndTime.patchValue(enddate);
    // }
    // if (!!this.VtoOffer) {
    //   this.departmentChange(this.VtoOffer.departmentId);
    // }
  }

  initializeMessages() {
    this.messageList.noOfUsers = {
      required: Constants.VALIDATION_MSG.VTO.NO_OF_USER,
      pattern: Constants.VALIDATION_MSG.VTO.NO_Of_USER_PATTERN,
    };
    this.messageList.endTime = {
      required: Constants.VALIDATION_MSG.VTO.END_TIME,
    };
    this.messageList.startTime = {
      required: Constants.VALIDATION_MSG.VTO.START_TME,
    };
    this.messageList.date = {
      required: Constants.VALIDATION_MSG.VTO.DATE,
    };
    this.messageList.departmentId = {
      required: Constants.VALIDATION_MSG.VTO.DEPARTMENT_ID,
    };
    this.messageList.shiftToSkip = {
      required: Constants.VALIDATION_MSG.VTO.SHIFT_ID,
    };
    this.messageList.timeType = {
      required: Constants.VALIDATION_MSG.OFFER.TIME_TYPE_REQUIRED,
    };
  }

  // getshiftDetail(Id) {
  //   return new Promise((resolve, reject) => {
  //     this.shiftService.getShiftDetails(Id).then(res => {
  //       if (res['Success']) {
  //         this.shiftDetail = res['Data'].shiftWeekDay;
  //         this.shiftDetail.map(x => {
  //           if (x.weekday > 0) {
  //             this.weekDayArray.push(x.weekday - 1);
  //           }
  //         });
  //         for (let i = 0; i < 7; i++) {
  //           if (this.weekDayArray.indexOf(i) === -1) {
  //             this.disableDaysForDateToSkip.push(i);
  //           } else {
  //             this.disableDaysForDateToWork.push(i);
  //           }
  //         }
  //         resolve(this.shiftDetail);
  //       }
  //     }, err => { });
  //   });
  // }

  getDepartmentList() {
    this.departmentService
      .getDepartmentListByCompanyId(null, this.companyId)
      .then(
        (res) => {
          if (res["Success"]) {
            this.departmentList = res["Data"];
          } else {
            this.departmentList = [];
          }
        },
        (err) => {
          this.departmentList = [];
        }
      );
  }

  control(controlName: string): AbstractControl {
    return this.offerForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.offerForm);
    if (this.offerForm.invalid) {
      return;
    }
    this.openConfirmationPopup(this.confirmationPopup, this.offerForm.value);
  }

  save() {
    this.formService.markFormGroupTouched(this.offerForm);
    if (this.offerForm.invalid) {
      return;
    }
    // if (!this.isSelectedCurrectShift) {
    //   this.toaster.error(Constants.NO_AVAILABLE_SHIFT);
    //   return;
    // }
    let selectedDateTime = new Date(this.offerForm.controls.dateToSkip.value);
    if (
      selectedDateTime.toDateString() == new Date().toDateString() &&
      new Date(this.offerForm.controls.vtoStartTime.value) < new Date()
    ) {
      this.toaster.error("You cannot add request in past time");
      return;
    }
    this.confirm();
  }

  openConfirmationPopup(template: TemplateRef<any>, data: any) {
    this.confirmData = data;
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-md",
      backdrop: "static",
    });
  }

  cancel() {
    this.modalRef.hide();
  }

  confirm() {
    const mxdate = this.datepipe.transform(
      this.setnewDate(this.offerForm.controls.dateToSkip.value),
      "yyyy-MM-dd"
    );
    this.isSubmitting = true;
    this.offerForm.controls.departmentId.setValue(
      Number(this.offerForm.controls.departmentId.value)
    );
    this.offerForm.controls.shiftToSkip.setValue(
      !!this.offerForm.controls.shiftToSkip.value
        ? Number(this.offerForm.controls.shiftToSkip.value)
        : null
    );
    this.offerForm.controls.vtoCount.setValue(
      Number(this.offerForm.controls.vtoCount.value)
    );
    this.offerForm.controls.vtoType.setValue(
      this.role === this.roleEnum.user
        ? vtoType.myRequest
        : vtoType.managerOffer
    );
    this.offerForm.controls.dateToSkip.setValue(mxdate);

    const startTimeValue = this.offerForm.controls.vtoStartTime.value;
    const endTimeValue = this.offerForm.controls.vtoEndTime.value;

    if (
      !!this.offerForm.controls.vtoStartTime.value &&
      startTimeValue instanceof Date
    ) {
      this.offerForm.controls.vtoStartTime.setValue(
        new Date(startTimeValue).getHours() +
          ":" +
          new Date(startTimeValue).getMinutes()
      );
    }
    if (
      !!this.offerForm.controls.vtoEndTime.value &&
      endTimeValue instanceof Date
    ) {
      this.offerForm.controls.vtoEndTime.setValue(
        new Date(endTimeValue).getHours() +
          ":" +
          new Date(endTimeValue).getMinutes()
      );
    }
    const saveMethod = !!this.offerForm.controls.offerId.value
      ? this.vtoService.updateVtoRequestOffer(this.offerForm.value)
      : this.vtoService.addVtoRequestOffer(this.offerForm.value);
    saveMethod.then(
      (response) => {
        if (response["Success"]) {
          this.isSubmitting = false;
          this.closeRequest.emit(true);
          if (this.role === this.roleEnum.manager) {
            this.bsService.hide(1);
            this.modalRef.hide();
          }
          this.toaster.success(
            this.offerForm.controls.offerId.value > 0
              ? Constants.VTO_REQUEST_UPDATE_SUCCESS_MSG
              : Constants.VTO_REQUEST_ADD_SUCCESS_MSG
          );
        } else {
          this.isSubmitting = false;
          this.toaster.error(response["Message"]);
          this.offerForm.controls.dateToSkip.setValue(new Date(mxdate));
          this.offerForm.controls.dateToSkip.setValue(new Date(mxdate));
          this.offerForm.controls.vtoStartTime.setValue(startTimeValue);
          this.offerForm.controls.vtoEndTime.setValue(endTimeValue);
          if (this.role === this.roleEnum.manager) {
            this.setTimes(
              this.offerForm.controls.vtoStartTime.value,
              this.offerForm.controls.vtoEndTime.value
            );
          }
        }
      },
      (err) => {
        this.isSubmitting = false;
      }
    );
  }

  closeModal() {
    this.closeRequest.emit(false);
  }

  setnewDate(date) {
    if (date.getTime()) {
      const dateObj = {
        year: +this.datepipe.transform(date, "yyyy"),
        month: +this.datepipe.transform(date, "MM"),
        day: +this.datepipe.transform(date, "dd"),
      };
      return new Date(dateObj["year"], dateObj["month"] - 1, dateObj["day"]);
    }
    return new Date(date);
  }

  closePopUp() {
    this.cancleRequest.emit(true);
  }

  departmentChange(event) {
    this.offerForm.controls.shiftToSkip.setValue("");
    this.offerForm.controls.timeType.setValue("");
    this.offerForm.controls.vtoStartTime.setValue("");
    this.offerForm.controls.vtoEndTime.setValue("");
    if (!!event.currentTarget) {
      if (!!event.currentTarget.value) {
        if (this.role === this.roleEnum.manager) {
          this.getShiftByDepartment(Number(event.currentTarget.value));
        }
      }
    } else {
      if (!!event) {
        if (this.role === this.roleEnum.manager) {
          this.getShiftByDepartment(Number(event));
        }
      }
    }
  }

  getShiftByDepartment(departmentId) {
    this.shiftService.getShiftListByDepartment(departmentId).then(
      (response) => {
        if (response["Success"]) {
          this.shiftList = response["Data"];
        } else {
          this.shiftList = [];
        }
      },
      (err) => {
        this.shiftList = [];
      }
    );
  }

  onShiftChange(event) {
    this.isChange = true;
    this.offerForm.controls.timeType.setValue("");
    this.offerForm.controls.vtoStartTime.setValue("");
    this.offerForm.controls.vtoEndTime.setValue("");
    if (
      !!event.currentTarget.value &&
      !!this.offerForm.controls.timeType.value &&
      !!this.offerForm.controls.dateToSkip.value
    ) {
      const skipDate = this.datepipe.transform(
        this.setnewDate(this.offerForm.controls.dateToSkip.value),
        "yyyy-MM-dd"
      );
      // if(this.offerForm.value.offerId == 0)
      this.getTimeByShiftDateTime(
        skipDate,
        event.currentTarget.value,
        this.offerForm.controls.timeType.value
      );
    }
  }

  onTimeChange(event) {
    this.isChange = true;
    this.offerForm.controls.vtoStartTime.setValue("");
    this.offerForm.controls.vtoEndTime.setValue("");
    if (
      !!event.currentTarget.value &&
      !!this.offerForm.controls.dateToSkip.value &&
      !!this.offerForm.controls.shiftToSkip.value
    ) {
      const skipDate = this.datepipe.transform(
        this.setnewDate(this.offerForm.controls.dateToSkip.value),
        "yyyy-MM-dd"
      );
      // if(this.offerForm.value.offerId == 0)
      this.getTimeByShiftDateTime(
        skipDate,
        this.offerForm.controls.shiftToSkip.value,
        event.currentTarget.value
      );
    }
  }

  getTimeByShiftDateTime(date, shift, time) {
    this.shiftService
      .getTimeByShiftDateTime(date, Number(shift), Number(time))
      .then(
        (response) => {
          if (response["Success"]) {
            if (time == 4) {
              if (this.offerForm.controls.offerId.value == 0 || this.isChange) {
                this.defaultCurrentTime();
              } else {
                this.setTimes(
                  this.VtoOffer.vtoStartTime,
                  this.VtoOffer.vtoEndTime
                );
              }
            } else {
              if (this.offerForm.controls.offerId.value == 0 || this.isChange) {
                // if (!this.isSelectedCurrectShift) {
                //   this.defaultCurrentTime();
                // } else {
                  this.setTimes(
                    response["Data"].startTime,
                    response["Data"].endTime
                  );
                  if (!this.isSelectedCurrectShift){
                    this.defaultCurrentTime();
                    this.isSelectedCurrectShift = true;
                  }
                // }
                // }
              } else {
                this.setTimes(
                  this.VtoOffer.vtoStartTime,
                  this.VtoOffer.vtoEndTime
                );
              }
            }
          } else {
            this.offerForm.controls.vtoStartTime.setValue(null);
            this.offerForm.controls.vtoEndTime.setValue(null);
          }
        },
        (err) => {}
      );
  }
  getShiftList() {
    this.shiftService
      .getShiftListByUserId(Number(localStorage.getItem(Constants.SHIFTID)))
      .then(
        (response) => {
          if (response["Success"]) {
            this.shiftList = response["Data"];
          }
        },
        (err) => {
          this.shiftList = [];
        }
      );
  }
  skipDateChange(event) {
    this.isChange = true;
    let currentWeekDay = new Date().getDay() + 1;
    console.log(currentWeekDay);
    this.isSelectedCurrectShift = true;
    if (this.isSelectedCurrectShift) {
      if (!!this.shiftList) {
        this.isSelectedCurrectShift = false;

        for (
          let index = 0;
          index < this.shiftList.shiftWeekDay.length;
          index++
        ) {
          if (
            this.shiftList.shiftWeekDay[index].weekday ==
            new Date(event).getDay() + 1
          ) {
            let shiftWeek = this.shiftList.shiftWeekDay[index];
            this.offerForm.controls.vtoStartTime.setValue(new Date(event));
            this.offerForm.controls.vtoEndTime.setValue(new Date(event));

            if (this.offerForm.controls.timeType.value == 1) {
              this.setTimes(shiftWeek.startTime, shiftWeek.endTime);
            }
            if (this.offerForm.controls.timeType.value == 2) {
              this.setTimes(shiftWeek.startTime, shiftWeek.firstHalfEndTime);
            }
            if (this.offerForm.controls.timeType.value == 3) {
              this.setTimes(shiftWeek.secondHalfStartTime, shiftWeek.endTime);
            }
            this.isSelectedCurrectShift = true;
            break;
          }
        }
        if (!this.isSelectedCurrectShift && !this.isSubmitting) {
          this.defaultCurrentTime();
          // this.toaster.error(Constants.NO_AVAILABLE_SHIFT);
        }
      }
    }
    if (!!event && event.getTime() &&   this.offerForm.controls.dateToSkip.touched && !this.isSubmitting) {
      const skipDate = this.datepipe.transform(
        this.setnewDate(event),
        "yyyy-MM-dd"
      );
      this.offerForm.controls.vtoStartTime.setValue('');
      this.offerForm.controls.vtoEndTime.setValue('');
      if (
        !!this.offerForm.controls.shiftToSkip.value &&
        !!this.offerForm.controls.timeType.value
      ) {
        this.getTimeByShiftDateTime(
          skipDate,
          this.offerForm.controls.shiftToSkip.value,
          this.offerForm.controls.timeType.value
        );
      }
    }
  }

  getName(data, type) {
    if (type === "shift") {
      return this.shiftList.find((x) => x.shiftId === Number(data.shiftToSkip))
        .title;
    }
    if (type === "time") {
      return this.timeList.find((x) => x.id === Number(data.timeType)).value;
    }
    if (type === "department") {
      return this.departmentList.find(
        (x) => x.departmentId === Number(data.departmentId)
      ).departmentName;
    }
  }

  setTimes(startTime, endTime) {
    if (!!startTime) {
      const startdate = new Date();
      startdate.setHours(
        Number(startTime.split(":")[0]),
        Number(startTime.split(":")[1])
      );
      this.offerForm.controls.vtoStartTime.setValue(startdate);
    } else {
      this.toaster.error(Constants.SHIFT_START_TIME_CONFIGURE);
      this.offerForm.controls.vtoStartTime.setValue("");
    }
    if (!!endTime) {
      const enddate = new Date();
      enddate.setHours(
        Number(endTime.split(":")[0]),
        Number(endTime.split(":")[1])
      );
      this.offerForm.controls.vtoEndTime.setValue(enddate);
    } else {
      if (!this.timeList[3].id) {
        this.toaster.error(Constants.SHIFT_END_TIME_CONFIGURE);
      }
      this.offerForm.controls.vtoEndTime.setValue("");
    }
  }

  defaultCurrentTime() {
    var current = new Date();
    var startShiftTime = current.toLocaleTimeString().split(":");
    var startHours =
      startShiftTime[0] + ":" + startShiftTime[1] + ":" + startShiftTime[2];
    this.offerForm.controls.vtoStartTime.setValue(startHours);
    this.offerForm.controls.vtoEndTime.setValue(null);
    let startTime = startHours;
    let endTime = null;
    this.setTimes(startTime, endTime);
  }
}
