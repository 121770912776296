import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Constants, bsConfig, SubscriptionType, ContentEnum } from '../constant/constants';
import { FormService } from '../core/form.service';
import { Plan } from '../models/plan.model';
import { PlanService } from '../service/plan.service';
import { ActivatedRoute } from '@angular/router';
import { BsDatepickerViewMode } from 'ngx-bootstrap/datepicker/models';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig> = bsConfig;
  minMode: BsDatepickerViewMode = 'month';
  isFirstOpen = true;
  plan: any;
  public messageList: any = new Plan();
  planForm: FormGroup;
  subscriptionType = SubscriptionType;
  priceModules: any;
  stateList = [];
  detailNotValid = false;
  minDate = new Date();
  paymentOpen = false;
  appName = environment.APP_NAME;
  modalRef: BsModalRef;
  btnText = Constants.BTN_TEXT;
  cancleBtnText = Constants.CANCLE_BTN_TEXT;
  title = Constants.TERMS_CONDITION;
  contentType = ContentEnum;

  constructor(private toaster: ToastrService,
    private formService: FormService,
    private planService: PlanService,
    private activeRoute: ActivatedRoute,
    private build: FormBuilder,
    private modalService: BsModalService) {
    this.plan = this.activeRoute.snapshot.data.plan.Data;
    console.log(this.plan);
    this.appName = localStorage.getItem(Constants.APP_NAME);
  }

  ngOnInit() {
    this.getStateList();
    this.bsConfig = Object.assign({}, {
      minMode: this.minMode,
      dateInputFormat: 'MM-YYYY',
    });
    this.initializePlanForm();
    this.initializeMessages();
  }

  initializePlanForm() {
    this.planForm = this.build.group({
      pricingId: new FormControl(!!this.plan ? this.plan.pricingId : 0),
      numberOfUsers: new FormControl(!!this.plan ? this.plan.numberOfUsers : 0),
      setUpCost: new FormControl(!!this.plan ? this.plan.setUpCost : 0),
      costForFirstUsers: new FormControl(!!this.plan ? this.plan.costForFirstUsers : 0),
      costForRestUsers: new FormControl(!!this.plan ? this.plan.costForRestUsers : 0),
      isActive: new FormControl(true),
      priceForEnablingMsg: new FormControl(!!this.plan ? this.plan.priceForEnablingMsg : 0, [Validators.required, Validators.pattern(Constants.REGEX.DECIMAL_PATTRN), Validators.maxLength(10)]),
      cardHolderName: new FormControl(!!this.plan ? this.plan.cardHolderName : ''),
      creditCardNumber: new FormControl(!!this.plan ? this.plan.creditCardNumber : 0),
      expirationDate: new FormControl(!!this.plan ? !!this.plan.expirationDate ? new Date(this.plan.expirationDate) : null : null),
      securityCode: new FormControl(!!this.plan ? this.plan.securityCode : 0),
      isAutoRenew: new FormControl(!!this.plan ? this.plan.isAutoRenew : false),
      PricingSubscription: this.build.array([]),
      Address: new FormControl(!!this.plan && !!this.plan.BillingAddress ? this.plan.BillingAddress.Address : ''),
      City: new FormControl(!!this.plan && !!this.plan.BillingAddress ? this.plan.BillingAddress.City : ''),
      StateId: new FormControl(!!this.plan && !!this.plan.BillingAddress ? this.plan.BillingAddress.StateId : ''),
      Zip: new FormControl(!!this.plan && !!this.plan.BillingAddress ? this.plan.BillingAddress.Zip : ''),
      BillingAddressId: new FormControl(!!this.plan && !!this.plan.BillingAddress ? this.plan.BillingAddress.BillingAddressId : ''),
      isPaymentDetail: new FormControl(!!this.plan ? this.plan.isPaymentDetail : false),
      isTermsCondition: new FormControl(!!this.plan ? !!this.plan.lastUpdatedTerms ? true : false : false),
      lastUpdatedTerms: new FormControl(!!this.plan ? this.plan.lastUpdatedTerms : null)
    });
    this.priceModules = this.planForm.get("PricingSubscription") as FormArray;
    for (let i = 0; i < 12; i++) {
      (this.planForm.controls.PricingSubscription as FormArray).push(
        this.subscriptionControl(i)
      );
    }
    this.planForm.controls.isTermsCondition.disable();
  }

  subscriptionControl(index?: any): FormGroup {
    const group = this.build.group({
      pricingSubscriptionId: new FormControl(0),
      subscriptionType: new FormControl(0),
      pricingId: new FormControl(0),
      isSubscribe: new FormControl(false),
      numberOfUsers: new FormControl(0),
      costForFirstUsers: new FormControl(0),
      costForRestUsers: new FormControl(0),
    });

    if (!!this.plan && this.plan['pricingSubscription'].length > 0) {
      group.patchValue(this.plan['pricingSubscription'][index]);
      // if (index === 0) {
      //   group.controls.isSubscribe.setValue(true);
      // }
    }
    return group;
  }

  initializeMessages() {
    this.messageList.priceForEnablingMsg = {
      required: Constants.VALIDATION_MSG.PLAN.PRICEFORENABLINGMSG,
      pattern: Constants.VALIDATION_MSG.COMPANY.ONLY_DIGIT_ALLOWED
    };
    this.messageList.cardHolderName = {
      required: Constants.VALIDATION_MSG.PLAN.CARDHOLDERNAME,
      pattern: Constants.VALIDATION_MSG.PLAN.CHARACTER_ALLOW_MSG
    };
    this.messageList.creditCardNumber = {
      required: Constants.VALIDATION_MSG.PLAN.CREDITCARDNUMBER,
      pattern: Constants.VALIDATION_MSG.COMPANY.ONLY_DIGIT_ALLOWED,
      minLength: Constants.VALIDATION_MSG.PLAN.CREDIT_CARD_MIN_LENGTH,
      maxLength: Constants.VALIDATION_MSG.PLAN.CREDIT_CARD_MAX_LENGTH,
    };
    this.messageList.expirationDate = {
      required: Constants.VALIDATION_MSG.PLAN.EXPIRATIONDATE,
    };
    this.messageList.securityCode = {
      required: Constants.VALIDATION_MSG.PLAN.SECURITYCODE,
      pattern: Constants.VALIDATION_MSG.COMPANY.ONLY_DIGIT_ALLOWED
    };
    this.messageList.Address = {
      required: Constants.VALIDATION_MSG.PLAN.ADDRESS_REQUIRED,
    };
    this.messageList.City = {
      required: Constants.VALIDATION_MSG.PLAN.CITY_REQUIRED,
    };
    this.messageList.StateId = {
      required: Constants.VALIDATION_MSG.PLAN.STATE_REQUIRED,
    };
    this.messageList.Zip = {
      required: Constants.VALIDATION_MSG.PLAN.ZIP_REQUIRED,
      pattern: Constants.VALIDATION_MSG.PLAN.ZIP_INVALID,
    };
  }

  getStateList() {
    this.planService.getState().then(response => {
      if (response['Success']) {
        this.stateList = response['Data'];
      } else {
        this.stateList = [];
      }
    }, err => { this.stateList = []; });
  }

  updatePlanDetail() {
    this.formService.markFormGroupTouched(this.planForm);
    if (this.planForm.invalid) {
      return;
    }
    this.priceModules = this.planForm.get("PricingSubscription") as FormArray;
    this.priceModules.controls.map((element, i) => {
      element.controls.subscriptionType.setValue(i + 1);
      element.controls.costForFirstUsers.setValue(parseFloat(element.controls.costForFirstUsers.value));
      element.controls.costForRestUsers.setValue(parseFloat(element.controls.costForRestUsers.value));
      element.controls.numberOfUsers.setValue(parseFloat(element.controls.numberOfUsers.value));
    });
    this.planForm.controls.setUpCost.setValue(parseFloat(this.planForm.controls.setUpCost.value));
    this.planForm.controls.costForFirstUsers.setValue(parseFloat(this.planForm.controls.costForFirstUsers.value));
    this.planForm.controls.costForRestUsers.setValue(parseFloat(this.planForm.controls.costForRestUsers.value));
    this.planForm.controls.priceForEnablingMsg.setValue(parseFloat(this.planForm.controls.priceForEnablingMsg.value));
    const Data = {
      pricingId: this.planForm.controls.pricingId.value,
      setUpCost: this.planForm.controls.setUpCost.value,
      isActive: this.planForm.controls.isActive.value,
      priceForEnablingMsg: this.planForm.controls.priceForEnablingMsg.value,
      CardHolderName: this.planForm.controls.cardHolderName.value,
      CreditCardNumber: this.planForm.controls.creditCardNumber.value,
      ExpirationDate: this.planForm.controls.expirationDate.value,
      SecurityCode: this.planForm.controls.securityCode.value,
      IsAutoRenew: this.planForm.controls.isAutoRenew.value,
      PricingSubscription: this.planForm.controls.PricingSubscription.value,
      isPaymentDetail: this.planForm.controls.isPaymentDetail.value,
      lastUpdatedTerms: this.planForm.controls.lastUpdatedTerms.value,
      BillingAddress: {
        BillingAddressId: Number(this.planForm.controls.BillingAddressId.value),
        Address: this.planForm.controls.Address.value,
        City: this.planForm.controls.City.value,
        StateId: !!this.planForm.controls.StateId.value ? Number(this.planForm.controls.StateId.value) : '',
        Zip: this.planForm.controls.Zip.value
      }
    };

    this.planService.updatePlan(Data).then((res: any) => {
      if (res['Success']) {
        this.toaster.success(Constants.PLAN_UPDATE_SUCCESS_MSG);
      } else {
        this.planForm.controls.expirationDate.setValue(new Date(this.planForm.controls.expirationDate.value))
        this.toaster.error(res['Message']);
      }
    });
  }

  unsubscribe() {

  }

  reset() {
    this.planForm.reset();
  }

  expiryDateChange(event) {
    if (!!event) {
      if ((new Date().getMonth() + 1) >= (moment(event).month() + 1) && (new Date().getFullYear()) >= (moment(event).year())) {
        this.toaster.error(Constants.PLAN_EXIPRY_MSG);
      }
    }
  }

  onChange(event) {
    this.paymentOpen = !event;
    if (this.paymentOpen) {
      this.planForm.controls.cardHolderName.setValidators([Validators.required, Validators.pattern(Constants.REGEX.CHARACTER_PATTERN)]);
      this.planForm.controls.creditCardNumber.setValidators([Validators.required, Validators.pattern(Constants.REGEX.INTEGER_PATTERN), Validators.minLength(16), Validators.maxLength(16)]);
      this.planForm.controls.expirationDate.setValidators(Validators.required);
      this.planForm.controls.securityCode.setValidators([Validators.required, Validators.pattern(Constants.REGEX.INTEGER_PATTERN), Validators.maxLength(10)]);
      this.planForm.controls.Address.setValidators(Validators.required);
      this.planForm.controls.City.setValidators(Validators.required);
      this.planForm.controls.StateId.setValidators(Validators.required);
      this.planForm.controls.Zip.setValidators([Validators.required, Validators.pattern(Constants.REGEX.ZIP_PATTERN)]);
    } else {
      this.planForm.controls.cardHolderName.setValidators(null);
      this.planForm.controls.creditCardNumber.setValidators(null);
      this.planForm.controls.expirationDate.setValidators(null);
      this.planForm.controls.securityCode.setValidators(null);
      this.planForm.controls.Address.setValidators(null);
      this.planForm.controls.City.setValidators(null);
      this.planForm.controls.StateId.setValidators(null);
      this.planForm.controls.Zip.setValidators(null);
    }
    this.planForm.controls.cardHolderName.updateValueAndValidity();
    this.planForm.controls.creditCardNumber.updateValueAndValidity();
    this.planForm.controls.expirationDate.updateValueAndValidity();
    this.planForm.controls.securityCode.updateValueAndValidity();
    this.planForm.controls.Address.updateValueAndValidity();
    this.planForm.controls.City.updateValueAndValidity();
    this.planForm.controls.StateId.updateValueAndValidity();
    this.planForm.controls.Zip.updateValueAndValidity();
  }

  openTermsModal(template: TemplateRef<any>, data: any) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  checkAgreeDisagree(event) {
    if (event) {
      this.planForm.controls.isTermsCondition.enable();
    } else {
      this.planForm.controls.isTermsCondition.disable();
    }
    this.modalRef.hide();
  }

  get isValid(): boolean {
    if (this.appName === 'CoverageCatch') {
      return this.planForm.invalid || !this.planForm.controls.isTermsCondition.value;
    }
    return this.planForm.invalid;
  }

  onCheckBoxChange(event) {
    this.planForm.controls.isTermsCondition.setValue(false);
    this.planForm.controls.isTermsCondition.disable();
  }
}
