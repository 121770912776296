import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from '../constant/constants';


@Injectable({
  providedIn: 'root'
})
export class CallInRequestService {

  constructor(private apiService: ApiService) { }

  getReasons(currentPage, companyId) {
    // return this.apiService.get(
    //   `${API.CALL_OFF_REQUEST.callOffReasonsGetAll}`,
    //   true,
    //   true,
    // );
    return this.apiService.get(
      `${API.CALL_OFF_REQUEST.callOffReasonsGetAll}?companyId=` + companyId
      + `&page=` + currentPage,
      true,
      true,
    );
  }
  getReasonsByType(isStopLoader =true) {
    return this.apiService.get(
      `${API.CALL_OFF_REQUEST.callOffReasons}?reasonType=1`,
      true,
      isStopLoader,
    );
  }
  updateCallOffReason(data) {
    return this.apiService.put(
      `${API.CALL_OFF_REQUEST.callOffReasons}`,
      data,
      true,
    );
  }
  addCallOffReason(data) {
    return this.apiService.post(
      `${API.CALL_OFF_REQUEST.addCallOffReason}`,
      data,
      true,
    );
  }
  deleteCallOffReason(id) {
    return this.apiService.delete(
      `${API.CALL_OFF_REQUEST.callOffReasons}?id=${id}`,
      true,
    );
  }
  callOffReasonFilter(data) {
    return this.apiService.post(`${API.CALL_OFF_REQUEST.callOffReasonFilterdata}`, data, true);
  }
  addCallOffRequestOffer(params) {
    return this.apiService.post(`${API.CALL_OFF_REQUEST.addCallOff}`, params, true);
  }

  updateCallOffRequestOffer(params) {
    return this.apiService.put(`${API.CALL_OFF_REQUEST.editCallOff}`, params, true);
  }

  logCallOffRequest(offerType,message){
    return this.apiService.post(
      `${API.OFFER_LOG.OfferLogEntry}?offerType=${offerType}&message=${message}`,
      null,
      true,
    );
  }

  checkMonthlyCallOffRequest(startdate, Enddate, offerId) {
    return this.apiService.post(`${API.CALL_OFF_REQUEST.checkMonthlyCallOffRequest}?startdate=${startdate}&Enddate=${Enddate}&offerId=${offerId}`
      ,[6,7]
      , true);
  }
  getCallOffRequestList(currentPage, rowsOnPage,isStopLoader = true, isShowLoader = true) {
    return this.apiService.get(
      `${API.CALL_OFF_REQUEST.getUserList}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      isStopLoader,
      isShowLoader
    );
  }

  userCallOffRequestsDataFilter(data) {
    return this.apiService.post(`${API.CALL_OFF_REQUEST.userDataFilter}`, data, true);
  }

  GetCallOffListForManager(currentPage, rowsOnPage,isStopLoader = true,isShowLoader = true) {
    return this.apiService.get(
      `${API.CALL_OFF_REQUEST.getCallOffListForManager}?companyId=${localStorage.getItem(Constants.COMPANYID)}&page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      isStopLoader,
      isShowLoader
    );
  }

  managerCallOffOfferDataFilter(data) {
    return this.apiService.post(`${API.CALL_OFF_REQUEST.managerCallOffOfferDataFilter}`, data, true);
  }

  // HRADMIN

  getCallOffReqestedList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.CALL_OFF_REQUEST.getCallOffReqestedList}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }

  getCallOffClosedList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.CALL_OFF_REQUEST.getClosedVotRequestOffer}?companyId=${localStorage.getItem(Constants.COMPANYID)}&page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }



  downloadPendingExcelFile(data) {
    return this.apiService.post(`${API.CALL_OFF_REQUEST.downloadPendingExcelFile}`, data, true);
  }

  downloadClosedExcelFile(data) {
    return this.apiService.post(`${API.CALL_OFF_REQUEST.downloadClosedExcelFile}`, data, true);
  }

  closedCallOffOfferDataFilter(data) {
    return this.apiService.post(`${API.CALL_OFF_REQUEST.closedDataFilter}`, data, true);
  }
  approvalCallOffOfferDataFilter(data) {
    return this.apiService.post(`${API.CALL_OFF_REQUEST.approvalDataFilter}`, data, true);
  }
  approveCallOffOffer(data) {
    return this.apiService.post(`${API.CALL_OFF_REQUEST.approveCallOffOffer}`, data, true);
  }
  getSettingByCompanyID(OfferType, companyId,isStopLoader = true,isLoader?) {
    return this.apiService.get(
      `${API.CALL_OFF_REQUEST.getSettingByCompanyID}?companyId=` + companyId
      + `&OfferType=` + OfferType,
      true,
      isStopLoader,
      isLoader
    );
  }
}