import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TermsCondition } from 'src/app/models/terms-condition.model';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { TermsConditionService } from 'src/app/service/terms-condition.service';
import { ToastrService } from 'ngx-toastr';
import { FormService } from 'src/app/core/form.service';
import { Constants, typeField } from 'src/app/constant/constants';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent implements OnInit {
  @Input() termsCondition: TermsCondition;
  @Output() close = new EventEmitter<boolean>();
  termsConditionForm: FormGroup;
  public messageList: any = new TermsCondition();
  typeList = typeField;
  Editor = ClassicEditor;
  companyList = [];

  constructor(private toaster: ToastrService,
    private formService: FormService,
    private termsConditionService: TermsConditionService) {
    this.Editor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'insertTable',
          '|',
          'undo',
          'redo'
        ]
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
      },
      language: 'en'
    };
  }

  ngOnInit() {
    this.initializeTermsConditionForm();
    this.initializeMessages();
  }

  initializeTermsConditionForm() {
    this.termsConditionForm = new FormGroup({
      termsAndConditionsId: new FormControl(!!this.termsCondition ? this.termsCondition.termsAndConditionsId : 0),
      description: new FormControl(!!this.termsCondition ? this.termsCondition.description : '', Validators.required),
      isActive: new FormControl(true),
      typeField: new FormControl(!!this.termsCondition ? this.termsCondition.typeField : null, Validators.required),
      companyId: new FormControl(!!this.termsCondition ? this.termsCondition.companyId : Number(localStorage.getItem(Constants.COMPANYID)))
    });
  }

  initializeMessages() {
    this.messageList.typeField = {
      required: Constants.VALIDATION_MSG.TERMS_CONDITION.TYPE_REQUIRED,
    };
    this.messageList.description = {
      required: Constants.VALIDATION_MSG.TERMS_CONDITION.DESCRIPTION_REQUIRED,
    };
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.termsConditionForm);
    if (this.termsConditionForm.invalid) {
      return;
    }
    const data = {
      termsAndConditionsId: this.termsConditionForm.controls.termsAndConditionsId.value,
      description: this.termsConditionForm.controls.description.value,
      typeField: Number(this.termsConditionForm.controls.typeField.value),
      isActive: true,
      companyId: this.termsConditionForm.controls.companyId.value,
    }

    const saveMethod = this.termsConditionForm.controls.termsAndConditionsId.value > 0 ? this.termsConditionService.updateTermsCondition(data) : this.termsConditionService.saveTermsCondition(data);
    saveMethod.then((res: any) => {
      if (res['Success']) {
        this.toaster.success(this.termsConditionForm.controls.termsAndConditionsId.value > 0 ? Constants.TERMS_CONDITION_UPDATE_SUCCESS_MSG : Constants.TERMS_CONDITION_ADD_SUCCESS_MSG);
        this.close.emit(true);
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => {
      this.close.emit(false);
    });
  }

  control(controlName: string): AbstractControl {
    return this.termsConditionForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }

  cancel() {
    this.close.emit(false);
  }
}
