import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TimePunchesService } from "src/app/service/time-punches.service";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  bsConfig,
  ComplianceEnum,
  Constants,
  PunchLocationEnum,
  PunchTypeEnum,
  SourceEnum,
} from "src/app/constant/constants";
import { FormService } from "src/app/core/form.service";
import { ToastrService } from "ngx-toastr";
import { UtilityService } from "src/app/service/utility.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { timePunchModel } from "src/app/models/time-punch.model";
@Component({
  selector: "app-edit-time-punches",
  templateUrl: "./edit-time-punches.component.html",
  styleUrls: ["./edit-time-punches.component.scss"],
})
export class EditTimePunchesComponent implements OnInit {
  @ViewChild("confirmationPopup", { static: false })
  confirmationPopup: TemplateRef<any>;
  @Input() timePunchModel: timePunchModel;
  @Output() closeRequest = new EventEmitter<string>();
  modalRef: BsModalRef;
  public timePunchesForm: FormGroup;
  complianceList = ComplianceEnum;
  punchLocationList = PunchLocationEnum;
  punchTypeList = PunchTypeEnum;
  sourceList = SourceEnum;
  timePunchList = [];
  isConfirm = false;
  confirmMsg: string;
  totalItems: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  bsConfig: Partial<BsDatepickerConfig> = bsConfig;
  public messageList: any = new timePunchModel();
  today = new Date();

  constructor(
    private modalService: BsModalService,
    private timePunchesService: TimePunchesService,
    private datepipe: DatePipe,
    private formService: FormService,
    private toaster: ToastrService,
    private utility: UtilityService
  ) {}

  ngOnInit() {
    this.initializeTimePunchesForm();
    
  }
  async initializeTimePunchesForm() {
    this.timePunchesForm = new FormGroup({
      TimePunchesId: new FormControl(
        !!this.timePunchModel ? this.timePunchModel.TimePunchesId : 0
      ),
      PunchTime: new FormControl(
        !!this.timePunchModel ? new Date(this.timePunchModel.PunchTime) : null,
        Validators.required
      ),
      PunchTimeHoursMinutes: new FormControl(
        !!this.timePunchModel
          ? new Date(this.timePunchModel.PunchTime)
          : new Date(),
        Validators.required
      ),
      AdjustedPunchTime: new FormControl(
        !!this.timePunchModel &&
        new Date(this.timePunchModel.AdjustedPunchTime).getTime() > 0
          ? new Date(this.timePunchModel.AdjustedPunchTime)
          : null
      ),
      AdjustedPunchTimeHoursMinutes: new FormControl(
        !!this.timePunchModel &&
        new Date(this.timePunchModel.AdjustedPunchTime).getTime() > 0
          ? new Date(this.timePunchModel.AdjustedPunchTime)
          : null
      ),
      punchType: new FormControl(
        !!this.timePunchModel ? this.timePunchModel.PunchType : "",
        Validators.required
      ),
      punchLocation: new FormControl(
        !!this.timePunchModel ? this.timePunchModel.PunchLocation : "",
        Validators.required
      ),
      source: new FormControl(
        !!this.timePunchModel ? this.timePunchModel.Source : "",
        Validators.required
      ),
      Compliance: new FormControl(
        !!this.timePunchModel ? this.timePunchModel.Compliance : "",
        Validators.required
      ),
      Geolocation: new FormControl(
        !!this.timePunchModel ? this.timePunchModel.geoLocation : ""
      ),
    });
    this.timePunchesForm.controls.AdjustedPunchTime.disable();
    this.timePunchesForm.controls.AdjustedPunchTimeHoursMinutes.disable();
    if(this.timePunchesForm.controls.punchType.value ==3){
      this.timePunchesForm.controls.punchType.setValue(1)
    }
    else if(this.timePunchesForm.controls.punchType.value ==4){
      this.timePunchesForm.controls.punchType.setValue(2)
    }
  }

  initializeMessages() {
    this.messageList.PunchTime = {
      required: Constants.VALIDATION_MSG.PUNCHES.PUNCH_TIME,
    };
    this.messageList.AdjustedPunchTime = {
      required: Constants.VALIDATION_MSG.PUNCHES.ADJUST_PUNCH_TIME,
    };
    this.messageList.source = {
      required: Constants.VALIDATION_MSG.PUNCHES.SOURCE,
    };
    this.messageList.Compliance = {
      required: Constants.VALIDATION_MSG.PUNCHES.COMPLIANCE,
    };
    this.messageList.punchType = {
      required: Constants.VALIDATION_MSG.PUNCHES.PUNCH_TYPE,
    };
    this.messageList.punchLocation = {
      required: Constants.VALIDATION_MSG.PUNCHES.PUNCH_LOCATION,
    };
  }

  getTimePunchList(currentPage) {
    const getMethod = this.timePunchesService.getListByUser(
      currentPage,
      this.rowsOnPage
    );
    getMethod.then(
      (res: any) => {
        if (res["Success"]) {
          this.timePunchList = res.Data.results;
          this.totalItems =
            res.Data.totalNumberOfRecords === 0
              ? undefined
              : res.Data.totalNumberOfRecords;
        } else {
          this.timePunchList = [];
          this.totalItems = undefined;
        }
      },
      (err) => {
        this.timePunchList = [];
      }
    );
  }
  save() {
    this.formService.markFormGroupTouched(this.timePunchesForm);
    if (this.timePunchesForm.invalid) {
      return;
    }
    let todayDate:any = new Date().getTime();
    let selectedDate:any = new Date(this.timePunchesForm.controls.PunchTimeHoursMinutes.value).getTime();
    
    if(new Date(this.timePunchesForm.controls.PunchTime.value).toDateString() == new Date().toDateString() && todayDate < selectedDate){
      this.toaster.error(Constants.VALIDATION_MSG.PUNCHESBYHR.PUNCH_TIME_BYHRS)
      return;
    } 
    this.timePunchesForm.controls.AdjustedPunchTime.enable();
    this.timePunchesForm.controls.AdjustedPunchTimeHoursMinutes.enable();
    let punchTimeHoursMinutes =
      this.timePunchesForm.controls.PunchTimeHoursMinutes.value;
    let mixedPunchDateTime = this.convertDateTime(
      this.timePunchesForm.controls.PunchTime.value,
      punchTimeHoursMinutes
    );
    this.timePunchesForm.controls.AdjustedPunchTime.setValue(
      this.timePunchModel.AdjustedPunchTime
    );
    this.timePunchesForm.controls.PunchTime.setValue(mixedPunchDateTime);
    this.removeValueFromObject(
      this.timePunchesForm.value,
      "PunchTimeHoursMinutes",
      "AdjustedPunchTimeHoursMinutes"
    );
    let timePunchesFormValue = this.timePunchesForm.value;

    this.timePunchesForm.reset();

    console.log(timePunchesFormValue);

    const saveMethod =
      this.timePunchesService.updateTimePunches(timePunchesFormValue);
    saveMethod.then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(Constants.TIME_OFF_REQ_EDIT_MSG);
          this.closeRequest.emit("Close");
          
        } else {
          this.timePunchesForm.controls.AdjustedPunchTime.disable();
          this.timePunchesForm.controls.AdjustedPunchTimeHoursMinutes.disable();
          this.utility.showErrorToast(res["Message"]);
        }
      },
      (err) => {
        this.timePunchesForm.controls.AdjustedPunchTime.disable();
        this.timePunchesForm.controls.AdjustedPunchTimeHoursMinutes.disable();
      }
    );
  }

  setnewDate(date) {
    const dateObj = {
      year: +this.datepipe.transform(date, "yyyy"),
      month: +this.datepipe.transform(date, "MM"),
      day: +this.datepipe.transform(date, "dd"),
    };
    return this.datepipe.transform(
      new Date(dateObj["year"], dateObj["month"] - 1, dateObj["day"]),
      "yyyy-MM-dd"
    );
  }

  closePopUp() {
    this.closeRequest.emit("Close");
  }

  close(event) {
    this.isConfirm = false;
  }

  openPopup() {
    this.isConfirm = false;
    this.modalRef = this.modalService.show(this.confirmationPopup, {
      class: "modal-dialog-centered modal-md",
      backdrop: "static",
    });
  }

  control(controlName: string): AbstractControl {
    return this.timePunchesForm.get(controlName);
  }
  value(controlName: string) {
    return this.control(controlName).value;
  }

  convertDateTime(date1, date2) {
    console.log(date1, date2);
    let newDate = new Date(date1);
    return this.datepipe.transform(
      new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        date2.getHours(),
        date2.getMinutes(),
        0,
        0
      ),
      "yyyy-MM-dd HH:mm"
    );
  }

  removeValueFromObject(values, removeValue1, removeValue2) {
    for (const [key, value] of Object.entries(values)) {
      if (key === removeValue1 || key === removeValue2) {
        delete values[key];
      }
    }
  }
}
