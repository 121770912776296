import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { Constants } from 'src/app/constant/constants';
import { Role, OfferStatus } from 'src/app/models/role-model';
import { Offer } from 'src/app/models/offer.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UtilityService } from 'src/app/service/utility.service';
import { DatePipe } from '@angular/common';
import { DepartmentService } from 'src/app/service/department.service';
import { OfferService } from 'src/app/service/offer.service';
import { ToastrService } from 'ngx-toastr';
import { FlexRequestService } from 'src/app/service/flex-request.service';
import { ShiftService } from 'src/app/service/shift.service';
import { commonHelper } from 'src/app/shared/Helpers/commonHelper';
import * as _ from 'lodash';

@Component({
  selector: 'app-available-flex-work-request',
  templateUrl: './available-flex-work-request.component.html',
  styleUrls: ['./available-flex-work-request.component.scss']
})
export class AvailableFlexWorkRequestComponent implements OnInit {
  @Output() reloadCount = new EventEmitter<boolean>();
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  @ViewChild('workDateValue', { static: false }) workDateValue;
  @ViewChild('shiftValue', { static: false }) shiftValue;
  @ViewChild('departmentValue', { static: false }) departmentValue;
  @ViewChild('statusValue', { static: false }) statusValue;
  selected = [];
  allRowsSelected = [];
  selectedRecords = [];
  flexRequestList = [];
  totalItems: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  columns = [];
  searchSort: any;
  role: number;
  roleEnum = Role;
  companyId: number;
  offerStatus = OfferStatus;
  today = new Date();
  nextweekDate = new Date(this.today.getTime() + 7 * 24 * 60 * 60 * 1000);
  nextDate: any;
  searchDate: any;
  filterValue = [];
  shiftList = [];
  departmentList = [];
  statusList = [{ id: 4, name: 'Available' }, { id: 7, name: 'Accepted' }]
  deleteId: string;
  isConfirmed = false;
  flexOffer: Offer;
  modalRef: BsModalRef;
  showAllCheckbox = false;
  commonShiftList: any[];

  constructor(private flexService: FlexRequestService,
    private shiftService: ShiftService,
    private utilityService: UtilityService,
    private datepipe: DatePipe,
    private modalService: BsModalService,
    private departmentService: DepartmentService,
    private offerService: OfferService,
    private toaster: ToastrService) {
    this.columns = ['departmentId', 'dateToSkip', 'shiftToSkip', 'status', 'departmentName', 'FlexTypestr', 'dateToSkipShortDate', 'vtoStartTime', 'vtoEndTime', 'vtoCount', 'createdByUsername', 'offerStatusStr', 'timeTypeStr'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Columns: [
      ],
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.setPage({ offset: 0 });
    if (this.role === this.roleEnum.hrAdmin) {
      this.getShiftList();
      this.getDepartmentList();
      //this.convertEnumToArray();
    }
  }

  // convertEnumToArray() {
  //   for (const [propertyKey, propertyValue] of Object.entries(OfferStatus)) {
  //     if (!Number.isNaN(Number(propertyKey))) {
  //       continue;
  //     }
  //     this.statusList.push({ id: propertyValue, name: propertyKey });
  //   }
  // }

  ngAfterViewInit() {
    if (this.role === this.roleEnum.hrAdmin) {
      // this.workDateValue.nativeElement.value = this.nextDate;
      this.filterValue.push('');
      this.filterValue.push(this.shiftValue.nativeElement.value);
      this.filterValue.push(this.departmentValue.nativeElement.value);
      this.filterValue.push(this.statusValue.nativeElement.value);
      this.columns.forEach((element, i) => {
        if (i < 4) {
          const obj = {
            Data: 'string',
            Searchable: true,
            Orderable: true,
            Name: element,
            Search: {
              Value: this.filterValue[i],
              Regex: 'string'
            }
          }
          this.searchSort.Columns.push(obj);
        }
      });
    }
  }

  async setPage(pageInfo,isReloadCount = false) {
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
    await this.getFlexRequestList(this.page.pageNumber + 1);
    if(isReloadCount){
      this.reloadCount.emit();
    }
  }

  async getFlexRequestList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.workDateValue.nativeElement.value = this.datepipe.transform(this.setnewDate(this.nextweekDate), 'yyyy-MM-dd');
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      this.nextDate = this.datepipe.transform(this.setnewDate(this.nextweekDate), 'yyyy-MM-dd');
      const getMehod = this.role === this.roleEnum.hrAdmin ? this.flexService.getHrAdminAvailableFlexRequestList(currentPage, this.rowsOnPage, this.nextDate) :
        this.flexService.getAvailableFlexRequestList(currentPage, this.rowsOnPage, this.companyId);
     await getMehod.then((res: any) => {
        if (JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))) {
          this.utilityService.showLoading();
        }
        if (res['Success']) {
          this.flexRequestList = res.Data.results;
          this.totalItems = res.Data.totalNumberOfRecords ;
          this.showAllCheckbox = this.flexRequestList.some(x => x.status === this.offerStatus.accepted)
        } else {
          this.flexRequestList = [];
          this.totalItems = 0;
        }
      }, err => { this.flexRequestList = []; });
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clear();
      }
    }
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  clear() {
    if (this.role === this.roleEnum.user) {
      this.searchSort.Search.Value = '';
      this.searchSort.Order[0].ColumnName = '';
      this.searchSort.Order[0].Dir = 'asc';
      this.filterTextValue.nativeElement.value = '';
      this.setPage({ offset: 0 });
    } else {
      this.searchSort.Search.Value = '';
      this.searchSort.Order[0].ColumnName = '';
      this.searchSort.Order[0].Dir = 'asc';
      this.filterTextValue.nativeElement.value = '';
      this.searchSort.Columns.forEach(element => {
        element.Search.Value = '';
      });
      this.shiftValue.nativeElement.value = '';
      this.departmentValue.nativeElement.value = '';
      // this.workDateValue.nativeElement.value = this.nextDate;
      this.workDateValue.nativeElement.value = '';
      this.statusValue.nativeElement.value = '';
      this.searchDate = this.nextDate;
      this.setPage({ offset: 0 });
    }
  }

  filterData() {
    this.searchSort.Search.ColumnNameList = this.columns;
    const filterMethod = this.role === this.roleEnum.hrAdmin ? this.flexService.flexHrAdminrAvailableRequestDataFilter(this.searchSort, this.role === this.roleEnum.hrAdmin ? !!this.searchDate ? this.searchDate : this.nextDate : this.nextDate) :
      this.flexService.flexAvailableRequestOfferDataFilter(this.searchSort);
    filterMethod.then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.flexRequestList = res['data'];
        this.totalItems = res['recordsFiltered'];
        this.showAllCheckbox = this.flexRequestList.some(x => x.status === this.offerStatus.accepted)
      } else { this.flexRequestList = []; this.totalItems = 0; }
    }, err => { this.flexRequestList = []; this.totalItems = 0; });
  }

  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }

  acceptVtoOffer(offer) {
    this.flexService.acceptFlexOffer(offer.offerId)
      .then(response => {
        if (response['Success']) {
          this.utilityService.showSuccessToast(Constants.FLEX_REQUEST_ACCEPT_SUCCESS_MSG);
          this.setPage({ offset: 0 });
        } else {
          this.utilityService.showErrorToast(response['Message']);
        }
      }, err => { })
  }

  approveRejectOffer(type, data) {
    this.flexService.approveRejectHrAdminFlexOffer(data.offerId, type === 1 ? 2 : 3)
      .then(response => {
        if (response['Success']) {
          this.utilityService.showSuccessToast(type === 1 ? Constants.FLEX_REQUEST_APPROVE_SUCCESS_MSG : Constants.FLEX_REQUEST_DENIED_SUCCESS_MSG);
          this.setPage({ offset: 0 });
        } else {
          this.utilityService.showErrorToast(response['Message']);
        }
      }, err => { });
  }

  setnewDate(date) {
    const dateObj = {
      year: +(this.datepipe.transform(date, 'yyyy')),
      month: +(this.datepipe.transform(date, 'MM')),
      day: +(this.datepipe.transform(date, 'dd'))
    };
    return new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day']);
  }

  getDepartmentList() {
    this.departmentService.getDepartmentListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID))).then(res => {
      if (res['Success']) {
        this.departmentList = res['Data'];
      } else { this.departmentList = []; }
    }, err => { this.departmentList = []; });
  }

  workDateChange(event) {
    this.searchDate = null;
    const findDateColumn = this.searchSort.Columns.find(x => x.Name === 'dateToSkip');
    if (!!findDateColumn) {
      findDateColumn.Search.Value = this.datepipe.transform(this.setnewDate(event), 'yyyy-MM-dd');
      this.searchDate = this.datepipe.transform(this.setnewDate(event), 'yyyy-MM-dd');
    }
  }

  departmentChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(x => x.Name === 'shiftToSkip');
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = '';
    }
    if (event.currentTarget.value)
      this.shiftList = commonHelper.filterShiftListFromDepartmentId(event.currentTarget.value, this.commonShiftList);
    else
      this.shiftList = this.commonShiftList
    const findDepartmentColumn = this.searchSort.Columns.find(x => x.Name === 'departmentId');
    if (!!findDepartmentColumn) {
      findDepartmentColumn.Search.Value = event.currentTarget.value;
    }
  }

  onClickDelete(data) {
    this.deleteId = data.offerId;
    this.isConfirmed = true;
  }

  close(event) {
    this.isConfirmed = false;
  }
  cancle(event) {
    this.modalRef.hide();
  }
  delete(id) {
    this.isConfirmed = false;
    this.offerService.deleteOffer(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.FLEX_REQUEST_DELETE_SUCCESS_MSG);
        this.setPage({ offset: 0 },true);
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { });
  }
  openModal(template: TemplateRef<any>, data: any) {

    if (data) {
      this.flexOffer = data;
      } else {
      this.flexOffer = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 },event);
    }
    this.modalRef.hide();
  }

  getShiftList() {
    this.shiftService.getShiftListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID)))
      .then(response => {
        this.shiftList = response['Data'];
        this.commonShiftList = _.merge([], this.shiftList)
      }, err => { this.shiftList = []; });
  }

  shiftChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(x => x.Name === 'shiftToSkip');
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = event.currentTarget.value;
    }
  }

  statusChange(event) {
    const findStatusColumn = this.searchSort.Columns.find(x => x.Name === 'status');
    if (!!findStatusColumn) {
      findStatusColumn.Search.Value = event.currentTarget.value;
    }
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.selectedRecords = this.selected.map(x => x.offerId);
  }

}
