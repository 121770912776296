import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl, Validators, AbstractControl } from "@angular/forms";
import { bsConfig } from "../../constant/constants";
import { timeOffRequestModel } from "./../../models/TimeOffRequest.model";
import { TimeOffService } from "src/app/service/time-off.service";
import { Constants } from "src/app/constant/constants";
import { FormService } from "src/app/core/form.service";
import { ToastrService } from "ngx-toastr";
import { UtilityService } from "src/app/service/utility.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-add-time-off-request",
  templateUrl: "./add-time-off-request.component.html",
  styleUrls: ["./add-time-off-request.component.scss"],
})
export class AddTimeOffRequestComponent implements OnInit {
  @Input() timeOffRequestModel: timeOffRequestModel;
  @Output() closeRequest = new EventEmitter<boolean>();
  @Output() cancleRequest = new EventEmitter<boolean>();
  timeoffRequestForm: FormGroup;
  messageList: any = timeOffRequestModel;
  bsConfig = bsConfig;
  today = new Date();
  typeList = [];
  companyId: number;
  timeOffConfigList = [];
  constructor(
    private timeOffService: TimeOffService,
    private formService: FormService,
    private toaster: ToastrService,
    private utility: UtilityService,
    private datepipe: DatePipe,
  ) {}

  ngOnInit() {
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.getTimeOffConfigList();
    this.initializeForm();
    this.initializeMessages();
    this.typeList = [
      {
        id: 1,
        value: "Paid Time Off",
      },
      {
        id: 2,
        value: "Call-Off time",
      },
    ];
  }
  getTimeOffConfigList() {
    this.timeOffService.getTimeOff(null, this.companyId).then(
      (response) => {
        if (response["Success"]) {
          this.timeOffConfigList = response["Data"];
        }
      }
    );
  }
  close() {
    this.cancleRequest.emit(true);
  }
  initializeForm() {
    this.timeoffRequestForm = new FormGroup({
      timeOffUserRequestId: new FormControl(
        !!this.timeOffRequestModel
          ? this.timeOffRequestModel.TimeOffUserRequestId
          : 0
      ),
      userName: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.UserName : "",
        Validators.required
      ),
      timeOffEndDate: new FormControl(""),
      timeOffStartDate: new FormControl(
        !!this.timeOffRequestModel
        ? new Date(this.timeOffRequestModel.TimeOffStartDateStr)
        : null,
      Validators.required),
      status: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.Status : 1
      ),
      shiftId: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.ShiftId : 3
      ),
      timeOffId: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.TimeOffId : "",
        Validators.required
      ),
      UserId: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.UserId : 0
      ),
      TimeOffQuantity: new FormControl(
        !!this.timeOffRequestModel
          ? this.timeOffRequestModel.TimeOffQuantity
          : null,
        [Validators.required]
      ),
      DepartmentId: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.DepartmentId : 0
      ),
    });
    if (this.timeoffRequestForm.controls.timeOffUserRequestId.value > 0) {
      this.timeoffRequestForm.controls.userName.disable();
    }
  }
  initializeMessages() {
    this.messageList.userName = {
      required: Constants.VALIDATION_MSG.TIME_OFF.USERNAME_REQUIRED,
    };
    this.messageList.timeOffEndDate = {
      required: Constants.VALIDATION_MSG.TIME_OFF.DATE_TIME_END_REQUIRED,
    };
    this.messageList.timeOffId = {
      required: Constants.VALIDATION_MSG.TIME_OFF.TIME_OFF_TYPE,
    };
    this.messageList.TimeOffQuantity = {
      required: Constants.VALIDATION_MSG.TIME_OFF.TIME_OFF_QUANTITY,
    //   min: Constants.VALIDATION_MSG.TIME_OFF.GREATER_THAN_ZERO,
    };
  }
  onSubmit() {
    this.formService.markFormGroupTouched(this.timeoffRequestForm);
    if (this.timeoffRequestForm.invalid) {
      return;
    }
    const mxdate = this.datepipe.transform(
      this.setnewDate(this.timeoffRequestForm.controls.timeOffStartDate.value),
      "yyyy-MM-dd"
    );
    this.timeoffRequestForm.controls.timeOffEndDate.setValue(
      this.timeoffRequestForm.controls.timeOffStartDate.value
    );
    this.timeoffRequestForm.controls.timeOffStartDate.setValue(mxdate);
    this.timeoffRequestForm.controls.userName.enable();
    const saveMethod =
      this.timeoffRequestForm.controls.timeOffUserRequestId.value > 0
        ? this.timeOffService.updateTimeUserRequest(
            this.timeoffRequestForm.value
          )
        : this.timeOffService.addTimeOffHrRequest(
            this.timeoffRequestForm.value
          );
    saveMethod.then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(
            this.timeoffRequestForm.controls.timeOffUserRequestId.value > 0
              ? Constants.TIME_OFF_REQ_EDIT_MSG
              : Constants.TIME_OFF_REQ_ADD_MSG
          );
          this.closeRequest.emit(true);
        } else {
          this.utility.showErrorToast(res["Message"]);
        }
      },
      (err) => {}
    );
  }
  value(controlName: string) {
    return this.control(controlName).value;
  }
  control(controlName: string): AbstractControl {
    return this.timeoffRequestForm.get(controlName);
  }
  setnewDate(date) {
    const dateObj = {
      year: +this.datepipe.transform(date, "yyyy"),
      month: +this.datepipe.transform(date, "MM"),
      day: +this.datepipe.transform(date, "dd"),
    };
    return new Date(dateObj["year"], dateObj["month"] - 1, dateObj["day"]);
  }
}
