import { Component, DoCheck, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, TemplateRef} from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import SwiperCore , {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
} from 'swiper';
import { ToastrService } from "ngx-toastr";
import { SignIn } from 'src/app/models/signIn.model';
import { FormService } from 'src/app/core/form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'src/app/service/company.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UtilityService } from 'src/app/service/utility.service';
import { Constants, ContentEnum } from 'src/app/constant/constants';
import { defaultRoutes } from 'src/app/core/auth/default-routes';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AllowRoleLoginComponent } from '../allow-role-login/allow-role-login.component';
import { take } from 'rxjs/operators';
import { CaptchaService } from 'src/app/service/captcha.service';
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
]);

@Component({
  selector: 'app-loginlayout',
  templateUrl: './loginlayout.component.html',
  styleUrls: ['./loginlayout.component.scss']
})
export class LoginlayoutComponent implements OnInit, DoCheck, OnChanges {
  @Input() companylist: any;
  @Output() SignInEvent = new EventEmitter<any>();
  @Input() flag: boolean;
  @Output() companyCheck = new EventEmitter<boolean>();
  @Output() companyCheckForm = new EventEmitter<any>();
  @Input() changeCompanyToggle: boolean;

  public loginForm: FormGroup;
  public messageList: any = new SignIn();
  logoUrl = "";
  webUrl = "";
  companyId: string;
  companyName = "";
  userFailedCount: number = 0;
  sitekey: string;
  isShowCompany: boolean = true;
  type: any;
  title = Constants.TERMS_CONDITION;
  modalRef: BsModalRef;
  contentType = ContentEnum;
  activeSectionName = 'home';
  isOpenMobileNavigation: boolean;
  isShowCloseIcon: boolean;
  isShowMenuIcon: boolean;
  data: any;
  showCaptcha: boolean;
  userName: string;
  isMultiple: boolean = false;
  userCompanyName: any;
  isCompanyName: boolean = false;
  changeCompany: boolean = false;
  constructor(
    private scroller: ViewportScroller,
    private formService: FormService,
    private router: Router,
    private toaster: ToastrService,
    private companyService: CompanyService,
    private authService: AuthService,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private titleService: Title,
    private modalService: BsModalService,
    private captchaService: CaptchaService
  ) { 
    this.logoUrl = localStorage.getItem(Constants.LOGO_PATH);
    this.webUrl = localStorage.getItem(Constants.WEB_URL);
    this.companyId = localStorage.getItem(Constants.COMPANY_NAME);
    this.userName = localStorage.getItem(Constants.USERNAME);
    document.documentElement.style.setProperty(
      "--bgImg",
      `url(${localStorage.getItem('\.\.\/\.\.\/assets\/images\/\/coverMyWork\/loginbg-top\.jpg')})`
    ); 
    
  }

  ngOnInit() {    
    if(window.innerWidth <= 767) {
      this.isShowMenuIcon = true;
    }
    else if(window.innerWidth > 767) {
      this.isShowMenuIcon = false;
    }
    else {
      this.isShowMenuIcon = false;
    }
    
    this.sitekey = Constants.RECAPTCHA_SITEKEY;
    if (
      window.location.hostname.split(".").length > 2 &&
      window.location.hostname.split(".")[0] !== "www"
    ) {
      this.companyName = window.location.hostname.split(".")[0];
    } else {
      this.route.queryParams.subscribe((params) => {
        if (!params.hasOwnProperty("company")) {
          this.router.navigate([""], { queryParams: { company: "" } });
        } else {
          this.companyName = params["company"];
        }
      });
    }
    this.userCompanyName = localStorage.getItem(Constants.COMPANY_NAME);
    if(!!this.userCompanyName){
      this.isCompanyName = true;
    }
    this.initializeForm();
    this.initializeMessages(); 
  }

  initializeForm() {
    this.loginForm = new FormGroup({
      companyId: new FormControl(!!this.companyId ? this.companyId : "", [Validators.required]),
      companyUserName: new FormControl(!!this.userName ? this.userName : "", [Validators.required]),
      password: new FormControl("", [Validators.required]),
    });
  }

  initializeMessages() {
    this.messageList.companyId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMPANY_REQUIRED,
    };
    this.messageList.companyUserName = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMPANY_NAME_REQUIRED,
    };

    this.messageList.password = {
      required: Constants.VALIDATION_MSG.SIGN_UP.PASSWORD_REQUIRED,
    };
  }

  onSlideChange(swiperSlide) {
    console.log('slide change', swiperSlide);
  }

  goSection($element) {
    this.activeSectionName = $element.id;
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  signIn() {   
    this.SignInEvent.emit(this.loginForm);
  }

  captchaSolved(event) {
   // console.log('sjsjsjsj', event);
    if (event === null) this.showCaptcha = true;
    else this.showCaptcha = false;
  }

  userNameChange(){
    this.flag = false;
    this.loginForm.patchValue({
      companyId: ""
    })
  }

  redirectToStore(target) {
    if (target === "playStore")
    window.open("https://play.google.com/store/apps/details?id=com.covermywork.stage","_blank")
      
    else if (target === "appStore")
    window.open("https://apps.apple.com/us/app/covermywork/id1591215261?ign-mpt=uo%3D2","_blank")
  }

  checkAgreeDisagree(event) {
    this.modalRef.hide();
  }

  openTermsModal(template: TemplateRef<any>, data: any) {
    if (data === 1) {
      this.type = this.contentType.TNC;
      this.title = Constants.TERMS_CONDITION;
    } else {
      this.type = this.contentType.PrivacyPolicy;
      this.title = Constants.PRIVACY;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }

  openMobileNavigation() {
    this.isOpenMobileNavigation = true;
    this.isShowCloseIcon = true;
    this.isShowMenuIcon = false;
  }

  closeMobileNavigation() {
    this.isOpenMobileNavigation = false;
    this.isShowCloseIcon = false;
    this.isShowMenuIcon = true;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if(window.innerWidth <= 767) {
      this.isOpenMobileNavigation = false;
      this.isShowCloseIcon = false;
      this.isShowMenuIcon = true;
    }
    else if(window.innerWidth > 767) {
      this.isOpenMobileNavigation = false;
      this.isShowCloseIcon = false;
      this.isShowMenuIcon = false;
    }
    else {
      this.isOpenMobileNavigation = false;
      this.isShowCloseIcon = false;
      this.isShowMenuIcon = false;
    }
  } 
  ngOnChanges() {
    this.captchaService.getMessage().subscribe(message => {
      if (message) {
        this.showCaptcha = message.show;
      } 
    });
  }
  ngDoCheck()  {
    
    
  }
}
