export class Plan {
    pricingId: number;
    numberOfUsers: number;
    setUpCost: number;
    costForFirstUsers: number;
    costForRestUsers: number;
    isActive: true;
    priceForEnablingMsg: number;
    CardHolderName: string;
    CreditCardNumber: string;
    ExpirationDate: string;
    SecurityCode: string;
    IsAutoRenew: boolean;
    companyId: number;
}