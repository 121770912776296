import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { submitFeedBackEnum } from "src/app/constant/constants";
import { ApiService } from "src/app/service/api.service";
import {
  FormGroup,
  FormControl,
  AbstractControl,
  Validators,
} from "@angular/forms";
import { FormService } from "src/app/core/form.service";
import { IssueReportModel, Offer } from "src/app/models/offer.model";
import { FileToUpload } from "src/app/models/fileUpload";
import { UtilityService } from "src/app/service/utility.service";
import { UserService } from 'src/app/service/user.service';
import { Constants } from 'src/app/constant/constants';

@Component({
  selector: 'app-add-feedback-report',
  templateUrl: './add-feedback-report.component.html',
  styleUrls: ['./add-feedback-report.component.scss']
})
export class AddFeedbackReportComponent implements OnInit {
  @Output() closeRequest = new EventEmitter<boolean>();
  @ViewChild('myFileInput', {static: false}) myFileInput;
  Editor = ClassicEditor;
  feedbackType = submitFeedBackEnum;
  offer: Offer;
  selectedFeedback: number;
  theFiles: any[] = [];
  public feedbackForm: FormGroup;
  fileObj: any=[];
  fileForTotalSize: any;
  fileSizeArr : any = [];
  totalSize: any = 0;
  public messageList:any = new IssueReportModel(); 
  constructor( public userService: UserService , private apiService: ApiService, private formService: FormService, private utility: UtilityService) {
    this.Editor.defaultConfig = {
      toolbar: {
        items: [],
      },
    };
   }

  ngOnInit() {
    this.feedbackForm = new FormGroup({
      UserID: new FormControl(localStorage.getItem("userId")),
      Subject: new FormControl("", Validators.required),
      Body: new FormControl("", Validators.required),
      Attachment:new FormControl()
    });

    this.initializeMessages();
  }

  closePopUp() {
    this.closeRequest.emit(true);
  }

  initializeMessages() {
    this.messageList.Body = {
      required: Constants.VALIDATION_MSG.SETTING.DESCRIPTION_REQUIRED,
    };
    this.messageList.Subject = {
      required: Constants.VALIDATION_MSG.MARKET_CAMPAIGN.OPTION,
    };
  }

  onSave(){
    this.formService.markFormGroupTouched(this.feedbackForm);
    if (this.feedbackForm.invalid) {
      return;
    }
    if(this.totalSize >= 5){
      this.utility.showErrorToast("Selected files have exceeded the limit");
      return;
    }
    this.feedbackForm.controls.Attachment.setValue(this.fileObj);
    const saveMethod = this.userService.addFeedbackRequest(this.feedbackForm.value).then((res) => {
      if(res["Success"]){
        this.utility.showSuccessToast("Your request has been submitted. Someone will contact you soon");
        this.closePopUp();
      }
    }, (e) => {
      this.utility.showSuccessToast("Something went wrong!")
      this.closePopUp();
    })
  }

  onFileChange(event) {
    this.theFiles = [];
    let file = event.target.files[0];
    if(this.fileObj.some(e => e.FileName === file.name)){
      this.utility.showErrorToast("This file has already been added");
      return;
    }
    let fileSizeMB = (file.size / 1024) / 1024;
    this.fileForTotalSize = fileSizeMB;
    let fileName = file.name.toLowerCase().split(".")
    if(fileName[fileName.length - 1] == "exe" || fileName[fileName.length - 1] == "sln" || fileName[fileName.length - 1] == "html" ||
       fileName[fileName.length - 1] == "apk" || fileName[fileName.length - 1] == "zip" || fileName[fileName.length - 1] == "css" || fileName[fileName.length - 1] == "json"){
      this.utility.showErrorToast("This file format isn't supported");
      return;
    }
    else if((this.totalSize + fileSizeMB) >= 5){
      this.utility.showErrorToast("Selected files have exceeded the limit");
      this.myFileInput.nativeElement.value = '';
      return;
    }
    else if(fileSizeMB < 0.001){
      this.utility.showErrorToast("Selected file size must be larger than 1 KB");
      this.myFileInput.nativeElement.value = '';
      return;
    }else {
      this.totalSize += this.fileForTotalSize;
      this.theFiles.push(file);
      this.fileSizeArr.push(this.fileForTotalSize);
      this.readAndUploadFile(this.theFiles[0]);
      this.myFileInput.nativeElement.value = '';
    }
  }

  private readAndUploadFile(theFile: any) {
    let file = new FileToUpload();

    // Set File Information
    file.fileName = theFile.name;
    file.fileSize = theFile.size;
    file.fileType = theFile.type;
    file.lastModifiedTime = theFile.lastModified;
    file.lastModifiedDate = theFile.lastModifiedDate;

    // Use FileReader() object to get file to upload
    // NOTE: FileReader only works with newer browsers
    let reader = new FileReader();

    // Setup onload event for reader
    reader.onload = () => {
      // Store base64 encoded representation of file
      file.fileAsBase64 = reader.result.toString();
        this.fileObj.push({
          FileName : file.fileName,
          Base64StringFile: file.fileAsBase64
        })
    }

    // Read the file
    reader.readAsDataURL(theFile);
  }

  removeFile(index: number) {
    this.fileObj.splice(index, 1);
    this.totalSize -= this.fileSizeArr[index];
    this.fileSizeArr.splice(index, 1);
    this.myFileInput.nativeElement.value = '';
  }
}