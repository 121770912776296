import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from '../constant/constants';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private apiService: ApiService) { }

  getSettings(currentPage, companyId) {
    return this.apiService.get(
      `${API.SETTING_ROUTES.getSetting}?companyId=` + companyId
      + `&page=` + currentPage,
      true,
      true,
    );
  }

  updateSettings(params) {
    return this.apiService.post(`${API.SETTING_ROUTES.updateSetting}`, params, true);
  }
  addSettings(params) {
    return this.apiService.post(`${API.SETTING_ROUTES.addSetting}`, params, true);
  }
  dataFilter(data) { //dataFilter
    return this.apiService.post(`${API.SETTING_ROUTES.dataFilter}`, data, true);
  }
}
