import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../constant/constants';
import { Company } from '../models/company.model';
import { CompanyService } from '../service/company.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  companyList = [];
  company = new Company();
  totalItems: any;
  modalRef: BsModalRef;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  isConfirmed: boolean = false;
  deleteId: string;
  role: number;
  searchSort: any;
  columns = [];
  isShowLink = false;
  deleteMsg = "All associated users/data will be deleted, Are you sure?";

  constructor(private modalService: BsModalService, private toaster: ToastrService,
    private companyService: CompanyService) {
    this.columns = ['companyName'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getCompanyList(this.page.pageNumber + 1);
  }

  getCompanyList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.isShowLink = true;
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      this.companyService.getCompanyList(currentPage)
        .then((res: any) => {
          if (res['Success']) {
            this.companyList = res.Data.results;
            this.totalItems = res.Data.totalNumberOfRecords;
          } else { this.companyList = []; this.totalItems = 0; }
        }, err => { this.companyList = []; });
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.isShowLink = true;
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.isShowLink = false;
        this.clear()
      }
    }
  }

  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.company = data;
    } else {
      this.company = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }

  close(event) {
    this.isConfirmed = false;
  }


  onClickDelete(data) {
    this.deleteId = data.companyId;
    this.isConfirmed = true;
  }

  delete(id) {
    this.isConfirmed = false;
    this.companyService.deleteCompany(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.COMPANY_DELETE_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      } else { this.toaster.error(res['Message']); }
    }, err => { });
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  clear() {
    this.isShowLink = false;
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  filterData() {
    this.companyService.dataFilter(this.searchSort).then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.companyList = res['data'];
        this.totalItems = res['recordsFiltered'];
      } else { this.companyList = []; this.totalItems = 0; }
    }, err => { this.companyList = []; this.totalItems = 0; });
  }

  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }
}
