import { NgModule } from '@angular/core';

import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user.component';
import { AddEditComponent } from './add-edit/add-edit.component';
import { SharedModule } from '../shared/shared/shared.module';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { UserService } from '../service/user.service';
import { CompanyService } from '../service/company.service';
import { DepartmentService } from '../service/department.service';
import { ShiftService } from '../service/shift.service';
import { LocationService } from '../service/location.service';
import { OfferService } from '../service/offer.service';
import { DirectiveModule } from '../shared/shared/directive/directive.module';
import { AuthService } from '../core/auth/auth.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CommonModule, DatePipe } from '@angular/common';
import { FileUploadEmployeesComponent } from './file-upload-employees/file-upload-employees.component';


@NgModule({
  declarations: [UserComponent, AddEditComponent,FileUploadEmployeesComponent],
  imports: [
    CommonModule,
    SharedModule,
    UserRoutingModule,
    NgxBootstrapIconsModule.pick(allIcons),
    DirectiveModule,
    NgMultiSelectDropDownModule
  ],
  providers: [UserService, CompanyService, DepartmentService, ShiftService, LocationService, OfferService, AuthService,DatePipe]
})
export class UserModule { }
