import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { API } from "../routes/api-routes";
import { environment } from "src/environments/environment";
import { PendingRequestComponent } from './../offer/pending-request/pending-request.component';

@Injectable({
  providedIn: "root",
})
export class TimeOffService {
  constructor(private apiService: ApiService) {}

  getTimeOff(currentPage, companyId,isStopLoader = true,isShowLoader =true) {
    return this.apiService.get(
      `${API.TIME_OFF_CONFIG.timeOffGetAll}?companyId=` +
        companyId +
        `&page=` +
        currentPage +
        `&pageSize=10`,
      true,
      isStopLoader,
      isShowLoader
    );
  }

  GetAllConfigForUser(){
  return this.apiService.get(
    `${API.TIME_OFF_CONFIG.timeOffGetAllForUser}`,
    true,
    true
  );
}

getTimeOffConfigTypelist(){
  return this.apiService.get(
    `${API.TIME_OFF_CONFIG.timeOffConfigTypesList}`,
    true,
    true
  );
}

  getHrUserList(userId, currentPage, pagesize,isStopLoader=true,isShowLoader = true) {
    return this.apiService.get(
      `${API.TIME_OFF_HR_REQUESTS.gethrUserRequests}?userId=` +
        userId +
        `&page=` +
        currentPage +
        `&pageSize=` +
        pagesize,
      true,
      isStopLoader,
      isShowLoader
    );
  }

  getPendingTimeOffRequestList(currentPage, isChecked) {
    return this.apiService.get(
      `${API.TIME_OFF_HR_REQUESTS.getPendingTimeOffRequestList}?page=` +
        currentPage + `&isChecked=` + isChecked,
      true,
      true
    );
  }

  getPendingHRTimeOffRequestList(companyId, currentPage, isChecked) {
    return this.apiService.get(
      `${API.TIME_OFF_HR_REQUESTS.getPendingHRTimeOffRequestList}?page=` +
        currentPage + `&companyId=` + companyId + `&isChecked=` + isChecked ,
      true,
      true
    );
  }

  getClosedTimeOffRequestList(currentPage) {
    return this.apiService.get(
      `${API.TIME_OFF_HR_REQUESTS.getClosedTimeOffRequestList}?page=` +
        currentPage,
      true,
      true
    );
  }
  getClosedHRTimeOffRequestList(companyId, currentPage) {
    return this.apiService.get(
      `${API.TIME_OFF_HR_REQUESTS.getClosedHRTimeOffRequestList}?page=` +
        currentPage + `&companyId=` + companyId,
      true,
      true
    );
  }


  getClosedTimeOffRequestListFilterData(data, companyId) {
    return this.apiService.post(
      `${API.TIME_OFF_HR_REQUESTS.getClosedTimeOffRequestListFilterData}?companyId=` +
        companyId, 
      data,
      true
    );
  }

  getAllClosedTimeOffRequestManagerDataFilter(data, companyId) {
    return this.apiService.post(
      `${API.TIME_OFF_USER.getAllClosedTimeOffRequestManagerDataFilter}?companyId=` +
        companyId, 
      data,
      true
    );
  }

  getPendingTimeOffRequestListFilterData(data, companyId, isChecked) {
    return this.apiService.post(
      `${API.TIME_OFF_HR_REQUESTS.getPendingTimeOffRequestListFilterData}?companyId=` +
        companyId + `&isChecked=` + isChecked,
      data,
      true
    );
  }
  getAllPendingTimeOffRequestManagerFilter(data, isChecked){
    return this.apiService.post(
      `${API.TIME_OFF_USER.getAllPendingTimeOffRequestManagerFilter}?&isChecked=` + isChecked,
      data,
      true
    );
  }
  timeOffFilter(data, companyId) {
    return this.apiService.post(
      `${API.TIME_OFF_CONFIG.timeOffFilter}?companyId=` + companyId,
      data,
      true
    );
  }
  getUserRequestFilterData(data) {
    return this.apiService.post(
      `${API.TIME_OFF_USER.userDataFilter}`,
      data,
      true
    );
  }
  approvedDeniedUserRequests(ids, isApproved) {
    return this.apiService.put(
      `${API.TIME_OFF_HR_REQUESTS.approveDeniedUserRequest}?isApproved=` +
        isApproved,
      ids,
      true
    );
  }

  deleteTimeOff(id) {
    return this.apiService.delete(
      `${API.TIME_OFF_CONFIG.deleteTimeOff}?id=${id}`,
      true
    );
  }

  updateTimeOffConfig(params) {
    return this.apiService.put(
      `${API.TIME_OFF_CONFIG.editTimeOff}`,
      params,
      true
    );
  }

  addTimeOffConfig(params) {
    return this.apiService.post(
      `${API.TIME_OFF_CONFIG.addTimeOff}`,
      params,
      true
    );
  }

  addTimeOffHrRequest(params) {
    return this.apiService.post(
      `${API.TIME_OFF_HR_REQUESTS.addHrTimeOffRequest}`,
      params,
      true
    );
  }
  updateTimeUserRequest(params) {
    return this.apiService.put(
      `${API.TIME_OFF_HR_REQUESTS.updateHrTimeOffRequest}`,
      params,
      true
    );
  }

  hrManagerFilterData(data, userId) {
    return this.apiService.post(
      `${API.TIME_OFF_HR_REQUESTS.hrUserRequestsFilter}?userId=` + userId,
      data,
      true
    );
  }

  deleteTimeOffRequest(id) {
    return this.apiService.delete(
      `${API.TIME_OFF_USER.deleteTimeOffRequest}?id=${id}`,
      true
    );
  }

  updateTimeOffRequest(params) {
    return this.apiService.put(
      `${API.TIME_OFF_USER.editTimeOffRequest}`,
      params,
      true
    );
  }

  addTimeOffRequest(params) {
    return this.apiService.post(
      `${API.TIME_OFF_USER.addTimeOffRequest}`,
      params,
      true
    );
  }

  getTimeOffUser(currentPage, userName) {
    return this.apiService.get(
      `${API.TIME_OFF_USER.timeOffGetAllUser}?userName=` +
        userName +
        `&page=` +
        currentPage,
      true,
      true
    );
  }

    downloadPendingRequestsExcelFiles(data, companyId, isChecked) {
    return this.apiService.post(
      `${API.TIME_OFF_HR_REQUESTS.downloadPendingRequests}?companyId=` + companyId + `&isChecked=` + isChecked,
      data,
      true
    );
  }
  downloadClosedExcelFile(data, companyId) {
    return this.apiService.post(
      `${API.TIME_OFF_HR_REQUESTS.downloadClosedRequests}?companyId=` +
        companyId ,
      data,
      true
    );
  }
  downloadSummaryRequestsExcelFiles(data, userId) {
    return this.apiService.post(
      `${API.TIME_OFF_HR_REQUESTS.downloadSummaryRequests}?userId=` + userId,
      data,
      true
    );
  }
  downloadDetailsExcelFile(data,userId) {
    return this.apiService.post(
      `${API.TIME_OFF_HR_REQUESTS.downloadDetailsRequests}?userId=` +
        userId,
      data,
      true
    );
  }
  addTimeOffUserRequestHR(params) {
    return this.apiService.post(
      `${API.TIME_OFF_HR_REQUESTS.addTimeOffUserRequestHR}`,
      params,
      true
    );
  }
  updateTimeOffUserRequestHR(params) {
    return this.apiService.put(
      `${API.TIME_OFF_HR_REQUESTS.updateTimeOffUserRequestHR}`,
      params,
      true
    );
  }
}
