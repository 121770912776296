import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Role } from 'src/app/models/role-model';
import { Constants, bsConfig, typeField, timeList } from 'src/app/constant/constants';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Offer } from 'src/app/models/offer.model';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { DepartmentService } from 'src/app/service/department.service';
import { FormService } from 'src/app/core/form.service';
import { ToastrService } from 'ngx-toastr';
import { OfferService } from 'src/app/service/offer.service';
import { ShiftService } from 'src/app/service/shift.service';
import { UtilityService } from 'src/app/service/utility.service';
import { TermsConditionService } from 'src/app/service/terms-condition.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-offer-modal',
  templateUrl: './offer-modal.component.html',
  styleUrls: ['./offer-modal.component.scss']
})
export class OfferModalComponent implements OnInit {
  @Input() offer: Offer;
  @Output() close = new EventEmitter<boolean>();
  public messageList: any = new Offer();
  public offerForm: FormGroup;
  today = new Date();
  tomorrow = new Date(this.today);
  nextMinDate = new Date(this.tomorrow.setDate(this.tomorrow.getDate() + 1));
  // nextWeek = new Date(this.today.getTime() + 7 * 24 * 60 * 60 * 1000)
  // nextFirst = this.nextWeek.getDate() - this.nextWeek.getDay();
  // nextMinDate = new Date(this.nextWeek.setDate(this.nextFirst));
  currentDate: Date;
  first: any;
  minDate: Date;
  maxDate: Date;
  public roleEnum = Role;
  role: number;
  disclaimerMsg = Constants.MANAGER_OFFER_DISCLAIMER;
  disclaimer = Constants.DISCLAIMER;
  hrOfferText = Constants.HR_TERMS_AND_CONDITION_OFFER_TEXT;
  termsCondition = [];
  termsMsg: string = '';
  Editor = ClassicEditor;
  bsConfig = bsConfig;
  departmentList = [];
  shiftList = [];
  companyId: number;
  shiftId: number;
  departmentId: number;
  shiftDetail: any;
  disableDaysForDateToSkip = [];
  disableDaysForDateToWork = [];
  weekDayArray = [];
  isShowShift: boolean = false;
  managerWorkShiftList = [];
  removeFieldValue = false;
  removeWorkValue = false;
  timeList = timeList;

  constructor(private formService: FormService,
    private departmentService: DepartmentService,
    private toaster: ToastrService,
    private offerService: OfferService,
    private shiftService: ShiftService,
    private utility: UtilityService,
    private termsConditionService: TermsConditionService,
    private datepipe: DatePipe) {
    this.Editor.defaultConfig = {
      toolbar: {
        items: []
      }
    }
  }

  ngOnInit() {
    this.removeFieldValue = !!this.offer && this.offer.offerId > 0 ? true : false;
    this.removeWorkValue = !!this.offer && this.offer.offerId > 0 ? true : false;
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = !!this.offer ? this.offer.companyId : Number(localStorage.getItem(Constants.COMPANYID));
    this.getTermsCondition();
    this.getDepartmentList();
    if (this.role === this.roleEnum.user) {
      this.getShiftList();
    }
    this.initializeOfferForm();
    this.initializeMessages();
  }

  async initializeOfferForm() {
    this.offerForm = new FormGroup(
      {
        offerId: new FormControl(!!this.offer ? this.offer.offerId : 0),
        dateToSkip: new FormControl(!!this.offer ? new Date(this.offer.dateToSkip) : null, Validators.required),
        dateToWork: new FormControl(!!this.offer ? new Date(this.offer.dateToWork) : null, Validators.required),
        status: new FormControl(!!this.offer ? this.offer.status : 0),
        shiftToSkip: new FormControl(!!this.offer ? this.offer.shiftToSkip : '', Validators.required),
        shiftToWork: new FormControl(!!this.offer ? this.offer.shiftToWork : '', Validators.required),
        companyId: new FormControl(this.companyId),
        departmentId: new FormControl(!!this.offer ? this.offer.departmentId : '', Validators.required),
        termsMsg: new FormControl(''),
        createdBy: new FormControl(!!this.offer ? this.offer.createdBy : null),
        createdDate: new FormControl(!!this.offer ? this.offer.createdDate : null),
        offerType: new FormControl(1)
      },
    );
    this.shiftId = !!this.offer ? this.offer.shiftToSkip : Number(localStorage.getItem(Constants.SHIFTID));
    if (this.role === this.roleEnum.user) {
      this.departmentId = !!this.offer ? this.offer.departmentId : Number(localStorage.getItem(Constants.DEPARTMENTID));
      this.offerForm.controls.shiftToSkip.setValue(this.shiftId);
      this.offerForm.controls.departmentId.setValue(this.departmentId);
      // if (this.isShowShift === false) {
      //   this.offerForm.controls.shiftToWork.setValidators(null);
      // }
      await this.getshiftDetail();
    }
    if (this.role === this.roleEnum.manager) {
      this.shiftList = [];
      if (!!this.offer && !!this.offer.departmentId) {
        this.onDepartmentChange(this.offer.departmentId);
      }
    }
  }

  initializeMessages() {
    this.messageList.dateToSkip = {
      required: Constants.VALIDATION_MSG.OFFER.SKIP_DATE_REQUIRED
    };
    this.messageList.dateToWork = {
      required: Constants.VALIDATION_MSG.OFFER.WORK_ON_DATE_REQUIRED,
    };
    this.messageList.shiftToSkip = {
      required: Constants.VALIDATION_MSG.OFFER.SKIP_SHIFT_REQUIRED,
    };
    this.messageList.shiftToWork = {
      required: this.role === this.roleEnum.hrAdmin ? Constants.VALIDATION_MSG.OFFER.WORK_SHIFT_REQUIRED : Constants.VALIDATION_MSG.OFFER.WORK_OFFER_SHIFT_REQUIRED,
    };
    this.messageList.departmentId = {
      required: Constants.VALIDATION_MSG.OFFER.DEPARTMENT_REQUIRED,
    };
  }

  getshiftDetail() {
    return new Promise((resolve, reject) => {
      this.shiftService.getShiftDetails(this.shiftId).then(res => {
        if (res['Success']) {
          this.shiftDetail = res['Data'].shiftWeekDay;
          this.shiftDetail.map(x => {
            if (x.weekday && x.isActive > 0) {
              this.weekDayArray.push(x.weekday - 1);
            }
          });
          for (let i = 0; i < 7; i++) {
            if (this.weekDayArray.indexOf(i) === -1) {
              this.disableDaysForDateToSkip.push(i);
            } else {
              this.disableDaysForDateToWork.push(i);
            }
          }
          this.skipDateChange(this.offerForm.controls.dateToSkip.value)
          resolve(this.shiftDetail);
        }
      }, err => { });
    });
  }

  getTermsCondition() {
    this.termsConditionService.getTermsConditionListByCompanyId(this.companyId, null).then(res => {
      if (res['Success']) {
        this.termsCondition = res['Data'];
        if (this.role === this.roleEnum.user) {
          this.getTermsMsg(1);
        } else if (this.role === this.roleEnum.manager) {
          this.getTermsMsg(2);
        }
      } else {
        this.termsCondition = [];
      }
    }, err => { this.termsCondition = []; });
  }

  getTermsMsg(index) {
    const findTerms = this.termsCondition.find(x => x.typeField === typeField[index].id);
    if (!!findTerms) {
      this.termsMsg = findTerms.description;
      this.offerForm.controls.termsMsg.setValue(this.termsMsg);
    }
  }

  getDepartmentList() {
    this.departmentService.getDepartmentListByCompanyId(null, this.companyId).then(res => {
      if (res['Success']) {
        this.departmentList = res['Data'];
      } else { this.departmentList = []; }
    }, err => { this.departmentList = []; });
  }

  getShiftList() {
    this.shiftService.getShiftListByCompanyId(null, this.companyId).then(res => {
      if (res['Success']) {
        this.shiftList = res['Data'];
        this.isShowShift = res['Data'].length > 2;
        if (this.isShowShift === false) {
          this.offerForm.controls.shiftToWork.setValidators(null);
          this.offerForm.controls.shiftToWork.updateValueAndValidity();
        }
        if (this.isShowShift && !!this.offer && this.role === this.roleEnum.user) {
          this.workDateChange(this.offerForm.controls.dateToWork.value);
        }
      } else { this.shiftList = []; }
    }, err => { this.shiftList = []; })
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.offerForm);
    if (this.offerForm.invalid) {
      return;
    }
    const mndate = this.datepipe.transform(this.setnewDate(this.offerForm.controls.dateToSkip.value), 'yyyy-MM-dd');
    const mxdate = this.datepipe.transform(this.setnewDate(this.offerForm.controls.dateToWork.value), 'yyyy-MM-dd');
    if (this.role === this.roleEnum.manager) {
      // if (this.offerForm.controls.dateToWork.value.getTime() === this.offerForm.controls.dateToSkip.value.getTime()) {
      //   this.toaster.error(Constants.VALIDATION_MSG.OFFER.DATE_MSG);
      //   return;
      // }
      if (mndate === mxdate) {
        this.toaster.error(Constants.VALIDATION_MSG.OFFER.DATE_MSG);
        return;
      }
    }
    delete this.offerForm.controls.termsMsg;
    this.removeFieldValue = true;
    this.removeWorkValue = true;
    this.offerForm.controls.dateToWork.setValue(mxdate);
    this.offerForm.controls.dateToSkip.setValue(mndate);
    this.offerForm.controls.departmentId.setValue(Number(this.offerForm.controls.departmentId.value))
    this.offerForm.controls.shiftToSkip.setValue(Number(this.offerForm.controls.shiftToSkip.value))
    if (this.role === this.roleEnum.user) {
      const shift = this.shiftList.filter(x => x.shiftId !== Number(localStorage.getItem(Constants.SHIFTID)));
      this.isShowShift ? this.offerForm.controls.shiftToWork.setValue(Number(this.offerForm.controls.shiftToWork.value)) : this.offerForm.controls.shiftToWork.setValue(shift.length > 0 ? shift[0].shiftId : null);
    } else {
      this.offerForm.controls.shiftToWork.setValue(Number(this.offerForm.controls.shiftToWork.value))
    }

    let obj: any = Object.assign({}, this.offerForm.value);
    obj.dateToSkip = mndate;
    obj.dateToWork = mxdate;

    const saveMethod = this.offerForm.controls.offerId.value > 0 ? this.offerService.updateOffer(obj) :
      this.offerService.addOffer(obj);

    saveMethod.then(res => {
      if (res['Success']) {
        this.toaster.success(this.offerForm.controls.offerId.value > 0 ?
          Constants.OFFER_UPDATE_SUCCESS_MSG : Constants.OFFER_ADD_SUCCESS_MSG);
          this.close.emit(true);
      } else {
        this.utility.showErrorToast(res['Message']);
        this.removeFieldValue = true;
        this.removeWorkValue = true;
      }
    }, err => { })

  }
  

  cancel() {
    this.close.emit(false);
  }

  control(controlName: string): AbstractControl {
    return this.offerForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }

  skipDateChange(event) {
    if (!!event) {
      this.minDate = moment(event).startOf('week').toDate();
      this.maxDate = moment(event).endOf('week').toDate();
      // this.currentDate = new Date(event);
      // this.first = this.currentDate.getDate() - this.currentDate.getDay();
      // this.minDate = new Date(this.currentDate.setDate(this.first));
      // this.maxDate = new Date(this.currentDate.setDate(this.first + 6));
      if (!!this.shiftDetail && this.role === this.roleEnum.user) {
        this.disableDaysForDateToWork = [];
        if (this.role === this.roleEnum.user) {
          for (let k = 0; k < 7; k++) {
            if (this.disableDaysForDateToSkip.indexOf(k) < 0) {
              this.disableDaysForDateToWork.push(k);
            }
          }
        }
      }
      for (let j = 0; j < moment(event).day(); j++) {
        if (moment(this.minDate).add(j, 'days').toDate() <= this.nextMinDate) {
          if (this.disableDaysForDateToWork.indexOf(j) < 0) {
            this.disableDaysForDateToWork.push(j);
          }
        }
      }

      if (this.offerForm.controls.dateToWork.value >= this.minDate && this.offerForm.controls.dateToWork.value <= this.maxDate) {
        this.offerForm.controls.dateToWork.setErrors(null);
      } else {
        this.offerForm.controls.dateToWork.setErrors({ invalid: true });
      }
      if (this.role === this.roleEnum.manager && !!this.offerForm.controls.dateToSkip.value && !!this.offerForm.controls.departmentId.value) {
        this.shiftList = [];
        !this.removeFieldValue ? this.offerForm.controls.shiftToSkip.setValue('') : this.removeFieldValue = false;;
        const skipDate = this.datepipe.transform(this.setnewDate(event), 'yyyy-MM-dd');
        this.skipShiftByManager(skipDate, Number(this.offerForm.controls.departmentId.value));
      }
    }
  }

  setnewDate(date) {
    const dateObj = {
      year: +(this.datepipe.transform(date, 'yyyy')),
      month: +(this.datepipe.transform(date, 'MM')),
      day: +(this.datepipe.transform(date, 'dd'))
    };
    return new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day']);
  }

  workDateChange(event) {
    if (!!event) {
      const workDate = this.datepipe.transform(this.setnewDate(event), 'yyyy-MM-dd');
      const skipDate = this.datepipe.transform(this.setnewDate(this.offerForm.controls.dateToSkip.value), 'yyyy-MM-dd');
      if (this.role === this.roleEnum.user && this.isShowShift) {
        this.departmentId = !!this.offer ? this.offer.departmentId : Number(localStorage.getItem(Constants.DEPARTMENTID));
        this.shiftList = [];
        !this.removeFieldValue ? this.offerForm.controls.shiftToWork.setValue('') : this.removeFieldValue = false;
        this.shiftFilterByDateAndDepartment(skipDate, workDate, this.departmentId);
      } else if (this.role === this.roleEnum.manager && !!this.offerForm.controls.departmentId.value) {
        this.managerWorkShiftList = [];
        !this.removeWorkValue ? this.offerForm.controls.shiftToWork.setValue('') : this.removeWorkValue = false;
        this.shiftFilterByDateAndDepartment(skipDate, workDate, Number(this.offerForm.controls.departmentId.value));
      }
    }
  }

  shiftFilterByDateAndDepartment(skipDate, workDate, departmentId) {
    this.shiftService.getShiftListByWorkDate(skipDate, workDate, departmentId).then(res => {
      if (res['Success']) {
        this.role == this.roleEnum.manager ? this.managerWorkShiftList = res['Data'] : this.shiftList = res['Data'];
      } else {
        this.shiftList = [];
        this.managerWorkShiftList = [];
      }
    }, err => { this.shiftList = []; this.managerWorkShiftList = []; });
  }

  onDepartmentChange(event) {
    if (!!event.currentTarget) {
      if (!!event.currentTarget.value) {
        this.shiftList = [];
        this.managerWorkShiftList = [];
        this.offerForm.controls.shiftToSkip.setValue('');
        this.offerForm.controls.shiftToWork.setValue('');
        if (!!this.offerForm.controls.dateToWork.value && !!this.offerForm.controls.dateToSkip.value) {
          const workDate = this.datepipe.transform(this.setnewDate(this.offerForm.controls.dateToWork.value), 'yyyy-MM-dd');
          const skipDate = this.datepipe.transform(this.setnewDate(this.offerForm.controls.dateToSkip.value), 'yyyy-MM-dd');
          this.shiftFilterByDateAndDepartment(skipDate, workDate, Number(event.currentTarget.value));
          this.skipShiftByManager(skipDate, Number(event.currentTarget.value));
        }
      } else {
        this.shiftList = [];
        this.managerWorkShiftList = [];
        this.offerForm.controls.shiftToSkip.setValue('');
        this.offerForm.controls.shiftToWork.setValue('');
      }
    } else {
      if (!!event) {
        this.shiftList = [];
        this.managerWorkShiftList = [];
        if (!!this.offer && !!this.offer.dateToSkip && !!this.offer.dateToWork && !!this.offer.departmentId) {
          const workDate = this.datepipe.transform(this.setnewDate(new Date(this.offer.dateToWork)), 'yyyy-MM-dd');
          const skipDate = this.datepipe.transform(this.setnewDate(new Date(this.offer.dateToSkip)), 'yyyy-MM-dd');
          this.shiftFilterByDateAndDepartment(skipDate, workDate, Number(event));
          this.skipShiftByManager(skipDate, Number(event));
        }
      } else {
        this.shiftList = [];
        this.managerWorkShiftList = [];
        this.offerForm.controls.shiftToSkip.setValue('');
        this.offerForm.controls.shiftToWork.setValue('');
      }
    }
  }

  skipShiftByManager(skipDate, departmentId) {
    this.shiftService.getShiftListBySkipDate(skipDate, departmentId).then(res => {
      if (res['Success']) {
        this.shiftList = res['Data'];
      } else { this.shiftList = []; }
    }, err => { this.shiftList = []; });
  }
}
