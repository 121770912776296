import { Injectable } from '@angular/core';
import { Constants } from 'src/app/constant/constants';
import { ApiService } from 'src/app/service/api.service';
import { API } from 'src/app/routes/api-routes';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/service/utility.service';

@Injectable()
export class AuthService {
  constructor(private apiService: ApiService, private http: HttpClient, private router: Router, private utilityervice: UtilityService) {
  }

  get role(): number {
    return Number(localStorage.getItem(Constants.ROLE));
  }

  async commonToken(){
    return await this.http.get(`${environment.APP_URL}${API.SIGN_UP_ROUTES.commonToken}`).toPromise();; 
  }

  signUp(params) {
    return this.apiService.post(`${API.SIGN_UP_ROUTES.signUp}`, params, false);
  }

  login(params) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
      return this.http.post(`${environment.APP_URL}${API.LOGIN_ROUTES.login}`, params, { headers: headers }).toPromise()
    .finally(() => {
      this.utilityervice.hideLoading();
    });
  }

  getUserData() {
    return this.apiService.get(`${API.LOGIN_ROUTES.userData}`, true);
  }

  hasUserNameEmployeeIdExists(companyId, isUser, userName, employeeId) {
    return this.apiService.get(`${API.SIGN_UP_ROUTES.isUserAuthenticate}?companyId=${companyId}&isUser=${isUser}&userName=${userName}&employeeId=${employeeId}`, false);
  }

  getConfiguration() {
    return this.apiService.get(`${API.SIGN_UP_ROUTES.getConfiguration}`, false, true, false);
  }

  agreeForCondition(userId) {
    return this.apiService.put(`${API.TERMS_CONDITION_ROUTES.updateTerms}?userId=${userId}`, null, true);
  }

  loginAsUser(userId) {
    return this.apiService.get(`${API.LOGIN_ROUTES.loginAsUser}?userId=${userId}`, true, true);
  }

  pendingRequestCount(){
    return this.apiService.get(`${API.LOGIN_ROUTES.hrpendingRequestCount}`, true); 
  }
}
