import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class CaptchaService {
  subject = new Subject<any>();
  constructor() { }

  sendForCaptcha(value: boolean) {
    this.subject.next({ show: value });
  }
  
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}