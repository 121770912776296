export class TrackFunctionTimeModel{
    RecordId? :Number;
    CompanyId : Number;
    CreatedBy : Number;
    LocationId? : string;
    FunctionId : Number;
    ShiftStartTime? : any;
    ShiftEndTime? : any;
    FunctionStartTime? : any;
    FunctionEndTime? : any;
    ModifiedBy? : Number;
    ModifiedDate? : any;
    IsDeleted? : boolean;
    AutoCloseFlag? : boolean;
    IsLocked? : boolean;
}