import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { SharedModule } from '../shared/shared/shared.module';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { AddEditSettingComponent } from './add-edit-setting/add-edit-setting.component';
import { DirectiveModule } from '../shared/shared/directive/directive.module';


@NgModule({
  declarations: [SettingsComponent, AddEditSettingComponent],
  imports: [
    SharedModule,
    NgxBootstrapIconsModule.pick(allIcons),
    CommonModule,
    DirectiveModule,
    SettingsRoutingModule
  ]
})
export class SettingsModule { }
