import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { FormService } from 'src/app/core/form.service';
import { Constants, timeOffClassList } from 'src/app/constant/constants';
import { TimeOff } from 'src/app/models/time-off.model';
import { TimeOffService } from 'src/app/service/time-off.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/service/utility.service';

@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent implements OnInit {
  @Input() timeOff: TimeOff;
  @Output() close = new EventEmitter<boolean>();
  timeoffForm: FormGroup;
  typeList = timeOffClassList;
  timeoffCofigTypeList=[];
  companyId: number;
  public messageList: any = new TimeOff();
  constructor(private formService: FormService, private utilityService: UtilityService, private timeOffService: TimeOffService, private toaster: ToastrService,
  ) { }

  ngOnInit() {
    this.utilityService.hideLoading();
    this.initializeTimeOffConfigForm();
    this.initializeMessages();
    this.getTimeOffConfigTypeList();
  }
  initializeTimeOffConfigForm() {
    this.timeoffForm = new FormGroup({
      companyId: new FormControl(Number(localStorage.getItem(Constants.COMPANYID))),
      TimeOffId: new FormControl(!!this.timeOff ? this.timeOff.TimeOffId : 0),
      TimeOffConfigTypeId : new FormControl(!!this.timeOff ? this.timeOff.TimeOffConfigTypeId : '',Validators.required),
      SeedAtHire: new FormControl(!!this.timeOff ? this.timeOff.SeedAtHire : '', [Validators.required,Validators.pattern(Constants.REGEX.INTEGER_PATTERN)]),
      AnnualAllocation: new FormControl(!!this.timeOff ? this.timeOff.AnnualAllocation : '', [Validators.required,Validators.pattern(Constants.REGEX.INTEGER_PATTERN)]),
      ClassType: new FormControl(!!this.timeOff ? this.timeOff.ClassType : '', Validators.required),
      MaxAccumulation: new FormControl(!!this.timeOff ? this.timeOff.MaxAccumulation : '', [Validators.required,Validators.pattern(Constants.REGEX.INTEGER_PATTERN)]),
      UseItOrLoseIt: new FormControl(!!this.timeOff ? this.timeOff.UseItOrLoseIt : false),
      isActive: new FormControl(true),
    });
  }
  initializeMessages() {
    this.messageList.TimeOffConfigTypeId = {
      required: Constants.VALIDATION_MSG.TIME_OFF.TIME_OFF_TYPE,
    };
    this.messageList.SeedAtHire = {
      required: Constants.VALIDATION_MSG.TIME_OFF.DEFAULT_SEED_AT_HIRE,
      pattern:Constants.TIME_OFF_VALID_VALUE
    };
    this.messageList.AnnualAllocation = {
      required: Constants.VALIDATION_MSG.TIME_OFF.DEFAULT_ALLOCATION,
      pattern:Constants.TIME_OFF_VALID_VALUE
    };
    this.messageList.ClassType = {
      required: Constants.VALIDATION_MSG.TIME_OFF.TIME_OFF_CLASS_REQ,
    };
    this.messageList.MaxAccumulation = {
      required: Constants.VALIDATION_MSG.TIME_OFF.MAXIMUM_ACCUMMULATION,
      pattern:Constants.TIME_OFF_VALID_VALUE
    };
  }

  getTimeOffConfigTypeList()
  {
    this.timeOffService.getTimeOffConfigTypelist().then((res) => {
      if(res["Success"]){
        this.timeoffCofigTypeList = res["Data"]
      }
    })
  }

  cancel() {
    this.close.emit(false);
  }

  control(controlName: string): AbstractControl {
    return this.timeoffForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }


  onSubmit() {
    this.formService.markFormGroupTouched(this.timeoffForm);
    if (this.timeoffForm.invalid) {
      return;
    }
    const saveMethod = this.timeoffForm.controls.TimeOffId.value > 0 ? this.timeOffService.updateTimeOffConfig(this.timeoffForm.value)
      : this.timeOffService.addTimeOffConfig(this.timeoffForm.value);
    saveMethod.then((res) => {
      if (res['Success']) {
        this.toaster.success(this.timeoffForm.controls.TimeOffId.value > 0 ? Constants.TIME_OFF_CONFIG_EDIT_MSG : Constants.TRAINING_ADD_SUCCESS_MSG);
        this.close.emit(true);
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { this.close.emit(false); });
  }
}
