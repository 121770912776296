import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Constants, bsConfig } from 'src/app/constant/constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OfferStatus } from 'src/app/models/role-model';
import { ToastrService } from 'ngx-toastr';
import { OfferService } from 'src/app/service/offer.service';
import { UtilityService } from './../../service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-employer-swap-request',
  templateUrl: './employer-swap-request.component.html',
  styleUrls: ['./employer-swap-request.component.scss']
})
export class EmployerSwapRequestComponent implements OnInit {
  @Input() requestType: string;
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  @Output() reloadCount = new EventEmitter<boolean>();
  requestedOffer: any;
  requestedOfferList = [];
  modalRef: BsModalRef;
  isConfirmed = false;
  totalItems: any;
  rowsOnPage = Constants.HR_PAGE_ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  offerId: number;
  confirmMsg = '';
  confirmSaveBtn = '';
  confirmCancelBtn = '';
  offerStatus = OfferStatus;
  isApprove = false;
  isReject = false;
  searchSort: any;
  columns = [];
  isShowLink = false;
  bsConfig = bsConfig;
  closedOfferList = [];
  IsCoverageCatch: boolean = false;

  constructor(private modalService: BsModalService,
    private toaster: ToastrService,
    private offerService: OfferService,
    private utilityService: UtilityService) {
    this.columns = ['createdByUsername', 'dateToSkipDateStr', 'createdByShift', 'shiftToWorkTitle',
                   'acceptedByUsername', 'dateToWorkDateStr', 'acceptedByShift', 'shiftToSkipTitle',
                    'offerStatusStr', 'approvedByObj.companyUsername','approvedDate'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.isConfirmed = false;
    this.IsCoverageCatch = localStorage.getItem(Constants.APP_NAME) === "CoverageCatch" ? true:false;
    this.setPage({ offset: 0 });
  }

  async setPage(pageInfo,isReloadCount = false) {
    this.page.pageNumber = pageInfo.offset;
    this.requestType === Constants.REQUESTED_OFFER ?
      await this.getRequestedOfferList(this.page.pageNumber + 1)
      : await this.getClosedOfferList(this.page.pageNumber + 1);
      if(isReloadCount){
        this.reloadCount.emit();
      }
  }

  async getRequestedOfferList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.isShowLink = true;
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      await this.offerService.getRequestedOfferList(currentPage, this.rowsOnPage)
        .then((res: any) => {
          if(JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))){
            this.utilityService.showLoading();
          }
          if (res['Success']) {
            this.requestedOfferList = res.Data.results;
            this.totalItems =res.Data.totalNumberOfRecords;
          } else {
            this.requestedOfferList = [];
            this.totalItems = 0;
          }
        }, err => { this.requestedOfferList = []; });
    }
  }

  async getClosedOfferList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.isShowLink = true;
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
     await this.offerService.getClosedOfferList(currentPage, this.rowsOnPage)
        .then((res: any) => {
          if(JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))){
            this.utilityService.showLoading();
          }
          if (res['Success']) {
            this.closedOfferList = res.Data.results;
            this.totalItems = res.Data.totalNumberOfRecords;
          } else {
            this.closedOfferList = [];
            this.totalItems = 0;
          }
        }, err => { this.closedOfferList = []; });
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.isShowLink = true;
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.isShowLink = false;
        this.clear();
      }
    }
  }

  close(event) {
    this.isConfirmed = false;
    this.isApprove = false;
    this.isReject = false;
  }

  checkApproveReject(type, data) {
    this.isConfirmed = true;
    if (type === Constants.APPROVE) {
      this.isApprove = true;
      this.confirmSaveBtn = Constants.PENDING_CONFIRM_BTN_TEXT;
      this.confirmCancelBtn = Constants.PENDING_CANCEL_BTN_TEXT;
      this.confirmMsg = Constants.APPROVE_MSG;
    } else {
      this.isReject = true;
      this.confirmSaveBtn = Constants.YES;
      this.confirmCancelBtn = Constants.NO;
      this.confirmMsg = Constants.REJECT_MSG;
    }
    this.offerId = data.offerId;
  }

  ApproveReject(id) {
    this.isConfirmed = false;
    if (this.isApprove) {
      this.offerService.approveOffer(id).then(res => {
        if (res['Success']) {
          this.toaster.success(Constants.OFFER_APPROVE_SUCCESS_MSG);
          this.setPage({ offset: 0 }, true);
          this.isApprove = false;
        } else { this.toaster.error(res['Message']); }
      }, err => { });
    } else {
      this.offerService.rejectOffer(id).then(res => {
        if (res['Success']) {
          this.toaster.success(Constants.OFFER_REJECT_SUCCESS_MSG);
          this.setPage({ offset: 0 },true);
        } else { this.toaster.error(res['Message']); }
      }, err => { });
    }
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  clear() {
    this.isShowLink = false;
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  filterData() {
    const filterMethod = this.requestType === Constants.REQUESTED_OFFER ?
      this.offerService.approvalOfferDataFilter(this.searchSort) :
      this.offerService.closedOfferDataFilter(this.searchSort);
    filterMethod.then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.requestType === Constants.REQUESTED_OFFER ?
          this.requestedOfferList = res['data']
          : this.closedOfferList = res['data'];
        this.totalItems =  res['recordsFiltered'];
      } else { this.closedOfferList = []; this.requestedOfferList = []; this.totalItems = 0; }
    }, err => { this.closedOfferList = []; this.requestedOfferList = []; this.totalItems = 0; });
  }

  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }
  downloadFile(){
    const downloadMethod = this.requestType !== 'approvedOffer' ?
    this.offerService.downloadPendingExcelFile(this.searchSort) :
    this.offerService.downloadClosedExcelFile(this.searchSort);
  downloadMethod.then(response => {
    if (response['Success']) {
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      a.href = environment.BLOB_URL + response['Message'];
      a.download = response['Message'].split('/')[1];
      a.click();
    } else {
      this.utilityService.showErrorToast(response['Message']);
    }
  }, err => { });;
  }
}
