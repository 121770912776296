import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User, SignUpMessageList } from 'src/app/models/signup-model';
import { FormGroup, FormControl, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { Role } from 'src/app/models/role-model';
import { FormService } from 'src/app/core/form.service';
import { DepartmentService } from 'src/app/service/department.service';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/service/company.service';
import { Constants, roleList, phoneMask, dropdownList, drpSetting, SubscriptionType, bsConfig } from 'src/app/constant/constants';
import { LocationService } from 'src/app/service/location.service';
import { ShiftService } from 'src/app/service/shift.service';
import { UserService } from 'src/app/service/user.service';
import { ValidationService } from '../../service/validation.service';
import { PlanService } from 'src/app/service/plan.service';
import { BsDatepickerConfig, BsDatepickerViewMode } from "ngx-bootstrap/datepicker";
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UtilityService } from 'src/app/service/utility.service';
@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent implements OnInit {
  public subDepartmentList: any = [];
  @Input() user: User;
  @Output() close = new EventEmitter<boolean>();
  public userForm: FormGroup;
  public messageList: any = new SignUpMessageList();
  roleEnum = Role;
  companyId: number;
  companyList = [];
  role: number;
  locationList = [];
  departmentList = [];  
  shiftList = [];
  eyeShow = false;
  isShowConfirmPassword = false;
  roleList = roleList;
  commonRoleList =roleList;
  allShiftList = [];
  phoneMask = phoneMask;
  dropdownList = dropdownList;
  selectedItems = [];
  selectedRoleItems = [];
  dropdownSettings = drpSetting.dropdownSettings;
  methods = [];
  methodsRole = [];
  subscriptionType = SubscriptionType;
  isHireDate = false;
  userid: any;
  pricingId: any;
  bsConfig: Partial<BsDatepickerConfig> = bsConfig;
  managerUserList = [];
  isShowManager = false;
  isValidUser = true;
  locationId: number;
  roleId: number = 4;
  isTimeOff: boolean = false;

  constructor(private formService: FormService,
    private departmentService: DepartmentService,
    private toaster: ToastrService,
    private companyService: CompanyService,
    public validationService: ValidationService,
    private locationService: LocationService,
    private shiftService: ShiftService,
    private userService: UserService,
    private planService: PlanService,
    private datepipe: DatePipe,
    public authService: AuthService,
    private utilityService: UtilityService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.isTimeOff = JSON.parse(localStorage.getItem(Constants.IS_TIME_OFF));

    this.bsConfig = Object.assign({}, {
      dateInputFormat: 'MM-DD-YYYY',
    });
    if ((this.role === Role.superUser || this.role === Role.hrAdmin) && this.isTimeOff) {
      this.isHireDate = true;
    } else {
      this.isHireDate = false;
    }
    if (this.role === Role.superUser) {
      this.getCompanyList()
    }
    this.filterRoleList();
    if (!!this.user) {
      this.companyId = this.user.companyId;
    }
    if (this.role === this.roleEnum.superUser) {
      this.companyId = !!this.user ? this.user.companyId : 0;
    } else {
      this.companyId = !!this.user ? this.user.companyId : Number(localStorage.getItem(Constants.COMPANYID));
    }
    if (!!this.companyId) {
      this.getLocationList();
      this.getDepartmentList();
      this.getShiftList();
    }
    this.getManager();
    this.initializeUserForm();
    this.initializeMessages();
    // this.getSubscriptionType();
  }

  initializeUserForm() {
    this.userForm = this.fb.group(
      {
        userId: new FormControl(!!this.user ? this.user.userId : 0),
        emailId: new FormControl(!!this.user ? this.user.emailId : '', [
          Validators.required,
          Validators.pattern(Constants.REGEX.EMAIL_PATTERN),
        ]),
        name: new FormControl(!!this.user ? this.user.name : '', Validators.required),
        vCode: new FormControl(!!this.user ? this.user.vCode : ''),
        companyId: new FormControl(!!this.user ? this.user.companyId : ''),
        managerId: new FormControl(!!this.user ? this.user.managerId : ''),
        departmentId: new FormControl(!!this.user ? this.user.departmentId : '', Validators.required),
        subDepartmentId: new FormControl(!!this.user ? !!this.user.subDepartmentId ? this.user.subDepartmentId : '' : ''),
        locationId: new FormControl(!!this.user ? this.user.locationId : '', Validators.required),
        isRecievingTextMessage: new FormControl(!!this.user ? this.user.isRecievingTextMessage : false),
        phone: new FormControl(!!this.user ? this.user.phone : '', [
          Validators.required,
          //Validators.pattern(Constants.REGEX.PHONE_PATTERN)
        ]),
        companyUsername: new FormControl(!!this.user ? this.user.companyUsername : '', Validators.required),
        employeeId: new FormControl(!!this.user ? this.user.employeeId : ''),
        // roleId: new FormControl(!!this.user ? this.user.roleId : '', Validators.required),
        shiftId: new FormControl(!!this.user ? this.user.shiftId : '', Validators.required),
        isActive: new FormControl(!!this.user ? this.user.isActive : true),
        HireDate: new FormControl((!!this.user && !!this.user.HireDate) ? new Date(this.user.HireDate) : null),
        GetCommunicationMethod: new FormControl(!!this.user ? this.user.GetCommunicationMethod : this.selectedItems, Validators.required),
        Roles: new FormControl(!!this.user ? this.user.Roles : this.selectedRoleItems, Validators.required),
        password: new FormControl(!!this.user ? this.user.password : '', !!this.user ? null : [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(16),
        ]),
        confirmPassword: new FormControl(!!this.user ? this.user.password : '', !!this.user ? null : Validators.required)
      },
      {
        validators: this.validationService.confirmedValidator('password', 'confirmPassword')
      }
    );

    if (!!this.userForm.controls.GetCommunicationMethod.value) {
      this.userForm.controls.GetCommunicationMethod.value.forEach(element => {
        this.selectedItems.push(this.dropdownList[Number(element - 1)]);
      });
      this.userForm.get('GetCommunicationMethod').patchValue(this.selectedItems);
    } else {
      this.userForm.get('GetCommunicationMethod').patchValue([]);
    }
    if (!!this.userForm.controls.Roles.value) {
      this.userForm.controls.Roles.value.forEach(element => {
        this.selectedRoleItems.push({ id: element, text: this.commonRoleList.find(x => x.id == element)['text'] });
      });
      this.userForm.get('Roles').patchValue(this.selectedRoleItems);
    } else {
      this.userForm.get('Roles').patchValue([]);
    }
    if (this.role === Role.superUser) {
      this.userForm.controls.companyId.setValidators(Validators.required);
    }
    if (!!this.user) {
      this.setOptionalField(this.user.roleId)
      if (this.user.roleId === Role.user) {
        this.isShowManager = true;
      }
    }
    // if (!!this.userForm.controls.HireDate.value) {
    //   const mxdate = this.datepipe.transform(
    //     this.setnewDate(this.userForm.controls.HireDate.value),
    //     "yyyy-MM-dd"
    //   );
    //   this.userForm.controls.HireDate.setValue(mxdate);
    // }
  }

  setnewDate(date) {
    const dateObj = {
      year: +(this.datepipe.transform(date, 'yyyy')),
      month: +(this.datepipe.transform(date, 'MM')),
      day: +(this.datepipe.transform(date, 'dd'))
    };
    return new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day']);
  }
  initializeMessages() {
    this.messageList.emailId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.EMAIL_REQUIRED,
      pattern: Constants.VALIDATION_MSG.SIGN_UP.EMAIL_INVALID,
    };
    this.messageList.password = {
      required: Constants.VALIDATION_MSG.SIGN_UP.PASSWORD_REQUIRED,
    };
    this.messageList.name = {
      required: Constants.VALIDATION_MSG.SIGN_UP.NAME_REQUIRED,
    };
    this.messageList.companyId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMPANY_REQUIRED,
    };
    this.messageList.departmentId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.DEPARTMENT_REQUIRED,
    };
    this.messageList.subDepartmentId = {
      required: Constants.VALIDATION_MSG.SUB_DEPARTMENT.SUB_DEPARTMENT_NAME_REQUIRED,
    };
    this.messageList.locationId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.LOCATION_REQUIRED,
    };
    this.messageList.phone = {
      required: Constants.VALIDATION_MSG.SIGN_UP.PHONE_REQUIRED,
      // pattern: Constants.VALIDATION_MSG.SIGN_UP.PHONE_INVALID
    };
    this.messageList.companyUsername = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMPANY_NAME_REQUIRED,
    };
    this.messageList.employeeId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.EMPLOYEEID_REQUIRED,
    };
    this.messageList.roleId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.ROLE_REQUIRED,
    };
    this.messageList.shiftId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.SHIFT_REQUIRED,
    };
    this.messageList.companyId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMPANY_REQUIRED,
    };
    this.messageList.confirmPassword = {
      required: Constants.VALIDATION_MSG.SIGN_UP.CONFIRM_PASSWORD_REQUIRED,
      confirmedValidator: Constants.VALIDATION_MSG.SIGN_UP.PASSWORD_CONFIRM_PASSWORD_NOT_MATCH
    };
    this.messageList.GetCommunicationMethod = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMMUNICATION_METHOD_REQUIRED
    }
  }

  getSubDeptByDepartment(departmentId, companyId) {

    this.departmentService.getSubDeptByDepartment(departmentId, companyId)
      .then(response => {
        if (response['Success']) {
          this.subDepartmentList = response['Data'];
        } else {
          this.subDepartmentList = [];
        }
      }, err => { this.subDepartmentList = []; });

  }

  getCompanyList() {
    this.companyService.getCompanyListWithOutPagination().then(res => {
      if (res['Success']) {
        this.companyList = res['Data'];
      } else { this.companyList = []; }
    }, err => { this.companyList = []; })
  }

  // getSubscriptionType() {
  //   this.getUser();

  // }
  // getUser() {
  //   this.userService.getUserByUserId(Number(localStorage.getItem(Constants.USERID)))
  //     .then(response => {
  //       this.pricingId = response['Data'].company.pricing.pricingId;
  //       this.getpricing();
  //       // this.commonShiftList = _.merge([], this.shiftList)
  //     }, err => { });
  // }
  // getpricing() {
  //   this.planService.getUserByPricingId(this.pricingId)
  //     .then(response => {
  //       this.isHireDate = response['Data'].isTimeOff;
  //     }, err => { });
  // }

  onCompanyChange(event: any) {

    const id = Number(event.currentTarget.value);
    if (!!id) {
      this.companyId = id;
      this.getDepartmentList();
      this.getShiftList();
      this.getLocationList();
    } else {
      this.companyId = 0;
      this.departmentList = [];
      this.shiftList = [];
      this.userForm.get('departmentId').setValue('');
      this.userForm.get('shiftId').setValue('');
      this.userForm.get('locationId').setValue('');
    }
    // this.getSubscriptionType();
  }

  getLocationList() {
    this.locationService.getLocationListByCompany(this.companyId, null).then(res => {
      if (res['Success']) {
        this.locationList = res['Data'];
      } else { this.locationList = []; }
    }, err => { this.locationList = []; });
  }

  getDepartmentList() {
    this.departmentService.getDepartmentListByCompanyId(null, this.companyId).then(res => {
      if (res['Success']) {
        this.departmentList = res['Data'];
      } else { this.departmentList = []; }
    }, err => { this.departmentList = []; });
  }

  getShiftList() {
    this.shiftService.getShiftListByCompanyId(null, this.companyId).then(res => {
      if (res['Success']) {
        this.allShiftList = res['Data'];
        this.shiftList = [];
        if (!!this.user && !!this.user.departmentId) {
          this.onDepartmentChange(this.user.departmentId);
        }
      } else { this.shiftList = []; this.allShiftList = []; }
    }, err => { this.shiftList = []; this.allShiftList = []; })
  }

  toggleEyeShow() {
    this.eyeShow = !this.eyeShow;
  }

  toggleisShowConfirmPassword() {
    this.isShowConfirmPassword = !this.isShowConfirmPassword;
  }

  filterRoleList() {
    if (this.role === this.roleEnum.superUser) {
      this.roleList = this.roleList.slice(1, 6);
    } else if (this.role === this.roleEnum.purchaser) {
      this.roleList = this.roleList.slice(1, 6)
    } else if (this.role === this.roleEnum.hrAdmin) {
      this.roleList = this.roleList.slice(2, 6)
    } else if (this.role === this.roleEnum.manager) {
      this.roleList = this.roleList.slice(4, 6);
    }
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.userForm);
    if (this.userForm.invalid) {
      return;
    }

    if (this.userForm.value.GetCommunicationMethod.length > 0) {
      const communicationMethod = this.userForm.value.GetCommunicationMethod.map(x => x.id.toString());
      this.userForm.get('GetCommunicationMethod').patchValue(communicationMethod);
    } else {
      this.userForm.controls.GetCommunicationMethod.setValue([]);
    }
    if (this.userForm.value.Roles.length > 0) {
      const Roles = this.userForm.value.Roles.map(x => x.id.toString());
      this.userForm.get('Roles').patchValue(Roles);
    } else {
      this.userForm.controls.Roles.setValue([]);
      this.toaster.error(Constants.VALIDATION_MSG.SIGN_UP.ROLE_REQUIRED);
      return;
    }
    this.userForm.controls.companyId.setValue(this.companyId);
    this.userForm.controls.locationId.setValue(!!this.userForm.controls.locationId.value ? Number(this.userForm.controls.locationId.value) : null);
    this.userForm.controls.departmentId.setValue(!!this.userForm.controls.departmentId.value ? Number(this.userForm.controls.departmentId.value) : null);
    this.userForm.controls.shiftId.setValue(!!this.userForm.controls.shiftId.value ? Number(this.userForm.controls.shiftId.value) : null);
    this.userForm.controls.employeeId.setValue(this.userForm.controls.employeeId.value);
    // this.userForm.controls.roleId.setValue(Number(this.userForm.controls.roleId.value));
    if (!!this.userForm.controls.HireDate.value) {
      const mxdate = this.datepipe.transform(
        this.setnewDate(this.userForm.controls.HireDate.value),
        "yyyy-MM-dd"
      );
      this.userForm.controls.HireDate.setValue(mxdate);
    }
    this.userForm.controls.managerId.setValue(Number(this.userForm.controls.managerId.value));
    const data = {
      ...this.userForm.value,
    };
    delete data.confirmPassword;
    const saveMethod = this.userForm.controls.userId.value > 0 ? this.userService.updateUser(data) :
      this.userService.addUser(data)
    saveMethod.then((res: any) => {
      if (res['Success']) {
        this.toaster.success(this.userForm.controls.userId.value > 0 ? Constants.USER_UPDATE_SUCESS_MSG : Constants.USER_ADD_SUCCESS_MSG);
        this.close.emit(true);
      } else {
        this.toaster.error(res['Message']);
        this.selectedItems = [];
        this.methods = [];
        this.userForm.value.GetCommunicationMethod.forEach(element => {
          const getMethod = this.dropdownList.find(x => x.id === Number(element));
          if (!!getMethod) {
            this.methods.push(getMethod);
          }
        });
        this.selectedItems = this.methods;
        this.userForm.get('GetCommunicationMethod').patchValue(this.methods);
        
        this.selectedRoleItems = [];
        this.methodsRole = [];
        this.userForm.value.Roles.forEach(element => {
          const getMethod = this.roleList.find(x => x.id === Number(element));
          if (!!getMethod) {
            this.methodsRole.push(getMethod);
          }
        });
        this.selectedRoleItems = this.methodsRole;
        this.userForm.get('Roles').patchValue(this.methodsRole);
      }
    }, err => { this.close.emit(false); });
  }

  cancel() {
    this.close.emit(false);
  }

  onRoleChange(event) {
    this.setOptionalField(Number(event.id));

    if (!this.isValidUser) {
      if (this.userForm.controls.roleId.value == "5") {
        this.isShowManager = true;
      }
      else {
        this.userForm.get('managerId').setValue('');
        this.isShowManager = false;
      }
    }
  }

  setOptionalField(value) {
    this.userForm.controls.departmentId.setValidators(value !== 5 ? null : [Validators.required]);
    this.userForm.controls.shiftId.setValidators(value !== 5 ? null : [Validators.required]);
    this.userForm.controls.locationId.setValidators(value !== 5 ? null : [Validators.required]);
    this.userForm.controls.Roles.setValidators(value !== 5 ? null : [Validators.required]);
    this.userForm.controls.employeeId.setValidators(null);
    this.userForm.controls.departmentId.updateValueAndValidity();
    this.userForm.controls.shiftId.updateValueAndValidity();
    this.userForm.controls.employeeId.updateValueAndValidity();
    this.userForm.controls.locationId.updateValueAndValidity();

  }

  control(controlName: string): AbstractControl {
    return this.userForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }

  onDepartmentChange(event) {

    if (!!event.currentTarget) {
      if (!!event.currentTarget.value) {
        this.shiftList = this.allShiftList;
        this.shiftList = this.shiftList.filter(x => x.departmentId === Number(event.currentTarget.value));
        this.userForm.controls.shiftId.setValue('');
        this.userForm.controls.subDepartmentId.setValue('');

        this.getSubDeptByDepartment(Number(event.currentTarget.value), this.companyId);
      } else {
        this.shiftList = [];
        this.userForm.controls.shiftId.setValue('');
        this.userForm.controls.subDepartmentId.setValue('');
      }
    } else {
      if (!!event) {
        this.shiftList = this.allShiftList;
        this.shiftList = this.shiftList.filter(x => x.departmentId === Number(event));
        this.getSubDeptByDepartment(Number(event), this.companyId);
      } else {
        this.shiftList = [];
        this.userForm.controls.shiftId.setValue('');
        this.userForm.controls.subDepartmentId.setValue('');
      }
    }
  }

  onItemSelect(item: any) {

    this.selectedItems.push(item);
    this.userForm.controls.GetCommunicationMethod.setValue(this.selectedItems);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    this.selectedItems = items;
    this.userForm.controls.GetCommunicationMethod.setValue(this.selectedItems);
  }

  onItemDeSelect(items: any) {
    this.selectedItems = this.selectedItems.filter(x => x.id !== items.id);
    this.userForm.controls.GetCommunicationMethod.setValue(this.selectedItems);
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.userForm.controls.GetCommunicationMethod.setValue(this.selectedItems);
  }

  onRoleSelect(item: any) {

    this.selectedRoleItems.push(item);
    this.userForm.controls.Roles.setValue(this.selectedRoleItems);
    console.log(this.selectedRoleItems);
    
    this.onRoleChange(item);
    
  }
  onSelectAllRole(items: any) {
    this.selectedRoleItems = items;
    this.userForm.controls.Roles.setValue(this.selectedRoleItems);
  }

  onRoleDeSelect(items: any) {
    this.selectedRoleItems = this.selectedRoleItems.filter(x => x.id !== items.id);
    this.userForm.controls.Roles.setValue(this.selectedRoleItems);
  }

  onDeSelectAllRole() {
    this.selectedRoleItems = [];
    this.userForm.controls.Roles.setValue(this.selectedRoleItems);
  }

  onLocationChange(event: any) {
    const id = Number(event.currentTarget.value);
    if (!!id) {
      this.locationId = id;
      this.getManager();
    } else {
      this.locationId = 0;
      this.userForm.get('locationId').setValue('');
    }
    //this.checkValidUser();
  }

  getManager() {
    this.userService.getAllManagerList(this.companyId, this.locationId).then(res => {

      if (res['Success']) {
        this.managerUserList = res['Data'];
      } else {
        this.managerUserList = [];
      }
      this.utilityService.hideLoading();
    }, err => {
      this.managerUserList = [];
      this.utilityService.hideLoading();
    });
  }
  checkValidUser() {

    if (!!this.userForm.controls.companyUsername.value && !!this.userForm.controls.employeeId.value && this.companyId) {
      this.authService.hasUserNameEmployeeIdExists(this.companyId, false, this.userForm.controls.companyUsername.value, this.userForm.controls.employeeId.value).then(res => {
        if (res['Success']) {
          this.isValidUser = false;
        } else {
          this.isValidUser = true;
          this.utilityService.showErrorToast(res['Message']);
          this.isShowConfirmPassword = true;
        }
        if (this.userForm.controls.Roles.value.find( element => element.id == 5) ) {
          this.isShowManager = true;
        }
        this.utilityService.hideLoading();
      }, err => { });
    } else {
      this.isValidUser = true;
    }
  }
}
