import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-employer-training-request',
  templateUrl: './employer-training-request.component.html',
  styleUrls: ['./employer-training-request.component.scss']
})
export class EmployerTrainingRequestComponent implements OnInit {
  @Input() requestType: string;
  constructor() { }

  ngOnInit() {
  }

}
