import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Department } from 'src/app/models/department.model';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { FormService } from 'src/app/core/form.service';
import { DepartmentService } from 'src/app/service/department.service';
import { Constants } from 'src/app/constant/constants';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/service/company.service';
import { Role } from 'src/app/models/role-model';

@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent implements OnInit {
  @Input() department: Department;
  @Output() close = new EventEmitter<boolean>();
  departmentForm: FormGroup;
  public messageList: any = new Department();
  roleEnum = Role;
  companyList = [];
  role: number;
  constructor(private formService: FormService,
    private departmentService: DepartmentService,
    private toaster: ToastrService,
    private companyService: CompanyService) { }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.initializeDepartmentForm();
    this.initializeMessages();
    if (this.role === Role.superUser) {
      this.getCompanyList()
    }
  }


  initializeDepartmentForm() {
    this.departmentForm = new FormGroup({
      departmentId: new FormControl(!!this.department ? this.department.departmentId : 0),
      departmentName: new FormControl(!!this.department ? this.department.departmentName : '', Validators.required),
      companyId: new FormControl(!!this.department ? this.department.companyId : null),
      isActive: new FormControl(true)
    });
    if (this.role === Role.superUser) {
      this.departmentForm.controls.companyId.setValidators(Validators.required);
    }
  }

  initializeMessages() {
    this.messageList.departmentName = {
      required: Constants.VALIDATION_MSG.DEPARTMENT.DEPARTMENT_NAME_REQUIRED,
    };
    this.messageList.companyId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMPANY_REQUIRED,
    };
  }

  getCompanyList() {
    this.companyService.getCompanyListWithOutPagination().then(res => {
      if (res['Success']) {
        this.companyList = res['Data'];
      } else { this.companyList = []; }
    }, err => { this.companyList = []; })
  }

  cancel() {
    this.close.emit(false);
  }

  control(controlName: string): AbstractControl {
    return this.departmentForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.departmentForm);
    if (this.departmentForm.invalid) {
      return;
    }
    if (this.role !== Role.superUser) {
      this.departmentForm.controls.companyId.setValue(localStorage.getItem(Constants.COMPANYID));
    }
    const saveMethod = this.departmentForm.controls.departmentId.value > 0 ? this.departmentService.updatedDepartment(this.departmentForm.value)
      : this.departmentService.addDepartment(this.departmentForm.value);
    saveMethod.then((res) => {
      if (res['Success']) {
        this.toaster.success(this.departmentForm.controls.departmentId.value > 0 ? Constants.DEPARTMENT_UPDATE_SUCESS_MSG : Constants.DEPARTMENT_ADD_SUCCESS_MSG);
        this.close.emit(true);
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { this.close.emit(false); });
  }
}
