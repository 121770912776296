import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';

@Injectable()
export class InvoiceService {

  constructor(private apiService: ApiService) { }

  getInvoiceListByCompany(companyId, currentPage) {
    return this.apiService.get(
      `${API.INVOICE_ROUTES.getInvoice}?companyId=${companyId}&page=${currentPage}`,
      true,
      true,
    );
  }

  updateInvoice(params) {
    return this.apiService.put(`${API.INVOICE_ROUTES.updateInvoice}`, params, true);
  }

  printInvoiceData(invoiceId) {
    return this.apiService.get(
      `${API.INVOICE_ROUTES.printInvoice}?invoiceId=${invoiceId}`,
      true,
      true,
    );
  }

  dataFilter(data, companyId) { //dataFilter
    return this.apiService.post(`${API.INVOICE_ROUTES.dataFilter}?companyId=${companyId}`, data, true);
  }

}
