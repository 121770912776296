import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Constants, typeField } from 'src/app/constant/constants';
import { TermsConditionService } from 'src/app/service/terms-condition.service';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {
  @Input() offerId: number;
  termsCondition = [];
  termsMsg: string = '';
  companyId: number;

  @Output() acceptOfferId = new EventEmitter<any>();
  @Output() close = new EventEmitter<boolean>();
  constructor(private termsConditionService: TermsConditionService) { }

  ngOnInit() {
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.getTermsCondition();

  }

  getTermsCondition() {
    this.termsConditionService.getTermsConditionListByCompanyId(this.companyId, null).then(res => {
      if (res['Success']) {
        this.termsCondition = res['Data'];
        const findTerms = this.termsCondition.find(x => x.typeField === typeField[0].id);
        if (!!findTerms) {
          this.termsMsg = findTerms.description;
        }
      }
      else {
        this.termsCondition = [];
      }
    }, err => { this.termsCondition = []; });
  }

  disAgree(event) {
    this.close.emit(false);
  }

  agree() {
    this.acceptOfferId.emit(this.offerId);
    //this.close.emit(true);
  }
}
