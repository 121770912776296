export class Setting {
    Id:number;
    CompanyId: number;
    Description: string;
    Enable: boolean;
    OfferType:number;
    SettingType:string;
    VOTCutOffHours:number;
    ClockInGracePeriod:number;
    ClockInOutGracePeriod?:number;
    ClockOutGracePeriod:number;
    ClockInOutLocationGracePeriod:number;
}

export class SettingMessageList {
    description: string;
    enable: boolean;
    offerType:number;
    settingType:string;

}