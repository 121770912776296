import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Constants, SubscriptionType } from 'src/app/constant/constants';
import { FormService } from 'src/app/core/form.service';
import { Kiosk, Offer } from 'src/app/models/offer.model';
import { KioskService } from 'src/app/service/kiosk.service';
import { ToastrService } from 'ngx-toastr';
import { CallInRequestService } from 'src/app/service/callInRequest.service';

@Component({
  selector: 'app-add-edit-kiosk',
  templateUrl: './add-edit-kiosk.component.html',
  styleUrls: ['./add-edit-kiosk.component.scss']
})
export class AddEditKioskComponent implements OnInit {
  @ViewChild('confirmationPopup', { static: false }) confirmationPopup: TemplateRef<any>;
  @ViewChild('startTimeConfirmPopup', { static: false }) startTimeConfirmPopup: TemplateRef<any>;
  @Input() Offer: Offer;
  @Output() closeRequest = new EventEmitter<boolean>();

  isConfirm = false;
  confirmMsg: string;
  IsCoverMyWork: boolean = false;
  modalRef: BsModalRef;
  public messageList: any = new Kiosk();
  public kioskForm: FormGroup;
  counter: number = 0;
  settingList: any;
  message: string;
  moduleId: any;
  locationList = [];
  companyId: number;

  constructor(
    private formService: FormService,
    private datepipe: DatePipe,
    private toaster: ToastrService,
    private kiosk: KioskService,
  ) { }

  ngOnInit() {
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.initializeKioskForm();
    this.initializeMessages();
    this.getLocationList();
   
  }
  async initializeKioskForm() {
    this.kioskForm = new FormGroup(
      {
        KioskId: new FormControl(!!this.Offer ? this.Offer.KioskId : 0),
        offerType: new FormControl(11),
        LocationId: new FormControl(!!this.Offer ? this.Offer.LocationId : 0,Validators.required),
        Name: new FormControl(!!this.Offer ? this.Offer.Name : '',Validators.required),
        HardwareIdentifier: new FormControl(!!this.Offer ? this.Offer.HardwareIdentifier : '',Validators.required),

      },
    );
  }
  save() {
    this.formService.markFormGroupTouched(this.kioskForm);
    if (this.kioskForm.invalid) {
      return;
    }
    this.confirm();

  }
  setnewDate(date) {
    if (date.getTime()) {
      const dateObj = {
        year: +(this.datepipe.transform(date, 'yyyy')),
        month: +(this.datepipe.transform(date, 'MM')),
        day: +(this.datepipe.transform(date, 'dd'))
      };
      return new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day']);
    }
    return new Date(date)
  }
  confirm() {
    this.kioskForm.controls.KioskId.setValue(Number(this.kioskForm.controls.KioskId.value));
    const saveMethod = !!this.kioskForm.controls.KioskId.value ?
      this.kiosk.updateKioskRequestOffer(this.kioskForm.value) :
      this.kiosk.addKioskRequestOffer(this.kioskForm.value);
    saveMethod.then(response => {
      if (response['Success']) {
        console.log(response);
        this.closeRequest.emit(true);
        this.toaster.success(this.kioskForm.controls.KioskId.value > 0 ?
         Constants.KIOSK_REQUEST_UPDATE_SUCCESS_MSG : Constants.KIOSK_REQUEST_ADD_SUCCESS_MSG);
      } else {
        this.toaster.error(response['Message']);

      }
    }, err => { });
  }
  initializeMessages() {
    this.messageList.LocationId = {
      required: Constants.VALIDATION_MSG.KIOSK.LOCATION,
    };
    this.messageList.Name = {
      required: Constants.VALIDATION_MSG.KIOSK.KIOSK_NAME,
    };
    this.messageList.HardwareIdentifier = {
      required: Constants.VALIDATION_MSG.KIOSK.HAREWARE_IDENTIFICATION,
    }
  }
  closePopUp() {
    this.closeRequest.emit(true);
  }
  control(controlName: string): AbstractControl {
    return this.kioskForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }
  getLocationList() {
    this.kiosk.getLocation(this.companyId,null).then(res => {
      if (res['Success']) {
        this.locationList = res['Data'];
      } else { this.locationList = []; }
    }, err => { this.locationList = []; });
  }
}
