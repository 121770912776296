import { Injectable } from '@angular/core';
import { API } from '../routes/api-routes';
import { ApiService } from './api.service';
import { Constants } from '../constant/constants';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apiService: ApiService) { }

  getUserList(currentPage) {
    return this.apiService.get(
      `${API.USER_ROUTE.getUser}?page=` +
      currentPage,
      true,
      true,
    );
  }

  addFeedbackRequest(data){
    return this.apiService.post(`${API.FEEDBACK_ROUTES.addFeedbackRequest}`, data, true);
  }

  getUserListByCompanyId(currentPage, companyId,IsActive,pagesize) {
    return this.apiService.get(
      `${API.USER_ROUTE.getUserByCompany}?id=` + companyId
      + `&page=` + currentPage+`&isActive=${IsActive}&PageSize=${pagesize}` ,
      true,
      true,
    );
  }

  addUser(params) {
    return this.apiService.post(`${API.USER_ROUTE.addUser}`, params, true);
  }

  updateUser(params) {
    return this.apiService.put(`${API.USER_ROUTE.updateUser}`, params, true);
  }

  deleteUser(Id) {
    return this.apiService.delete(
      `${API.USER_ROUTE.deleteUser}` + Id + `?deletedBy=` + localStorage.getItem(Constants.USERID),
      true,
    );
  }

  getAllManagerList(companyId, locationId)
  {
    return this.apiService.get(
      `${API.USER_ROUTE.getAllManagerList}?locationId=` + locationId + `&companyId=`+ companyId,
      true,);
  }

  getUserDetail() {
    return this.apiService.get(
      `${API.USER_ROUTE.getUser}/${localStorage.getItem(Constants.USERID)}`,
      true,
      true,
    );
  }

  fileUpload(data: FormData) {
    return this.apiService.postFormDataReqWithToken(
      `${API.USER_ROUTE.uploadFile}`,
      data, true
    );
  }

  dataFilter(data,isActive) { //dataFilter
    return this.apiService.post(`${API.USER_ROUTE.dataFilter}?isActive=${isActive}`, data, true);
  }

  allDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.USER_ROUTE.allDataFilter}`, data, true);
  }

  getUserByUserId(userId) {
    return this.apiService.get(
      `${API.USER_ROUTE.getUserByUser}?id=` + userId
     ,
      true,
      true,
    );
  }

}
