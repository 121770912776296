export class EmployeeRoster{
    employeeRosterId:number;
    employeeId:string;
    username:string;
    companyId:number;
    createdDate:string;
    EmployeeType:number;
    PTOEligible:boolean;
    HolidayPayEligible:boolean;
    OTEligible:boolean;
    Hours:number;
}