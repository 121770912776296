import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Constants } from '../constant/constants';
import { Location } from 'src/app/models/location.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Offer } from '../models/offer.model';
import { UtilityService } from '../service/utility.service';
import { TimeOffService } from '../service/time-off.service';
import { Role } from '../models/role-model';
import { ToastrService } from 'ngx-toastr';
import { TimeOff } from '../models/time-off.model';


@Component({
  selector: 'app-time-off',
  templateUrl: './time-off.component.html',
  styleUrls: ['./time-off.component.scss']
})
export class TimeOffComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  page: any;
  searchSort: any;
  columns = [];
  isShowLink = false;
  timeOff = new TimeOff();
  // timeOff = new Location();
  role: number;
  roleEnum = Role;
  //timeOff: any;
  timeOffList = [];
  totalItems: any;
  modalRef: BsModalRef;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  companyId: number;
  isConfirmed: boolean = false;
  deleteId: string;

  constructor(private modalService: BsModalService, private toaster: ToastrService,
    private utilityService: UtilityService, private timeOffService: TimeOffService) {
    this.columns = [
      "AnnualAllocation",
      "ClassType",
      "ClassTypeStr",
      "MaxAccumulation",
      "Name",
      "SeedAtHire"
    ];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  ngOnInit() {
    this.utilityService.hideLoading();
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = this.role === this.roleEnum.superUser ? 0 : Number(localStorage.getItem(Constants.COMPANYID));
    this.setPage({ offset: 0 });
  }


  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getTimeOffList(this.page.pageNumber + 1);
  }
  getTimeOffList(currentPage) {

    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.isShowLink = true;
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod = this.timeOffService.getTimeOff(currentPage, this.companyId);
      getMethod.then((res: any) => {
        if (res['Success']) {
          this.timeOffList = res.Data.results;
          this.totalItems = res.Data.totalNumberOfRecords === 0 ? undefined : res.Data.totalNumberOfRecords;
        } else {
          this.timeOffList = []; this.totalItems = undefined;
        }
      },
        err => { this.timeOffList = []; });
    }
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }
  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.isShowLink = true;
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.isShowLink = false;
        this.clear()
      }
    }
  }
  filterData() {
    this.timeOffService.timeOffFilter(this.searchSort, this.companyId).then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.timeOffList = res['data'];
        this.totalItems = res['recordsFiltered'] === 0 ? undefined : (res['recordsFiltered']);
      } else { this.timeOffList = []; this.totalItems = undefined; }
    }, err => { this.timeOffList = []; this.totalItems = undefined; });
  }
  clear() {
    this.isShowLink = false;
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.timeOff = data;
    } else {
      this.timeOff = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }
  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }
  onClickDelete(data) {
    this.deleteId = data.TimeOffId;
    this.isConfirmed = true;
  }

  delete(id) {
    this.isConfirmed = false;
    this.timeOffService.deleteTimeOff(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.TIME_OFF_CONFIG_DELETE_MSG);
        this.setPage({ offset: 0 });
      } else { this.toaster.error(res['Message']); }
    }, err => { });
  }
  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }
}
