import { DebounceKeyupDirective } from './debounce-keyup.directive';
import { NgModule } from '@angular/core';
import { MouseMovementDirective } from './mouse-movement.directive';

@NgModule({
  declarations: [
    DebounceKeyupDirective,
    MouseMovementDirective
  ],
  exports: [
    DebounceKeyupDirective,
    MouseMovementDirective
  ]
})
export class DirectiveModule { }
