import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { bsConfig, Constants } from "src/app/constant/constants";
import { OfferStatus } from "src/app/models/role-model";
import { ToastrService } from "ngx-toastr";
import { OfferService } from "src/app/service/offer.service";
import { UtilityService } from "../../../service/utility.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-time-punches-approve-reject",
  templateUrl: "./time-punches-approve-reject.component.html",
  styleUrls: ["./time-punches-approve-reject.component.scss"],
})
export class TimePunchesApproveRejectComponent implements OnInit {
  @ViewChild("filterTextValue", { static: false }) filterTextValue;
  @Output() reloadCount = new EventEmitter<boolean>();
  @Input() requestType: string;
  requestedOffer: any;
  requestedOfferList = [];
  modalRef: BsModalRef;
  isConfirmed = false;
  totalItems: any;
  rowsOnPage = Constants.HR_PAGE_ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  offerId: number;
  confirmMsg = "";
  confirmSaveBtn = "";
  confirmCancelBtn = "";
  offerStatus = OfferStatus;
  isApprove = false;
  isReject = false;
  searchSort: any;
  columns = [];
  bsConfig = bsConfig;
  closedOfferList = [];
  IsCoverageCatch: boolean = false;
  selectedRecords = [];
  selected = [];
  public isHiddenColumn: boolean = false;

  constructor(
    private offerService: OfferService,
    private utilityService: UtilityService
  ) {
    this.columns = [
      "CreatedByObj.companyUsername",
      "OldDateStr",
      "NewDateStr",
      "PunchTypeStr",
      "OldPunchTypeStr",
      "reason",
      "ApprovedByObj.companyUsername",
      "RejectedByObj.companyUsername",
      "offerStatusStr",
    ];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  ngOnInit() {
    this.isConfirmed = false;
    this.IsCoverageCatch =
      localStorage.getItem(Constants.APP_NAME) === "CoverageCatch"
        ? true
        : false;
    this.setPage({ offset: 0 });
  }

  async setPage(pageInfo, isReloadCount = false) {
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
    this.requestType === Constants.REQUESTED_OFFER
      ? await this.getRequestedOfferList(this.page.pageNumber + 1)
      : await this.getClosedOfferList(this.page.pageNumber + 1);

    if (isReloadCount) {
      this.reloadCount.emit();
    }
  }
  clear() {
    this.searchSort.Search.Value = "";
    this.searchSort.Order[0].ColumnName = "";
    this.searchSort.Order[0].Dir = "asc";
    this.filterTextValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }
  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }
  filterData() {
    const filterMethod =
      this.requestType === Constants.REQUESTED_OFFER
        ? this.offerService.hrGetUserPendingRequestsDataFilter(this.searchSort)
        : this.offerService.hrGetUserClosedRequestsDataFilter(this.searchSort);
    filterMethod.then(
      (res) => {
        if (res["data"] && res["data"].length > 0) {
          this.requestType === Constants.REQUESTED_OFFER
            ? (this.requestedOfferList = res["data"])
            : (this.closedOfferList = res["data"]);
          this.totalItems = res["recordsFiltered"];
        } else {
          this.closedOfferList = [];
          this.requestedOfferList = [];
          this.totalItems = 0;
        }
      },
      (err) => {
        this.closedOfferList = [];
        this.requestedOfferList = [];
        this.totalItems = 0;
      }
    );
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clear();
      }
    }
  }

  async getRequestedOfferList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      await this.offerService
        .hrGetUserPendingRequests(currentPage, this.rowsOnPage)
        .then(
          (res: any) => {
            if (JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))) {
              this.utilityService.showLoading();
            }
            if (res["Success"]) {
              this.requestedOfferList = res.Data.results;
              this.totalItems = res.Data.totalNumberOfRecords;
            } else {
              this.requestedOfferList = [];
              this.totalItems = 0;
            }
          },
          (err) => {
            this.requestedOfferList = [];
          }
        );
    }
  }

  async getClosedOfferList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      await this.offerService
        .hrGetUserClosedRequests(currentPage, this.rowsOnPage)
        .then(
          (res: any) => {
            if (JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))) {
              this.utilityService.showLoading();
            }
            if (res["Success"]) {
              this.closedOfferList = res.Data.results;
              this.totalItems = res.Data.totalNumberOfRecords;
            } else {
              this.closedOfferList = [];
              this.totalItems = 0;
            }
          },
          (err) => {
            this.closedOfferList = [];
          }
        );
    }
  }
  async Approved(selected, status) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(selected);
    this.selectedRecords = this.selected.map((x) => x.id);
    await this.offerService
      .approvedDeniedUserRequests(this.selectedRecords, status)
      .then(
        (res) => {
          if (res["Success"]) {
            this.setPage({ offset: 0 },true);
            if (status == 2)
              this.utilityService.showSuccessToast(
                Constants.TIME_PUNCHES_APPROVE_MSG
              );
            else
              this.utilityService.showSuccessToast(
                Constants.TIME_PUNCHES_REJECT_MSG
              );
          } else {
            this.utilityService.showErrorToast(res["Message"]);
          }
        },
        (err) => {}
      );
  }
  downloadFile() {
    const downloadMethod =
      this.requestType !== "approvedOffer"
        ? this.offerService.downloadPendingRequestsExcelFiles(
            this.searchSort
          )
        : this.offerService.hrGetUserClosedRequestsDownload(
            this.searchSort
          );
    downloadMethod.then(
      (response) => {
        if (response["Success"]) {
          const a = document.createElement("a");
          a.setAttribute("style", "display:none;");
          document.body.appendChild(a);
          a.href = environment.BLOB_URL + response["Message"];
          a.download = response["Message"].split("/")[1];
          a.click();
        } else {
          this.utilityService.showErrorToast(response["Message"]);
        }
      },
      (err) => {}
    );
  }
}
