import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MarketingCampaignRoutingModule } from './marketing-campaign-routing.module';

import { SharedModule } from '../shared/shared/shared.module';
import { MarketingCampaignComponent } from './marketing-campaign.component';
import { MarketCampaignService } from '../service/marketCampaign.service';
import { FinePrintComponent } from './fine-print/fine-print.component';

@NgModule({
  declarations: [MarketingCampaignComponent, FinePrintComponent],
  imports: [
    CommonModule,
    MarketingCampaignRoutingModule,
    SharedModule
  ],
  providers:[MarketCampaignService]
})
export class MarketingCampaignModule { }
