import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Offer } from "src/app/models/offer.model";
import {
  FormGroup,
  FormControl,
  AbstractControl,
  Validators,
} from "@angular/forms";
import { Role } from "src/app/models/role-model";
import {
  Constants,
  bsConfig,
  timeList,
  typeField,
  SubscriptionType,
} from "src/app/constant/constants";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FormService } from "src/app/core/form.service";
import { DepartmentService } from "src/app/service/department.service";
import { ToastrService } from "ngx-toastr";
import { OfferService } from "src/app/service/offer.service";
import { ShiftService } from "src/app/service/shift.service";
import { UtilityService } from "src/app/service/utility.service";
import { TermsConditionService } from "src/app/service/terms-condition.service";
import { DatePipe } from "@angular/common";
import { VotRequestService } from "src/app/service/vot-request.service";
import { CallInRequestService } from "src/app/service/callInRequest.service";
import * as moment from "moment";

@Component({
  selector: "app-add-edit-vot-request",
  templateUrl: "./add-edit-vot-request.component.html",
  styleUrls: ["./add-edit-vot-request.component.scss"],
})
export class AddEditVotRequestComponent implements OnInit {
  public getWorkingShiftDetails: any = {};
  public workDate: any;
  public HourLate: any = {};
  public HourEarly: any = {};
  public settingList: any = [];
  public moduleId: any;
  public isCheckboxDisabled: boolean = true;
  public isShiftDetailVot: boolean = false;
  public isDisabledTimeDrp: boolean = false;
  @Input() offer: Offer;
  @Output() closeRequest = new EventEmitter<boolean>();
  public messageList: any = new Offer();
  public offerForm: FormGroup;
  today = new Date();
  tomorrow = new Date(this.today);
  nextMinDate = new Date(this.tomorrow.setDate(this.tomorrow.getDate() + 1));
  currentDate: Date;
  public roleEnum = Role;
  role: number;
  disclaimerMsg = Constants.MANAGER_OFFER_DISCLAIMER;
  disclaimer = Constants.DISCLAIMER;
  hrOfferText = Constants.HR_TERMS_AND_CONDITION_OFFER_TEXT;
  termsCondition = [];
  termsMsg: string = "";
  Editor = ClassicEditor;
  bsConfig = bsConfig;
  departmentList = [];
  shiftList = [];
  companyId: number;
  shiftId: number;
  departmentId: number;
  shiftDetail: any;
  disableDaysForDateToSkip = [];
  disableDaysForDateToWork = [];
  weekDayArray = [];
  timeList = timeList;
  subscriptionType = SubscriptionType;
  disabledDates = ["11-08-2021, 11-05-2021, 11-03-2021"];
  isVOTCutOffHour: boolean = false;
  VOTCutOffHours: string;
  selecteddateStartTime: string;
  appName:string;
  constructor(
    private formService: FormService,
    private departmentService: DepartmentService,
    private toaster: ToastrService,
    private offerService: OfferService,
    private shiftService: ShiftService,
    private utility: UtilityService,
    private termsConditionService: TermsConditionService,
    private datepipe: DatePipe,
    private votRequestService: VotRequestService,
    private callInRequstService: CallInRequestService
  ) {
    this.Editor.defaultConfig = {
      toolbar: {
        items: [],
      },
    };
  }

  ngOnInit() {
    this.appName = localStorage.getItem(Constants.APP_NAME);
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = !!this.offer
      ? this.offer.companyId
      : Number(localStorage.getItem(Constants.COMPANYID));
    this.getTermsCondition();
    this.getDepartmentList();
    this.initializeOfferForm();
    this.initializeMessages();
    this.getSettingByCompanyID();
    if (this.offer) {
      if (this.offer.offerId > 0) {
        this.shiftFilterByDateAndDepartment(
          this.offer.dateToWork,
          Number(this.offer.departmentId)
        );
      }
    }
  }

  async initializeOfferForm() {
    this.offerForm = new FormGroup({
      offerId: new FormControl(!!this.offer ? this.offer.offerId : 0),
      dateToWork: new FormControl(
        !!this.offer ? new Date(this.offer.dateToWork) : null,
        Validators.required
      ),
      status: new FormControl(!!this.offer ? this.offer.status : 1),
      shiftToWork: new FormControl(
        !!this.offer ? this.offer.shiftToWork : "",
        Validators.required
      ),
      companyId: new FormControl(this.companyId),
      departmentId: new FormControl(
        !!this.offer ? this.offer.departmentId : "",
        Validators.required
      ),
      termsMsg: new FormControl(""),
      createdBy: new FormControl(!!this.offer ? this.offer.createdBy : null),
      createdDate: new FormControl(
        !!this.offer ? this.offer.createdDate : null
      ),
      offerType: new FormControl(2),
      timeType: new FormControl(
        !!this.offer ? this.offer.timeType : "",
        Validators.required
      ),
      // isHourEarly: new FormControl(!!this.offer ? this.offer.isHourEarly : false ),
      // isHourLate: new FormControl(!!this.offer ? this.offer.isHourLate : false),
      isHourEarly: new FormControl({
        value: !!this.offer ? this.offer.isHourEarly : false,
        disabled: false,
      }),
      isHourLate: new FormControl({
        value: !!this.offer ? this.offer.isHourLate : false,
        disabled: false,
      }),
    });
    this.shiftId = Number(localStorage.getItem(Constants.SHIFTID));
    this.getshiftDetail();
  }

  initializeMessages() {
    this.messageList.dateToWork = {
      required: Constants.VALIDATION_MSG.OFFER.WORK_ON_DATE_REQUIRED,
    };
    this.messageList.shiftToWork = {
      required:
        this.role === this.roleEnum.hrAdmin
          ? Constants.VALIDATION_MSG.OFFER.WORK_SHIFT_REQUIRED
          : Constants.VALIDATION_MSG.OFFER.WORK_OFFER_SHIFT_REQUIRED,
    };
    this.messageList.departmentId = {
      required: Constants.VALIDATION_MSG.OFFER.DEPARTMENT_REQUIRED,
    };
    this.messageList.timeType = {
      required: Constants.VALIDATION_MSG.OFFER.TIME_TYPE_REQUIRED,
    };
  }

  getshiftDetail() {
    return new Promise((resolve, reject) => {
      this.shiftService.getShiftDetails(this.shiftId).then(
        (res) => {
          if (res["Success"]) {
            this.shiftDetail = res["Data"].shiftWeekDay;
            this.shiftDetail.map((x) => {
              if (x.weekday > 0) {
                this.weekDayArray.push(x.weekday - 1);
              }
            });
            for (let i = 0; i < 7; i++) {
              if (this.weekDayArray.indexOf(i) === -1) {
                this.disableDaysForDateToSkip.push(i);
              } else {
                this.disableDaysForDateToWork.push(i);
              }
            }
            resolve(this.shiftDetail);
          }
        },
        (err) => { }
      );
    });
  }

  getTermsCondition() {
    this.termsConditionService
      .getTermsConditionListByCompanyId(this.companyId, null)
      .then(
        (res) => {
          if (res["Success"]) {
            this.termsCondition = res["Data"];

            this.getTermsMsg(3);
          } else {
            this.termsCondition = [];
          }
        },
        (err) => {
          this.termsCondition = [];
        }
      );
  }

  getTermsMsg(index) {
    const findTerms = this.termsCondition.find(
      (x) => x.typeField === typeField[index].id
    );
    if (!!findTerms) {
      this.termsMsg = findTerms.description;
      this.offerForm.controls.termsMsg.setValue(this.termsMsg);
    }
  }

  getDepartmentList() {
    this.departmentService
      .getDepartmentListByCompanyId(null, this.companyId)
      .then(
        (res) => {
          if (res["Success"]) {
            this.departmentList = res["Data"];
          } else {
            this.departmentList = [];
          }
        },
        (err) => {
          this.departmentList = [];
        }
      );
  }

  getShiftList() {
    this.shiftService.getShiftListByCompanyId(null, this.companyId).then(
      (res) => {
        if (res["Success"]) {
          this.shiftList = res["Data"];
          if (!!this.offer) {
            this.workDateChange(this.offerForm.controls.dateToWork.value);
          }
        } else {
          this.shiftList = [];
        }
      },
      (err) => {
        this.shiftList = [];
      }
    );
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.offerForm);
    if (this.offerForm.invalid) {
      return;
    }
    const mxdate = this.datepipe.transform(
      this.setnewDate(this.offerForm.controls.dateToWork.value),
      "yyyy-MM-dd"
    );
    if (this.isVOTCutOffHour) {
      if (this.checkForCutOffHours(mxdate)) {
        this.utility.showErrorToast("There should be at least " + this.VOTCutOffHours + " Hours different from current time");
        return;
      }
    }

    delete this.offerForm.controls.termsMsg;
    this.offerForm.controls.dateToWork.setValue(mxdate);
    this.offerForm.controls.departmentId.setValue(
      Number(this.offerForm.controls.departmentId.value)
    );
    this.offerForm.controls.shiftToWork.setValue(
      Number(this.offerForm.controls.shiftToWork.value)
    );
    if (this.offerForm.controls.timeType.value) {
      this.offerForm.controls.timeType.setValue(
        Number(this.offerForm.controls.timeType.value)
      );
    }

    const saveMethod =
      this.offerForm.controls.offerId.value > 0
        ? this.votRequestService.updateVotRequestOffer(
          this.offerForm.getRawValue()
        )
        : this.votRequestService.addVotRequestOffer(
          this.offerForm.getRawValue()
        );

    saveMethod.then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(
            this.offerForm.controls.offerId.value > 0
              ? Constants.VOT_REQUEST_UPDATE_MSG
              : Constants.VOT_REQUEST_ADD_MSG
          );
          this.closeRequest.emit(true);
        } else {
          this.utility.showErrorToast(res["Message"]);
          this.offerForm.controls.dateToWork.setValue(new Date(mxdate));
        }
      },
      (err) => { }
    );
  }

  cancel() {
    this.closeRequest.emit(false);
  }

  control(controlName: string): AbstractControl {
    return this.offerForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }

  setnewDate(date) {
    const dateObj = {
      year: +this.datepipe.transform(date, "yyyy"),
      month: +this.datepipe.transform(date, "MM"),
      day: +this.datepipe.transform(date, "dd"),
    };
    return new Date(dateObj["year"], dateObj["month"] - 1, dateObj["day"]);
  }

  workDateChange(event) {
    if (!!event && event != "Invalid Date") {
      const workDate = this.datepipe.transform(
        this.setnewDate(event),
        "yyyy-MM-dd"
      );

      if (this.workDate != undefined) {
        if (this.workDate != workDate) {
          this.getShiftWorkingDetails(workDate, "DATE_CHANGED");
        }
      } else {
        this.getShiftWorkingDetails(workDate, "");
      }

      this.workDate = workDate;
      this.departmentId = !!this.offer
        ? this.offer.departmentId
        : Number(this.offerForm.controls.departmentId.value);
      // this.shiftList = [];

      // if (!!this.offerForm.controls.departmentId.value) {

      //   this.shiftFilterByDateAndDepartment(workDate, this.departmentId);

      // }
    }
  }
  checkForCutOffHours(selectedDate) {
    let _s = selectedDate.split('-');
    let userSelectedDate = new Date(_s[0], parseInt(_s[1]) !== 0 ? parseInt(_s[1]) - 1 : 0, _s[2]);
    let currentDate = new Date();
    let isSameDay = currentDate.toDateString() == userSelectedDate.toDateString();
    let ms: any;
    if (isSameDay)
      ms = moment(currentDate, "DD/MM/YYYY HH:mm:ss").diff(moment(userSelectedDate, "DD/MM/YYYY HH:mm:ss"));
    else
      ms = moment(userSelectedDate, "DD/MM/YYYY HH:mm:ss").diff(moment(currentDate, "DD/MM/YYYY HH:mm:ss"));
    let Difference_In_Hours = (ms / (1000 * 60 * 60)).toFixed(2);
    if (this.selecteddateStartTime != null) {
      let shiftminute: any = (parseFloat(this.selecteddateStartTime.split(":")[1]) / 60)
      shiftminute = shiftminute.toString().split(".")[1] != undefined ? shiftminute.toString().split(".")[1] : "00"
      let timeDifference = this.selecteddateStartTime.split(":")[0] + '.' + shiftminute;
      if (isSameDay &&(parseFloat(timeDifference)) -
      parseFloat(Difference_In_Hours.toString())<=
        parseInt(this.VOTCutOffHours)){
          return true;
        }
        else if (
          parseFloat(Difference_In_Hours.toString()) +
          (parseFloat(timeDifference)) <=
          parseInt(this.VOTCutOffHours)
        )
          return true;
    }
    else {
      if (
        parseFloat(Difference_In_Hours.toString()) <
        parseInt(this.VOTCutOffHours)
      )
        return true;
    }
    return false;
  }
  getShiftWorkingDetails(workDate, data: any) {
    this.offerService.getShiftWorkingDetails(workDate).then(
      (res) => {
        if (res["Success"]) {
          this.getWorkingShiftDetails = res["Data"];
          this.offerForm.controls.departmentId.setValue(
            this.getWorkingShiftDetails.department.departmentId
          );
          this.offerForm.controls.shiftToWork.setValue(
            this.getWorkingShiftDetails.shift.shiftId
          );
          this.shiftFilterByDateAndDepartment(
            workDate,
            this.getWorkingShiftDetails.department.departmentId
          );
          this.offerForm.controls.timeType.setValue(this.timeList[0].id);
          this.offerForm.controls.departmentId.disable();
          this.offerForm.controls.shiftToWork.disable();
          this.offerForm.controls.timeType.disable();
          let splitDate = workDate.split("-")
          this.selecteddateStartTime = res["Data"].shift.shiftWeekDay
            .map((x) => {
              if (x.weekday === new Date(splitDate[0], parseInt(splitDate[1]) !== 0 ? parseInt(splitDate[1]) - 1 : 0, splitDate[2]).getDay() + 1) {
                return x.startTime;
              }
            })
            .filter((x) => !!x)[0];
        } else {
          this.getWorkingShiftDetails = [];
          this.offerForm.controls.departmentId.enable();
          this.offerForm.controls.shiftToWork.enable();
          this.offerForm.controls.timeType.enable();
          this.selecteddateStartTime = null;
          // if(this.offer.offerId ==0 ){
          //   this.offerForm.controls.departmentId.setValue('');
          //   this.offerForm.controls.shiftToWork.setValue('');
          //   this.offerForm.controls.timeType.setValue('');
          // }
          if (data == "DATE_CHANGED") {
            this.offerForm.controls.departmentId.setValue("");
            this.offerForm.controls.shiftToWork.setValue("");
            this.offerForm.controls.timeType.setValue("");
          }
        }
      },
      (err) => {
        this.getWorkingShiftDetails = [];
      }
    );
  }

  shiftFilterByDateAndDepartment(workDate, departmentId) {
    this.shiftService.getShiftListBySkipDate(workDate, departmentId).then(
      (res) => {
        if (res["Success"]) {
          this.shiftList = res["Data"];
        } else {
          this.shiftList = [];
        }
      },
      (err) => {
        this.shiftList = [];
      }
    );
  }

  onDepartmentChange(event) {
    if (!!event.currentTarget) {
      if (!!event.currentTarget.value) {
        this.shiftList = [];

        this.offerForm.controls.shiftToWork.setValue("");
        if (!!this.offerForm.controls.dateToWork.value) {
          const workDate = this.datepipe.transform(
            this.setnewDate(this.offerForm.controls.dateToWork.value),
            "yyyy-MM-dd"
          );
          this.shiftFilterByDateAndDepartment(
            workDate,
            Number(event.currentTarget.value)
          );
        }
      } else {
        this.shiftList = [];
        this.offerForm.controls.shiftToWork.setValue("");
      }
    } else {
      if (!!event) {
        this.shiftList = [];
        if (
          !!this.offer &&
          !!this.offer.dateToWork &&
          !!this.offer.departmentId
        ) {
          const workDate = this.datepipe.transform(
            this.setnewDate(new Date(this.offer.dateToWork)),
            "yyyy-MM-dd"
          );
          this.shiftFilterByDateAndDepartment(workDate, Number(event));
        }
      } else {
        this.shiftList = [];
        this.offerForm.controls.shiftToWork.setValue("");
      }
    }
  }

  onShiftChange(event) {
    if (!!event.currentTarget) {
      if (!!event.currentTarget.value) {
        if (!!this.offerForm.controls.dateToWork.value) {
          const workDate = this.datepipe.transform(
            this.setnewDate(this.offerForm.controls.dateToWork.value),
            "yyyy-MM-dd"
          );
          this.GetShiftDetailsVot(workDate, Number(event.currentTarget.value));
        }
      }
    } else {
      if (!!event) {
        this.shiftList = [];
        if (
          !!this.offer &&
          !!this.offer.dateToWork &&
          !!this.offer.shiftToWork
        ) {
          const workDate = this.datepipe.transform(
            this.setnewDate(new Date(this.offer.dateToWork)),
            "yyyy-MM-dd"
          );
          this.GetShiftDetailsVot(workDate, Number(event));
        }
      }
    }
  }

  GetShiftDetailsVot(workDate, shiftId) {
    this.shiftService.getShiftDetailsVot(workDate, shiftId).then(
      (res) => {
        if (res["Success"]) {
          this.isShiftDetailVot = res["Data"];
          // if(this.isShiftDetailVot){
          //   if((this.HourLate && this.HourEarly) && (this.HourLate.Enable ||  this.HourEarly.Enable)){
          //     this.offerForm.controls.timeType.disable();
          //     this.offerForm.controls.timeType.setValue("");
          //   }
          //   this.offerForm.controls.isHourEarly.enable();
          //   this.offerForm.controls.isHourLate.enable();
          // }
          // else {
          //   this.offerForm.controls.isHourEarly.disable();
          //   this.offerForm.controls.isHourLate.disable();

          //   this.offerForm.controls.isHourEarly.setValue(false);
          //   this.offerForm.controls.isHourLate.setValue(false);
          //   this.offerForm.controls.timeType.enable();
          // }
        }
      },
      (err) => {
        this.offerForm.controls.timeType.enable();
        // this.offerForm.controls.isHourEarly.disable();
        // this.offerForm.controls.isHourLate.disable();
        // this.offerForm.controls.isHourEarly.setValue(false);
        // this.offerForm.controls.isHourLate.setValue(false);
      }
    );
  }

  getSettingByCompanyID() {
    let module = SubscriptionType.filter((item) => {
      return item.value === "VOT Request Module";
    });
    this.moduleId = module[0].id;
    const getMethod = this.callInRequstService.getSettingByCompanyID(
      this.moduleId,
      this.companyId
    );
    getMethod.then(
      (res: any) => {
        if (res["Success"]) {
          this.settingList = res.Data;
          if (this.offerForm.controls.offerId.value > 0) {
            this.GetShiftDetailsVot(
              this.workDate,
              this.offerForm.controls.shiftToWork.value
            );
          }
          if (this.settingList.length > 0) {
            this.settingList.map((item) => {
              if (item.SettingType === "Hour Early") {
                item.Name = "An Hour Early";
                this.HourEarly = item;
              } else if (item.SettingType === "Hour Late") {
                item.Name = "An Hour Late";
                this.HourLate = item;
              } else if (
                item.SettingType === "VOT Cut-Off Hours" &&
                item.Enable === true
              ) {
                this.VOTCutOffHours = item.VOTCutOffHours;
                this.isVOTCutOffHour = true;
              }
            });
          }
        } else {
          this.settingList = [];
        }
      },
      (err) => {
        this.settingList = [];
      }
    );
  }

  checkBoxClick() {
    // this.offerForm.controls.timeType.setValue("");
  }
}
