import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { bsConfig_withMinDate, Constants, Duration } from "src/app/constant/constants";
import { FormService } from "src/app/core/form.service";
import { timeOffRequestModel } from "src/app/models/TimeOffRequest.model";
import { TimeOffService } from "src/app/service/time-off.service";
import * as moment from "moment";
import { ShiftService } from "src/app/service/shift.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-hr-add-time-off-request",
  templateUrl: "./hr-add-time-off-request.component.html",
  styleUrls: ["./hr-add-time-off-request.component.scss"],
})
export class HrAddTimeOffRequestComponent implements OnInit {
  @Input() timeOffRequestModel: timeOffRequestModel;
  @Output() closeRequest = new EventEmitter<boolean>();
  @Output() cancleRequest = new EventEmitter<boolean>();
  durationType = Duration;
  public timeOffRequestForm: FormGroup;
  public messageList: any = new timeOffRequestModel();
  timeOffConfigList = [];
  allNetBalance = "";
  onSubmittingFrom = false;
  isSelectedCurrectShift = true;
  shiftList: any;
  companyId: number;
  selectedValue: any;
  counter = 0;
 // bsConfig = bsConfig_withMinDate;
  today = new Date();
  userName: any;
  constructor(
    private timeOffService: TimeOffService,
    private toaster: ToastrService,
    private formService: FormService,
    private shiftService: ShiftService,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {
    // this.userName = localStorage.getItem(Constants.USERNAME);
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.initializeTimeOffRequestForm();
    this.getTimeOffConfigList();
    this.initializeMessages();
    this.getShiftList();
  }
  close() {
    this.cancleRequest.emit(true);
  }
  initializeTimeOffRequestForm() {
    this.timeOffRequestForm = new FormGroup({
      companyId: new FormControl(
        Number(localStorage.getItem(Constants.COMPANYID))
      ),
      shiftId: new FormControl(Number(localStorage.getItem(Constants.SHIFTID))),
      timeOffUserRequestId: new FormControl(
        !!this.timeOffRequestModel
          ? this.timeOffRequestModel.TimeOffUserRequestId
          : 0
      ),
      TimeOffStartDate: new FormControl(
        !!this.timeOffRequestModel
          ? new Date(this.timeOffRequestModel.TimeOffStartDate)
          : new Date(),
        Validators.required
      ),
      TimeOffEndDate: new FormControl(
        !!this.timeOffRequestModel
          ? new Date(this.timeOffRequestModel.TimeOffEndDate)
          : new Date(),
        Validators.required
      ),
      TimeOffId: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.TimeOffId : "",
        Validators.required
      ),
      Duration: new FormControl(
        !!this.timeOffRequestModel
          ? this.timeOffRequestModel.Duration
          : this.durationType[0].id,
        Validators.required
      ),
      TimeOffDifference: new FormControl(
        !!this.timeOffRequestModel
          ? new Date(this.timeOffRequestModel.TimeOffDifference)
          : null
      ),
      TimeOffStartTime: new FormControl(
        !!this.timeOffRequestModel
          ? new Date(this.timeOffRequestModel.TimeOffStartDate)
          : "",
        Validators.required
      ),
      TimeOffEndTime: new FormControl(
        !!this.timeOffRequestModel
          ? new Date(this.timeOffRequestModel.TimeOffEndDate)
          : "",
        Validators.required
      ),
      createdBy: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.CreatedBy : null
      ),
      CreatedDate: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.CreatedDate : null
      ),
      isActive: new FormControl(true),
      UserId: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.UserId : 0
      ),
      userName: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.UserName : "",
        Validators.required
      ),
      DepartmentId: new FormControl(
        !!this.timeOffRequestModel ? this.timeOffRequestModel.DepartmentId : 0
      ),
    });
    if(!!this.timeOffRequestModel)
      this.setInialDurationLogic();
  }

  initializeMessages() {
    this.messageList.userName = {
      required: Constants.VALIDATION_MSG.TIME_OFF.USERNAME_REQUIRED,
    };
    this.messageList.TimeOffStartDate = {
      required: Constants.VALIDATION_MSG.TIME_OFF_REQUEST.START_TIME,
    };
    this.messageList.TimeOffEndDate = {
      required: Constants.VALIDATION_MSG.TIME_OFF_REQUEST.END_TIME,
    };
    this.messageList.TimeOffDifference = {
      required: Constants.VALIDATION_MSG.TIME_OFF_REQUEST.TOTAL_REQUESTED_HOURS,
    };
    this.messageList.TimeOffId = {
      required: Constants.VALIDATION_MSG.TIME_OFF_REQUEST.TIME_OFF_TYPE,
    };
    this.messageList.Duration = {
      required: Constants.VALIDATION_MSG.TIME_OFF_REQUEST.DURATION,
    };
    this.messageList.TimeOffEndTime = {
      required: Constants.VALIDATION_MSG.TIME_OFF_REQUEST.TIME,
    };
    this.messageList.TimeOffStartTime = {
      required: Constants.VALIDATION_MSG.TIME_OFF_REQUEST.TIME,
    };
  }

  getTimeOffConfigList() {
    this.timeOffService.GetAllConfigForUser().then((response) => {
      if (response["Success"]) {
        this.timeOffConfigList = response["Data"];
        this.timeOffConfigList.forEach((element) => {
          this.allNetBalance += `${element["Name"]}: ${element["NetBalance"]} \n`;
        });
        console.log(this.allNetBalance);
      }
    });
  }

  getShiftList(event = "init") {
    this.shiftService
      .getShiftListByUserId(Number(localStorage.getItem(Constants.SHIFTID))).then(
        (response) => {
          if (response["Success"]) {
            this.shiftList = response["Data"];

            // NEW FORM
            if(!this.timeOffRequestModel)
              this.startEndDateCalc();  
          }
        },
        (err) => {
          this.shiftList = [];
        }
      );
  }
  startEndDateCalc(flag = "init") {
    let currentDate = new Date();

    if(flag == "OnDurationChanged")
      currentDate = new Date(this.timeOffRequestForm.controls.TimeOffStartDate.value);

    let currentWeekDay = currentDate.getDay() + 1;
    let userNextWorkingDays = this.shiftList.shiftWeekDay.find(x => x.weekday === currentWeekDay);
    if(!userNextWorkingDays){
      userNextWorkingDays = this.shiftList.shiftWeekDay.find(x => x.weekday > currentWeekDay)
      if (!userNextWorkingDays) {
        userNextWorkingDays = this.shiftList.shiftWeekDay.find(x => x.weekday <= currentWeekDay)
      }
    }
    let userUpcommingWorkingDay = new Date(currentDate.setDate(currentDate.getDate() + (userNextWorkingDays.weekday + (6 - currentDate.getDay())) % 7));
    let userNextShiftStartTime = userNextWorkingDays.startTime.split(':')
    let userNextShiftEndTime = userNextWorkingDays.endTime.split(':');
    let startTime = new Date((new Date().setHours(userNextShiftStartTime[0]))).setMinutes(userNextShiftStartTime[1]);
    let endTime = new Date((new Date().setHours(userNextShiftEndTime[0]))).setMinutes(userNextShiftEndTime[1]);

    // NEW FORM
    if(!this.timeOffRequestModel && flag == "init"){
      this.timeOffRequestForm.controls.TimeOffStartDate.setValue(new Date(userUpcommingWorkingDay));
      this.timeOffRequestForm.controls.TimeOffEndDate.setValue(new Date(userUpcommingWorkingDay));
    }
    
    this.timeOffRequestForm.controls.TimeOffStartTime.setValue(new Date(startTime));
    this.timeOffRequestForm.controls.TimeOffEndTime.setValue(new Date(endTime));

    if (this.timeOffRequestForm.controls.Duration.value == 1) {
      this.selectedValue=1;
      this.setTimes(userNextWorkingDays.startTime, userNextWorkingDays.endTime);
      this.disabledInputs();
    } else if (this.timeOffRequestForm.controls.Duration.value == 2) {
      this.selectedValue=2;
      this.setTimes(userNextWorkingDays.startTime, userNextWorkingDays.firstHalfEndTime);
      this.disabledInputs();
    } else if (this.timeOffRequestForm.controls.Duration.value == 3) {
      this.selectedValue=3;
      this.setTimes(userNextWorkingDays.secondHalfStartTime, userNextWorkingDays.endTime);
      this.disabledInputs();
    } else if (this.timeOffRequestForm.controls.Duration.value == 4) {
      if(this.selectedValue==2){
        this.setTimes(userNextWorkingDays.startTime, userNextWorkingDays.firstHalfEndTime);
      }
      else if(this.selectedValue==3){
        this.setTimes(userNextWorkingDays.secondHalfStartTime, userNextWorkingDays.endTime);
      }
      else{
        this.setTimes(userNextWorkingDays.startTime, userNextWorkingDays.endTime);
      }
      // this.timeOffRequestForm.controls.TimeOffStartDate.setValue(new Date());
      // this.timeOffRequestForm.controls.TimeOffEndDate.setValue(new Date());
      // this.timeOffRequestForm.controls.TimeOffStartTime.setValue(new Date(startTime));
      // this.timeOffRequestForm.controls.TimeOffEndTime.setValue(new Date(endTime));
      this.enabledInputs();
    }
  }
  onDurationChange(event) {
    this.startEndDateCalc("OnDurationChanged");
  }

  setTimes(startTime, endTime, date = new Date()) {
    if (!!startTime) {
      const startdate = date;
      startdate.setHours(
        Number(startTime.split(":")[0]),
        Number(startTime.split(":")[1])
      );
      this.timeOffRequestForm.controls.TimeOffStartTime.setValue(startdate);
    } else {
      this.toaster.error(Constants.SHIFT_START_TIME_CONFIGURE);
      this.timeOffRequestForm.controls.TimeOffStartTime.setValue(null);
    }
    if (!!endTime) {
      const enddate = new Date();
      enddate.setHours(
        Number(endTime.split(":")[0]),
        Number(endTime.split(":")[1])
      );
      this.timeOffRequestForm.controls.TimeOffEndTime.setValue(enddate);
    } else {
      this.toaster.error(Constants.SHIFT_END_TIME_CONFIGURE);
      this.timeOffRequestForm.controls.TimeOffEndTime.setValue(null);
    }
  }
  enabledInputs() {
    this.timeOffRequestForm.controls.TimeOffStartDate.enable();
    this.timeOffRequestForm.controls.TimeOffEndDate.enable();
    this.timeOffRequestForm.controls.TimeOffStartTime.enable();
    this.timeOffRequestForm.controls.TimeOffEndTime.enable();
  }

  disabledInputs() {
    this.timeOffRequestForm.controls.TimeOffEndDate.disable();
    this.timeOffRequestForm.controls.TimeOffStartTime.disable();
    this.timeOffRequestForm.controls.TimeOffEndTime.disable();
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.timeOffRequestForm);
    if (this.timeOffRequestForm.invalid) {
      return;
    }

    // if (!this.isSelectedCurrectShift) {
    //   this.toaster.error(Constants.NO_AVAILABLE_SHIFT);
    //   return;
    // }

    this.onSubmittingFrom = true;

    let startDate = new Date(
      this.timeOffRequestForm.controls.TimeOffStartDate.value
    );
    let startDateFromTime = new Date(
      this.timeOffRequestForm.controls.TimeOffStartTime.value
    );
    let endDate = new Date(
      this.timeOffRequestForm.controls.TimeOffEndDate.value
    );
    let endDateFromTime = new Date(
      this.timeOffRequestForm.controls.TimeOffEndTime.value
    );

    this.timeOffRequestForm.controls.TimeOffStartDate.setValue(
      moment(
        new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          startDateFromTime.getHours(),
          startDateFromTime.getMinutes(),
          0,
          0
        )
      ).format("YYYY-MM-DD HH:mm")
    );

    this.timeOffRequestForm.controls.TimeOffEndDate.setValue(
      moment(
        new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          endDateFromTime.getHours(),
          endDateFromTime.getMinutes(),
          0,
          0
        )
      ).format("YYYY-MM-DD HH:mm")
    );

    this.enabledInputs();
    const saveMethod =
    this.timeOffRequestForm.controls.timeOffUserRequestId.value > 0
      ? this.timeOffService.updateTimeOffUserRequestHR(
        this.timeOffRequestForm.value
      )
      : this.timeOffService.addTimeOffUserRequestHR(this.timeOffRequestForm.value);
  saveMethod.then(
    (res) => {
      this.onSubmittingFrom = false;
      this.disabledInputs();

      if (res["Success"]) {
        this.toaster.success(
          this.timeOffRequestForm.controls.timeOffUserRequestId.value > 0
            ? Constants.TIME_OFF_REQ_EDIT_MSG
            : Constants.TIME_OFF_REQ_ADD_MSG
        );
        this.closeRequest.emit(true);
      } else {
        this.toaster.error(res["Message"]);
        let tempEndDate =
          this.timeOffRequestForm.controls.TimeOffEndDate.value;
        this.timeOffRequestForm.controls.TimeOffStartDate.setValue(
          new Date(this.timeOffRequestForm.controls.TimeOffStartDate.value)
        );
        this.timeOffRequestForm.controls.TimeOffEndDate.setValue(
          new Date(tempEndDate)
        );
      }
    },
    (err) => {
      this.onSubmittingFrom = false;
      this.disabledInputs();
      this.closeRequest.emit(false);
    }
  );
    // const saveMethod = this.timeOffService.addTimeOffUserRequestHR(
    //   this.timeOffRequestForm.value
    // );
    // saveMethod.then(
    //   (res) => {
    //     this.onSubmittingFrom = false;
    //     this.disabledInputs();

    //     if (res["Success"]) {
    //       this.toaster.success(Constants.TIME_OFF_REQ_ADD_MSG);
    //       this.closeRequest.emit(true);
    //     } else {
    //       this.toaster.error(res["Message"]);
    //       let tempEndDate =
    //         this.timeOffRequestForm.controls.TimeOffEndDate.value;
    //       this.timeOffRequestForm.controls.TimeOffStartDate.setValue(
    //         new Date(this.timeOffRequestForm.controls.TimeOffStartDate.value)
    //       );
    //       this.timeOffRequestForm.controls.TimeOffEndDate.setValue(
    //         new Date(tempEndDate)
    //       );
    //     }
    //   },
    //   (err) => {
    //     this.onSubmittingFrom = false;
    //     this.disabledInputs();
    //     this.closeRequest.emit(false);
    //   }
    // );
  }

  setnewDate(date) {
    if (date.getTime()) {
      const dateObj = {
        year: +(this.datepipe.transform(date, 'yyyy')),
        month: +(this.datepipe.transform(date, 'MM')),
        day: +(this.datepipe.transform(date, 'dd'))
      };
      return new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day']);
    }
    return new Date(date)
  }

  startDateChangeValidation(event) {

    if (this.onSubmittingFrom) return;

    if (this.timeOffRequestForm.controls.Duration.value == 4
      || this.onSubmittingFrom)
      return;

    let currentWeekDay = new Date().getDay() + 1;
    console.log(currentWeekDay);
    if (!!this.shiftList) {

      this.isSelectedCurrectShift = false;

      for (let index = 0; index < this.shiftList.shiftWeekDay.length; index++) {
        if (this.shiftList.shiftWeekDay[index].weekday == new Date(event).getDay() + 1) {
          let shiftWeek = this.shiftList.shiftWeekDay[index];
          this.timeOffRequestForm.controls.TimeOffStartDate.setValue(new Date(event));
          this.timeOffRequestForm.controls.TimeOffEndDate.setValue(new Date(event));

          if (this.timeOffRequestForm.controls.Duration.value == 1) {
            this.setTimes(shiftWeek.startTime, shiftWeek.endTime, event);
          }
          if (this.timeOffRequestForm.controls.Duration.value == 2) {
            this.setTimes(shiftWeek.startTime, shiftWeek.firstHalfEndTime);
          }
          if (this.timeOffRequestForm.controls.Duration.value == 3) {
            this.setTimes(shiftWeek.secondHalfStartTime, shiftWeek.endTime);
          }
          this.isSelectedCurrectShift = true;
          break;
        }

      }
      if (!this.isSelectedCurrectShift) {
        this.toaster.error(Constants.NO_AVAILABLE_SHIFT);
      }
    }

    if (
      this.timeOffRequestForm.controls.timeOffUserRequestId.value > 0 &&
      this.counter <= 1
    ) {
      this.counter++;
      return;
    }
  }

  setInialDurationLogic(){
    if (this.timeOffRequestForm.controls.Duration.value == 1) {
      this.disabledInputs();
    } else if (this.timeOffRequestForm.controls.Duration.value == 2) {
      this.disabledInputs();
    } else if (this.timeOffRequestForm.controls.Duration.value == 3) {
      this.disabledInputs();
    } else if (this.timeOffRequestForm.controls.Duration.value == 4) {
      this.enabledInputs();
    }
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }
  control(controlName: string): AbstractControl {
    return this.timeOffRequestForm.get(controlName);
  }
}
