import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Constants } from "../constant/constants";
import { Role } from "../models/role-model";
import { UtilityService } from "./../service/utility.service";
import { DepartmentMappingService } from './../service/departmentMapping.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-department-mapping",
  templateUrl: "./department-mapping.component.html",
  styleUrls: ["./department-mapping.component.scss"],
})
export class DepartmentMappingComponent implements OnInit {
  @ViewChild("filterTextValue", { static: false }) filterTextValue;

  departmentMappingList=[];
  role: number;
  roleEnum = Role;
  selected = [];
  selectedRecords = [];
  allRowsSelected = [];
  page: any;
  filterValue = [];
  searchSort: any;
  totalItems: any;
  columns = [];
  departmentMapping: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  modalRef: BsModalRef;
  isConfirmed: boolean = false;
  deleteId: string;

  constructor(
    private modalService: BsModalService,
    private utilityService: UtilityService,
    private departmentMappingService : DepartmentMappingService,
    private toaster:ToastrService
  ) {
    this.columns=["functions.functionName","department.departmentName","subDepartment.subDepartmentName","displaymodules"]
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  ngOnInit() {
    this.utilityService.hideLoading();

    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getdepartmentMappingList(this.page.pageNumber + 1);
  }

  getdepartmentMappingList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      this.departmentMappingService.getDepartmentMappingList(currentPage).then(
        (res: any) => {
          if (res["Success"]) {
            this.departmentMappingList = res.Data.results;
            this.totalItems = res.Data.totalNumberOfRecords;
          } else {
            this.departmentMappingList = [];
            this.totalItems = 0;
          }
        },
        (err) => {
          this.departmentMappingList = [];
        }
      );
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clear();
      }
    }
  }

  filterData() {
    this.departmentMappingService.dataFilter(this.searchSort).then(
      (res) => {
        if (res["data"] && res["data"].length > 0) {
          this.departmentMappingList = res["data"];
          this.totalItems = res["recordsFiltered"];
        } else {
          this.departmentMappingList = [];
          this.totalItems = 0;
        }
      },
      (err) => {
        this.departmentMappingList = [];
        this.totalItems = 0;
      }
    );
  }

  closeModal(e) {
    if (e) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }
 
  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.departmentMapping = data;
    } else {
      this.departmentMapping = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }
  clear() {
    this.searchSort.Search.Value = "";
    this.searchSort.Order[0].ColumnName = "";
    this.searchSort.Order[0].Dir = "asc";
    this.filterTextValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }
  onClickDelete(data) {
    this.deleteId = data.departmentMappingId;
    this.isConfirmed = true;
  }

  delete(id) {
    this.isConfirmed = false;
    this.departmentMappingService.deleteDepartmentMapping(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.DEPARTMENT_MAPPING_DELETE_SUCESS_MSG);
        this.setPage({ offset: 0 });
      } else { this.toaster.error(res['Message']); }
    }, err => { });
  }
  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }
  close(event) {
    this.isConfirmed = false;
  }
}
