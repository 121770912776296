import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AuthService } from "./core/auth/auth.service";
import { HttpClient } from "@angular/common/http";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild("startTimeConfirmPopup", { static: false })
  startTimeConfirmPopup: TemplateRef<any>;

  constructor(
    private titleService: Title,
    private authService: AuthService,
    private http: HttpClient,
    private modalService: BsModalService
  ) {}

  async ngOnInit() {
  }
  
  reload() {
    window.location.reload();
  }
  onActivate(event) {
    window.scroll({
      top: 0,
      left: 0,
    });
  }
}
