import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { TimeOffRoutingModule } from './time-off-routing.module';
import { TimeOffComponent } from './time-off.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddEditComponent } from './add-edit/add-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared/shared.module';
import { DirectiveModule } from '../shared/shared/directive/directive.module';
import { UserService } from '../service/user.service';
import { LocationService } from '../service/location.service';
import { CompanyService } from '../service/company.service';
import { ShiftService } from '../service/shift.service';
import { DepartmentService } from '../service/department.service';
import { OfferService } from '../service/offer.service';
import { AuthService } from '../core/auth/auth.service';


@NgModule({
  declarations: [TimeOffComponent, AddEditComponent],
  imports: [
    CommonModule,
    TimeOffRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    [NgxDatatableModule] ,
    NgxBootstrapIconsModule.pick(allIcons),
DirectiveModule
  ],
  providers: [UserService, CompanyService, DepartmentService, ShiftService, LocationService, OfferService, AuthService]
})
export class TimeOffModule { }
