import { ChangeDetectorRef, Component, NgZone, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Constants, drpSettingLocation } from "src/app/constant/constants";
import { Role } from "src/app/models/role-model";
import { LocationService } from "src/app/service/location.service";
import { TrainingService } from "src/app/service/training.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-activity-log",
  templateUrl: "./activity-log.component.html",
  styleUrls: ["./activity-log.component.scss"],
})
export class ActivityLogComponent implements OnInit {
  @ViewChild("filterTextValue", { static: false }) filterTextValue;

  activityLogList = [];
  role: number;
  roleEnum = Role;
  selected = [];
  selectedRecords = [];
  allRowsSelected = [];
  page: any;
  filterValue = [];
  searchSort: any;
  totalItems: any;
  columns = [];
  rowsOnPage = Constants.HR_PAGE_ROWS_ON_PAGE;
  modalRef: any;
  moduleTraining: any;
  deleteId: any;
  isConfirmed: boolean;
  isActiveUser: boolean = false;
  locationList=[];
  companyId: number;
  dropdownSettings = drpSettingLocation.dropdownSettings;
  SelectedLocation=[];
  Default_location: number;
  ActivityTrainingListForSearch=[];
  constructor(
    private modalService: BsModalService,
    private trainingService: TrainingService,
    private toaster: ToastrService,
    private locationService: LocationService,
    private zone:NgZone,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.columns = [
      "userName",
      "moduleName.moduleName",
      "functionName.functionName",
      "trainerName",
      "statusStr",
      "createdDateStr",
      "modifiedDateStr",
      "recordedBy.companyUsername",
      "action",
      "trainingDateStr",
    ];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  ngOnInit() {
    this.zone.run(()=>{
      this.setPage({ offset: 0 });
      this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
      this.Default_location = Number(localStorage.getItem(Constants.LOCATIONID));
      this.getLocationList();
    })

  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getModuleConfigList(this.page.pageNumber + 1);
  }

  getModuleConfigList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      this.trainingService.getActivityDatList(currentPage,this.rowsOnPage,this.isActiveUser).then(
        (res: any) => {
          if (res["Success"]) {
            var activityLogList = res.Data.results;
            this.totalItems = res.Data.totalNumberOfRecords;
            this.ActivityTrainingListForSearch = activityLogList;
            setTimeout(() => {
              this.FilterLocationData();
            }, 500);
          } else {
            this.activityLogList = [];
            this.totalItems = 0;
          }
        },
        (err) => {
          this.activityLogList = [];
        }
      );
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clear();
      }
    }
  }

  filterData() {
    this.trainingService.activityDataFilter(this.searchSort,this.isActiveUser).then(
      (res) => {
        if (res["data"] && res["data"].length > 0) {
          var activityLogList = res["data"];
          this.totalItems = res["recordsFiltered"];
          this.ActivityTrainingListForSearch = activityLogList;
          setTimeout(() => {
            this.FilterLocationData();
          }, 500);
        } else {
          this.activityLogList = [];
          this.totalItems = 0;
        }
      },
      (err) => {
        this.activityLogList = [];
        this.totalItems = 0;
      }
    );
  }

  closeModal(e) {
    if (e) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }

  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.moduleTraining = data;
    } else {
      this.moduleTraining = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }
  clear() {
    this.searchSort.Search.Value = "";
    this.searchSort.Order[0].ColumnName = "";
    this.searchSort.Order[0].Dir = "asc";
    this.filterTextValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  downloadFile() {
    const downloadMethod = this.trainingService.downloadActivityExcel(
      this.searchSort,this.isActiveUser
    );
    downloadMethod.then(
      (response) => {
        if (response["Success"]) {
          const a = document.createElement("a");
          a.setAttribute("style", "display:none;");
          document.body.appendChild(a);
          a.href = environment.BLOB_URL + response["Message"];
          a.download = response["Message"].split("/")[1];
          a.click();
        } else {
          this.toaster.error(response["Message"]);
        }
      },
      (err) => {}
    );
  }
  displayActive(event) {
    if (event.target.checked) {
      this.isActiveUser = true;
      this.getModuleConfigList(this.page.pageNumber + 1);
    } else if (!event.target.checked) {
      this.isActiveUser = false;
      this.getModuleConfigList(this.page.pageNumber + 1);
    }
  }
  getLocationList() {
    this.locationService.getLocationListByCompany(this.companyId, null).then(res => {
      if (res['Success']) {
        var locationLists = res['Data'];
        const index = locationLists.findIndex(item => item.locationId === this.Default_location);
        var location = locationLists[index];
        var obj = {
          locationId:location.locationId,
          locationName:location.locationName
        };
        this.SelectedLocation.push(obj);
        this.locationList = locationLists;
      } else { this.locationList = []; }
    }, err => { this.locationList = []; });
  }
  onRoleSelect(item: any) {
    this.SelectedLocation.push(item);
    //this.SelectedLocation = this.getUniqueListBy(this.SelectedLocation,"locationId")
    this.FilterLocationData();
  }
  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}
  onSelectAllRole(items: any) {
    this.SelectedLocation = items;
    this.FilterLocationData();
  }

  onRoleDeSelect(items: any) {
    this.SelectedLocation = this.SelectedLocation.filter(x =>x.locationId !== items.locationId);
    this.FilterLocationData();
  }

  onDeSelectAllRole() {
    this.SelectedLocation = [];
    this.activityLogList = this.ActivityTrainingListForSearch;
  }
  FilterLocationData(){
    var searchedData = [];
    this.SelectedLocation.forEach(element => {
      let moduleList =  this.ActivityTrainingListForSearch.filter(data=> Number(data.recordedBy.locationId) === Number(element.locationId));
      if(moduleList.length > 0){
        moduleList.forEach(ele => {
          searchedData.push(ele);
        });
     
      }
    });
    setTimeout(() => {
      this.activityLogList = searchedData;
      this.changeDetectorRef.markForCheck()
      this.changeDetectorRef.detectChanges();
    }, 500);
  }
}
