import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { VotRequestService } from 'src/app/service/vot-request.service';
import { UtilityService } from 'src/app/service/utility.service';
import { Constants } from 'src/app/constant/constants';

@Component({
  selector: 'app-vot-approve-reject-confirmation',
  templateUrl: './vot-approve-reject-confirmation.component.html',
  styleUrls: ['./vot-approve-reject-confirmation.component.scss']
})
export class VotApproveRejectConfirmationComponent implements OnInit {
  @Input() type: string;
  @Input() selectedRecords: string[];
  @Output() close = new EventEmitter<boolean>();
  requestData: any;
  IsCoverageCatch: boolean = false;
  constructor(
    private votRequestService: VotRequestService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.IsCoverageCatch = localStorage.getItem(Constants.APP_NAME) === "CoverageCatch" ? true:false;
    this.getVotRequestHours();
  }

  getVotRequestHours() {
    this.votRequestService.getSelectedVotHours(this.selectedRecords)
      .then(response => {
        if (response['Success']) {
          this.requestData = response['Data']
        }
      }, err => { });
  }

  cancel() {
    this.close.emit(false);
  }

  approveRejectVot() {
    const postMethod = this.type === 'approved' ? this.votRequestService.approveVotOffer(this.selectedRecords)
      : this.votRequestService.rejectVotOffer(this.selectedRecords);
    postMethod.then(response => {
      if (response['Success']) {
        this.type === 'approved' ? this.utilityService.showSuccessToast(Constants.VOT_REQUEST_APPROVE_MSG)
          : this.utilityService.showSuccessToast(Constants.VOT_REQUEST_DENIED_MSG);
        this.close.emit(true);
      } else {
        this.utilityService.showErrorToast(response['Message']);
      }
    }, err => { });
  }
}
