import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../constant/constants';
import { Role } from '../models/role-model';
import { CallInRequestService } from './../service/callInRequest.service';
import { Reasons } from './../models/Reasons.model';

@Component({
  selector: 'app-call-off-reason',
  templateUrl: './call-off-reason.component.html',
  styleUrls: ['./call-off-reason.component.scss']
})
export class CallOffReasonComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  CallOffReasons = new Reasons();
  reasonList = [];
  modalRef: BsModalRef;
  totalItems: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  companyId: number;
  isConfirmed: boolean = false;
  deleteId: string;
  role: number;
  roleEnum = Role;
  searchSort: any;
  columns = [];
  isShowLink = false;

  constructor(private modalService: BsModalService,
    private toaster: ToastrService,
    private CallInRequestService: CallInRequestService) {
    this.columns = ['name', 'reasonType','reasonTypeStr'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = this.role === this.roleEnum.superUser ? 0 : Number(localStorage.getItem(Constants.COMPANYID));
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getCallOffReasonList(this.page.pageNumber + 1);
  }

  getCallOffReasonList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.isShowLink = true;
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod = this.CallInRequestService.getReasons(currentPage, this.companyId);
      getMethod.then((res: any) => {
        if (res['Success']) {
          this.reasonList = res.Data.results;
          this.reasonList = this.reasonList.filter(x => x.reasonId != 1);
          this.totalItems = res.Data.totalNumberOfRecords;
        } else { this.reasonList = []; this.totalItems = 0; }
      }, err => { this.reasonList = []; });
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.isShowLink = true;
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.isShowLink = false;
        this.clear()
      }
    }
  }

  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.CallOffReasons = data;
    } else {
      this.CallOffReasons = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-md',
      backdrop: 'static'
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }

  onClickDelete(data) {
    this.deleteId = data.reasonId;
    this.isConfirmed = true;
  }

  delete(id) {
    this.isConfirmed = false;
    this.CallInRequestService.deleteCallOffReason(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.CALL_OFF_REASON_DELETE_MSG);
        this.setPage({ offset: 0 });
      } else { this.toaster.error(res['Message']); }
    }, err => { });
  }

  close(event) {
    this.isConfirmed = false;
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  clear() {
    this.isShowLink = false;
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  filterData() {
    this.CallInRequestService.callOffReasonFilter(this.searchSort).then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.reasonList = res['data'];
        this.reasonList = this.reasonList.filter(x => x.reasonId != 1);
        this.totalItems = res['recordsFiltered'] === 0 ? 0: (res['recordsFiltered'] -1);
      } else { this.reasonList = []; this.totalItems = 0; }
    }, err => { this.reasonList = []; this.totalItems = 0; });
  }

  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }
}

