import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Menu } from './menu.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Role } from 'src/app/models/role-model';
import { Constants } from 'src/app/constant/constants';
import { Title } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Offer } from "src/app/models/offer.model";
import { UtilityService } from 'src/app/service/utility.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isCollapsed = true;
  isUserCollapsed = true;
  role: number;
  public roleEnum = Role;
  menu = [];
  userName: string = '';
  //logoUrl = '';
  appName = 'Cover my work';
  isShowTraning = false;
  isShowTimeOff = false;
  isHideHeader : boolean; 
  isShowClockInOut = false;
  companyId:number;
  modalRef: BsModalRef;
  public offerModal: Offer;
  constructor(private router: Router, private authService: AuthService, private titleService: Title,
     private activeRoute: ActivatedRoute,
     private modalService: BsModalService,
     private utilityService: UtilityService) {
    //this.logoUrl = localStorage.getItem(Constants.LOGO_PATH);
    this.activeRoute.url.subscribe(activeUrl =>{
      let currentRouterLink = window.location.pathname;
      if(currentRouterLink === '/layout/launch-kiosk') {
        this.isHideHeader = true;
      }
      else {
        this.isHideHeader = false;
      }
    });
  }

  ngOnInit() {
    this.userName = localStorage.getItem(Constants.USERNAME);
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.menu = Menu[this.authService.role] || [];
    this.isShowTraning = JSON.parse(localStorage.getItem(Constants.IS_TRAINING));
    this.isShowTimeOff = JSON.parse(localStorage.getItem(Constants.IS_TIME_OFF));
    this.isShowClockInOut=JSON.parse(localStorage.getItem(Constants.IS_CLOCKINOUT));
  }

  logout() {
    let _companyId = localStorage.getItem(Constants.COMPANYID);
    let _userName = localStorage.getItem(Constants.USERNAME);
    let commonToken = localStorage.getItem(Constants.COMMONTOKEN);
    let userRoleId = localStorage.getItem("roleId");
    let identifierUser = localStorage.getItem("identifierUser");
    let roleId = localStorage.getItem(Constants.ROLE);
    let companyName = localStorage.getItem(Constants.COMPANY_NAME);
    let returningData = localStorage.getItem(Constants.RETURNING_COMPANYID);
    localStorage.clear();
    if (window.location.hostname.split('.').length > 2 && window.location.hostname.split('.')[0] !== 'www') {
      sessionStorage.clear();
    }
    this.getConfiguration();
    // localStorage.setItem(Constants.COMPANYID,_companyId);
    localStorage.setItem(Constants.COMMONTOKEN,commonToken);
    localStorage.setItem(Constants.USERNAME,_userName );
    localStorage.setItem("roleId",userRoleId);
    localStorage.setItem("identifierUser",identifierUser);
    localStorage.setItem(Constants.ROLE, roleId);
    localStorage.setItem(Constants.COMPANY_NAME, companyName);
    localStorage.setItem(Constants.RETURNING_COMPANYID, returningData);
  }

  feebackBtn(){
    console.log("test");
  }

  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.offerModal = data;
      console.log(this.offerModal, "data");
    } else {
      this.offerModal = null;
    }

    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }

  closeModal(event) {
    this.modalRef.hide();
  }

  async getConfiguration() {
    this.utilityService.showLoading();
    await this.authService.getConfiguration().then(response => {
      if (response['Success'] && !!response['Data']) {
        localStorage.setItem(Constants.LOGO_PATH, !!response['Data'].LogoURL ? `../../assets/images/${response['Data'].LogoURL}` : '');
        localStorage.setItem(Constants.LOGIN_URL, !!response['Data'].LoginURL ? `../../assets/images/${response['Data'].LoginURL}` : '');
        localStorage.setItem(Constants.WEB_URL, response['Data'].WebURL);
        localStorage.setItem(Constants.APP_NAME, response['Data'].AppName);
        this.appName = localStorage.getItem(Constants.APP_NAME);
        this.titleService.setTitle(this.appName);
        if (window.location.hostname.split('.').length > 2 && window.location.hostname.split('.')[0] !== 'www') {
          this.router.navigate(['']);
        } else {
          this.router.navigate([''], { queryParams: { company: sessionStorage.getItem(Constants.COMPANY_NAME) } });
        }
      }
    }, err => { this.utilityService.hideLoading(); });
    this.utilityService.hideLoading();
  }
} 
