import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from '../constant/constants';

@Injectable()
export class ShiftService {

  constructor(private apiService: ApiService) { }

  getShiftList(currentPage) {
    return this.apiService.get(
      `${API.SHIFT_ROUTES.shiftNew}?page=` +
      currentPage,
      false,
      true,
    );
  }

  getShiftDetails(id) {
    return this.apiService.get(
      `${API.SHIFT_ROUTES.shiftNewById}?id=${id}`,
      true,
      true,
    );
  }

  getShiftListByCompanyId(currentPage, companyId,isStopLoader = true,isShowLoader = true) {
    return this.apiService.get(
      `${API.SHIFT_ROUTES.getShiftByCompany}?companyId=` + companyId
      + `&page=` + currentPage,
      false,
      isStopLoader,
      isShowLoader
    );
  }

  getShiftListByUserId(shiftId) {
    // return this.apiService.get(
    //   `${API.SHIFT_ROUTES.getShiftByUser}?id=` + userId
    //  ,
    //   true,
    //   true,
    // );
    return this.apiService.get(
      `${API.SHIFT_ROUTES.getShiftByUser}?id=${shiftId}`,
      true,
      true,
    );
  }

  addShift(params) {
    return this.apiService.post(`${API.SHIFT_ROUTES.saveShift}`, params, true);
  }

  updateShift(params) {
    return this.apiService.put(`${API.SHIFT_ROUTES.saveShift}`, params, true);
  }

  deleteShift(Id) {
    return this.apiService.delete(
      `${API.SHIFT_ROUTES.deleteShift}` + Id + `?deletedBy=` + localStorage.getItem(Constants.USERID),
      true,
    );
  }

  getShiftListByWorkDate(skipDate, workDate, departmentId) {
    return this.apiService.get(
      `${API.SHIFT_ROUTES.getShiftByDate}?dateToSkip=${skipDate}&dateToWork=${workDate}&department=${departmentId}`,
      true,
      true,
    );
  }

  getShiftListBySkipDate(skipDate, departmentId) {
    return this.apiService.get(
      `${API.SHIFT_ROUTES.getSkipShiftByDate}?dateToSkip=${skipDate}&department=${departmentId}`,
      true,
      true,
    );
  }

  dataFilter(data) { //dataFilter
    return this.apiService.post(`${API.SHIFT_ROUTES.dataFilter}`, data, true);
  }

  getShiftListByDepartment(departmentId) {
    return this.apiService.get(
      `${API.SHIFT_ROUTES.getShiftByDepartment}?department=` + departmentId,
      true,
      true,
    );
  }

  getTimeByShiftDateTime(date, shift, time) {
    return this.apiService.get(
      `${API.SHIFT_ROUTES.getTimeByShiftDateTime}?shiftId=${shift}&date=${date}&timeId=${time}`,
      true,
      true,
    );
  }

  getStartEndTimeByShift(shift){
    return this.apiService.get(
      `${API.SHIFT_ROUTES.getStartEndTimeByShift}?shiftId=${shift}`,
      true,
      true,
    );
  }

  getShiftDetailsVot(date, shiftId) {
    return this.apiService.get(
      `${API.SHIFT_ROUTES.getShiftDetailsVot}?date=${date}&shiftId=${shiftId}`,
      true,
      true,
    );
  }



  
}
