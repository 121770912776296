import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from '../constant/constants';

@Injectable()
export class TermsConditionService {

  constructor(private apiService: ApiService) { }

  getTermsConditionList(currentPage) {
    return this.apiService.get(
      `${API.TERMS_CONDITION_ROUTES.termsConditionNew}?page=` +
      currentPage,
      false,
      true,
    );
  }

  getTermsConditionListByCompanyId(companyId, currentPage,isStopLoader=true) {
    return this.apiService.get(
      `${API.TERMS_CONDITION_ROUTES.getTermsConditionByCompany}?companyid=${companyId}&page=${currentPage}`,
      true,
      isStopLoader,
    );
  }
  // saveTermsCondition(params) {
  //   const path = params.locationId > 0
  //     ? `${API.TERMS_CONDITION_ROUTES.saveTermsCondition}/` + params.termsConditionId
  //     : `${API.TERMS_CONDITION_ROUTES.saveTermsCondition}`;
  //   return this.apiService.post(path, params, true);
  // }

  saveTermsCondition(params) {
    return this.apiService.post(`${API.TERMS_CONDITION_ROUTES.saveTermsCondition}`, params, true);
  }

  updateTermsCondition(params) {
    return this.apiService.put(`${API.TERMS_CONDITION_ROUTES.saveTermsCondition}`, params, true);
  }

  deleteTermsCondition(Id) {
    return this.apiService.delete(
      `${API.TERMS_CONDITION_ROUTES.deleteTermsCondition}` + Id + `?deletedBy=` + localStorage.getItem(Constants.USERID),
      true,
    );
  }

  dataFilter(data) { //dataFilter
    return this.apiService.post(`${API.TERMS_CONDITION_ROUTES.dataFilter}`, data, true);
  }

  getTermsCondition(type) {
    return this.apiService.get(
      `${API.TERMS_CONDITION_ROUTES.getTerms}?TermsType=${type}`,
      true,
      true
    );
  }
}
