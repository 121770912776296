import { Injectable } from '@angular/core';
import { API } from 'src/app/routes/api-routes';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(public apiService: ApiService) {}

  getNotifications(userId,currentPage){
    return this.apiService.get(
      `${API.NOTIFICATION.get}?userId=` +
        userId+
        `&page=` +
        currentPage,
      true,
      true
    );
  }

  getNotificationCount(userId){
    return this.apiService.get(
      `${API.NOTIFICATION.getNotificationCount}?userId=` +
        userId,
      true,
      true
    );
  }
  SendNotification(userId,Subject,Message,IsEmail,templateName,isNotification){
    var url = `${API.NOTIFICATION.sendNotification}?userId=${userId}&Subject=${Subject}&Message=${Message}&IsEmail=${IsEmail}&templateName=${templateName}&isNotification=${isNotification}`;
    return this.apiService.get(url,true,true);
  }
}