import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { KioskRoutingModule } from './kiosk-routing.module';
import { KioskComponent } from './kiosk.component';
import { AddEditKioskComponent } from './add-edit-kiosk/add-edit-kiosk.component';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { OfferModule } from '../offer/offer.module';
import { DepartmentService } from '../service/department.service';
import { KioskService } from '../service/kiosk.service';
import { OfferService } from '../service/offer.service';
import { DirectiveModule } from '../shared/shared/directive/directive.module';
import { SharedModule } from '../shared/shared/shared.module';


@NgModule({
  declarations: [KioskComponent,AddEditKioskComponent],
  imports: [
    CommonModule,
    KioskRoutingModule,
    SharedModule,
    NgxBootstrapIconsModule.pick(allIcons),
    DirectiveModule,
    OfferModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    OfferService,
    DepartmentService,
    DatePipe,
    KioskService
  ],
})
export class KioskModule { }
