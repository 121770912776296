import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilityService } from 'src/app/service/utility.service';
import { Constants } from 'src/app/constant/constants';
import { VtoRequestService } from 'src/app/service/vto-request.service';

@Component({
  selector: 'app-vto-approve-reject-confirmation',
  templateUrl: './vto-approve-reject-confirmation.component.html',
  styleUrls: ['./vto-approve-reject-confirmation.component.scss']
})
export class VtoApproveRejectConfirmationComponent implements OnInit {
  @Input() type: string;
  @Input() selectedRecords: string[];
  @Output() close = new EventEmitter<boolean>();
  requestData: any;
  constructor(
    private utilityService: UtilityService,
    private vtoService: VtoRequestService
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.close.emit(false);
  }

  approveRejectVot() {
    // const postMethod = this.type === 'approved' ? this.votRequestService.approveVotOffer(this.selectedRecords)
    //   :
    this.vtoService.processVtoRequest(this.selectedRecords)
      .then(response => {
        if (response['Success']) {
          this.utilityService.showSuccessToast(Constants.VTO_REQUEST_PROCESS_SUCCESS_MSG)
          this.close.emit(true);
        } else {
          this.utilityService.showErrorToast(response['Message']);
        }
      }, err => { });
  }
}
