import { Role } from 'src/app/models/role-model';


export const defaultRoutes = {
    0: '/',
    [Role.user]: '/layout/offer',
    [Role.hrAdmin]: '/layout/employer',
    [Role.superUser]: '/layout/company',
    [Role.manager]: '/layout/offer',
    [Role.purchaser]: '/layout/plan',
    [Role.learningAdmin]: '/layout/LearningAdmin'
};
