import { Component, OnInit } from '@angular/core';
import { UtilityService } from './../service/utility.service';

@Component({
  selector: 'app-learning-admin',
  templateUrl: './learning-admin.component.html',
  styleUrls: ['./learning-admin.component.scss']
})
export class LearningAdminComponent implements OnInit {
  isModuleTraining:boolean = true;
  isFunctionTraining:boolean;
  isActivityLog:boolean;

  constructor(private utilityService : UtilityService) { }

  ngOnInit() {
    this.utilityService.hideLoading();
  }

  tabChange(e){
    if(e === 1){
      this.isModuleTraining=true;
      this.isFunctionTraining=false;
      this.isActivityLog=false;
    }
    else if(e === 2){
      this.isModuleTraining=false;
      this.isFunctionTraining=true;
      this.isActivityLog=false;
    }
    else if(e === 3){
      this.isModuleTraining=false;
      this.isFunctionTraining=false;
      this.isActivityLog=true;
    }
  }
}
