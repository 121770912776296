import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training-request',
  templateUrl: './training-request.component.html',
  styleUrls: ['./training-request.component.scss']
})
export class TrainingRequestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
