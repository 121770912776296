import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Constants, drpSetting } from "src/app/constant/constants";
import { FormService } from "./../../core/form.service";
import { ModuleFunctionService } from "src/app/service/moduleFunction.service";
import { DepartmentService } from "./../../service/department.service";
import { ToastrService } from "ngx-toastr";
import { DepartmentMappingService } from "./../../service/departmentMapping.service";

@Component({
  selector: "app-add-edit-department-mapping",
  templateUrl: "./add-edit-department-mapping.component.html",
  styleUrls: ["./add-edit-department-mapping.component.scss"],
})
export class AddEditDepartmentMappingComponent implements OnInit {
  @Input() departmentMapping: any;
  @Output() closeRequest = new EventEmitter<boolean>();
  public mappingForm: FormGroup;
  functionList = [];
  moduleList = [];
  departmentList = [];
  subDepartmentList = [];
  messageList: any = {
    FunctionId: String,
    departmentId: String,
    subDepartmentId: String,
    modules: String,
  };
  selectedItems = [];
  dropdownSettings = drpSetting.dropdownSettings;
  companyId: number;

  constructor(
    private formService: FormService,
    private moduleFunctionService: ModuleFunctionService,
    private departmentService: DepartmentService,
    private toaster: ToastrService,
    private departmentMappingService: DepartmentMappingService
  ) {}

  ngOnInit() {
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.initializeForm();
    this.initializeMessages();
    this.loadDropdownData();
  }
  initializeForm() {
    this.mappingForm = new FormGroup({
      departmentMappingId: new FormControl(
        !!this.departmentMapping
          ? this.departmentMapping.departmentMappingId
          : 0
      ),
      FunctionId: new FormControl(
        !!this.departmentMapping ? this.departmentMapping.FunctionId : "",
        Validators.required
      ),
      departmentId: new FormControl(
        !!this.departmentMapping ? this.departmentMapping.departmentId : "",
        Validators.required
      ),
      subDepartmentId: new FormControl(
        !!this.departmentMapping ? this.departmentMapping.subDepartmentId : "",
        Validators.required
      ),
      modules: new FormControl(
        !!this.departmentMapping ? this.departmentMapping.modules : "",
        Validators.required
      ),
      IsActive: new FormControl(true),
      recordedByID : new FormControl(!!this.departmentMapping ? this.departmentMapping.recordedByID : ''),
      companyId :new FormControl(Number(localStorage.getItem(Constants.COMPANYID))),
    });
  }
  initializeMessages() {
    this.messageList.FunctionId = {
      required: Constants.VALIDATION_MSG.DEPARTMENT_MAPPING.FUNCTION,
    };
    this.messageList.departmentId = {
      required: Constants.VALIDATION_MSG.DEPARTMENT_MAPPING.DEPARTMENT,
    };
    this.messageList.subDepartmentId = {
      required: Constants.VALIDATION_MSG.DEPARTMENT_MAPPING.SUBDEPARTMENT,
    };
    this.messageList.modules = {
      required: Constants.VALIDATION_MSG.DEPARTMENT_MAPPING.MODULE,
    };
  }
  loadDropdownData() {
    this.moduleFunctionService.functionDataList(null).then(
      (res: any) => {
        if (res["Success"]) {
          this.functionList = res.Data;
        } else {
          this.functionList = [];
        }
      },
      (err) => {
        this.functionList = [];
      }
    );
    this.moduleFunctionService.getModuleConfigList(null).then(
      (res: any) => {
        let temp = [];
        if (res["Success"]) {
          for (let i = 0; i < res.Data.length; i++) {
            temp.push({
              id: res.Data[i].moduleId,
              text: res.Data[i].moduleName,
            });
          }
          this.moduleList = temp;
          if (!!this.mappingForm.controls.modules.value) {
            this.mappingForm.controls.modules.value.forEach((element) => {
              this.selectedItems.push(
                this.moduleList.find((x) => x.id === Number(element))
              );
            });
            this.mappingForm.get("modules").patchValue(this.selectedItems);
          } else {
            this.mappingForm.get("modules").patchValue([]);
          }
        } else {
          this.moduleList = [];
        }
      },
      (err) => {
        this.moduleList = [];
      }
    );
    this.departmentService
      .getDepartmentListByCompanyId(null, this.companyId)
      .then(
        (res) => {
          if (res["Success"]) {
            this.departmentList = res["Data"];
            if (
              !!this.departmentMapping &&
              !!this.departmentMapping.departmentId
            ) {
              this.onDepartmentChange(this.departmentMapping.departmentId);
            }
          } else {
            this.departmentList = [];
          }
        },
        (err) => {
          this.departmentList = [];
        }
      );
  }
  onDepartmentChange(event) {
    if (!!event.currentTarget) {
      if (!!event.currentTarget.value) {
        this.mappingForm.controls.subDepartmentId.setValue("");
        this.departmentService
          .getSubDeptByDepartment(Number(event.target.value), this.companyId)
          .then(
            (response) => {
              if (response["Success"]) {
                this.subDepartmentList = response["Data"];
              } else {
                this.subDepartmentList = [];
              }
            },
            (err) => {
              this.subDepartmentList = [];
            }
          );
      } else {
        this.subDepartmentList = [];
        this.mappingForm.controls.subDepartmentId.setValue("");
      }
    } else {
      if (!!event) {
        this.departmentService
          .getSubDeptByDepartment(Number(event), this.companyId)
          .then(
            (response) => {
              if (response["Success"]) {
                this.subDepartmentList = response["Data"];
              } else {
                this.subDepartmentList = [];
              }
            },
            (err) => {
              this.subDepartmentList = [];
            }
          );
      } else {
        this.subDepartmentList = [];
        this.mappingForm.controls.subDepartmentId.setValue("");
      }
    }
  }

  cancel() {
    this.closeRequest.emit(false);
  }
  value(controlName: string) {
    return this.control(controlName).value;
  }
  control(controlName: string): AbstractControl {
    return this.mappingForm.get(controlName);
  }
  onSubmit() {
    this.formService.markFormGroupTouched(this.mappingForm);
    if (this.mappingForm.invalid) {
      return;
    }
    if (this.mappingForm.value.modules.length > 0) {
      this.selectedItems.sort((a, b) => a.id > b.id && 1 || -1)
      const selectedModules = this.mappingForm.value.modules.map((x) =>
        x.id.toString()
      );
      this.mappingForm.get("modules").patchValue(selectedModules);
    } else {
      this.mappingForm.controls.modules.setValue([]);
    }
    const saveMethod =
      this.mappingForm.controls.departmentMappingId.value > 0
        ? this.departmentMappingService.updateDepartmentMapping(
            this.mappingForm.value
          )
        : this.departmentMappingService.addDepartmentMapping(
            this.mappingForm.value
          );
    saveMethod.then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(
            this.mappingForm.controls.departmentMappingId.value > 0
              ? Constants.DEPARTMENT_MAPPING_UPDATE_SUCESS_MSG
              : Constants.DEPARTMENT_MAPPING_ADD_SUCESS_MSG
          );
          this.closeRequest.emit(true);
        } else {
          let returnSelectedRecords=[];
          this.mappingForm.controls.modules.value.forEach((element) => {
            returnSelectedRecords.push(
              this.moduleList.find((x) => x.id === Number(element))
            );
          });
          this.mappingForm.get("modules").patchValue(returnSelectedRecords);
          this.toaster.error(res["Message"]);
        }
      },
      (err) => {
        this.closeRequest.emit(false);
      }
    );
  }
  onItemSelect(item: any) {
    this.selectedItems.push(item);
    this.mappingForm.controls.modules.setValue(this.selectedItems);
  }

  onSelectAll(items: any) {
    this.selectedItems = items;
    this.mappingForm.controls.modules.setValue(this.selectedItems);
  }

  onItemDeSelect(items: any) {
    this.selectedItems = this.selectedItems.filter((x) => x.id !== items.id);
    this.mappingForm.controls.modules.setValue(this.selectedItems);
  }

  onDeSelectAll() {
    this.selectedItems = [];
    this.mappingForm.controls.modules.setValue(this.selectedItems);
  }
}
