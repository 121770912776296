import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/constant/constants';
import { FormService } from 'src/app/core/form.service';
import { Role } from 'src/app/models/role-model';
import { CompanyService } from 'src/app/service/company.service';
import { CallInRequestService } from './../../service/callInRequest.service';
import { Reasons } from './../../models/Reasons.model';
import { reasonType } from './../../constant/constants';

@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent implements OnInit {

  @Input() callOffReason: Reasons;
  @Output() close = new EventEmitter<boolean>();
  callOffreasonForm: FormGroup;
  public messageList: any = new Reasons();
  roleEnum = Role;
  companyList = [];
  role: number;
  reasons = reasonType;
  constructor(private formService: FormService,
    private callOffService: CallInRequestService,
    private toaster: ToastrService,
    private companyService: CompanyService) { }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.initializeDepartmentForm();
    this.initializeMessages();
    if (this.role === Role.superUser) {
      this.getCompanyList()
    }
  }


  initializeDepartmentForm() {
    this.callOffreasonForm = new FormGroup({
      reasonId: new FormControl(!!this.callOffReason ? this.callOffReason.reasonId : 0),
      name: new FormControl(!!this.callOffReason ? this.callOffReason.name : '', Validators.required),
      reasonType : new FormControl(!!this.callOffReason ? this.callOffReason.reasonType : '',Validators.required)
    });
  }

  initializeMessages() {
    this.messageList.Name = {
      required: Constants.VALIDATION_MSG.CALL_IN.Name,
    };
    this.messageList.reasonType = {
      required: Constants.VALIDATION_MSG.CALL_IN.MODULE_REQ,
    };
  }

  getCompanyList() {
    this.companyService.getCompanyListWithOutPagination().then(res => {
      if (res['Success']) {
        this.companyList = res['Data'];
      } else { this.companyList = []; }
    }, err => { this.companyList = []; })
  }

  cancel() {
    this.close.emit(false);
  }

  control(controlName: string): AbstractControl {
    return this.callOffreasonForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.callOffreasonForm);
    if (this.callOffreasonForm.invalid) {
      return;
    }
    const saveMethod = this.callOffreasonForm.controls.reasonId.value > 0 ? this.callOffService.updateCallOffReason(this.callOffreasonForm.value)
      : this.callOffService.addCallOffReason(this.callOffreasonForm.value);
    saveMethod.then((res) => {
      if (res['Success']) {
        this.toaster.success(this.callOffreasonForm.controls.reasonId.value > 0 ? Constants.REASON_UPDATE_MSG : Constants.REASON_ADD_MSG);
        this.close.emit(true);
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { this.close.emit(false); });
  }
}
