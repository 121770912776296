import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Constants, bsConfig, timeList, flexType } from 'src/app/constant/constants';
import { OfferStatus, Role } from 'src/app/models/role-model';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/public_api';
import { ShiftService } from 'src/app/service/shift.service';
import { DepartmentService } from 'src/app/service/department.service';
import { DatePipe } from '@angular/common';
import { UtilityService } from 'src/app/service/utility.service';
import { FlexRequestService } from 'src/app/service/flex-request.service';
import { environment } from 'src/environments/environment';
import { commonHelper } from '../../Helpers/commonHelper';
import * as _ from 'lodash';

@Component({
  selector: 'app-flex-request',
  templateUrl: './flex-request.component.html',
  styleUrls: ['./flex-request.component.scss']
})
export class FlexRequestComponent implements OnInit {
  @ViewChild('table', { static: false }) table: any;
  type = '';
  selected = [];
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  @ViewChild('workDateValue', { static: false }) workDateValue;
  @ViewChild('shiftValue', { static: false }) shiftValue;
  @ViewChild('departmentValue', { static: false }) departmentValue;
  @ViewChild('timeValue', { static: false }) timeValue;
  requestedOffer: any;
  requestedOfferList = [];
  modalRef: BsModalRef;
  totalItems: any;
  rowsOnPage = Constants.HR_PAGE_ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  offerStatus = OfferStatus;
  searchSort: any;
  columns = [];
  bsConfig: Partial<BsDatepickerConfig> = bsConfig;
  allRowsSelected = [];
  timeList = timeList;
  shiftList = [];
  departmentList = [];
  selectedRecords = [];
  filterValue = [];
  vtoType = flexType;
  role: number;
  roleEnum = Role;
  commonShiftList: any[];
  
  constructor(
    private modalService: BsModalService,
    private shiftService: ShiftService,
    private departmentService: DepartmentService,
    private datepipe: DatePipe,
    private utilityService: UtilityService,
    private flexService: FlexRequestService
  ) {
    this.columns = ['dateToSkip', 'shiftToSkip', 'timeType', 'departmentId', 'createdByUsername', 'departmentName', 'dateToSkipShortDate', 'vtoStartTime', 'vtoEndTime', 'FlexTypestr', 'offerStatusStr', 'acceptedByShift', 'acceptedByUsername', 'approvedByObj.companyUsername', 'processedByObj.companyUsername'];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [
      ],
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.bsConfig = Object.assign({}, {
      dateInputFormat: 'MM/DD/YYYY',
    });
    this.setPage({ offset: 0 });
    this.getShiftList();
    this.getDepartmentList();
  }

  ngAfterViewInit() {
    this.filterValue.push(this.workDateValue.nativeElement.value);
    this.filterValue.push(this.shiftValue.nativeElement.value);
    this.filterValue.push(this.departmentValue.nativeElement.value);
    this.filterValue.push(this.timeValue.nativeElement.value);
    this.columns.forEach((element, i) => {
      if (i < 4) {
        const obj = {
          Data: 'string',
          Searchable: true,
          Orderable: true,
          Name: element,
          Search: {
            Value: this.filterValue[i],
            Regex: 'string'
          }
        }
        this.searchSort.Columns.push(obj);
      }
    });
  }

  setPage(pageInfo) {
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
    this.getRequestedOfferList(this.page.pageNumber + 1)
  }

  getRequestedOfferList(currentPage) {
    if ((!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) ||
      (!!this.shiftValue && !!this.shiftValue.nativeElement.value) ||
      (!!this.departmentValue && !!this.departmentValue.nativeElement.value) ||
      (!!this.timeValue && !!this.timeValue.nativeElement.value) ||
      (!!this.workDateValue && this.workDateValue.nativeElement.value)) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod = this.flexService.getClosedFlexRequestList(currentPage, this.rowsOnPage);
      getMethod.then((res: any) => {
        if (JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))) {
          this.utilityService.showLoading();
        }
        if (res['Success']) {
          this.requestedOfferList = res['Data'].results;
          this.totalItems = res['Data'].totalNumberOfRecords;
        } else {
          this.requestedOfferList = [];
          this.totalItems = 0;
        }
      }, err => {
        this.requestedOfferList = [];
        this.totalItems = 0;
      });
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clearText();
      }
    }
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  clearText() {
    this.searchSort.Search.Value = '';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }


  clear() {
    this.searchSort.Search.Value = '';
    this.searchSort.Order[0].ColumnName = '';
    this.searchSort.Order[0].Dir = 'asc';
    this.filterTextValue.nativeElement.value = '';
    this.searchSort.Columns.forEach(element => {
      element.Search.Value = '';
    });
    this.shiftValue.nativeElement.value = '';
    this.departmentValue.nativeElement.value = '';
    this.timeValue.nativeElement.value = '';
    this.workDateValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  filterData() {
    const filterMethod = this.flexService.flexClosedRequestDataFilter(this.searchSort);
    filterMethod.then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.requestedOfferList = res['data'];
        this.totalItems =  res['recordsFiltered'];
      } else {
        this.requestedOfferList = [];
        this.totalItems = 0;
      }
    }, err => { this.requestedOfferList = []; this.totalItems = 0; });
  }


  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.selectedRecords = this.selected.map(x => x.offerId);
  }

  getShiftList() {
    this.shiftService.getShiftListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID)))
      .then(response => {
        this.shiftList = response['Data'];
        this.commonShiftList = _.merge([], this.shiftList)
      }, err => { this.shiftList = []; });
  }

  getDepartmentList() {
    this.departmentService.getDepartmentListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID))).then(res => {
      if (res['Success']) {
        this.departmentList = res['Data'];
      } else { this.departmentList = []; }
    }, err => { this.departmentList = []; });
  }

  workDateChange(event) {
    const findDateColumn = this.searchSort.Columns.find(x => x.Name === 'dateToSkip');
    if (!!findDateColumn) {
      findDateColumn.Search.Value = this.datepipe.transform(this.setnewDate(event), 'yyyy/MM/dd');
    }
  }

  setnewDate(date) {
    const dateObj = {
      year: +(this.datepipe.transform(date, 'yyyy')),
      month: +(this.datepipe.transform(date, 'MM')),
      day: +(this.datepipe.transform(date, 'dd'))
    };
    return new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day']);
  }

  shiftChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(x => x.Name === 'shiftToSkip');
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = event.currentTarget.value;
    }
  }

  departmentChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(x => x.Name === 'shiftToSkip');
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = '';
    }
    if (event.currentTarget.value)
      this.shiftList = commonHelper.filterShiftListFromDepartmentId(event.currentTarget.value, this.commonShiftList);
    else
      this.shiftList = this.commonShiftList
    const findDepartmentColumn = this.searchSort.Columns.find(x => x.Name === 'departmentId');
    if (!!findDepartmentColumn) {
      findDepartmentColumn.Search.Value = event.currentTarget.value;
    }
  }

  timeChange(event) {
    const findTimeColumn = this.searchSort.Columns.find(x => x.Name === 'timeType');
    if (!!findTimeColumn) {
      findTimeColumn.Search.Value = event.currentTarget.value;
    }
  }

  downloadFile() {
    if (this.requestedOfferList.length > 0) {
      const downloadMethod = this.flexService.downloadClosedExcelFile(this.searchSort);
      downloadMethod.then(response => {
        if (response['Success']) {
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.href = environment.BLOB_URL + response['Message'];
          a.download = response['Message'].split('/')[1];
          a.click();
        } else {
          this.utilityService.showErrorToast(response['Message']);
        }
      }, err => { });
    }
  }

  openModal(template: TemplateRef<any>, type) {
    if (type === 'approve') {
      this.type = type;
    } else {
      this.type = type;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-md',
      backdrop: 'static'
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }

  // toggleExpandRow(row) {
  //   row.VtoDetail = [...row.VtoDetail];
  //   this.table.rowDetail.toggleExpandRow(row);
  // }

  // onDetailToggle(event) {

  // }


}
