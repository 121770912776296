import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from './../constant/constants';

@Injectable({
  providedIn: 'root'
})
export class KioskService {

  constructor(private apiService: ApiService) { }

  getKioskUserList(companyId, currentPage) {
    return this.apiService.get(
      `${API.KIOSK.getKioskList}?companyId=` + companyId + `&page=` + currentPage,
      true,
      true,
    );
  }

  addKioskRequestOffer(params) {
    return this.apiService.post(`${API.KIOSK.addKioskRequest}`, params, true);
  }

  updateKioskRequestOffer(params) {
    return this.apiService.put(`${API.KIOSK.updateKioskRequest}`, params, true);
  }
  kioskUserDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.KIOSK.kioskDataFilter}`, data, true);
  }
 
  deleteOffer(id) {
    // return this.apiService.delete(
    //   `${API.KIOSK.deleteOfferUser}?KioskId=` + Id,
    //   true,
    // );
    return this.apiService.delete(
      `${API.KIOSK.deleteOfferUser}?id=` + id,
      true,
    );
  }
  getLocation(companyId,currentPage){
    return this.apiService.get(
      `${API.LOCATION_ROUTES.getLocationByCompany}?companyId=${companyId}&page=${currentPage}`,
      false,
      true,
      );
  }
}
