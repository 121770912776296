import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from 'src/app/models/location.model';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Constants, timePickingSystemList } from 'src/app/constant/constants';
import { FormService } from 'src/app/core/form.service';
import { LocationService } from 'src/app/service/location.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { CompanyService } from 'src/app/service/company.service';
import { Role } from 'src/app/models/role-model';


@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent implements OnInit {
  @Input() location: Location;
  @Output() close = new EventEmitter<boolean>();
  locationForm: FormGroup;
  public messageList: any = new Location();
  companyId: any;
  timePickingSystemList = timePickingSystemList;
  companyList = [];
  roleId: number;
  RoleEnum =Role;

  constructor(private toaster: ToastrService,
    private formService: FormService,
    private locationService: LocationService,
    private companyService: CompanyService)  { }

  ngOnInit() {
    this.roleId =   Number(localStorage.getItem(Constants.ROLE))
    this.companyId = !!this.location ? this.location.companyId : Number(localStorage.getItem(Constants.COMPANYID));
    this.getCompanyList();
    this.initializeLocationForm();
    this.initializeMessages();
  }

  initializeLocationForm() {
    this.locationForm = new FormGroup({
      companyId: this.roleId === this.RoleEnum.superUser ?  new FormControl(!!this.location ? this.location.companyId : null,
        Validators.required) : new FormControl(this.companyId),
      locationId: new FormControl(!!this.location ? this.location.locationId : 0),
      locationName: new FormControl(!!this.location ? this.location.locationName : '', Validators.required),
      Latitude: new FormControl(!!this.location ? this.location.Latitude : '', [Validators.pattern(Constants.REGEX.LATITUDE_PATTERN), Validators.required]),
      Longitude: new FormControl(!!this.location ? this.location.Longitude : '', [Validators.pattern(Constants.REGEX.LONGITUDE_PATTERN), Validators.required]),
      timePickingSystem: new FormControl(!!this.location ? this.location.timePickingSystem : null,
        Validators.required),
      isActive: new FormControl(true),
      // companyId: new FormControl(this.companyId)
    });
  }

  initializeMessages() {
    this.messageList.companyId = {
      required: Constants.VALIDATION_MSG.LOCATION.COMPANY_NAME_REQUIRED,
    };
    this.messageList.locationName = {
      required: Constants.VALIDATION_MSG.LOCATION.LOCATION_NAME_REQUIRED,
    };
    this.messageList.timePickingSystem = {
      required: Constants.VALIDATION_MSG.LOCATION.TIME_PICKING_SYSTEM_REQUIRED,
    };
   this.messageList.Longitude = {
     required: Constants.VALIDATION_MSG.LOCATION.LONGITUDE_REQUIRED, 
     pattern: Constants.VALIDATION_MSG.LOCATION.LONGITUDE_Pattern
   };
   this.messageList.Latitude = {
    required: Constants.VALIDATION_MSG.LOCATION.LATITUDE_REQUIRED,
    pattern: Constants.VALIDATION_MSG.LOCATION.LATITUDE_Pattern 
  };
  }
  getCompanyList() {
    this.companyService.getCompanyListWithOutPagination().then(res => {
      if (res['Success']) {
        this.companyList = res['Data'];
      } else { this.companyList = []; }
    }, err => { this.companyList = []; })
  }
  onSubmit() {
    this.formService.markFormGroupTouched(this.locationForm);
    if (this.locationForm.invalid) {
      return;
    }
    this.locationForm.controls.timePickingSystem.setValue(Number(this.locationForm.controls.timePickingSystem.value));
    const saveMethod = this.locationForm.controls.locationId.value > 0 ? this.locationService.updateLocation(this.locationForm.value) : this.locationService.addLocation(this.locationForm.value);
    saveMethod.then((res) => {
      if (res['Success']) {
        this.toaster.success(this.locationForm.controls.locationId.value > 0 ? Constants.LOCATION_UPDATE_SUCESS_MSG : Constants.LOCATION_ADD_SUCCESS_MSG);
        this.close.emit(true);
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { this.close.emit(false); });
  }

  control(controlName: string): AbstractControl {
    return this.locationForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }

  cancel() {
    this.close.emit(false);
  }
}
