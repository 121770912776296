import { NgModule } from "@angular/core";
import { EmployeerRoutingModule } from "./employeer-routing.module";
import { EmployeerComponent } from "./employeer.component";
import { SharedModule } from "../shared/shared/shared.module";
import { EmployeerRequestedOfferComponent } from "./employeer-requested-offer/employeer-requested-offer.component";
import { ApprovedRequestsComponent } from "./approved-requests/approved-requests.component";
import { NgxBootstrapIconsModule, allIcons } from "ngx-bootstrap-icons";
import { EmployerDetailComponent } from "./employer-detail/employer-detail.component";
import { OfferService } from "../service/offer.service";
import { DirectiveModule } from "../shared/shared/directive/directive.module";
import { EmployerSwapRequestComponent } from "./employer-swap-request/employer-swap-request.component";
import { EmployerVotRequestComponent } from "./employer-vot-request/employer-vot-request.component";
import { EmployerTransferRequestComponent } from "./employer-transfer-request/employer-transfer-request.component";
import { EmployerTrainingRequestComponent } from "./employer-training-request/employer-training-request.component";
import { VotApproveRejectConfirmationComponent } from "./vot-approve-reject-confirmation/vot-approve-reject-confirmation.component";
import { VotRequestService } from "../service/vot-request.service";
import { ShiftService } from "../service/shift.service";
import { DepartmentService } from "../service/department.service";
import { DatePipe } from "@angular/common";
import { EmployerVtoRequestComponent } from "./employer-vto-request/employer-vto-request.component";
import { VtoApproveRejectConfirmationComponent } from "./vto-approve-reject-confirmation/vto-approve-reject-confirmation.component";
import { EmployeeCallInRequestComponent } from "./employee-call-in-request/employee-call-in-request.component";
import { VtoRequestService } from "../service/vto-request.service";
import { EmployerEarlyGoRequestComponent } from "./employer-early-go-request/employer-early-go-request.component";
import { OfferModule } from "../offer/offer.module";
import { FlexRequestService } from "../service/flex-request.service";
import { TransferDenialReasonComponent } from "./transfer-denial-reason/transfer-denial-reason.component";
import { TransferApproveRequestComponent } from "./transfer-approve-request/transfer-approve-request.component";
import { TimeOffRequestComponent } from "../time-off-request/time-off-request.component";
import { AddTimeOffRequestComponent } from "../time-off-request/add-time-off-request/add-time-off-request.component";
import { EmployerLateInRequestComponent } from './employer-late-in-request/employer-late-in-request.component';
import { AcceptLateInRequestComponent } from "../offer/accept-late-in-request/accept-late-in-request.component";

import { TimePunchesInfoComponent } from "./time-punches-info/time-punches-info.component";
import { EditTimePunchesComponent } from './time-punches-info/edit-time-punches/edit-time-punches.component';
import { AddPunchesTimeComponent } from './time-punches-info/add-punches-time/add-punches-time.component';
import { EmployerReviewRequestComponent } from './employer-review-request/employer-review-request.component';
import { InconsistTimePunchesRecordsComponent } from './time-punches-info/inconsist-time-punches-records/inconsist-time-punches-records.component';
import { HrAddTimeOffRequestComponent } from '../time-off-request/hr-add-time-off-request/hr-add-time-off-request/hr-add-time-off-request.component';
import { TimePunchesApproveRejectComponent } from './time-punches-approve-reject/time-punches-approve-reject/time-punches-approve-reject.component';

@NgModule({
  declarations: [
    EmployeerComponent,
    AddTimeOffRequestComponent,
    TimeOffRequestComponent,
    EmployeerRequestedOfferComponent,
    ApprovedRequestsComponent,
    EmployerDetailComponent,
    EmployerSwapRequestComponent,
    EmployerVotRequestComponent,
    EmployerTransferRequestComponent,
    EmployerTrainingRequestComponent,
    VotApproveRejectConfirmationComponent,
    EmployerVtoRequestComponent,
    VtoApproveRejectConfirmationComponent,
    EmployeeCallInRequestComponent,
    EmployerEarlyGoRequestComponent,
    TransferDenialReasonComponent,
    TransferApproveRequestComponent,

    EmployerLateInRequestComponent,AcceptLateInRequestComponent,
    TimePunchesInfoComponent,
    EditTimePunchesComponent,
    AddPunchesTimeComponent,
    EmployerReviewRequestComponent,
    InconsistTimePunchesRecordsComponent,
    HrAddTimeOffRequestComponent,
    TimePunchesApproveRejectComponent,


  

  ],
  imports: [
    EmployeerRoutingModule,
    SharedModule,
    NgxBootstrapIconsModule.pick(allIcons),
    DirectiveModule,
    OfferModule,
  ],
  exports: [],
  providers: [
    OfferService,
    VotRequestService,
    ShiftService,
    DepartmentService,
    DatePipe,
    VtoRequestService,
    FlexRequestService,
  ],
})
export class EmployeerModule {}
