import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  Constants,
  ComplianceEnum,
  bsConfig,
} from "src/app/constant/constants";
import { timePunchModel } from "src/app/models/time-punch.model";
import { DatePipe } from "@angular/common";
import { TimePunchesService } from "src/app/service/time-punches.service";
import { Role, OfferStatus } from "src/app/models/role-model";
import { ShiftService } from "src/app/service/shift.service";
import { DepartmentService } from "src/app/service/department.service";
import { commonHelper } from "src/app/shared/Helpers/commonHelper";
import * as _ from "lodash";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";

import { DatatableComponent } from "@swimlane/ngx-datatable";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Offer } from "src/app/models/offer.model";
import { OfferService } from "src/app/service/offer.service";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { UtilityService } from "src/app/service/utility.service";

@Component({
  selector: "app-time-punches",
  templateUrl: "./time-punches.component.html",
  styleUrls: ["./time-punches.component.scss"],
})
export class TimePunchesComponent implements OnInit {
  @ViewChild("userNameValue", { static: false }) userNameValue;
  @ViewChild("filterTextValue", { static: false }) filterTextValue;
  @ViewChild("shiftValue", { static: false }) shiftValue;
  @ViewChild("departmentValue", { static: false }) departmentValue;
  @ViewChild("complianceValue", { static: false }) complianceValue;
  @ViewChild("punchDate", { static: false }) punchDate;
  @ViewChild("punchEndDate", { static: false }) punchEndDate;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  bsConfig: Partial<BsDatepickerConfig> = bsConfig;
  roleEnum = Role;
  role: number;
  page: any;
  searchSort: any;
  userName: string;
  columns = [];
  managercolumns = [];
  complianceList = ComplianceEnum;
  public timePunch: timePunchModel;
  offerStatus = OfferStatus;
  timePunchList = [];
  editedtimePunchList = [];
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  public totalItems: any;
  public editedtotalItems: any;
  filterValue = [];
  nextDate: any;
  searchDate: any;
  shiftList = [];
  departmentList = [];
  commonShiftList = [];
  companyId: number;
  userId: number;
  locationDetails: any;
  longitude: string;
  latitude: string;
  modalRef: BsModalRef;
  public myOffer: Offer;
  workHours: any = null;
  isInconsistent: false;
  isConfirmed = false;
  deleteId: string;
  public isHiddenColumn: boolean = false;
  listTab = true;
  editTab = false;
  editTabColumnList = [];
  constructor(
    private timePunchesService: TimePunchesService,
    private datepipe: DatePipe,
    private shiftService: ShiftService,
    private departmentService: DepartmentService,
    private modalService: BsModalService,
    private toaster: ToastrService,
    private offerService: OfferService,
    private utilityService: UtilityService
  ) {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.userId = Number(localStorage.getItem(Constants.USERID));
    this.columns = [
      "PunchTime",
      "ComplianceStr",
      "PunchTypeStr",
      "PunchLocationStr",
      "SourceStr",
      "offerStatusStr",
      "PunchTimeStr",
    ];
    this.managercolumns = [
      "PunchTime",
      "ComplianceStr",
      "CreatedByObj.companyUsername",
      "CreatedByObj.shiftId",
      "CreatedByObj.departmentId",
      "AdjustedPunchTimeStr",
      "PunchTypeStr",
      "PunchLocationStr",
      "SourceStr",
      "PunchTimeStr",
    ];
    this.editTabColumnList = [
      "OldDateStr",
      "NewDateStr",
      "OldPunchTypeStr",
      "PunchTypeStr",
      "offerStatusStr",
      "reason",
    ];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  async ngOnInit() {
    this.bsConfig = Object.assign(
      {},
      {
        dateInputFormat: "MM/DD/YYYY",
      }
    );
    this.page.pageNumber = 0;
    this.utilityService.showLoading();
    forkJoin([
      this.role === this.roleEnum.manager ?
      this.timePunchesService.managerTimePunchesList(1, false, false):
      this.timePunchesService.getListByUser(
        1,
        this.rowsOnPage,
        false,
        false)
      ,
      this.shiftService.getShiftListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID)),
        false,
        false
      ),
      this.departmentService.getDepartmentListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID)),
        false,
        false
      ),
      this.timePunchesService.getUserWeekHours(this.userId, false, false),
    ]).subscribe((res: any) => {
      if (res[0]["Success"]) {
        this.timePunchList = res[0].Data.results;
        this.totalItems =
          res[0].Data.totalNumberOfRecords === 0
            ? undefined
            : res[0].Data.totalNumberOfRecords;
      } else {
        this.timePunchList = [];
      }
      if (res[1]["Success"]) {
        this.shiftList = res[1]["Data"];
        this.commonShiftList = _.merge([], this.shiftList);
      }

      if (res[2]["Success"]) {
        this.departmentList = res[2]["Data"];
      } else {
        this.departmentList = [];
      }

      if (res[3]["Success"]) {
        this.workHours =
          Number(res[3].Data.ClockedOutWorkHours) +
          Number(res[3].Data.RunningWorkHours);
        this.isInconsistent = res[3].Data.Inconsistent;
      }
      this.utilityService.hideLoading();
    });
  }

  ngAfterViewInit() {
    if (this.role === this.roleEnum.manager) {
      this.filterValue.push("");
      this.filterValue.push(this.userNameValue.nativeElement.value);
      this.filterValue.push(this.departmentValue.nativeElement.value);
      this.filterValue.push(this.shiftValue.nativeElement.value);
      this.filterValue.push(this.punchDate.nativeElement.value);
      this.filterValue.push(this.punchEndDate.nativeElement.value);
      this.filterValue.push(this.complianceValue.nativeElement.value);
      this.managercolumns.forEach((element, i) => {
        if (i < 9) {
          const obj = {
            Data: "string",
            Searchable: true,
            Orderable: true,
            Name: element,
            Search: {
              Value: this.filterValue[i],
              Regex: "string",
              isSearchBetweenDates: i === 0 ? true : false,
              endDate: null,
            },
          };
          this.searchSort.Columns.push(obj);
        }
      });
    } else {
      this.filterValue.push("");
      this.filterValue.push(this.punchDate.nativeElement.value);
      this.filterValue.push(this.punchEndDate.nativeElement.value);
      this.filterValue.push(this.complianceValue.nativeElement.value);
      this.columns.forEach((element, i) => {
        if (i < 2) {
          const obj = {
            Data: "string",
            Searchable: true,
            Orderable: true,
            Name: element,
            Search: {
              Value: this.filterValue[i],
              Regex: "string",
              isSearchBetweenDates: i === 0 ? true : false,
              endDate: null,
            },
          };
          this.searchSort.Columns.push(obj);
        }
      });
    }
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getTimePunchList(this.page.pageNumber + 1);
  }

  setPageForEditedScreen(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getEditedRequests(this.page.pageNumber + 1);
  }
  getEditedRequests(currentPage) {
    this.searchSort.Search.ColumnNameList = this.editTabColumnList;
    let getMethod = this.timePunchesService.userEditedRequests(currentPage);
    getMethod.then((res: any) => {
      if (res["Success"]) {
        this.editedtimePunchList = res.Data.results;
        this.editedtotalItems =
          res.Data.totalNumberOfRecords === 0
            ? undefined
            : res.Data.totalNumberOfRecords;
      } else {
        this.editedtimePunchList = [];
      }
    });
  }
  getTimePunchList(currentPage) {
    if (this.role === this.roleEnum.manager) {
      if (
        (!!this.filterTextValue &&
          !!this.filterTextValue.nativeElement.value) ||
        (!!this.filterTextValue && !!this.userNameValue.nativeElement.value) ||
        (!!this.filterTextValue &&
          !!this.departmentValue.nativeElement.value) ||
        (!!this.filterTextValue && !!this.shiftValue.nativeElement.value) ||
        (!!this.complianceValue &&
          !!this.complianceValue.nativeElement.value) ||
        (!!this.punchDate && !!this.punchDate.nativeElement.value) ||
        (!!this.punchDate && !!this.punchEndDate.nativeElement.value)
      ) {
        this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
        this.searchSort.Search.ColumnNameList = this.managercolumns;
        this.searchSort.Page = currentPage;
        this.filterData();
      } else {
        const getMethod =
          this.timePunchesService.managerTimePunchesList(currentPage);
        getMethod.then((res: any) => {
          if (res["Success"]) {
            this.timePunchList = res.Data.results;
            this.totalItems =
              res.Data.totalNumberOfRecords === 0
                ? undefined
                : res.Data.totalNumberOfRecords;
          } else {
            this.timePunchList = [];
          }
        });
      }
    } else {
      if (
        (!!this.filterTextValue &&
          !!this.filterTextValue.nativeElement.value) ||
        (!!this.complianceValue &&
          !!this.complianceValue.nativeElement.value) ||
        (!!this.punchDate && !!this.punchDate.nativeElement.value) ||
        (!!this.punchEndDate && !!this.punchEndDate.nativeElement.value)
      ) {
        this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = currentPage;
        this.filterData();
      } else {
        const getMethod = this.timePunchesService.getListByUser(
          currentPage,
          this.rowsOnPage
        );

        getMethod.then((res: any) => {
          if (res["Success"]) {
            this.timePunchList = res.Data.results;
            this.totalItems =
              res.Data.totalNumberOfRecords === 0
                ? undefined
                : res.Data.totalNumberOfRecords;
          } else {
            this.timePunchList = [];
          }
        });
      }
    }
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList =
          this.role === this.roleEnum.user
            ? this.listTab
              ? this.columns
              : this.editTabColumnList
            : this.managercolumns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clear();
      }
    }
  }
  clearText() {
    this.searchSort.Search.Value = "";
    this.filterTextValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }
  openMapPopUpModel(template: TemplateRef<any>, data) {
    if (data.geoLocation) {
      const splitGeoLocation = data.geoLocation.split("|");
      if (splitGeoLocation.length > 1) {
        this.locationDetails = {
          latitude: splitGeoLocation[0],
          longitude: splitGeoLocation[1],
          locationName: data.location,
        };
      }

      this.myOffer = data;
    } else {
      this.myOffer = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }
  closeMap() {
    this.modalRef.hide();
  }

  filterData() {
    if (
      this.role == this.roleEnum.manager &&
      !!this.userNameValue.nativeElement.value
    ) {
      const findUserNameColumn = this.searchSort.Columns.find(
        (x) => x.Name === "CreatedByObj.companyUsername"
      );
      if (!!findUserNameColumn) {
        findUserNameColumn.Search.Value =
          this.userNameValue.nativeElement.value.toLowerCase();
      }
    }

    const filterList =
      this.role === this.roleEnum.user
        ? this.listTab
          ? this.timePunchesService.userTimePunchesDataFilter(this.searchSort)
          : this.timePunchesService.userEditedDataFilter(this.searchSort)
        : this.timePunchesService.managerFilterData(this.searchSort);
    filterList.then(
      (res) => {
        if (res["data"] && res["data"].length > 0) {
          if (this.listTab) {
            this.timePunchList = res["data"];
            this.totalItems = res["recordsFiltered"];
          } else {
            this.editedtimePunchList = res["data"];
            this.editedtotalItems = res["recordsFiltered"];
          }
        } else {
          this.timePunchList = [];
          this.totalItems = 0;
          this.editedtimePunchList = [];
          this.editedtotalItems = 0;
        }
      },
      (err) => {
        this.timePunchList = [];
        this.totalItems = 0;
        this.editedtimePunchList = [];
        this.editedtotalItems = 0;
        this.editedtimePunchList = [];
        this.editedtotalItems = 0;
        this.editedtimePunchList = [];
        this.editedtotalItems = 0;
        this.editedtimePunchList = [];
        this.editedtotalItems = 0;
        this.editedtimePunchList = [];
        this.editedtotalItems = 0;
        this.editedtimePunchList = [];
        this.editedtotalItems = 0;
      }
    );
  }

  clear() {
    if (this.role === this.roleEnum.manager) {
      this.searchSort.Search.Value = "";
      this.searchSort.Order[0].ColumnName = "";
      this.searchSort.Order[0].Dir = "asc";
      this.filterTextValue.nativeElement.value = "";
      this.searchSort.Columns.forEach((element) => {
        element.Search.Value = "";
      });

      this.userNameValue.nativeElement.value = "";
      this.departmentValue.nativeElement.value = "";
      this.shiftValue.nativeElement.value = "";
      this.complianceValue.nativeElement.value = "";
      this.punchDate.nativeElement.value = "";
      this.punchEndDate.nativeElement.value = "";
      this.setPage({ offset: 0 });
    } else if (this.listTab) {
      this.searchSort.Search.Value = "";
      this.searchSort.Order[0].ColumnName = "";
      this.searchSort.Order[0].Dir = "asc";
      this.filterTextValue.nativeElement.value = "";
      this.searchSort.Columns.forEach((element) => {
        element.Search.Value = "";
      });
      this.complianceValue.nativeElement.value = "";
      this.punchDate.nativeElement.value = "";
      this.punchEndDate.nativeElement.value = "";
      this.searchDate = this.nextDate;
      this.setPage({ offset: 0 });
    } else {
      this.searchSort.Search.Value = "";
      this.searchSort.Order[0].ColumnName = "";
      this.searchSort.Order[0].Dir = "asc";
      this.filterTextValue.nativeElement.value = "";
      this.searchSort.Columns.forEach((element) => {
        element.Search.Value = "";
      });
      this.setPageForEditedScreen({ offset: 0 });
    }
  }

  async getDepartmentList() {
    await this.departmentService
      .getDepartmentListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID))
      )
      .then(
        (res) => {
          if (res["Success"]) {
            this.departmentList = res["Data"];
          } else {
            this.departmentList = [];
          }
        },
        (err) => {
          this.departmentList = [];
        }
      );
  }
  setnewDate(date) {
    const dateObj = {
      year: +this.datepipe.transform(date, "yyyy"),
      month: +this.datepipe.transform(date, "MM"),
      day: +this.datepipe.transform(date, "dd"),
    };
    return new Date(dateObj["year"], dateObj["month"] - 1, dateObj["day"]);
  }
  startWorkDateChange(event) {
    this.searchDate = null;
    const findDateColumn = this.searchSort.Columns.find(
      (x) => x.Name === "PunchTime"
    );
    if (!!findDateColumn) {
      findDateColumn.Search.Value = this.datepipe.transform(
        this.setnewDate(event),
        "MM/dd/yyyy"
      );
      this.searchDate = this.datepipe.transform(
        this.setnewDate(event),
        "yyyy-MM-dd"
      );
    }
  }
  endWorkDateChange(event) {
    this.searchDate = null;
    const findDateColumn = this.searchSort.Columns.find(
      (x) => x.Name === "PunchTime"
    );
    if (!!findDateColumn) {
      findDateColumn.Search.endDate = this.datepipe.transform(
        this.setnewDate(event),
        "MM/dd/yyyy"
      );
      this.searchDate = this.datepipe.transform(
        this.setnewDate(event),
        "yyyy-MM-dd"
      );
    }
  }
  complianceChange(event) {
    const findComplianceColumn = this.searchSort.Columns.find(
      (x) => x.Name === "ComplianceStr"
    );
    if (!!findComplianceColumn) {
      if (!!event.currentTarget.value) {
        findComplianceColumn.Search.Value = this.complianceList
          .find((x) => x.id == event.currentTarget.value)
          .value.toLowerCase();
      } else {
        findComplianceColumn.Search.Value = "";
      }
    }
  }
  shiftChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(
      (x) => x.Name === "CreatedByObj.shiftId"
    );
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = event.currentTarget.value;
    }
  }
  userNameChange(event) {
    if (event.target.value == "") {
      this.clear();
    } else {
      const findUserNameColumn = this.searchSort.Columns.find(
        (x) => x.Name === "CreatedByObj.companyUsername"
      );
      if (!!findUserNameColumn) {
        findUserNameColumn.Search.Value =
          event.currentTarget.value.toLowerCase();
      }
      this.filterData();
    }
  }

  departmentChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(
      (x) => x.Name === "CreatedByObj.departmentId"
    );
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = "";
    }
    if (event.currentTarget.value)
      this.shiftList = commonHelper.filterShiftListFromDepartmentId(
        event.currentTarget.value,
        this.commonShiftList
      );
    else this.shiftList = this.commonShiftList;
    const findDepartmentColumn = this.searchSort.Columns.find(
      (x) => x.Name === "CreatedByObj.departmentId"
    );
    if (!!findDepartmentColumn) {
      findDepartmentColumn.Search.Value = event.currentTarget.value;
    }
  }

  showPunchLocation(event) {}
  async getShiftList() {
    await this.shiftService
      .getShiftListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID))
      )
      .then(
        (response) => {
          this.shiftList = response["Data"];
          this.commonShiftList = _.merge([], this.shiftList);
        },
        (err) => {
          this.shiftList = [];
        }
      );
  }
  async getUserWeekHours() {
    await this.timePunchesService
      .getUserWeekHours(this.userId)
      .then((res: any) => {
        if (res["Success"]) {
          this.workHours =
            Number(res.Data.ClockedOutWorkHours) +
            Number(res.Data.RunningWorkHours);
          this.isInconsistent = res.Data.Inconsistent;
        } else {
        }
      });
  }

  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.myOffer = data;
      console.log(this.myOffer, "data");
    } else {
      this.myOffer = null;
    }

    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }

  onClickDelete(data) {
    this.deleteId = data.TimePunchesId;
    this.isConfirmed = true;
  }

  delete(timePunchId) {
    this.isConfirmed = false;
    this.offerService.removeTimePunchRequest(timePunchId).then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(Constants.OFFER_DELETE_SUCCESS_MSG);
          this.setPage({ offset: 0 });
        } else {
          this.toaster.error(res["Message"]);
        }
      },
      (err) => {}
    );
  }

  close(event) {
    this.isConfirmed = false;
  }

  tabs() {
    this.listTab = !this.listTab;
    this.editTab = !this.editTab;
    if (this.editTab) {
      this.setPageForEditedScreen({ offset: 0 });
    } else if (this.listTab) {
      this.setPage({ offset: 0 });
    }
  }

  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }
}
