import {
  Component,
  Input,
  OnInit,
  ViewChild,
  TemplateRef,
  EventEmitter,
  Output,
  IterableDiffers,
} from "@angular/core";
import {
  Constants,
  OfferTypesEnum,
  SettingType,
  SubscriptionType,
} from "src/app/constant/constants";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { EarlyOutService } from "./../../service/earlyOut.service";
import { Offer } from "./../../models/offer.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { OfferStatus, Role } from "src/app/models/role-model";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { UtilityService } from "src/app/service/utility.service";
import { CallInRequestService } from "src/app/service/callInRequest.service";
import { DatePipe } from "@angular/common";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-employer-early-go-request",
  templateUrl: "./employer-early-go-request.component.html",
  styleUrls: ["./employer-early-go-request.component.scss"],
})
export class EmployerEarlyGoRequestComponent implements OnInit {
  @Output() reloadCount = new EventEmitter<boolean>();
  @ViewChild("filterTextValue", { static: false }) filterTextValue;
  @Input() requestType: string;
  offerId: number;
  confirmMsg = "";
  confirmCancelBtn = "";
  confirmSaveBtn = "";
  isApprove = false;
  isReject = false;
  isConfirmed = false;
  selected = [];
  columns = [];
  selectedRecords = [];
  earlyOutRequestList = [];
  searchSort: any;
  Offer: Offer;
  modalRef: BsModalRef;
  public earlyGoRequestList: any = [];
  public totalItems: any = "1";
  page: any;
  offerStatus = OfferStatus;
  role: number;
  roleEnum = Role;
  isEarlyOutUTOEnabled: boolean = false;

  public earlyGoForm: FormGroup;
  rowsOnPage = Constants.HR_PAGE_ROWS_ON_PAGE;
  moduleId: number;
  isPaidTimeOff: boolean;
  IsCoverMyWork: boolean;
  constructor(
    private modalService: BsModalService,
    private earlyOutService: EarlyOutService,
    private utilityService: UtilityService,
    private callInRequstService: CallInRequestService,
    private toaster: ToastrService,
    private datePipe: DatePipe
  ) {
    this.columns = [
      "createdByObj.companyUsername",
      "dateToSkipShortDate",
      "vtoStartTime",
      "shiftToSkipNavigation.title",
      "departmentName",
      "createdDate",
      "uTOHours",
      "ReasonObj.name",
      "Status",
      "approvedByObj.name",
      "VtoStartTime12Hours",
      "IsPaidOff",
      "OtherReason",
      "depatureTime"
    ];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  ngOnInit() {
    this.isConfirmed = false;
    this.setPage({ offset: 0 });
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.IsCoverMyWork =
      localStorage.getItem(Constants.APP_NAME) === "CoverMyWork" ? true : false;
  }

  setPage(pageInfo, isReloadCount = false) {
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
    this.utilityService.showLoading();
    let module = SubscriptionType.filter((item) => {
      return item.id === OfferTypesEnum.EarlyOutModule;
    });
    this.moduleId = module[0].id;
    forkJoin([
      this.callInRequstService
      .getSettingByCompanyID(
        this.moduleId,
        Number(localStorage.getItem(Constants.COMPANYID)),
        false,
        false
      ),
      this.requestType === Constants.REQUESTED_OFFER
      ? this.earlyOutService.getEarlyPendingHrRequestList(
        this.page.pageNumber + 1,
          this.rowsOnPage,
          false,
          false
        )
      : this.earlyOutService.getEarlyClosedHrRequestList(
        this.page.pageNumber + 1,
          this.rowsOnPage,
          false,
          false
        )
    ]).subscribe((res:any)=>{
      if (res[0]["Success"]) {
        //first api response
        if (res[0].Data.length > 0) {
          res[0].Data.map((item) => {
            if (item.SettingType === SettingType[0].value) {
              item.Name = "Paid Time Off";
              if (item.Enable == true) {
                this.isPaidTimeOff = true;
              } else {
                this.isPaidTimeOff = false;
              }
            } else if (item.SettingType === SettingType[7].value) {
              if (item.Enable == true) {
                this.isEarlyOutUTOEnabled = true;
              }
            }
          });
        }
      } 

      //second api response 
      if (res[1]["Success"]) {
        if (res[1].Data && res[1].Data.results.length > 0) {
          this.earlyOutRequestList = res[1].Data.results;
          this.totalItems = res[1].Data.totalNumberOfRecords;
          // this.totalRequestedHours = this.role == this.roleEnum.manager ? res.Data.totalHours.totalHoursApproved : 0;
        } else {
          this.earlyOutRequestList = [];
          this.totalItems = 0;
        }
      } else {
        this.earlyOutRequestList = [];
        this.totalItems = 0;
      }
      this.utilityService.hideLoading();
    })  
    if (isReloadCount) {
      this.reloadCount.emit();
    }
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  getSettingByCompanyIDAsync() {
    let module = SubscriptionType.filter((item) => {
      return item.id === OfferTypesEnum.EarlyOutModule;
    });
    this.moduleId = module[0].id;
    this.callInRequstService
      .getSettingByCompanyID(
        this.moduleId,
        Number(localStorage.getItem(Constants.COMPANYID))
      )
      .then(
        (res: any) => {
          if (res["Success"]) {
            if (res.Data.length > 0) {
              res.Data.map((item) => {
                if (item.SettingType === SettingType[0].value) {
                  item.Name = "Paid Time Off";
                  if (item.Enable == true) {
                    this.isPaidTimeOff = true;
                  } else {
                    this.isPaidTimeOff = false;
                  }
                } else if (item.SettingType === SettingType[7].value) {
                  if (item.Enable == true) {
                    this.isEarlyOutUTOEnabled = true;
                  }
                }
              });
            }
          } else {
          }
        },
        (err) => {}
      );
  }

  async getEarlyOutOfferListAsync(currentPage) {
    const getMethod =
      this.requestType === Constants.REQUESTED_OFFER
        ? this.earlyOutService.getEarlyPendingHrRequestList(
            currentPage,
            this.rowsOnPage
          )
        : this.earlyOutService.getEarlyClosedHrRequestList(
            currentPage,
            this.rowsOnPage
          );
   await getMethod.then(
      (res: any) => {
        if (res["Success"]) {
          if (res.Data && res.Data.results.length > 0) {
            this.earlyOutRequestList = res.Data.results;
            this.totalItems = res.Data.totalNumberOfRecords;
            // this.totalRequestedHours = this.role == this.roleEnum.manager ? res.Data.totalHours.totalHoursApproved : 0;
          } else {
            this.earlyOutRequestList = [];
            this.totalItems = 0;
          }
        } else {
          this.earlyOutRequestList = [];
          this.totalItems = 0;
        }
      },
      (err) => {
        this.earlyOutRequestList = [];
        this.totalItems = 0;
      }
    );
  }
  clear() {
    this.searchSort.Search.Value = "";
    this.searchSort.Order[0].ColumnName = "";
    this.searchSort.Order[0].Dir = "asc";
    this.filterTextValue.nativeElement.value = "";
    this.searchSort.Columns.forEach((element) => {
      element.Search.Value = "";
    });
    this.setPage({ offset: 0 });
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clearText();
      }
    }
  }

  clearText() {
    this.searchSort.Search.Value = "";
    this.filterTextValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }

  filterData() {
    const filterMethod =
      this.requestType === Constants.REQUESTED_OFFER
        ? this.earlyOutService.hrPendingEarltyOutOfferDataFilter(
            this.searchSort
          )
        : this.earlyOutService.hrClosedEarltyOutOfferDataFilter(
            this.searchSort
          );
    filterMethod.then(
      (res) => {
        if (res["data"] && res["data"].length > 0) {
          this.earlyOutRequestList = res["data"];
          this.totalItems = res["recordsFiltered"];
        } else {
          this.totalItems = 0;
        }
      },
      (err) => {
        this.earlyOutRequestList = [];
        this.totalItems = 0;
      }
    );
  }
  // openModal(template: TemplateRef<any>, data: any) {

  //   if (data) {
  //     this.Offer = data;
  //   } else {
  //     this.Offer = null;
  //   }
  //   this.modalRef = this.modalService.show(template, {
  //     class: 'modal-dialog-centered modal-lg',
  //     backdrop: 'static'
  //   });
  // }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 }, event);
    }
    this.modalRef.hide();
  }

  close(event) {
    this.isConfirmed = false;
    this.isReject = false;
  }

  Denied(type, data) {
    this.isConfirmed = true;
    this.isReject = true;
    this.confirmSaveBtn = Constants.YES;
    this.confirmCancelBtn = Constants.NO;
    this.confirmMsg = Constants.REJECT_MSG;
    this.offerId = data.offerId;
  }

  openApprove(template: TemplateRef<any>, data: any) {
    if (!this.isEarlyOutUTOEnabled) {
      this.earlyOutService
        .proceedHroffer({
          ...data,
          isUtoBalance: false,
          isWarningCompleted: false,
        })
        .then(async (res) => {
          if (res["Success"]) {
            await this.getEarlyOutOfferListAsync(this.page.pageNumber + 1);
            await this.reloadCount.emit();
            this.toaster.success(
              Constants.EARLY_OUT_REQUEST_PROCESS_SUCCESS_MSG
            );
          } else {
            this.toaster.error(res["Message"]);
          }
        });
      return;
    }
    if (data) {
      this.Offer = data;
    } else {
      this.Offer = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }
  ApproveReject(id) {
    this.isReject = false;
    this.earlyOutService.rejectManagerEarlyOutOffers(id).then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(Constants.OFFER_REJECT_SUCCESS_MSG);
          this.setPage({ offset: 0 });
        } else {
          this.toaster.error(res["Message"]);
        }
      },
      (err) => {}
    );
  }
  convertDateToSkipShortDate(record) {
    const date = record.dateToSkipShortDate.split("/");
    const vtoStartTime = record.vtoStartTime.split(":");
    const dateToSkipHH = vtoStartTime[0];
    const dateToSkipMin = vtoStartTime[1];
    const dateToSkipMM = parseInt(date[0]);
    const dateToSkipDD = parseInt(date[1]);
    const dateToSkipYYYY = parseInt(date[2]);
    if (record.IsDateCrossOver) {
      return this.datePipe.transform(
        new Date(
          dateToSkipYYYY,
          dateToSkipMM - 1,
          dateToSkipDD - 1,
          dateToSkipHH,
          dateToSkipMin
        ),
        "MM/dd/yyyy hh:mm a"
      );
    }
    return this.datePipe.transform(
      new Date(
        dateToSkipYYYY,
        dateToSkipMM - 1,
        dateToSkipDD,
        dateToSkipHH,
        dateToSkipMin
      ),
      "MM/dd/yyyy hh:mm a"
    );
  }
  downloadFile() {
    const downloadMethod =
      this.requestType !== "approvedOffer"
        ? this.earlyOutService.downloadPendingExcelFile(this.searchSort)
        : this.earlyOutService.downloadClosedExcelFile(this.searchSort);
    downloadMethod.then(
      (response) => {
        if (response["Success"]) {
          const a = document.createElement("a");
          a.setAttribute("style", "display:none;");
          document.body.appendChild(a);
          a.href = environment.BLOB_URL + response["Message"];
          a.download = response["Message"].split("/")[1];
          a.click();
        } else {
          this.utilityService.showErrorToast(response["Message"]);
        }
      },
      (err) => {}
    );
  }
}
