import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  AfterViewInit,
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../constant/constants";
import { timeOffRequestModel } from "../models/TimeOffRequest.model";
import { TimeOffService } from "../service/time-off.service";
import { UtilityService } from "./../service/utility.service";

@Component({
  selector: "app-time-off-request",
  templateUrl: "./time-off-request.component.html",
  styleUrls: ["./time-off-request.component.scss"],
})
export class TimeOffRequestComponent implements OnInit, AfterViewInit {
  @ViewChild("filterTextValue", { static: false }) filterTextValue;
  @ViewChild("timeOffConfig", { static: false }) timeOffConfig;
  page: any;
  searchSort: any;
  columns = [];
  isShowLink = false;
  IsActive = false;
  modalRef: BsModalRef;
  timeOffList = [];
  timeOff: any;
  totalItems: any;
  timeOffRequest: any;
  addTimeOffRequest: any;
  show = false;
  summaryTab = false;
  detailsTab = true;
  typeList = [];
  rowsOnPage = Constants.HR_PAGE_ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  selected = [];
  allRowsSelected = [];
  selectedRecords = [];
  timeoffRequestList = [];
  userId: number;
  companyId: number;
  timeOffConfigList: [];
  filterValue = [];
  deleteId: number;
  isConfirmed: boolean = false;
  public myOffer: timeOffRequestModel;

  constructor(
    private modalService: BsModalService,
    private timeOffService: TimeOffService,
    private utilityService: UtilityService,
    private toaster: ToastrService
  ) {
    this.columns = [
      "TimeOffId",
      "UserName",
      "TimeOffConfiguration.Name",
      "TimeOffEndDateStr",
      "TimeOffQuantity",
      "NetBalance",
    ];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  async ngOnInit() {
    this.userId = Number(localStorage.getItem(Constants.USERID));
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber =0;
    this.utilityService.showLoading();
    forkJoin([
      this.timeOffService.getTimeOff(null, this.companyId,false,false),
      this.timeOffService.getHrUserList(
        this.userId,
        1,
        this.rowsOnPage,
        false,
        false
      )
    ]).subscribe((res : any )=> {
        if (res[0]["Success"]) {
          this.timeOffConfigList = res[0]["Data"];
        } 

        if (JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))) {
          this.utilityService.showLoading();
        }
        if (res[1]["Success"]) {
          this.timeoffRequestList = res[1].Data.results;
          this.totalItems =
          res[1].Data.totalNumberOfRecords === 0
              ? undefined
              : res[1].Data.totalNumberOfRecords;
        } else {
          this.timeoffRequestList = [];
          this.totalItems = undefined;
        }
        this.utilityService.hideLoading()
    })
    this.typeList = [
      {
        id: 1,
        value: "Paid Time Off",
      },
      {
        id: 2,
        value: "Call-Off time",
      },
    ];
  }
  ngAfterViewInit() {
    this.filterValue.push("");
    this.filterValue.push(this.timeOffConfig.nativeElement.value);
    this.columns.forEach((element, i) => {
      if (i < 1) {
        const obj = {
          Data: "string",
          Searchable: true,
          Orderable: true,
          Name: element,
          Search: {
            Value: this.filterValue[i],
            Regex: "string",
          },
        };
        this.searchSort.Columns.push(obj);
      }
    });
  }
  timeOffConfigChange(event) {
      this.searchSort.Search.Column = this.columns;
      const findTimeOffConfigColumn = this.searchSort.Columns.find(
        (x) => x.Name === "TimeOffId"
      );
      if (!!findTimeOffConfigColumn) {
        findTimeOffConfigColumn.Search.Value = event.currentTarget.value;
      }
      this.filterData();
  }

  setPage(pageInfo) {
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
    this.getTimeOffRequestList(this.page.pageNumber + 1);
  }

  getTimeOffRequestList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod = this.timeOffService.getHrUserList(
        this.userId,
        currentPage,
        this.rowsOnPage
      );
      getMethod.then(
        (res: any) => {
          if (JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))) {
            this.utilityService.showLoading();
          }
          if (res["Success"]) {
            this.timeoffRequestList = res.Data.results;
            this.totalItems =
              res.Data.totalNumberOfRecords === 0
                ? undefined
                : res.Data.totalNumberOfRecords;
          } else {
            this.timeoffRequestList = [];
            this.totalItems = undefined;
          }
        },
        (err) => {
          this.timeoffRequestList = [];
        }
      );
    }
  }
  onClickDelete(data) {
    this.deleteId = data.TimeOffUserRequestId;
    this.isConfirmed = true;
  }

  delete(id) {
    this.isConfirmed = false;
    this.timeOffService.deleteTimeOffRequest(id).then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(Constants.TIME_OFF_DELETE_SUCCESS_MSG);
          this.setPage({ offset: 0 });
        } else {
          this.toaster.error(res["Message"]);
        }
      },
      (err) => {}
    );
  }

  close(event) {
    this.isConfirmed = false;
  }

  clear() {
    this.isShowLink = false;
    this.searchSort.Search.Value = "";
    this.searchSort.Order[0].ColumnName = "";
    this.searchSort.Order[0].Dir = "asc";
    this.filterTextValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }

  get enableDisableCloseIcon(): boolean {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      return false;
    }
    return true;
  }

  async getTimeOffConfigList() {
    await this.timeOffService.getTimeOff(null, this.companyId).then(
      (response) => {
        if (response["Success"]) {
          this.timeOffConfigList = response["Data"];
        } 
      }
    );
  }
  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }
  cancle(event) {
    this.modalRef.hide();
  }
  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.timeOffRequest = data;
    } else {
      this.timeOffRequest = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }
  openAddTimeOff(template: TemplateRef<any>, data: any) {
    if (data) {
      this.addTimeOffRequest = data;
    } else {
      this.addTimeOffRequest = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }
  tabs(str) {
    if (str == "details") {
      this.detailsTab = true;
      this.summaryTab = false;
    } else {
      this.summaryTab = true;
      this.detailsTab = false;
    }
  }
  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clear();
      }
    }
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  filterData() {
    const filterMethod = this.timeOffService.hrManagerFilterData(
      this.searchSort,
      this.userId
    );
    filterMethod.then(
      (res) => {
        if (res["data"] && res["data"].length > 0) {
          this.timeoffRequestList = res["data"];
          this.totalItems =
            res["recordsFiltered"] === 0 ? undefined : res["recordsFiltered"];
        } else {
          this.timeoffRequestList = [];
          this.totalItems = undefined;
        }
      },
      (err) => {
        this.timeoffRequestList = [];
        this.totalItems = undefined;
      }
    );
  }

  downloadFile() {
    const downloadMethod = this.detailsTab
      ? this.timeOffService.downloadDetailsExcelFile(
          this.searchSort,
          this.userId
        )
      : this.timeOffService.downloadSummaryRequestsExcelFiles(
          this.searchSort,
          this.userId
        );
    downloadMethod.then(
      (response) => {
        if (response["Success"]) {
          const a = document.createElement("a");
          a.setAttribute("style", "display:none;");
          document.body.appendChild(a);
          a.href = environment.BLOB_URL + response["Message"];
          a.download = response["Message"].split("/")[1];
          a.click();
        } else {
          this.utilityService.showErrorToast(response["Message"]);
        }
      },
      (err) => {}
    );
  }
}
