import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from '../constant/constants';

@Injectable()
export class VotRequestService {

  constructor(private apiService: ApiService) { }

  getVotRequestList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.VOT_REQUEST_ROUTES.getVotRequest}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      false,
    );
  } 
  
  addVotRequestOffer(params) {
    return this.apiService.post(`${API.VOT_REQUEST_ROUTES.addVotRequest}`, params, true);
  }

  updateVotRequestOffer(params) {
    return this.apiService.put(`${API.VOT_REQUEST_ROUTES.updateVotRequest}`, params, true);
  }

  votRequestOfferDataFilter(data) { //dataFilter
    return this.apiService.post(`${API.VOT_REQUEST_ROUTES.votRequestOfferDataFilter}`, data, true);
  }


  getVotHrRequestedOfferList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.VOT_REQUEST_ROUTES.getHrVotRequest}?page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }

  getSelectedVotHours(ids) {
    return this.apiService.post(`${API.VOT_REQUEST_ROUTES.getVotHours}`, ids, true);
  }

  approveVotOffer(Ids) {
    return this.apiService.put(`${API.VOT_REQUEST_ROUTES.approveVotOffer}`, Ids, true);
  }

  rejectVotOffer(Ids) {
    return this.apiService.put(`${API.VOT_REQUEST_ROUTES.rejectVotOffer}`, Ids, true);
  }

  getVotClosedRequestedOfferList(currentPage, rowsOnPage) {
    return this.apiService.get(
      `${API.VOT_REQUEST_ROUTES.getClosedVotRequestOffer}?companyId=${localStorage.getItem(Constants.COMPANYID)}&page=` +
      currentPage + `&pageSize=${rowsOnPage}`,
      true,
      true,
    );
  }

  approvalVotOfferDataFilter(data) {
    return this.apiService.post(`${API.VOT_REQUEST_ROUTES.approveVotDataFilter}`, data, true);
  }

  closedVotOfferDataFilter(data) {
    return this.apiService.post(`${API.VOT_REQUEST_ROUTES.closedVotDataFilter}`, data, true);
  }

  downloadExcelFile(data) {
    return this.apiService.post(`${API.VOT_REQUEST_ROUTES.downloadExcel}`, data, true);
  }
  downloadClosedExcelFile(data){
    return this.apiService.post(`${API.VOT_REQUEST_ROUTES.downloadClosedExcelFile}`, data, true);
  }
}
