import { NgModule } from '@angular/core';
import { CompanyRoutingModule } from './company-routing.module';
import { CompanyComponent } from './company.component';
import { SharedModule } from '../shared/shared/shared.module';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { AddEditComponent } from './add-edit/add-edit.component';
import { CompanyService } from '../service/company.service';
import { LocationService } from '../service/location.service';
import { DirectiveModule } from '../shared/shared/directive/directive.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';

@NgModule({
  declarations: [CompanyComponent, AddEditComponent],
  imports: [
    SharedModule,
    CompanyRoutingModule,
    NgxBootstrapIconsModule.pick(allIcons),
    DirectiveModule,
    AccordionModule
  ],
  providers: [CompanyService, LocationService]
})
export class CompanyModule { }
