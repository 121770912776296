
import { Component, OnInit, ViewChild, TemplateRef,Input, EventEmitter, Output } from '@angular/core';
import { Constants,timeList } from 'src/app/constant/constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OfferStatus, Role } from 'src/app/models/role-model';
import { EarlyOutService } from './../../service/earlyOut.service';
import { Offer } from 'src/app/models/offer.model';
import { OfferService } from './../../service/offer.service';
import { ToastrService } from 'ngx-toastr';
import { TransferRequestService } from 'src/app/service/transfer-request.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from 'src/app/service/utility.service';
import { ShiftService } from 'src/app/service/shift.service';
import { DepartmentService } from 'src/app/service/department.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { commonHelper } from 'src/app/shared/Helpers/commonHelper';

@Component({
  selector: 'app-employer-transfer-request',
  templateUrl: './employer-transfer-request.component.html',
  styleUrls: ['./employer-transfer-request.component.scss']
})
export class EmployerTransferRequestComponent implements OnInit {
  @Output() reloadCount = new EventEmitter<boolean>();
  showAllCheckbox :boolean = false;
  filterValue = [];
  shiftList = [];
  timeList = timeList;
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  @ViewChild('workDateValue', { static: false }) workDateValue;
  @ViewChild('shiftValue', { static: false }) shiftValue;
  @ViewChild('departmentValue', { static: false }) departmentValue;
  @ViewChild('statusValue', { static: false }) statusValue;
  allRowsSelected = [];
  @Input() requestType: string;
  pendingStatusList = [{ id: 1, name: 'Pending Approval' }, { id: 2, name: 'Approved' },{id:7,name:"Pending Process"}];
  closedStatusList =  [{ id: 8, name: 'Expired' }, { id: 6, name: 'Processed' }];
  public statusList:any=[];
  public showAction: boolean = true;
  public totalItems: any ;
  page: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  role: number;
  roleEnum = Role;
  deleteId: string;
  isConfirmed = false;
  offerStatus = OfferStatus;
  searchSort: any; 
  public selected:any=[];
  public selectedRecords:any=[];
  public myOffer: Offer;
  modalRef: BsModalRef;
  columns = [];
  searchDate: any;
  nextDate: any;
  departmentList:any=[];
  
  
  public transferRequestList:any=[];
  rows = [];
  isStatus: boolean = false;
  commonShiftList: any;
  constructor(private modalService: BsModalService,
    private tranferService:TransferRequestService,
    private utilityService:UtilityService,
    private shiftService:ShiftService,
    private departmentService:DepartmentService,
    private datepipe: DatePipe
    ) { 
      // this.columns = ['departmentId', 'dateToSkip', 'shiftToSkip', 'status', 'departmentName', 'FlexTypestr', 'dateToSkipShortDate', 'vtoStartTime', 'vtoEndTime', 'vtoCount', 'createdByUsername', 'offerStatusStr', 'timeTypeStr'];
      // this.columns = ['createdByUsername','departmentId','createdShortDate','shiftToSkip','title','departmentName','shiftToWorkTitle','offerStatusStr','status','dateToSkip','isPit','operateToShow']
      
      this.columns = [
        'departmentId', 'createdDate', 'shiftToSkip', 'status','createdByUsername', 'createdShortDate','createdByObj.shift.title','departmentName','department.departmentName','subDepartmentName', 'shiftToWorkTitle', 'offerStatusStr', 'dateToSkip', 'isPit', 'operateToShow','preferenceId', 'dateToWorkDateStr','createdByShift'
      ];
      
      this.page = {
        pageNumber: 0,
        size: this.rowsOnPage,
      };
      this.searchSort = {
        Page: this.page.pageNumber + 1,
        PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
        Columns: [
        ],
        Search: {
          Value: '',
          ColumnNameList: [],
          Regex: 'string'
        },
        Order: [{
          Column: 0,
          ColumnName: '',
          Dir: 'asc'
        }]
      }
    }
    
    ngOnInit() {
      this.isConfirmed = false;
      this.role = Number(localStorage.getItem(Constants.ROLE));
      if (this.role === this.roleEnum.hrAdmin) {
        this.getShiftList();
        this.getDepartmentList();
        //this.convertEnumToArray();
      }
      this.statusList = this.requestType === Constants.REQUESTED_OFFER ?
      this.pendingStatusList
      : this.closedStatusList;
      console.log(this.statusList)
      this.setPage({ offset: 0 });
      console.log(this.requestType);
    }
    
    ngAfterViewInit() {
      if (this.role === this.roleEnum.hrAdmin) {
        // this.workDateValue.nativeElement.value = this.nextDate;
        this.filterValue.push('');
        this.filterValue.push(this.shiftValue.nativeElement.value);
        this.filterValue.push(this.departmentValue.nativeElement.value);
        this.filterValue.push(this.statusValue.nativeElement.value);
        this.columns.forEach((element, i) => {
          if (i < 4) {
            const obj = {
              Data: 'string',
              Searchable: true,
              Orderable: true,
              Name: element,
              Search: {
                Value: this.filterValue[i],
                Regex: 'string'
              }
            }
            this.searchSort.Columns.push(obj);
          }
        });
      }
    }
    
    workDateChange(event) {
      this.searchDate = null;
      const findDateColumn = this.searchSort.Columns.find(x => x.Name === 'createdDate');
      if (!!findDateColumn) {
        findDateColumn.Search.Value = this.datepipe.transform(this.setnewDate(event), 'yyyy-MM-dd');
        this.searchDate = this.datepipe.transform(this.setnewDate(event), 'yyyy-MM-dd');
      }
    }
    
    shiftChange(event) {
      const findShiftColumn = this.searchSort.Columns.find(x => x.Name === 'shiftToSkip');
      if (!!findShiftColumn) {
        findShiftColumn.Search.Value = event.currentTarget.value;
      }
      console.log(findShiftColumn);
    }
    
    departmentChange(event) {
      const findShiftColumn = this.searchSort.Columns.find(x => x.Name === 'shiftToSkip');
      if (!!findShiftColumn) {
        findShiftColumn.Search.Value = '';
      }
      if (event.currentTarget.value)
        this.shiftList = commonHelper.filterShiftListFromDepartmentId(event.currentTarget.value, this.commonShiftList);
      else
        this.shiftList = this.commonShiftList
      const findDepartmentColumn = this.searchSort.Columns.find(x => x.Name === 'departmentId');
      if (!!findDepartmentColumn) {
        findDepartmentColumn.Search.Value = event.currentTarget.value;
      }
    }
    
    statusChange(event) {
      const findStatusColumn = this.searchSort.Columns.find(x => x.Name === 'status');
      console.log(findStatusColumn)
      if (!!findStatusColumn) {
        findStatusColumn.Search.Value = event.currentTarget.value;
      }
    }
    
    getDepartmentList() {
      this.departmentService.getDepartmentListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID))).then(res => {
        if (res['Success']) {
          this.departmentList = res['Data'];
        } else { this.departmentList = []; }
      }, err => { this.departmentList = []; });
    }
    
    getShiftList() {
      this.shiftService.getShiftListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID)))
      .then(response => {
        this.shiftList = response['Data'];
        this.commonShiftList = _.merge([], this.shiftList)
      }, err => { this.shiftList = []; });
    }
    
    updateFilter(event) {
      if (event.key !== "Tab") {
        if (!!event.target.value) {
          this.searchSort.Search.Value = event.target.value;
          this.searchSort.Search.ColumnNameList = this.columns;
          this.searchSort.Page = this.page.pageNumber + 1;
          this.filterData();
        } else {
          this.clear();
        }
      }
    }
    
    setnewDate(date) {
      const dateObj = {
        year: +(this.datepipe.transform(date, 'yyyy')),
        month: +(this.datepipe.transform(date, 'MM')),
        day: +(this.datepipe.transform(date, 'dd'))
      };
      return new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day']);
    }
    
    clear() {
      if (this.role === this.roleEnum.user) {
        this.searchSort.Search.Value = '';
        this.searchSort.Order[0].ColumnName = '';
        this.searchSort.Order[0].Dir = 'asc';
        this.filterTextValue.nativeElement.value = '';
        this.setPage({ offset: 0 });
      } else {
        this.searchSort.Search.Value = '';
        this.searchSort.Order[0].ColumnName = '';
        this.searchSort.Order[0].Dir = 'asc';
        this.filterTextValue.nativeElement.value = '';
        this.searchSort.Columns.forEach(element => {
          element.Search.Value = '';
        });
        this.shiftValue.nativeElement.value = '';
        this.departmentValue.nativeElement.value = '';
        // this.workDateValue.nativeElement.value = this.nextDate;
        this.workDateValue.nativeElement.value = '';
        this.statusValue.nativeElement.value = '';
        this.searchDate = this.nextDate;
        this.setPage({ offset: 0 });
      }
    }
    
    
    async setPage(pageInfo,isReloadCount = false) {
      this.selected = [];
      this.selectedRecords = [];
      this.page.pageNumber = pageInfo.offset;
      await this.getTransferOfferList(this.page.pageNumber + 1)
      if(isReloadCount){
        this.reloadCount.emit();
      }
    }
    
    
    
    openDenailModal(template: TemplateRef<any>, data: any) {
      if (data) {
        this.myOffer = data;
      } else {
        this.myOffer = null;
      }     
      this.modalRef = this.modalService.show(template, {
        class: 'modal-dialog-centered modal-md',
        backdrop: 'static'
      });
    }
    // closeModal(event) {
    //   this.modalRef.hide();
    
    // }
    
    closeModal(event) {
      if (event) {
        this.setPage({ offset: 0 },event);
      }
      this.modalRef.hide();
    }
    closeApproveModal(event){
      if (event) {
        this.setPage({ offset: 0 },event);
      }
      this.modalRef.hide();
    }
    openTransferApproveModal(template: TemplateRef<any>, data: any) {
      if (data) {
        this.myOffer = data;
      } else {
        this.myOffer = null;
      }
      console.log(this.myOffer)
      this.modalRef = this.modalService.show(template, {
        class: 'modal-dialog-centered modal-md',
        backdrop: 'static'
      });
    }
    
    async getTransferOfferList(currentPage) {
      const getMethod = this.requestType === Constants.REQUESTED_OFFER ?
      this.tranferService.getTransferPendingHrRequestList(currentPage, this.rowsOnPage)
      : this.tranferService.getTransferClosedHrRequestList(currentPage, this.rowsOnPage);
     await getMethod.then((res: any) => {
        if (res['Success']) {
          if (res.Data && res.Data.results.length > 0) {
            this.transferRequestList = res.Data.results;
            this.showAllCheckbox = this.transferRequestList.some(e=>e.status == this.offerStatus.accepted);
            this.transferRequestList.map((item:any)=>{
              if(item.isPit){
                item.operateToShow = "Yes"
              }
              else{
                item.operateToShow = "No";
              }
              if (item.status ===this.offerStatus.accepted){
                item.offerStatusStr = "Pending Process"
              }
            })
            this.totalItems = res.Data.totalNumberOfRecords;
          } else {
            this.transferRequestList = [];
            this.totalItems = 0;
          }
        } else {
          this.transferRequestList = [];
          this.totalItems = 0;
        }
      }, err => {
        this.transferRequestList = [];
        this.totalItems = 0;
      });
    }
    
    sortCallback(sortInfo) {
      this.searchSort.Page = this.page.pageNumber + 1;
      this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
      this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
      this.filterData();
    }
    
    filterData() {
      console.log(this.searchSort)
      const filterMethod = this.requestType === Constants.REQUESTED_OFFER ?
      this.tranferService.hrPendingTransferOfferDataFilter(this.searchSort)
      : this.tranferService.hrClosedTransferOfferDataFilter(this.searchSort);
      filterMethod.then(res => {
        if (res['data'] && res['data'].length > 0) {
          this.transferRequestList = res['data'];
          this.showAllCheckbox = this.transferRequestList.some(e=>e.status == this.offerStatus.accepted);
          this.transferRequestList.map((item:any)=>{
            if(item.isPit){
              item.operateToShow = "Yes"
            }
            else{
              item.operateToShow = "No";
            }
            if (item.status ===this.offerStatus.accepted){
              item.offerStatusStr = "Pending Process"
            }
          })
          this.totalItems = res['recordsFiltered'];
        } else {
          this.totalItems = 0;
        }
      }, err => { this.transferRequestList = []; this.totalItems = 0; });
    }
    
    emptySumm() {
      return null;
    }
    onSelect({ selected }) {
      selected = selected.filter((e)=> {
        return e.status == 7;
      });
      console.log(selected);
      this.selected.splice(0, this.selected.length);
      this.selected.push(...selected);
      this.selectedRecords = this.selected.map(x => x.offerId);
    }
    downloadFile() {
      const downloadMethod = this.requestType !== 'approvedOffer' ?
      this.tranferService.downloadPendingExcelFile(this.searchSort) :
      this.tranferService.downloadClosedExcelFile(this.searchSort);
      downloadMethod.then(response => {
        if (response['Success']) {
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.href = environment.BLOB_URL + response['Message'];
          a.download = response['Message'].split('/')[1];
          a.click();
        } else {
          this.utilityService.showErrorToast(response['Message']);
        }
      }, err => { });;
    }
    
    Process(){
      const postMethod = this.tranferService.TransferProcessOffer(this.selectedRecords)
      postMethod.then(response => {
        if (response['Success']) {
          this.utilityService.showSuccessToast(Constants.TRANSFER_REQUEST_PROCESS_MSG)
          this.setPage({ offset: 0 });
        } else {
          this.utilityService.showErrorToast(response['Message']);
        }
      }, err => { });
    }
  }
  
  