import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Status, Constants } from 'src/app/constant/constants';
import { Invoice } from 'src/app/models/invoice.model';
import { FormGroup, FormControl } from '@angular/forms';
import { InvoiceService } from 'src/app/service/invoice.service';
import { UtilityService } from 'src/app/service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoice-page',
  templateUrl: './invoice-page.component.html',
  styleUrls: ['./invoice-page.component.scss']
})
export class InvoicePageComponent implements OnInit {
  @Input() invoice: Invoice;
  @Output() close = new EventEmitter<boolean>();
  Status = Status;
  invoiceForm: FormGroup;
  swapTier1Total = 0;
  swapTier2Total = 0;
  votTier1Total = 0;
  votTier2Total = 0;
  transferTier1Total = 0;
  transferTier2Total = 0;
  trainingTier1Total = 0;
  trainingTier2Total = 0;
  vtoTier1Total = 0;
  vtoTier2Total = 0;
  callInTier1Total = 0;
  callInTier2Total = 0;
  earlyOutTier1Total = 0;
  earlyOutTier2Total = 0;
  flexWorkTier1Total = 0;
  flexWorkTier2Total = 0;
  swapDetail: any;
  votDetail: any;
  traningDetail: any;
  transferDetail: any;
  vtoDetail: any;
  callInDetail: any;
  earlyOutDetail: any;
  flexWorkDetail: any;

  appName = environment.APP_NAME;
  constructor(private invoiceService: InvoiceService, private utilityService: UtilityService) { }

  ngOnInit() {
    this.appName = localStorage.getItem(Constants.APP_NAME);
    this.initializeInvoiceForm();
    if (!!this.invoice['invoiceSwapDetail'] && this.invoice['invoiceSwapDetail'].length > 0) {
      this.swapDetail = this.invoice['invoiceSwapDetail'].find(x => x.SubscriptionType === 1);
      if (!!this.swapDetail) {
        this.swapTier1Total = (this.swapDetail['costForFirstUsers']) * (this.swapDetail['firstUsers']);
        this.swapTier2Total = (this.swapDetail['costForRestUsers']) * (this.swapDetail['restUsers']);
      }
      this.votDetail = this.invoice['invoiceSwapDetail'].find(x => x.SubscriptionType === 2);
      if (!!this.votDetail) {
        this.votTier1Total = (this.votDetail['costForFirstUsers']) * (this.votDetail['firstUsers']);
        this.votTier2Total = (this.votDetail['costForRestUsers']) * (this.votDetail['restUsers']);
      }
      this.transferDetail = this.invoice['invoiceSwapDetail'].find(x => x.SubscriptionType === 3);
      
      if (!!this.transferDetail) {
        this.transferTier1Total = (this.transferDetail['costForFirstUsers']) * (this.transferDetail['firstUsers']);
        this.transferTier2Total = (this.transferDetail['costForRestUsers']) * (this.transferDetail['restUsers']);
      }
      this.traningDetail = this.invoice['invoiceSwapDetail'].find(x => x.SubscriptionType === 4);
      if (!!this.traningDetail) {
        this.trainingTier1Total = (this.traningDetail['costForFirstUsers']) * (this.traningDetail['firstUsers']);
        this.trainingTier2Total = (this.traningDetail['costForRestUsers']) * (this.traningDetail['restUsers']);
      }
      this.vtoDetail = this.invoice['invoiceSwapDetail'].find(x => x.SubscriptionType === 5);
      if (!!this.vtoDetail) {
        this.vtoTier1Total = (this.vtoDetail['costForFirstUsers']) * (this.vtoDetail['firstUsers']);
        this.vtoTier2Total = (this.vtoDetail['costForRestUsers']) * (this.vtoDetail['restUsers']);
      }
      this.callInDetail = this.invoice['invoiceSwapDetail'].find(x => x.SubscriptionType === 6);
      if (!!this.callInDetail) {
        this.callInTier1Total = (this.callInDetail['costForFirstUsers']) * (this.callInDetail['firstUsers']);
        this.callInTier2Total = (this.callInDetail['costForRestUsers']) * (this.callInDetail['restUsers']);
      }
      this.earlyOutDetail = this.invoice['invoiceSwapDetail'].find(x => x.SubscriptionType === 7);
      if (!!this.earlyOutDetail) {
        this.earlyOutTier1Total = (this.earlyOutDetail['costForFirstUsers']) * (this.earlyOutDetail['firstUsers']);
        this.earlyOutTier2Total = (this.earlyOutDetail['costForRestUsers']) * (this.earlyOutDetail['restUsers']);
      }
      this.flexWorkDetail = this.invoice['invoiceSwapDetail'].find(x => x.SubscriptionType === 8);
      if (!!this.flexWorkDetail) {
        this.flexWorkTier1Total = (this.flexWorkDetail['costForFirstUsers']) * (this.flexWorkDetail['firstUsers']);
        this.flexWorkTier2Total = (this.flexWorkDetail['costForRestUsers']) * (this.flexWorkDetail['restUsers']);
      }
    }
  }

  cancel() {
    this.close.emit(false);
  }

  initializeInvoiceForm() {
    this.invoiceForm = new FormGroup({
      invoiceId: new FormControl(!!this.invoice ? this.invoice.invoiceId : 0),
      invoiceNumber: new FormControl(!!this.invoice ? this.invoice.invoiceNumber : ''),
      invoiceDate: new FormControl(!!this.invoice ? this.invoice.invoiceDate : ''),
      amount: new FormControl(!!this.invoice ? this.invoice.amount : 0),
      paidOnDate: new FormControl(!!this.invoice ? this.invoice.paidOnDate : ''),
      status: new FormControl(!!this.invoice ? this.invoice.status : 0),
      invoiceSwapDetailId: new FormControl(!!this.invoice ? this.invoice.invoiceSwapDetailId : 0),
      companyId: new FormControl(!!this.invoice ? this.invoice.companyId : ''),
      isFirstInvoice: new FormControl(!!this.invoice ? this.invoice['isFirstInvoice'] : false)
    });
  }

  onSubmit() {
    if (this.invoiceForm.controls.status.value === "1") {
      this.invoiceForm.controls.status.setValue(Number(this.invoiceForm.controls.status.value));
      this.invoiceForm.controls.paidOnDate.setValue(this.invoiceForm.controls.status.value === 1 ? new Date() : null);
      this.invoiceService.updateInvoice(this.invoiceForm.value).then(res => {
        if (res['Success']) {
          this.utilityService.showSuccessToast(Constants.INVOICE_PAIDED_SUCCESS_MSG);
          this.close.emit(true);
        } else { this.utilityService.showErrorToast(res['Message']); }
      }, err => { });
    } else {
      this.utilityService.showErrorToast(Constants.INVOICE_DUE_MSG);
    }
  }
}
