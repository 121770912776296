import { Component, OnInit,TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constants } from '../constant/constants';
import { MarketingCampaignOptionList } from './../constant/constants';
import { FormService } from './../core/form.service';
import { MarketCampaignService } from './../service/marketCampaign.service';
import { phoneMask } from 'src/app/constant/constants';
import { UtilityService } from './../service/utility.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-marketing-campaign',
  templateUrl: './marketing-campaign.component.html',
  styleUrls: ['./marketing-campaign.component.scss']
})
export class MarketingCampaignComponent implements OnInit {
  public title:any;
  public data:any;
  public CampaignForm: FormGroup;
  logoUrl: string;
  marketCampaignOptionList = MarketingCampaignOptionList;
  IsOptionOne: boolean = false;
  IsOptionTwo: boolean = false;
  messageList: any = [];
  phoneMask = phoneMask;
  modalRef: BsModalRef;

  constructor(private formService: FormService,
    private MarketCampaignService: MarketCampaignService,
    private utilityService : UtilityService,
    private router : Router,
    private modalService: BsModalService
    ) { }

  ngOnInit() {
    this.logoUrl = localStorage.getItem(Constants.LOGO_PATH);
    document.documentElement.style.setProperty('--bgImg', `url(${localStorage.getItem(Constants.LOGIN_URL)})`);
    this.CampaignForm = new FormGroup({
      Name: new FormControl('', [Validators.required]),
      Number: new FormControl('', [Validators.required]),
      SelectedOption: new FormControl('', [Validators.required]),
      CompanyName: new FormControl(''),
      ContactPersonName: new FormControl(''),
      ContactPersonNumber: new FormControl(''),
      ContactPersonEmail: new FormControl(''),

    });
    this.initializeMessages();
    this.utilityService.hideLoading();
  }
  initializeMessages() {
    this.messageList.yourName = {
      required: Constants.VALIDATION_MSG.MARKET_CAMPAIGN.NAME
    };
    this.messageList.mobileNumber = {
      required: Constants.VALIDATION_MSG.MARKET_CAMPAIGN.NUMBER
    };
    this.messageList.option = {
      required: Constants.VALIDATION_MSG.MARKET_CAMPAIGN.OPTION,
    };
    this.messageList.yourCompanyName = {
      required: Constants.VALIDATION_MSG.MARKET_CAMPAIGN.COMPANYNAME,
    };
    this.messageList.contactPersonName = {
      required: Constants.VALIDATION_MSG.MARKET_CAMPAIGN.CONTACT_PERSON_NAME,
    };
    this.messageList.contactPersonNumber = {
      required: Constants.VALIDATION_MSG.MARKET_CAMPAIGN.CONTACT_PERSON_NUMBER,
    };
    this.messageList.contactPersonEmail = {
      required: Constants.VALIDATION_MSG.MARKET_CAMPAIGN.CONTACT_PERSON_EMAIL,
    };
  }
  optionChange(value) {
    if (parseInt(value) === 1) {
      this.IsOptionOne = true;
      this.IsOptionTwo = false;
      this.CampaignForm.controls.CompanyName.setValidators([Validators.required]);
      this.CampaignForm.controls.ContactPersonName.setValidators([]);
      this.CampaignForm.controls.ContactPersonNumber.setValidators([]);
      this.CampaignForm.controls.ContactPersonEmail.setValidators([]);
    }
    else if (parseInt(value) === 2) {
      this.IsOptionTwo = true;
      this.IsOptionOne = false;
      this.CampaignForm.controls.CompanyName.setValidators([Validators.required]);
      this.CampaignForm.controls.ContactPersonName.setValidators([Validators.required]);
      this.CampaignForm.controls.ContactPersonNumber.setValidators([Validators.required]);
      this.CampaignForm.controls.ContactPersonEmail.setValidators([Validators.required]);

      // this.offerForm.controls.OtherReason.setValidators(null);
      // this.offerForm.controls.OtherReason.updateValueAndValidity();
    }
  }
  submit() {
    this.formService.markFormGroupTouched(this.CampaignForm);
    if (this.CampaignForm.invalid) {
      return;
    }
    this.MarketCampaignService.add(this.CampaignForm.value).then(res => {
      if (res['Success']) {
        if (this.CampaignForm.controls.SelectedOption.value === "1") {
          this.utilityService.showSuccessToast("Thank you for interest in CoverMyWork. We will be in touch shortly.")
        }
        this.router.navigate([''], { queryParams: { company: '' } });
      } else {
        this.utilityService.showErrorToast(res['Message']);
      }
    }, err => { })
  }

  public openFinePrint(template: TemplateRef<any>){
    this.title = Constants.FINE_PRINT;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  checkAgreeDisagree(event) {
    this.modalRef.hide();
  }
}
