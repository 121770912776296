import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Constants, trainingStatus } from "src/app/constant/constants";
import { FormService } from "src/app/core/form.service";
import { TrainingTypeEnum } from "src/app/models/role-model";
import { TrainingService } from "./../../service/training.service";
import { ToastrService } from "ngx-toastr";
import { ModuleFunctionService } from "src/app/service/moduleFunction.service";
import { NgxFileDropEntry } from "ngx-file-drop";
import { UtilityService } from "src/app/service/utility.service";
import { FileToUpload } from "src/app/models/fileUpload";

@Component({
  selector: "app-add-edit-module-training",
  templateUrl: "./add-edit-module-training.component.html",
  styleUrls: ["./add-edit-module-training.component.scss"],
})
export class AddEditModuleTrainingComponent implements OnInit {
  @Input() moduleTraining: any;
  @Output() closeRequest = new EventEmitter<boolean>();
  public files: NgxFileDropEntry[] = [];
  moduleList = [];
  public trainingForm: FormGroup;
  messageList: any = {
    UserName: String,
    moduleID: String,
    trainingDate: Date,
    TrainerName: String,
    Status: String,
  };
  statusList = [];
  theFile: any = null;
  theFiles: any[] = [];
  FileName: String;
  fileObj: any=[];
  constructor(
    private formService: FormService,
    private trainingService: TrainingService,
    private toaster: ToastrService,
    private moduleFunctionService: ModuleFunctionService,
    private utility: UtilityService,
  ) { }

  ngOnInit() {
    this.statusList = trainingStatus;
    this.initializeForm();
    this.initializeMessages();
    this.getModuleList();
  }
  initializeForm() {
    this.trainingForm = new FormGroup({
      trainingId: new FormControl(
        !!this.moduleTraining ? this.moduleTraining.trainingId : 0
      ),
      Username: new FormControl(
        !!this.moduleTraining ? this.moduleTraining.userName : "",
        Validators.required
      ),
      moduleID: new FormControl(
        !!this.moduleTraining ? this.moduleTraining.moduleID : "",
        Validators.required
      ),
      trainingDate: new FormControl(
        !!this.moduleTraining ? new Date(this.moduleTraining.trainingDate) : "",
        Validators.required
      ),
      TrainerName: new FormControl(
        !!this.moduleTraining ? this.moduleTraining.trainerName : "",
        Validators.required
      ),
      Status: new FormControl(
        !!this.moduleTraining ? this.moduleTraining.status : "",
        Validators.required
      ),
      trainingType: new FormControl(TrainingTypeEnum.module),
      isActive: new FormControl(true),
      recordedByID: new FormControl(
        !!this.moduleTraining ? this.moduleTraining.recordedByID : ""
      ),
      statusChangedDate: new FormControl(
        !!this.moduleTraining ? this.moduleTraining.statusChangedDate : ""
      ),
      createdDate: new FormControl(
        !!this.moduleTraining ? this.moduleTraining.createdDate : ""
      ),
      FileModels:new FormControl(),
      FileIds:new FormControl(!!this.moduleTraining ? this.moduleTraining.FileIds : "")
      
    });
    if(!!this.moduleTraining && this.moduleTraining.moduleID > 0 && this.moduleTraining.FileModels.length >0){
      this.fileObj = this.moduleTraining.FileModels.map(x=>{
        return {
          FileName : x.OriginalFileName,
          Path : x.Path
        }
      })
    }
    // var a = document.getElementById('aa');
    // a.innerHTML = "";
    // var filelbl = document.getElementById('fileLabel'); 
    // filelbl.innerHTML = !!this.moduleTraining ? !!this.moduleTraining.FileName ?  this.moduleTraining.FileName : "Select File" : "Select File";
  }
  initializeMessages() {
    this.messageList.Username = {
      required: Constants.VALIDATION_MSG.Module_Traning.USERNAME,
    };
    this.messageList.moduleID = {
      required: Constants.VALIDATION_MSG.Module_Traning.MODULE,
    };
    this.messageList.trainingDate = {
      required: Constants.VALIDATION_MSG.Module_Traning.TRAINED_DATE,
    };
    this.messageList.TrainerName = {
      required: Constants.VALIDATION_MSG.Module_Traning.TRAINER,
    };
    this.messageList.Status = {
      required: Constants.VALIDATION_MSG.Module_Traning.STATUS,
    };
  }
  getModuleList() {
    this.moduleFunctionService.getModuleConfigList(null).then(
      (res: any) => {
        if (res["Success"]) {
          this.moduleList = res.Data;
        } else {
          this.moduleList = [];
        }
      },
      (err) => {
        this.moduleList = [];
      }
    );
  }
  cancel() {
    this.closeRequest.emit(false);
  }
  value(controlName: string) {
    return this.control(controlName).value;
  }
  control(controlName: string): AbstractControl {
    return this.trainingForm.get(controlName);
  }
  onSubmit() {
    this.formService.markFormGroupTouched(this.trainingForm);
    if (this.trainingForm.invalid) {
      return;
    }
    if (
      this.trainingForm.controls.Username.value ==
      this.trainingForm.controls.TrainerName.value
    ) {
      this.toaster.error("Users can not train themselves");
      return;
    }
    this.trainingForm.controls.FileModels.setValue(this.fileObj);
    const saveMethod =
      this.trainingForm.controls.trainingId.value > 0
        ? this.trainingService.updateTraining(this.trainingForm.value)
        : this.trainingService.addTraining(this.trainingForm.value);
    saveMethod.then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(
            this.trainingForm.controls.trainingId.value > 0
              ? Constants.TRAINING_UPDATE_SUCCESS_MSG
              : Constants.TRAINING_ADD_SUCCESS_MSG
          );
          this.closeRequest.emit(true);
        } else {
          this.toaster.error(res["Message"]);
        }
      },
      (err) => {
        this.closeRequest.emit(false);
      }
    );
  }
  private readAndUploadFile(theFile: any) {
    let file = new FileToUpload();

    // Set File Information
    file.fileName = theFile.name;
    file.fileSize = theFile.size;
    file.fileType = theFile.type;
    file.lastModifiedTime = theFile.lastModified;
    file.lastModifiedDate = theFile.lastModifiedDate;

    // Use FileReader() object to get file to upload
    // NOTE: FileReader only works with newer browsers
    let reader = new FileReader();

    // Setup onload event for reader
    reader.onload = () => {
      // Store base64 encoded representation of file
      file.fileAsBase64 = reader.result.toString();
      this.fileObj.push({
        FileName : file.fileName,
        Base64StringFile: file.fileAsBase64
      })
    }

    // Read the file
    reader.readAsDataURL(theFile);
  }

  onFileChange(event) {
    this.theFiles = [];
    let file = event.target.files[0];
    let fileSizeMB = (file.size / 1024) / 1024;
    let fileName = file.name.toLowerCase().split(".")
    if (fileName[fileName.length - 1] != "pdf" && fileName[fileName.length - 1] != "xls"
    && fileName[fileName.length - 1] != "xlsx" && fileName[fileName.length - 1] != "doc"
    &&  fileName[fileName.length - 1] != "docx" ) {
      this.utility.showErrorToast("This file is not in valid format");
      return;
    } else if(fileSizeMB>5){
      this.utility.showErrorToast("Selected file size must be less than 5 MB");
      return;
    }else {
      this.theFiles.push(file);
      this.readAndUploadFile(this.theFiles[0]);
    }
  }
  removeFile(index: number) {
    this.fileObj.splice(index, 1);
  }
}
