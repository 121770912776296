import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Constants, OfferTypesEnum, SettingType, SubscriptionType, timeList } from 'src/app/constant/constants';
import { Offer } from 'src/app/models/offer.model';
import { OfferStatus, Role } from 'src/app/models/role-model';
import { CallInRequestService } from 'src/app/service/callInRequest.service';
import { LateInService } from 'src/app/service/lateIn.service';
import { UtilityService } from 'src/app/service/utility.service';
import { commonHelper } from 'src/app/shared/Helpers/commonHelper';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { DepartmentService } from 'src/app/service/department.service';
import { ShiftService } from 'src/app/service/shift.service';

@Component({
  selector: 'app-employer-late-in-request',
  templateUrl: './employer-late-in-request.component.html',
  styleUrls: ['./employer-late-in-request.component.scss']
})
export class EmployerLateInRequestComponent implements OnInit {
  @Output() reloadCount = new EventEmitter<boolean>();
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  @ViewChild('workDateValue', { static: false }) workDateValue;
  @ViewChild('shiftValue', { static: false }) shiftValue;
  @ViewChild('departmentValue', { static: false }) departmentValue;
  @ViewChild('timeValue', { static: false }) timeValue;
  @Input() requestType: string;
  offerId: number;
  confirmMsg = '';
  approveMsg = '';
  confirmCancelBtn = '';
  confirmSaveBtn = '';
  isApprove = false;
  isReject = false;
  isConfirmed = false;
  requestedOffer: any;
  requestedOfferList = [];
  lateInRequestList = [];
  searchSort: any;
  Offer: Offer;
  modalRef: BsModalRef;
  // public earlyGoRequestList: any = [];
  public totalItems: any;
  page: any;
  offerStatus = OfferStatus;
  role: number;
  roleEnum = Role;
  selected = [];
  moduleId: number;
  public lateInForm: FormGroup;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  isPaidTimeOff: boolean;
  IsCoverMyWork: boolean;
  timeList = timeList;
  shiftList = [];
  departmentList = [];
  selectedRecords = [];
  filterValue = [];
  commonShiftList = [];
  columns = [];
  totalApprovedHours = 0;
  totalDeniedHours = 0;
  totalRequestedHours = 0;
  companyId: number;
  searchDate: any;
  nextDate: any;

  constructor(private modalService: BsModalService,
    private lateInService: LateInService,
    private shiftService: ShiftService,
    private departmentService: DepartmentService,
    private utilityService: UtilityService,
    private callInRequstService: CallInRequestService,
    private toaster: ToastrService,
    private datepipe: DatePipe,) {
    this.columns = [
      'departmentId', 'dateToSkip', 'shiftToSkip', 'createdByUsername', 'createdShortDate', 'createdByObj.shift.title', 'departmentName', 'department.departmentName', 'subDepartmentName', 'shiftToWorkTitle', 'createdDate', 'isPit', 'operateToShow', 'preferenceId', 'dateToWorkDateStr', 'createdByShift'
    ];

    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [
      ],
      Search: {
        Value: '',
        ColumnNameList: [],
        Regex: 'string'
      },
      Order: [{
        Column: 0,
        ColumnName: '',
        Dir: 'asc'
      }]
    }
  }

  ngOnInit() {
    this.isConfirmed = false;
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.IsCoverMyWork = localStorage.getItem(Constants.APP_NAME) === "CoverMyWork" ? true : false;
    this.setPage({ offset: 0 });
    this.getDepartmentList();
    this.getShiftList();
  }

  // ngAfterViewInit() {

  //   // this.workDateValue.nativeElement.value = this.nextDate;
  //   this.filterValue.push('');
  //   this.filterValue.push(this.shiftValue.nativeElement.value);
  //   this.filterValue.push(this.departmentValue.nativeElement.value);
  //   this.columns.forEach((element, i) => {
  //     if (i < 3) {
  //       const obj = {
  //         Data: 'string',
  //         Searchable: true,
  //         Orderable: true,
  //         Name: element,
  //         Search: {
  //           Value: this.filterValue[i],
  //           Regex: 'string'
  //         }
  //       }
  //       this.searchSort.Columns.push(obj);
  //     }
  //   });
  // }
  ngAfterViewInit() {
    this.filterValue.push(this.workDateValue.nativeElement.value);
    this.filterValue.push(this.shiftValue.nativeElement.value);
    this.filterValue.push(this.departmentValue.nativeElement.value);
    // this.filterValue.push(this.timeValue.nativeElement.value);
    this.columns.forEach((element, i) => {
      if (i < 4) {
        const obj = {
          Data: 'string',
          Searchable: true,
          Orderable: true,
          Name: element,
          Search: {
            Value: this.filterValue[i],
            Regex: 'string'
          }
        }
        this.searchSort.Columns.push(obj);
      }
    });
  }

  async setPage(pageInfo,isReloadCount = false) {
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
    await this.getSettingByCompanyIDAsync();
    await this.getLateInOfferListAsync(this.page.pageNumber + 1);
    if(isReloadCount){
      this.reloadCount.emit();
    }
    // this.getRequestedOfferList(this.page.pageNumber + 1)
  }

  getDepartmentList() {
    this.departmentService.getDepartmentListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID))).then(res => {
      if (res['Success']) {
        this.departmentList = res['Data'];
      } else { this.departmentList = []; }
    }, err => { this.departmentList = []; });
  }

  getShiftList() {
    this.shiftService.getShiftListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID)))
      .then(response => {
        this.shiftList = response['Data'];
        this.commonShiftList = _.merge([], this.shiftList)
      }, err => { this.shiftList = []; });
  }


  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  async getSettingByCompanyIDAsync() {
    let module = SubscriptionType.filter((item) => {
      return item.id === OfferTypesEnum.EarlyOutModule;
    });
    this.moduleId = module[0].id;
    await this.callInRequstService.getSettingByCompanyID(this.moduleId, Number(localStorage.getItem(Constants.COMPANYID))).then((res: any) => {
      if (res['Success']) {
        if (res.Data.length > 0) {
          res.Data.map((item) => {
            if (item.SettingType === SettingType[0].value) {
              item.Name = "Paid Time Off";
              if (item.Enable == true) {
                this.isPaidTimeOff = true;
              }
              else {
                this.isPaidTimeOff = false;
              }
            }
          });
        }
      } else { }
    }, err => { });

  }

  async getLateInOfferListAsync(currentPage) {
    if ((!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) ||
      (!!this.shiftValue && !!this.shiftValue.nativeElement.value) ||
      (!!this.departmentValue && !!this.departmentValue.nativeElement.value) ||
      (!!this.workDateValue && this.workDateValue.nativeElement.value)) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod = this.requestType === Constants.REQUESTED_OFFER ?
        this.lateInService.getLateInPendingHrRequestList(currentPage, this.rowsOnPage)
        : this.lateInService.getLateInClosedHrRequestList(currentPage, this.rowsOnPage);
     await getMethod.then((res: any) => {
        if (JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))) {
          this.utilityService.showLoading();
        }
        if (res['Success']) {
          if (res.Data && res.Data.results.length > 0) {
            this.lateInRequestList = res.Data.results
            this.totalItems = res.Data.totalNumberOfRecords;
            // this.totalRequestedHours = this.role == this.roleEnum.manager ? res.Data.totalHours.totalHoursApproved : 0;
          } else {
            this.lateInRequestList = [];
            this.totalItems = 0;
          }
        } else {
          this.lateInRequestList = [];
          this.totalItems = 0;
        }
      }, err => {
        this.lateInRequestList = [];
        this.totalItems = 0;
      });
    }
  }

  clearText() {
    this.searchSort.Search.Value = '';
    this.filterTextValue.nativeElement.value = '';
    this.setPage({ offset: 0 });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 },event);
    }
    this.modalRef.hide();
  }

  close(event) {
    this.isConfirmed = false;
    this.isReject = false;
  }

  // Denied(type, data) {
  //   this.isConfirmed = true;
  //   this.isReject = true;
  //   this.confirmSaveBtn = Constants.YES;
  //   this.confirmCancelBtn = Constants.NO;
  //   this.confirmMsg = Constants.REJECT_MSG;
  //   this.offerId = data.offerId;
  // }

  Denied(data) {
    this.isReject = true;
    this.offerId = data.offerId;
    this.confirmSaveBtn = Constants.YES;
    this.confirmCancelBtn = Constants.NO;
    this.confirmMsg = 'Are you sure you want to reject this request?';
    // this.offerId = data.offerId;
  }

  openApprove(data) {
    this.offerId = data.offerId
    this.isConfirmed = true;
    this.approveMsg = 'Are you sure you want to approve this request?'
  }

  openApproveProcess(template: TemplateRef<any>, data: any) {
    if (data) {
      this.Offer = data;
    } else {
      this.Offer = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }
  // ApproveReject(id) {
  //   this.isReject = false;
  //   this.lateInService.rejectManagerLateInOffers(id).then(res => {
  //     if (res['Success']) {
  //       this.toaster.success(Constants.OFFER_REJECT_SUCCESS_MSG);
  //       this.setPage({ offset: 0 });
  //     } else {
  //       this.toaster.error(res['Message']);
  //     }
  //   }, err => { });
  // }

  approve(id) {
    this.isConfirmed = false;
    this.lateInService.approveManagerLateInOffers(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.OFFER_APPROVE_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { });

  }
  reject(id) {
    this.isReject = false;
    this.lateInService.rejectManagerLateInOffers(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.OFFER_REJECT_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { });
  }



  downloadFile() {
    const downloadMethod = this.requestType !== 'approvedOffer' ?
      this.lateInService.downloadPendingExcelFile(this.searchSort) :
      this.lateInService.downloadClosedExcelFile(this.searchSort);
    downloadMethod.then(response => {
      if (response['Success']) {
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.href = environment.BLOB_URL + response['Message'];
        a.download = response['Message'].split('/')[1];
        a.click();
      } else {
        this.utilityService.showErrorToast(response['Message']);
      }
    }, err => { });;
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clearText();
      }
    }
  }

  workDateChange(event) {
    this.searchDate = null;
    const findDateColumn = this.searchSort.Columns.find(x => x.Name === 'dateToSkip');
    if (!!findDateColumn) {
      findDateColumn.Search.Value = this.datepipe.transform(this.setnewDate(event), 'yyyy-MM-dd');
      this.searchDate = this.datepipe.transform(this.setnewDate(event), 'yyyy-MM-dd');
    }
  }

  setnewDate(date) {
    const dateObj = {
      year: +(this.datepipe.transform(date, 'yyyy')),
      month: +(this.datepipe.transform(date, 'MM')),
      day: +(this.datepipe.transform(date, 'dd'))
    };
    return new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day']);
  }

  departmentChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(x => x.Name === 'shiftToWork');
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = '';
    }
    if (event.currentTarget.value)
      this.shiftList = commonHelper.filterShiftListFromDepartmentId(event.currentTarget.value, this.commonShiftList);
    else
      this.shiftList = this.commonShiftList
    const findDepartmentColumn = this.searchSort.Columns.find(x => x.Name === 'departmentId');
    if (!!findDepartmentColumn) {
      findDepartmentColumn.Search.Value = event.currentTarget.value;
    }
  }

  shiftChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(x => x.Name === 'shiftToSkip');
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = event.currentTarget.value;
    }
    console.log(findShiftColumn);
  }

  timeChange(event) {
    const findTimeColumn = this.searchSort.Columns.find(x => x.Name === 'timeType');
    if (!!findTimeColumn) {
      findTimeColumn.Search.Value = event.currentTarget.value;
    }
  }

  // getRequestedOfferList(currentPage) {
  //   if ((!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) ||
  //     (!!this.shiftValue && !!this.shiftValue.nativeElement.value) ||
  //     (!!this.departmentValue && !!this.departmentValue.nativeElement.value) ||
  //     (!!this.workDateValue && this.workDateValue.nativeElement.value)) {
  //     this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
  //     this.searchSort.Search.ColumnNameList = this.columns;
  //     this.searchSort.Page = currentPage;
  //     this.filterData();
  //   } else {
  //     const getMethod = this.requestType === Constants.REQUESTED_OFFER ?
  //       this.callInRequestService.getCallOffReqestedList(currentPage, this.rowsOnPage)
  //       : this.callInRequestService.getCallOffClosedList(currentPage, this.rowsOnPage);
  //     getMethod.then((res: any) => {
  //       if(JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))){
  //         this.utilityService.showLoading();
  //       }
  //       if (res['Success']) {
  //         if (!!res.Data.pagedResults && res.Data.pagedResults.results.length > 0) {
  //           this.requestedOfferList = res.Data.pagedResults.results;
  //           this.totalItems = res.Data.pagedResults.totalNumberOfRecords;
  //           this.totalRequestedHours = res.Data.totalHours.totalHoursApproved;
  //         } else {
  //           this.requestedOfferList = [];
  //           this.totalItems = 0;
  //         }
  //       } else {
  //         this.requestedOfferList = [];
  //         this.totalItems = 0;
  //       }
  //     }, err => {
  //       this.requestedOfferList = [];
  //       this.totalItems = 0;
  //     });
  //   }
  // }
  filterData() {
    console.log(this.searchSort)
    const filterMethod = this.requestType === Constants.REQUESTED_OFFER ? this.lateInService.hrPendingLateInOfferDataFilter(this.searchSort)
      : this.lateInService.hrClosedLateInOfferDataFilter(this.searchSort);
    filterMethod.then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.lateInRequestList = res['data'];
        this.totalItems = res['recordsFiltered'];
      } else {
        this.totalItems = 0;
      }
    }, err => { this.lateInRequestList = []; this.totalItems = 0; });
  }

  clear() {
    if (this.role === this.roleEnum.user) {
      this.searchSort.Search.Value = '';
      this.searchSort.Order[0].ColumnName = '';
      this.searchSort.Order[0].Dir = 'asc';
      this.filterTextValue.nativeElement.value = '';
      this.setPage({ offset: 0 });
    } else {
      this.searchSort.Search.Value = '';
      this.searchSort.Order[0].ColumnName = '';
      this.searchSort.Order[0].Dir = 'asc';
      this.filterTextValue.nativeElement.value = '';
      this.searchSort.Columns.forEach(element => {
        element.Search.Value = '';
      });
      this.shiftValue.nativeElement.value = '';
      this.departmentValue.nativeElement.value = '';
      // this.workDateValue.nativeElement.value = this.nextDate;
      this.workDateValue.nativeElement.value = '';
      this.searchDate = this.nextDate;
      this.setPage({ offset: 0 });
    }
  }
}
