import { Component, EventEmitter, Input, NgZone, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Constants,OfferTypesEnum,SettingType,SubscriptionType } from 'src/app/constant/constants';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { FormService } from 'src/app/core/form.service';
import { CallInRequestService } from './../../service/callInRequest.service';
import { Offer } from 'src/app/models/offer.model';
import { UtilityService } from 'src/app/service/utility.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SettingsService } from 'src/app/service/settings.service';

import { Content } from '@angular/compiler/src/render3/r3_ast';
import { TermsConditionService } from 'src/app/service/terms-condition.service';

@Component({
  selector: "app-add-edit-call-in-request",
  templateUrl: "./add-edit-call-in-request.component.html",
  styleUrls: ["./add-edit-call-in-request.component.scss"],
  providers: [DatePipe],
})
export class AddEditCallInRequestComponent implements OnInit {
  public isPaidTimeOff: boolean = false;
  public paidTimeOff: any = {};
  public flma: any = {};
  public settingList: any = [];
  public moduleId: any;
  public message: any;
  @ViewChild("confirmationPopup", { static: false })
  confirmationPopup: TemplateRef<any>;
  @ViewChild("awsomePopUp", { static: false }) awsomePopUp: TemplateRef<any>;

  @Input() offer: Offer;
  @Output() closeRequest = new EventEmitter<boolean>();
  @Output() showSwap = new EventEmitter(false);
  reasonList = [];
  otherReason = false;
  public offerForm: FormGroup;
  messageList: any = new Offer();
  CallInOn: string;
  CallInFor: string;
  OtherReason: string;
  companyId: number;
  currentPage: number = 1;
  departmentId: number;
  shiftToSkip: number;
  confirmMsg: string;
  count: any;
  today = new Date();
  settingData: any;
  tomorrow = new Date(this.today);
  isSwapRequest: boolean = false;
  swapRequestMsg: string;
  modalRef: BsModalRef;
  tryHalfDay: boolean = false;
  tryHalfDayMsg: string;
  cancleCallOffMsg: string = "system successfully prevented a call off.";
  paidWarning = true;
  IsCoverMyWork: boolean = false;
  public FMLAWarning: boolean = false;
  public isConfirmWarning: boolean = false;
  subscriptionType = SubscriptionType;
  offerTypeEnum = OfferTypesEnum;
  msgSettingList = SettingType;
  settingTypeEnum = SettingType;
  utoWarningMsg = "";

  constructor(
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private toaster: ToastrService,
    private formService: FormService,
    private utility: UtilityService,
    private callInRequstService: CallInRequestService,
    private ngZone: NgZone,
    private settingsService: SettingsService,
    private termsAndConditionService: TermsConditionService
  ) {
    this.IsCoverMyWork =
      localStorage.getItem(Constants.APP_NAME) === "CoverMyWork" ? true : false;
  }

  async ngOnInit() {
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.departmentId = Number(localStorage.getItem(Constants.DEPARTMENTID));
    this.shiftToSkip = Number(localStorage.getItem(Constants.SHIFTID));
    this.isSwapRequest = JSON.parse(
      localStorage.getItem(Constants.IS_SWAP_REQUEST)
    );
     this.initializeCallInForm();
    await this.getReasonList();
    await this.initializeMessages();
    // if (this.paidWarning) {
    //   this.message =
    //     "All missed time will be covered by you Unpaid Time Off(UTO) balance. If you do not have enough PTO balance to cover the entire absence, Paid Time Off(PTO) will be applied to cover the shortage. It is your responsibility to ensure that you have enough UTO+PTO accumulated to cover this absence.";
    // }
    
  }

  getMessage() {
    this.termsAndConditionService
      .getTermsConditionListByCompanyId(this.companyId, null)
      .then(
        (response) => {
          if (response["Success"]) {
            this.settingData = response["Data"].filter(
              (x) => x.typeFieldstr == Constants.CALL_OFF_UTO_MESSAGE
            );
            if (this.settingData.length == 0) {
              this.utoWarningMsg = "";
            } else {
              if (this.settingData[0].length == 0) {
                this.utoWarningMsg = "";
              } else {
                this.utoWarningMsg = this.settingData[0]["description"].replace(
                  /<[^>]*>/g,
                  ""
                );
              }
            }
          } else {
            this.settingData = [];
          }

          this.checkBoxClick();
        },
        (err) => {
          this.settingData = [];
          this.checkBoxClick();
        }
      );
  }

  initializeCallInForm() {
    this.offerForm = new FormGroup({
      offerId: new FormControl(!!this.offer ? this.offer.offerId : 0),
      dateToSkip: new FormControl(
        !!this.offer ? new Date(this.offer.dateToSkip) : new Date(),
        Validators.required
      ),
      status: new FormControl(!!this.offer ? this.offer.status : 5),
      ReasonId: new FormControl(
        !!this.offer ? this.offer.ReasonId : "",
        Validators.required
      ),
      OtherReason: new FormControl(!!this.offer ? this.offer.OtherReason : ""),
      companyId: new FormControl(this.companyId),
      departmentId: new FormControl(this.departmentId),
      offerType: new FormControl(6),
      shiftToSkip: new FormControl(this.shiftToSkip),
      IsHRCallBack: new FormControl(
        !!this.offer ? this.offer.IsHRCallBack : false
      ),
      IsPaidOff: new FormControl(!!this.offer ? this.offer.IsPaidOff : false),
      IsFMLA: new FormControl(!!this.offer ? this.offer.IsFMLA : false),
      createdBy: new FormControl(!!this.offer ? this.offer.createdBy : null),
    });
    if (
      this.offerForm.controls.offerId.value > 0 &&
      this.offerForm.controls.ReasonId.value === 1
    ) {
      this.otherReason = true;
      this.offerForm.controls.OtherReason.setValidators(Validators.required);
    }
  }
  initializeMessages() {
    this.messageList.dateToSkip = {
      required: Constants.VALIDATION_MSG.CALL_IN.CALL_OFF_ON,
    };
    this.messageList.CallOffReason = {
      required: Constants.VALIDATION_MSG.CALL_IN.CALL_OFF_FOR,
    };
    this.messageList.CallOffOtherReason = {
      required: Constants.VALIDATION_MSG.CALL_IN.OTHER_REASON,
    };
  }
  async getSettingByCompanyID() {
    let module = SubscriptionType.filter((item) => {
      return item.value === "Call-Off Module";
    });
    this.moduleId = module[0].id;
    const getMethod = this.callInRequstService.getSettingByCompanyID(
      this.moduleId,
      this.companyId,
      false
    );
   await getMethod.then(
      (res: any) => {
        if (res["Success"]) {
          this.settingList = res.Data;
          if (this.settingList.length > 0) {
            this.settingList.map((item) => {
              if (item.SettingType === "PaidTimeOff") {
                item.Name = "Paid Time Off";
                this.paidTimeOff = item;
                if (this.value("offerId") == 0) {
                  if (this.paidTimeOff.Enable == true) {
                    // this.message = "All missed time will be covered by your Unpaid Time Off(UTO) balance. It is your responsibility to ensure that you have enough UTO accumulated to cover this absence."
                    this.isPaidTimeOff = true;
                    // this.message = "All missed time will be covered by your Unpaid Time Off (UTO) balance. If you do not have enough UTO balance to cover the entire absence, Paid Time Off (PTO) will be applied to cover the shortage. It is your responsibility to ensure that you have enough UTO+PTO accumulated to cover this absence."
                    this.offerForm.controls["IsPaidOff"].setValue(true);
                  } else {
                    this.isPaidTimeOff = false;
                    // this.message = "All missed time will be covered by your Unpaid Time Off (UTO) balance. It is your responsibility to ensure that you have enough UTO accumulated to cover this absence."
                    this.offerForm.controls["IsPaidOff"].setValue(false);
                  }
                }
              } else if (item.SettingType === "FMLA") {
                item.Name = item.SettingType;
                this.flma = item;
              }
              else if(item.SettingType === Constants.CALL_OFF_INTERVENTION){
                let callOfIntervention = item;
                if (this.value("offerId") == 0) {
                  if(callOfIntervention.Enable == true 
                    && item.OfferType == this.offerTypeEnum.CallOffModule){
                    this.isConfirmWarning = true;
                  }
                }
              }
            });
            if (this.value("offerId") > 0) {
              this.editTimeMessage(this.settingList);
            }
          }
        } else {
          this.settingList = [];
        }

        this.getMessage();
      },
      (err) => {
        this.settingList = [];
        this.getMessage();
      }
    );
  }
  async getReasonList() {
   await this.callInRequstService.getReasonsByType(false).then(
      async (response) => {
        if (response["Success"]) {
          this.reasonList = response["Data"];
          await this.getSettingByCompanyID();
        } else {
          this.reasonList = [];
        }
      },
      (err) => {
        this.reasonList = [];
      }
    );
  }

  onSubmit() {
    if (this.otherReason) {
    }
    this.formService.markFormGroupTouched(this.offerForm);
    if (this.offerForm.invalid) {
      return;
    }
    let formDate = this.datePipe.transform(
      this.setnewDate(this.offerForm.controls.dateToSkip.value),
      "yyyy-MM-dd"
    );
    let todaysDate = this.datePipe.transform(this.today, "yyyy-MM-dd");

    this.settingsService.getSettings(null,this.companyId).then(
      (response) => {
        if (response["Success"]) {
          this.settingData = response["Data"];

          let _settingData = response["Data"].filter(
            (x) =>
              x.SettingType == Constants.CALL_OFF_INTERVENTION &&
              x.OfferType == this.offerTypeEnum.CallOffModule
          );
          //Cond 1 : If the setting "Call-off intervention" is ON then intervention messages should come, if not, the request should just get created without any messages.
          if (
            _settingData.length > 0 &&
            _settingData[0]["OfferType"] == this.offerTypeEnum.CallOffModule &&
            _settingData[0]["Enable"] == true
          ) {
            // For future days: if swap module subcribe

            if (formDate > todaysDate) {
              if (localStorage.isSwap == "true") {
                this.isSwapRequest = true;
                this.swapRequestMsg = Constants.CALL_OFF_CONFIRM_DISCLAIMER;
              } else {
                  this.continueProcess();
                }
              }else{
                this.continueProcess();
              }
            } 
            else{
              this.proceedSubmit(true);
            }
            
        } else {
          this.settingData = [];
        }
      },
      (err) => {
        this.settingData = [];
      }
    );

    // if(this.isConfirmWarning == true)
    // {

    // }
    // else{

    //   this.proceedSubmit(true);
    // }
  }

  manageConfirmationModel(event) {
    if (event) {
      this.continueProcess();
    } else {
      this.tryHalfDay = true;
      this.tryHalfDayMsg =
        "Would you like to consider going to work for half day, instead of calling-off? It is recommended to make every effort to preserve as much UTO as you can";
    }
  }

  redirectToSwap(event) {
    var self = this;
    this.ngZone.run(() => {
      // now you are in a zone
      self.cancelValidationPopUp();
      setTimeout(function () {
        self.showSwap.emit(true);
      }, 1000);
    });
  }
  continueProcess() {
    var FormDate = this.offerForm.controls.dateToSkip.value;
    const mxdate = this.datePipe.transform(
      this.setnewDate(FormDate),
      "yyyy-MM-dd"
    );
    delete this.offerForm.controls.termsMsg;
    const dateToSkip = new Date(FormDate);
    const firstday = new Date(dateToSkip.setDate(dateToSkip.getDate() - 30));
    const startdate = this.datePipe.transform(
      this.setnewDate(firstday),
      "yyyy-MM-dd"
    );
    let _settingData = this.settingData.filter(
      (x) =>
        x.SettingType == Constants.UTO_SYSTEM_FOR_ATTENDANCE &&
        x.OfferType == this.offerTypeEnum.CallOffModule
    );

    if (
      _settingData.length > 0 &&
      _settingData[0]["OfferType"] == this.offerTypeEnum.CallOffModule &&
      _settingData[0]["Enable"] == true
    ) {
      //Cond 2 : 2nd sentence should only be added if "UTO system for attendance" setting is ON for the company
      const checkExistingRequests =
        this.callInRequstService.checkMonthlyCallOffRequest(
          startdate,
          mxdate,
          this.offerForm.controls.offerId.value
        );
      checkExistingRequests.then(
        (res) => {
          if (res["Success"]) {
            if (parseInt(res["Message"]) > 0) {
              this.confirmMsg = `You have called off and taken early out for total of ${
                parseInt(res["Message"]) + 1
              } time in last 30 days. Are you sure you want to continue?`;
              this.modalRef = this.modalService.show(this.confirmationPopup, {
                class: "modal-dialog-centered modal-md",
                backdrop: "static",
              });
            } else {
              this.proceedSubmit(true);
            }
          } else {
            this.utility.showErrorToast(res["Message"]);
            this.offerForm.controls.dateToSkip.setValue(new Date(mxdate));
          }
        },
        (err) => {}
      );
    } else {
      this.proceedSubmit(true);
    }
  }

  proceedSubmit(event) {
    if (event) {
      if (this.modalRef !== undefined) {
        this.modalRef.hide();
      }
      this.tryHalfDay = false;
      const mxdate = this.datePipe.transform(
        this.setnewDate(this.offerForm.controls.dateToSkip.value),
        "yyyy-MM-dd"
      );
      this.offerForm.controls.dateToSkip.setValue(mxdate);
      const saveMethod =
        this.offerForm.controls.offerId.value > 0
          ? this.callInRequstService.updateCallOffRequestOffer(
              this.offerForm.value
            )
          : this.callInRequstService.addCallOffRequestOffer(
              this.offerForm.value
            );
      saveMethod.then(
        (res) => {
          if (res["Success"]) {
            this.toaster.success(
              this.offerForm.controls.offerId.value > 0
                ? Constants.CALL_OFF_REQUEST_UPDATE_SUCCESS_MSG
                : Constants.CALL_OFF_REQUEST_ADD_SUCCESS_MSG
            );
            this.closeRequest.emit(true);
          } else {
            this.utility.showErrorToast(res["Message"]);
            this.offerForm.controls.dateToSkip.setValue(new Date(mxdate));
          }
        },
        (err) => {}
      );
    }
  }

  cancel() {
    this.closeRequest.emit(false);
  }
  cancelValidationPopUp() {
    this.callInRequstService.logCallOffRequest(6, this.cancleCallOffMsg);
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    }
    this.isSwapRequest = false;
  }
  control(controlName: string): AbstractControl {
    return this.offerForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }
  reasonChange(value: string) {
    if (parseInt(value) === 1) {
      this.otherReason = true;
      this.offerForm.controls.OtherReason.setValidators(Validators.required);
    } else {
      this.otherReason = false;
      this.offerForm.controls.OtherReason.setValidators(null);
      this.offerForm.controls.OtherReason.updateValueAndValidity();
    }
  }

  setnewDate(date) {
    const dateObj = {
      year: +this.datePipe.transform(date, "yyyy"),
      month: +this.datePipe.transform(date, "MM"),
      day: +this.datePipe.transform(date, "dd"),
    };
    return new Date(dateObj["year"], dateObj["month"] - 1, dateObj["day"]);
  }

  proceedHalfDay(event) {
    if (event) {
      if (this.modalRef !== undefined) {
        this.modalRef.hide();
      }
      this.tryHalfDay = true;
      this.tryHalfDayMsg =
        "Would you like to consider going to work for half day, instead of calling-off? It is recommended to make every effort to preserve as much UTO as you can";
    }
  }
  cancleCallOff() {
    this.tryHalfDay = false;
    this.callInRequstService.logCallOffRequest(6, this.cancleCallOffMsg);
    this.modalRef = this.modalService.show(this.awsomePopUp, {
      class: "modal-dialog-centered modal-md",
      backdrop: "static",
    });
  }
  closeAll(event) {
    if (event) {
      if (this.modalRef !== undefined) {
        this.modalRef.hide();
      }
      this.closeRequest.emit(false);
    }
  }
  checkBoxClick() {
    if (this.offerForm.value.IsFMLA && this.offerForm.value.IsPaidOff) {
      this.message =
        "Missed time will be covered by available balance in the order of FMLA and Paid Time Off. All remaining time will be covered by your Unpaid Time Off Balance. It is your responsibility to ensure you have enough time balance available in the categories you selected.";
    } else if (this.offerForm.value.IsFMLA && !this.offerForm.value.IsPaidOff) {
      this.message =
        "Missed time will be covered by your FMLA balance, if available. Any uncovered time will be covered by your Unpaid Time Off Balance. It is your responsibility to ensure you have enough FMLA balance available";
    } else if (!this.offerForm.value.IsFMLA && this.offerForm.value.IsPaidOff) {
      // this.message = "All missed time will be covered by your Unpaid Time Off(UTO) balance. It is your responsibility to ensure that you have enough UTO accumulated to cover this absence."

      this.message =
        "All missed time will be covered by your Unpaid Time Off (UTO) balance. If you do not have enough UTO balance to cover the entire absence, Paid Time Off (PTO) will be applied to cover the shortage. It is your responsibility to ensure that you have enough UTO+PTO accumulated to cover this absence.";
    } else if (!this.offerForm.value.IsFMLA && !this.offerForm.value.IsPaidOff) {
      // this.message = "All missed time will be covered by you Unpaid Time Off(UTO) balance. If you do not have enough PTO balance to cover the entire absence, Unpaid Time Off(UTO) will be applied to cover the shortage. It is your responsibility to ensure that you have enough UTO accumulated to cover this absence."
        this.message = this.utoWarningMsg;
    }
  }
  editTimeMessage(settingarr) {
    let isFLMA: boolean = false;
    let IsPaidOff: boolean = false;
    settingarr.map((item) => {
      if (item.SettingType === "PaidTimeOff") {
        if (item.Enable == true) {
          this.message =
            "All missed time will be covered by your Unpaid Time Off (UTO) balance. If you do not have enough UTO balance to cover the entire absence, Paid Time Off (PTO) will be applied to cover the shortage. It is your responsibility to ensure that you have enough UTO+PTO accumulated to cover this absence.";
          IsPaidOff = true;
        } else {
          this.message =
            "All missed time will be covered by your Unpaid Time Off (UTO) balance. It is your responsibility to ensure that you have enough UTO accumulated to cover this absence.";
          this.offerForm.controls["IsPaidOff"].setValue(false);
        }
      } else if (item.SettingType === "FMLA") {
        if (item.Enable == true) {
          isFLMA = true;
        } else {
          this.offerForm.controls["IsFMLA"].setValue(false);
        }
      }
      this.checkBoxClick();
    });
  }
}
      