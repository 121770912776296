import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SubDepartment } from 'src/app/models/subdepartment.model';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { FormService } from 'src/app/core/form.service';
import { DepartmentService } from 'src/app/service/department.service';
import { Constants } from 'src/app/constant/constants';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/service/company.service';
import { Role } from 'src/app/models/role-model';

@Component({
  selector: 'app-add-edit-sub-department',
  templateUrl: './add-edit-sub-department.component.html',
  styleUrls: ['./add-edit-sub-department.component.scss']
})
export class AddEditSubDepartmentComponent implements OnInit {
  @Input() subDepartment: SubDepartment;
  @Output() close = new EventEmitter<boolean>();
  subDepartmentForm: FormGroup;
  public messageList: any = new SubDepartment();
  roleEnum = Role;
  companyList = [];
  role: number;
  public departmentList:any=[];
  constructor(private formService: FormService,
    private departmentService: DepartmentService,
    private toaster: ToastrService,
    private companyService: CompanyService) { }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.getDepartmentList();
    this.initializeDepartmentForm();
    this.initializeMessages();
    if (this.role === Role.superUser) {
      this.getCompanyList()
    }
  }


  initializeDepartmentForm() {
    this.subDepartmentForm = new FormGroup({
      subDepartmentId: new FormControl(!!this.subDepartment ? this.subDepartment.subDepartmentId : 0),
      departmentId: new FormControl(!!this.subDepartment ? this.subDepartment.departmentId : '', Validators.required),
      subDepartmentName: new FormControl(!!this.subDepartment ? this.subDepartment.subDepartmentName : '', Validators.required),
      companyId: new FormControl(!!this.subDepartment ? this.subDepartment.companyId : null),
      isActive: new FormControl(true)
    });
    if (this.role === Role.superUser) {
      this.subDepartmentForm.controls.companyId.setValidators(Validators.required);
    }
  }

  initializeMessages() {
    this.messageList.departmentId = {
      required: Constants.VALIDATION_MSG.SUB_DEPARTMENT.DEPARTMENT_REQUIRED,
    };
    this.messageList.subDepartmentName = {
      required: Constants.VALIDATION_MSG.SUB_DEPARTMENT.SUB_DEPARTMENT_NAME_REQUIRED,
    };
    this.messageList.companyId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMPANY_REQUIRED,
    };
  }

  getCompanyList() {
    this.companyService.getCompanyListWithOutPagination().then(res => {
      if (res['Success']) {
        this.companyList = res['Data'];
      } else { this.companyList = []; }
    }, err => { this.companyList = []; })
  }

  getDepartmentList() {
    this.departmentService.getDepartmentListByCompanyId(null, Number(localStorage.getItem(Constants.COMPANYID))).then(res => {
      if (res['Success']) {
        this.departmentList = res['Data'];
      } else { this.departmentList = []; }
    }, err => { this.departmentList = []; });
  }

  cancel() {
    this.close.emit(false);
  }

  control(controlName: string): AbstractControl {
    return this.subDepartmentForm.get(controlName);
  }

  value(controlName: string) {
    return this.control(controlName).value;
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.subDepartmentForm);
    if (this.subDepartmentForm.invalid) {
      return;
    }
    if (this.role !== Role.superUser) {
      this.subDepartmentForm.controls.companyId.setValue(localStorage.getItem(Constants.COMPANYID));
    }
    const saveMethod = this.subDepartmentForm.controls.subDepartmentId.value > 0 ? this.departmentService.updatedSubDepartment(this.subDepartmentForm.value)
      : this.departmentService.addSubDepartment(this.subDepartmentForm.value);
    saveMethod.then((res) => {
      if (res['Success']) {
        this.toaster.success(this.subDepartmentForm.controls.subDepartmentId.value > 0 ? Constants.DEPARTMENT_UPDATE_SUCESS_MSG : Constants.DEPARTMENT_ADD_SUCCESS_MSG);
        this.close.emit(true);
      } else {
        this.toaster.error(res['Message']);
      }
    }, err => { this.close.emit(false); });
  }
}

