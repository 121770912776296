import { Injectable } from '@angular/core';
import { PlanService } from './plan.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Constants } from '../constant/constants';

@Injectable({
  providedIn: 'root'
})
export class PlanResolverService {

  constructor(private planService: PlanService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return this.planService.getPlanDetails(Number(localStorage.getItem(Constants.PRICINGID)));
  }
}
