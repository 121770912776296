import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
  AfterViewInit,
  EventEmitter,
  Output,
} from "@angular/core";
import {
  Constants,
  bsConfig,
  timeList,
  SubscriptionType,
} from "src/app/constant/constants";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { OfferStatus } from "src/app/models/role-model";
import { VotRequestService } from "src/app/service/vot-request.service";
import { ShiftService } from "src/app/service/shift.service";
import { DepartmentService } from "src/app/service/department.service";
import { DatePipe } from "@angular/common";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { environment } from "src/environments/environment";
import { UtilityService } from "src/app/service/utility.service";
import { CallInRequestService } from "src/app/service/callInRequest.service";
import * as _ from "lodash";
import { commonHelper } from "src/app/shared/Helpers/commonHelper";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-employer-vot-request",
  templateUrl: "./employer-vot-request.component.html",
  styleUrls: ["./employer-vot-request.component.scss"],
})
export class EmployerVotRequestComponent implements OnInit, AfterViewInit {
  @Output() reloadCount = new EventEmitter<boolean>();
  companyId: number;
  public HourEarly: any = {};
  public HourLate: any = {};
  public settingList: any = {};
  public moduleId: any;
  public isHiddenColumn: boolean = false;
  @Input() requestType: string;
  type = "";
  selected = [];
  @ViewChild("filterTextValue", { static: false }) filterTextValue;
  @ViewChild("workDateValue", { static: false }) workDateValue;
  @ViewChild("shiftValue", { static: false }) shiftValue;
  @ViewChild("departmentValue", { static: false }) departmentValue;
  @ViewChild("timeValue", { static: false }) timeValue;
  requestedOffer: any;
  requestedOfferList = [];
  modalRef: BsModalRef;
  totalItems: any;
  rowsOnPage = Constants.HR_PAGE_ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  page: any;
  offerId: number;
  offerStatus = OfferStatus;
  searchSort: any;
  columns = [];
  bsConfig: Partial<BsDatepickerConfig> = bsConfig;
  allRowsSelected = [];
  timeList = timeList;
  shiftList = [];
  departmentList = [];
  selectedRecords = [];
  filterValue = [];
  totalApprovedHours = 0;
  totalDeniedHours = 0;
  totalRequestedHours = 0;
  IsCoverMyWork: boolean = false;
  commonShiftList = [];

  constructor(
    private modalService: BsModalService,
    private votRequestService: VotRequestService,
    private shiftService: ShiftService,
    private departmentService: DepartmentService,
    private datepipe: DatePipe,
    private utilityService: UtilityService,
    private callInRequstService: CallInRequestService
  ) {
    this.columns = [
      "dateToWork",
      "departmentId",
      "shiftToWork",
      "timeType",
      "department.departmentName",
      "dateToWorkDateStr",
      "timeTypeStr",
      "createdByUsername",
      "shiftToWorkTitle",
      "isHourEarly",
      "isHourLate",
      "createdShortDate",
    ];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  ngOnInit() {
    this.IsCoverMyWork =
      localStorage.getItem(Constants.APP_NAME) === "CoverMyWork" ? true : false;
    this.bsConfig = Object.assign(
      {},
      {
        dateInputFormat: "MM/DD/YYYY",
      }
    );
    this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
    this.utilityService.showLoading();
    this.setPage({ offset: 0 });
    let module = SubscriptionType.filter((item) => {
      return item.value === "VOT Request Module";
    });
    this.moduleId = module[0].id;
    forkJoin([
      this.shiftService.getShiftListByCompanyId(null, this.companyId,false,false),
      this.departmentService.getDepartmentListByCompanyId(null, this.companyId,false,false),
      this.callInRequstService.getSettingByCompanyID(
        this.moduleId,
        this.companyId,
        false,
        false
      ),
    ]).subscribe((res: any) => {
      //first api response
      if (res[0]["Success"]) {
        this.shiftList = res[0]["Data"];
        this.commonShiftList = _.merge([], this.shiftList);
      }

      //second api resonse
      if (res[1]["Success"]) {
        this.departmentList = res[1]["Data"];
      } else {
        this.departmentList = [];
      }

      //third api response
      if (res[2]["Success"]) {
        this.settingList = res[2].Data;
        if (this.settingList.length > 0) {
          this.settingList.map((item) => {
            if (item.SettingType === "Hour Early") {
              item.Name = "An Hour Early";
              this.HourEarly = item;
              console.log(this.HourEarly);
            } else if (item.SettingType === "Hour Late") {
              item.Name = "An Hour Late";
              this.HourLate = item;
            }
          });
        }
      } else {
        this.settingList = [];
      }
      this.utilityService.hideLoading();
    });
  }

  ngAfterViewInit() {
    this.filterValue.push(this.workDateValue.nativeElement.value);
    this.filterValue.push(this.shiftValue.nativeElement.value);
    this.filterValue.push(this.departmentValue.nativeElement.value);
    this.filterValue.push(this.timeValue.nativeElement.value);
    this.columns.forEach((element, i) => {
      if (i < 4) {
        const obj = {
          Data: "string",
          Searchable: true,
          Orderable: true,
          Name: element,
          Search: {
            Value: this.filterValue[i],
            Regex: "string",
          },
        };
        this.searchSort.Columns.push(obj);
      }
    });
  }

  async setPage(pageInfo, isReloadCount = false) {
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
    await this.getRequestedOfferList(this.page.pageNumber + 1);
    if (isReloadCount) {
      this.reloadCount.emit();
    }
  }

  async getRequestedOfferList(currentPage) {
    if (
      (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) ||
      (!!this.shiftValue && !!this.shiftValue.nativeElement.value) ||
      (!!this.departmentValue && !!this.departmentValue.nativeElement.value) ||
      (!!this.timeValue && !!this.timeValue.nativeElement.value) ||
      (!!this.workDateValue && this.workDateValue.nativeElement.value)
    ) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      const getMethod =
        this.requestType === Constants.REQUESTED_OFFER
          ? this.votRequestService.getVotHrRequestedOfferList(
              currentPage,
              this.rowsOnPage
            )
          : this.votRequestService.getVotClosedRequestedOfferList(
              currentPage,
              this.rowsOnPage
            );
      await getMethod.then(
        (res: any) => {
          if (JSON.parse(localStorage.getItem(Constants.IS_TERMS_UPDATE))) {
            this.utilityService.showLoading();
          }
          if (res["Success"]) {
            if (
              !!res.Data.pagedResults &&
              res.Data.pagedResults.results.length > 0
            ) {
              this.requestedOfferList = res.Data.pagedResults.results;
              this.totalItems = res.Data.pagedResults.totalNumberOfRecords;
              if (this.IsCoverMyWork)
                this.requestedOfferList = this.requestedOfferList.filter(
                  (item) =>
                    item.timeTypeStr !== "An Hour Early" &&
                    item.timeTypeStr !== "An Hour Late"
                );
              this.totalRequestedHours =
                res.Data.approvalHours.totalHoursPending;
              this.totalApprovedHours =
                res.Data.approvalHours.totalHoursApproved;
              this.totalDeniedHours = res.Data.approvalHours.totalHoursDenied;
            } else {
              this.requestedOfferList = [];
              this.totalItems = 0;
            }
          } else {
            this.requestedOfferList = [];
            this.totalItems = 0;
          }
        },
        (err) => {
          this.requestedOfferList = [];
          this.totalItems = 0;
        }
      );
    }
  }

  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clearText();
      }
    }
  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  clearText() {
    this.searchSort.Search.Value = "";
    this.filterTextValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }

  clear() {
    this.searchSort.Search.Value = "";
    this.searchSort.Order[0].ColumnName = "";
    this.searchSort.Order[0].Dir = "asc";
    this.filterTextValue.nativeElement.value = "";
    this.searchSort.Columns.forEach((element) => {
      element.Search.Value = "";
    });
    this.shiftValue.nativeElement.value = "";
    this.departmentValue.nativeElement.value = "";
    this.timeValue.nativeElement.value = "";
    this.workDateValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }

  filterData() {
    const filterMethod =
      this.requestType === Constants.REQUESTED_OFFER
        ? this.votRequestService.approvalVotOfferDataFilter(this.searchSort)
        : this.votRequestService.closedVotOfferDataFilter(this.searchSort);
    filterMethod.then(
      (res) => {
        if (res["offerlist"].data && res["offerlist"].data.length > 0) {
          this.requestedOfferList = res["offerlist"].data;
          this.totalItems = res["offerlist"]["recordsFiltered"];
        } else {
          this.totalItems = 0;
        }
        this.totalRequestedHours = res["approvehours"].totalHoursPending;
        this.totalApprovedHours = res["approvehours"].totalHoursApproved;
        this.totalDeniedHours = res["approvehours"].totalHoursDenied;
      },
      (err) => {
        this.requestedOfferList = [];
        this.totalItems = 0;
      }
    );
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.selectedRecords = this.selected.map((x) => x.offerId);
  }

  openModal(template: TemplateRef<any>, type) {
    if (type === "approve") {
      this.type = type;
    } else {
      this.type = type;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-md",
      backdrop: "static",
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 }, event);
    }
    this.modalRef.hide();
  }

  getShiftList() {
    this.shiftService
      .getShiftListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID))
      )
      .then(
        (response) => {
          this.shiftList = response["Data"];
          this.commonShiftList = _.merge([], this.shiftList);
        },
        (err) => {
          this.shiftList = [];
        }
      );
  }

  getDepartmentList() {
    this.departmentService
      .getDepartmentListByCompanyId(
        null,
        Number(localStorage.getItem(Constants.COMPANYID))
      )
      .then(
        (res) => {
          if (res["Success"]) {
            this.departmentList = res["Data"];
          } else {
            this.departmentList = [];
          }
        },
        (err) => {
          this.departmentList = [];
        }
      );
  }

  workDateChange(event) {
    const findDateColumn = this.searchSort.Columns.find(
      (x) => x.Name === "dateToWork"
    );
    if (!!findDateColumn) {
      findDateColumn.Search.Value = this.datepipe.transform(
        this.setnewDate(event),
        "yyyy/MM/dd"
      );
    }
  }

  setnewDate(date) {
    const dateObj = {
      year: +this.datepipe.transform(date, "yyyy"),
      month: +this.datepipe.transform(date, "MM"),
      day: +this.datepipe.transform(date, "dd"),
    };
    return new Date(dateObj["year"], dateObj["month"] - 1, dateObj["day"]);
  }

  shiftChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(
      (x) => x.Name === "shiftToWork"
    );
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = event.currentTarget.value;
    }
  }

  departmentChange(event) {
    const findShiftColumn = this.searchSort.Columns.find(
      (x) => x.Name === "shiftToWork"
    );
    if (!!findShiftColumn) {
      findShiftColumn.Search.Value = "";
    }
    if (event.currentTarget.value)
      this.shiftList = commonHelper.filterShiftListFromDepartmentId(
        event.currentTarget.value,
        this.commonShiftList
      );
    else this.shiftList = this.commonShiftList;
    const findDepartmentColumn = this.searchSort.Columns.find(
      (x) => x.Name === "departmentId"
    );
    if (!!findDepartmentColumn) {
      findDepartmentColumn.Search.Value = event.currentTarget.value;
    }
  }

  timeChange(event) {
    const findTimeColumn = this.searchSort.Columns.find(
      (x) => x.Name === "timeType"
    );
    if (!!findTimeColumn) {
      findTimeColumn.Search.Value = event.currentTarget.value;
    }
  }

  emptySumm() {
    return null;
  }

  // downloadFile() {
  //   this.votRequestService.downloadExcelFile(this.searchSort)
  //     .then(response => {
  //       if (response['Success']) {
  //         const a = document.createElement('a');
  //         a.setAttribute('style', 'display:none;');
  //         document.body.appendChild(a);
  //         a.href = environment.BLOB_URL + response['Message'];
  //         a.download = response['Message'].split('/')[1];
  //         a.click();
  //       } else {
  //         this.utilityService.showErrorToast(response['Message']);
  //       }
  //     }, err => { });
  // }

  downloadFile() {
    const downloadMethod =
      this.requestType !== "approvedOffer"
        ? this.votRequestService.downloadExcelFile(this.searchSort)
        : this.votRequestService.downloadClosedExcelFile(this.searchSort);
    downloadMethod.then(
      (response) => {
        if (response["Success"]) {
          const a = document.createElement("a");
          a.setAttribute("style", "display:none;");
          document.body.appendChild(a);
          a.href = environment.BLOB_URL + response["Message"];
          a.download = response["Message"].split("/")[1];
          a.click();
        } else {
          this.utilityService.showErrorToast(response["Message"]);
        }
      },
      (err) => {}
    );
  }

  getSettingByCompanyID() {
    let module = SubscriptionType.filter((item) => {
      return item.value === "VOT Request Module";
    });
    this.moduleId = module[0].id;
    const getMethod = this.callInRequstService.getSettingByCompanyID(
      this.moduleId,
      this.companyId,
      false
    );
    getMethod.then(
      (res: any) => {
        if (res["Success"]) {
          this.settingList = res.Data;
          if (this.settingList.length > 0) {
            this.settingList.map((item) => {
              if (item.SettingType === "Hour Early") {
                item.Name = "An Hour Early";
                this.HourEarly = item;
                console.log(this.HourEarly);
              } else if (item.SettingType === "Hour Late") {
                item.Name = "An Hour Late";
                this.HourLate = item;
              }
            });
          }
        } else {
          this.settingList = [];
        }
      },
      (err) => {
        this.settingList = [];
      }
    );
  }
}
