import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { Constants, loginRoleList, roleList } from "src/app/constant/constants";
import { AuthService } from "src/app/core/auth/auth.service";
import { defaultRoutes } from "src/app/core/auth/default-routes";
import { FormService } from "src/app/core/form.service";
import { Role } from "src/app/models/role-model";
import { RolePopup } from "src/app/models/role-popup.model";
import { UtilityService } from "src/app/service/utility.service";

@Component({
  selector: "app-allow-role-login",
  templateUrl: "./allow-role-login.component.html",
  styleUrls: ["./allow-role-login.component.scss"],
})
export class AllowRoleLoginComponent implements OnInit {
  roleId: any;
  roleName: string;
  roleList = roleList;
  roleForm: FormGroup;
  public messageList: any = new RolePopup();
  checkedValue: string;
  loginRoleList = loginRoleList;
  showRoleList = [];

  constructor(
    private router: Router,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private document: Document,
    private formService: FormService,
    private authService: AuthService,
    private utilityService: UtilityService,
    public bsModalRef: BsModalRef,
    public options: ModalOptions
  ) {}

  ngOnInit() {
    this.getRole();
    localStorage.setItem(
      "identifierUser",
      localStorage.getItem(Constants.USERID)
    );
    let userRoleId = localStorage.getItem("roleId");
    if (!!userRoleId && userRoleId != "null" && userRoleId != "undefined") {
      this.checkedValue = userRoleId;
    }
    this.roleForm = new FormGroup({
      userrole: new FormControl(!!this.checkedValue ? this.checkedValue : "", [
        Validators.required,
      ]),
    });

    this.initializeMessages();
  }
  initializeMessages() {
    this.messageList.userrole = {
      required: Constants.VALIDATION_MSG.ROLE.USER_ROLE_REQUIRED,
    };
  }

  onRoleSubmit() {
    this.formService.markFormGroupTouched(this.roleForm);
    if (this.roleForm.invalid) {
      return;
    }
    let userrole = this.roleForm.controls.userrole.value;
    localStorage.setItem("roleId", this.roleForm.controls.userrole.value);
    localStorage.setItem(Constants.ROLE, this.roleForm.controls.userrole.value);

    // if (userrole === "login as an end-user") {
    let userData =
      this.options.initialState +
      "&isLoggedInAsAUser=true&LoginRole=" +
      userrole;
    this.loginAsUser(userData);
    // } else {
    //   this.onClose();
    // }
  }

  onClose() {
    this.document.body.classList.remove("modal-open");
    this.modalService.hide(1);
    this.router.navigate([
      defaultRoutes[Number(localStorage.getItem(Constants.ROLE))],
    ]);
    // this.router.navigate([defaultRoutes[Number(localStorage.getItem(Constants.ROLE))]]);
  }

  getRole() {
    this.showRoleList = [];
    // this.roleId = localStorage.getItem(Constants.ROLE);
    this.roleId = localStorage.getItem(Constants.ROLE);
    let roles = this.roleId.split(",");
    let roleObj;
    roles.forEach((element) => {
      roleObj = this.loginRoleList.find((x) => x.id == element);
      if (!!roleObj) {
        this.showRoleList.push({ Id: element, Role: roleObj.role });
      }
    });
    return true;
    // let roleObj = this.loginRoleList.filter((element) => {
    //   if (element.id.toString() == this.roleId || element.role == "User") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });
    // this.checkedValue = roleObj[0]["value"];
    // this.showRoleList = roleObj;
  }

  setChecked(value) {
    this.checkedValue = value;
    this.roleForm.controls.userrole.setValue(this.checkedValue);
  }

  loginAsUser(userData) {
    let userrole = this.roleForm.controls.userrole.value;
    this.authService.login(userData).then(
      (res: any) => {
        if (!res.error) {
          // this.utilityService.showSuccessToast("Logged in as a User");
          localStorage.setItem(Constants.ROLE, userrole.toString());
          localStorage.setItem("roleId", userrole.toString());
          localStorage.setItem(Constants.TOKEN, res.access_token);
          if (userrole == 1) {
            this.utilityService.showSuccessToast("Logged in as a Admin");
          } else if (userrole == 2) {
            this.utilityService.showSuccessToast("Logged in as a Purchaser");
          } else if (userrole == 3) {
            this.utilityService.showSuccessToast("Logged in as a HrAdmin");
          } else if (userrole == 4) {
            this.utilityService.showSuccessToast("Logged in as a Manager");
          } else if (userrole == 5) {
            this.utilityService.showSuccessToast("Logged in as a User");
          } else {
            this.utilityService.showSuccessToast(
              "Logged in as a LearningAdmin"
            );
          }
          this.roleId = Role.user;
          this.onClose();
        } else {
          this.utilityService.showErrorToast(res["error_description"]);
        }
      },
      (err) => {
        this.utilityService.showErrorToast(err.error.error_description);
      }
    );
  }
}
