import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';

@Injectable({
  providedIn: 'root'
})
export class TimeTrackingService {

  constructor(private apiService: ApiService) { }

  getAllTimeTracking(userId?,companyId?,currentPage?,pageSize?) {
    //&page=${currentPage}&pageSize=${pageSize}
    return this.apiService.get(
      `${API.TIME_TRACKING_ROUTES.getFunctionTimeTracking}?userId=${userId}&companyId=${companyId}&page=${currentPage}&pageSize=${pageSize}`,
      true,
      true
    );
  }

  AddTrackFunctionTime(data) {
    //dataFilter
    return this.apiService.post(
      `${API.TIME_TRACKING_ROUTES.functionTimeTracking}`,
      data,
      true
    );
  }
  UpdateTrackFunctionTime(data,ID) {
    //dataFilter
    return this.apiService.put(
      `${API.TIME_TRACKING_ROUTES.updateTimeTracking}?Id=${ID}`,
      data,
      true
    );
  }
  DeleteTimeTracking(ID) {
    //dataFilter
    return this.apiService.delete(
      `${API.TIME_TRACKING_ROUTES.DeleteTimeTracking}?Id=${ID}`,
      true,
      true
    );
  }
  GetAllFunction(companyId,page?,size?){
    //&page=${page}&pageSize=${size}
    return this.apiService.get(
      `${API.TIME_TRACKING_ROUTES.getAllFunction}?CompanyId=${companyId}`,
      true,
      true
    );
  }
}
