import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/constant/constants';
import { OfferStatus, Role } from 'src/app/models/role-model';
import { timeOffRequestModel } from 'src/app/models/TimeOffRequest.model';
import { TimeOffService } from 'src/app/service/time-off.service';
import { UtilityService } from 'src/app/service/utility.service';

@Component({
  selector: 'app-timeoff-request',
  templateUrl: './timeoff-request.component.html',
  styleUrls: ['./timeoff-request.component.scss']
})
export class TimeoffRequestComponent implements OnInit {
  @ViewChild('filterTextValue', { static: false }) filterTextValue;
  @Input() requestType: string;
  modalRef: BsModalRef;
  role: number;
  roleEnum = Role;
  page: any;
  searchSort: any;
  userName: string;
  columns = [];
  offerStatus = OfferStatus;
  isShowLink = false;
  public myOffer: timeOffRequestModel;
  timeOffRequestList: [];
  rowsOnPage = Constants.ROWS_ON_PAGE;
  currentPage = Constants.CURRENT_PAGE;
  public totalItems: any;
  timeOffRequest: any;
  isConfirmed: boolean = false;
  deleteId: string;

  constructor(private modalService: BsModalService, private toaster: ToastrService,
    private utilityService: UtilityService, private timeOffService: TimeOffService) {
    this.columns = [
      "userName",
      "TimeOffStartDateStr",
      "TimeOffEndDateStr",
      "status",
      "CreatedDateStr",
      "createdBy",
      "timeOffId",
      "TimeOffDifference",
      "offerStatusStr",
      "NetBalance"
    ];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.userName = localStorage.getItem(Constants.USERNAME);
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }


  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getUserTimeOffList(this.page.pageNumber + 1);
  }

  getUserTimeOffList(currentPage) {

    const getMethod = this.timeOffService.getTimeOffUser(currentPage, this.userName);
    getMethod.then((res: any) => {
      if (res['Success']) {
        this.timeOffRequestList = res.Data.results;
        this.timeOffRequestList.forEach((item: any) => {
          if (new Date(new Date(item.TimeOffStartDateStr).setHours(0,0,0,0)) > new Date()) {
            item.userDelete = true;
          }
        });
        this.totalItems = res.Data.totalNumberOfRecords === 0 ? undefined : res.Data.totalNumberOfRecords;
      } else {
        this.timeOffRequestList = []; this.totalItems = undefined;
      }
    },
      err => { this.timeOffRequestList = []; });

  }

  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }
  filterData() {
    const filterMethod = this.timeOffService.getUserRequestFilterData(this.searchSort)
    filterMethod.then(res => {
      if (res['data'] && res['data'].length > 0) {
        this.timeOffRequestList = res['data'];
        this.totalItems = res['recordsFiltered'] === 0 ? undefined : res['recordsFiltered'];
      } else {
        this.totalItems = undefined;
      }
    }, err => { this.timeOffRequestList = []; this.totalItems = undefined; });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }
  cancle(event) {
    this.modalRef.hide();
  }
  openModal(template: TemplateRef<any>, data: any) {

    if (data) {
      this.timeOffRequest = data;
    } else {
      this.timeOffRequest = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }

  onClickDelete(data) {
    this.deleteId = data.TimeOffUserRequestId;
    this.isConfirmed = true;
  }

  delete(id) {
    this.isConfirmed = false;
    this.timeOffService.deleteTimeOffRequest(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.TIME_OFF_REQ_DELETE_MSG);
        this.setPage({ offset: 0 });
      } else { this.toaster.error(res['Message']); }
    }, err => { });
  }
  close(event) {
    this.isConfirmed = false;
  }
}
