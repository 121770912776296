import { Injectable } from '@angular/core';
import { API } from '../routes/api-routes';
import { ApiService } from './api.service';
import { Constants } from '../constant/constants';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
    constructor(private apiService: ApiService) { }

    subscriptionByEmail(email) {
        return this.apiService.post(
            `${API.CONTACT.SUBSCRIPTION}?email=${email}`,
            null,
            true,
          );
    } 

    contact(data) {
        console.log('data',data);
        return this.apiService.post(
            `${API.CONTACT.CONTACT_MESSAGE_NEW}`,
            data,
            false,
        );
    }
}