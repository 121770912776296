import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Constants } from "../constant/constants";
import { Role } from "../models/role-model";
import { UtilityService } from "../service/utility.service";

@Component({
  selector: "app-offer",
  templateUrl: "./offer.component.html",
  styleUrls: ["./offer.component.scss"],
})
export class OfferComponent implements OnInit {
  //showDeleteModal = false;
  isAvailableOffer = false;
  isRequestedOffer = false;
  isTraningModule = false;
  isTimeOffRequest = false;
  isShowClosedRequestOption = false
  //isPendingRequest = false;
  role: number;
  roleEnum = Role;
  isShowTraning = false;
  isShowClockInOut = false;
  isClockInOutModule = false;
  isShowSwap = false;
  isShowVto = false;
  isShowFlexWork = false;
  isAvailableOfferUser = false;
  isAvailableOfferUserMsg=false;
  showTimeTracking: boolean=false;
  isTimeOff =false;
  @ViewChild('noModule', { static: true }) noModule: ElementRef;
  
  constructor(private utilityService: UtilityService) { }

  ngOnInit() {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.isShowSwap = JSON.parse(localStorage.getItem(Constants.IS_SWAP));
    this.isShowVto = JSON.parse(localStorage.getItem(Constants.IS_VTO));
    this.isShowFlexWork = JSON.parse(localStorage.getItem(Constants.IS_FLEX_WORK));
    this.isTimeOff = JSON.parse(localStorage.getItem(Constants.IS_TIME_OFF));
    if (this.role === this.roleEnum.user && (this.isShowSwap || this.isShowVto || this.isShowFlexWork)) {
      this.isAvailableOfferUser = true;
      this.isAvailableOfferUserMsg=false;
      this.isAvailableOffer = false;
      this.isShowTraning = JSON.parse(
        localStorage.getItem(Constants.IS_TRAINING)
      );
      this.isShowClockInOut = JSON.parse(
        localStorage.getItem(Constants.IS_CLOCKINOUT)
      );
      this.getOffer(1);
      //  this.isRequestedOffer = true;
    } else if (this.role === this.roleEnum.user && !(this.isShowSwap || this.isShowVto || this.isShowFlexWork)) {
      this.isAvailableOfferUser = false;
this.isAvailableOfferUserMsg=false;
      // this.isAvailableOffer = true;
      this.isShowTraning = JSON.parse(
        localStorage.getItem(Constants.IS_TRAINING)
      );
      this.isShowClockInOut = JSON.parse(
        localStorage.getItem(Constants.IS_CLOCKINOUT)
      );
      this.getOffer(2);
      //  this.isRequestedOffer = true;
    }
    else {
      this.isAvailableOfferUser = false;
      this.isAvailableOffer = true;

      // this.isRequestedOffer = true;
      this.isShowTraning = JSON.parse(
        localStorage.getItem(Constants.IS_TRAINING)
      );
      this.isShowClockInOut = JSON.parse(
        localStorage.getItem(Constants.IS_CLOCKINOUT)
      );
      this.getOffer(1);
      // this.isRequestedOffer = true;

    }
    this.utilityService.hideLoading();
  }

  // closeConfirmation(event) {
  //   this.showDeleteModal = false;
  // }

  getOffer(data) {
    // this.isAvailableOfferUser = false;
    this.isAvailableOffer = false;
    this.isRequestedOffer = false;
    this.isTraningModule = false;
    this.isTimeOffRequest = false;
    this.isClockInOutModule = false;
    this.isShowClosedRequestOption = false;
    // this.isPendingRequest = false;
    if (data === 1) {
      this.isAvailableOffer = true;
      // this.isAvailableOfferUser = true;
      this.isShowClosedRequestOption = true;
      this.showTimeTracking = false;
    } else if (data === 2) {
      this.isRequestedOffer = true;
    } else if (data === 3) {
      this.isTraningModule = true;
    } else if (data === 4) {
      this.isTimeOffRequest = true; 
    } else if (data === 5) {
      this.isClockInOutModule = true;
    }
    else if(data === 6){
      this.showTimeTracking = true;
    }
    if(this.isAvailableOfferUserMsg){
      this.noModule.nativeElement.innerHTML = '';
      let elementClass: any = document.getElementsByClassName("sub");
      if (elementClass.length > 0)
        elementClass[0].style.border = 'none'
      this.noModule.nativeElement.innerHTML ="<h1> No modules subscribed, Please contact admin for subscription</h1>";
     }
  }
  
}
