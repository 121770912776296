import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { API } from "../routes/api-routes";

@Injectable()
export class ModuleFunctionService {
  constructor(private apiService: ApiService) {}

  //#region Function
  addFunction(data) {
    //dataFilter
    return this.apiService.post(
      `${API.FUNCTION_ROUTES.addFunction}`,
      data,
      true
    );
  }
  updateFunction(data) {
    //dataFilter
    return this.apiService.put(
      `${API.FUNCTION_ROUTES.updateFunction}`,
      data,
      true
    );
  }

  functionDataList(currentPage) {
    return this.apiService.get(
      `${API.FUNCTION_ROUTES.function}?page=` + currentPage,
      true,
      true
    );
  }

  moduleDataFilter(data) {
    return this.apiService.post(
      `${API.MODULE_ROUTES.dataFilter}`,
      data,
      true
    );
  }
  
  functionDataFilter(data) {
    return this.apiService.post(
      `${API.FUNCTION_ROUTES.dataFilter}`,
      data,
      true  
    );
  }
  deleteFunction(Id) {
    return this.apiService.delete(
      `${API.FUNCTION_ROUTES.function}?id=` + Id,
      true,
    );
  }
  //#endregion

  //#region  Module
  addModule(data) {
    //dataFilter
    return this.apiService.post(
      `${API.MODULE_ROUTES.addModule}`,
      data,
      true
    );
  }
  updateModule(data) {
    //dataFilter
    return this.apiService.put(
      `${API.MODULE_ROUTES.updateModule}`,
      data,
      true
    );
  }

  getModuleConfigList(currentPage) {
    return this.apiService.get(
      `${API.MODULE_ROUTES.module}?page=` + currentPage,
      true,
      true
    );
  }
  deleteModule(Id) {
    return this.apiService.delete(
      `${API.MODULE_ROUTES.module}?id=` + Id,
      true,
    );
  }
  //#endregion
}
