import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DepartmentMappingRoutingModule } from './department-mapping-routing.module';
import { DepartmentMappingComponent } from './department-mapping.component';
import { SharedModule } from './../shared/shared/shared.module';
import { AddEditDepartmentMappingComponent } from './add-edit-department-mapping/add-edit-department-mapping.component';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ModuleFunctionService } from '../service/moduleFunction.service';
import { DepartmentService } from './../service/department.service';
import { DepartmentMappingService } from '../service/departmentMapping.service';


@NgModule({
  declarations: [DepartmentMappingComponent, AddEditDepartmentMappingComponent],
  imports: [
SharedModule,
    DepartmentMappingRoutingModule,
    NgMultiSelectDropDownModule,
    NgxBootstrapIconsModule.pick(allIcons),
  ],
  providers:[ModuleFunctionService,DepartmentService,DepartmentMappingService]
})
export class DepartmentMappingModule { }
