import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { Constants, SubscriptionType } from "src/app/constant/constants";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { OfferStatus, Role } from "src/app/models/role-model";
import { EarlyOutService } from "./../../service/earlyOut.service";
import { Offer } from "src/app/models/offer.model";
import { OfferService } from "./../../service/offer.service";
import { ToastrService } from "ngx-toastr";
import { CallInRequestService } from "src/app/service/callInRequest.service";
import { DatePipe } from "@angular/common";
import { Observable, forkJoin } from "rxjs";
import { UtilityService } from "src/app/service/utility.service";

@Component({
  selector: "app-early-go-request",
  templateUrl: "./early-go-request.component.html",
  styleUrls: ["./early-go-request.component.scss"],
})
export class EarlyGoRequestComponent implements OnInit {
  @ViewChild("filterTextValue", { static: false }) filterTextValue;
  public showAction: boolean = true;
  selected = [];
  columns = [];
  selectedRecords = [];
  earlyOutRequestList = [];
  searchSort: any;
  public myOffer: Offer;
  modalRef: BsModalRef;
  public totalItems: any = "1";
  page: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  role: number;
  roleEnum = Role;
  deleteId: string;
  isConfirmed = false;
  offerStatus = OfferStatus;
  moduleId: number;
  settingList: any;
  isPaidTimeOff: boolean;
  message: string;
  IsCoverMyWork: boolean;

  constructor(
    private modalService: BsModalService,
    private earlyOutService: EarlyOutService,
    private offerService: OfferService,
    private toaster: ToastrService,
    private datePipe: DatePipe,
    private utilityService: UtilityService,
    private callInRequstService: CallInRequestService
  ) {
    this.columns = [
      "createdByObj.name",
      "depatureTime",
      "dateToSkipShortDate",
      "vtoStartTime",
      "uTOHours",
      "ReasonObj.name",
      "offerStatusStr",
      "VtoStartTime12Hours",
      "processedByObj.name",
      "IsPaidOff",
      "OtherReason",
    ];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  ngOnInit() {
    this.IsCoverMyWork =
      localStorage.getItem(Constants.APP_NAME) === "CoverMyWork" ? true : false;
    this.role = Number(localStorage.getItem(Constants.ROLE));
    if (this.role == this.roleEnum.manager) {
      this.showAction = false;
    }
    this.setPage({ offset: 0 });
  }

  async setPage(pageInfo) {
    this.utilityService.showLoading();
    this.selected = [];
    this.selectedRecords = [];
    this.page.pageNumber = pageInfo.offset;
    let module = SubscriptionType.filter((item) => {
      return item.value === "Early Out Module";
    });
    this.moduleId = module[0].id;
    forkJoin([
      this.role === this.roleEnum.manager
        ? this.earlyOutService.getEarlyOutClosedManagerRequestList(
            this.page.pageNumber,
            this.rowsOnPage,
            false,
            false
          )
        : this.earlyOutService.getEarlyOutRequestList(
            this.page.pageNumber,
            this.rowsOnPage,
            false,
            false
          ),
      this.callInRequstService.getSettingByCompanyID(
        this.moduleId,
        Number(localStorage.getItem(Constants.COMPANYID)),
        false,
        false
      ),
    ]).subscribe((res: any) => {
      if (res[0]["Success"]) {
        if (res[0].Data && res[0].Data.results.length > 0) {
          this.earlyOutRequestList = res[0].Data.results;
          this.totalItems = res[0].Data.totalNumberOfRecords;
        } else {
          this.earlyOutRequestList = [];
          this.totalItems = 0;
        }
      } else {
        this.earlyOutRequestList = [];
        this.totalItems = 0;
      }
      if (res[1]["Success"]) {
        if (res[1].Data.length > 0) {
          res[1].Data.map((item) => {
            if (item.SettingType === "PaidTimeOff") {
              item.Name = "Paid Time Off";
              if (item.Enable == true) {
                this.isPaidTimeOff = true;
              } else {
                this.isPaidTimeOff = false;
              }
            }
          });
        }
      } else {
        this.settingList = [];
      }
      this.utilityService.hideLoading();
    });
  }

  getSettingByCompanyIDAsync() {
    let module = SubscriptionType.filter((item) => {
      return item.value === "Early Out Module";
    });
    this.moduleId = module[0].id;
    this.callInRequstService.getSettingByCompanyID(
      this.moduleId,
      Number(localStorage.getItem(Constants.COMPANYID)),
      false,
      false
    );
    // ).then(
    //   (res: any) => {
    //     if (res["Success"]) {
    //       if (res.Data.length > 0) {
    //         res.Data.map((item) => {
    //           if (item.SettingType === "PaidTimeOff") {
    //             item.Name = "Paid Time Off";
    //             if (item.Enable == true) {
    //               this.isPaidTimeOff = true;
    //             } else {
    //               this.isPaidTimeOff = false;
    //             }
    //           }
    //         });
    //       }
    //     } else {
    //       this.settingList = [];
    //     }
    //   },
    //   (err) => {
    //     this.settingList = [];
    //   }
    // );
  }

  getEarlyOutOfferListAsync(currentPage) {
    const getMethod =
      this.role === this.roleEnum.manager
        ? this.earlyOutService.getEarlyOutClosedManagerRequestList(
            currentPage,
            this.rowsOnPage,
            false,
            false
          )
        : this.earlyOutService.getEarlyOutRequestList(
            currentPage,
            this.rowsOnPage,
            false,
            false
          );
    // getMethod.then(
    //   (res: any) => {
    //     if (res["Success"]) {
    //       if (res.Data && res.Data.results.length > 0) {
    //         this.earlyOutRequestList = res.Data.results;
    //         this.totalItems = res.Data.totalNumberOfRecords;
    //       } else {
    //         this.earlyOutRequestList = [];
    //         this.totalItems = 0;
    //       }
    //     } else {
    //       this.earlyOutRequestList = [];
    //       this.totalItems = 0;
    //     }
    //   },
    //   (err) => {
    //     this.earlyOutRequestList = [];
    //     this.totalItems = 0;
    //   }
    // );
  }
  sortCallback(sortInfo) {
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
    this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
    this.filterData();
  }

  filterData() {
    const filterMethod =
      this.role === this.roleEnum.user
        ? this.earlyOutService.userEarltyOutOfferDataFilter(this.searchSort)
        : this.earlyOutService.managerClosedEarltyOutOfferDataFilter(
            this.searchSort
          );
    filterMethod.then(
      (res) => {
        if (res["data"] && res["data"].length > 0) {
          this.earlyOutRequestList = res["data"];
          this.totalItems = res["recordsFiltered"];
        } else {
          this.totalItems = 0;
        }
      },
      (err) => {
        this.earlyOutRequestList = [];
        this.totalItems = 0;
      }
    );
  }
  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clearText();
      }
    }
  }
  clear() {
    this.searchSort.Search.Value = "";
    this.searchSort.Order[0].ColumnName = "";
    this.searchSort.Order[0].Dir = "asc";
    this.filterTextValue.nativeElement.value = "";
    this.searchSort.Columns.forEach((element) => {
      element.Search.Value = "";
    });
    this.setPage({ offset: 0 });
  }
  clearText() {
    this.searchSort.Search.Value = "";
    this.filterTextValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }

  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.myOffer = data;
    } else {
      this.myOffer = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }

  closeModal(event) {
    if (event) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }

  onClickDelete(data) {
    this.deleteId = data.offerId;
    this.isConfirmed = true;
  }
  delete(id) {
    this.isConfirmed = false;
    this.offerService.deleteOffer(id).then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(Constants.OFFER_DELETE_SUCCESS_MSG);
          this.setPage({ offset: 0 });
        } else {
          this.toaster.error(res["Message"]);
        }
      },
      (err) => {}
    );
  }
  convertDateToSkipShortDate(data) {
    const date = data.dateToSkipShortDate.split("/");
    const departureStartTime = data.vtoStartTime.split(":");
    const dateToSkipHH = parseInt(departureStartTime[0]);
    const dateToSkipMin = parseInt(departureStartTime[1]);
    const dateToSkipMM = parseInt(date[0]);
    const dateToSkipDD = parseInt(date[1]);
    const dateToSkipYYYY = parseInt(date[2]);
    return this.datePipe.transform(
      new Date(
        dateToSkipYYYY,
        dateToSkipMM - 1,
        dateToSkipDD,
        dateToSkipHH,
        dateToSkipMin
      ),
      "MM/dd/yyyy hh:mm a"
    );
  }
  getFormatedDate(date) {
    return this.datePipe.transform(new Date(date), "MM/dd/yyyy hh:mm a");
  }
  close(event) {
    this.isConfirmed = false;
  }
}
