import { Component, OnInit, ViewChild, TemplateRef,Input ,HostListener } from '@angular/core';
import { Constants } from 'src/app/constant/constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OfferStatus, Role } from 'src/app/models/role-model';
import { Offer } from 'src/app/models/offer.model';
import { OfferService } from './../../service/offer.service';
import { ToastrService } from 'ngx-toastr';
import { TransferRequestService } from 'src/app/service/transfer-request.service';


@Component({
  selector: 'app-transfer-request',
  templateUrl: './transfer-request.component.html',
  styleUrls: ['./transfer-request.component.scss']
})
export class TransferRequestComponent implements OnInit {
  public isApprovedReq:boolean = false;
  public showAllCheckbox:boolean = false;
  @HostListener('document:keyup', ['$event']) handleKeyUp(event) {
    if (event.keyCode === 27) {
      this.isAccept = false;
      this.isAccept = false;
      this.isNotInterested = false
    }
  }
  public type:any;
  public text:any;
  companyId: number;
  @Input() requestType: string;
  public showAction: boolean = true;
  public totalItems: any ;
  page: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  labelapprovedMessage  = Constants.APPROVED_LABEL_MESSAGE;
  role: number;
  roleEnum = Role;
  deleteId: string;
  isConfirmed = false;
  isAccept = false;
  isNotInterested = false;
  offerStatus = OfferStatus;
  searchSort: any; 
  public selected:any=[];
  public selectedRecords:any=[];
  public myOffer: Offer;
  modalRef: BsModalRef;
  acceptId:any;
  rejectId:any;
  columns = [];
  
  
  public transferRequestList:any=[];
  constructor(private modalService: BsModalService,
    private transferRequestService:TransferRequestService,
    private offerService:OfferService,
    private toaster: ToastrService,
    private tranferService:TransferRequestService
    ) { 
      
      this.page = {
        pageNumber: 0,
        size: this.rowsOnPage,
      };
      this.searchSort = {
        Page: this.page.pageNumber + 1,
        PageSize: Constants.HR_PAGE_ROWS_ON_PAGE,
        Columns: [
        ],
        Search: {
          Value: '',
          ColumnNameList: [],
          Regex: 'string'
        },
        Order: [{
          Column: 0,
          ColumnName: '',
          Dir: 'asc'
        }]
      }
    }
    
    ngOnInit() {
      console.log(this.requestType)
      this.companyId = Number(localStorage.getItem(Constants.COMPANYID));
      this.role = Number(localStorage.getItem(Constants.ROLE));
      if (this.role == this.roleEnum.manager) {
        this.showAction = false;
        this.columns = [
          'departmentId', 'createdDate', 'shiftToSkip', 'status','createdByUsername', 'createdShortDate','createdByObj.shift.title','departmentName','department.departmentName','subDepartmentName', 'shiftToWorkTitle', 'offerStatusStr', 'dateToSkip', 'isPit', 'operateToShow','preferenceId', 'dateToWorkDateStr','createdByShift'
        ];
      }
      else{
        this.columns = ['departmentId','departmentName','subDepartmentName','shiftToWorkTitle','operateToShow','isPit','createdShortDate','preferenceId','dateToWork','dateToWorkDateStr','status','offerStatusStr']
      }
      this.setPage({ offset: 0 });
    }
    
    setPage(pageInfo) {
      this.selected = [];
      this.selectedRecords = [];
      this.page.pageNumber = pageInfo.offset;
      if (this.role == this.roleEnum.manager) {
        this.getManagerTransferRequest(this.page.pageNumber + 1);
      }
      else{
        this.getTransferOfferList(this.page.pageNumber + 1)
      }
    }
    
    sortCallback(sortInfo) {
      this.searchSort.Page = this.page.pageNumber + 1;
      this.searchSort.Order[0].ColumnName = sortInfo.sorts[0].prop;
      this.searchSort.Order[0].Dir = sortInfo.sorts[0].dir;
      // this.filterData();
      if (this.role == this.roleEnum.manager) {
        this.filterManagerData();
      }
      else if(this.role == this.roleEnum.user){
        this.filterUserData();
      }
    }
    
    filterUserData(){
      const filterMethod = this.tranferService.getTransferRequestsForUserDataFilter(this.searchSort)
      filterMethod.then(res => {
        if (res['data'] && res['data'].length > 0) {
          this.transferRequestList = res['data'];
          this.showAllCheckbox = this.transferRequestList.some(e=>e.status == this.offerStatus.accepted);
          this.isApprovedReq = this.transferRequestList.some(e=>e.status == this.offerStatus.scheduleUpdated);
          this.transferRequestList.map((item:any)=>{
            if(item.isPit){
              item.operateToShow = "Yes"
            }
            else{
              item.operateToShow = "No";
            }
            if (item.status ===this.offerStatus.accepted){
              item.offerStatusStr = "Pending Process"
            }
          })
          this.totalItems = res['recordsFiltered'];
        } else {
          this.totalItems = 0;
        }
      }, err => { this.transferRequestList = []; this.totalItems = 0; });
    }
    
    filterManagerData() {
      console.log(this.searchSort)
      const filterMethod = this.requestType === Constants.REQUESTED_OFFER ?
      this.tranferService.hrPendingTransferOfferDataFilter(this.searchSort)
      : this.tranferService.hrClosedTransferOfferDataFilter(this.searchSort);
      filterMethod.then(res => {
        if (res['data'] && res['data'].length > 0) {
          this.transferRequestList = res['data'];
          this.showAllCheckbox = this.transferRequestList.some(e=>e.status == this.offerStatus.accepted);
          this.transferRequestList.map((item:any)=>{
            if(item.isPit){
              item.operateToShow = "Yes"
            }
            else{
              item.operateToShow = "No";
            }
            if (item.status ===this.offerStatus.accepted){
              item.offerStatusStr = "Pending Process"
            }
          })
          this.totalItems = res['recordsFiltered'];
        } else {
          this.totalItems = 0;
        }
      }, err => { this.transferRequestList = []; this.totalItems = 0; });
    }
    
    getManagerTransferRequest(currentPage) {
      const getMethod = this.requestType === Constants.ISAVAILABLE_OFFER ?
      this.transferRequestService.getTransferPendingHrRequestList(currentPage, this.rowsOnPage)
      : this.transferRequestService.getTransferClosedHrRequestList(currentPage, this.rowsOnPage);
      getMethod.then((res: any) => {
        if (res['Success']) {
          if (res.Data && res.Data.results.length > 0) {
            this.transferRequestList = res.Data.results;
            this.transferRequestList.map((item:any)=>{
              if(item.isPit){
                item.operateToShow = "Yes"
              }
              else{
                item.operateToShow = "No";
              }
              if (item.status ===this.offerStatus.accepted){
                item.offerStatusStr = "Pending Process"
              }
            })
            this.totalItems = res.Data.totalNumberOfRecords;
          } else {
            this.transferRequestList = [];
            this.totalItems = 0;
          }
        } else {
          this.transferRequestList = [];
          this.totalItems = 0;
        }
      }, err => {
        this.transferRequestList = [];
        this.totalItems = 0;
      });
    }
    
    openModal(template: TemplateRef<any>, data: any) {
      if (data) {
        this.myOffer = data;
      } else {
        this.myOffer = null;
      }
      this.modalRef = this.modalService.show(template, {
        class: 'modal-dialog-centered modal-lg',
        backdrop: 'static'
      });
    }
    
    closeModal(event) {
      if (event) {
        this.setPage({ offset: 0 });
      }
      this.modalRef.hide();
    }
    getTransferOfferList(currentPage) {
      
      const getMethod = this.role === this.roleEnum.manager ? this.transferRequestService.getTransferClosedManagerRequestList(currentPage, this.rowsOnPage)
      : this.transferRequestService.getTransferRequestList(currentPage, this.rowsOnPage,this.companyId)
      getMethod.then((res: any) => {
        if (res['Success']) {
          if (res.Data && res.Data.results.length > 0) {
            this.transferRequestList = res.Data.results;
            this.isApprovedReq = this.transferRequestList.some(e=>e.status == this.offerStatus.scheduleUpdated);
            this.transferRequestList.map((item)=>{
              if(item.isPit){
                item.operateToShow = "Yes"
              }
              else{
                item.operateToShow = "No"
              }
              if(item.status == this.offerStatus.accepted){
                item.offerStatusStr = "Pending Process";
              }
            })
            this.totalItems =  res.Data.totalNumberOfRecords;
          } else {
            this.transferRequestList = [];
            this.totalItems = 0;
          }
        } else {
          this.transferRequestList = [];
          this.totalItems = 0;
        }
      }, err => {
        this.transferRequestList = [];
        this.totalItems = 0;
      });
    }
    
    
    onClickDelete(data) {
      this.deleteId = data.offerId;
      this.isConfirmed = true;
    }
    
    onClickAccept(data){
      
      this.type = "TRANSFER_ACCEPT"
      this.text = Constants.IACCEPT_MSG;
      this.acceptId = data.offerId;
      this.isAccept = true;
    }
    
    onClickNotInterested(data){
      this.type = "TRANSFER_NOT_INTERESTED"
      this.text = Constants.NOT_INTERESTED_MSG;
      this.rejectId = data.offerId;
      this.isNotInterested = true;
    }
    
    close(event) {
      this.isConfirmed = false;
    }
    closeAccept(event){
      this.isAccept = false;
    }
    closeNotInterested(event){
      this.isNotInterested = false;
    }
    
    
    delete(id) {
      this.isConfirmed = false;
      this.offerService.deleteOffer(id).then(res => {
        if (res['Success']) {
          this.toaster.success(Constants.OFFER_DELETE_SUCCESS_MSG);
          this.setPage({ offset: 0 });
        } else {
          this.toaster.error(res['Message']);
        }
      }, err => { });
    }
    
    accept(offerId){
      this.transferRequestService.acceptTransferOffer(offerId).then(res => {
        if (res['Success']) {
          this.toaster.success(Constants.TRANSFER_OFFER_ACCEPT_MSG);
          this.setPage({ offset: 0 });
        } else {
          this.toaster.error(res['Message']);
        }
      }, err => { });
    }
    
    notInterested(offerId){
      this.isNotInterested = false;
      this.transferRequestService.notInterestedTransferOffer(offerId).then(res => {
        if (res['Success']) {
          this.toaster.success(Constants.TRANSFER_OFFER_NOT_INTERESTED_MSG);
          this.setPage({ offset: 0 });
        } else {
          this.toaster.error(res['Message']);
        }
      }, err => { });
    }
  }
  