import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModuleFunctionRoutingModule } from './module-function-routing.module';
import { ModuleFunctionComponent } from './module-function.component';
import { SharedModule } from '../shared/shared/shared.module';
import { ModuleTrainingConfigComponent } from './module-training-config/module-training-config.component';
import { AddEditModuleTrainingConfigComponent } from './add-edit-module-training-config/add-edit-module-training-config.component';
import { FunctionTrainingConfigComponent } from './function-training-config/function-training-config.component';
import { AddEditFunctionTrainingConfigComponent } from './add-edit-function-training-config/add-edit-function-training-config.component';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { ModuleFunctionService } from '../service/moduleFunction.service';


@NgModule({
  declarations: [ModuleFunctionComponent, ModuleTrainingConfigComponent, AddEditModuleTrainingConfigComponent, FunctionTrainingConfigComponent, AddEditFunctionTrainingConfigComponent],
  imports: [
  SharedModule,
    CommonModule,
    ModuleFunctionRoutingModule,
    NgxBootstrapIconsModule.pick(allIcons)
  ],
  providers: [ModuleFunctionService]
})
export class ModuleFunctionModule { }
