import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API } from '../routes/api-routes';
import { Constants } from '../constant/constants';

@Injectable()
export class LocationService {

  constructor(private apiService: ApiService) { }

  getLocationList(currentPage) {
    return this.apiService.get(
      `${API.LOCATION_ROUTES.location}?page=` +
      currentPage,
      true,
      true, 
    );
  }

  getLocationListByCompany(companyId, currentPage) {
    return this.apiService.get(
      `${API.LOCATION_ROUTES.getLocationByCompany}?companyId=${companyId}&page=${currentPage}`
      ,
      false,
      true,
    );
  }

  addLocation(params) {
    return this.apiService.post(`${API.LOCATION_ROUTES.saveLocation}`, params, true);
  }

  updateLocation(params) {
    return this.apiService.put(`${API.LOCATION_ROUTES.saveLocation}`, params, true);
  }

  deleteLocation(Id) {
    return this.apiService.delete(
      `${API.LOCATION_ROUTES.deleteLocation}` + Id + `?deletedBy=` + localStorage.getItem(Constants.USERID),
      true,
    );
  }

  dataFilter(data) { //dataFilter
    return this.apiService.post(`${API.LOCATION_ROUTES.dataFilter}`, data, true);
  }
}
