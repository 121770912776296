import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TimePunchesService } from '../service/time-punches.service';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-reset-identity',
  templateUrl: './reset-identity.component.html',
  styleUrls: ['./reset-identity.component.scss']
})
export class ResetIdentityComponent implements OnInit {
  accessToken: any;
  dateTime: any;
  userId:any;
text:string='Please wait...'
  userName: any;
  constructor(
    private route: ActivatedRoute,
    private clockOutService:TimePunchesService,
    private utilityService:UtilityService
  ) { 
    
  }

  ngOnInit() {
    this.utilityService.showLoading();
    this.route.queryParams.subscribe((params) => {

      if (params.hasOwnProperty("UserName")) {
        this.userName = params["UserName"];
      }
      if (params.hasOwnProperty("dateTime")) {
        this.dateTime = params["dateTime"];
      }
      if (params.hasOwnProperty("userId")) {
        this.userId = params["userId"];
      }
      if(!!this.dateTime)
      this.clockOutService.resetUserIdentity(this.userName, this.dateTime, this.userId).then((res:any) => {
        this.utilityService.hideLoading()
        if(res["Success"]){
          this.text = "Please try to Punch-in/Out from your device"
          return this.utilityService.showSuccessToast(res['Message'])
        }
        this.text = "Something went wrong"
        this.utilityService.showErrorToast(
          res['Message']
        )
      })
    });
  }

}
